import { useCallback, useReducer } from "react";
import {
  EstimatorState,
  newEstimatorProjectData,
} from "../../pages/finops/resourceCalculator/helper-component/types";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createEstimatorApi,
  fetchEstimatorApi,
  fetchEstimatorInfo,
  fetchResouceDetailsApi,
  fetchSpecificProjectDetailsApi,
  updateProjectApi,
} from "../../service/finops/api-service";

const sourceDatas = [
  {
    serviceInfo: "Infrastructure Services",
    MonthlyCost: "00.00",
    id: "1",
  },
  {
    serviceInfo: "Digital Services",
    MonthlyCost: "00.00",
    id: "2",
  },
  {
    serviceInfo: "Operation Services",
    MonthlyCost: "00.00",
    id: "3",
  },
];

const initialState: EstimatorState = {
  digitalServiceResource: [],
  operationResource: [],
  selectedEstimator: [],
  infraResource: [],
  initalDataEstimator: sourceDatas,
  digitalCost: 0,
  operationCost: 0,
  infraServiceCost: 0,
  popupVisible: false,
  selectedEstimatorData: null,
};
export const transformData = (input: any, estimateTitle: string) => {
  const output = [
    {
      total_monthly_cost: "$0",
      name: estimateTitle,
    },
    {
      serviceInfo: "Digital Services",
      data: [],
      totalCost: "$0",
    },
    {
      serviceInfo: "Operation Services",
      data: [],
      totalCost: "$0",
    },
    {
      serviceInfo: "Infrastructure Services",
      data: [],
      totalCost: "$0",
    },
  ];

  let totalMonthlyCost = 0;

  input?.technicalResource?.forEach((resource: { totalcost: string }) => {
    (output[1] as any)?.data?.push(resource);
    totalMonthlyCost += parseFloat(resource?.totalcost?.replace("$", ""));
    (output[1] as any).totalCost = `$${totalMonthlyCost.toFixed(2)}`;
  });

  input?.operationalResource?.forEach((resource: { totalcost: string }) => {
    (output[2] as any)?.data?.push(resource);
    totalMonthlyCost += parseFloat(resource.totalcost?.replace("$", ""));
    (output[2] as any).totalCost = `$${totalMonthlyCost?.toFixed(2)}`;
  });

  input?.infrastructureResource?.forEach((resource: { totalcost: string }) => {
    (output[3] as any)?.data?.push(resource);
    totalMonthlyCost += parseFloat(resource.totalcost?.replace("$", ""));
    (output[3] as any).totalCost = `$${totalMonthlyCost?.toFixed(2)}`;
  });

  output[0].total_monthly_cost = `$${totalMonthlyCost?.toFixed(2)}`;

  return output;
};

const reducer = (state: EstimatorState, action: any) => {
  switch (action.type) {
    case "SELECTED_DIGITAL_RESOURCE":
      return {
        ...state,
        digitalServiceResource: action.payload,
        digitalCost: calculateTotalCostPerDay(action.payload),
      };
    case "SELECTED_OPERATION_RESOURCE":
      return {
        ...state,
        operationResource: action.payload,
        operationCost: calculateTotalCostPerDay(action.payload),
      };
    case "SELECTED_INFRA_RESOURCE":
      return {
        ...state,
        infraResource: action.payload,
        infraServiceCost: calculateTotalCostPerDay(action.payload),
      };
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "SELECTED_ESTIMATE_DATA":
      return {
        ...state,
        selectedEstimatorData: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const calculateTotalCostPerDay = (data: any[]) => {
  let totalCost = 0;
  data.forEach((item) => {
    const costPerDay = parseInt(item.cost_per_day.replace("$", ""), 10);
    totalCost += costPerDay * 30;
  });
  return totalCost;
};

export const useEstimator = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onProjectMapPopupOpen = useCallback((data: any) => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
    dispatch({ type: "SELECTED_ESTIMATE_DATA", payload: data });
  }, []); // used

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  ); // used

  return {
    state,
    newEstimatorProjectData,
    onProjectMapPopupOpen,
    changePopupVisibility,
  };
};

export const useFetchEstimator = () => {
  return useQuery("fetch-estimator-data", fetchEstimatorApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateEstimator = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(createEstimatorApi, {
    onSuccess: () => {
      fnQueryClient.invalidateQueries("fetch-estimator-data");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useFetchEstimatorInfo = () => {
  return useQuery("fetch-estimator-data", fetchEstimatorInfo, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useFetchResouce = () => {
  return useQuery("fetch-resource", fetchResouceDetailsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

const fetchProjectDetailsApi = async (id: any) => {
  const queryParams = {
    clientId: id,
  };
  const response: any = await fetchSpecificProjectDetailsApi(queryParams);
  if (response) {
    return response;
  }
}; // used 

export const useFetchProject = (id: string) => {
  return useQuery(["fetch-project", id], () => fetchProjectDetailsApi(id), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useUpdateProject = (
  toggleProjectModal: () => void,
) => {
  const fnQueryClient = useQueryClient();
  return useMutation(updateProjectApi, {
    onSuccess: () => {
      toggleProjectModal();
      fnQueryClient.invalidateQueries("fetch-project");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used 