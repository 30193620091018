import { useEffect, useState } from "react";
import { PskanbanBoard } from "../../../../components/core-component/kanban";
import { keyToImageMap } from "../../../../utlis/image";
import { taskStatuses, cardStatuses } from "../../../../config/datastudio/commonConfig";
import { useDocumentCatalog, useFetchDocumentSummary } from "../../../../hooks/datastudio/useDocumentCatalog";
import { MetricsTicker } from "../../../../components/ui-components/ticker-card/TickerCardNew";
import '../../../../styles/datastudio/DocumentCatalog.scss';
import DocumentGridView from "./DocumentGridView";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";
import TableSkeleton from "../../../../components/ui-components/dataGrid/helper-component/TableSkeleton";

const DocumentBoardView = (props: any) => {

  const {
    client,
    gridRef,
    onRowClick,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    selectedDays,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData,
    mapFileData,
    setMapFileData,
    boardViewApiProps,
    handleColumnIndexChange,
    clientId,
    status,
    handleStatusChange,
    refreshKey,
    onSelectionChanged
  } = props;

  const {
    state: {
      gridTitleStatus,
      selectedClientId,
    },
    setGridTitleStatus,
    setSelectedClientId
  } = useDocumentCatalog();

  const [apiBoardProps, setApiBoardProps] = useState(boardViewApiProps);

  const { data: summaryData, isLoading } = useFetchDocumentSummary({ ...selectedDays, refreshKey });

  const getPriority = (status: string) => {
    switch (status) {
      case "Pending":
        return 1;
      case "In Progress":
        return 2;
      case "Failed":
        return 3;
      case "Ready for Review":
        return 4;
      case "Completed":
        return 5;
      case "Rejected":
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    const updatedApiProps = {
      ...apiBoardProps,
      additionalParams: {
        ...apiBoardProps?.additionalParams,
        clientId: clientId || "",
        status: status || "Pending",
      },
    };
    setSelectedClientId("");
    setGridTitleStatus(status || "Pending");
    setApiBoardProps(updatedApiProps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, clientId, refreshKey]);

  useEffect(() => {
    if (selectedClientId !== "") {
      const updatedApiProps = {
        ...apiBoardProps,
        additionalParams: {
          ...apiBoardProps?.additionalParams,
          clientId: selectedClientId || clientId,
          status: status || "Pending",
        },
      };
      setGridTitleStatus(status || "Pending");
      setApiBoardProps(updatedApiProps);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, selectedClientId, status]);

  const processData = (data: any[]) => {
    return data?.flatMap((clientData) =>
      clientData?.taskDetails?.flatMap(({ status, tasks }: any) =>
        tasks?.map((task: any) => ({
          clientName: clientData?.clientName,
          clientId: clientData?.clientId,
          status,
          priority: getPriority(status),
          taskNames: [`${task?.taskName} : ${task?.fileCount}`],
        }))
      )
    );
  };

  const kanbanFiltered = summaryData?.filter((item: any) => (client === 'All Clients' || item?.clientName === client));

  const formattedData = processData(kanbanFiltered || []);

  const handleTileCardClick = (data: any) => {
    if (gridTitleStatus === data) {
      setSelectedClientId("");
    }
    setGridTitleStatus(data);
    handleStatusChange(data);
    const updatedApiProps = {
      ...apiBoardProps,
      additionalParams: {
        ...apiBoardProps?.additionalParams,
        clientId: "",
        status: data,
      },
    };
    setApiBoardProps(updatedApiProps);
  };

  const handleCardClick = (data: any) => {
    const { status, clientId } = data[0];
    if (gridTitleStatus === status && selectedClientId === clientId) {
      return;
    }
    setGridTitleStatus(status);
    handleStatusChange(status);
    setSelectedClientId(clientId);

    const updatedApiProps = {
      ...apiBoardProps,
      additionalParams: {
        ...apiBoardProps?.additionalParams,
        clientId: clientId,
        status: status,
      },
    };
    setApiBoardProps(updatedApiProps);
  };

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      e?.rowElement?.classList?.add("hand-cursor");
    }
  };

  const groupAndTransformData = (data: any[]) => {
    const groupedData: { [key: string]: { title: string; data: any[]; icon: string } } = {};

    const cardFiltered = data?.filter((item: any) =>
      (client === 'All Clients' || item?.clientName === client) &&
      (gridTitleStatus === 'All Items' || item?.documentProcess?.some((process: any) => process?.name === gridTitleStatus))
    );

    cardFiltered?.forEach((client: { clientName: string; documentProcess: { name: any; value: any; }[] }) => {
      client?.documentProcess?.forEach((process: { name: any; value: any; }) => {
        const title = process?.name;
        if (!groupedData[title]) {
          groupedData[title] = {
            title,
            data: [],
            icon: keyToImageMap[title]
          };
        }
        groupedData[title]?.data?.push({
          name: client?.clientName,
          value: process?.value
        });
      });
    });

    return Object?.values(groupedData);
  };

  const transformedData = groupAndTransformData(summaryData || []);

  return (
    <>
      <div className="doc-row-card">
        {cardStatuses?.map((status) => {
          if (isLoading) {
            return (
              <PsSkeleton key={status} width={200} height={250} />
            );
          }
          const metric = transformedData?.find((item) => item?.title === status) || {
            title: status,
            data: [],
            icon: keyToImageMap[status] || '',
          };
          return (
            <MetricsTicker
              key={metric?.title}
              title={metric?.title}
              secondaryValue={metric?.data}
              icon={metric?.icon}
              onClick={() => handleTileCardClick(metric?.title)}
            />
          );
        })}
      </div>
      {isLoading ? <PsSkeleton height={400} /> :
        <PskanbanBoard
          status={taskStatuses}
          data={formattedData}
          onCardClick={handleCardClick}
        />
      }
      <div className="t-gap recent-activity-grid">
        <h6>{gridTitleStatus}</h6>
        {isLoading ? <TableSkeleton /> :
          <DocumentGridView
            onRowClick={onRowClick}
            onRowPrepared={onRowPrepared}
            handleClientLinkClick={handleClientLinkClick}
            handleProjectLinkClick={handleProjectLinkClick}
            handleTaskLinkClick={handleTaskLinkClick}
            gridRef={gridRef}
            telemetryData={telemetryData}
            setTelemetryData={setTelemetryData}
            metricsData={metricsData}
            setMetricsData={setMetricsData}
            pdfFinalData={pdfFinalData}
            setPdfFinalData={setPdfFinalData}
            mapFileData={mapFileData}
            setMapFileData={setMapFileData}
            boardViewApiProps={apiBoardProps}
            handleColumnIndexChange={handleColumnIndexChange}
            id="boardViewGrid"
            onSelectionChanged={onSelectionChanged}
          />}
      </div>
    </>
  );
};

export default DocumentBoardView;
