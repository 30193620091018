export const transformExecutionStorageData = (data: any) => {
    const categoryData = data || [];
    const result: { id: string; text: string; expanded: boolean; items: any[]; data: any }[] = [
       {
        id: `1`,
        text: "",
        expanded: false,
        items: [],
        data: categoryData
      }
    ];
  return result;
  };