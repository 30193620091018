import { useNavigate } from "react-router-dom";
import "../../../styles/core-components/recentActivity.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import TelemetryPopup from "./helper-component/telemetry/Telemetry";
import DataGridMultiViewLayout from "../../ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import RecentActivityGridView from "./helper-component/GirdView";
import RecentActivityBoardView from "./helper-component/BoardView";
import { DropDownButton } from "devextreme-react";
import DateDropDown from "../../ui-components/datebox/DateBox";
import { Item } from "devextreme-react/toolbar";
import { dropDownOptions } from "../../../config/constants";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook, useFetchBoardData } from "../../../hooks/app/useApps";
import { usePage } from "../../../contexts/pageContext";
import { replaceQueryUrl } from "../../../utlis/helper";

export const recentStatus = ["In Progress", "Completed", "Failed"];
interface ViewType {
  text: string;
  value: "gridView" | "boardView";
}

const viewOptions: ViewType[] = [
  { text: "Grid View", value: "gridView" },
  { text: "Board View", value: "boardView" },
];

const RecentActivityDataGrid = (props: any) => {
  const {
    showToolbar,
    filteredClientId,
    filteredClient,
    filteredProject,
    filteredClientSpecific,
    height,
    searchvisible,
  } = props;
  const taskId = useHelperHooks().getQueryParams("taskId");
  const [viewType, setViewType] = useState("gridView");
  const [refreshKey, setRefreshKey] = useState(0);
  const { data: summaryData } = useFetchBoardData();
  const { data: DateRange } = useFetchDateRange();
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { getQueryParams } = useHelperHooks();
  const initialView = getQueryParams("view");

  const {
    state: {
      selectedExecution,
      showTelemetryModal,
      client,
      projectName,
      clientId,
      projectId,
      grid_status,
      kanban_clientId,
      selectedDays,
    },
    onPopUpCloseTelementry,
    onRowClick,
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
    handleDropDownPageIndex,
    handleStatusChange,
    handleDateRangeChange,
    handleColumnIndexChange,
  } = useAppHook();
 
   const clientFilter =  filteredClientId ?? filteredClient ?? filteredClientSpecific;
  const TelemetryProps = useMemo(() => {
    return {
      selectedExecution,
      showTelemetryModal,
      onRowClick,
      onPopUpCloseTelementry,
    };
  }, [
    onRowClick,
    showTelemetryModal,
    onPopUpCloseTelementry,
    selectedExecution,
  ]);

  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
  } = filterValue || {};

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      clientId: clientFilter ? clientFilter :  p_clientId,
      projectId: filteredProject ? filteredProject : p_projectId,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [filterValue, grid_status, p_clientId, p_projectId, clientFilter, filteredProject]);

  const navigate = useNavigate();


  useEffect(() => {
    if (initialView) setViewType(initialView);
  }, [initialView]);
  


  const handleViewChange = useCallback(
    (e: any) => {
      const { item } = e;
      if (item) {
        setViewType(item?.value);
        replaceQueryUrl("view", item.value);
      }
    },
    [setViewType]
  );

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const DataGridProps = useMemo(() => {
    return {
      onRowClick,
      handleClientLinkClick,
      handleTaskLinkClick,
      handleProjectLinkClick,
      gridRef,
      client,
      height: height ? height : "height-large",
      searchvisible,
      summaryData,
      handleDropDownPageIndex,
      handleStatusChange,
      status: grid_status,
      clientId: kanban_clientId,
      additionalParams,
      handleColumnIndexChange,
      refreshKey,
    };
  }, [
    onRowClick,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleProjectLinkClick,
    gridRef,
    client,
    height,
    searchvisible,
    summaryData,
    handleDropDownPageIndex,
    handleStatusChange,
    grid_status,
    kanban_clientId,
    additionalParams,
    handleColumnIndexChange,
    refreshKey,
  ]);

  const PageBodyComponent = useCallback(
    () => [
      {
        mode: "grid",
        Component: RecentActivityGridView,
        componentProps: DataGridProps,
      },
      {
        mode: "board",
        Component: RecentActivityBoardView,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps]
  );

  const additionalToolbarContent = useMemo(
    () => (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          items={clientDropDownData}
          stylingMode="text"
          text={p_clientName ? p_clientName : client}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
          stylingMode="text"
          text={p_projectName ? p_projectName : "All Projects"}
          displayExpr="projectName"
          dropDownOptions={{ width: "auto" }}
          items={projectDropDownData}
          useSelectMode
          onSelectionChanged={filterByProject}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
        text={viewOptions.find((item) => item.value === viewType)?.text ||
                "gridView"
              }
          stylingMode="text"
          displayExpr="text"
          items={viewOptions}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={handleViewChange}
          // onItemClick={handleDropDownPageIndex}
        />
        <DateDropDown
          onDateChange={handleDateRangeChange}
          data={DateRange}
          handleDropDownPageIndex={handleDropDownPageIndex}
        />
      </Item>
    ),
    [
      clientDropDownData,
      p_clientName,
      client,
      filterByClient,
      handleDropDownPageIndex,
      p_projectName,
      projectDropDownData,
      filterByProject,
      viewType,
      handleViewChange,
      handleDateRangeChange,
      DateRange,
    ]
  );

  const layoutProps = {
    headerText: "Recent Activity",
    refetch: handleRefetch,
    additionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: viewType,
    isLoading: false,
    showToolbar: showToolbar,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <TelemetryPopup {...TelemetryProps} />
    </>
  );
};

export default RecentActivityDataGrid;
