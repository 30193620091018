export interface HeaderData {
  workflowName: string,
  description: string,
  version: string,
  activity: string,
  type: string,
  applicationName: string,
  clientName: string,
  projectName: string,
  clientId: string,
  projectId: string,
  serviceName: string,
  moduleId: string,
  source: string,
  target: string,
  correlationId: string,
  userId: string,
  priority: string,
  severity: string,
}

export const newHeaderTab: HeaderData = {
  workflowName: '',
  description: '',
  version: '1.0',
  activity: '',
  type: '',
  applicationName: 'Polaris',
  clientName: '',
  projectName: '',
  clientId: '',
  projectId: '',
  serviceName: '',
  moduleId: '',
  source: '',
  target: '',
  correlationId: '',
  userId: '',
  priority: '',
  severity: ''
}

export interface MetaData {
  tags: string[],
  description: string,
  headers?: {
    contentType: string,
    contentLength: any,
    encoding: string,
  }
  catalog: string,
  index: string,
  environment: string,
  retryCount: any,
  durationMs: any,
  geoLocation: string,
  additionalInfo?: {
    osVersion: string,
    pythonVersion: string,
    dockerInfo: string
  }
}

export const newMetaTab: MetaData = {
  tags: [],
  description: '',
  headers: {
    contentType: '',
    contentLength: '',
    encoding: ''
  },
  catalog: '',
  index: '',
  environment: '',
  retryCount: '',
  durationMs: '',
  geoLocation: '',
  additionalInfo: {
    osVersion: '',
    pythonVersion: '',
    dockerInfo: ''
  }
}

export interface WorkflowData {
  name: string,
  description: string,
  inputType: string,
  inputLocation: string,
  inputFilename: string,
  action: string,
  functions: string[],
  type: string,
  impact: string,
  outputType: string,
  outputFilename: string,
  outputLocation: string
}

export const newWorkflowTab: WorkflowData[] = [{
  name: '',
  description: '',
  inputType: '',
  inputLocation: '',
  inputFilename: '',
  action: '',
  functions: [],
  type: '',
  impact: '',
  outputType: '',
  outputFilename: '',
  outputLocation: ''
}]

export interface DataItems {
  items: string[]
}

export const newDataTab: DataItems = {
  items: []
}

export interface WorkflowState {
  popupVisible: boolean
}
