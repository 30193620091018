import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createClientApi,
  deleteClientById,
  updateClientApi,
  fetchClientCatalogApi
} from "../../service/client/api-service";
import { useCallback, useReducer, useRef } from "react";
import DataGrid, { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { useNavigate } from "react-router-dom";
import { ClientState } from "../../pages/client/client-catalog/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const initialState: ClientState = {
  popupVisible: false,
  EditpopupVisible: false,
  clientId: 0,
  selectedClient: null,
  deletePopupVisible: false,
  pageCount: 1,
  pageSize: 25,
  pageIndex: [0],
  isNextPage: false,
  sortOrder: "",
  sortColumn: "",
};

const reducer = (state: ClientState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, // used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        EditpopupVisible: action.payload, // used
      };
    case "CLIENT_ID":
      return {
        ...state,
        clientId: action.payload,
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used
      };
    case "SELECTED_CLIENT":
      return {
        ...state,
        selectedClient: action.payload, // used
      };
      case "PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      };

    case "PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload,
      };
    case "IS_NEXTPAGE":
      return {
        ...state,
        isNextPage: action.payload,
      };
    case "PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SORTING_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };
    case "SORTING_COLUMN":
      return {
        ...state,
        sortColumn: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useClientCatlog = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);
  const navigate = useNavigate();

  const onAddContactClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []);

  const onRowClick = useCallback(
    ({ data }: DataGridTypes.RowClickEvent) => {
      navigate(`/client-profile?clientId=${data.clientId}`);
    },
    [navigate]
  );

  const onEditClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_CLIENT", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectClient(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []);

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []);

  const handleDropDownPageIndex = useCallback(() => {
    dispatch({ type: "IS_NEXTPAGE", payload: false });
    dispatch({
      type: "PAGE_INDEX",
      payload: [0],
    });
  }, []);

  const handlePageIndexChange = useCallback(
    (
      e: {
        fullName: string;
        value: number;
        component: any;
      },
    ) => {
      if (e.fullName === "paging.pageIndex") {
        dispatch({
          type: "PAGE_INDEX",
          payload: [...state.pageIndex, e.value],
        });
        const isNextPage = state.pageIndex?.includes(e.value);
        dispatch({ type: "IS_NEXTPAGE", payload: isNextPage });
      } else if (e.fullName === "paging.pageSize") {
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        const pageSize = e.value;
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "PAGE_SIZE",
          payload: pageSize,
        });
      } else if (
        e.fullName.startsWith("columns[") &&
        e.fullName.endsWith("].sortOrder")
      ) {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        const sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        dispatch({ type: "IS_NEXTPAGE", payload: false });
        dispatch({
          type: "PAGE_INDEX",
          payload: [0],
        });
        dispatch({
          type: "SORTING_ORDER",
          payload: sortOrder,
        });
        dispatch({
          type: "SORTING_COLUMN",
          payload: sortColumn,
        });
      }
    },
    [state.sortOrder, state.pageIndex, state.pageCount,state.isNextPage]
  );
  return {
    state,
    gridRef,
    onAddContactClick,
    onDeletePopupOpen,
    onRowClick,
    onEditClick,
    changeEditPopupVisibility,
    changePopupVisibility,
    onDeletePopupClose,
    handlePageIndexChange,
    handleDropDownPageIndex,
  };
};

export const useCreateClient = (
  toggleClientModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? updateClientApi : createClientApi;
  return useMutation(taskfunc, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.response,
        type: "success",
      });
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-client-catalog");
      fnQueryClient.invalidateQueries("fetch-clients");
      fnQueryClient.invalidateQueries("fetch-clients-name");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteClient = (
  onDeleteHandler: () => void,
  clientId: string,
  userId: string,
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteClient", clientId, userId],
    () => deleteClientById(clientId, userId),
    {
      enabled: !!clientId && !!userId && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-clients");
        queryClient.invalidateQueries("fetch-client-catalog");
      },
    }
  );
};

export const useFetchClientCatalog = (clientId: any,
  status : any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage:boolean
) => {
  const page = pageIndex[pageIndex.length -1] + 1;
  const queryParams = {
    clientId,
    status,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  }
  return useQuery(
    ["fetch-client-catalog", queryParams], 
    () => fetchClientCatalogApi(queryParams), 
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select: (data: any) => {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
