import { TextArea } from "devextreme-react";
import Card from "../../../../../../components/ui-components/card/card";

const DataTab = (props: any) => {

  const { data } = props;

  const dataItem = data?.data

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <div className="dx-field">
          <div className="dx-field-label">Data</div>
          <div className="dx-field-value">
            <TextArea
              value={dataItem?.items}
              height={400}
              readOnly={true}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DataTab;