import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import Popup from "devextreme-react/popup";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import { editCellStatusRender } from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../../config/constants";
import { allowedPageSizes } from "../../../../../config/client/commonConfig";
import { useState } from "react";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import WorkflowIcon from "../../../../../assets/images/icons/icon-processing-2.png";
import TemplateLineage from "../../../../../lineageTracing/template";
import LineageViewer from "../../../../../components/ui-components/lineageView/LineageView";

const TemplateGrid = (props: any) => {
  const {
    onEditTemplateClick,
    onDeletePopupOpen,
    onClonePopupOpen,
    handleRowClick,
    gridRef,
    templateLibrary,
    showPopup,
    onHandleTemplatePopupClose,
    dataTemplateJson,
  } = props;

  const [selectedData, setSelectedData] = useState<any>();
  const [openLineage, setOpenLineage] = useState(false);

  const onHanldingLineage = () => {
    setOpenLineage(!openLineage);
  };

  const onHandleLineageData = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  };

  const renderLineageIcon = (cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  };

  console.log(selectedData, "selectedData");
  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: <TemplateLineage data={selectedData} />,
  };

  return (
    <>
      <DataGrid
        className="grid theme-dependent height-large"
        noDataText={"No template to display at this time"}
        dataSource={templateLibrary}
        onExporting={onExporting}
        allowColumnReordering
        showBorders
        ref={gridRef}
        onRowClick={handleRowClick}
        allowColumnResizing
      >
        <SearchPanel visible={true} width={200} />
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <Column dataField="template_name" caption="Template Name" />
        <Column dataField="template_function" caption="Template Types" />
        <Column
          dataField="status"
          caption="Status"
          cellRender={ContactStatus}
          editCellRender={editCellStatusRender}
        />
        <Column dataField="notes" caption="Note" />
        <Column dataField="template_path" caption="Template Path" />

        <Editing
          mode="row"
          useIcons={true}
          allowDeleting={true}
          key={"id"}
          allowUpdating
        />
        <Column
          caption=""
          type="buttons"
          cellRender={renderLineageIcon}
          width={70}
        />
        <Column caption="" type="buttons" minWidth={200}>
          <ActionsButton
            name="edit"
            onClick={onEditTemplateClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton
            text="Clone"
            hint="Clone"
            icon="copy"
            cssClass="action-image"
            onClick={onClonePopupOpen}
          />
          <ActionsButton
            name="delete"
            cssClass="action-image"
            onClick={onDeletePopupOpen}
          />
        </Column>
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <div>
        <Popup
          visible={showPopup}
          onHiding={onHandleTemplatePopupClose}
          title="Template Details"
          dragEnabled={true}
          fullScreen={true}
          width={600}
          height={400}
        >
          {dataTemplateJson ? (
            <div>
              <pre>
                {JSON.stringify(dataTemplateJson?.blobfile_info, null, 2)}
              </pre>
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </Popup>
        <LineageViewer {...lineageDataProps} />
      </div>
    </>
  );
};

export default TemplateGrid;
