import DataGrid, {
    Column,
    HeaderFilter,
    Editing,
    Paging,
  } from "devextreme-react/data-grid";
  import { useEffect } from "react";
  import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
  import { useDocumentProcessingStateProvider } from "../../../../../../hooks/datastudio/useDocumentProcessing";
  import { getRowStyle } from "../../../../../../utlis/helper";
  
  export const ExtractView = (props: any) => {
    const { data, isLoading, selectedConfidence } = props;
    const { state, setDocumentProcessingStateData } =
      useDocumentProcessingStateProvider();
  
    useEffect(() => {
      setDocumentProcessingStateData(data);
    }, [data]);
  
    const handleEditing = (changes: any) => {
      if (!changes || changes?.length === 0) {
        return;
      }
      const updatedData = state?.documentProcessingStateData?.map((row: any) => {
        const change = changes?.find((c: any) => c?.key === row?.key);
        if (change) {
          return { ...row, ...change?.data };
        }
        return row;
      });
      setDocumentProcessingStateData(updatedData);
    };
  
    const onRowPrepared = (e: any) => {
      if (e?.rowType === "data") {
        const confidenceValue = e?.data["confidence"];
        if (
          confidenceValue !== "" &&
          confidenceValue !== undefined &&
          confidenceValue !== null
        ) {
          const rowStyle = getRowStyle(confidenceValue, selectedConfidence);
          Object?.assign(e?.rowElement?.style, rowStyle);
        }
      }
    };
  
    if (!data) {
      return <PsSkeleton count={1} height={300} width={300} />;
    }
  
    return (
      <div>
        {Object?.keys(data)?.map((key) => (
          <div key={key} style={{ marginBottom: "20px" }}>
            <DataGrid
              className="grid theme-dependent"
              dataSource={Array?.isArray(data[key]) ? data[key] : [data[key]]}
              onRowUpdating={(e: any) => handleEditing(e?.changes)}
              noDataText={
                isLoading ? "Loading..." : "Pdf Data will be displayed here"
              }
              width={"100%"}
              showBorders={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              columnResizingMode="widget"
              onRowPrepared={onRowPrepared}
            >
              <Editing
                mode="cell"
                allowUpdating={true}
                allowDeleting={false}
                allowAdding={false}
              />
              <Paging enabled={false} />
              <HeaderFilter visible={true} />
              {Array?.isArray(data[key]) && data[key]?.length > 0
                ? Object?.keys(data[key][0] || {})?.map((field) => (
                    <Column key={field} dataField={field} caption={field} />
                  ))
                : Object?.keys(data[key] || {})?.map((field) => (
                    <Column key={field} dataField={field} caption={field} />
                  ))}
            </DataGrid>
          </div>
        ))}
      </div>
    );
  };