import { useEffect, useState } from "react";
import { PskanbanBoard } from "../../../../components/core-component/kanban";
import { keyToImageMap } from "../../../../utlis/image";
import { taskStatuses, cardStatuses, allowedPageSizes } from "../../../../config/datastudio/commonConfig";
import { useFetchDocumentSummary, useCatalogBoardInfo } from "../../../../hooks/datastudio/useDocumentCatalog";
import { MetricsTicker } from "../../../../components/ui-components/ticker-card/TickerCardNew";
import '../../../../styles/datastudio/DocumentCatalog.scss';
import { DataGrid } from "devextreme-react";
import DataSource from "devextreme/data/data_source";
import { Column, HeaderFilter, MasterDetail, Pager, Paging, Scrolling, Sorting } from "devextreme-react/cjs/data-grid";
import MasterDetailView from "./CatalogGrid/DocumentInfo";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { cellStartTime, cellUpdatedTime } from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import TableSkeleton from "../../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";
import DocumentGridView from "./DocumentGridView";

const DocumentBoardView = (props: any) => {
  const {
    client,
    gridRef,
    onRowClick,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    selectedDays,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData,
    mapFileData,
    setMapFileData,
    boardViewApiProps,
    handleColumnIndexChange,
    handlePageIndexChange,
    handleDropDownPageIndex,
    disableLinks,
    pageIndex,
    pageSize,
    isNextPage,
    clientId,
    ProjectId,
    sortColumn,
    sortOrder,
    status,
    handleStatusChange,
  } = props;
  const [clickedListData, setClickedListData] = useState<any[]>([]);

  const [gridTitleStatus, setGridTitleStatus] = useState(status || "Pending");
  const [selectedClientId, setSelectedClientId] = useState(clientId || "");
  const { data: summaryData } = useFetchDocumentSummary(selectedDays);
  const getPriority = (status: string) => {
    switch (status) {
      case "Pending":
        return 1;
      case "In Progress":
        return 2;
      case "Failed":
        return 3;
      case "Ready for Review":
        return 4;
      case "Completed":
        return 5;
      case "Rejected":
        return 6;
      default:
        return 0;
    }
  };

  useEffect(() => {
    boardViewApiProps.additionalParams.status = gridTitleStatus
    if(selectedClientId){
      boardViewApiProps.additionalParams.clientId = selectedClientId
    }
  }, [gridTitleStatus, selectedClientId])

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{
          cursor: "pointer",
          color: "#0F6CBD",
          textDecoration: "underline",
        }}
        onClick={() => onRowClick(cell)}
      >
        {cell.data.DocumentName}
      </span>
    );
  };

  const {
    data: catalogBoard,
    refetch,
    isFetching,
  } = useCatalogBoardInfo(selectedClientId,
     ProjectId, 
     selectedDays,  
     pageSize,
    sortColumn,
    sortOrder,
    pageIndex,
    isNextPage,
    status);

    const processData = (data: any[]) => {
      return data.flatMap((clientData) =>
        clientData.taskDetails.flatMap(({ status, tasks }: any) =>
          tasks.map((task: any) => ({
            clientName: clientData.clientName,
            clientId: clientData.clientId,
            status,
            priority: getPriority(status),
            taskNames: [`${task?.taskName} : ${task?.fileCount}`],
          }))
        )
      );
    };
   
   const kanbanFiltered = summaryData?.filter((item: any) =>
    (client === 'All Clients' || item?.clientName === client)
  );

  const formattedData = processData(kanbanFiltered || []);
  
  const handleTileCardClick = (data: any) => {
    setGridTitleStatus(data); 
    handleStatusChange(data)
    setSelectedClientId("");
    handleDropDownPageIndex([0]);
    const filteredData = summaryData?.filter(
      (item: any) => item?.executionStatus === data 
    );
    setClickedListData(filteredData);
  };

  const handleCardClick = (data: any) => {
    const { status, clientId } = data[0];
    setGridTitleStatus(status);
    handleStatusChange(status)
    setSelectedClientId(clientId);
    handleDropDownPageIndex([0]);
    const filteredData = summaryData?.filter(
      (item: any) =>
        item?.executionStatus === status && item?.clientId === clientId
    );
    setClickedListData(filteredData);
  };
  


  const onRowPrepared = (e: any) => {
    if (e.rowType === "data") {
      e.rowElement.classList.add("hand-cursor");
    }
  };

  const groupAndTransformData = (data: any[]) => {
    const groupedData: { [key: string]: { title: string; data: any[]; icon: string } } = {};

    const cardFiltered = data?.filter((item: any) =>
      (client === 'All Clients' || item?.clientName === client) &&
      (gridTitleStatus === 'All Items' || item?.documentProcess.some((process: any) => process?.name === gridTitleStatus))
    );
    cardFiltered?.forEach((client: { clientName: string; documentProcess: { name: any; value: any; }[] }) => {
      client?.documentProcess?.forEach((process: { name: any; value: any; }) => {
        const title = process?.name;
        if (!groupedData[title]) {
          groupedData[title] = {
            title,
            data: [],
            icon: keyToImageMap[title]
          };
        }
        groupedData[title].data.push({
          name: client?.clientName,
          value: process?.value
        });
      });
    });

    return Object.values(groupedData);
  };

  const transformedData = groupAndTransformData(summaryData || []);

  const dataV2 = new DataSource({
    key: "DocumentId",
    load: () => {
      return new Promise((resolve, reject) => {
        try {
          const datas = catalogBoard?.list || [];
          const totalCount = catalogBoard?.totalcount || datas.length;
          resolve({
            data: datas,
            totalCount: totalCount,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  });

  const handleNextPage = (e: any) => {
    handlePageIndexChange && handlePageIndexChange(e);
    setGridTitleStatus(status);
  };

  return (
    <>
      <div className="doc-row-card">
        {cardStatuses.map((status) => {
          const metric = transformedData.find((item) => item.title === status) || {
            title: status,
            data: [],
            icon: keyToImageMap[status] || '',
          };
          return (
            <MetricsTicker
              key={metric?.title}
              title={metric?.title}
              secondaryValue={metric?.data}
              icon={metric?.icon}
              onClick={() => handleTileCardClick(metric?.title) }
            />
          );
        })}
      </div>
      <PskanbanBoard
        status={taskStatuses}
        data={formattedData}
        onCardClick={handleCardClick}
      />
      <div className="t-gap recent-activity-grid"> 
        <h6>{gridTitleStatus}</h6>
        <DocumentGridView
          onRowClick={onRowClick}
          onRowPrepared={onRowPrepared}
          handleClientLinkClick={handleClientLinkClick}
          handleProjectLinkClick={handleProjectLinkClick}
          handleTaskLinkClick={handleTaskLinkClick}
          gridRef={gridRef}
          telemetryData={telemetryData}
          setTelemetryData={setTelemetryData}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          pdfFinalData={pdfFinalData}
          setPdfFinalData={setPdfFinalData}
          mapFileData={mapFileData}
          setMapFileData={setMapFileData}
          pageIndex={pageIndex}
          pageSize={pageSize}
          handlePageIndexChange={handlePageIndexChange}
          boardViewApiProps={boardViewApiProps}
          handleColumnIndexChange={handleColumnIndexChange}
        />
      </div>
    </>
  );
};

export default DocumentBoardView;
