// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bar-skeleton {
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  justify-content: space-between;
  align-items: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/Skeleton.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,8BAAA;EACA,qBAAA;AACF","sourcesContent":[".bar-skeleton {\n  padding: 20px;  \n  border-radius: 5px;\n  margin-bottom: 10px;\n  display: flex;\n  justify-content: space-between;\n  justify-content: space-between;\n  align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
