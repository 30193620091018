import Popup from "devextreme-react/popup";
import PsButton from "../../../../../components/ui-components/button/Button";

export const EditConfirmation = (props: any) => {
  const { visible, onConfirm, onCancel } = props;
  return (
    <Popup
      visible={visible}
      onHiding={onCancel}
      title="Confirmation"
      width="30%"
      height="250px"
    >
      <div className="task-start">
        <p>Are you sure you want to cancel the edit workflow process? If you do, all the edited data will be lost.</p>
      </div>
      <div style={{ float: "left" }}>
        <PsButton
          onClick={onConfirm}
          text="Yes"
          type="normal"
          mode="contained"
        />
      </div>
      <div style={{ float: "right" }}>
        <PsButton
          onClick={onCancel}
          text="No"
          type="normal"
          mode="contained"
        />
      </div>
    </Popup>
  );
};
