// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RowTile.css */
.management-row-card {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.management-row-card > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(33.333% - 20px); /* Make sure the items take up equal space with gaps */
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .management-row-card > * {
    flex: 1 1 100%;
  }
}
.management-column-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.management-column-card > * {
  flex: 1 1 auto;
  box-sizing: border-box;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/mangementStyles.scss"],"names":[],"mappings":"AAAA,gBAAA;AACA;EACI,aAAA;EACA,SAAA;EACA,eAAA;EACA,oBAAA;AACJ;;AAEE;EACE,YAAA;EAAA,cAAA;EAAA,gCAAA,EAAA,sDAAA;EACA,sBAAA;AACJ;;AAEE;EACE;IACE,cAAA;EACJ;AACF;AAEE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AAAJ;;AAGE;EACE,cAAA;EACA,sBAAA;AAAJ","sourcesContent":["/* RowTile.css */\n.management-row-card {\n    display: flex;\n    gap: 20px;\n    flex-wrap: wrap;\n    padding-bottom: 20px;\n  }\n  \n  .management-row-card > * {\n    flex: 1 1 calc(33.333% - 20px); /* Make sure the items take up equal space with gaps */\n    box-sizing: border-box;\n  }\n  \n  @media (max-width: 768px) {\n    .management-row-card > * {\n      flex: 1 1 100%;\n    }\n  }\n  \n  .management-column-card {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding-bottom: 20px;\n  }\n  \n  .management-column-card > * {\n    flex: 1 1 auto;\n    box-sizing: border-box;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
