// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infraTab {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/infrastructureTab.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".infraTab {\n    display: flex;\n    justify-content: flex-end;\n    margin-top: 10px;\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
