import DataGrid, { Column } from "devextreme-react/data-grid";
import PsButton from "../../../../../components/ui-components/button/Button";
import { useState, useMemo, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PsSkeleton from "../../../../../components/ui-components/skeleton/PsSkeleton";
import { useApp } from "../../../../../contexts/app";
import { useCreateUserSpecificRoles, useFetchClientUserRoles } from "../../../../../hooks/app/useApps";
import { CheckBox } from "devextreme-react";

const AdminList = ({ roles, setIsAdminDisabled }: { roles: any; setIsAdminDisabled: (value: boolean) => void }) => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const { user } = useApp();
  const userAdmin = user?.userId;
  const navigate = useNavigate();

  const { data: userRole, refetch: refetchAdminRoles } = useFetchClientUserRoles(userId);

  const selectedData = useMemo(() => {
    return userRole?.[0]?.admin_action?.reduce((acc: { [key: string]: boolean }, action: any) => {
      acc[action.roleId] = action.isChecked || false;
      return acc;
    }, {}) || {};
  }, [userRole]);

  const [localSelectedData, setLocalSelectedData] = useState<{ [key: string]: boolean }>(selectedData);

  useEffect(() => {
    setLocalSelectedData(selectedData); 
  }, [selectedData]);

  const combinedData = useMemo(() => {
    return roles?.map((role: any) => ({
      id: role.id,
      roleName: role.roleName,
      isChecked: localSelectedData[role.id] ?? false,
    })) || [];
  }, [roles, localSelectedData]);

  const onRoleChange = (roleId: string, isChecked: boolean) => {
    setLocalSelectedData((prev) => {
      if (prev[roleId] === isChecked) return prev;
      return { ...prev, [roleId]: isChecked };
    });
  };

  const { mutate } = useCreateUserSpecificRoles();

  const onSave = () => {
    const adminPermissions = Object.keys(localSelectedData).filter((roleId) => localSelectedData[roleId]);

    const payload = {
      userId,
      adminPermissions,
      createdBy: userAdmin,
    };

    mutate(payload);
    setIsAdminDisabled(adminPermissions.length > 0);
    navigate(-1);
    refetchAdminRoles();
  };

  const onCancel = () => {
    navigate(-1);
  };

  const renderRoleWithCheckbox = (cell: any) => {
    const roleId = cell.data.id;
    return (
      <div className="roleCheckboxContainer">
        <CheckBox
          key={roleId}
          id={roleId}
          text={cell.data.roleName}
          defaultValue={localSelectedData[roleId] ?? false}
          onValueChanged={(e) => onRoleChange(roleId, e.value)}
        />
      </div>
    );
  };

  return (
    <>
      {roles && userRole ? (
        <DataGrid
          dataSource={combinedData}
          remoteOperations={false}
          showBorders={true}
          id="gridContainer"
          allowColumnResizing={true}
          allowColumnReordering={true}
          repaintChangesOnly={true}
          keyExpr="id"
        >
          <Column caption="Admin Roles" cellRender={renderRoleWithCheckbox} />
        </DataGrid>
      ) : (
        <PsSkeleton count={5} height={30} />
      )}

      <div className="accessListContainer">
        <PsButton text="Cancel" type="default" mode="outlined" onClick={onCancel} />
        <PsButton text="Save" type="default" onClick={onSave} />
      </div>
    </>
  );
};

export default AdminList;