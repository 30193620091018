import { useCallback, useMemo } from "react";
import {
  ReactFlow,
  addEdge,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Position,
  MarkerType,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { processStatus } from "../../../../../config/constants";
import "../../../../../styles/core-components/recentActivity.scss";

const generateEdges = (stepCount: number, params: any) => {
  const edges: Edge[] = [
    // Initial connection from task to the first step
    {
      id: "task-step-1",
      source: "task",
      target: "step-1",
      markerEnd: { type: MarkerType?.ArrowClosed },
    },
  ];

  for (let i = 1; i <= stepCount; i++) {
    const processGroupId = `process-${i}`;
    const functionContainerId = `${processGroupId}-function`;

    edges?.push(
      // Step to Process
      {
        id: `step-${i}-process-${i}`,
        source: `step-${i}`,
        target: `${processGroupId}-action`,
        markerEnd: { type: MarkerType?.ArrowClosed },
      },
      // Process to Action
      {
        id: `${processGroupId}-action-function`,
        source: `${processGroupId}-action`,
        target: functionContainerId,
        markerEnd: { type: MarkerType?.ArrowClosed },
      },
      // Function to Step Output
      {
        id: `${functionContainerId}-output-${i}`,
        source: functionContainerId,
        target: `output-${i}`,
        animated: true,
        style: { stroke: "darkblue", strokeWidth: 2 },
        markerEnd: { type: MarkerType?.ArrowClosed },
      },
      // Step to Data
      {
        id: `step-${i}-data-${i}`,
        source: `step-${i}`,
        target: `data-${i}`,
        animated: true,
        style: { stroke: "darkblue", strokeWidth: 2 },
        markerEnd: { type: MarkerType?.ArrowClosed },
      },
      // Step to Telemetry
      {
        id: `data-${i}-telemetry-${i}`,
        source: `data-${i}`,
        target: `telemetry-${i}`,
        animated: true,
        style: { stroke: "darkblue", strokeWidth: 2 },
        markerEnd: { type: MarkerType?.ArrowClosed },
      },
      // Step to Metrics
      {
        id: `telemetry-${i}-metrics-${i}`,
        source: `telemetry-${i}`,
        target: `metrics-${i}`,
        animated: true,
        style: { stroke: "darkblue", strokeWidth: 2 },
        markerEnd: { type: MarkerType?.ArrowClosed },
      }
    );
    // Connect step output to the next step input if it exists
    if (i < stepCount) {
      edges?.push({
        id: `output-${i}-step-${i + 1}`,
        source: `output-${i}`,
        target: `step-${i + 1}`,
        animated: true,
        style: { stroke: "green" },
        label: params?.workflowsteps[i - 1]?.status,
        labelBgPadding: [8, 4],
        labelBgBorderRadius: 4,
        labelBgStyle: {
          fill: params?.workflowsteps[i - 1]?.status === processStatus?.[1]
            ? "#28a745" : params?.workflowsteps[i - 1]?.status === processStatus?.[0]
              ? "#007bff" : params?.workflowsteps[i - 1]?.status === processStatus?.[2]
                ? "#dc3545" : "#6c757d",
          color: "#fff",
          fillOpacity: 0.7,
        },
      });
    }
  }
  // Final Output Connection from last step output to the overall output
  edges?.push(
    {
      id: `output-${stepCount}-final-output`,
      source: `output-${stepCount}`,
      target: "output",
      markerEnd: { type: MarkerType?.ArrowClosed },
    },
    // Final status node
    {
      id: `final-output-status`,
      source: `output-${stepCount}`,
      target: "status",
      markerEnd: { type: MarkerType?.ArrowClosed },
      animated: true,
      style: { stroke: "green" },
      label: params?.workflowsteps[stepCount - 1]?.status,
      labelBgPadding: [8, 4],
      labelBgBorderRadius: 4,
      labelBgStyle: {
        fill: params?.workflowsteps[stepCount - 1]?.status === processStatus?.[1]
          ? "#28a745" : params?.workflowsteps[stepCount - 1]?.status === processStatus?.[0]
            ? "#007bff" : params?.workflowsteps[stepCount - 1]?.status === processStatus?.[2]
              ? "#dc3545" : "#6c757d",
        color: "#fff",
        fillOpacity: 0.7,
      },
    });
  return edges;
};

const transformDataToNodes: any = (datas: any, params: any) => {

  const tasklist = datas?.dataTask?.[0];
  const workflowList = datas?.dataWorkflow;
  const workflowSteps = workflowList?.workflow || [];
  const workflowProgress = params?.workflowsteps || [];
  const stepCount = workflowSteps.length;
  const workflowStatus = params?.status || [];

  const nodeDefaults = {
    sourcePosition: Position?.Right,
    targetPosition: Position?.Left,
    type: "textUpdater",
  };
  const stepSpacing = 1100;
  const spacingBetweenProcessAndOutput = 200;
  const nodes = [];

  // Task Node
  nodes?.push({
    id: "task",
    position: { x: 0, y: 100 },
    data: {
      step: "Task",
      label: `Name: ${tasklist?.taskName}`,
      description: `Description: ${tasklist?.taskDescription}`,
      additionalInfo: `Client: ${tasklist?.clientName}, Project: ${tasklist?.projectName}`,
      popupData: tasklist,
      popupType: "task",
      params: params,
    },
    ...nodeDefaults,
  });

  workflowSteps?.forEach((step: any, index: any) => {
    const baseX = index * stepSpacing + 300;

    // Step Node
    nodes?.push({
      id: `step-${index + 1}`,
      position: { x: baseX, y: 100 },
      data: {
        step: `Step-${index + 1}`,
        label: `Name: ${step?.name}`,
        description: `InputLocation: ${step?.inputLocation}`,
        additionalInfo: `InputFilename: ${step?.inputFilename}`,
        popupData: step,
        startTime: workflowProgress[index]?.startTime,
        params: params,
        popupType: `step-${index + 1}`,
      },
      ...nodeDefaults,
      sourcePosition: "right",
      targetPosition: "left",
    });

    // Data Node
    const DataNodeId = `data-${index + 1}`;
    nodes?.push({
      id: DataNodeId,
      position: { x: baseX, y: 400 },
      data: {
        step: "Data",
        label: `Data for Step-${index + 1}`,
        popupData: step,
        params: params,
        popupType: `data-${index + 1}`,
      },
      ...nodeDefaults,
      targetPosition: "top",
      sourcePosition: "bottom",
    });

    // Telemetry Node
    const telemetryNodeId = `telemetry-${index + 1}`;
    nodes?.push({
      id: telemetryNodeId,
      position: { x: baseX, y: 550 },
      data: {
        step: "Telemetry",
        label: `Telemetry for Step-${index + 1}`,
        popupType: `telemetry-${index + 1}`,
        params: params,
        stepId: `Step-${index + 1}`,
      },
      ...nodeDefaults,
      targetPosition: "top",
      sourcePosition: "bottom",
    });

    // Metrics Node
    const metricsNodeId = `metrics-${index + 1}`;
    nodes?.push({
      id: metricsNodeId,
      position: { x: baseX, y: 700 },
      data: {
        step: "Metrics",
        label: `Metrics for Step-${index + 1}`,
        popupType: `metrics-${index + 1}`,
        popupData: step,
        params: params,
      },
      ...nodeDefaults,
      targetPosition: "top",
      sourcePosition: "bottom",
    });

    // Process Group
    const processGroupId = `process-${index + 1}`;
    nodes?.push({
      id: processGroupId,
      position: { x: baseX + 300, y: 100 },
      data: {
        label: "Process",
      },
      ...nodeDefaults,
      style: {
        backgroundColor: "rgba(199, 209, 223, 0.2)",
        width: 300,
        height: 500,
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px"
      },
      type: "group",
    });

    // Action Node within Process Group
    nodes?.push({
      id: `${processGroupId}-action`,
      position: { x: 50, y: 50 },
      data: {
        step: "Action",
        popupType: `action-${index + 1}`,
        popupData: workflowProgress[index],
        label: step?.action,
        height: '100px',
        width: '200px'
      },
      ...nodeDefaults,
      sourcePosition: "bottom",
      parentId: processGroupId,
      type: "textUpdater",
      extent: "parent",
    });

    // Function Node within Process Group
    const functionContainerId = `${processGroupId}-function`;
    step?.functions?.forEach((func: any) => {
      nodes?.push({
        id: functionContainerId,
        position: { x: 50, y: 250 },
        data: {
          step: "Function",
          label: func,
          popupType: "function",
          height: '200px',
          width: '200px'
        },
        ...nodeDefaults,
        targetPosition: "top",
        parentId: processGroupId,
        type: "textUpdater",
        extent: "parent",
      });
    });

    // Output Node with additional spacing
    nodes?.push({
      id: `output-${index + 1}`,
      position: { x: baseX + 600 + spacingBetweenProcessAndOutput, y: 100 },
      data: {
        label: `OutputType: ${step?.outputType}`,
        description: `OutputLocation: ${step?.outputLocation}`,
        additionalInfo: `OutputFilename: ${step?.outputFilename}`,
        popupData: step,
        endTime: workflowProgress[index]?.endTime,
        params: params,
        popupType: `output-${index + 1}`,
      },
      ...nodeDefaults,
      targetPosition: "left",
    });
  });

  // Add Status Node after the final step
  nodes?.push({
    id: "status",
    position: { x: stepCount * stepSpacing + 300, y: 100 },
    data: {
      step: workflowStatus,
      popupType: "status",
      popupData: { workflowStatus },
      params: params,
    },
    ...nodeDefaults,
    targetPosition: "left",
  });

  const edges = generateEdges(stepCount, params);

  return { nodes, edges };
};

const WorkflowProcess = (props: any) => {
  const { data } = props;
  const { datas, params } = data;
  const { nodes: transformedNodes, edges: initialEdges } = transformDataToNodes(datas, params);
  const [nodes, , onNodesChange] = useNodesState(transformedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const nodeTypes = useMemo(() => ({ textUpdater: TextUpdaterNode }), []);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  return (
    <div style={{ width: "100%", height: "100vh", backgroundColor: "white" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default WorkflowProcess;
