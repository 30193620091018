import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import FileUploader from "devextreme-react/file-uploader";
import { getSizeQualifier } from "../../../../utlis/media-query";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
import { uploadAccept } from "../../../../config/datastudio/commonConfig";
import { useBulkUpload } from "../../../../hooks/client/useClientUrl";
import { useState } from "react";
import { ProgressBar } from "devextreme-react";

const UploadURLs = (props: any) => {
  const {
    showpopup,
    onClose,
  } = props;

  const { mutate, isLoading } = useBulkUpload(onClose);
  const [uploadURLs, setUploadURLs] = useState<any[]>([]);
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [resetUploaderKey, setResetUploaderKey] = useState(0);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const fileType = uploadURLs?.length > 0 ? uploadURLs[0]?.name?.split(".").pop() : "";

  const handleFilesUpload = (e: any) => {
    const files = e.value;
    const filePromises = files.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({ name: file.name, content: reader.result });
        reader.onerror = (error) => reject(error);
      });
    });
    Promise.all(filePromises).then((encodedFiles) => {
      setUploadURLs(encodedFiles);
      const initialFileProgress: { [key: string]: number } = {};
      encodedFiles.forEach((file) => {
        initialFileProgress[file.name] = 0;
      });
      setFileProgress(initialFileProgress);
    });
  };

    const handleSave = async () => {
    const payload = {
        type: fileType,
        content: uploadURLs[0]?.content,
    };

    if (!uploadURLs?.length) {
        showNotification({
          message: "Please select files to upload",
        });
        return;
    }

    setIsButtonLoading(true);
        const uploadPromises = uploadURLs.map((file: any) => {
        return new Promise<void>((resolve) => {
            let progress = 0;
            const interval = setInterval(() => {
            progress += 10;
            setFileProgress((prevProgress) => ({
                ...prevProgress,
                [file.name]: Math.min(progress, 100),
            }));
            if (progress >= 100) {
                clearInterval(interval);
                resolve();
            }
            }, 500);
        });
        });

        Promise.all(uploadPromises).then(() => {
        mutate(payload, {
            onSuccess: () => {
            resetUploader();
            showNotification({
                message: "Files added Successfully",
                type: "success",
            });
            },
            onError: () => {
            setIsButtonLoading(false);
            showNotification({ message: "Error uploading files", type: "error" });
            },
        });
        });
    };

    const resetUploader = () => {
        setUploadURLs([]);
        setFileProgress({});
        setResetUploaderKey((prevKey) => prevKey + 1);
        setIsButtonLoading(false);
    };

    const getProgressBar = (value: number) => <ProgressBar value={value} />;

  return (
    <FormPopup
      title="Upload URLs"
      visible={showpopup}
      setVisible={onClose}
      onSave={handleSave as any}
      isLoading={isLoading || isButtonLoading}
    >
      <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
        <GroupItem>
          <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
        </GroupItem>
        <GroupItem>
          <FormItem>
            <div className="file-uploader-container">
              <FileUploader
                key={resetUploaderKey}
                multiple={true}
                accept={uploadAccept}
                uploadMode="useForm"
                onValueChanged={handleFilesUpload}
              />
              <span className="note">
               {"Supported file formats: "}
               <span>.csv, .json</span>
            </span>
            {Object?.keys(fileProgress)?.map((fileName) => (
                <div key={fileName} className="progressDiv">
                  {fileName} {getProgressBar(fileProgress[fileName])}
                </div>
              ))}
            </div>
          </FormItem>
        </GroupItem>
      </Form>
    </FormPopup>
  );
};

export default UploadURLs;
