import React, { useMemo, useState } from "react";
import { PskanbanBoard } from "../../kanban";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import PsDataGrid from "../../../ui-components/dataGrid/DataGrid";
import { fetchRecentActivityAPI } from "../../../../service/app/api-service";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellStartTime,
  cellEndTime,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { usePage } from "../../../../contexts/pageContext";

export const recentStatus = ["In Progress", "Completed", "Failed"];

const RecentActivityBoardView = React.memo((props: any) => {
  const {
    summaryData,
    client,
    height,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    handleStatusChange,
    status,
    additionalParams,
    clientId,
    handleColumnIndexChange,
  } = props;

  const [gridTitleStatus, setGridTitleStatus] = useState("");
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};

  const mapSummaryToKanban = (data: any[]) => {
    return data.flatMap((clientData) =>
      clientData.taskDetails.flatMap(({ status, tasks }: any) => ({
        clientId: clientData.clientId,
        clientName: clientData.clientName,
        status,
        taskNames: tasks?.map(
          (task: any) => `Task: ${task.taskName}: ${task.executionCount}`
        ),
        priority: status === "In Progress" ? 3 : status === "Completed" ? 2 : 4,
      }))
    );
  };

  const allData = summaryData?.data || [];
  const kanbanData = mapSummaryToKanban(allData);
  const filteredKanbanData =
    client === "All Clients"
      ? kanbanData
      : kanbanData.filter((item) => item.clientName === client);

  const handleCardClick = (datas: any) => {
    const { status, clientId } = datas[0];
    setGridTitleStatus(`${status}`);
    handleStatusChange(status, clientId);
  };


  const {
    clientName: clientColunm,
    projectName: projectColumn,
    taskName: taskColumn,
    status: statusColumn,
    executionId:executionColumn,
    projectType: projectTypeColumn,
    endTime: timeEnded,
    timestamp: timeStarted,
  } = filterValue || {}; 

const columns = useMemo(
  () => [
    {
      dataField: "clientName",
      caption: "Client",
      filterValues: clientColunm, 
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleClientLinkClick(e, data?.data?.clientId)}
        >
          {data?.data?.projectName}
        </a>
      ),
    },
    {
      dataField: "projectName",
      caption: "Project Name",
      filterValues: projectColumn,
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId)}
        >
          {data?.data?.projectName}
        </a>
      ),
    },
    {
      dataField: "taskName",
      caption: "Task Name",
      filterValues:taskColumn,
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleTaskLinkClick(e, data?.data?.taskId)}
        >
          {data?.data?.taskName}
        </a>
      ),
    },
    {
      dataField: "executionId",
      caption: "Execution ID",
      filterValues:executionColumn,
      cellRender: (data: any) => {
        const executionId = data?.data?.executionId;
        const truncatedId = executionId ? executionId.substr(0, 10) : "";
        return <>{truncatedId}</>;
      },
    },
    { dataField: "projectType", caption: "Activity Type", filterValues: projectTypeColumn },
    { dataField: "status", caption: "Status", filterValues:statusColumn,cellRender: ContactStatus },
    {
      dataField: "timestamp",
      caption: "Time Started",
      filterValues: timeStarted,
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "Time Completed",
      filterValues: timeEnded,
      cellRender: cellEndTime,
    },
  ],
  [handleProjectLinkClick, handleClientLinkClick,handleTaskLinkClick, clientColunm, executionColumn, projectColumn, projectTypeColumn, statusColumn, taskColumn, timeEnded, timeStarted] 
);


  const pageProps = useMemo(() => ({
    nodataText: "No Recent Activity to display at this time",
    heightClass: height,
    id: "recentboardview",
  }), [height]);

  const apiProps = useMemo(
    () => ({
      apiFunction: (queryParams: any) =>
        fetchRecentActivityAPI({
          ...queryParams,
          ...additionalParams,
          status,
          clientId,
        }),
    }),
    [additionalParams, status, clientId]
  );

  const gridProps = useMemo(
    () => ({
      columns,
      keyExpr: "executionId",
      allowedPageSizes,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      searchPanelVisible: false,
      handleColumnIndexChange,
    }),
    [columns, handleColumnIndexChange]
  );

  return (
    <>
      <PskanbanBoard
        status={recentStatus}
        data={filteredKanbanData}
        onCardClick={handleCardClick}
      />

      <div className="t-gap recent-activity-grid">
        <h6>{gridTitleStatus}</h6>
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      </div>
    </>
  );
});

export default RecentActivityBoardView;