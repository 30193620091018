import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import {
  editCellStatusRender,
  cellComplexityRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { onExporting } from "../../../../config/constants";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { useNavigate } from "react-router-dom";

const UrlGrid = (props: any) => {
  const { data, onEditUrlClick, onDeletePopupOpen,gridRef } = props;
  const navigate = useNavigate();

  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
  };

  return (
  <div className="recent-activity-grid">
 
    <DataGrid
      className={`grid theme-dependent height-large`}
      noDataText={"No URLs to display at this time"}
      focusedRowEnabled
      dataSource={data}
      onExporting={onExporting}
      keyExpr={"id"}
      allowColumnReordering
      showBorders
      ref ={gridRef}
      allowColumnResizing={true}
    >
      <SearchPanel visible={true} width={200}/>
      <HeaderFilter visible />
      <Sorting mode="multiple" />
      <Scrolling mode="virtual" />
      <Column
        dataField="clientName"
        caption="Client"
        cellRender={(data) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return (
            <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        }}
        width="15%"
      />
      <Column
        dataField="url"
        caption="URL"
        cellRender={(data) => 
        <div title={data.value}
             style={{overflow: "hidden", textOverflow: "ellipsis" }}
        >{data.value ? data.value.substring(0, 50) : ""}
        </div>}
        width="30%"
      />
      <Column
        dataField="status"
        caption="Status"
        cellRender={ContactStatus}
        editCellRender={editCellStatusRender}
        width="10%"
      />
      <Column
        dataField="group"
        caption="Group Name"
        width="10%"
      />
      <Column
        dataField="complexity"
        caption="Complexity"
        width="10%"
        cellRender={cellComplexityRender}
      />
      <Column dataField="note" caption="Notes" width="10%" />
      <Column dataField="url_template_path" caption="Template" width="30%" />
      <Editing
        mode="row"
        useIcons={true}
        allowUpdating
        allowDeleting={true}
        key={"id"}
      />
      <Column caption="" type="buttons" width="10%">
        <ActionsButton
          name="edit"
          onClick={onEditUrlClick}
          hint="Edit"
          cssClass="action-image"
        />
        <ActionsButton name="delete" cssClass="action-image" onClick={onDeletePopupOpen} />
      </Column>
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Paging defaultPageSize={25} />
      <Pager
        visible={true}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  </div>  
  );
};

export default UrlGrid;
