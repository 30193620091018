import { DataGridTypes } from 'devextreme-react/data-grid';
import { saveAs } from "file-saver-es";
import * as XLSX from 'xlsx';
import { formatDate } from '../dataGridFormat/dataGridFormat';

export interface FileViwerPopupProps {
  visible: boolean;
  onHiding: () => void;
  data: any | null;
  title: string | any;
  isLoading: boolean;
  extensionType: string;
  type?: string;
  params?: any;
  showExportToDataLake?: boolean; 
  customComponentData ?: {
    title: string;
    component: any;
  }
}

export const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
  25,
  50,
  100
];

export const FormatTitle = (title: any) => {
  const formatKey = (key: string) => {
    return key
      ?.replace(/([A-Z])/g, ' $1')
      ?.toLowerCase();
  };

  const titleData = {
    file: title?.file,
    date: formatDate(title?.createdDate),
    status: title?.status,
    client: title?.clientName,
    project: title?.projectName,
    task: title?.taskName,
  };
  return (
    <div className="title-container">
      <div className="title-text">File Viewer</div>
      <div className="title-details">
        {Object?.entries(titleData)?.map(([key, value]) =>
          value ? (
            <div key={key} className="title-detail">
              {`${formatKey(key)}: ${value}`}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};

export const isArrayOfObjects = (data: any): boolean => {
  return Array?.isArray(data) && data?.every((item: any) => typeof item === 'object' && !Array?.isArray(item) && item !== null);
};

export const hasNestedStructure = (data: any): boolean => {
  return isArrayOfObjects(data) && data?.some((item: any) => Object?.values(item)?.some(value => Array?.isArray(value)));
};

export const ExportController = (
  isGridView: string,
  isData: boolean,
  gridData: any,
  extensionType: string,
  data: string,
  isXml: boolean,
  isPdf: boolean,
  title: any
) => {
  if (extensionType === 'txt') {
    if (isData && data) {
      try {
        const textBlob = new Blob([data], { type: 'text/plain' });
        saveAs(textBlob, `${title}.txt`);
      } catch (error) {
        console.error('Error exporting TXT:', error);
      }
    } else {
      console.error('No data available for TXT export.');
    }
  } else if (isGridView && isData) {
    if (isArrayOfObjects(gridData) && !hasNestedStructure(gridData)) {
      try {
        const ws = XLSX?.utils?.json_to_sheet(gridData);
        const wb = XLSX?.utils?.book_new();
        XLSX?.utils?.book_append_sheet(wb, ws, 'Sheet1');
        XLSX?.writeFile(wb, `${title}.xlsx`);
      } catch (error) {
        console.error('Error exporting XLSX:', error);
      }
    } else {
      console.error('Invalid gridData format for XLSX export.');
    }
  } else if (isPdf) {
    try {
      const base64Data = data?.split(',')[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters?.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const pdfBlob = new Blob([byteArray], { type: 'application/pdf' });
      saveAs(pdfBlob, `${title}.pdf`);
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  } else if (isData && data) {
    try {
      if (['json', 'data', 'map'].includes(extensionType)) {
        const jsonBlob = new Blob([data], { type: 'application/json' });
        saveAs(jsonBlob, `${title}.json`);
      } else if (extensionType === 'csv') {
        const csvBlob = new Blob([data], { type: 'text/csv' });
        saveAs(csvBlob, `${title}.csv`);
      }
    } catch (error) {
      console.error('Error exporting JSON, CSV:', error);
    }
  } else if (isXml && data) {
    try {
      const xmlBlob = new Blob([formatXML(data)], { type: 'application/xml' });
      saveAs(xmlBlob, `${title}.xml`);
    } catch (error) {
      console.error('Error exporting XML:', error);
    }
  } else {
    console.warn('No matching export format found.');
  }
};

export const formatXML = (xmlString: string) => {
  const cleanedString = xmlString?.replace(/['"]/g, '');

  const isValidBase64 = (str: string) => {
    const base64Regex = /^(?:[A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
    return base64Regex?.test(str);
  };

  if (!isValidBase64(cleanedString)) {
    return 'Error: The provided XML string is not properly encoded in base64.';
  }

  try {
    const decodedXml = atob(cleanedString);
    const xmlStringCleaned = decodedXml
      ?.replace(/<\?xml[^>]*\?>\s*/, '')
      ?.replace(/rn/g, '');
    return xmlStringCleaned;
  } catch (error) {
    return 'Error occurred during XML formatting';
  }
};

export const transformExecutionStorageData = (data: any) => {
  const categoryData = data || [];
  const result: { id: string; text: string; expanded: boolean; items: any[]; data: any }[] = [
     {
      id: `1`,
      text: "",
      expanded: false,
      items: [],
      data: categoryData
    }
  ];
return result;
};