import "../../../styles/ui-components/ContentExpandCollapse.scss";
import { useCallback } from "react";
import Pluse from "../../../assets/media/plus.png";
import Minus from "../../../assets/media/minus.png";
import PsButton from "../../ui-components/button/Button";
import MasonryLayout from "../../ui-components/MasonryLayout/MasonryLayout";
import { ContentExpandCollapseProps } from "./types";

const ContentExpandCollapse = (props: ContentExpandCollapseProps) => {
  const { cardDetails } = props;
  const plusImg = "/static/media/plus.0f4eeb08ebbfa93539a6.png";

  const handleButtonClick = useCallback((componentId: string) => {
    const container = document.querySelector(`#${componentId}`);
    const img: any = document.querySelector(`#img-${componentId}`);
    if (container && img) {
      const currentSrc = img.src;
      if (currentSrc?.includes(plusImg)) {
        img.src = Minus;
        container.classList.remove("collapsed");
      } else {
        img.src = Pluse;
        container.classList.add("collapsed");
      }
    }
  }, []);

  const renderLeftSideCard = useCallback(() => {
    return cardDetails?.map((item: any) => {
      const {
        title,
        Component: CardComponent, // pass any card component here,
        buttonProps,
        componentId,
        componentProps,
        hasCard,
      } = item;
      const itemName = componentId + "-card";
      return (
        <>
          <div className="content-pannel-header">
            <div className="content-pannel-title">{title}</div>
            {buttonProps && <PsButton {...buttonProps} />}
            <button
              className="expand-collapse"
              onClick={() => handleButtonClick(componentId)}
            >
              <img src={Minus} alt="plus" id={`img-${componentId}`} />
            </button>
          </div>
          <MasonryLayout
            className="card-container"
            id={componentId}
            itemName={itemName}
            hasCard={hasCard}
          >
            {/* pass any card component here */}
            <CardComponent {...componentProps} className={itemName} />
          </MasonryLayout>
        </>
      );
    });
  }, [cardDetails, handleButtonClick]);

  return (
    <div className="contentExpandCollapse">
      <div className="content-pannel">{renderLeftSideCard()}</div>
    </div>
  );
};

export default ContentExpandCollapse;
