import { useCallback, useMemo } from "react";
import PsImageWithTextCard from "../../../components/ui-components/PsImageWithTextCard/ItemCard";
import { DocumentProcessingGuides, DocumentProcessServiceMap } from "../../../config/digital-service/document";
import ContentExpandCollapse from "../../../components/ui-components/content-expand-collapse/ContentExpandCollapse";
import { useNavigate } from "react-router-dom";
import "../../../styles/digital-service/documentprocessing-home.scss";

export const DocumentProcessingHomePage = () => {
  const navigate = useNavigate();

  const onCardClickData = useCallback((e:any) => {
    const { navigation } = e || {};
    navigate(navigation)
  },[navigate]);
  
  const serviceProps = useMemo(() => {
    return {
      cardItems: DocumentProcessServiceMap,
      onCardClick: onCardClickData,
    };
  }, [onCardClickData]);

  const referenceProps = useMemo(() => {
    return {
      cardItems: DocumentProcessingGuides,
      onCardClick: () => {},
    };
  }, []);

  const cardDetails = useMemo(
    () => [
      {
        title: "Services",
        Component: PsImageWithTextCard,
        componentProps: serviceProps,
        componentId: "document-processing-service",
      },
      {
        title: "Quick Start Guides & Reference Library",
        Component: PsImageWithTextCard,
        componentProps: referenceProps,
        componentId: "document-processing-reference",
      },
    ],
    [referenceProps, serviceProps]
  );

  return (
    <>
      <div className="documet-processing-home-page ">
        <div className="jumbotron">
          <div className="jumbotron-content">
            <h1>Document Processing</h1>
            <h3>Transform PDFs into actionable data with AI/ML</h3>
            <p>
              Use the power of artificial intelligence and machine learning to
              seamlessly parse and extract data from PDFs. Accurately identify
              and extract relevant information, turning complex documents into
              structured data. Say goodbye to manual data entry and hello to
              efficiency and accuracy with our AI/ML-driven PDF parsing service.
            </p>
          </div>
        </div>
      </div>
      <ContentExpandCollapse cardDetails={cardDetails} />
    </>
  );
};

export default DocumentProcessingHomePage;
