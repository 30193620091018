// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tickerCostDetails {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(25% - 20px); /* Adjust calc as needed */
  height: 70px;
  background-color: white;
  --gap-padding: 12px;
  border-radius: 8px;
  box-shadow: var(--card-shadow);
  border: none;
  padding: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-weight: 200 !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/finops/techCost-analytics.scss"],"names":[],"mappings":"AAAE;EACE,YAAA;EAAA,cAAA;EAAA,4BAAA,EAAA,0BAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,8BAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,2BAAA;AACJ","sourcesContent":["  .tickerCostDetails {\n    flex: 1 1 calc(25% - 20px); /* Adjust calc as needed */\n    height: 70px;\n    background-color: white;\n    --gap-padding: 12px;    \n    border-radius: 8px;\n    box-shadow: var(--card-shadow);\n    border: none;\n    padding: 16px;\n    margin-bottom: 20px;\n    display: flex;\n    align-items: center;\n    margin-top: 10px;\n    font-weight: 200!important;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
