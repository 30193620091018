import { FileUploader } from "devextreme-react";
import { AddFilesProps } from "../types";
import { useCallback } from "react";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";

const Addfiles = (props: AddFilesProps) => {
  const { handleFilesUpload, isPdfSaving } = props;

  const renderUploadFile = useCallback(() => {
    return (
      <>
        <div className="container-1">
          <h6>Upload, Extract, and Analyze Your Documents with Ease.</h6>
          <article>
            Upload your PDF files to automatically extract key data with our
            powerful Document Analyzer. Once uploaded, the service will quickly
            process the document and extract critical information. You can
            review, edit, and analyze the extracted data. Simply upload your
            document to get started!
            <h5>How is Works</h5>
            <ul>
              <li>Upload your PDF file.</li>
              <li>
                Automatic Data Extraction – Our system will extract key
                information in seconds.
              </li>
              <li>
                Edit and Analyze – Review the data in an editable grid, make any
                necessary changes, and perform your analysis.
              </li>
            </ul>
            <p className="start-info">Ready to start? Upload your document now and let our Document Analyzer do the rest!</p>
          </article>
        </div>
        <div className="container-2">
          {!isPdfSaving ? (
            <FileUploader
              uploadMode="useForm"
              onValueChanged={handleFilesUpload}
              accept={".pdf"}
              allowCanceling={true}
            />
          ) : (
            <PsSkeleton />
          )}
          <span className="note">
            {"Allowed file extensions: "}
            <span>.PDF</span>
          </span>
        </div>
      </>
    );
  }, [handleFilesUpload, isPdfSaving]);

  return (
    <div className="add-file-wrapper height-large">{renderUploadFile()}</div>
  );
};

export default Addfiles;
