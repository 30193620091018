// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opexDashboard {
  display: flex;
  gap: 20px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
}
.opexDashboard .opex-leftPane {
  width: 75%;
}
.opexDashboard .opex-rightPane {
  width: 25%;
}

.opex-dashboard-layout {
  background-color: rgb(255, 255, 255);
  padding: 20px;
  border-radius: 5px;
}

.message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  color: #666;
  height: 70vh;
  max-height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/opexDashboard.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAGI;EACE,UAAA;AADN;AAII;EACE,UAAA;AAFN;;AAME;EACE,oCAAA;EACA,aAAA;EACA,kBAAA;AAHJ;;AAOE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;AAJJ","sourcesContent":["// OpexDashboard.module.scss\n\n.opexDashboard {\n    display: flex;\n    gap: 20px;\n    background-color: white;\n    padding: 15px;\n    border-radius: 5px;\n  \n    .opex-leftPane {\n      width: 75%;\n    }\n  \n    .opex-rightPane {\n      width: 25%;\n    }\n  }\n\n  .opex-dashboard-layout{\n    background-color: rgb(255 255 255);\n    padding: 20px;\n    border-radius: 5px;\n  }\n  \n\n  .message-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    text-align: center;\n    font-size: 20px;\n    color: #666;\n    height: 70vh;\n    max-height: 70vh;\n  }\n  \n  \n  \n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
