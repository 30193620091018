import { TickerCard } from "./techCost-analytics";
import {
  CostAnalysis,
  RevenueAnalysisCard,
} from "../../../../components/finops";

export const TechOpsCost = ({
  cardData,
  sales,
  salesByState,
}: {
  cardData: any;
  sales: any;
  salesByState: any;
}) => {
  return (
    <>
      <div className="techCost compactTile-container">
        {cardData?.map((cardData: any, index: any) => (
          <TickerCard
            key={index}
            title={cardData.designation}
            value={cardData.value}
          />
        ))}
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ flex: 1, marginRight: "10px" }}>
          <CostAnalysis datasource={sales} />
        </div>
        <div style={{ flex: 1 }}>
          <RevenueAnalysisCard datasource={salesByState} />
        </div>
      </div>
    </>
  );
};
