import { BrowserRouter as Router } from "react-router-dom";
import { useScreenSizeClass } from "./utlis/media-query";
import { Content } from "./Content";
import "devexpress-gantt/dist/dx-gantt.css";
import "react-loading-skeleton/dist/skeleton.css";
import { AppProvider } from "./contexts/app";
import "./styles/ui-components/styles.scss";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeContext, useThemeContext } from "./Themes/theme";
import { NavigationProvider } from "./contexts/navigation";
// import io from 'socket.io-client';
import { useEffect, useState } from "react";
import { ErrorProvider } from "./ErrorProvider";
import ErrorBoundaryWrapper from "./ErrorBoundary";
import {GTAPageEventHandler} from "./utlis/GAT-event-handler";
import '@xyflow/react/dist/style.css';
import { PagePropertiesProvider } from "./contexts/pageContext";
import { PermissionProvider } from "./contexts/permissions";
import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 

config({ licenseKey });

// const socket = io('http://127.0.0.1:5000', {
//   transports: ['websocket'],
// });

// socket.on('roleChanged', (data) => {
//   console.log('Role changed:', data);
// });

// socket.on('disconnect', () => {
//   console.log('Disconnected from WebSocket');
// });

const RootApp = () => {
  return (
    <>
      <Content />
    </>
  );
};

function App() {
  const [isRouteChanged, setRouteChanged] = useState(false);
  const screenSizeClass = useScreenSizeClass();
  const themeContext = useThemeContext();
  const location = window.location;

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: isRouteChanged ? 0 : Infinity,
        refetchOnWindowFocus: false,
      },
    },
  });

  useEffect(() => {
    GTAPageEventHandler(location);
  }, [location]);

  useEffect(() => {
    if (location?.pathname) {
      setRouteChanged(true);
    } else {
      setRouteChanged(false);
    }
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Collect all keys that start with "GRID-"
      const keysToRemove = [];
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.startsWith("GRID-")) {
          keysToRemove.push(key);
        }
      }
  
      keysToRemove.forEach((key) => localStorage.removeItem(key));
    };
  
    window.addEventListener("beforeunload", handleBeforeUnload);
  
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <ThemeContext.Provider value={themeContext}>
          <AppProvider>
            <PagePropertiesProvider>
              <NavigationProvider>
                <PermissionProvider>
                  <ErrorProvider>
                    <ErrorBoundaryWrapper>
                      <div className={`app ${screenSizeClass}`}>
                        {themeContext.isLoaded ? <RootApp /> : ""}
                      </div>
                    </ErrorBoundaryWrapper>
                  </ErrorProvider>
                </PermissionProvider>
              </NavigationProvider>
            </PagePropertiesProvider>
          </AppProvider>
        </ThemeContext.Provider>
      </QueryClientProvider>
    </Router>
  );
}

export default App;
