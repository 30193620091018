// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.legend {
  margin-bottom: 20px;
}

.legend-heading {
  margin-bottom: 10px;
}

.legend-items {
  display: flex;
  flex-direction: row;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legend-color {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  background-color: red;
}

.legend-text {
  font-size: 14px;
}

.legend-component {
  display: flex;
  justify-content: center;
  gap: 50px;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/legend.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;AACF;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,iBAAA;EACA,qBAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,aAAA;AACF","sourcesContent":[".legend {\n  margin-bottom: 20px;\n}\n\n.legend-heading {\n  margin-bottom: 10px;\n}\n\n.legend-items {\n  display: flex;\n  flex-direction: row;\n}\n\n.legend-item {\n  display: flex;\n  align-items: center;\n  margin-right: 20px;\n}\n\n.legend-color {\n  width: 20px;\n  height: 20px;\n  margin-right: 5px;\n  background-color: red;\n}\n\n.legend-text {\n  font-size: 14px;\n}\n\n.legend-component{\n  display: flex;\n  justify-content: center;\n  gap:50px;\n  padding: 20px;\n \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
