import DataGrid, {
  Column,
  HeaderFilter,
  Editing,
  Paging
} from "devextreme-react/data-grid";
import { useEffect } from "react";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { useDocumentProcessingStateProvider } from "../../../../../../hooks/datastudio/useDocumentProcessing";
import { getRowStyle } from "../../../../../../utlis/helper";

export const TableView = (props: any) => {
  
  const { table, isLoading, selectedConfidence } = props;

  const {
    state,
    setDocumentProcessingStateData,
  } = useDocumentProcessingStateProvider();

  useEffect(() => {
    setDocumentProcessingStateData(table);
  }, [table]);

  const getColumnNames = (data: Array<Record<string, any>>): string[] => {
    if (data?.length > 0) {
      return Object?.keys(data?.[0]);
    }
    return [];
  };

  const handleEditing = (changes: any) => {
    if (!changes || changes?.length === 0) {
      return;
    }
    const updatedData = state?.documentProcessingStateData?.map((row: any) => {
      const change = changes?.find((c: any) => c?.key === row?.key);
      if (change) {
        return { ...row, ...change?.data };
      }
      return row;
    });
    setDocumentProcessingStateData(updatedData);
  };

  const columns = getColumnNames(table)?.map((columnName, index) => {
    if (columnName !== "S.NO") {
      const isConfidenceColumn = columnName === "confidence";
      return (
        <Column
          key={index}
          dataField={columnName}
          caption={columnName?.replace(/_/g, " ")}
          alignment="left"
          width={300}
          allowEditing={!isConfidenceColumn}
        />
      );
    }
    return null;
  });

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      const confidenceValue = e?.data["confidence"];
      if (confidenceValue !== "" && confidenceValue !== undefined && confidenceValue !== null) {
        const rowStyle = getRowStyle(confidenceValue, selectedConfidence);
        Object?.assign(e?.rowElement?.style, rowStyle);
      }
    }
  };

  if (!table) {
    return <PsSkeleton count={1} height={300} width={300} />;
  }

  return (
    <DataGrid
      className="grid theme-dependent"
      dataSource={state?.documentProcessingStateData}
      onRowUpdating={(e: any) => handleEditing(e?.changes)}
      noDataText={
        isLoading ? "Loading..." : "Pdf Data will be displayed here"
      }
      width={"100%"}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnResizingMode="widget"
      onRowPrepared={onRowPrepared}
    >
      <Editing
        mode="cell"
        allowUpdating={true}
        allowDeleting={false}
        allowAdding={false}
      />
      <HeaderFilter visible={true} />
      {columns}
      <Paging enabled={false} />
    </DataGrid>
  );
};
