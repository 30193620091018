import {
  ClientDashboard,
  ClientCatalog,
  UrlCatalog,
  ClientProfile,
  Projects,
  ProjectProfile,
  ProjectsList,
} from "./pages/client";
import { TasksAutomationPage, InfraStructure, WorkFlow } from "./pages/task";
import {
  ActivityDashboardPage,
  AlertsAndExceptionsPage,
  LogsAndMetrics,
} from "./pages/activity-monitor";
import {
  DataAnalyzer,
  DocumentProcessing,
  DocumentCatalog,
  DocumentAnalyze,
  DataCatalog,
} from "./pages/datastudio";
import {
  RateCardPage,
  FinopsDashboardPage,
  InvoiceDetails,
  ResourceCalculator,
} from "./pages/finops";
import {
  WebScrapingHomepage,
  DocumentProcessingHomePage,
  DataEngineeringHomePage,
  DataLakeManagement,
  TemplateLibrary,
} from "./pages/digital-service";
import { withNavigationWatcher } from "./contexts/navigation";
import { UnderConstruction } from "./components/core-component/under-construction/underConstruction";
import WorkSpaces from "./pages/workbench/WorkSpaces";
import Workbooks from "./pages/workbench/Workbooks";
import WorkBooksDetails from "./pages/workbench/helper-component/details/WorkspacesDetails";
import WorksBookDetailsPage from "./pages/workbench/helper-component/details/WorkBookDetails";
import WorkbookItems from "./pages/workbench/helper-component/Items/WorkbookItems";
import WorkSpaceDashboard from "./pages/workbench/WorkSpaceDashboard";
import { UserAdministration } from "./pages/userAdministration/UserAdministration";
import { ClientTask } from "./pages/task/taskDetails/ClientTask";
import Permission from "./pages/userAdministration/helper-component/permissions/Permission";
import Linecage from "./components/ui-components/react-flow/linecage";
import { ProjectsLinage } from "./pages/project-lineage/ProjectsLineage";

const generateUnderConstructionRoutes = (paths: any) => {
  return paths.map((path: any) => ({
    path,
    element: UnderConstruction,
  }));
};

const underConstructionPaths = [
  "/logs-metrics",
  "/operations-dashboard",
  "/client-operations",
  "/reports-dashboards",
  "/master-data-data-lake",
  "/rpa",
  "/data-analyzer",
  "/visualization-reports",
  "/application-catalog",
  "/application-modernization",
  "/realtime-datahub",
  "/ai-ml-studio",
  "/document-intelligence",
  "/operational-excellence",
];

const routes = [
  {
    path: "/",
    element: WorkSpaces,
  },
  {
    path: "/workspace-dashboard",
    element: WorkSpaceDashboard,
  },
  {
    path: "/workspaces/:workspaceName/:workspaceId",
    element: WorkBooksDetails,
  },
  {
    path: "/my-workbooks",
    element: Workbooks,
  },
  {
    path: "/workbooks/:workbookName/:workbookId",
    element: WorksBookDetailsPage,
  },
  {
    path: "/workbooks/:workbookName/:workbookId/add-items",
    element: WorkbookItems,
  },
  {
    path: "/add-projects",
    element: ProjectsList,
  },
  {
    path: "/client-dashboard",
    element: ClientDashboard,
  },
  {
    path: "/client-profile",
    element: ClientProfile,
  },
  {
    path: "/client-catalog",
    element: ClientCatalog,
  },
  {
    path: "/url-catalog",
    element: UrlCatalog,
  },
  {
    path: "/projects",
    element: Projects,
  },
  {
    path: "/data-lineage",
    element: ProjectsLinage,
  },
  {
    path: "/project-profile",
    element: ProjectProfile,
  },
  {
    path: "/estimator",
    element: ResourceCalculator,
  },
  {
    path: "/task-automation",
    element: TasksAutomationPage,
  },
  {
    path: "/client-task",
    element: ClientTask,
  },
  {
    path: "/infrastructure",
    element: InfraStructure,
  },
  {
    path: "/alert-exceptions",
    element: AlertsAndExceptionsPage,
  },
  {
    path: "/activity-dashboard",
    element: ActivityDashboardPage,
  },
  {
    path: "/template-library",
    element: TemplateLibrary,
  },
  {
    path: "/FinopsDashboard",
    element: FinopsDashboardPage,
  },
  {
    path: "/RateCard",
    element: RateCardPage,
  },
  {
    path: "/ClientInvoice",
    element: InvoiceDetails,
  },
  {
    path: "/document-processing",
    element: DocumentProcessing,
  },
  {
    path: "/document-analyzer",
    element: DocumentAnalyze,
  },
  {
    path: "/data-analyzer",
    element: DataAnalyzer,
  },
  {
    path: "/document-catalog",
    element: DocumentCatalog,
  },
  {
    path: "/data-catalog",
    element: DataCatalog,
  },
  {
    path: "/document-processing-service",
    element: DocumentProcessingHomePage,
  },
  {
    path: "/logs-metrics",
    element: LogsAndMetrics,
  },
  {
    path: "/data-engineering",
    element: DataEngineeringHomePage,
  },
  {
    path: "/web-scraping",
    element: WebScrapingHomepage,
  },
  {
    path: "/user-administration",
    element: UserAdministration,
  },
  {
    path: "/user-administration/:userId/permissions",
    element: Permission,
  },
  {
    path: "/data-lake-management",
    element: DataLakeManagement,
  },
  {
    path: '/line-diagram',
    element: Linecage
  },
  {
    path: "/workflow",
    element: WorkFlow,
  },
  ...generateUnderConstructionRoutes(underConstructionPaths),
];

export const appRoutes = routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
