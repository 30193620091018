// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resource-gird-calculations {
  padding: 10px;
}
.resource-gird-calculations .resource-grid {
  padding-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/finops/resourceGrid.scss"],"names":[],"mappings":"AAGA;EACI,aAAA;AAFJ;AAGI;EACI,iBAAA;AADR","sourcesContent":["\n\n\n.resource-gird-calculations{\n    padding: 10px;\n    .resource-grid{\n        padding-top: 10px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
