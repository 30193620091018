import { useMemo, useState, useCallback } from "react";
import { useEditUserStatus } from "../../hooks/admin/useUserAdministration";
import DataGridMultiViewLayout from "../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import UserDataGrid from "../userAdministration/helper-component/userGrid";
import EditUser from "../userAdministration/helper-component/editUser";
import { useAppHook } from "../../hooks/app/useApps";
import { useApp } from "../../contexts/app";

export const UserAdministration = () => {
  const { mutate: editUserStatus } = useEditUserStatus();
  const [refreshKey, setRefreshKey] = useState(0);
  const [editPopupVisible, setEditPopupVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const { handleColumnIndexChange } = useAppHook();
  const { user } = useApp();
  const userAdmin = user?.userId;

  const onRowUpdated = useCallback(
    (e: any) => {
      const { data } = e;
      editUserStatus({ userId: data?.userId, status: data?.status });
    },
    [editUserStatus]
  );

  const handleRefetch = useCallback(() => {
    setRefreshKey((prevKey) => prevKey + 1);
  }, []);

  const handleEditClick = useCallback(
    (user: any) => {
      const rowData = user?.row?.data;
      setSelectedUser(rowData);
      setEditPopupVisible(true);
    },
    []
  );

  const handleEditClose = useCallback(() => {
    setEditPopupVisible(false);
    setSelectedUser(null);
  }, []);

  const DataGridProps = useMemo(
    () => ({
      handleColumnIndexChange,
      onRowUpdated,
      refreshKey,
      onEditClick: handleEditClick,
      userAdmin,
    }),
    [handleColumnIndexChange, onRowUpdated, refreshKey, handleEditClick, userAdmin]
  );

  const editProps = useMemo(
    () => ({
      editPopupVisible,
      selectedUser,
      onClose: handleEditClose,
      onRefetch: handleRefetch,
    }),
    [editPopupVisible, selectedUser, handleEditClose, handleRefetch]
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: UserDataGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "User Administration",
    refetch: handleRefetch,
    additionalToolbarContent: null,
    pageBodyComponent: PageBodyComponent(),
    isLoading: false,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <EditUser {...editProps} /> 
    </>
  );
};

export default UserAdministration;

