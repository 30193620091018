import { useCallback, useState } from "react";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { OperationsData } from "../../../../../../types/operations-add";
import { useCreateOperations } from "../../../../../../hooks/finops/useRatecard";
import { OperationsEditDetails } from "../../../../../../components/finops/library/operations-form/OperationsEditForm";

const OperationsEditPopup = (props: any) => {
  const { 
    editPopupVisible,
    changeEditPopupVisibility,
    selectedRow,
  } = props;

  const [formData, setData] = useState<OperationsData>();
  const { row} = selectedRow || {};
  const { data } = row || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility()
  },[changeEditPopupVisibility]);

  const { mutate,isLoading } = useCreateOperations(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<OperationsData | undefined>) => {
      setData(data);
    }, []
  );
  if(typeof window === 'undefined' ) return null;
  return (
    <>
      <FormPopup
        title="Operations Role Details"
        visible={editPopupVisible}
        setVisible={changeEditPopupVisibility}
        onSave={handleFormSubmit}
        isLoading={isLoading}
      >
        <OperationsEditDetails
          data={data}
          editing={true}
          onDataChanged={onDataChanged} 
        />  
      </FormPopup>
    </>
  )
}
export default OperationsEditPopup;