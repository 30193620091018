import WorkflowSteps from "./workflowTab";
import { useState } from "react";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import Button from "devextreme-react/button";
import HeaderTab from "./headerTab";
import MetaTab from "./metaTab";
import DataTab from "./dataTab";

const TabView = (props: any) => {
  const { data } = props;

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleNextTab = () => {
    if (activeTab < 3) setActiveTab(activeTab + 1);
  };

  const handlePreviousTab = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  return (
    <div className="workflow-container">
      <TabPanel
        selectedIndex={activeTab}
        onSelectionChanged={(e: any) => setActiveTab(e?.component?.option("selectedIndex"))}
        className="view-tab-panel"
      >
        <TabPanelItem title="Header">
          <HeaderTab data={data} />
        </TabPanelItem>
        <TabPanelItem title="Meta">
          <MetaTab data={data} />
        </TabPanelItem>
        <TabPanelItem title="Workflow">
          <WorkflowSteps data={data} />
        </TabPanelItem>
        <TabPanelItem title="Data">
          <DataTab data={data} />
        </TabPanelItem>
      </TabPanel>
      <div className="footer-actions">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeTab > 0 && (
            <Button
              text="Previous"
              onClick={handlePreviousTab}
              style={{ marginRight: '10px' }}
            />
          )}
          {activeTab < 3 && (
            <Button
              text="Next"
              onClick={handleNextTab}
              style={{ marginRight: '10px' }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default TabView;
