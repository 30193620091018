import React from 'react';
import { TelemetryIPop } from '../Telemetry-Ipop/Telemetry-Ipop';
import '../../../styles/ui-components/Pagination.scss';

interface PaginationProps {
  totalPage: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  TelemetryDescription?: any;
  TelemetryHidden?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({ totalPage, currentPage, onPageChange, TelemetryDescription, TelemetryHidden }) => {
  const getPages = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPage, currentPage + 2);

    if (startPage > 1) {
      pages.push(1);
      if (startPage > 2) {
        pages.push('...');
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (endPage < totalPage) {
      if (endPage < totalPage - 1) {
        pages.push('...');
      }
      pages.push(totalPage);
    }

    return pages;
  };

  const handlePageChange = (page: number | string) => {
    if (page !== '...' && typeof page === 'number') {
      onPageChange(page);
    }
  };

  return (
    <div className="pagination">
      <div className="tele-icon">
        <TelemetryIPop TelemetryDescription={TelemetryDescription} TelemetryHidden={TelemetryHidden} />
      </div>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </button>
      {getPages().map((page, index) => (
        <button
          key={index}
          onClick={() => handlePageChange(page)}
          className={page === currentPage ? 'active' : ''}
          disabled={page === '...'}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPage}
      >
        Next
      </button>
    </div>
  );
};

export default Pagination;
