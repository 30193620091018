import PortfolioRowCard from "./helper-component/tileview";
import SideTileView from "./helper-component/side-tile";
import ClientInfo from "../clientInformation/clientInfoV2";
import "../../../../../styles/client/portfolio.scss";

const PortfolioDashboard = (props: any) => {
  const { apiProps, showToolbar, gridRef, selectedDays,handleColumnIndexChange} = props;

  return (
    <div className="opexDashboard">
      <div className="opex-leftPane">
        <PortfolioRowCard selectedValue={selectedDays} />
        <ClientInfo
          gridRef={gridRef}
          height="height-large"
          apiProps={apiProps}
          showToolbar={showToolbar}
          handleColumnIndexChange={handleColumnIndexChange}
        />
      </div>
      <div className="opex-rightPane">
        <SideTileView selectedDays={selectedDays} />
      </div>
    </div>
  );
};

export default PortfolioDashboard;