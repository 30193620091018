import React, {useEffect, useState } from 'react';
import classNames from 'classnames';
import Form, { SimpleItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { FormTextbox } from '../../../ui-components/form/form-textbox/FormTextbox';
import { getSizeQualifier } from "../../../../utlis/media-query"
import { OperationsData } from '../../../../types/operations-add';

export const OperationsEditDetails = ({ 
    editing, 
    data, 
    onDataChanged 
}: {
  editing: boolean, 
  data: OperationsData, 
  onDataChanged: (data:any) => void
}) => {
  const [formData, setFormData] = useState<OperationsData>({ ...data });

  useEffect(() => {
      setFormData({ ...data });
    }, [data]);

  const updateField = (field: string) => (value: any) => {
    const newData = { ...formData, [field]: value };
    onDataChanged(newData);
    setFormData(newData);
  };
  
  return (
    <Form
      className={classNames({ 'plain-styled-form task-form-details': true, 'view-mode': !editing })}
      screenByWidth={getSizeQualifier}
    >
      <GroupItem itemType='group'>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='Role'
            value={formData.designation}
            isEditing={!editing}
            onValueChange={updateField('designation')}
          />
        </SimpleItem>
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='Role Description'
            value={formData.description}
            isEditing={!editing}
            onValueChange={updateField('description')}
          />
        </SimpleItem>
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='Rate'
            value={formData.cost_per_day}
            isEditing={!editing}
            onValueChange={updateField('cost_per_day')}
          />
        </SimpleItem>
      </GroupItem>
    </Form>
  );
};
