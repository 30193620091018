import React, {
  useEffect,
  createContext,
  useContext,
  useCallback,
  useState,
  ReactNode,
} from "react";
import type { AppContextType } from "../types";
import {
  convertValueToJsonFile,
  decryptText,
  encryptText,  
  getTokenFromHash,
} from "../utlis/helper";
import { useLocation } from "react-router-dom";
import { ID_TOKEN } from "../auth/authConfig";
import {
  useUpdateErrorLog,
  useUpdateUser,
} from "../hooks/admin/useUserAdministration";

type AppProviderProps = {
  children: ReactNode;
};

const specialKey = "***&&&key***";
const storageKey = ["userInfo", "isLoggedIn"];
const AuthContext = createContext<AppContextType>({} as AppContextType);

function AppProvider({ children }: AppProviderProps) {
  const location = useLocation();
  const { data, mutate, isLoading } = useUpdateUser() as any;
  const { mutate: createErrorLogs } = useUpdateErrorLog();
  const [user, setUser] = useState<AppContextType["user"] | null>(null);
  const isAuthenticated = !!localStorage.getItem("isLoggedIn");
  const storedUserInfo = localStorage.getItem("userInfo");
  const status = data?.data;

  useEffect(() => {
    const token = getTokenFromHash(location.hash, ID_TOKEN);
    if (token && !isAuthenticated) {
      mutate({ token });
    } else if (!isAuthenticated) {
      window.location.href = process.env
        .REACT_APP_MICROSOFT_LOGIN_URI as string;
    }
  }, [location.hash, mutate, isAuthenticated]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      const userResponse = data.data[0];
      if (userResponse.status === "Active") {
        const encryptedData = encryptText(userResponse, specialKey);
        localStorage.setItem("userInfo", encryptedData);
        localStorage.setItem("isLoggedIn", "true");
        setUser(userResponse);
      }
    } else if (storedUserInfo && !user) {
      const decryptedData = decryptText(storedUserInfo, specialKey);
      setUser(decryptedData);
    }
  }, [data, storedUserInfo, user]);

  const onErrorLogs = useCallback(() => {
    const error = localStorage.getItem("errors");
    const {id,given_name} = user;
    if (error) {
      const errorData = JSON.parse(error);
      const jsonFile = convertValueToJsonFile(errorData);
      const formData = new FormData();
      formData.append("source", location.pathname);
      formData.append("userId", id);
      formData.append("userName", given_name);
      formData.append("file", jsonFile, 'errorLog.json');
      createErrorLogs(formData);
    }
  }, [createErrorLogs, location.pathname, user]);

  const signOut = useCallback(() => {
    onErrorLogs();
    localStorage.clear();
    window.location.href = process.env.REACT_APP_MICROSOFT_LOGIN_URI as string;
  }, [onErrorLogs]);

  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (storageKey.includes(event.key)) {
        signOut();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [signOut]);

  const value = { user, isAuthenticated, status: status, isLoading, signOut };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useApp = () => useContext(AuthContext);

export { AppProvider, useApp };
