import DataGrid, {
  Column,
  HeaderFilter,
  Editing,
  Paging
} from "devextreme-react/data-grid";
import PsSkeleton from "../../../../../../../../components/ui-components/skeleton/PsSkeleton";
import { getRowStyle } from "../../../../../../../../utlis/helper";

export const TableView = (props: any) => {
  const { dataSource, isLoading, selectedConfidence, rowUpdated } = props;

  const getColumnNames = (data: Array<Record<string, any>>): string[] => {
    if (data?.length > 0) {
      return Object?.keys(data?.[0]);
    }
    return [];
  };

  const columns = getColumnNames(dataSource)?.map((columnName, index) => {
    if (columnName !== "S.NO") {
      const isConfidenceColumn = columnName === "confidence";
      return (
        <Column
          key={index}
          dataField={columnName}
          caption={columnName?.replace(/_/g, " ")}
          alignment="left"
          width={300}
          allowEditing={!isConfidenceColumn}
        />
      );
    }
    return null;
  });

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      const confidenceValue = e?.data["confidence"];
      if (confidenceValue !== "" && confidenceValue !== undefined && confidenceValue !== null) {
        const rowStyle = getRowStyle(confidenceValue, selectedConfidence);
        Object?.assign(e?.rowElement?.style, rowStyle);
      }
    }
  };

  if (!dataSource) {
    return <PsSkeleton count={1} height={300} width={300} />;
  }

  return (
    <DataGrid
      className="grid theme-dependent"
      dataSource={dataSource}
      noDataText={
        isLoading ? "Loading..." : "No analyzed PDF available to display at this time"
      }
      width={"100%"}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnResizingMode="widget"
      onRowUpdated={rowUpdated}
      onRowPrepared={onRowPrepared}
    >
      <Editing
        mode="cell"
        allowUpdating={true}
        allowDeleting={false}
        allowAdding={false}
      />
      <HeaderFilter visible={true} />
      {columns}
      <Paging enabled={false} />
    </DataGrid>
  );
};
