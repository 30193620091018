import { Popover } from "devextreme-react";
import { generateRandomId,truncateString } from '../../../utlis/helper';

const FileViewCard = (props: any) => {
  const { image, title, creater, description ,onClick,date,dataa} = props;
  const randomId = generateRandomId();
  return (
    <div className="workbook-detail-card" onClick={()=>onClick(dataa)}>
      <img className="workbook-detail-card-icon" src={image} alt="" />
      <div className="card-content">
        <div className="workbook-detail-card-title">{title}</div>
        <div className="workbook-detail-card-creator"><span>{creater}</span> <span>{date}</span></div>
        <div className="workbook-detail-card-description">
          <div>{truncateString(description, 145)}</div>
        </div>
        <span className="action-icon"></span>
        <span className="favorite-icon"></span>
        <span className="info-icon" id={randomId}></span>
      </div>
      <Popover
        target={`#${randomId}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        width={200}
      >
        {description}
      </Popover>
    </div>
  );
};

export default FileViewCard;
