import React, { useMemo } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Pager,
  DataGridTypes,
  Button as ActionsButton,
  HeaderFilter,
} from "devextreme-react/data-grid";
import PsAccordion from "../../../components/ui-components/accordion/accordion";
import { CustomItem } from "./helper-component/estimatorComponent";
import { CustomTitle } from "./helper-component/estimatorComponent";
import {
  useEstimator,
  useFetchEstimatorInfo,
} from "../../../hooks/finops/useEstimator";
import EstimatorMapToProject from "./helper-component/estimatorPopUp";
import { CellDollar } from "../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../config/finops/commonConfig";
import '../../../styles/finops/estimator.scss';

const detailRenderTask = (detail: DataGridTypes.MasterDetailTemplateData) => {
  return (
    <>
      <div className="externalPsAccordion">
        <PsAccordion
          dataSource={detail.data?.services}
          Component={CustomItem}
          CustomTitle={CustomTitle}
        />
      </div>
    </>
  );
};

const EstimateInformations = () => {
  const {
    state: { popupVisible, selectedEstimatorData },
    onProjectMapPopupOpen,
    changePopupVisibility,
  } = useEstimator();

  const estimatorProps = useMemo(() => {
    return {
      selectedEstimatorData,
      popupVisible,
      onProjectMapPopupOpen,
      changePopupVisibility,
    };
  }, [
    selectedEstimatorData,
    popupVisible,
    onProjectMapPopupOpen,
    changePopupVisibility,
  ]);

  const { data: clientInfo, isLoading } = useFetchEstimatorInfo();

  return (
    <>
        <DataGrid
          className="grid theme-dependent-estimator"
          dataSource={clientInfo}
          noDataText={"No Estimate  to display at this time"}
          allowColumnReordering
          columnAutoWidth={true}
          showBorders={true}
          allowColumnResizing={true}
        >
          <HeaderFilter visible={true} />
          <Paging defaultPageSize={25} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
          <Column caption="Estimate Name" dataField="name" dataType="string" />
          <Column
            caption="Total Per Month"
            dataField="total_monthly_cost"
            alignment="center"
            dataType="string"
            cellRender={CellDollar}
          />
          <Column caption="" type="buttons" width={160}>
            <ActionsButton
              text="Map To Project"
              cssClass="start-icon-image"
              onClick={onProjectMapPopupOpen}
            />
          </Column>

          <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
        </DataGrid>
        <EstimatorMapToProject {...estimatorProps} />
      </>
  );
};

export default EstimateInformations;
