import EdgeTypesFlow from "../workflow-lineage/data-flow";

const WorkflowSteps = (props: any) => {

  const {
    newWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData
  } = props;

  return (
    <>
      <EdgeTypesFlow
        newWorkflowData={newWorkflowData}
        updateWorkflowField={updateWorkflowField}
        actionData={actionData}
        functionFilterData={functionFilterData}
        typeData={typeData}
      />
    </>
  );
};

export default WorkflowSteps;

