  import FileUploaderComponent from "../../../../components/ui-components/fileUploader/fileUpload";
  import { FileData } from "../../../../components/ui-components/fileUploader/type";
  import { useFetchFileUploadProject } from "../../../../hooks/client/useProjectProfile";
  import { useQueryClient } from "react-query";
  import { useApp } from "../../../../contexts/app";

  const ProjectDataFileUploader = (props: any) => {
    const { popupAddFilesVisible, handleFormPopupClose, projectId } = props;
    const { mutate, isLoading } = useFetchFileUploadProject();
    const queryClient = useQueryClient();
    const { user } = useApp();
    const userName = user?.name || "";
    const userId = user?.userId || "";

    const handleFileUpload = (files: FileData[]) => {
      files.forEach((file) => {
        const payload = {
          projectId,
          projectFiles: [file], 
          uploadedBy: userName,
          userId: userId,
        };
        mutate(payload);
        queryClient.invalidateQueries("fetch-project-data-list");
      });
    };

    return (
      <FileUploaderComponent
        allowedExtensions={[".json", ".pdf", ".csv", ".xml", ".txt"]}
        onUpload={handleFileUpload}
        isLoading={isLoading}
        resetUploaderKey={0}
        popupAddFilesVisible={popupAddFilesVisible}
        handleFormPopupClose={handleFormPopupClose}
      />
    );
  };

  export default ProjectDataFileUploader;