import { useCallback, useMemo, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Panel,
  OnConnect,
  MiniMap,
  Background,
  Controls,
  MarkerType,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { Modal } from "./FlowPopup";

const generateNodesAndEdges = (editWorkflowData: any) => {
  const nodes = [
    {
      id: 'start',
      data: { step: 'Start', label: 'Start' },
      sourcePosition: 'bottom',
      position: { x: 175, y: 150 },
      type: 'circleNode',
    },
    ...editWorkflowData?.map((step: any, index: any) => ({
      id: (index + 1)?.toString(),
      data: {
        step: `Step-${index + 1}`,
        label: `Name: ${step?.name}`,
        description: step?.description,
        additionalInfo: step?.action,
      },
      sourcePosition: index < editWorkflowData?.length - 1 ? "bottom" : undefined,
      targetPosition: "top",
      position: { x: 100, y: (index + 1) * 250 },
      type: "textUpdater",
    })),
    {
      id: 'end',
      data: { step: 'End', label: 'End' },
      targetPosition: 'top',
      position: { x: 175, y: (editWorkflowData?.length + 1) * 250 },
      type: 'circleNode',
    },
  ];

  const edges = [
    {
      id: `start-1`,
      source: 'start',
      target: '1',
      markerEnd: { type: MarkerType?.ArrowClosed },
    },
    ...editWorkflowData?.slice(1)?.map((_: any, index: any) => ({
      id: `${index + 1}-${index + 2}`,
      source: (index + 1)?.toString(),
      target: (index + 2)?.toString(),
      markerEnd: { type: MarkerType?.ArrowClosed },
    })),
    {
      id: `${editWorkflowData?.length}-${'end'}`,
      source: editWorkflowData?.length?.toString(),
      target: 'end',
      markerEnd: { type: MarkerType?.ArrowClosed },
    },
  ];

  return { nodes, edges };
};

const EdgeTypesFlow = (props: any) => {
  const { editWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData, transmitTypeData } = props;

  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => generateNodesAndEdges(editWorkflowData),
    [editWorkflowData]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const [showModal, setShowModal] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);

  const onModalToggle = useCallback(
    (nodeId?: string) => {
      if (nodeId) {
        setCurrentNodeId(nodeId);
      }
      setShowModal(!showModal);
    },
    [showModal]
  );

  const formProps = useMemo(() => ({
    editWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData,
    fileTypeData,
    transmitTypeData
  }), [editWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData, transmitTypeData]);

  const nodeTypes = useMemo(
    () => ({
      textUpdater: (props: any) => (
        <TextUpdaterNode {...props} onClick={() => onModalToggle(props?.id)} nodeElement={'textUpdater'} />
      ),
      circleNode: (props: any) => (
        <TextUpdaterNode {...props} nodeElement={'circleNode'} />
      )
    }),
    [onModalToggle]
  );

  const updateNodeData = (nodeId: string, key: string, value: any) => {
    setNodes((nds) =>
      nds?.map((node) =>
        node?.id === nodeId
          ? { ...node, data: { ...node?.data, [key]: value } }
          : node
      )
    );
  };

  const onAddNode = () => {
    const newNodeId = (nodes?.length - 1)?.toString();
    const lastNode = nodes[nodes?.length - 2];
    const newNodePositionY = lastNode?.position?.y + 250;
    const newNode: any = {
      id: newNodeId,
      data: {
        step: `Step-${newNodeId}`,
        label: `Name: Step-${newNodeId}`,
        description: `This is step ${newNodeId}`,
        additionalInfo: "Additional Info",
      },
      sourcePosition: "bottom",
      targetPosition: "top",
      position: { x: 100, y: newNodePositionY },
      type: "textUpdater",
    };

    const endNodePositionY = newNodePositionY + 250;

    const endNode = {
      id: 'end',
      data: { step: 'End', label: 'End' },
      targetPosition: 'top',
      position: { x: 175, y: endNodePositionY },
      type: 'circleNode',
    };

    const newEdge = {
      id: `${lastNode?.id}-${newNodeId}`,
      source: lastNode?.id,
      target: newNodeId,
      markerEnd: { type: MarkerType?.ArrowClosed }
    };

    const endEdge = {
      id: `${newNodeId}-end`,
      source: newNodeId,
      target: 'end',
      markerEnd: { type: MarkerType?.ArrowClosed }
    };

    setNodes((nds) => [...nds?.slice(0, -1), newNode, endNode]);
    setEdges((eds) => [...eds?.slice(0, -1), newEdge, endEdge]);
  };

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <MiniMap />
          <Background />
          <Controls />
          <Panel>
            <button onClick={onAddNode}>Add</button>
          </Panel>
        </ReactFlow>
        <Modal
          showModal={showModal}
          onModalToggle={onModalToggle}
          formData={formProps}
          nodeId={currentNodeId}
          steps={`Step-${currentNodeId}`}
          updateNodeData={updateNodeData}
        />
      </div>
    </>
  );
};

export default EdgeTypesFlow;
