// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#kanban {
  white-space: nowrap;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}
#kanban .list {
  border-radius: 8px;
  margin: 10px 15px 10px 0;
  background-color: rgba(192, 192, 192, 0.4);
  vertical-align: top;
  white-space: normal;
  width: 400PX;
}
#kanban .list-title {
  font-size: 16px;
  padding: 10px;
  padding-left: 30px;
  margin-bottom: -10px;
  font-weight: bold;
  cursor: pointer;
}
#kanban .sortable-cards {
  min-height: 50vh;
  max-height: 50vh;
  overflow-y: auto;
}
#kanban .card {
  position: relative;
  background-color: white;
  box-sizing: border-box;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}
#kanban .card-subject {
  padding-bottom: 10px;
}
#kanban .card-assignee {
  opacity: 0.6;
}
#kanban .card-priority {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 5px;
  width: 5px;
  border-radius: 2px;
  background: #86c285;
}
#kanban .priority-1 {
  background: #adadad;
}
#kanban .priority-2 {
  background: #86c285;
}
#kanban .priority-3 {
  background: #edc578;
}
#kanban .priority-4 {
  background: #ef7d59;
}
#kanban .list-gap {
  margin-left: 15px;
}`, "",{"version":3,"sources":["webpack://./src/styles/core-components/Kanban.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;EACA,aAAA;EACA,eAAA;EACA,uBAAA;EACA,WAAA;AACF;AAAE;EACE,kBAAA;EACA,wBAAA;EACA,0CAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AAEJ;AACE;EACE,eAAA;EACA,aAAA;EACA,kBAAA;EACA,oBAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACE;EACE,gBAAA;EACA,gBAAA;EACA,gBAAA;AACJ;AAEE;EACE,kBAAA;EACA,uBAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;EACA,eAAA;AAAJ;AAGE;EACE,oBAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,SAAA;EACA,UAAA;EACA,kBAAA;EACA,mBAAA;AAHJ;AAME;EACE,mBAAA;AAJJ;AAOE;EACE,mBAAA;AALJ;AAQE;EACE,mBAAA;AANJ;AASE;EACE,mBAAA;AAPJ;AAUE;EACE,iBAAA;AARJ","sourcesContent":["#kanban {\n  white-space: nowrap;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: center;\n  width: 100%;\n  .list {\n    border-radius: 8px;\n    margin: 10px 15px 10px 0;\n    background-color: rgba(192, 192, 192, 0.4);\n    vertical-align: top;\n    white-space: normal;\n    width: 400PX;\n  }\n\n  .list-title {\n    font-size: 16px;\n    padding: 10px;\n    padding-left: 30px;\n    margin-bottom: -10px;\n    font-weight: bold;\n    cursor: pointer;\n  }\n  .sortable-cards {\n    min-height: 50vh;\n    max-height: 50vh;\n    overflow-y: auto;\n  }\n\n  .card {\n    position: relative;\n    background-color: white;\n    box-sizing: border-box;\n    padding: 10px 20px;\n    margin: 10px;\n    cursor: pointer;\n  }\n\n  .card-subject {\n    padding-bottom: 10px;\n  }\n\n  .card-assignee {\n    opacity: 0.6;\n  }\n\n  .card-priority {\n    position: absolute;\n    top: 10px;\n    bottom: 10px;\n    left: 5px;\n    width: 5px;\n    border-radius: 2px;\n    background: #86c285;\n  }\n\n  .priority-1 {\n    background: #adadad;\n  }\n\n  .priority-2 {\n    background: #86c285;\n  }\n\n  .priority-3 {\n    background: #edc578;\n  }\n\n  .priority-4 {\n    background: #ef7d59;\n  }\n\n  .list-gap {\n    margin-left: 15px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
