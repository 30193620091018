import { useProjectProfile } from "../../../../hooks/client/useProjectProfile";
import TasksGrid from "./tasksGrid";
import TasksStart from "./tasksStart";
import { useMemo } from "react";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import TaskStart from "../../../../assets/icons/icon-task-2.png"

export const TasksTab = () => {
  const {
    state: {
      selectedTask,
      startPopupVisible,
    },
    onStartPopupOpen,
    onStartPopupClose,
  } = useProjectProfile();

  const DataGridProps = useMemo(() => {
    const renderTaskStart = (cell: any) => {
      return (
        <PSIconText
          src={TaskStart}
          alt="Start"
          hint="Start"
          width={28}
          height={28}
          onClick={() => onStartPopupOpen(cell)}
        />
      );
    };
    return {
      onStartPopupOpen,
      renderTaskStart,
    };
  }, [onStartPopupOpen]);

  const startProps = useMemo(() => {
    return {
      selectedTask,
      startPopupVisible,
      onStartPopupClose,
    };
  }, [selectedTask, startPopupVisible, onStartPopupClose]);

  return (
    <div >
      <TasksGrid {...DataGridProps} />
      <TasksStart {...startProps} />
    </div>
  );
};
