import { useCallback, useState } from "react";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { InfrastructureData } from "../../../../../../types/infrastructure-add";
import { InfrastructureEditDetails } from "../../../../../../components/finops/library/infrastructure-form/InfrastructureEditForm";
import { useCreateInfrastructure } from "../../../../../../hooks/finops/useRatecard";

const InfrastructureEditPopup = (props: any) => {
  const {
    editPopupVisible,
    changeEditPopupVisibility,
    selectedRow,
  } = props;

  const [formData, setData] = useState<InfrastructureData>();
  const { row } = selectedRow || {};
  const { data } = row || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility();
  }, [changeEditPopupVisibility]);

  const { mutate, isLoading } = useCreateInfrastructure(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<InfrastructureData | undefined>) => {
      setData(data);
    },
    []
  );
  if (typeof window === "undefined") return null;
  return (
    <>
      <FormPopup
        title="Infrastructure Resource Details"
        visible={editPopupVisible}
        setVisible={changeEditPopupVisibility}
        onSave={handleFormSubmit}
        isLoading={isLoading}
      >
        <InfrastructureEditDetails
          data={data}
          editing={true}
          onDataChanged={onDataChanged}
        />
      </FormPopup>
    </>
  );
};
export default InfrastructureEditPopup;
