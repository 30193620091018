import DataGrid, { Column, MasterDetail } from "devextreme-react/data-grid";
import AccessList from "./helper-component/AccessList";

const ServiceLsit = [
  {
    id: 1,
    name: "Client",
    key: "client",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
  {
    id: 2,
    name: "Data Studio",
    key: "dataStudio",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
  {
    id: 3,
    name: "Digital Servcie",
    key: "digitalService",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
  {
    id: 4,
    name: "Activity Monitor",
    key: "activityMonitor",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
  {
    id: 5,
    name: "Finops",
    key: "finops",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
  {
    id: 6,
    name: "Task Automation",
    key: "taskAutomation",
    roles: {
      admin: {
        isActive: true,
        Value: "Admin",
      },
      reader: {
        isActive: true,
        Value: "Reader",
      },
      contributor: {
        isActive: true,
        Value: "Contributor",
      },
    },
  },
];

const Permission = () => {
  return (
    <DataGrid
      className={`grid theme-dependent height-large`}
      dataSource={ServiceLsit}
      remoteOperations={true}
      showBorders={true}
      id="gridContainer"
      allowColumnResizing={true}
      allowColumnReordering={true}
      repaintChangesOnly={true}
      noDataText={"No projects to display at this time"}
    >
      <Column caption="Service" dataField="name" dataType="string" />
      <MasterDetail enabled component={AccessList} />
    </DataGrid>
  );
};

export default Permission;
