import { Item } from "devextreme-react/toolbar";
import { useMemo, useState } from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import { dropDownOptions } from "../../../config/constants";
import "../../../styles/client/project-form.scss";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { useNavigate } from "react-router-dom";
import PortfolioDashboard from "./helper-component/portfolio-dashboard/portfolio";
import {
  useFetchopexCard,
  useProjects,
} from "../../../hooks/client/useProject";
import ClientInfo from "./helper-component/clientInformation/clientInfoV2";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import ShareUsers from "../../../assets/images/icons/icon-add-users-1.png";
import collabration from "../../../assets/images/icons/icon.collabration.png";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import OpexDashboard from "./helper-component/opex-dashboard/opexDashboard";
import ManagementDashboard from "./helper-component/management-dashboard/management";
import { useAppHook } from "../../../hooks/app/useApps";
import { projectViewDropdown } from "../../../config/client/commonConfig";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { fetchProjectApi } from "../../../service/client/api-service";
import { usePage } from "../../../contexts/pageContext";

export const Projects = (props: any) => {
  const { showToolbar, height, filteredClientId } = props;
  const { data: DateRange } = useFetchDateRange();
  const [refreshKey, setRefreshKey] = useState(0);
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};

  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
    projetView: p_projetView,
   
  } = filterValue || {};

  const {
    state: { projetView },
    filterByProjectView,
  } = useProjects();
  const {
    state: utilsState,
    state: { selectedDays },
    clientDropDownData,
    projectDropDownData,
    filterByClient,
    filterByProject,
    gridRef,
    handleDropDownPageIndex,
    handleDateRangeChange,
    handleColumnIndexChange,
  } = useAppHook();

  const { client, opexParams } = utilsState;

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      clientId: p_clientId ? p_clientId : filteredClientId,
      projectId: p_projectId,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [filterValue, filteredClientId, p_clientId, p_projectId]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchProjectApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const navigate = useNavigate();

  const {
    data: opexCardData,
    isSuccess,
    isLoading: isOpexLoading,
  } = useFetchopexCard(opexParams);

  const currentView = projectViewDropdown.find(
    (item) => item.value === projetView
  )?.name;

  const AdditionalToolbarContent = useMemo(
    () => (
      <Item
        location="after"
        locateInMenu="auto"
        cssClass="toolbar-item-flex toolbar-item-label-buton"
      >
        <DropDownButton
          items={clientDropDownData || ["Loading..."]}
          stylingMode="text"
          text={p_clientName ? p_clientName : "All Clients"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
          stylingMode="text"
          text={p_projectName ? p_projectName : "All Projects"}
          displayExpr="projectName"
          dropDownOptions={dropDownOptions}
          items={projectDropDownData}
          useSelectMode
          onSelectionChanged={filterByProject}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
          items={projectViewDropdown}
          stylingMode="text"
          displayExpr={"name"}
          text={p_projetView ? p_projetView : currentView}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByProjectView}
        />
        <DateDropDown
          onDateChange={handleDateRangeChange}
          data={DateRange}
          handleDropDownPageIndex={handleDropDownPageIndex}
        />
        <PSIconText
          text="New Project"
          src={NewIcon}
          alt="New Project"
          onClick={() => navigate("/add-projects")}
          eventName="New Project Clicked"
        />
        <PSIconText
          text="Share"
          alt="Project-Share item with users"
          src={ShareUsers}
          onClick={() => onclick}
          eventName="Project-Share item with users"
        />
        <PSIconText
          alt="Collaboration"
          text="Collaboration"
          src={collabration}
          onClick={() => onclick}
          eventName="Project-Collaboration"
        />
      </Item>
    ),
    [clientDropDownData,p_clientName,filterByClient,handleDropDownPageIndex,p_projectName,projectDropDownData,filterByProject,p_projetView,
      currentView,
      filterByProjectView,
      handleDateRangeChange,
      DateRange,
      navigate,
    ]
  );

  const DataGridProps = useMemo(() => {
    return {
      selectedClient: client,
      showToolbar: false,
      selectedDays,
      gridRef,
      height: height ? height : "height-large",
      searchvisible: true,
      additionalParams,
      apiProps,
      refreshKey,
      handleColumnIndexChange,
     
    };
  }, [
    client,
    selectedDays,
    gridRef,
    height,
    additionalParams,
    apiProps,
    refreshKey,
    handleColumnIndexChange,
  

  ]);

  
  const PageBodyComponent = useMemo(
    () => [
      {
        mode: "grid",
        Component: ClientInfo,
        componentProps: DataGridProps,
      },
      {
        mode: "opex",
        Component: OpexDashboard,
        componentProps: {
          opexCardData: opexCardData,
          isOpexLoading,
          isSuccess,
          client,
        },
      },
      {
        mode: "management",
        Component: ManagementDashboard,
        componentProps: DataGridProps,
      },
      {
        mode: "portfolio",
        Component: PortfolioDashboard,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps, opexCardData, isOpexLoading, isSuccess, client]
  );

  const layoutProps = {
    headerText: "Projects",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent,
    layoutView: projetView,
    isLoading: false,
    showToolbar,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
};