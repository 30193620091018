import { useEffect, useState, useMemo } from "react";
import PsDataGrid from "../../../../../../components/ui-components/dataGrid/DataGrid";
import Bullet, { Size, Tooltip } from "devextreme-react/bullet";
import { ContactStatus } from "../../../../../../components/ui-components/contact-status/ContactStatus";

export function CustomTitle(data: any) {
  return <div className="header">{data?.title}</div>;
}

export function CustomItem(data: any) {
  const [maxAmount, setMaxAmount] = useState(0);

  useEffect(() => {
    const allAmounts = [
      ...(data.digitalServices || []).map(
        (item: any) =>
          parseFloat(item.cost_per_day.replace(/[^0-9.-]+/g, "")) || 0
      ),
      ...(data.operations || []).map(
        (item: any) =>
          parseFloat(item.cost_per_day.replace(/[^0-9.-]+/g, "")) || 0
      ),
      ...(data.infrastructure || []).map(
        (item: any) =>
          parseFloat(item.cost_per_day.replace(/[^0-9.-]+/g, "")) || 0
      ),
    ];
    setMaxAmount(Math.max(...allAmounts));
  }, [data]);

  const bulletCellRender = (cellInfo: any) => {
    const amount = parseFloat(cellInfo.value.replace(/[^0-9.-]+/g, "")) || 0;
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px" }}>{cellInfo.value}</span>
        <Bullet value={amount} startScaleValue={0} endScaleValue={maxAmount}>
          <Tooltip enabled={false} />
          <Size width={150} height={20} />
        </Bullet>
      </div>
    );
  };

  const formatDate = (date: string) => {
    if (!date) return "";
    const formattedDate = new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    return formattedDate;
  };

  const pageProps = useMemo(
    () => ({
      nodataText: "No Recent Details to display at this time",
      id: "managementresourcegrid",
    }),
    []
  );

  const getColumnsForService = (serviceType: string) => {
    const roleField =
      serviceType === "infrastructure" ? "resource" : "designation";
    return [
      {
        dataField: roleField,
        caption: "Role",
      },
      {
        dataField: "cost_per_day",
        caption: "Value",
        cellRender: bulletCellRender,
      },
      {
        dataField: "status",
        caption: "Status",
        cellRender: ContactStatus,
      },
      {
        dataField: "startDate",
        caption: "Start Date",
        cellRender: (data: any) => formatDate(data?.value),
      },
      {
        dataField: "endDate",
        caption: "End Date",
        cellRender: (data: any) => formatDate(data?.value),
      },
    ];
  };

  return (
    <div>
      {["digitalServices", "operations", "infrastructure"]?.map(
        (service) =>
          data[service] && (
            <PsDataGrid
              key={service}
              pageProps={pageProps}
              gridProps={{
                keyExpr:
                  service === "infrastructure" ? "resource" : "designation",
                dataSource: data[service],
                columns: getColumnsForService(service),
                pagingEnabled: false,
                pagerEnabled: false,
              }}
            />
          )
      )}
    </div>
  );
}
