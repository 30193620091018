import HtmlEditor from "devextreme-react/html-editor";
import { getPreviewRowStyle } from "../../../../../../utlis/helper";
import { confidenceBG } from "../../../../../../config/datastudio/confidence";
import { restrictColumns } from "../../../../../../config/datastudio/commonConfig";

export const DefaultView = (props: any) => {
  const { data, selectedConfidence } = props;

  const capitalizeHeading = (text: string): string => {
    if (!text) return "";
    let result = text?.replace(/([A-Z])/g, " $1");
    result = result?.replace(/[-_]/g, " ");
    return result
      ?.split(" ")
      ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
      ?.join(" ")
      ?.trim();
  };

  const generateTable = (rows: any[]): string => {
    if (!Array?.isArray(rows) || rows?.length === 0) return "";

    const headers = Object?.keys(rows?.[0] || {});

    return `
      <table style="width: 100%; border-collapse: collapse; margin-top: 30px; font-family: Arial, sans-serif;">
        <tr style="background-color: #f5f5f5;">
          ${headers?.map(header => `<th style="border: 1px solid #ddd; padding: 10px; text-align: left;">${header?.replace(/-/g, " ")}</th>`)?.join("")}
        </tr>
        ${rows?.map(row => {
          const confidenceValue = row["confidence"];
          const qualityIndex = row["qualityIndex"];
          let backgroundColor = qualityIndex === 0 ? confidenceBG : "transparent";
          if (confidenceValue) {
            const rowStyle = getPreviewRowStyle(confidenceValue, selectedConfidence);
            backgroundColor = rowStyle?.backgroundColor || backgroundColor;
          }
          return `
            <tr style="background-color: ${backgroundColor};">
              ${headers?.map(header => `<td style="border: 1px solid #ddd; padding: 8px;">${row[header] ?? ""}</td>`)?.join("")}
            </tr>
          `;
        })?.join("")}
      </table>
    `;
  };

  const htmlConversion = (data: any): string => {
    if (!data || Object?.keys(data)?.length === 0) {
      return `<h5 style="font-family: Arial, sans-serif; text-align: center">No data to show</h5>`;
    }

    let htmlContent = "";

    const documentDetails = Object?.entries(data)?.filter(([key, value]) => (typeof value !== "object" || value === null) && !restrictColumns?.includes(key));

    if (documentDetails?.length > 0) {
      const qualityInfo = `
         <table style="width: 100%; border-collapse: collapse; padding: 30px; font-family: Arial, sans-serif;">
            ${documentDetails?.map(([key, value]) => `
              <tr>
                <td style="text-align: left; border: 1px solid #ddd; padding: 10px; font-weight: bold;">${capitalizeHeading(key)}</td>
                <td style="text-align: left; border: 1px solid #ddd; padding: 10px;">${value ?? "N/A"}</td>
              </tr>
            `)?.join("")}
          </table>
      `;
      htmlContent += qualityInfo;
    }

    for (const key in data) {
      if (Array?.isArray(data[key])) {
        htmlContent += `<h5 style='margin-top: 25px; font-family: Arial, sans-serif;'>${capitalizeHeading(key)}</h5>`;
        htmlContent += generateTable(data[key]);
      }
    }

    return htmlContent;
  };

  const htmlData = htmlConversion(data || {});

  return (
    <HtmlEditor
      height={"70vh"}
      width={"100%"}
      value={htmlData}
      readOnly={true}
    />
  );
};