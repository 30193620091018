import { RequiredRule } from "devextreme-react/form";
import { FormTextbox } from "../../../../../../components/ui-components/form/form-textbox/FormTextbox";
import SelectBox from "devextreme-react/select-box";
import { simpleProductLabel } from "../../../../../../config/constants";
import { Validator } from "devextreme-react";

const HeaderTab = (props: any) => {

  const { newHeaderData, updateHeaderField, clientData, projectData, onClientOptionChanged, onProjectOptionChanged } = props;

  return (
    <>
      <div className="dx-field">
        <div className="dx-field-label">Workflow Name</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Workflow Name..."
            value={newHeaderData?.workflowName}
            isEditing={false}
            onValueChange={updateHeaderField("workflowName")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Description</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Description..."
            value={newHeaderData?.description}
            isEditing={false}
            onValueChange={updateHeaderField("description")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Version</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Version..."
            value={newHeaderData?.version}
            isEditing={false}
            onValueChange={updateHeaderField("version")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Activity</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Activity..."
            value={newHeaderData?.activity}
            isEditing={false}
            onValueChange={updateHeaderField("activity")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Type</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Type..."
            value={newHeaderData?.type}
            isEditing={false}
            onValueChange={updateHeaderField("type")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Application Name</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Application Name..."
            value={newHeaderData?.applicationName}
            isEditing={false}
            onValueChange={updateHeaderField("applicationName")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Client Name</div>
        <div className="dx-field-value">
          <SelectBox
            dataSource={clientData}
            displayExpr="name"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select a Client..."
            onValueChange={(event: any) => onClientOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </div>
      </div >
      <div className="dx-field">
        <div className="dx-field-label">Project Name</div>
        <div className="dx-field-value">
          <SelectBox
            dataSource={projectData}
            displayExpr="projectName"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select a Project..."
            onValueChange={(event: any) => onProjectOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Service Name</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Service Name..."
            value={newHeaderData?.serviceName}
            isEditing={false}
            onValueChange={updateHeaderField("serviceName")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Module Id</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Module Id..."
            value={newHeaderData?.moduleId}
            isEditing={false}
            onValueChange={updateHeaderField("moduleId")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Source</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Source..."
            value={newHeaderData?.source}
            isEditing={false}
            onValueChange={updateHeaderField("source")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Target</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Target..."
            value={newHeaderData?.target}
            isEditing={false}
            onValueChange={updateHeaderField("target")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Priority</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Priority..."
            value={newHeaderData?.priority}
            isEditing={false}
            onValueChange={updateHeaderField("priority")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Severity</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Severity..."
            value={newHeaderData?.severity}
            isEditing={false}
            onValueChange={updateHeaderField("severity")}
          />
        </div>
      </div>
    </>
  );
}

export default HeaderTab;