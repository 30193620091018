import { generateRandomId, truncateString } from "../../../utlis/helper";
import "../../../styles/ui-components/WorkbenchCards.scss";
import { Popover } from "devextreme-react/popover";

const WorkbenchUICards = (props: any) => {
  const { image, title, creater, description, date, onClick, className } =
    props;
  const randomId = generateRandomId();
  return (
    <div className={`workspace-card ${className}`} onClick={onClick}>
      <img className="card-icon" src={image} alt="" />
      <div className="card-content">
        <div className="workspace-card-title">{title}</div>
        <div className="workspace-card-creator">
          <span>{creater}</span> <span>{date}</span>
        </div>
        <div className="workspace-card-description">
          <div>{truncateString(description, 110)}</div>
        </div>
        <span className="action-icon"></span>
        <span className="favorite-icon"></span>
        <span className="info-icon" id={randomId}></span>
      </div>
      <Popover
        target={`#${randomId}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="top"
        width={200}
      >
        {description}
      </Popover>
    </div>
  );
};

export default WorkbenchUICards;
