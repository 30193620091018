// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* RowTile.css */
.opex-row-card {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  padding-bottom: 20px;
}

.opex-row-card > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(25% - 20px);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .opex-row-card > * {
    flex: 1 1 100%;
  }
}
.opex-column-card {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 20px;
}

.opex-column-card > * {
  flex: 1 1 auto;
  box-sizing: border-box;
}

.pivotGrid-Drop-down {
  display: flex;
  justify-content: flex-end;
  margin: 15px;
  margin-bottom: 40px;
}

.pivotGrid-label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/opexStyles.scss"],"names":[],"mappings":"AAAA,gBAAA;AAEA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,oBAAA;AAAF;;AAGA;EACE,YAAA;EAAA,cAAA;EAAA,4BAAA;EACA,sBAAA;AAAF;;AAGA;EACE;IACE,cAAA;EAAF;AACF;AAIE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,oBAAA;AAFJ;;AAKE;EACE,cAAA;EACA,sBAAA;AAFJ;;AAKE;EACE,aAAA;EACA,yBAAA;EACA,YAAA;EACA,mBAAA;AAFJ;;AAME;EACE,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;AAHJ","sourcesContent":["/* RowTile.css */\n\n.opex-row-card {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 20px;\n  padding-bottom: 20px;\n}\n\n.opex-row-card > * {\n  flex: 1 1 calc(25% - 20px);\n  box-sizing: border-box;\n}\n\n@media (max-width: 768px) {\n  .opex-row-card > * {\n    flex: 1 1 100%; \n  }\n}\n\n  \n  .opex-column-card {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    padding-bottom: 20px;\n  }\n  \n  .opex-column-card > * {\n    flex: 1 1 auto;\n    box-sizing: border-box;\n  }\n\n  .pivotGrid-Drop-down{\n    display: flex;\n    justify-content: flex-end;\n    margin: 15px;\n    margin-bottom: 40px;\n    \n  }\n\n  .pivotGrid-label {\n    font-size: 14px; \n    font-weight: 600;\n    margin-bottom:10px;\n    margin-top:6px;\n  }\n\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
