import InvoiceInformations from "../../../core-component/invoiceComponent/invoiceInformation";
import { useFetchInvoiceInfo } from "../../../../hooks/app/useApps";
import { useHelperHooks } from "../../../../hooks/helper/utils";


const FinopsTab = () => {
  const clientId = useHelperHooks().getQueryParams("clientId");
  const { data: invoiceData, isLoading } = useFetchInvoiceInfo(clientId);

  return (
    <>
      <InvoiceInformations clientInfo={invoiceData} isLoading={isLoading} />
    </>
  );
};

export default FinopsTab;
