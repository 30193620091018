import React from 'react';
import "../../../styles/ui-components/legend.scss";

interface LegendProps {
  items: { color: string; text: string }[];
}

const Legend = ({ items }: LegendProps) => (
  <div className="legend-component">
    <div> Legend </div>
    {items.map((item, index) => (
      <div className="legend-item" key={index}>
        <div className="legend-color" style={{ backgroundColor: item.color }} />
        <div className="legend-text">{item.text}</div>
      </div>
    ))}
  </div>
);

export default Legend;