import {
  createDocumentAnalyze,
  fetchAnalyzeDocumentApi,
  fetchDocumentAnalyzerProcessApi,
  fetchDocumentAnalyzerPDFApi,
  fetchDocumentAnalyzeTelemetry,
  fetchRecenAnalyseDataApi,
  updateDocumentAnalyzerApi,
  PdfEditDocumentAnalyze,
  fetchDocumentInfoApi,
  fetchDocumentMetricsInfoApi,
} from "../../service/datastudio/api-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatDate } from "devextreme/localization";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useApp } from "../../contexts/app";
import { exportToCSV, formatFileSize } from "../../utlis/helper";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { useAppHook, useFetchFunctionDropDownData } from "../app/useApps";
import { useHelperHooks } from "../helper/utils";
import { customDateRange } from "../../config/constants";

export const initialState = {
  uploadData: "",
  rowTitle: {},
  rowGrid: "",
  selectedConfidence: null,
  confidenceValue: "",
  pdfPage: 1,
  pdfFinalData: {},
  metricsData: {},
  telemetryDatas: {},
  tabView: {
    addFiles: {
      type: "addFiles",
      toggle: true,
    },
  },
};

export const reducer = (state: any, action: any) => {
  switch (action?.type) {
    case "SET_UPLOAD_DATA":
      return { ...state, uploadData: action?.payload };
    case "ROW_CLICK_GRID":
      return { ...state, rowGrid: action?.payload };
    case "SET_ROW_TITLE":
      return { ...state, rowTitle: action?.payload };
    case "SET_TAB_VIEW":
      return {
        ...state,
        tabView: {
          [action.payload.name]: {
            type: action.payload.name,
            toggle: action.payload.toggle,
          },
        },
      };
    case "SET_EDITING_DATA":
      return { ...state, isRowUpdated: action?.payload };
    case "SET_SELECTED_CONFIDENCE":
      return { ...state, selectedConfidence: action?.payload };
    case "SET_CONFIDENCE_VALUE":
      return { ...state, confidenceValue: action?.payload };
    case "SET_PDF_PAGE":
      return { ...state, pdfPage: action?.payload };
    case "SET_PDF_FINAL_DATA":
      return { ...state, pdfFinalData: action?.payload };
    case "SET_METRICS_DATA":
      return { ...state, metricsData: action?.payload };
    case "SET_TELEMETRY_DATA":
      return { ...state, telemetryDatas: action?.payload };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDocumentAnalyze = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    user: { userId, name },
  } = useApp();
  const dataCatalog_userId = useHelperHooks().getQueryParams("userId");
  const dataCatalog_fileName = useHelperHooks().getQueryParams("fileName");
  const dataCatalog_dataCatalogId = useHelperHooks().getQueryParams("dataCatalogId");
  const dataCatalog_processType = useHelperHooks().getQueryParams("processType");
  const dataCatalog_status = useHelperHooks().getQueryParams("status");

  const user_name = name;

  const [selectedDays, setSelectedDays] = useState<any>(null);
  
  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod ] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
      }
      else {
        setSelectedDays({ timePeriod });
      }
    },
    [setSelectedDays]
  );

  const {
     state : {
      pageSize,
      pageIndex,
      isNextPage,
      sortOrder,
      sortColumn
     },
     handleDropDownPageIndex,
     handlePageIndexChange,
  } = useAppHook();

  const {
    isSuccess: hasFileUploaded,
    mutate: saveAnalyzePdf,
    data: createdData,
    isLoading: isPdfSaving,
  } = useCreateDocumentAnalyze();
  const parentData = (createdData as { data: any })?.data?.[0];

  const {
    mutate: saveEditAnalyzePdf,
    data: createdEditData,
    isSuccess: isEditSuccess
  } = useCreatePdfEditDocumentAnalyze();
  const childData = (createdEditData as { data: any })?.data?.[0];

  const [isInterval, setIsInterval] = useState(true);
  const [shouldFetchAnalyzeData, setShouldFetchAnalyzeData] = useState(false);

  const { mutate: updateMutate, isLoading: isFileSaveLoading } =
    useUpdateDocumentAnalyzer();

  const {
    data: processData,
    isLoading: isAnalyzing,
    refetch: fetchAnalyzeDocument,
  } = useFetchDocumentAnalyzerProcessApi(state?.uploadData || state?.rowGrid);

  const { data: analyzedData, isLoading, refetch: startRefetch, isFetching } = useFetchDocumentAnalyzeApi(
    processData || state.uploadData, shouldFetchAnalyzeData
  );

  const { data: telemetryData, refetch: telemetryRefetch } = useFetchDocumentAnalyzeTelemetry(
    processData?.[0]?.dataCatalogId || state?.rowGrid?.dataCatalogId,
    isInterval,
    shouldFetchAnalyzeData
  );

  const pdfData = useFetchDocumentAnalyzerPDFApi(state.uploadData);

  const { data: DocumentData, 
    refetch: documentRefetch, 
    isLoading: documentLoading } = useFetchRecentDataGrid(
      selectedDays,
      pageIndex,
      pageSize,
      sortColumn,
      sortOrder,
      isNextPage,
    );

  const { data: fetchFunctionDropDownFeatures } = useFetchFunctionDropDownData("dropdown");
  const confidenceData = fetchFunctionDropDownFeatures?.confidenceDropdown || [];

  useEffect(() => {
    if (telemetryData?.executionStatus === "Completed") {
      setIsInterval(false);
      setShouldFetchAnalyzeData(false);
    }
  }, [telemetryData]);

  const handleShowAnalyzedData = () => {
    dispatch({
      type: "SET_TAB_VIEW",
      payload: {
        name: "resultGrid",
        toggle: !state.tabView["resultGrid"]?.toggle,
      },
    });
  };

  const handleAnalyzeDocument = () => {
    fetchAnalyzeDocument();
    telemetryRefetch();
    setIsInterval(true);
    setShouldFetchAnalyzeData(true);
  };

  const confidenceSelectBoxRef = useRef<any>(null);
  const onTabChange = useCallback(
    (name: string) => {
      dispatch({ type: "CLEAR" });
      dispatch({
        type: "SET_TAB_VIEW",
        payload: {
          name: name,
          toggle: !state.tabView[name]?.toggle,
        },
      });
      confidenceSelectBoxRef?.current?.instance?.reset();
    },
    [state.tabView]
  );

  useEffect(() => {
    if (isEditSuccess) {
      onTabChange("myFiles");
    }
  }, [isEditSuccess]);

  const handleFilesUpload = async (e: any) => {
    const files = e.value;
    try {
      dispatch({ type: "RESET_DATA" });
      const encodedFiles = await Promise.all(
        files?.map((file: any) => {
          const reader = new FileReader();
          return new Promise((resolve, reject) => {
            reader.readAsDataURL(file);
            reader.onload = () => {
              const timestamp = formatDate(new Date(), "yyyyMMddHHmm");
              const fileExtension = file?.name?.split(".").pop();
              const renamedFile = `${file?.name
                ?.split(".")
                ?.slice(0, -1)
                ?.join(".")}_${timestamp}.${file?.name?.split(".")?.pop()}`;
              const fileSize = formatFileSize(file?.size);
              resolve({
                name: renamedFile,
                content: reader?.result,
                extension: fileExtension,
                size: fileSize,
                originalFileName: file?.name,

              });
            };
            reader.onerror = (error) => reject(error);
          });
        })
      );
      const { name, content, extension, size } = encodedFiles[0] || {};
      const params = { fileName: name, userId: userId, userName: user_name };
      dispatch({ type: "SET_UPLOAD_DATA", payload: { ...params, content, size } });
      handleSave(encodedFiles, extension, size);
    } catch (error) {
      showNotification({
        message: "File upload failed. Please try again.",
        type: "error",
      });
    }
  };

  const handleSave = (files: any, fileExtension: string, size: any) => {
    const payload = {
      userName: user_name,
      userId: userId,
      fileName: files,
      fileSize: size,
      assetType: fileExtension,
      processType: "Document Analyzer",
      created_by: userId,
      created_at: "",
    };
    saveAnalyzePdf(payload, {
      onSuccess: () => {
        handleShowAnalyzedData();
      },
    });
  };

  const handleSaveDocument = () => {
    const queryParams = {
      dataCatalogId:
        processData?.[0]?.dataCatalogId || state.uploadData?.dataCatalogId,
    };
    updateMutate({ queryParams, payload: analyzedData });
  };

  const handleItemClick = (e: any) => {
    const item = e?.itemData;
    if (item?.text === "Download") {
      const fileName = processData?.[0]?.fileName || state?.rowGrid?.fileName;

      const ExportData: any[] = [];
      analyzedData &&
        Object?.keys(analyzedData)?.forEach((pageKey) => {
          if (Array?.isArray(analyzedData[pageKey])) {
            analyzedData[pageKey]?.forEach((entry: any) => {
              if (entry?.content && Array?.isArray(entry?.content)) {
                entry?.content?.forEach((row: any) => ExportData?.push(row));
              } else if (entry?.content) {
                ExportData?.push(entry?.content);
              }
            });
          }
        });

      exportToCSV(ExportData, `${fileName}.csv`);
    } else if (item?.text === "Export to Project") {
    }
  };

  const handleRowClick = (e: any) => {
    const { fileName, dataCatalogId, status, userId, userName, referencedataCatalogId,processType } = e?.data || {};
    const rowPdfParams = {
      fileName,
      userId: userId,
      dataCatalogId,
    };
    const rowGridParams = {
      fileName: fileName,
      dataCatalogId: dataCatalogId,
      parentId: referencedataCatalogId,
      status: status,
      userId: userId,
      userName: userName,
      processType:processType,
    };
    const rowTitle = { file: fileName, status: status }
    dispatch({ type: "ROW_CLICK_GRID", payload: rowGridParams });
    dispatch({ type: "SET_UPLOAD_DATA", payload: rowPdfParams });
    dispatch({ type: "SET_ROW_TITLE", payload: rowTitle });
    handleShowAnalyzedData();
    if (status === "Uploaded") {
      setShouldFetchAnalyzeData(false);
    } else {
      setShouldFetchAnalyzeData(true);
    }
  };

  useEffect(() => {
    if (dataCatalog_userId && dataCatalog_fileName && dataCatalog_dataCatalogId && dataCatalog_processType && dataCatalog_status) {
      const rowPdfParams = {
        fileName: dataCatalog_fileName,
        userId: dataCatalog_userId,
        dataCatalogId: dataCatalog_dataCatalogId,
      };
      const rowGridParams = {
        fileName: dataCatalog_fileName,
        dataCatalogId: dataCatalog_dataCatalogId,
        status: dataCatalog_status,
        userId: dataCatalog_userId,
        userName: user_name,
      };
      const rowTitle = { file: dataCatalog_fileName, status: dataCatalog_status }
      dispatch({ type: "ROW_CLICK_GRID", payload: rowGridParams });
      dispatch({ type: "SET_UPLOAD_DATA", payload: rowPdfParams });
      dispatch({ type: "SET_ROW_TITLE", payload: rowTitle });
      handleShowAnalyzedData();
      if (dataCatalog_status === "Uploaded") {
        setShouldFetchAnalyzeData(false);
      } else {
        setShouldFetchAnalyzeData(true);
      }
    }
  }, [dataCatalog_userId, dataCatalog_fileName, dataCatalog_dataCatalogId, dataCatalog_processType, user_name, dataCatalog_status]);

  const setSelectedConfidence = (value: number | null) => {
    dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: value });
  };

  const setPdfPage = (value: number) => {
    dispatch({ type: "SET_PDF_PAGE", payload: value });
  };

  const rowUpdated = () => {
    dispatch({ type: "SET_EDITING_DATA", payload: true });
  };

  const setConfidenceValue = (value: string | null) => {
    dispatch({ type: "SET_CONFIDENCE_VALUE", payload: value });
  }

  const setPdfFinalData = (value: any) => {
    dispatch({ type: "SET_PDF_FINAL_DATA", payload: value });
  };

  const setMetricsData = (value: any) => {
    dispatch({ type: "SET_METRICS_DATA", payload: value });
  };

  const setTelemetryData = (value: any) => {
    dispatch({ type: "SET_TELEMETRY_DATA", payload: value });
  };

  const handleApplyConfidence = (e: any) => {
    if (!e) {
      dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: [] });
      dispatch({ type: "SET_CONFIDENCE_VALUE", payload: [] });
    } else {
      const { value, confidence } = e || {};
      if (value) {
        const confidenceValue = parseFloat(value) / 100;
        setSelectedConfidence(confidenceValue);
        setConfidenceValue(confidence);
      }
    }
  };

  const refetch = useCallback(() => {
    dispatch({ type: "SET_SELECTED_CONFIDENCE", payload: [] });
    dispatch({ type: "SET_CONFIDENCE_VALUE", payload: [] });
    startRefetch();
    documentRefetch();
    telemetryRefetch();
  }, [startRefetch, documentRefetch, telemetryRefetch]);

  return {
    state,
    analyzedData,
    telemetryData,
    pdfData,
    DocumentData,
    isFetching,
    onTabChange,
    handleFilesUpload,
    handleSave,
    handleItemClick,
    handleSaveDocument,
    handleAnalyzeDocument,
    handleRowClick,
    isLoading,
    isAnalyzing,
    isPdfSaving,
    hasFileUploaded,
    rowUpdated,
    isFileSaveLoading,
    confidenceData,
    handleApplyConfidence,
    setPdfPage,
    refetch,
    setSelectedConfidence,
    setConfidenceValue,
    documentLoading,
    confidenceSelectBoxRef,
    parentData,
    childData,
    saveEditAnalyzePdf,
    setPdfFinalData,
    setMetricsData,
    setTelemetryData,
    onCurrentValueChange,
    pageIndex,
    pageSize,
    handleDropDownPageIndex,
    handlePageIndexChange,
  };
};

export const useCreateDocumentAnalyze = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(createDocumentAnalyze, {
    onSuccess: () => {
      showNotification({
        message: "File uploaded successfully",
      });
      fnQueryClient.removeQueries("fetch-document-analyze-telemetry");
      fnQueryClient.removeQueries("fetch-document-analyzer-pdf");
      fnQueryClient.invalidateQueries("fetch-recent-document");
    },
    onError: (error: Error) => {
      showNotification({
        message: "File upload failed",
        type: "error",
      });
    },
  });
};

export const useCreatePdfEditDocumentAnalyze = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(PdfEditDocumentAnalyze, {
    onSuccess: () => {
      showNotification({
        message: "Pdf edited successfully",
      });
      fnQueryClient.removeQueries("fetch-document-analyze-telemetry");
      fnQueryClient.removeQueries("fetch-document-analyzer-pdf");
      fnQueryClient.invalidateQueries("fetch-recent-document");
    },
    onError: (error: Error) => {
      showNotification({
        message: "File upload failed",
        type: "error",
      });
    },
  });
};

export const useFetchRecentDataGrid = (selectedDays : any,
  pageIndex : any,
  Count : any,
  sortColumn : any,
  sortOrder : any,
  isNextPage : boolean,
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length -1] + 1;
  const queryParams = {
    processType: "Document Analyzer",
    startTime, 
    endTime,
    timePeriod,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  };
  return useQuery(
    ["fetch-recent-document", queryParams],
    () => fetchRecenAnalyseDataApi(queryParams),
    {
      enabled: !isNextPage,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDocumentAnalyzeApi = (analyzeData: any, shouldFetch: boolean) => {
  const { dataCatalogId } = analyzeData?.[0] || analyzeData || {};
  const queryParams = {
    dataCatalogId: dataCatalogId,
  };
  return useQuery(
    ["fetch-document-data-list", queryParams],
    () => fetchAnalyzeDocumentApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!dataCatalogId && shouldFetch,
      select: (data: any) => {
        return data?.data || {};
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDocumentAnalyzerProcessApi = (data: any) => {
  const queryParams = {
    file_name: data?.fileName || data?.fileName,
    userId: data?.userId,
  };
  return useQuery(
    ["fetch-document-analyzer-process", queryParams],
    () => fetchDocumentAnalyzerProcessApi(queryParams),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.map((item: any) => {
          return {
            ...item,
            fileName: item?.fileName,
            dataCatalogId: item?.dataCatalogId,
            processType: item?.processType,
            userId: item?.userId,
          };
        });
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDocumentAnalyzerPDFApi = (processData: any) => {
  const { fileName, userId: uid } = processData || {};
  const queryParams = {
    file_name: fileName || processData?.fileName,
    userId: uid,
    processType: "Document Analyzer",
  };
  return useQuery(
    ["fetch-document-analyzer-pdf", queryParams],
    () => fetchDocumentAnalyzerPDFApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!fileName && !!uid,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDocumentAnalyzeTelemetry = (
  dataCatalogId: string,
  isInterval: boolean,
  shouldFetchAnalyzeData: boolean
) => {
  const fnQueryClient = useQueryClient();
  return useQuery(
    ["fetch-document-analyze-telemetry", dataCatalogId],
    () => fetchDocumentAnalyzeTelemetry(dataCatalogId),
    {
      enabled: !!dataCatalogId && shouldFetchAnalyzeData,
      refetchOnWindowFocus: false,
      refetchInterval: isInterval ? 500 : false,
      select: (data: any) => {
        return data;
      },
      onSuccess: (data: any) => {
        const isSuccess = data?.executionStatus;
        if (isSuccess === "Completed") {
          fnQueryClient.invalidateQueries("fetch-document-data-list");
          fnQueryClient.invalidateQueries("fetch-recent-document");
        }
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useUpdateDocumentAnalyzer = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(updateDocumentAnalyzerApi, {
    onSuccess: () => {
      showNotification({
        message: "Document saved successfully",
      });
      fnQueryClient.invalidateQueries("fetch-recent-document");
    },
    onError: (error: Error) => {
      showNotification({
        message: "Document save failed",
        type: "error",
      });
    },
  });
};

export const useFetchDocumentInfo = (dataCatalogId: string, docStage: string) => {
  return useQuery(
    ["fetch-document-analyzer-pdf", dataCatalogId, docStage], 
    () => fetchDocumentInfoApi(dataCatalogId, docStage),
    {
      refetchOnWindowFocus: false,
      enabled: !!dataCatalogId && !!docStage,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchDocumentMetricsInfo = (data: any) => {
  const { dataCatalogId } = data || {};
  return useQuery(
    ["fetch-document-analyzer-pdf", dataCatalogId],
    () => fetchDocumentMetricsInfoApi(dataCatalogId),
    {
      refetchOnWindowFocus: false,
      enabled: !!dataCatalogId,
      select: (data: any) => {
        const res = JSON?.stringify(data?.data, null, 2) ;
        return res;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};