import React, { useCallback, useMemo } from "react";
import { allowedPageSizes } from "../../../components/ui-components/fileViewer/types";
import { statusItems } from "../../../config/constants";
import { ContactStatus } from "../../../components/ui-components/contact-status/ContactStatus";
import { cellCreatedDate } from "../../../components/ui-components/dataGridFormat/dataGridFormat";
import PsButton from "../../../components/ui-components/button/Button";
import { useNavigate } from "react-router-dom";
import PsDataGrid from "../../../components/ui-components/dataGrid/DataGrid";
import DataSource from "devextreme/data/data_source";
import { fetchUserDataGrid } from "../../../service/admin/api-service";
import { userStatus } from "../../../config/user-adminstration/commonConfig";


const UserDataGrid = (props: any) => {
  const { onRowUpdated,handleColumnIndexChange,refreshKey, onEditClick, userAdmin} = props;
  const navigate = useNavigate();

  const onAssignRoles = useCallback((userId: string) => (event: any) => {
    navigate(`/user-administration/${userId}/permissions`);
  }, [navigate]);

  const AssignRoles = useCallback((cell: any) => {
    const { data } = cell;
    const { userId, status } = data;
    const isUserActive = status === userStatus[0];
    const isDisabled = userAdmin === userId || !isUserActive;
  
    return <MemoizedAssignRolesButton userId={userId} onAssignRoles={onAssignRoles} isDisabled={isDisabled} />;
  }, [onAssignRoles, userAdmin]);

  const MemoizedAssignRolesButton = useMemo(() => {
    return ({ userId, onAssignRoles, isDisabled }: { userId: string, onAssignRoles: (userId: string) => (event: any) => void, isDisabled:boolean }) => (
      <PsButton text="Assign Roles" onClick={onAssignRoles(userId)} disabled={isDisabled}  />
    );
  }, []);


  const columns = useMemo(
    () => [
      { caption: "User Name", dataField: "name", dataType: "string" },
      { caption: "Given Name", dataField: "given_name", dataType: "string" },
      {
        caption: "Status",
        dataField: "status",
        dataType: "string",
        cellRender: ContactStatus,
        lookup: { dataSource: statusItems },
      },
      { caption: "E Mail", dataField: "emails", dataType: "string" },
      {
        caption: "Assigned Roles",
        dataField: "roles",
        dataType: "string",
        cellRender: AssignRoles,
      },
      {
        caption: "Created Date",
        dataField: "createdDate",
        dataType: "datetime",
        cellRender: cellCreatedDate,
      },
    ],
    [AssignRoles]
  );


  const gridProps = useMemo(
    () => ({
      dataSource:DataSource,
      columns,
      keyExpr: "userId",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      onRowEdit: onEditClick,
      handleColumnIndexChange,
    }),
    [columns, onEditClick, handleColumnIndexChange]
  );


const apiProps = useMemo(
    () => ({
      apiFunction: fetchUserDataGrid,
      refreshKey,
    }),
    [refreshKey]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No User to display at this time",
      heightClass: "height-large",
      id: "userDataGrid",
    }),
    [] 
  );


  return (
    <>
    <div className="recent-activity-grid">
      <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
    </div>  
    </>
  );
};

export default UserDataGrid;
