import { Item } from "devextreme-react/toolbar";
import { useFetchInfraDetails } from "../../../hooks/task/useInfra";
import { useNavigate } from "react-router";
import DropDownButton from "devextreme-react/drop-down-button";
import { dropDownOptions } from "../../../config/constants";
import "../../../styles/task/Infrastructure.scss";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { useCallback, useMemo } from "react";
import { InfraStructureDataGrid } from "./helper-component/InfraGridView";
import { useAppHook } from "../../../hooks/app/useApps";

export const InfraStructure = () => {
  const { data, isFetching, refetch } = useFetchInfraDetails();

  const navigate = useNavigate();

  const {
    state: { client },
    filterByClient,
    clientDropDownData,
    gridRef,
  } = useAppHook();

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      event.preventDefault();
      navigate(`/client-profile?clientId=${clientId}`);
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      event.preventDefault();
      navigate(`/client-task?taskId=${taskId}`);
    },
    [navigate]
  );

  const DataGridProps = useMemo(() => {
    return {
      data,
      handleClientLinkClick,
      handleTaskLinkClick,
      gridRef,
    };
  }, [data, handleClientLinkClick, gridRef, handleTaskLinkClick]);

  const PageBodyComponent = useCallback(
    () => [
      {
        mode: "grid",
        Component: InfraStructureDataGrid,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps]
  );

  const AdditionalToolbarContent = useMemo(
    () => (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          items={clientDropDownData || ["Loading..."]}
          stylingMode="text"
          text={client}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
        />
      </Item>
    ),
    [client, clientDropDownData, filterByClient]
  );

  const layoutProps = {
    headerText: "Infrastructure Services",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: isFetching,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
};
