import React, { useReducer, useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import { formatFileSize } from "../../../utlis/helper";
import { FormPopup } from "../../ui-components/form/form-popup/FormPopup";
import { FileData, FileUploaderComponentProps, State, Action } from "./type"; // Import types here

const initialState: State = {
  files: [],
  fileProgress: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FILES":
      return { ...state, files: action.payload };
    case "RESET_FILES":
      return { ...state, files: [] };
    default:
      return state;
  }
};

const FileUploaderComponent: React.FC<FileUploaderComponentProps> = ({
  allowedExtensions,
  onUpload,
  isLoading,
  resetUploaderKey,
  popupAddFilesVisible,
  handleFormPopupClose,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number | null }>({});

  const handleFilesUpload = (e: { value?: File[] }) => {
    const files = e.value;
    if (!files) return;

    const filePromises = files.map((file) => {
      return new Promise<FileData>((resolve, reject) => {
        const reader = new FileReader();
        const fileSize = formatFileSize(file.size);
        reader.readAsDataURL(file);
        reader.onload = () => resolve({ name: file.name, content: reader.result, size: fileSize });
        reader.onerror = (error) => reject(error);
      });
    });

    Promise.all(filePromises).then((encodedFiles) => {
      dispatch({ type: "SET_FILES", payload: encodedFiles });
      const initialFileProgress: { [key: string]: number | null } = {};
      encodedFiles.forEach((file) => {
        initialFileProgress[file.name] = 0;
      });
      setFileProgress(initialFileProgress);

      encodedFiles.forEach((file) => {
        const interval = setInterval(() => {
          setFileProgress((prevProgress) => {
            const newProgress = { ...prevProgress };
            if (newProgress[file.name] !== null && newProgress[file.name]! < 100) {
              newProgress[file.name] = newProgress[file.name]! + 10;
            } else {
              clearInterval(interval);
            }
            return newProgress;
          });
        }, 500);
      });
    });
  };

  const handleRemoveFile = (fileName: string) => {
    const updatedFiles = state.files.filter((file) => file.name !== fileName);
    dispatch({ type: "SET_FILES", payload: updatedFiles });
    const updatedFileProgress = { ...fileProgress };
    delete updatedFileProgress[fileName];
    setFileProgress(updatedFileProgress);
  };

  const handleSave = () => {
    if (state.files.length === 0) {
      alert("Please select files to upload");
      return;
    }
    onUpload(state.files);
    dispatch({ type: "RESET_FILES" });
    setFileProgress({});
    handleFormPopupClose();
  };

  return (
    <FormPopup
      title="Add Files"
      visible={popupAddFilesVisible}
      setVisible={handleFormPopupClose}
      onSave={handleSave}
      isLoading={isLoading}
      width={1200}
      height={600}
    >
      <div className="file-uploader-container">
        <FileUploader
          key={resetUploaderKey}
          multiple
          accept={allowedExtensions.join(",")}
          uploadMode="useForm"
          onValueChanged={handleFilesUpload}
          showFileList={false}
        />
        <span className="note">
          {"Allowed file extensions: "}
          {allowedExtensions.join(", ")}
        </span>
        {Object?.keys(fileProgress)?.map((fileName) => (
          <div key={fileName} className="progressDiv">
            {fileName} 
            {fileProgress[fileName] !== null && (
              <ProgressBar value={fileProgress[fileName] ?? 0} />
            )}
            <button
              className="remove-button"
              onClick={() => handleRemoveFile(fileName)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </FormPopup>
  );
};

export default FileUploaderComponent;
