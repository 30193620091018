import { useCallback, useMemo, useRef } from "react";
import { SelectBox } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { useDocumentProcessingStateProvider } from "../../../hooks/datastudio/useDocumentProcessing";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { useNavigate } from "react-router-dom";
import PsButton from "../../../components/ui-components/button/Button";
import PsSkeleton from "../../../components/ui-components/skeleton/PsSkeleton";
import { DocumentProcessingViewer } from "./helper-component/documentProcessingViewer/DocumentProcessingViewer";
import "../../../styles/datastudio/documentProcessing.scss";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useHelperHooks } from "../../../hooks/helper/utils";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import SaveIcon from "../../../assets/icons/icon-save-1.png";
import FinalizeIcon from "../../../assets/images/icons/finalize.png";
import RejectIcon from "../../../assets/images/icons/reject.png";
import GridSource from "../../../assets/icons/icon-grid-source-view.png";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";
import Split from "react-split";
import { DefaultView } from "./helper-component/documentProcessingViewer/helper-component/defaultView";
import { getNameByValue } from "../../../config/datastudio/pageConfig";

export const DocumentProcessing = () => {
  const {
    state: { selectedConfidence, pdfPage, confidenceValue, documentProcessingFiles, previewVisible },
    DocumentProcessingData,
    isDocumentProcessingDataLoading,
    isDocumentProcessingDataFetching,
    refetch,
    handleSaveDocumentProcessing,
    handleFinalizeDocumentProcessing,
    handleRejectDocumentProcessing,
    handleApplyConfidence,
    handleDocumentProcessingFiles,
    fetchFunctionDropDownFeatures,
    setPdfPage,
    fileType,
    setPreviewVisible,
    pdfData,
    isPdfLoading,
    selectedType
  } = useDocumentProcessingStateProvider();

  const confidenceSelectBoxRef = useRef<any>(null);

  const refetchs = () => {
    refetch();
    confidenceSelectBoxRef?.current?.instance?.reset();
  };

  const executionStatus = useHelperHooks()?.getQueryParams("executionStatus");
  const DocumentName = useHelperHooks()?.getQueryParams("DocumentName");
  const isMap = useHelperHooks()?.getQueryParams("isMap");

  const confidenceData = fetchFunctionDropDownFeatures?.confidenceDropdown || [];
  const documentProcessingFile = useMemo(() => {
    return fetchFunctionDropDownFeatures?.documentProcessingFiles || [];
  }, [fetchFunctionDropDownFeatures]);

  const navigate = useNavigate();

  const handleBackButton = () => {
    navigate(-1);
  };

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode?.Hand,
      },
    },
  });
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const handlePageChange = useCallback((page: number) => {
    setPdfPage(page);
    jumpToPage(page - 1);
  }, [setPdfPage, jumpToPage]);

  const processedData = DocumentProcessingData?.document_data
  const extractedData = DocumentProcessingData?.extracted_data?.documents?.[0]
  const defaultData = DocumentProcessingData?.export_data
  const documentData = documentProcessingFiles === fileType?.[1] ? extractedData : processedData;

  const placeholderText = getNameByValue(documentProcessingFile, documentProcessingFiles);

  const commonComponents = (
    <>
      <SelectBox
        dataSource={documentProcessingFile}
        placeholder={placeholderText}
        displayExpr="name"
        width={180}
        showClearButton={true}
        onValueChange={handleDocumentProcessingFiles}
        disabled={isMap === "disable"}
      />
      <SelectBox
        ref={confidenceSelectBoxRef}
        dataSource={confidenceData}
        defaultValue={confidenceValue}
        displayExpr="confidence"
        width={180}
        showClearButton={true}
        placeholder={"Select Confidence"}
        onValueChange={handleApplyConfidence}
      />
      <PSIconText
        text={previewVisible ? "Preview" : "Edit"}
        alt={previewVisible ? "preview" : "edit"}
        onClick={() => setPreviewVisible(!previewVisible)}
        src={GridSource}
        disabled={isMap === "disable" || selectedType === fileType?.[0] || selectedType === fileType?.[1]}
      />
      <PSIconText
        text="Save"
        alt="save"
        onClick={handleSaveDocumentProcessing}
        src={SaveIcon}
        disabled={selectedType === fileType?.[0] || selectedType === fileType?.[1]}
      />
      <PSIconText
        text="Finalize"
        alt="finalize"
        onClick={handleFinalizeDocumentProcessing}
        src={FinalizeIcon}
        disabled={selectedType === fileType?.[0] || selectedType === fileType?.[1]}
      />
    </>
  );
  const statusComponents: { [key: string]: JSX.Element } = {
    'Completed': (
      <PSIconText
        text="Reject"
        alt="reject"
        src={RejectIcon}
        onClick={handleRejectDocumentProcessing}
      />
    ),
    'Ready for Review': commonComponents,
    'Rejected': commonComponents
  };
  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      {executionStatus && statusComponents[executionStatus] ? statusComponents[executionStatus] : null}
    </Item>
  );

  const AdditionalToolbarBeforeContent = executionStatus === 'Completed' || 'Ready for Review' || 'Pending' || 'Failed' ? (
    <Item location='before' locateInMenu="auto">
      <div style={{ marginLeft: '1px' }} >
        <PsButton icon='arrowleft' mode='text' width={'auto'} onClick={handleBackButton} />
      </div>
    </Item>
  ) : null;

  const title: any = (
    <span>Document Processing &nbsp; <small>{DocumentName} &nbsp;&nbsp;&nbsp; {executionStatus}</small></span>
  )

  const renderPdf = useCallback(() => {
    if (isPdfLoading) {
      return <PsSkeleton count={1} height={800} width={'100%'} />;
    }

    if (pdfData) {
      return (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
          <Viewer
            fileUrl={pdfData}
            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]}
            initialPage={pdfPage - 1}
            defaultScale={SpecialZoomLevel?.PageFit}
          />
        </Worker>
      );
    }
    return null;
  }, [isPdfLoading, pdfData, defaultLayoutPluginInstance, pageNavigationPluginInstance, pdfPage]);

  const renderAnalyzedData = useCallback(() => {
    if (isDocumentProcessingDataLoading || isDocumentProcessingDataFetching) {
      return (
        <div className="skeleton-container">
          <PsSkeleton height={'70vh'} width={"100%"} />
        </div>
      );
    }
    if (!documentData) {
      return (
        (!previewVisible) ? (
          <DefaultView
            data={defaultData}
            selectedConfidence={selectedConfidence}
          />
        ) : (
          <DocumentProcessingViewer
            pageData={defaultData}
            isLoading={isDocumentProcessingDataLoading}
            selectedConfidence={selectedConfidence}
            onPageChange={handlePageChange}
            viewType={documentProcessingFiles}
            fileType={fileType}
          />
        )
      );
    }
    if (documentData) {
      return (
        <DocumentProcessingViewer
          pageData={documentData}
          isLoading={isDocumentProcessingDataLoading}
          selectedConfidence={selectedConfidence}
          onPageChange={handlePageChange}
          viewType={documentProcessingFiles}
          fileType={fileType}
        />
      )
    }
  }, [isDocumentProcessingDataLoading, isDocumentProcessingDataFetching, documentData, previewVisible, defaultData, selectedConfidence, handlePageChange, documentProcessingFiles, fileType]);


  return (
    <>
      <ToolbarAnalytics
        additionalToolbarBeforeContent={AdditionalToolbarBeforeContent}
        title={title}
        refresh={refetchs}
        additionalToolbarContent={AdditionalToolbarContent}
      />
      <Split
        sizes={[40, 60]}
        minSize={[150, 150]}
        className="split"
      >
        <div className="pdf-wrapper document-processing-resize">
          {renderPdf()}
        </div>
        {executionStatus !== "Pending" && executionStatus !== "In Progress" && executionStatus !== "Failed" && (
          <div className="data-wrapper-dp document-processing-resize">
            <div className={`content-container ${isDocumentProcessingDataLoading ? "loading" : ""}`}>
              {renderAnalyzedData()}
            </div>
          </div>
        )}
      </Split>
    </>
  );
};

