import { useCallback, useEffect, useMemo } from "react";
import UrlGrid from "./helper-component/urlGrid";
import NewUrl from "./helper-component/newUrl";
import EditUrl from "./helper-component/editUrl";
import DeleteUrl from "./helper-component/deleteUrl";
import { useUrl } from "../../../hooks/client/useClientUrl";
import DropDownButton from "devextreme-react/drop-down-button";
import { dropDownOptions } from "../../../config/constants";
import { ViewType } from "./types";
import { Item } from "devextreme-react/toolbar";
import { SearchPanel } from "devextreme-react/data-grid";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import UrlKanbanPage from "./helper-component/kanban";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import { useAppHook } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useFetchUrlSummary } from "../../../hooks/client/useClientUrl";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import uploadIcon from "../../../assets/icons/icon-upload-1.png";
import UploadURLs from "./helper-component/uploadURLs";
import { useLocation, useNavigate } from "react-router-dom";
import { filterUrlStatusList } from "../../../config/client/commonConfig";
import { usePage } from "../../../contexts/pageContext";
import { fetchAllUrlsApi } from "../../../service/client/api-service";
import { replaceQueryUrl } from "../../../utlis/helper";

const viewOptions: ViewType[] = [
  { text: "Grid View", value: "gridView" },
  { text: "Workflow View", value: "boardView" },
];

export const UrlCatalog = () => {
  const { data: summaryData } = useFetchUrlSummary();
  const location = useLocation();
  const currentPermission = useMemo(() => location?.state?.currentPermission || {}, [location?.state]);

  const {
    state: {
      popupVisible,
      selectedClientData,
      EditpopupVisible,
      deletePopupVisible,
      showpopup,
      viewType,
      refreshKey,
      openLineage,
      selectedData,
      gridTitleStatus
    },
    onAddContactClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditUrlClick,
    changeEditPopupVisibility,
    setPopupVisible,
    setViewType,
    setRefreshKey,
    setOpenLineage,
    setSelectedData,
    setGridTitleStatus
  } = useUrl();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    url_status: p_urlStatus,
  } = filterValue || {};

  const {
    state: {
      client,
      grid_status,
      kanban_clientId,
    },
    filterByClient,
    clientDropDownData,
    filterByUrlStatus,
    gridRef,
    handleDropDownPageIndex,
    handleStatusChange,
    handleColumnIndexChange
  } = useAppHook();

  const navigate = useNavigate();
  const { getQueryParams } = useHelperHooks();
  const showBackButton = Boolean(getQueryParams("action"));
  const initialView = getQueryParams("view");

  const handleClientLinkClick = useCallback((event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
  }, [navigate]);

  const additionalParams = useMemo(() => {
    return {
      clientId: p_clientId,
      status: p_urlStatus === "All Items" ? "" : p_urlStatus,
    };
  }, [p_clientId, p_urlStatus]);

  const apiProps = useMemo(() => ({
    apiFunction: fetchAllUrlsApi,
    additionalParams: additionalParams,
    refreshKey
  }), [additionalParams, refreshKey]);
  
  const DataGridProps = useMemo(() => {
    return {
      gridRef,
      client,
      onDeletePopupOpen,
      onEditUrlClick,
      summaryData,
      handleDropDownPageIndex,
      handleStatusChange,
      status: grid_status,
      clientId: kanban_clientId,
      handleClientLinkClick,
      additionalParams,
      height: "height-large",
      handleColumnIndexChange,
      apiProps,
      openLineage,
      setOpenLineage,
      selectedData,
      setSelectedData,
      gridTitleStatus,
      setGridTitleStatus
    };
  }, [
    gridRef,
    client,
    onDeletePopupOpen,
    onEditUrlClick,
    summaryData,
    handleDropDownPageIndex,
    handleStatusChange,
    grid_status,
    kanban_clientId,
    handleClientLinkClick,
    additionalParams,
    handleColumnIndexChange,
    apiProps,
    openLineage,
    setOpenLineage,
    selectedData,
    setSelectedData,
    gridTitleStatus,
    setGridTitleStatus
  ]);

  const handleAddFilesClick = useCallback(() => {
    setPopupVisible(!showpopup);
  }, [setPopupVisible, showpopup]);

  const uploadURLsProps = useMemo(() => {
    return {
      showpopup,
      onClose: handleAddFilesClick,
    };
  }, [handleAddFilesClick, showpopup]);

  const handleViewChange = useCallback(
    (e: any) => {
      const { item } = e;
      if (item) {
        setViewType(item?.value);
        replaceQueryUrl("view", item?.value);
      }
    },
    [setViewType]
  );

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const UrlEditProps = useMemo(() => {
    return {
      selectedClientData,
      EditpopupVisible,
      changeEditPopupVisibility,
    };
  }, [selectedClientData, EditpopupVisible, changeEditPopupVisibility]);

  const urlDeleteProps = useMemo(() => {
    return {
      selectedClientData,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [deletePopupVisible, onDeletePopupClose, selectedClientData]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: UrlGrid,
      componentProps: DataGridProps,
    },
    {
      mode: "board",
      Component: UrlKanbanPage,
      componentProps: DataGridProps,
    },
  ];

  useEffect(() => {
    if (initialView) setViewType(initialView);
  }, [initialView, setViewType]);

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          stylingMode="text"
          items={clientDropDownData || ["Loading..."]}
          width={"auto"}
          text={p_clientName ? p_clientName : "All Clients"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
          items={filterUrlStatusList}
          stylingMode="text"
          text={p_urlStatus ? p_urlStatus : "All Items"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByUrlStatus}
          onItemClick={handleDropDownPageIndex}
        />
        <DropDownButton
          text={viewOptions?.find((item) => item?.value === viewType)?.text || "gridView"}
          stylingMode="text"
          displayExpr="text"
          items={viewOptions}
          width={"auto"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={handleViewChange}
          onItemClick={handleDropDownPageIndex}
        />
        { currentPermission?.canUpdate &&
        <PSIconText
          text="Upload URLs"
          src={uploadIcon}
          alt="addFiles"
          onClick={handleAddFilesClick}
        />}
        { currentPermission?.canCreate && 
        <PSIconText
          text="Add URL"
          src={NewIcon}
          alt="Add URL"
          onClick={onAddContactClick}
        /> }
        <SearchPanel placeholder="Client Search" />
      </Item>
    )
  }, [clientDropDownData, filterByClient, filterByUrlStatus, handleAddFilesClick, handleDropDownPageIndex, handleViewChange, onAddContactClick, p_clientName, p_urlStatus, viewType, currentPermission]);

  const layoutProps = {
    headerText: "URL Catalog",
    refetch: () => setRefreshKey((prev: any) => prev + 1),
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: viewType,
    isLoading: false,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewUrl {...FormProps} />
      <EditUrl {...UrlEditProps} />
      <DeleteUrl {...urlDeleteProps} />
      <UploadURLs {...uploadURLsProps} />
    </div>
  );
};
