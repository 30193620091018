import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { useCallback, useReducer, useRef, useState } from "react";
import { FilterContactStatus } from "../../pages/finops/rateCard/types";
import { DataGrid } from "devextreme-react";
import {
  fetchInvoiceApi,
} from "../../service/finops/api-service";
import { useQuery } from "react-query";
import { filterFrequenctList } from "../../config/constants";
import { useFetchClient, useFinopsProject } from "../app/useApps";

export interface FilterState {
  client: any;
  status: string;
}

const initialState: FilterState = {
  client: "All Clients",
  status: filterFrequenctList[0],
};

const reducer = (state: FilterState, action: any) => {
  switch (action.type) {
    case "CLIENT":
      return {
        ...state,
        client: action.payload,
      };
    case "FREQUENCY":
      return {
        ...state,
        frequency: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useFinops = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const gridRef = useRef<DataGrid>(null);
  const { data: clientDropDownData } = useFetchClient();
  const [selectedValue, setSelectedValue] = useState("");
  const { data: projectDropDownData } = useFinopsProject(selectedValue);

  let modifiedClientDropDownData: any[] | undefined = [];

  if (Array.isArray(clientDropDownData) && clientDropDownData.length > 0) {
    const allClientsItem = {
      name: "All Clients",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      state: "",
      city: "",
      clientTag: "",
      status: "",
      note: "",
      clientId: "",
      important_links_and_urls: "",
      specialInstructions: "",
      technology_integration_requirements: "",
      kpis: "",
      keyMetrics_and_reporting_frequency: "",
      clientShortName: "",
      primary_email: "",
      address1: "",
      address2: "",
      numberOfTask: 0,
    };

    modifiedClientDropDownData = [allClientsItem, ...clientDropDownData];
  }

  let modifiedProjectDropDownData: any[] | undefined = [];

  if (Array.isArray(projectDropDownData) && projectDropDownData.length > 0) {
    const allProjectItem = {
      id: "",
      projectId: "",
      projectName: "All Projects",
      clientId: "",
      clientName: "",
      status: "",
      projectDescription: "",
      startDate: "",
      endDate: "",
    };

    modifiedProjectDropDownData = [allProjectItem, ...projectDropDownData];
  }

  const filterByClientProject = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: name }: { item: FilterContactStatus } = e;
      if (name === "All Clients") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["name", "=", name]);
      }
      const { item: clientData } = e;
      setSelectedValue(clientData.clientId);
      dispatch({ type: "CLIENT", payload: name });
    },
    [gridRef]
  );

  const filterByClient = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: name }: { item: FilterContactStatus } = e;
      if (name === "All Clients") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["name", "=", name]);
      }
      dispatch({ type: "CLIENT", payload: name });
    },
    [gridRef]
  );

  const filterByStatus = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: status }: { item: FilterContactStatus } = e;
      if (status === "thisMonth") {
        gridRef.current?.instance.clearFilter();
      } else {
        gridRef.current?.instance.filter(["frequency", "=", status]);
      }
      dispatch({ type: "FREQUENCY", payload: status });
    },
    [gridRef]
  );

  const handleRefreshFinops = () => {
    gridRef.current?.instance.refresh();
    dispatch({ type: "CLEAR" });
  };
  return {
    state,
    filterByClientProject,
    filterByStatus,
    filterByClient,
    clientDropDownData,
    gridRef,
    modifiedClientDropDownData,
    modifiedProjectDropDownData,
    handleRefreshFinops,
  };
};

export const useFetchInvoiceInfo = () => {
  return useQuery("fetch-invoiceInfo", fetchInvoiceApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

