import { useCallback, useMemo, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Panel,
  OnConnect,
  MiniMap,
  Background,
  Controls,
  MarkerType,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { Modal } from "./FlowPopup";

const generateNodesAndEdges = (editWorkflowData: any) => {
  const nodes = editWorkflowData?.map((step: any, index: any) => ({
    id: (index + 1)?.toString(),
    data: {
      step: `Step-${index + 1}`,
      label: `Name: ${step?.name}`,
      description: step?.description,
      additionalInfo: step?.action,
    },
    sourcePosition: index < editWorkflowData?.length - 1 ? "bottom" : undefined,
    targetPosition: index > 0 ? "top" : undefined,
    position: { x: 100, y: index * 250 },
    type: "textUpdater",
  }));

  const edges = editWorkflowData?.slice(1)?.map((_: any, index: any) => ({
    id: `${index + 1}-${index + 2}`,
    source: (index + 1)?.toString(),
    target: (index + 2)?.toString(),
    markerEnd: { type: MarkerType?.ArrowClosed },
  }));

  return { nodes, edges };
};

const EdgeTypesFlow = (props: any) => {
  const { editWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData } = props;

  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => generateNodesAndEdges(editWorkflowData),
    [editWorkflowData]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const [showModal, setShowModal] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);

  const onModalToggle = useCallback(
    (nodeId?: string) => {
      if (nodeId) {
        setCurrentNodeId(nodeId);
      }
      setShowModal(!showModal);
    },
    [showModal]
  );

  const formProps = useMemo(() => ({
    editWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData,
    fileTypeData
  }), [editWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData]);

  const nodeTypes = useMemo(
    () => ({
      textUpdater: (props: any) => (
        <TextUpdaterNode {...props} onClick={() => onModalToggle(props?.id)} />
      ),
    }),
    [onModalToggle]
  );

  const updateNodeData = (nodeId: string, key: string, value: any) => {
    setNodes((nds) =>
      nds?.map((node) =>
        node?.id === nodeId
          ? { ...node, data: { ...node?.data, [key]: value } }
          : node
      )
    );
  };

  const onAddNode = () => {
    const newNodeId = (nodes?.length + 1)?.toString();
    const lastNode = nodes[nodes?.length - 1];
    const newNodePositionY = lastNode?.position?.y + 250;
    const newNode: any = {
      id: newNodeId,
      data: {
        step: `Step-${newNodeId}`,
        label: `Name: Step-${newNodeId}`,
        description: `This is step ${newNodeId}`,
        additionalInfo: "Additional Info",
      },
      sourcePosition: "bottom",
      targetPosition: "top",
      position: { x: 100, y: newNodePositionY },
      type: "textUpdater",
    };

    const newEdge = {
      id: `${lastNode?.id}-${newNodeId}`,
      source: lastNode?.id,
      target: newNodeId,
      markerEnd: { type: MarkerType?.ArrowClosed }
    };
    setEdges((eds: any) => addEdge(newEdge, eds));
    setNodes((nds) => [...nds, newNode]);
  };

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <MiniMap />
          <Background />
          <Controls />
          <Panel>
            <button onClick={onAddNode}>Add</button>
          </Panel>
        </ReactFlow>
        <Modal
          showModal={showModal}
          onModalToggle={onModalToggle}
          formData={formProps}
          nodeId={currentNodeId}
          steps={`Step-${currentNodeId}`}
          updateNodeData={updateNodeData}
        />
      </div>
    </>
  );
};

export default EdgeTypesFlow;
