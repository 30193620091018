import { useCallback } from "react";
import { DataGrid, Editing } from "devextreme-react/data-grid";
import { useCreateWorkItem } from "../../../../../../hooks/workbench/useWorkbench";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { useApp } from "../../../../../../contexts/app";

const AddManualDataEntryGrid = (props: any) => {
  const {
    editPopupVisible,
    changeEditPopupVisibility,
    selectedManualEntryData,
  } = props;

  const { user } = useApp();

  const { content, filePath, fileId } = selectedManualEntryData || {};
  const isEdit = true;

  const { mutate, isLoading } = useCreateWorkItem(isEdit);

  const handleSavingmanualDataEntry = useCallback(
    (e: any) => {
      const { changes } = e;
      const transformData = (data: any[]) => {
        return data.map((item) => item.data);
      };

      const transformedData = transformData(changes);
      const payload = {
        activityId: fileId,
        filePath: filePath,
        data: transformedData,
        updatedBy: user?.userId,
      };
      mutate(payload);
    },
    [fileId, filePath, mutate, user?.userId]
  );

  return (
    <>
      <FormPopup
        title="Add Manual Data Entry"
        visible={editPopupVisible}
        setVisible={changeEditPopupVisibility}
        toolbarVisible={false}
        isLoading={isLoading}
        fullScreen={true}
      >
        <DataGrid
          dataSource={content?.rows}
          showBorders={true}
          onSaving={handleSavingmanualDataEntry}
          columns={content?.header}
        >
          <Editing
            mode="batch"
            allowAdding={true}
            allowUpdating={true}
            allowDeleting={false}
          />
        </DataGrid>
      </FormPopup>
    </>
  );
};
export default AddManualDataEntryGrid;
