import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import EstimateInformations from "./estimatorData";

export const ResourceEstimator = () => {
  const pageBodyComponent = () => [
    {
      title: "Estimator Details",
      Component: EstimateInformations,
      componentProps: { showToolbar: false },
      reloadParams: 'fetch-estimator-data'
    },
  ];

  const LayoutProps = {
    headerText: "",
    pageBodyComponent: pageBodyComponent(),
    showToolbar: false,
  };

  return <DataGridTitleCardLayout {...LayoutProps} />;
};
