import "../../../../../styles/client/opexDashboard.scss";
import OpexPivotGrid from "./helper-component/pivotChartGrid";
import OpexRowCard from "./helper-component/opexRowCard";
import { keyMappings, replaceMappings } from "../../../../../config/client/commonConfig";
import { keyToImageMap } from "../../../../../utlis/image";

const OpexDashboard = ({ opexCardData, isOpexLoading, isSuccess, client, additionalParams }: any) => {
  const { data = {} } = opexCardData || {};

  const formatDate = (isoString: string, isTimeAvailable: boolean) => {
    if (!isoString) return "N/A";
    
    const date = new Date(isoString);
    
    if (isTimeAvailable != null && !isTimeAvailable) {
      date.setHours(23, 59, 59);
    }

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false
    });
    return formattedDate
  };

  const mapDataToRowCard = (dataArray: any[]) => {
    const order = ["Failed", "Rejected", "Documents Processed", "Data Processed"];
  
    const otherItems = dataArray.filter((item: any) => !order.includes(item?.name));
    const orderedItems = order.map((name) => dataArray.find((item: any) => item?.name === name)).filter(Boolean);
  
    const processedData = [...otherItems, ...orderedItems];
  
    return processedData.map((item: any) => {
      const newName = keyMappings[item?.name] || item?.name;
  
      const secondaryValue = Object.entries(item)
        .filter(([key]) => key !== 'name')
        .map(([key, value]: [string, any]) => {
          let formattedKey = keyMappings[key] || key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());
  
          if (key === "MOM") value = `(${value})`;
  
          formattedKey = Object.keys(replaceMappings).reduce((acc, replaceKey) => {
            const regex = new RegExp(replaceKey, "i");
            return acc.toLowerCase().includes(replaceKey) ? acc.replace(regex, replaceMappings[replaceKey]) : acc;
          }, formattedKey);
  
          return { name: formattedKey, value };
        });
  
      return {
        name: newName,
        image: keyToImageMap[item?.name],
        primaryValue: item?.count || item?.primaryValue,
        secondaryValue,
      };
    });
  };
  
  

  const rowCardData = [
    // ...mapDataToRowCard(data?.webScraping || []),
    ...mapDataToRowCard(data?.documentsCount || [])
  ];

  const { success, failed, urls, property_data,rejected,pending,completed,readyForReview,inProgress, exported, readyForTransfer} = data?.documentsDetail || {};
  const projectType = data?.projectType ||{};
  const clientId = data?.clientId || "";

  return (
    <div className="opex-dashboard-layout">
      <div className="summary-log-details">
        {data?.upToDate && data?.lastUpdatedDate &&
          <div>
            <p>Effective Date: <span>{formatDate(data?.upToDate, false)}</span>
            </p><p>Last Updated On: <span>{formatDate(data?.lastUpdatedDate, true)}</span></p>
          </div>
        }
      </div>
      <OpexRowCard opexCardData={rowCardData} isOpexLoading={isOpexLoading} />
         {!client ?  (
        <div className="message-container">Select a client to get started</div>
      ) : (
        <OpexPivotGrid
          successData={success}
          failedData={failed}
          urlsData={urls}
          propertyData={property_data}
          rejectedData={rejected}
          pendingData={pending}
          completedData={completed}
          reviewData={readyForReview}
          inProgressData={inProgress}
          exportedData={exported}
          readyForTransferData={readyForTransfer}
          projectType={projectType}
          clientId={clientId}
          timePeriod={additionalParams?.timePeriod}
          startTime={additionalParams?.startTime}
          endTime={additionalParams?.endTime}
          clientName={data?.clientName}
        />
      )}
    </div>
  );
};

export default OpexDashboard;

