// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/media/background-digital-32.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-engineering-home-page .jumbotron {
  display: flex;
  justify-content: end;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 2rem;
  text-align: center;
  height: 480px;
  overflow: hidden;
  position: relative;
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  box-shadow: 0 10px 10px rgba(180, 180, 180, 0.9);
}
.data-engineering-home-page .jumbotron-content {
  width: 30%;
  color: rgb(231, 242, 255);
  text-align: left;
  margin-top: 5px;
  font-size: 20px;
  margin: 0 20px 0 20px;
}
.data-engineering-home-page .jumbotron-content p {
  margin-top: 45px;
  font-size: 18px;
  line-height: 26px;
  color: rgb(174, 173, 173);
  text-align: left;
}
.data-engineering-home-page .jumbotron-content h3 {
  margin-top: 25px;
  font-size: 20px;
  line-height: 32px;
  color: rgb(148, 153, 214);
}`, "",{"version":3,"sources":["webpack://./src/styles/digital-service/DataEngineering.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,oBAAA;EACA,yDAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,sBAAA,EAAA,kDAAA;EACA,2BAAA,EAAA,sBAAA;EACA,4BAAA,EAAA,sCAAA;EACA,gDAAA;AAAJ;AAEE;EACE,UAAA;EACA,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,eAAA;EACA,qBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;EACA,gBAAA;AAAJ;AAEE;EACE,gBAAA;EACA,eAAA;EACA,iBAAA;EACA,yBAAA;AAAJ","sourcesContent":[".data-engineering-home-page {\n  .jumbotron {\n    display: flex;\n    justify-content: end;\n    background-image: url(\"../../assets/media/background-digital-32.jpg\");\n    background-color: #f8f9fa;\n    padding: 20px;\n    margin-bottom: 2rem;\n    text-align: center;\n    height: 480px;\n    overflow: hidden;\n    position: relative;\n    background-size: cover; /* Ensures the image covers the entire container */\n    background-position: center; /* Centers the image */\n    background-repeat: no-repeat; /* Prevents the image from repeating */\n    box-shadow: 0 10px 10px rgba(180, 180, 180, 0.9);\n  }\n  .jumbotron-content {\n    width: 30%;\n    color: rgb(231, 242, 255);\n    text-align: left;\n    margin-top: 5px;\n    font-size: 20px;\n    margin: 0 20px 0 20px;\n  }\n  .jumbotron-content p {\n    margin-top: 45px;\n    font-size: 18px;\n    line-height: 26px;\n    color: rgb(174, 173, 173);\n    text-align: left;\n  }\n  .jumbotron-content h3 {\n    margin-top: 25px;\n    font-size: 20px;\n    line-height: 32px;\n    color: rgb(148, 153, 214);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
