import { useLocation } from "react-router-dom";
import { TelemetryItem } from "../../pages/client/client-profile/types";

export const useHelperHooks = () => {
    const search = useLocation().search;
  
    const getQueryParams = (queryName: string) => {
      return new URLSearchParams(search).get(queryName);
    };
  
    return {
      getQueryParams,
    };
  };
  
  function renameKeys(obj: any, keyMap: Record<string, string>): any {
    if (obj) {
      return Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          keyMap[key] || key,
          Array.isArray(value)
            ? value.map((item) => renameKeys(item, keyMap))
            : typeof value === "object"
            ? renameKeys(value, keyMap)
            : value,
        ])
      );
    }
  }
  
  export function transformData(data: TelemetryItem[]): TelemetryItem[] {
    const keyMap = {
      modules: "items",
      telemetry: "items",
      executionDetails: "items",
      result: "items",
      data: "items",
      exception: "items",
    };
    return data?.map((item) => renameKeys(item, keyMap));
  }
  
  export const addExpandProperty = (obj: any) => {
    if (Array.isArray(obj)) {
      obj.forEach((item) => addExpandProperty(item));
    } else if (typeof obj === "object" && obj !== null) {
      obj.expanded = true;
      for (const key in obj) {
        addExpandProperty(obj[key]);
      }
    }
  };
  
  export const collectIdsRecursively = (data: any[]): any[] => {
    let ids: any[] = [];
  
    const collectIds = (items: any[]) => {
      items.forEach((item) => {
        if (item.id) {
          ids.push(item.id);
        }
        if (item.items) {
          collectIds(item.items);
        }
      });
    };
  
    collectIds(data);
    return ids;
  };
  
  export const getFinalStatus = (data: any) => {
    const status = data && data[0]?.items[2]?.status;
    return status === "Completed";
  };
  