// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-datagrid-header-panel {
  padding: 0;
}

.externalPsAccordion .dx-accordion {
  width: 100% !important;
}
.externalPsAccordion .header {
  font-size: 14px !important;
  font-weight: bold;
}
.externalPsAccordion .header-title {
  font-size: 14px !important;
  font-weight: bold;
  margin-left: 10px;
  padding: 5px;
}

#accordion {
  height: auto !important;
}

.dx-theme-fluent-typography {
  font-family: "Inter", sans-serif;
}

.dx-theme-fluent .dx-widget {
  font-family: "Inter", sans-serif;
}

.view-wrapper {
  overflow: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 var(--page-padding);
}

.clientinformation-toolbar-header {
  font-size: 15px;
  font-weight: 800;
  color: black !important;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/ClientInformation.scss"],"names":[],"mappings":"AAAC;EACG,UAAA;AACJ;;AAGI;EACE,sBAAA;AAAN;AAEI;EACE,0BAAA;EACA,iBAAA;AAAN;AAEI;EACE,0BAAA;EACA,iBAAA;EACA,iBAAA;EACA,YAAA;AAAN;;AAGE;EACE,uBAAA;AAAJ;;AAEE;EACE,gCAAA;AACJ;;AAGI;EACE,gCAAA;AAAN;;AAIE;EACE,gBAAA;EACA,aAAA;EACA,YAAA;EACA,sBAAA;EACA,8BAAA;AADJ;;AAIE;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;AADJ","sourcesContent":[" .dx-datagrid-header-panel {\n    padding: 0;\n  }\n  \n  .externalPsAccordion{\n    .dx-accordion{\n      width: 100%!important;\n    }\n    .header{\n      font-size: 14px!important;\n      font-weight: bold;\n    }\n    .header-title{\n      font-size: 14px!important;\n      font-weight: bold;\n      margin-left: 10px;\n      padding: 5px;\n    }\n  }\n  #accordion{\n    height: auto!important;\n  }\n  .dx-theme-fluent-typography {\n    font-family: \"Inter\", sans-serif;\n  }\n  \n  .dx-theme-fluent {\n    .dx-widget {\n      font-family: \"Inter\", sans-serif;\n    }\n  }\n\n  .view-wrapper {\n    overflow: hidden;\n    display: flex;\n    flex-grow: 1;\n    flex-direction: column;\n    padding: 0 var(--page-padding);\n  }\n  \n  .clientinformation-toolbar-header {\n    font-size: 15px;\n    font-weight: 800;\n    color: black!important;\n    text-decoration: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
