import React from 'react';
import Bullet, { Size, Tooltip } from 'devextreme-react/bullet';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import { CardAnalytics } from '../../library/finops-analytics/CardAnalytics';
import {convertToUSD} from '../../../../utlis/helper'
import '../../../../styles/finops/RevenueAnalysisCard.scss';

export const RevenueAnalysisCard = ({ datasource }: { datasource: any }) => {
  if (!datasource || datasource.length === 0) {
    return null; 
  }

  
  let totalIndex = datasource.findIndex((item: { name: string; }) => item.name === "Total");
  
 
  if (totalIndex !== -1) {
    let totalObject = datasource.splice(totalIndex, 1)[0];
    datasource.push(totalObject);
  }


  const convertedDatasource = datasource.map((item: any) => {
    return {
      ...item,
      value: item.value ? convertToUSD(item.value) : item.value,
     
    };
   
  });
  

  const isDesignationBased = 'designation' in datasource[0];
  const isResorceBased = 'resource' in datasource[0];
  const isTotalBased = 'total' in convertedDatasource[0];
 
  return (
    <CardAnalytics title='Cost Analysis' contentClass='sales-by-state grid'>
      <DataGrid 
      dataSource={convertedDatasource} 
      noDataText={"No Revenue Details to display at this time"}
      height={290}>
        <Column
            caption={isDesignationBased ? 'Designation' : isResorceBased ? 'Resource' : 'Name'}
            dataField={isDesignationBased ? 'designation' : isResorceBased ? 'resource' : 'name'}
            width={200}
            sortOrder='asc'
        />
        <Column
          alignment='left'
          caption='Cost'
          dataField={isTotalBased ? 'value' : 'value'}
          dataType='number'
          format='currency'
          sortOrder='asc'

          
        />
        <Column
          alignment='left'
          caption='Percentage'
          dataField='percentage'
          cssClass='sales-bullet'
          name='percentB'
          cellRender={(cellInfo) => {
            const percentage = cellInfo.data.percentage;
            return (
              <Bullet
                showTarget={false}
                showZeroLevel={false}
                value={percentage}
                startScaleValue={0}
                endScaleValue={100}
              >
                <Tooltip enabled={false} />
                <Size width={200} height={20} />
              </Bullet>
            );
          }}
          width={300}
        />
      </DataGrid>
    </CardAnalytics>
  );
};
