import React, { useEffect, useRef } from 'react';

import  { Button as TextBoxButton } from 'devextreme-react/text-box';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import '../../../../styles/ui-components/FormTextbox.scss';
import { TextArea } from "devextreme-react";

type TextboxProps = {
  value: string,
  label?: string,
  isEditing?: boolean,
  onValueChange: (data: any) => void,
  mask?: string,
  icon?: string,
  placeholder?: string, 
  height?:any
}

export const FormTextArea = ({ value, label, placeholder, isEditing, onValueChange, mask = '', icon, children,height }: React.PropsWithChildren<TextboxProps>) => {
  const validatorRef = useRef<any>(null); 
  const textBoxRef = useRef<any>(null);
  useEffect(()=>{
    if (validatorRef.current) {
      validatorRef.current.instance.reset(); // Reset validation
    }
    if (textBoxRef.current) {
      textBoxRef.current.instance.reset(); // Reset TextBox value
    }
  },[])

  return (
    <TextArea
      key="text-area"
      label={label}
      value={value}
      mask={mask}
      placeholder={placeholder}
      readOnly={isEditing}
      elementAttr={{ class: 'form-editor' }}
      inputAttr={{ class: 'form-editor-input' }}
      stylingMode='filled'
      onValueChange={onValueChange}
      height={height}
    >
      { icon &&
      <TextBoxButton
        name='icon'
        location='before'
        options={{
          icon: icon,
          stylingMode: 'text',
          elementAttr: { class: 'form-editor-icon' }
        }} />
      }
      <Validator ref={validatorRef}>
        <RequiredRule />
        {children}
      </Validator>
    </TextArea>
  );
};
