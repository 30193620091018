import React, { useEffect, useState } from "react";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { EmailRule } from "devextreme-react/validator";
import { Client } from "../../types";
import { TextArea } from "devextreme-react";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";

const ClientEditForm = ({
  newContactData,
  updateField,
}: {
  newContactData: Client;
  updateField: (data: any) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState(newContactData);

  const OnChangeUpdateField = (field: string) => (value: any) => {
    const updatedClient = { ...editedClient, [field]: value };
    setEditedClient(updatedClient);
    updateField(updatedClient);
  };

  useEffect(() => {
    setEditedClient(newContactData);
  }, [newContactData]);

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label="Organization Name"
            value={editedClient?.name}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("name")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Address 1"
            value={editedClient?.address1}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("address1")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Address 2"
            value={editedClient?.address2}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("address2")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="City"
            value={editedClient?.city}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("city")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="State"
            value={String(editedClient?.state?.stateShort)}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("state")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            value={editedClient?.email}
            onValueChange={OnChangeUpdateField("email")}
            isEditing={isEditing}
            label="Email"
            icon="email"
          >
            <EmailRule />
          </FormTextbox>
        </FormItem>

        <FormItem>
          <FormTextbox
            label="First Name"
            value={editedClient?.firstName}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("firstName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Last Name"
            value={editedClient?.lastName}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("lastName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            value={editedClient?.phone}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("phone")}
            icon="tel"
            label="Phone"
            mask="+1(000)000-0000"
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Primary Email"
            value={editedClient?.primary_email}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("primary_email")}
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Notes"
            value={editedClient?.note}
            onValueChange={OnChangeUpdateField("note")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Special Instructions"
            value={editedClient?.specialInstructions}
            onValueChange={OnChangeUpdateField("specialInstructions")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
      </GroupItem>

      <GroupItem cssClass="contact-fields-group">
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <TextArea
            label="Important Links and URLs"
            value={editedClient?.important_links_and_urls}
            onValueChange={OnChangeUpdateField("important_links_and_urls")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Technology Integration requirements"
            value={editedClient?.technology_integration_requirements}
            onValueChange={OnChangeUpdateField(
              "technology_integration_requirements"
            )}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="KPIs"
            value={editedClient?.kpis}
            onValueChange={OnChangeUpdateField("kpis")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="KeyMetrics and Reporting Frequency"
            value={editedClient?.keyMetrics_and_reporting_frequency}
            onValueChange={OnChangeUpdateField(
              "keyMetrics_and_reporting_frequency"
            )}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default ClientEditForm;
