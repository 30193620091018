import Card from "../../../../../../components/ui-components/card/card";
import EdgeTypesFlow from "../workflow-lineage/data-flow";

const WorkflowSteps = (props: any) => {

  const {
    newWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData,
    fileTypeData,
    transmitTypeData
  } = props;

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <EdgeTypesFlow
          newWorkflowData={newWorkflowData}
          updateWorkflowField={updateWorkflowField}
          actionData={actionData}
          functionFilterData={functionFilterData}
          typeData={typeData}
          fileTypeData={fileTypeData}
          transmitTypeData={transmitTypeData}
        />
      </Card>
    </div>
  );
};

export default WorkflowSteps;

