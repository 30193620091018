// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown {
  position: relative;
  display: inline-block;
  margin-right: "10px";
}

.toolbar-details-filter {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/activity-monitor/filterToolbar.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,qBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,mBAAA;AACF","sourcesContent":[".dropdown {\n  position: relative;\n  display: inline-block;\n  margin-right: '10px';\n}\n\n.toolbar-details-filter {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  margin-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
