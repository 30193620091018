import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  DataGridTypes,
  Button as ActionButton,
} from "devextreme-react/data-grid";
import { useStopProgress } from "../../../../../hooks/task/useInfra";
import "../../../../../styles/task/infra-data.scss";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import ConfirmModal from "./helper-component/ConfirmModal";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";
import AciProcessDetails from "./helper-component/AciProcessDetails";
import ReusableWorkflowDataGrid from "./helper-component/WorkflowGrid";

const WorkflowProcessDetails = (
  props: DataGridTypes.MasterDetailTemplateData
) => {
  const {
    execution_data=[],
    clientId,
    taskId,
    executionId,
    clientTag,
    customerName,
    taskName,
  } = props?.data?.data || {};

  const { pre_executions = [], post_execution = [], main_executions = [] } =
  execution_data[0] || {};

  const [popupVisible, setPopupVisible] = useState(false);
  const { mutate, isLoading, error, isSuccess } = useStopProgress();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `terminating container instance...`,
        type: "success",
      });
    } else if (error) {
      showNotification({
        message: `Error terminating container instance: ${error.message}`,
        type: "error",
      });
    }
  }, [isSuccess, isLoading, error]);

  const onHandleConfirm = useCallback(
    (e: any) => {
      const cotainerGroupData = e?.row?.data;
      const { containers } = cotainerGroupData;
      const containerName = containers[0]?.container_name;
      const queueMsg = {
        container_name: containerName,
        container_group_name: e?.row?.data?.group_name,
        module: "manual_shutdown_container",
        customer_id: clientId,
        taskId: taskId,
        executionId: executionId,
        clientTag: clientTag,
        clientName: customerName,
        taskName: taskName,
      };
      mutate(queueMsg);
    },
    [clientId, clientTag, customerName, executionId, mutate, taskId, taskName]
  );

  const onHandleModal = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const ConfirmModalProps = useMemo(() => {
    return {
      onHandleConfirm,
      onHandleModal,
      popupVisible,
      isLoading,
    };
  }, [onHandleConfirm, onHandleModal, popupVisible, isLoading]);

  const precolumns = [
    { dataField: "function_app_name", caption: "Function App Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status" },
  ];

  const postColumns = [
    { dataField: "function_app_name", caption: "Function App Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status" },
  ];

  const mainColumns = [
    { dataField: "group_name", caption: "Container Group Name" },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    { dataField: "status", caption: "Status" },
    {
      caption: "",
      type: "buttons",
      width: 130,
      cellRender: (e: any) => {
        return (
          <ActionButton
            text="STOP"
            cssClass="stop-icon-image"
            onClick={(e) => onHandleConfirm(e)}
            disabled={(e) => e?.row?.data?.status === "Completed"}
          />
        );
      },
    },
  ];

  const createGridProps = (
    data: any[],
    columns: any,
    detailComponent?: (props: any) => JSX.Element
  ) => ({
    dataSource: data?.map((item, index) => ({ ...item, id: index + 1 })) || [],
    columns,
    keyExpr: "id",
    ...(detailComponent && { detailComponent }),
  });

  return (
    <React.Fragment>
      <ReusableWorkflowDataGrid
        title="Pre-Processor"
        gridProps={createGridProps(pre_executions, precolumns)}
        pageProps={{
          nodataText: "No Function Details to display at this time",
        }}
      />
      <ReusableWorkflowDataGrid
        title="Main Processor"
        gridProps={createGridProps(
          main_executions,
          mainColumns,
          AciProcessDetails
        )}
        pageProps={{
          nodataText: "No Container Group Details to display at this time",
        }}
      />
      <ReusableWorkflowDataGrid
        title="Post-Processor"
        gridProps={createGridProps(post_execution, postColumns)}
        pageProps={{
          nodataText: "No Post Execution Details to display at this time",
        }}
      />
      <ConfirmModal {...ConfirmModalProps} />
    </React.Fragment>
  );
};

export default WorkflowProcessDetails;
