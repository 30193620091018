import "../../styles/core-components/staticinfo.scss";
import logos from "../../assets/images/shore-logo-1.png";

const PageError = () => {
  return (
    <div id="static-info-error">
      <div className="logo-wrapper">
        <img
          src={logos}
          alt="Logo"
          className="polarisLogo"
          style={{ width: "45px", height: "45px" }}
        />
        <span>Polaris</span>
      </div>
      <div className="error-text">
        <h1>
          Something went wrong. We’re sorry for the inconvenience. Please click
          <a href="/"> here</a> to return to the home page and try again. If the
          issue persists, feel free to contact our support team
        </h1>
      </div>
    </div>
  );
};

export default PageError;
