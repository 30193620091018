import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { FormTextbox } from "../../../ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../utlis/media-query";
import { InfrastructureData } from "../../../../types/infrastructure-add";
import { SelectBox } from "devextreme-react";
import {
  simpleProductLabel,
  resourceTypeOptions,
  typeOptions,
} from "../../../../config/constants";

export const InfrastructureNewForm = ({
  newInfrastructureData,
  onResourceTypeChanged,
  onTypeChanged,
  updateField,
}: {
  newInfrastructureData: InfrastructureData;
  onResourceTypeChanged: (e: string) => void;
  onTypeChanged: (e: string) => void;
  updateField: (field: string) => (value: any) => void;
}) => {
  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label="Resource Name"
            placeholder="Enter resource name..."
            value={newInfrastructureData.resource_name}
            isEditing={false}
            onValueChange={updateField("resource_name")}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            label="Resource Type"
            dataSource={typeOptions}
            displayExpr={(item) => item}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            onValueChange={(event: any) => onTypeChanged(event)}
          ></SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            label="Resource Size"
            dataSource={resourceTypeOptions}
            displayExpr={(item) => item}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            onValueChange={(event: any) => onResourceTypeChanged(event)}
          ></SelectBox>
        </FormItem>
        
        <FormItem>
          <FormTextbox
            label="Rate"
            placeholder="Rate..."
            value={
              newInfrastructureData.cost_per_day.startsWith("$")
                ? newInfrastructureData.cost_per_day
                : "$" + newInfrastructureData.cost_per_day
            }
            isEditing={false}
            onValueChange={updateField("cost_per_day")}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};
