import "../../../../styles/ui-components/FormPopup.scss";
import { useCallback, useRef, PropsWithChildren, useMemo } from "react";
import { Popup, ToolbarItem } from "devextreme-react/popup";
import ValidationGroup from "devextreme-react/validation-group";
import PsButton from "../../button/Button";

type PopupProps = {
  title: string;
  visible: boolean;
  width?: number | string;
  height?: number | string;
  wrapperAttr?: { class: string };
  isSaveDisabled?: boolean;
  setVisible: (visible: boolean) => void;
  onSave?: () => void;
  toolbarVisible?: boolean;
  isLoading?: boolean;
  fullScreen?: boolean;
};

export const FormPopup = ({
  title,
  visible,
  width = 800,
  height = 500,
  onSave,
  setVisible,
  fullScreen = false,
  wrapperAttr = { class: "" },
  isSaveDisabled = false,
  children,
  toolbarVisible = true,
  isLoading = false,
}: PropsWithChildren<PopupProps>) => {
  const validationGroup = useRef<ValidationGroup>(null);

  const close = useCallback(() => {
    validationGroup.current?.instance.reset();
    setVisible(false);
  }, [setVisible]);

  const onSaveClick = useCallback(() => {
    if (!validationGroup.current?.instance.validate().isValid) return;
    onSave && onSave();
  }, [onSave]);

  const loaderProps = useMemo(() => {
    return {
      loadertext: "Saving...",
      show: isLoading,
    };
  }, [isLoading]);

  return (
    <Popup
      title={title}
      visible={visible}
      onHiding={close}
      fullScreen={fullScreen}
      width={width}
      wrapperAttr={{
        ...wrapperAttr,
        class: `${wrapperAttr?.class} form-popup`,
      }}
      height={height}
    >
        <ToolbarItem toolbar="bottom" location="center" visible={toolbarVisible}>
          <div
            className={`form-popup-buttons-container ${width <= '360' ? "flex-buttons" : ""
              }`}
          >
            <PsButton text="Cancel" mode="outlined" type="normal" onClick={close} eventName="Popup close" />
            <PsButton
              text="Save"
              mode="contained"
              type="default"
              disabled={isSaveDisabled}
              onClick={onSaveClick}
              loaderProps={loaderProps}
              eventName="Form Popup Save button"
            />
          </div>
        </ToolbarItem>
        <ValidationGroup ref={validationGroup}>{children}</ValidationGroup>
    </Popup>
  );
};
