import "../../../styles/ui-components/ContactStatus.scss";

export const ContactStatus = ({
  text,
  contentClass = "",
  showText = true,
}: {
  text: any;
  contentClass?: string;
  showText?: boolean;
}) => {
  const transformedText = text
    ? String(text).toLowerCase().replace(/\s+/g, "")
    : "";
  const className = `status status-contact status-${transformedText} ${contentClass}`;

  return (
    <div className={className}>
      <span>{showText ? String(text) : ""}</span>
    </div>
  );
};
