import { Popup } from "devextreme-react/popup";
import PsButton from "../../../../../../components/ui-components/button/Button";
import { useDeleteInfrastructure } from "../../../../../../hooks/finops/useRatecard";

const InfrastructureDeletePopup = (props: any) => {
  const { deletePopupVisible, onDeletePopupClose, selectedRow } = props;
  const { row } = selectedRow || {};
  const { data } = row || {};
  const { id, resource_type } = data || {};
  const { isLoading, refetch, isRefetching } = useDeleteInfrastructure(
    onDeletePopupClose,
    id
  );

  const loaderProps = {
    show: isLoading || isRefetching,
    loadertext: "Please Wait...",
  };

  if (typeof window === "undefined" || !deletePopupVisible) return null;
  return (
    <Popup
      title="Delete Infrastructure Resource"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height="auto"
    >
        <div>
          <p>
            Are you sure you want to delete the <b>{resource_type}</b> ?
          </p>
          <p>
            <b>
              If you delete this Infrastructure Resource, you will not be able to
              recover it.
            </b>
          </p>
        </div>
        <div>
          <PsButton
            onClick={() => refetch()}
            text="Confirm"
            type="normal"
            mode="contained"
            loaderProps={loaderProps}
          />
          <div style={{ float: "right" }}>
            <PsButton
              onClick={onDeletePopupClose}
              text="Close"
              type="normal"
              mode="contained"
            />
          </div>
        </div>
    </Popup>
  );
};

export default InfrastructureDeletePopup;
