import { DataGridTypes, HeaderFilter } from "devextreme-react/cjs/data-grid";
import DataGrid, { Column } from "devextreme-react/data-grid";
import {
  useFetchDataAnalyzerMyFilesInfo,
  useGetDataProcessingDataApi,
} from "../../../../hooks/datastudio/useDataAnalyzer";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import { useState } from "react";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const dataCatalogId = props.data?.data?.dataCatalogId;
  const { data: myfilesInfo, isLoading } =
    useFetchDataAnalyzerMyFilesInfo(dataCatalogId);
  const [openPopup, setOpenPopup] = useState(false);
  const [filesData, setFilesData] = useState<any>();

  const { data: getFilesData, isLoading: isDataLoading } =
    useGetDataProcessingDataApi({
      filename: filesData?.name,
      userId: filesData?.userId,
      processType: filesData?.type,
      isFileUploaded: true,
    });

  const fileViewerData = JSON?.stringify(getFilesData?.data, null, 2);
  const fileExtensions = filesData?.name?.split(".").pop();

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const title = {
    file: filesData?.name,
    status: filesData?.status,
  };

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      name: data?.filename,
      userId: data?.userId,
      status: data?.status,
      type: data?.processType,
    };
    setFilesData(params);
    setOpenPopup(true);
  };

  const handleRowClick = (e: any) => {
    const { data } = e;
    const params = {
      file_name: data?.filename,
      folder_path: data?.filepath,
      status: data?.status,
    };
    setFilesData(params);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        alt="View File"
        src={MyFilesView}
        hint="View File"
        width={28}
        height={28}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  };

  if (isLoading) {
    return <PsSkeleton height={30} count={4} />;
  }

  return (
    <>
      <DataGrid
        dataSource={myfilesInfo}
        noDataText={
          isLoading ? "Loading..." : "Recent Updation Will Be Dispalyed Here"
        }
        allowColumnReordering
        showBorders
        allowColumnResizing={true}
        onRowClick={handleRowClick}
      >
        <HeaderFilter visible={true} />
        <Column
          caption="File Name"
          dataField="filename"
          dataType="string"
          width={350}
        />
        <Column
          caption="Uploaded By"
          dataField="userName"
          dataType="string"
          width={150}
        />
        <Column caption="Type" dataField="processType" dataType="string" />
        <Column
          caption="Status"
          dataField="status"
          dataType="string"
        />
        <Column
          caption="Uploaded On"
          dataField="created_at"
          dataType="datetime"
          cellRender={cellStartTime}
        />
        <Column
          caption="Last Updated"
          dataField="updated_at"
          dataType="datetime"
          cellRender={cellUpdatedTime}
        />
        <Column
          caption=""
          type="buttons"
          width={180}
          cellRender={renderViewFile}
        />
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={isDataLoading}
        extensionType={fileExtensions}
        params={filesData}
        showExportToDataLake={true}
      />
    </>
  );
};

export default MasterDetailView;
