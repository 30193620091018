// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border-radius: 4px;
  box-shadow: none;
  position: relative;
  border: 1px solid var(--border-color);
  overflow: hidden;
}
.card .header {
  padding: var(--content-padding) var(--content-padding) 0;
}
.card .overflow-menu {
  position: absolute;
  right: 8px;
  left: auto;
  top: 10px;
}
.card .title {
  color: var(--base-text-color);
  line-height: 19px;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
  display: inline-block;
  padding-right: 25px;
}
.card .card-contents {
  padding: 0 var(--content-padding) var(--content-padding);
  box-sizing: content-box;
  font-size: 14px;
  line-height: 17px;
  height: 270px;
}`, "",{"version":3,"sources":["webpack://./src/styles/finops/CardAnalytics.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,gBAAA;EACA,kBAAA;EACA,qCAAA;EACA,gBAAA;AADF;AAGE;EACE,wDAAA;AADJ;AAIE;EACE,kBAAA;EACA,UAAA;EACA,UAAA;EACA,SAAA;AAFJ;AAKE;EACE,6BAAA;EACA,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;EACA,mBAAA;AAHJ;AAME;EACE,wDAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;AAJJ","sourcesContent":["// @use '../../../variables.scss' as *;\n\n.card {\n  border-radius: 4px;\n  box-shadow: none;\n  position: relative;\n  border: 1px solid var(--border-color);\n  overflow: hidden;\n\n  .header {\n    padding: var(--content-padding) var(--content-padding) 0;\n  }\n\n  .overflow-menu {\n    position: absolute;\n    right: 8px;\n    left: auto;\n    top: 10px;\n  }\n\n  .title {\n    color: var(--base-text-color);\n    line-height: 19px;\n    font-weight: 500;\n    font-size: 16px;\n    margin-bottom: 20px;\n    display: inline-block;\n    padding-right: 25px;\n  }\n\n  .card-contents {\n    padding: 0 var(--content-padding) var(--content-padding);\n    box-sizing: content-box;\n    font-size: 14px;\n    line-height: 17px;\n    height: 270px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
