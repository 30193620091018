const IntegrationTab = () => {
  return (
    <>
      <div className="widget-container">
        <div className="integrationPage">
          <p>
            Integration Tab is currently under construction. Check back later!
          </p>
        </div>
      </div>
    </>
  );
};

export default IntegrationTab;
