import DataGrid, {
  Column,
  Paging,
  HeaderFilter,
  Pager,
  MasterDetail,
  SearchPanel
} from "devextreme-react/data-grid";
import {
  useFetchDocumentAnalyzerPDFApi,
  useFetchDocumentMetricsInfo,
  useFetchDocumentAnalyzeTelemetry
} from "../../../../../hooks/datastudio/useDocumentAnalyze";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";
import { allowedPageSizes } from "../../../../../config/datastudio/commonConfig";
import MyFilesView from "../../../../../assets/icons/icon-eye.png";
import MetricsIcon from "../../../../../assets/icons/icon-metrics-4.png";
import TelemetryIcon from "../../../../../assets/icons/icon-telemetry-5.png";
import { useMemo, useState } from "react";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import TableSkeleton from "../../../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";
import MasterDetailView from "./helper-component/documentInfo";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../../contexts/pageContext";

const MyDocuments = ({
  onRowClick,
  isFetching,
  documentLoading,
  setPdfFinalData,
  pdfFinalData,
  setMetricsData,
  metricsData,
  setTelemetryData,
  telemetryDatas,
  apiProps,
  handleColumnIndexChange,
}: any) => {

  const [openPopup, setOpenPopup] = useState(false);

  const { data: PdfFiles, isLoading: isPdfLoading } =
    useFetchDocumentAnalyzerPDFApi(pdfFinalData);

  const { data: MetricsInfoData, isLoading: isMetricsLoading } = useFetchDocumentMetricsInfo(metricsData);

  const { data: TelemetryInfoData, isLoading: isTelemetryLoading } = useFetchDocumentAnalyzeTelemetry(telemetryDatas?.dataCatalogId, false, true);

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleViewPdf = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setMetricsData(null);
    setTelemetryData(null);
    const params = {
      fileName: data?.fileName,
      userId: data?.userId,
      processType: data?.processType,
      status: data?.status,
      ext: 'pdf',
      name: data?.fileName,
      type: data?.processType,
    };
    setPdfFinalData(params);
    setOpenPopup(true);
  };
  
  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        hint="View File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewPdf(e, cell)}
      />
    );
  };

  const handleViewMetrics = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setPdfFinalData(null);
    setTelemetryData(null);
    const params = {
      fileName: data?.fileName,
      userId: data?.userId,
      processType: data?.processType,
      status: data?.status,
      dataCatalogId: data?.dataCatalogId,
      ext: 'json'
    };
    setMetricsData(params);
    setOpenPopup(true);
  };

  const renderViewMetricsFile = (cell: any) => {
    return (
      <PSIconText
        src={MetricsIcon}
        alt="Metrics File"
        hint="Metrics File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewMetrics(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    );
  };

  const handleViewTelemetry = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setPdfFinalData(null);
    setMetricsData(null);
    const params = {
      fileName: data?.fileName,
      userId: data?.userId,
      processType: data?.processType,
      status: data?.status,
      dataCatalogId: data?.dataCatalogId,
      ext: 'json'
    };
    setTelemetryData(params);
    setOpenPopup(true);
  };

  const renderViewTelemetryFile = (cell: any) => {
    return (
      <PSIconText
        src={TelemetryIcon}
        alt="Telemetry File"
        hint="Telemetry File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewTelemetry(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    );
  };

  const title = {
    file: pdfFinalData?.fileName || metricsData?.fileName || telemetryDatas?.fileName,
    status: pdfFinalData?.status || metricsData?.status || telemetryDatas?.status,
  };

  const extension = pdfFinalData?.ext || metricsData?.ext || telemetryDatas?.ext;

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => onRowClick(cell)}
      >
        {cell.data.fileName}
      </span>
    );
  };

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    fileName : fileNameColumn,
    originalFileName : originalFileNameColumn,
    fileSize : fileSizeColumn,
    docStage : docStageColumn,
    userName : userNameColumn,
    status : statusColumn,
    created_at : createdatColumn,
    last_updated : lastupdatedColumn,
  } = filterValue || {};

  const columns = useMemo(
    () => [
       {
        caption:"System File Name",
          dataField:"fileName",
          dataType:"string",
          filterValues: fileNameColumn,
          cellRender:renderFileName
       },
       {
        caption:"Original File Name",
        dataField:"originalFileName",
        dataType:"string",
        filterValues: originalFileNameColumn,
       },
       {
         caption:"File Size",
         dataField:"fileSize",
         dataType:"string",
         filterValues: fileSizeColumn,
       },
       {
        caption:"File Type",
        dataField:"docStage",
        dataType:"string",
        filterValues: docStageColumn,
       },
       {
        caption:"Created By",
        dataField:"userName",
        dataType:"string",
        filterValues: userNameColumn,
       },
       {
        caption:"Status",
        dataField:"status",
        dataType:"string",
        cellRender:ContactStatus,
        filterValues: statusColumn,
       },
       {
        caption:"Created Date",
        dataField:"created_at",
        dataType:"datetime",
        cellRender:cellStartTime,
        filterValues: createdatColumn,
       },
       {
        caption:"Modified Date",
        dataField:"last_updated",
        dataType:"datetime",
        cellRender:cellUpdatedTime,
        filterValues: lastupdatedColumn,
       },
       {
        caption:"",
        type:"buttons", 
        cellRender:renderViewMetricsFile,
        width:70
       },
       {
        caption:"", 
        type:"buttons",
        cellRender:renderViewTelemetryFile,
        width:70
       },
       {
        caption:"",
        type:"buttons", 
        cellRender:renderViewFile,
        width:70
       }
    ], 
      [fileNameColumn, originalFileNameColumn, fileSizeColumn, docStageColumn, userNameColumn,
        statusColumn, createdatColumn, lastupdatedColumn
      ])

  const pageProps = useMemo( 
    () => ({
    nodataText: "No Recent Details to display at this time",
    heightClass: "height-large",
    id: "mydocumentsgrid",
  }), []);

  const gridProps =useMemo( 
    () => ({
    dataSource: DataSource,
    columns,
    keyExpr: "dataCatalogId",
    allowedPageSizes,
    searchPanelVisible: true,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    detailComponent: MasterDetailView,
   handleColumnIndexChange,
  }), [handleColumnIndexChange, columns]);

  if (isFetching || documentLoading) {
    return <TableSkeleton />;
  }

  return (
    <div className="recent-activity-grid">
         <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={PdfFiles || MetricsInfoData || JSON?.stringify(TelemetryInfoData, null, 2)}
        title={title}
        isLoading={isPdfLoading || isMetricsLoading || isTelemetryLoading}
        extensionType={extension}
        params={pdfFinalData}
        showExportToDataLake={true}
      />
    </div>
  );
};

export default MyDocuments;
