export interface DocumentAnalyzerState {
  analyzeData: string;
  uploadData: string;
  process: string;
  analyzerId: string;
  addFiles: any[];
  gridNoDataText: string;
  analysisStarted: boolean;
  fileUploaded: boolean;
  pdfFile: any;
  uploadProgress: number;
}

export type AddFilesProps = {
  handleFilesUpload: (e: any) => void
  isPdfSaving: boolean
}

export const FormatTitle = (title: any) => {
  const formatKey = (key: string) => {
    return key
      ?.replace(/([A-Z])/g, ' $1')
      ?.toLowerCase();
  };

  const titleData = {
    file: title?.file,
    status: title?.status,
  };

  return (
    <div className="titlecontainer">
      <div className="title-text">Document Analyzer</div>
      <div className="title-details">
        {Object?.entries(titleData)?.map(([key, value]) =>
          value ? (
            <div key={key} className="title-detail">
              {`${formatKey(key)}: ${value}`}
            </div>
          ) : null
        )}
      </div>
    </div>
  );
};