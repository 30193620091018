import { useCallback, useMemo, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  OnConnect,
  MiniMap,
  Background,
  Controls,
  MarkerType,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { Modal } from "./FlowPopup";

const generateNodesAndEdges = (workflowData: any) => {
  const nodes = workflowData?.map((step: any, index: any) => ({
    id: (index + 1)?.toString(),
    data: {
      step: `Step-${index + 1}`,
      label: `Name: ${step?.name}`,
      description: step?.description,
      additionalInfo: step?.action,
    },
    sourcePosition: index < workflowData?.length - 1 ? "bottom" : undefined,
    targetPosition: index > 0 ? "top" : undefined,
    position: { x: 100, y: index * 250 },
    type: "textUpdater",
  }));

  const edges = workflowData?.slice(1)?.map((_: any, index: any) => ({
    id: `${index + 1}-${index + 2}`,
    source: (index + 1)?.toString(),
    target: (index + 2)?.toString(),
    markerEnd: { type: MarkerType?.ArrowClosed },
  }));

  return { nodes, edges };
};

const EdgeTypesFlow = (props: any) => {
  const { data } = props;
  const workflowData = data?.workflow;

  const { nodes: initialNodes, edges: initialEdges } = useMemo(
    () => generateNodesAndEdges(workflowData),
    [workflowData]
  );

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const [showModal, setShowModal] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);

  const onModalToggle = useCallback(
    (nodeId?: string) => {
      if (nodeId) {
        setCurrentNodeId(nodeId);
      }
      setShowModal(!showModal);
    },
    [showModal]
  );

  const formProps = useMemo(() => ({ data }), [data]);

  const nodeTypes = useMemo(
    () => ({
      textUpdater: (props: any) => (
        <TextUpdaterNode {...props} onClick={() => onModalToggle(props?.id)} />
      ),
    }),
    [onModalToggle]
  );

  return (
    <>
      <div style={{ width: "100%", height: "100vh" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <MiniMap />
          <Background />
          <Controls />
        </ReactFlow>
        <Modal
          showModal={showModal}
          onModalToggle={onModalToggle}
          formData={formProps}
          nodeId={currentNodeId}
          steps={`Step-${currentNodeId}`}
        />
      </div>
    </>
  );
};

export default EdgeTypesFlow;
