import { TextArea } from "devextreme-react";

const MetaTab = (props: any) => {

  const { data } = props;

  const metaData = data?.meta

  return (
    <>
      <div className="dx-field">
        <div className="dx-field-label">Tags</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.tags}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Description</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.description}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Content Type</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.headers?.contentType}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Content Length</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.headers?.contentLength}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Encoding</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.headers?.encoding}
            readOnly={true}
          />
        </div>
      </div >
      <div className="dx-field">
        <div className="dx-field-label">Catalog</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.catalog}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Index</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.index}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Environment</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.environment}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Retry Count</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.retryCount}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Duration Ms</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.durationMs}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Geo Location</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.geoLocation}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">OS Version</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.additionalInfo?.osVersion}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Python Version</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.additionalInfo?.pythonVersion}
            readOnly={true}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Docker Info</div>
        <div className="dx-field-value">
          <TextArea
            value={metaData?.additionalInfo?.dockerInfo}
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
}

export default MetaTab;