// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.IconText-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.IconText-container.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.IconText-container:hover {
  transform: scale(1.1);
}

.text-size {
  font-size: 8px;
  font-weight: 400;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/IconText.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACJ;AACI;EACE,YAAA;EACA,mBAAA;EACA,oBAAA;AACN;;AAGE;EACE,qBAAA;AAAJ;;AAGE;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AAAJ","sourcesContent":[".IconText-container {\n    display: flex;\n    flex-direction: column; \n    align-items: center; \n    justify-content: center;\n    cursor: pointer;\n  \n    &.disabled {\n      opacity: 0.5;               \n      cursor: not-allowed;        \n      pointer-events: none;       \n    }\n  }\n\n  .IconText-container:hover{\n    transform: scale(1.1);\n  }\n  \n  .text-size {\n    font-size: 8px;\n    font-weight: 400;\n    text-align: center; \n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
