// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessListContainer {
  margin: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.rolesCheckboxes {
  display: flex;
  gap: 20px;
}

.projectDetailsContainer {
  padding: 10px;
}

.projectRolesCheckboxes {
  display: flex;
  gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/user-administration/accesslist.scss"],"names":[],"mappings":"AACA;EACI,YAAA;EACA,aAAA;EACA,yBAAA;EACA,SAAA;AAAJ;;AAGA;EACI,aAAA;EACA,SAAA;AAAJ;;AAGA;EACI,aAAA;AAAJ;;AAGE;EACE,aAAA;EACA,SAAA;AAAJ","sourcesContent":["\n.accessListContainer {\n    margin: 20px;\n    display: flex;\n    justify-content: flex-end;\n    gap: 20px;\n}\n\n.rolesCheckboxes {\n    display: flex;\n    gap: 20px;\n  }\n\n.projectDetailsContainer {\n    padding: 10px;\n  }\n\n  .projectRolesCheckboxes {\n    display: flex;\n    gap: 20px;\n  }\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
