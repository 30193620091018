import React from 'react';
import PieChart, {
  Series,
  Label,
  Connector,
  Legend,
} from 'devextreme-react/pie-chart';

function formatLabel(arg: { argumentText: string; valueText: string; }) {
  return `${arg.argumentText}: $${arg.valueText}`;
}

function InfraPieChart({datasource}:any) {

  const dataWithNumericValues = datasource?.map((item: { value: string; }) => ({
    ...item,
    value: parseFloat(item.value.replace('$', ''))
  }));

  return (
    <PieChart
      id="pie"
      dataSource={dataWithNumericValues}
      palette="Bright"
      title="Infra Cost Analysis"
    >
      <Series
        argumentField="resource"
        valueField="value"
      >
        <Label visible={true} customizeText={formatLabel} format="fixedPoint">
          <Connector visible={true} width={0.5} />
        </Label>
      </Series>
      <Legend horizontalAlignment="center" verticalAlignment="bottom" />
    </PieChart>
  );
}

export default InfraPieChart;
