import { convertToUSD } from '../../../../utlis/helper';
import '../../../../styles/finops/techCost-analytics.scss';
export const TickerCard = ({ title, value }: { title: string, value: number }) => {
    const formattedValue = convertToUSD(value);
    return (
        <div className='tickerCostDetails'>
            <div className='middle'>
                <div className='title'>
                    {title}
                </div>
                <div className='total'>
                    {formattedValue}
                </div>
            </div>
        </div>
    );
};