import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { ValidationProps } from "../types";
import ResultGrid from "./ResultGrid";
import ApplyIcon from "../../../../assets/icons/icon-apply-2.png";
import { DropDownButton, List } from "devextreme-react";
import { useFetchTemplatedropdown } from "../../../../hooks/datastudio/useDataAnalyzer";
import ResetIcon from "../../../../assets/icons/icon-reset-1.png";

const Validation = (props: ValidationProps) => {
  const {
    tabView,
    resultGridProps,
    selectedTemplate,
    totalrowsprocessed,
    totalrowsvalidated,
    totalrowswitherrors,
    isVisibile,
    handleSelectionChanged,
    handleResetButton,
    handleListClick,
    handleApplyClick,
    isDataLoading,
  } = props;
  const { toggle } = tabView["validation"];

  const { data: templatedata, isLoading: isTemplateLoading } = useFetchTemplatedropdown();
  const templateDropdown =
    templatedata?.map((item: any) => item?.fileName) || [];

  const result = isDataLoading
    ? ["Loading..."]
    : [
        `Errors found: ${totalrowswitherrors}`,
        "Warnings found: 0",
        `Rows validated: ${totalrowsvalidated}`,
        `Rows processed: ${totalrowsprocessed}`,
        `Rows with errors: ${totalrowswitherrors}`,
        `Total rows: ${totalrowsprocessed}`,
      ];

  return (
    <>
      {toggle ? (
        <div className="validation-telematry">
          <div className="validation-dropdown">
            <DropDownButton
              text={selectedTemplate || "Select a Template"}
              items={templateDropdown}
              stylingMode="text"
              hoverStateEnabled
              useSelectMode
              height={30}
              noDataText={isTemplateLoading ? "Loading..." : "No Data"}
              onSelectionChanged={handleSelectionChanged}
              icon="file"
            />
          </div>
          <div className="validation-content">
            <div className="validation-stats">
              {isVisibile ? (
                <List
                  items={result}
                  onItemClick={handleListClick}
                />
              ) : null}
            </div>
            <div className="validation-apply-reset">
              <PSIconText
                text="Apply"
                alt="Apply"
                src={ApplyIcon}
                onClick={handleApplyClick}
                disabled={selectedTemplate === "Select a Template"}
              />
              <PSIconText
                text="Reset"
                alt="Reset"
                src={ResetIcon}
                onClick={handleResetButton}
                disabled={!tabView["validation"]?.toggle}
              />
            </div>
          </div>
        </div>
      ) : null}
      <ResultGrid {...resultGridProps} />
    </>
  );
};

export default Validation;
