import DataGrid, { Column } from 'devextreme-react/data-grid';
import { CellDollar } from "../../ui-components/dataGridFormat/dataGridFormat"
import { convertToUSD } from '../../../utlis/helper';

export function CustomTitle(data: any) {
  return <>
    <div className="header">{data?.title}</div>
    <div className="headertitle" > Total Cost {convertToUSD(data?.totalCost)} </div>
  </>
}

export function CustomItem(data: any) {

  return (
    <div>
      {data.infraCost && (
        <DataGrid
          dataSource={data.infraCost}
          noDataText={"No Infrastructures  to display at this time"}
          allowColumnReordering={true}
          showBorders={true}
        >
          <Column dataField="resource" caption="Resource" />
          <Column dataField='value' caption='Value' />
          <Column dataField="status" caption="Status" />
        </DataGrid>
      )}
      {data.techCost && (
        <DataGrid
          dataSource={data.techCost}
          noDataText={"No Digital Services  to display at this time"}
          allowColumnReordering={true}
          showBorders={true}
        >
          <Column dataField="designation" caption="Role" />
          <Column dataField='value' caption='Value' cellRender={CellDollar} />
          <Column dataField="status" caption="Status" />
        </DataGrid>
      )}
      {data.operationCost && (
        <DataGrid
          dataSource={data.operationCost}
          noDataText={"No Operations  to display at this time"}
          allowColumnReordering={true}
          showBorders={true}
        >
          <Column dataField="designation" caption='Role' />
          <Column dataField="value" caption='Value' cellRender={CellDollar} />
          <Column dataField='status' caption='Status' />
        </DataGrid>
      )}
    </div>
  );
}