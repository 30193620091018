import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { Template } from "../../types";
import { SelectBox } from "devextreme-react";
import FileUploader from 'devextreme-react/file-uploader';
import { getSizeQualifier } from "../../../../../../utlis/media-query";
import { FormTextbox } from "../../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { STATUS_LIST } from "../../../../../../config/constants";

const TemplateNewForm = ({
  newTemplateData,
  updateField,
  onStatusChanged,
  onTemplateTypeChanged,
  getTemplateTypeList
}: {
  newTemplateData: Template;
  updateField: (field: string) => (value: any) => void;
  onTemplateTypeChanged: (e:string) => void;
  onStatusChanged: (e: string) => void;
  getTemplateTypeList:any
}) => {

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label="Template Name"
            value={newTemplateData.template_name}
            isEditing={false}
            onValueChange={updateField("template_name")}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            label="Template Types"
            dataSource={getTemplateTypeList}
            onValueChange={(event: any) => onTemplateTypeChanged(event)}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Note"
            value={newTemplateData.notes}
            isEditing={false}
            onValueChange={updateField("notes")}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            label="Status"
            dataSource={STATUS_LIST}
            displayExpr={(item) => item}
            onValueChange={(event: any) => onStatusChanged(event)}
          />
        </FormItem>
      </GroupItem>
      <FormItem>
        <div className="file-uploader-container">
          <FileUploader
            labelText="Upload Template File"
            selectButtonText="Import File"
            accept=".json"
            uploadMode="useForm"
            onValueChanged={(e: any) => updateField("file")(e?.value[0])}
          />
        </div>
      </FormItem>
    </Form>
  );
};

export default TemplateNewForm;