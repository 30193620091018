import { useEffect, useState } from "react";
import classNames from "classnames";
import Form, { SimpleItem, GroupItem } from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import { TASK_STATUS } from "../../../../../config/constants";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { ProjectData } from "../../types";
import DatePicker from "devextreme-react/date-box";
import { useFetchProjectType } from "../../../../../hooks/client/useProject";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { useAppHook } from "../../../../../hooks/app/useApps";

const ProjectUrlPanelDetails = (props: any) => {
  const { data, editing, onDataChanged, isProjectUpdated } = props;
  const [formData, setFormData] = useState<ProjectData>({ ...data });
  const { data: projectTypeData } = useFetchProjectType("function");
  const projectType = projectTypeData?.functionType?.map(
    (item: any) => item.projectType
  );

  const { clientData } = useAppHook();

  useEffect(() => {
    setFormData({ ...data });
  }, [data, clientData]);

  const ClientNameValues = clientData?.map((item: { name: any }) => item?.name);

  const updateField = (field: string) => (value: any) => {
    const newData = { ...formData, [field]: value };
    onDataChanged(newData);
    setFormData(newData);
  };


  return (
    <>
      <Form
        className={classNames({
          "plain-styled-form task-form-details": true,
          "view-mode": !editing,
        })}
        screenByWidth={getSizeQualifier}
        colCount={1}
      >
        <GroupItem itemType="group" colCount={1}>
          <SimpleItem>
            <SelectBox
              label="Client Name"
              value={formData.clientName}
              items={ClientNameValues}
              readOnly={!editing}
              stylingMode="filled"
              onValueChange={updateField("clientName")}
            />
          </SimpleItem>
          <SimpleItem>
            <SelectBox
              label="Project Type"
              value={formData.projectType}
              items={projectType}
              readOnly={!editing}
              stylingMode="filled"
              onValueChange={updateField("projectType")}
            />
          </SimpleItem>
          <SimpleItem cssClass="accent">
            <FormTextbox
              label="Project Name"
              value={formData.projectName}
              isEditing={!editing}
              onValueChange={updateField("projectName")}
            />
          </SimpleItem>
          <SimpleItem cssClass="accent">
            <FormTextbox
              label="Project Description"
              value={formData.projectDescription}
              isEditing={!editing}
              onValueChange={updateField("projectDescription")}
            />
          </SimpleItem>
          <SimpleItem>
            <FormTextbox
              label="Project Manager Name"
              placeholder="Project Manager Name"
              value={formData.projectManagerName}
              isEditing={false}
              onValueChange={updateField("projectManagerName")}
            />
          </SimpleItem>
          <SimpleItem>
            <FormTextbox
              label="Project Manager Email"
              placeholder="Project Manager Email"
              value={formData.projectManagerEmail}
              onValueChange={updateField("projectManagerEmail")}
              isEditing={false}
            >
            </FormTextbox>
          </SimpleItem>
          <SimpleItem>
            <FormTextbox
              label="Project Manager Phone"
              placeholder="Project Manager Phone"
              value={formData.projectManagerPhone}
              isEditing={false}
              onValueChange={updateField("projectManagerPhone")}
            />
          </SimpleItem>
          <SimpleItem>
            <SelectBox
              label="Status"
              value={formData.status}
              items={TASK_STATUS}
              readOnly={!editing}
              stylingMode="filled"
              onValueChange={updateField("status")}
            />
          </SimpleItem>
          <SimpleItem>
            <FormTextbox
              label="Monthly Budget"
              placeholder="Monthly Budget"
              value={formData.budgetMonth}
              isEditing={false}
              onValueChange={updateField("budgetMonth")}
            />
          </SimpleItem>
          <SimpleItem>
            <FormTextbox
              label="Yearly Budget"
              placeholder="Yearly Budget"
              value={formData.budgetYear}
              isEditing={false}
              onValueChange={updateField("budgetYear")}
            />
          </SimpleItem>
          <SimpleItem>
            <DatePicker
              label="Start Date"
              value={formData.startDate}
              disabled={!editing}
              onValueChange={updateField("startDate")}
            />
          </SimpleItem>
          <SimpleItem>
            <DatePicker
              label="End Date"
              value={formData.endDate}
              onValueChange={updateField("endDate")}
            />
          </SimpleItem>
        </GroupItem>
      </Form>
    </>
  );
};

export default ProjectUrlPanelDetails;
