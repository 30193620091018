import { TextArea } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import ActionSheet, { ActionSheetTypes } from "devextreme-react/action-sheet";
import logos from "../../../assets/images/shore-logo-1.png";
import "../../../styles/ui-components/Chat.scss";
import { useApp } from "../../../contexts/app";
import PsButton from "../button/Button";
import { DayDateFormat,getUserColor} from "../../../utlis/helper";
import { ScrollView } from 'devextreme-react';
import { useFetchProjectCollaboration, useFetchSendProjectCollaboration } from "../../core-component/hooks/useCollaboration";
import { useHelperHooks } from "../../../hooks/helper/utils";

const newMessage = {
  msg: "",
};

const CollaborationChats = () => {
  const projectId = useHelperHooks().getQueryParams("projectId");
  const clientId = useHelperHooks().getQueryParams("clientId");
  const taskId = useHelperHooks().getQueryParams("taskId");
  const { user } = useApp();

  const msgId = projectId
    ? {
        projectId: projectId,
      }
    : clientId
    ? {
        clientId: clientId,
      }
    : taskId
    ? {
        taskId: taskId,
      }
    : null;

  const { data, refetch } = useFetchProjectCollaboration(msgId);
  const { mutate, isSuccess, isLoading } = useFetchSendProjectCollaboration();

  const [newMessageData, setNewMessageData] = useState<any>({
    ...newMessage,
  });
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);
  const [showReplyUI, setShowreplyUI] = useState(false);
  const [selectedText, setSelectedText] = useState("");
  const [actionSheetTarget, setActionSheetTarget] = useState<
    HTMLElement | string
  >("");
  const actionSheetItems = [{ text: "Reply" }, { text: "Delete" }];

  const updateField = (field: string) => (value: any) => {
    setNewMessageData({ ...newMessage, ...{ [field]: value } });
  };

  useEffect(() => {
    if (isSuccess) {
      setNewMessageData({ ...newMessage });
      refetch();
    }
  }, [isSuccess]);

  const onListItemClick = useCallback(
    (e: any) => {
      setShowreplyUI(false);
      setIsActionSheetVisible(true);
      setActionSheetTarget(e.target);
      setSelectedText(e.target.innerText);
    },
    [setIsActionSheetVisible, setActionSheetTarget]
  );

  const onActionSheetItemClick = useCallback(
    (e: ActionSheetTypes.ItemClickEvent) => {
      if (e?.itemData?.text === "Reply") {
        setShowreplyUI(true);
      }
    },
    []
  );

  const onVisibleChange = useCallback(
    (isVisible: boolean) => {
      if (isVisible !== isActionSheetVisible) {
        setIsActionSheetVisible(isVisible);
      }
    },
    [setIsActionSheetVisible, isActionSheetVisible]
  );

  const cancelReplyUI = () => {
    setShowreplyUI(false);
  };
  const handleFormSubmit = useCallback(() => {
    if (!newMessageData.msg) return;
    newMessageData["client_id"] = clientId;
    newMessageData["task_id"] = taskId;
    newMessageData["projectId"] = projectId;
    newMessageData["title"] = "dev";
    newMessageData["client_name"] = clientId;
    newMessageData["task_name"] = taskId;
    newMessageData["projectName"] = projectId;
    newMessageData["user"] = user?.name;
    newMessageData["is_master"] = true;
    mutate(newMessageData);
  }, [clientId, mutate, newMessageData, taskId, projectId, user]);

  const renderChats = () => {
    return (
      <ol className="chat">
        {data?.map((chat: any, index: number) => {
          const isMe = chat?.user === user?.name;
          const chatDate = chat?.date;
          const { color, initials } = getUserColor(chat?.user || "");
          const chatUser = chat?.user;
          const formattedChatDate = DayDateFormat(chatDate);
          return (
            <li key={index} className={isMe ? "me" : "them"}>
              <div className={`chat-info ${isMe ? "me-info" : ""}`}>
                <div className="user-details">
                  <div
                    className="user-icon"
                    style={
                      { "--user-icon-bg-color": color } as React.CSSProperties
                    }
                  >
                    {initials}
                  </div>
                  <small>
                    {chatUser} {formattedChatDate}
                  </small>
                </div>
              </div>
              <div className={`chat-msgblock ${isMe ? "me-msgblock" : ""}`}>
                <blockquote onClick={onListItemClick}>{chat?.msg}</blockquote>
              </div>
            </li>
          );
        })}
      </ol>
    );
  };

  return (
    <>
    <ScrollView>
      <div className="collaborationChat">
        {renderChats()}
        <ActionSheet
          title="Action"
          usePopover={true}
          visible={isActionSheetVisible}
          target={actionSheetTarget}
          items={actionSheetItems}
          onItemClick={onActionSheetItemClick}
          onVisibleChange={onVisibleChange}
        />
        {showReplyUI ? (
          <div className={`reply_UI ${showReplyUI ? "margincss" : ""}`}>
            <blockquote>
              <img
                src={logos}
                alt="Logo"
                className="polarisLogo"
                style={{ width: "30px", height: "30px" }}
              />
              {selectedText}
            </blockquote>
            <PsButton
              type="default"
              text={"Cancel"}
              mode="outlined"
              onClick={cancelReplyUI}
              eventName="Chat reply button event"
            />
          </div>
        ) : null}
        <div className={`action_block ${showReplyUI ? "margincss" : ""} `}>
          <TextArea
            placeholder="Type your message"
            value={newMessageData.msg}
            onValueChange={updateField("msg")}
          />
       
        </div>
        <PsButton
          type="default"
          text={isLoading ? "Sending..." : "Send"}
          onClick={handleFormSubmit}
          eventName="Chat send button event"
        />
      </div>
      </ScrollView>
    </>
  );
};

export default CollaborationChats;
