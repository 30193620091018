import Popup from "devextreme-react/popup";
import PsButton from "../ui-components/button/Button";
import { MergeColumnConfirmModalProps } from "./type";
import { TagBox, TextBox } from "devextreme-react";
import { useState } from "react";

const MergeColumnConfirmModal = (props: MergeColumnConfirmModalProps) => {
  const { show, onConfirm, columnData, onClose } = props;
  const [selectedData, setSelectedData] = useState<any>({
    columnName: "",
    replaceValue: "",
    separatorValue: "",
  });

  const loaderProps = {
    show: false,
    loadertext: "Please Wait...",
  };

  const setSelectedColumn = (e: any) => {
    const columns = e?.length === 1 ? e[0] : e;
    setSelectedData((prevData: any) => ({
      ...prevData,
      columnName: columns,
    }));
  };

  const setReplaceValue = (e: any) => {
    const replaceValue = e?.value;
    const columnNames = selectedData?.columnName;

    if (columnNames) {
        setSelectedData((prevData: any) => ({
          ...prevData,
          replaceValue,
        }));
      }
    }

    const setSeparator = (e: any) => {
    const separatorValue = e?.value;
    const columnNames = selectedData?.columnName;

    if (columnNames) {
        setSelectedData((prevData: any) => ({
          ...prevData,
          separatorValue,
        }));
      }
    }

  return (
    <Popup
      title="Merge Columns"
      visible={show}
      width="auto"
      height="auto"
      onHiding={onClose}
      onShowing={() => setSelectedData({ columnName: [], replaceValue: [] })}
    >
      <div style={{ display: "flex", marginTop: "10px", marginBottom: "20px" }}>
        <TagBox
          items={columnData}
          placeholder="Select column(s)"
          onValueChange={(e) => {
            setSelectedColumn(e);
          }}
          showClearButton={true}
          showSelectionControls={true}
          width={250}
          value={
            Array.isArray(selectedData?.columnName)
              ? selectedData?.columnName
              : [selectedData?.columnName]
          }
        />
        <div style={{ marginLeft: "20px" }}>
          <TextBox
            placeholder='New Column Name'
            showClearButton={true}
            width={250}
            onValueChanged={setReplaceValue}
            value={selectedData?.replaceValue}
          />
        </div>
        <div style={{ marginLeft: "20px" }}>
          <TextBox
            placeholder='Enter a separator key'
            showClearButton={true}
            width={250}
            onValueChanged={setSeparator}
            value={selectedData?.separatorValue}
          />
        </div>
      </div>

      {(
        <div style={{ color: "red", marginBottom: "20px" }}>
          <i>Separator key is not required if the selected columns contains only numeric values.</i>
        </div>
      )}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PsButton
          text="Merge"
          onClick={() => onConfirm(selectedData)}
          loaderProps={loaderProps}
          mode="contained"
          type="default"
        />
        <PsButton text="Close" onClick={onClose} type="normal" />
      </div>
    </Popup>
  );
};

export default MergeColumnConfirmModal;
