import { useCallback, useEffect, useState } from "react";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { DigitalServicesData } from "../../../../../../types/digital-services-add";
import { newDigitalFormData } from "../../../../../../config/constants";
import { DigitalServicesNewForm } from "../../../../../../components/finops/library/digital-services-form/DigitalServicesNewForm";
import { useCreateDigitalServices } from "../../../../../../hooks/finops/useRatecard";

export const DigitalServicesFormPopup = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;

  const [newDigitalServicesData, setnewDigitalServicesData] =
    useState<DigitalServicesData>({
      ...newDigitalFormData,
    });

  const updateField = (field: string) => (value: any) => {
    setnewDigitalServicesData({
      ...newDigitalServicesData,
      ...{ [field]: value },
    });
  };

  const { mutate, isLoading } = useCreateDigitalServices(changePopupVisibility);

  useEffect(() => {
    if (!popupVisible) {
      setnewDigitalServicesData({ ...newDigitalFormData });
    }
  }, [popupVisible]);

  const handleFormSubmit = useCallback(() => {
    mutate(newDigitalServicesData);
  }, [mutate, newDigitalServicesData]);

  if (typeof window === "undefined") return null;
  return (
    <FormPopup
      title="Add New Digital Services Role"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <DigitalServicesNewForm
        newDigitalServicesData={newDigitalServicesData}
        updateField={updateField}
      />
    </FormPopup>
  );
};
