 import { useCallback, useEffect, useState } from "react";
import {
    ReactFlow,
    addEdge,
    Node,
    Edge,
    useNodesState,
    useEdgesState,
    OnConnect,
    MiniMap,
    Background,
    Controls,
    Panel,
    ColorMode,
    Position,
    NodeTypes,
    } from "@xyflow/react";
 import CustomNode from "./CustomNode";
import { DayDateFormat } from "../../utlis/helper";
import { useFetchAuditLogSpecificLineageNodeApi } from "../../hooks/activity-monitor/useAuditLogs";
import PsPopUp from "../../components/ui-components/PsPop-up/PsPopUp";
import ReactJson from "react-json-view";

    const nodeDefaults = {
    sourcePosition: Position.Right,
    targetPosition: Position.Left,
    };

    const initialNodes: Node[] = [];
    const initialEdges: Edge[] = [];

    const AuditLogLineage = (props: any) => {
    const { data } = props;
    const [colorMode, setColorMode] = useState<ColorMode>("light");
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [selectedReferenceId, setSelectedReferenceId] = useState<string | null>(null);
    const [selectedLogData, setSelectedLogData] = useState<any>(null);
    const [popupVisible, setPopupVisible] = useState(false);

    const { data: NodeData, isLoading } = useFetchAuditLogSpecificLineageNodeApi(selectedReferenceId ?? "");

    useEffect(() => {
        if (data && data?.length > 0) {
          const newNodes = data.map((log: any, index: number) => {
            const hasReference = log?.referenceid && log?.referenceid.trim() !== "";
      
            return {
              id: `node-${index}`,
              type: "custom",
              position: { x: index * 300, y: index % 2 === 0 ? 100 : 250 },
              data: {
                title: (
                  <>
                    <strong>User : </strong> {log?.userName}
                  </>
                ),
                label: (
                  <span className={hasReference ? "badge-green" : "badge-blue"}>
                    {log.userAction}
                  </span>
                ),
                additionalInfo: (
                    <>
                    <strong>Timestamp : </strong> {DayDateFormat(log?.timeStamp)}
                    </>
                ),
                hasReference,
                onClick: () => hasReference && handleNodeClick(log),
              },
              ...nodeDefaults,
            };
          });
      
          const newEdges = newNodes.map((_: Node, index: number) => {
            if (index < newNodes?.length - 1) {
              return {
                id: `edge-${index}`,
                source: newNodes[index].id,
                target: newNodes[index + 1].id,
                markerEnd: { type: "arrowclosed" },
                animated: true,
                style: { stroke: "#6cb3f8", strokeWidth: 2 },
              };
            }
            return null;
          }).filter(Boolean);
      
          setNodes(newNodes);
          setEdges(newEdges);
        }
      }, [data]);

    const onConnect: OnConnect = useCallback(
        (params) => {
        setEdges((eds) => addEdge(params, eds));
        },
        [setEdges]
    );

    const handleNodeClick = (nodeData: any) => {
        if (nodeData?.referenceid && nodeData?.referenceid.trim() !== "") {
        setSelectedReferenceId(nodeData?.referenceid);
        setSelectedLogData(nodeData);
        setPopupVisible(true);
        } else {
        setPopupVisible(false);
        setSelectedReferenceId(null);
        setSelectedLogData(null);
        }
    };

    const closePopup = () => {
        setPopupVisible(false);
        setSelectedReferenceId(null);
        setSelectedLogData(null);
    };

    const nodeTypes: NodeTypes = {
        custom: CustomNode,
    };

    return (
        <div style={{ width: "100%", height: "100vh" }}>
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            colorMode={colorMode}
            nodeTypes={nodeTypes}
            maxZoom={2}
        >
            <MiniMap />
            <Background />
            <Controls />
            <Panel position="top-right">
            <select onChange={(e) => setColorMode(e.target.value as ColorMode)}>
                <option value="light">Light</option>
                <option value="dark">Dark</option>
                <option value="system">System</option>
            </select>
            </Panel>
        </ReactFlow>

        {popupVisible && selectedReferenceId && (
            <PsPopUp
            visible={popupVisible}
            onHiding={closePopup}
            fullScreen={true}
            title={selectedLogData?.assetName}
            isLoading={isLoading}
            component={
              <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}>
               <ReactJson
                name={false}
                src={NodeData || {}}
                enableClipboard={false}
                style={{ padding: "20px" }}
                displayDataTypes={false}
                />
              </pre>
            }
          />
        )}
        </div>
    );
    };

    export default AuditLogLineage;