import { useFetchProjectInvoiceInfo } from "../../../../hooks/client/useProjectProfile";
import InvoiceInformations from "../../../core-component/invoiceComponent/invoiceInformation";
import { useHelperHooks } from "../../../../hooks/helper/utils";

const FinopsTab = (props: any) => {
  const { projectData } = props;
  const projectId = useHelperHooks().getQueryParams("projectId");
  const clientId = projectData?.[0]?.clientId;
  const { data: invoiceData, isLoading } = useFetchProjectInvoiceInfo(
    clientId as string,
    projectId as string
  );
  return <InvoiceInformations clientInfo={invoiceData} isLoading={isLoading} />;
};

export default FinopsTab;
