
import React, { useCallback, useEffect, useState } from "react";
import DateRangeBox from "devextreme-react/date-range-box";
import DropDownButton from "devextreme-react/drop-down-button";
import { DateboxProps } from "./types";
import "../../../styles/ui-components/DateBox.scss";
import { usePage } from "../../../contexts/pageContext";
import { useLocation } from "react-router-dom";
import { formatDate } from "devextreme/localization";
import { customDateRange } from "../../../config/constants";

const DateDropDown = (props: DateboxProps) => {
  const { onDateChange, data, handleDropDownPageIndex} = props;
  const { getPageProperties, updatePageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { formattedStartTime, formattedEndTime, timePeriodName } = filterValue || {};
  const allDate = "All"; 
  const [selectedOption, setSelectedOption] = useState<string>(timePeriodName ? timePeriodName : allDate);
  const [dateboxData, setDateboxData] = useState<any[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(true);
  const { pathname } = useLocation();
  const path = pathname.split("/").join("");

  useEffect(() => {
    if (data) {
      setDateboxData(data?.dateRange);
    }
  }, [data]);

  const handleOptionSelect = useCallback(
    (option: string) => {
      setSelectedOption(option);
      updatePageProperties(path, {
        timePeriodName: option,
        formattedStartTime: null,
        formattedEndTime: null
      });
      
      setDropdownVisible(true);
      const endTime = new Date();
      switch (option) {
        case allDate : 
         onDateChange({ value: [new Date("1990-01-01"), endTime, allDate] })
        break;
        case dateboxData?.[0].datepick:
          const today = new Date();
          today?.setDate(today?.getDate());
          const frequencyToday = "today";
          let todays = {
            value: [today, endTime, frequencyToday],
          };
          onDateChange(todays);
          break;
        case dateboxData?.[1].datepick:
          const startOfWeek = new Date(
            endTime?.getFullYear(),
            endTime?.getMonth(),
            endTime?.getDate() - endTime?.getDay()
          );
          const frequencyWeek = "thisWeek";
          let thisWeek = { value: [startOfWeek, endTime, frequencyWeek] };
          onDateChange(thisWeek);
          break;
        case dateboxData?.[2].datepick:
          const startOfMonth = new Date(
            endTime?.getFullYear(),
            endTime?.getMonth(),
            1
          );
          const frequencyMonth = "thisMonth";
          let thisMonth = { value: [startOfMonth, endTime, frequencyMonth] };
          onDateChange(thisMonth);
          break;
        case dateboxData?.[3].datepick:
          setDropdownVisible(false);
          updatePageProperties(path, {
            timePeriod: customDateRange,
          });
          break;
        case dateboxData?.[4].datepick:
          const last7DaysStartDate = new Date();
          last7DaysStartDate?.setDate(last7DaysStartDate?.getDate() - 6);
          const frequency7days = "last7Days";
          let lastSevenDays = {
            value: [last7DaysStartDate, endTime, frequency7days],
          };
          onDateChange(lastSevenDays);
          break;
        case dateboxData?.[5].datepick:
          const last30DaysStartDate = new Date();
          last30DaysStartDate?.setDate(last30DaysStartDate?.getDate() - 29);
          const frequency30days = "last30Days";
          let lastThirtyDays = {
            value: [last30DaysStartDate, endTime, frequency30days],
          };
          onDateChange(lastThirtyDays);
          break;
        default:
          break;
      }
    },
    [onDateChange, dateboxData]
  );

  const handleCustomDateChange = (e: any) => {
    const { value } = e;
    if (value) {
      const customdays = customDateRange;
      const customDate = { value: [value?.[0], value?.[1], customdays] };
      onDateChange(customDate);
      updatePageProperties(path, {
        formattedStartTime: formatDate( value?.[0], "yyyy-MM-dd"),
        formattedEndTime: formatDate( value?.[1], "yyyy-MM-dd"),
        timePeriod: customdays
      });
      
    }
  };


  return (
    <div className="datebox">
        <DropDownButton
          dropDownOptions={{ width: "auto" }}
          splitButton={false}
          stylingMode="text"
          text={selectedOption}
          displayExpr="datepick"
          items={[{ datepick: allDate }, ...dateboxData]}
          onItemClick={(e) => {handleOptionSelect(e?.itemData?.datepick)
            handleDropDownPageIndex()}
          }
        />
      {selectedOption === dateboxData?.[3]?.datepick && (
        <div className="custom-date-range-container">
          <DateRangeBox
            className="custom-date-range"
            multiView={true}
            onValueChanged={handleCustomDateChange}
            defaultStartDate={formattedStartTime}
            defaultEndDate={formattedEndTime}
          />
        </div>
      )}
    </div>
  );
};

export default DateDropDown;
