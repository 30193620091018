import { ChangeEventHandler, useCallback, useEffect, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Panel,
  ColorMode,
  Position,
  NodeTypes,
} from "@xyflow/react";
import CustomNode from "./CustomNode";
import { DayDateFormat } from "../../utlis/helper";
const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};


const nodeProperties = [
  {
    label: "Profile",
    dataField: "clientName",
  },
  {
    label: "Client Data",
    dataField: "",
    defaultText:"Polaris-data-lake (Zenith Document Processing)"
  },
  {
    label: "Location",
    dataField: "location",
  },
  {
    label: "File",
    dataField: "Exception",
    defaultText: "Exception List",
  },
];

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const ProjectCatlogLineage = (props: any) => {
  const { data } = props;
  const [colorMode, setColorMode] = useState<ColorMode>("light");
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const renderTitleNode = (item: any) => {
    return (
      <ul style={{ listStyle: "none" }}>
        <li>Client: {item?.clientName}</li>
        <li>Project: {item?.projectShortName}</li>
        <li>projectType: {item?.projectType}</li>
        <li>ProjectTag: {item?.projectTag}</li>
      </ul>
    );
  };

  useEffect(() => {
    if (data) {
      const newNodes: any = nodeProperties
        .map((property, index) => {
          const { label, dataField, defaultText } = property;
          const title =
          label === "Profile" ? renderTitleNode(data) : null;
  
          return {
            id: `node-${dataField}-${index}`,
            type: "custom",
            position: { x: index * 300, y: 150 },
            data: {
              label: label,
              title: title || data[dataField] || defaultText,
              additionalInfo: (
                <ul style={{ listStyle: "none" }}>
                  <li>
                    Date: {DayDateFormat(data["startDate"]) || "N/A"}
                  </li>
                </ul>
              ),
            },
            ...nodeDefaults,
          };
        })
        .filter(Boolean);

      const newEdges = [];
      for (let i = 0; i < newNodes.length - 1; i++) {
        newEdges.push({
          id: `${newNodes[i].id}-${newNodes[i + 1].id}`,
          source: newNodes[i].id,
          target: newNodes[i + 1].id,
        });
      }

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [data, setEdges, setNodes]);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onChange: ChangeEventHandler<HTMLSelectElement> = (evt) =>
    setColorMode(evt.target.value as ColorMode);

  const nodeTypes: NodeTypes = {
    custom: CustomNode,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        colorMode={colorMode}
        nodeTypes={nodeTypes}
        maxZoom={2}
      >
        <MiniMap />
        <Background />
        <Controls />
        <Panel position="top-right">
          <select onChange={onChange} data-testid="colormode-select">
            <option value="light">light</option>
            <option value="dark">dark</option>
            <option value="system">system</option>
          </select>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default ProjectCatlogLineage;
