import { useCallback, useEffect, useState } from 'react';
import DropDownBox, { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import DataGrid, {
  Selection, Paging, Scrolling, HeaderFilter
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import '../../../../styles/finops/estimator.scss';
import { useFinopsProject } from '../../../../hooks/app/useApps';
import { useFetchProject } from '../../../../hooks/finops/useEstimator';

const gridColumns = ['projectName', 'clientName', 'projectDescription'];
const ownerLabel = { 'aria-label': 'Owner' };

function EstimatorProjectList({ onDataChanged, selectedEstimator }: {
  onDataChanged: (data: any) => void, selectedEstimator: any
}) {
  const [gridBoxValue, setGridBoxValue] = useState([]);
  const [isGridBoxOpened, setIsGridBoxOpened] = useState(false);
  const [projectIdsString, setProjectIdsString] = useState("");
  const [selectedProjects, setSelectedProjects] = useState([]);
  const { data: projectData } = useFetchProject("")
  const { data: projectDetailsData } = useFinopsProject(projectIdsString)

  useEffect(() => {
    if (selectedProjects.length > 0 && projectDetailsData) {
      const combinedData = selectedProjects.map((project: any) => {
        return {
          ...projectDetailsData,
          estimatorId: selectedEstimator?.estimaterId,
          estimatorName: selectedEstimator?.name,
        };
      });
      onDataChanged(combinedData);
    }
  }, [projectDetailsData, selectedProjects, selectedEstimator, onDataChanged]);

  const dataGridOnSelectionChanged = useCallback((e: { selectedRowKeys: any, selectedRowsData: any }) => {
    setGridBoxValue(e.selectedRowKeys);
    setIsGridBoxOpened(false);
    setSelectedProjects(e.selectedRowsData);
    setProjectIdsString(e.selectedRowsData?.map((project: any) => project.projectId).join(', '));
  }, []);

  const makeAsyncDataSource = (localData: any[]) => {
    const transformedData = localData?.map(item => {
      const { id, ...rest } = item;
      return { ID: id, ...rest };
    });

    if (!Array.isArray(transformedData) || transformedData.length === 0 || !transformedData[0].hasOwnProperty("ID")) {
      // console.error("Error: Invalid localData format", transformedData);
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "ID",
      load() {
        return transformedData;
      },
    });
  };

  const gridDataSource = makeAsyncDataSource(projectData);

  const gridBoxDisplayExpr = (item: { projectName: any; clientName: any }) =>
    item && `${item.projectName} - ${item.clientName}`;

  const dataGridRender = useCallback(
    () => (
      <DataGrid
        dataSource={gridDataSource}
        columns={gridColumns}
        noDataText={'No Projects to display at this time'}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={dataGridOnSelectionChanged}
        height="100%"
        allowColumnReordering
        allowColumnResizing
      >
        <Selection mode="single" />
        <Scrolling mode="virtual" />
        <Paging
          enabled={true}
          pageSize={10}
        />
        <HeaderFilter visible />
        {/* <FilterRow visible={true} /> */}
      </DataGrid>
    ),
    [gridBoxValue, gridDataSource, dataGridOnSelectionChanged],
  );

  const syncDataGridSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
    setGridBoxValue(e.value);
  }, []);

  const onGridBoxOpened = useCallback((e: DropDownBoxTypes.OptionChangedEvent) => {
    if (e.name === 'opened') {
      setIsGridBoxOpened(e.value);
    }
  }, []);


  return (
    <div className="dx-fieldset">
      <div className="dx-field">
        <div className="dx-field-label">Select Your Project</div>
        <div className="dx-field-value">
          <DropDownBox
            value={gridBoxValue}
            opened={isGridBoxOpened}
            valueExpr="ID"
            deferRendering={false}
            inputAttr={ownerLabel}
            displayExpr={gridBoxDisplayExpr}
            placeholder="Select a value..."
            showClearButton={true}
            dataSource={gridDataSource}
            onValueChanged={syncDataGridSelection}
            onOptionChanged={onGridBoxOpened}
            contentRender={dataGridRender}
            className='dx-field-value'
          />
        </div>
      </div>
    </div>
  );
}

export default EstimatorProjectList;
