import Card from "../../../ui-components/card/card";
import { useFetchYTDMTDCost } from "../../../../hooks/client/useProjectProfile";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import { convertToUSD } from "../../../../utlis/helper";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";


export const YtdMtdCostsCard = (props: any) => {
  const projectId = useHelperHooks().getQueryParams("projectId");

  const { data: ytdMtdCost, isFetching } = useFetchYTDMTDCost(projectId as string);

  const totalYTDCost = ytdMtdCost?.YTD?.totalCost;
  const totalMTDCost = ytdMtdCost?.MTD?.totalCost;

  const renderCostDetails = (costDetails: any, labelSuffix: string) => {
    return costDetails.map((detail: any, index: any) => (
      <div className="detail-row" key={index}>
        <span className="costLabel" style={{ width: "220px" }}>
          {detail.name} Charges ({labelSuffix})
        </span>
        <span className="value" style={{ textAlign: "end" }}>
          {convertToUSD(detail.value)}
        </span>
      </div>
    ));
  };

  return (
    <Card className="card_wrapper">
      <div className="ytdmtdContainer">
        <div className="ytdMtdCostContainer">
          {isFetching ? (
            <>
              <PsSkeleton height={30} count={10} />
            </>
          ) : (
            <>
              {totalYTDCost && renderCostDetails(totalYTDCost, "YTD")}
              {totalMTDCost && renderCostDetails(totalMTDCost, "MTD")}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default YtdMtdCostsCard;
