import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { useEffect, useRef } from "react";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { ProjectData } from "../../types";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { SelectBox } from "devextreme-react/select-box";
import {
  simpleProductLabel,
  TASK_STATUS,
} from "../../../../../config/constants";
import DatePicker from "devextreme-react/date-box";
import { useFetchClient } from "../../../../../hooks/app/useApps";
import { generateShortName } from "../../../../../utlis/helper";
import { FormTextArea } from "../../../../../components/ui-components/form/form-textbox/FormTextArea";

const ProjectNewForm = ({
  newProjectData,
  updateField,
  onOptionChanged,
  onOptionTaskActive,
  handleProjectTypeChange,
  handleStartDateChange,
  selectedStartDate,
  handleEstimatorTypeChange,
  getProjectTypeList,
}: {
  newProjectData: ProjectData;
  updateField: (field: string) => (value: any) => void;
  onOptionChanged: (e: {
    clientId: string;
    name: string;
    clientTag: string;
    clientShortName: string;
  }) => void;
  onOptionTaskActive: (e: string) => void;
  handleProjectTypeChange: (e: string) => void;
  handleStartDateChange: (value: any) => void;
  selectedStartDate: Date | null;
  handleEstimatorTypeChange: (value: any) => void;
  getProjectTypeList: any;
}) => {
  const { data } = useFetchClient();

  useEffect(() => {
    if (newProjectData.projectName) {
      const newShortName = generateShortName(newProjectData.projectName);
      updateField("projectShortName")(newShortName);
    }
  }, [updateField, newProjectData.projectName]);

  const handleOptionProjectType = (e: any) => {
    handleProjectTypeChange(e);
  };

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} />
        <FormItem>
          <FormTextbox
            placeholder="Project Name..."
            value={newProjectData?.projectName}
            isEditing={false}
            label="Project Name"
            onValueChange={updateField("projectName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Project Short Name..."
            value={newProjectData?.projectShortName}
            isEditing={false}
            label="Project Short Name"
            onValueChange={updateField("projectShortName")}
          />
        </FormItem>
        <FormItem>
          <FormTextArea
            placeholder="Project Description..."
            value={newProjectData?.projectDescription}
            label="Project Description"
            onValueChange={updateField("projectDescription")}
            height={100}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={getProjectTypeList}
            searchEnabled={true}
            displayExpr="projectType"
            inputAttr={simpleProductLabel}
            label="Project Type"
            placeholder="Project Type..."
            onValueChange={(event: any) => handleOptionProjectType(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Project Manager Name"
            label="Project Manager Name"
            value={newProjectData?.projectManagerName}
            isEditing={false}
            onValueChange={updateField("projectManagerName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            value={newProjectData?.projectManagerEmail}
            onValueChange={updateField("projectManagerEmail")}
            isEditing={false}
            placeholder="Email"
            label="Email"
          ></FormTextbox>
        </FormItem>
        <FormItem>
          <FormTextbox
            value={newProjectData?.projectManagerPhone}
            isEditing={false}
            onValueChange={updateField("projectManagerPhone")}
            label="Phone"
            mask="+1(000)000-0000"
          />
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={data}
            displayExpr="name"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            label="Client Name"
            placeholder="Select a Client..."
            onValueChange={(event: any) => onOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={[]}
            searchEnabled={true}
            displayExpr="name"
            inputAttr={simpleProductLabel}
            label="Resource Estimate"
            placeholder="Existing Estimate ..."
            onValueChange={(value: any) => handleEstimatorTypeChange(value)}
          >
            {/* <Validator>
              <RequiredRule />
            </Validator> */}
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={TASK_STATUS}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            defaultValue={TASK_STATUS[0]}
            label="Status"
            placeholder="Status..."
            onValueChange={(event: any) => onOptionTaskActive(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Monthly Budget"
            placeholder="Monthly Budget"
            value={newProjectData?.budgetMonth}
            isEditing={false}
            onValueChange={updateField("budgetMonth")}
          />
        </FormItem>

        <FormItem>
          <FormTextbox
            label="Yearly Budget"
            placeholder="Yearly Budget"
            value={newProjectData?.budgetYear}
            isEditing={false}
            onValueChange={updateField("budgetYear")}
          />
        </FormItem>
        <FormItem>
          <DatePicker
            label="Start Date"
            value={selectedStartDate ?? undefined}
            onValueChanged={(e) => handleStartDateChange(e.value as Date)}
            placeholder="Start Date"
            displayFormat="yyyy-MM-dd"
          >
            <Validator>
              <RequiredRule message="Date is required" />
            </Validator>
          </DatePicker>
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default ProjectNewForm;
