import { useEffect } from "react";
import Popup from "devextreme-react/popup";
import PsButton from "../../../../components/ui-components/button/Button";
import { useLocation, useNavigate } from "react-router-dom";

export const FinalizeConfirmation = (props: any) => {
  const {
    finalizePopupVisible,
    onFinalizePopupClose,
    fileCount,
    documentIds,
    DocumentCatalogBulkFinalizeMutate,
    isFinalizeLoading,
    isFinalizeSuccess
  } = props;

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleFinalizeProcess = () => {
    const selectedDocuments = documentIds
    DocumentCatalogBulkFinalizeMutate(selectedDocuments);
  };

  useEffect(() => {
    if (isFinalizeSuccess) {
      onFinalizePopupClose();
      navigate(pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinalizeSuccess]);

  const loaderProps = {
    show: isFinalizeLoading,
    loadertext: "Finalizing...",
  };

  return (
    <Popup
      title="Finalize File Confirmation"
      visible={finalizePopupVisible}
      onHiding={onFinalizePopupClose}
      width="30%"
      height="220px"
    >
      <div className="task-start">
        <p>Number of files selected to finalize: <b>{fileCount}</b>.</p>
        <p>Are you sure you want to finalize the selected files?</p>
      </div>
      <div style={{ float: "left" }}>
        <PsButton onClick={handleFinalizeProcess} text="Confirm" type="normal" mode="contained" loaderProps={loaderProps} />
      </div>
      <div style={{ float: "right" }}>
        <PsButton onClick={onFinalizePopupClose} text="Cancel" type="normal" mode="contained" />
      </div>
    </Popup>
  );
};
