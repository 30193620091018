import OpportunitiesTicker from "./OpportunitiesTicker";
import { useFetchActiveDocument } from "../../../../../hooks/datastudio/useDocumentCatalog";
import { useNavigate } from 'react-router-dom';
import "../../../../../styles/datastudio/ManagementView.scss";

const ActiveDocuments = (props: any) => {
  const { selectedDays } = props;
  const { data: activeDocument } = useFetchActiveDocument(selectedDays) as any;
  const navigate = useNavigate();
  console.log(activeDocument);

  const parseAndValidateInt = (value: any) => {
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const handleClick = (
    event: any,
  ) => {
    navigate(`/document-catalog`);
    event.preventDefault();
  };

  return (
    <div className="cards compact" style={{ columnGap: "-10px", marginTop: "5px", marginLeft: "-10px" }}>
      <OpportunitiesTicker
        title="Pending"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.Pending
        )}
        colur="black"
      />
      <OpportunitiesTicker
        title="Completed"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.Completed
        )}
        colur="green"
      />
      <OpportunitiesTicker
        title="Failed"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.Failed
        )}
        colur="red"
      />
      <OpportunitiesTicker
        title="Ready For Review"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.["Ready for Review"]
        )}
        colur="grey"
      />
      <OpportunitiesTicker
        title="In Progress"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.["In Progress"]
        )}
        colur="blue"
      />
      <OpportunitiesTicker
        title="Rejected"
        onClick={handleClick}
        value={parseAndValidateInt(
          activeDocument?.summary?.Rejected
        )}
        colur="red"
      />
    </div>
  );
};

export default ActiveDocuments;