import { fetchActiveTaskApi } from "../../service/client/api-service";
import { useQuery } from "react-query";

const fetchActiveTaskCountApi = async (data: any) => {
  const response: any = await fetchActiveTaskApi(data);
  if (response) {
    return response;
  }
};

export const useFetchActiveTask = (data: any) => {
  return useQuery(
    ["fetch-active-task", data],
    () => fetchActiveTaskCountApi(data),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
