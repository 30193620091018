import "../../../styles/client/ClientInformation.scss";
import { ContactStatus } from "../../../components/ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellTimeZone,
} from "../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../config/client/commonConfig";
import MasterDetailView from "./helper-component/ProjectInfo";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../components/ui-components/dataGrid/DataGrid";
import { useCallback, useMemo } from "react";
import { usePage } from "../../../contexts/pageContext";
import { useNavigate } from "react-router-dom";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const ProjectLineageGrid = ({
  handleColumnIndexChange,
  apiProps
}: any) => {
 
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const navigate = useNavigate();
  const {
    projectName : projectNameColumn,
    clientName : clientNameColumn,
    projectDescription : projectDescriptionColumn,
    projectType : projectTypeColumn,
    status : statusColumn,
    startDate : startDateColumn,
    endDate : endDateColumn
  } = filterValue || {};
 
  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string, projectName: any) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
      const eventName = `project_profile: ${projectName}_${projectId}`;
      GTAButtonEventHandler(eventName);
    },
    [navigate]
  );

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string, clientName: any) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
      const eventName = `client_profile: ${clientName}_${clientId}`;
      GTAButtonEventHandler(eventName);
    },
    [navigate]
  ); 
  
  const columns = useMemo( 
    () => [
    {
      dataField:"projectName",
      caption:"Project Name",
      width:"30%",
      filterValues: projectNameColumn, 
      allowHeaderFiltering:true,
      allowSorting:true,
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId, data?.data?.projectName)}
        >
          {data?.data?.projectName}
        </a>
      )},
    {
      dataField:"clientName",
      caption:"Client Name",
      width:"20%",
      filterValues : clientNameColumn, 
      allowSorting:true,
      allowHeaderFiltering:true,
      cellRender: (data: any) => (
        <a
          href="null"
          onClick={(e) => handleClientLinkClick(e, data?.data?.clientId, data?.data?.clientName)}
        >
          {data?.data?.clientName}
        </a>
      ),},
    {
      dataField:"projectDescription",
      dataType:"string",
      allowSorting:true,
      filterValues : projectDescriptionColumn,
      allowFiltering:false,
      caption:"Project Description",
      width:"30%"
    },
    {
      dataField:"projectType",
      allowSorting:true,
      dataType:"string",
      filterValues : projectTypeColumn,
      caption:"Activity Type",
      width:"20%",
      allowExporting:true
    },
    {
      dataField:"status",
      dataType:"string",
      filterValues: statusColumn,
      cellRender:ContactStatus,
      width:"10%"
    },
    {
      dataField:"startDate",
      dataType:"string",
      cellRender:cellTimeZone,
      filterValues: startDateColumn,
      width:"20%"
    },
    {
      dataField:"endDate",
      dataType:"string",
      cellRender:cellEndTime,
      filterValues : endDateColumn,
      width:"10%"
    }
  ], [projectNameColumn, clientNameColumn, projectDescriptionColumn, projectTypeColumn, statusColumn, 
    startDateColumn, endDateColumn])

  const pageProps = useMemo(
    () => ({
    nodataText: "No data to display at this time",
    heightClass: "height-large",
    id: "projectLineageGrid",
  }), []);

  const gridProps =useMemo( 
    () => ({
    dataSource: DataSource,
    columns,
    keyExpr: "projectId",
    allowedPageSizes,
    searchPanelVisible: true,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    handleColumnIndexChange,
    detailComponent : MasterDetailView,
  }), [ columns, handleColumnIndexChange ]);

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      </div>
    </>
  );
};

export default ProjectLineageGrid;
