import { useCallback, useState } from "react";
import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import { ClientUrl } from "../types";
import UrlEditForm from "./url-form/UrlEditForm";
import { useCreateUrl } from "../../../../hooks/client/useClientUrl";

const EditUrl = (props: any) => {
  const { EditpopupVisible, changeEditPopupVisibility, selectedClientData } = props;
  const [formData, setData] = useState<ClientUrl>();

  const { row } = selectedClientData || {};
  const { data: selectedClientUrl } = row || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility()
  }, [changeEditPopupVisibility]);

  const { mutate,isLoading } = useCreateUrl(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const OnChangeUpdateField = useCallback(
    (data: React.SetStateAction<ClientUrl | undefined>) => {
      setData(data);
    }, []
  );

  return (
    <>
      <FormPopup
        title="Edit Client URL"
        visible={EditpopupVisible}
        setVisible={changeEditPopupVisibility}
        onSave={handleFormSubmit}
        isLoading={isLoading}
      >
        <UrlEditForm
          newContactData={selectedClientUrl}
          updateField={OnChangeUpdateField}
        />
      </FormPopup>
    </>
  )
}

export default EditUrl;