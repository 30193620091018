export type WorkSpacelayoutProps = {
  headerText: string;
  additionalToolbarContent?: React.ReactNode;
  additionalToolbarBeforeContent?: React.ReactNode;
  //renderLeftSideCard: () => JSX.Element;
  cardDetails: CardDetailsProps[];
  activityData?: any;
  showToolbar?: boolean;
};

export type WorkSpaceProps = {
  workSpacePanelOpen: boolean;
  onToggleWorkSpaceClick: () => void;
};

export type WorkBookProps = {
  workBookPanelOpen: boolean;
  changePanelOpened: () => void;
};

export type WorkbookButtonProps = {
  show: boolean;
  onClick: () => void;
  text: string;
};

export type CardDetailsProps = {
  title: string;
  buttonProps?: WorkbookButtonProps;
  Component: React.FC<any>;
  componentProps: any;
  componentId: string;
  hasCard?: boolean;
};

export interface WorkBookItemData {
  activityName: string;
  createdBy: string | undefined;
  content?: string;
  activityDescription: string;
  activityType: string;
  workbookId?: string;
  workbookName?: string;
}

export const newAddItemFormData: WorkBookItemData = {
  activityName: '',
  createdBy: '',
  content: '',
  activityDescription: '',
  activityType: '',
  workbookId: '',
  workbookName: '',
};

export interface WorkBookItemDataEntry {
  activityName: string;
  activityDescription: string;
  content?: string;
  createdBy: string | undefined;
  workbookId?: string;
  workbookName?: string;
  activityType?: string;
}

export const newAddItemDataEntryFormData: WorkBookItemDataEntry = {
  activityName: '',
  activityDescription: '',
  content: '',
  createdBy: '',
  workbookId: '',
  workbookName: '',
  activityType: '',
};

export interface WorkBookItemDataAnalyse {
  activityName: string;
  activityDescription: string;
  content?: string;
  createdBy: string | undefined;
  workbookId?: string;
  workbookName?: string;
  activityType?: string;
}

export const newAddItemDataAnalyseFormData: WorkBookItemDataEntry = {
  activityName: '',
  activityDescription: '',
  content: '',
  createdBy: '',
  workbookId: '',
  workbookName: '',
  activityType: '',
};


export interface WorkSpaceData {
  workspaceName: string;
  workspaceDescription: string;
  createdBy: string | undefined;
  createdName?: string;
}

export const newWorkSpaceFormData: WorkSpaceData = {
  workspaceDescription: "",
  workspaceName: "",
  createdBy: "",
  createdName: "",
};

export interface WorkBookData {
  workbookDescription: string;
  workbookName: string;
  createdBy: string | undefined;
  workspaceId: string;
  createdName?: string;
}

export const newWorkBookFormData: WorkBookData = {
  workbookDescription: "",
  workbookName: "",
  createdBy: "",
  workspaceId: "",
  createdName: "",
};