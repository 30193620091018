import { formatDate } from "devextreme/localization";
import {
  fetchInfraDetailsApi,
  stopContainerInstanceApi,
} from "../../service/task/api-service";
import { useMutation, useQuery } from "react-query";



export const useFetchInfraDetails = (
  clientId : any,
  selectedDays: any,
  Count: any,
  sortOrder: any,
  sortColumn: any,
  pageIndex: any,
  isNextPage: any,
) => {
  const { startTime, endTime, timePeriod } = selectedDays;
  const formattedStartTime = startTime ? formatDate(startTime, "yyyy-MM-dd") : null;
  const formattedEndTime = endTime ? formatDate(endTime, "yyyy-MM-dd") : null;
  const page = pageIndex[pageIndex.length - 1] + 1;

  const queryParams = {
    clientId,
    startTime: formattedStartTime,
    endTime: formattedEndTime,
    timePeriod,
    Count,
    sortColumn,
    sortOrder,
    pageIndex,
    isNextPage,
    Page: page,
  };

  return useQuery(
    ["fetch-infra", queryParams],
    () => fetchInfraDetailsApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
       keepPreviousData: true, 
      select(data: any) {
        const list = data?.data?.map((item: any) => item.data) || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return {list, totalcount };
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};



export const useStopProgress = () => {
  return useMutation(stopContainerInstanceApi, {
    onError: (error: Error) => {
      console.log(error);
    },
  });
};
