import DataGrid, {
  Scrolling,
  Column,
  Paging,
  Pager,
  HeaderFilter,
  MasterDetail,
  SearchPanel
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellStartTime,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../config/constants";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import { useState } from "react";
import { useFetchMetricsDataApi } from "../../../../hooks/activity-monitor/useLogsMetrics";
import MetricsIcon from "../../../../assets/icons/icon-metrics-4.png";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import MasterDetailView from "./hierarchyView";
import TelemetryIcon from "../../../../assets/icons/icon-telemetry-5.png";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import { useFetchManifestData } from "../../../../hooks/task/useWorkflow";
import { useFetchSpecificTask } from "../../../../hooks/task/useTask";
import { useQueryClient } from "react-query";

export const recentStatus = ["In Progress", "Completed", "Failed"];

const RecentActivityGridView = (props: any) => {
  const {
    data,
    onRowClick,
    PagerVisible,
    handleProjectLinkClick,
    handleClientLinkClick,
    handleTaskLinkClick,
    gridRef,
    height,
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [filesData, setFilesData] = useState<any>('');
  const [typeData, setTypeData] = useState<any>('');
  const [selectedTask, setSelectedTask] = useState<any>(null);
  const [selectedWorkflowData, setSelectedWorkflowData] = useState<any>(null);
  const logmetricsParams = {
    executionName: filesData?.executionName,
    clientShortName: filesData?.clientShortName,
    projectShortName: filesData?.projectShortName,
    projectId: filesData?.projectId,
  };
  const { data: metricsGridData, isLoading: isMetricsLoading } = useFetchMetricsDataApi(logmetricsParams);
  const metricsData = metricsGridData?.data || [];
  const { data: manifestData, isLoading: isManifestLoading } = useFetchManifestData(selectedWorkflowData?.manifestId);
  const { data: taskData } = useFetchSpecificTask(selectedTask?.taskId);
  const mergedData = { dataTask: taskData, dataWorkflow: manifestData };
  const fileViewerData = JSON?.stringify((metricsData && Object?.keys(metricsData)?.length > 0 ? metricsData : mergedData), null, 2);
  const queryClient = useQueryClient();

  const closePopupException = () => {
    queryClient.removeQueries("fetch-manifest-data");
    setOpenPopup(false);
  };

  const handleViewTelemetry = (e: any, cell: any) => {
    e?.stopPropagation();
    setFilesData(null);
    setSelectedWorkflowData(null);
    onRowClick(cell);
  };

  const renderViewTelemetryFile = (cell: any) => {
    return (
      <PSIconText
        src={TelemetryIcon}
        alt="Telemetry File"
        hint="Telemetry File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewTelemetry(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    );
  };

  const handleViewWorkflow = (e: any, cell: any) => {
    e?.stopPropagation();
    setFilesData(null);
    const { row } = cell;
    const { data } = row;
    const type = 'workflow';
    setTypeData(type);
    setSelectedTask(data);
    setSelectedWorkflowData(data);
    setOpenPopup(true);
  };

  const renderViewWorkflowFile = (cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="Workflow File"
        hint="Workflow File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewWorkflow(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    );
  };

  const handleViewMetrics = (e: any, cell: any) => {
    e?.stopPropagation();
    setSelectedWorkflowData(null);
    const { row } = cell;
    const { data } = row;
    const params = {
      executionName: data?.executionName,
      clientShortName: data?.clientShortName,
      projectShortName: data?.projectShortName,
      projectId: data?.projectId,
      clientName: data?.clientName,
      projectName: data?.projectName,
      taskName: data?.taskName,
      clientId: data?.clientId,
    };
    setTypeData(null);
    setFilesData(params);
    setOpenPopup(true);
  }

  const renderViewMetrics = (cell: any) => {
    return (
      <PSIconText
        src={MetricsIcon}
        alt="Metrics"
        hint="Metrics"
        width={22}
        height={22}
        onClick={(e: any) => handleViewMetrics(e, cell)}
      />
    );
  }

  const title = {
    clientName: filesData?.clientName,
    projectName: filesData?.projectName,
    taskName: filesData?.taskName,
  }

  return (
    <>
      <div className="recent-activity-grid">
        <DataGrid
          className={`grid theme-dependent ${height}`}
          noDataText={"No recent activity to display at this time"}
          dataSource={data}
          onExporting={onExporting}
          allowColumnReordering
          showBorders
          ref={gridRef}
          allowColumnResizing={true}
        >
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} width={200} />
          <Column
            dataField="clientName"
            caption="Client"
            cellRender={(data) => {
              const clientName = data?.data?.clientName;
              const clientId = data?.data?.clientId;
              return (
                <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
                  {clientName}
                </a>
              );
            }}
          />
          <Column
            dataField="projectName"
            caption="Project Name"
            cellRender={(data) => {
              const projectName = data?.data?.projectName;
              const projectId = data?.data?.projectId;
              return (
                <a
                  href="null"
                  onClick={(e) => handleProjectLinkClick(e, projectId)}
                >
                  {projectName}
                </a>
              );
            }}
          />
          <Column
            dataField="taskName"
            caption="Task Name"
            cellRender={(data) => {
              const TaskName = data?.data?.taskName;
              const taskId = data?.data?.taskId;
              return (
                <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                  {TaskName}
                </a>
              );
            }}
          />
          <Column
            dataField="executionId"
            caption="Execution ID"
            cellRender={(data) => {
              const executionId = data?.data?.executionId;
              const truncatedId = executionId ? executionId?.substr(0, 10) : "";
              return <>{truncatedId}</>;
            }}
          />
          <Column
            dataField="projectType"
            caption="Activity Type"
            dataType="string"
          />
          <Column
            dataField="status"
            caption="Status"
            dataType="string"
            cellRender={ContactStatus}
          />
          <Column
            dataField="timestamp"
            caption="Time Started"
            cellRender={cellStartTime}
          />
          <Column
            dataField="endTime"
            caption="Time Completed"
            cellRender={cellEndTime}
          />
          <Column caption="" type="buttons" width={70} cellRender={renderViewTelemetryFile} />
          <Column caption="" type="buttons" width={70} cellRender={renderViewWorkflowFile} />
          <Column caption="" type="buttons" width={70} cellRender={renderViewMetrics} />
          <MasterDetail enabled={true} component={MasterDetailView} />
          <Scrolling rowRenderingMode="virtual" />
          <Paging defaultPageSize={25} />
          <Pager
            visible={PagerVisible}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
        <FileViewer
          visible={openPopup}
          onHiding={closePopupException}
          data={fileViewerData}
          title={title}
          isLoading={isMetricsLoading || isManifestLoading}
          extensionType={"json"}
          type={typeData}
          params={selectedWorkflowData}
        />
      </div>
    </>
  );
};

export default RecentActivityGridView;