import DataGrid, { Column, DataGridTypes } from "devextreme-react/data-grid";
import { ContactStatus } from "../../../../ui-components/contact-status/ContactStatus";

const DetailInfoRender = (detail: DataGridTypes.MasterDetailTemplateData) => {
  const { Data } = detail?.data || {};
  return (
    <DataGrid
      dataSource={Data}
      repaintChangesOnly={false}
      noDataText={"No Resource to display at this time"}
      columnAutoWidth={true}
      showBorders={true}
    >
      <Column dataField="Name" caption="Resource" dataType="string" width={300}/>
      <Column dataField="Amount" caption="Daily Cost" format={"currency"} />
      <Column dataField="quantity" caption="No.of Resource" />
      <Column dataField="status" caption="Status" cellRender={ContactStatus} />
      <Column dataField="StartDate" caption="StartDate" dataType="date" />
    </DataGrid>
  );
};

export default DetailInfoRender;
