import PsSkeleton from "./PsSkeleton";
import "../../../styles/ui-components/Skeleton.scss";

const ChartBarSkeleton = () => {
  return (
    <div className="bar-skeleton">
      <PsSkeleton count={1} height={100} width={20} />
      <PsSkeleton count={1} height={300} width={20} />
      <PsSkeleton count={1} height={50} width={20} />
      <PsSkeleton count={1} height={80} width={20} />
      <PsSkeleton count={1} height={200} width={20} />
      <PsSkeleton count={1} height={300} width={20} />
      <PsSkeleton count={1} height={50} width={20} />
    </div>
  );
};

export default ChartBarSkeleton;
