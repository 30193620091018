import { useCallback, useMemo } from "react";
import PsImageWithTextCard from "../../../components/ui-components/PsImageWithTextCard/ItemCard";
import ContentExpandCollapse from "../../../components/ui-components/content-expand-collapse/ContentExpandCollapse";
import {
  WebscrapingGuides,
  WebscrapingServiceMap,
} from "../../../config/digital-service/web-scraping";
import { useNavigate } from "react-router-dom";
import "../../../styles/digital-service/WebScraping.scss";

export const WebScrapingHomepage = () => {
  const navigate = useNavigate();

  const onCardClickData = useCallback((e:any) => {
    const { navigation } = e || {};
    navigate(navigation)
  },[navigate]);


  const serviceProps = useMemo(() => {
    return {
      cardItems: WebscrapingServiceMap,
      onCardClick: onCardClickData,
    };
  }, [onCardClickData]);

  const referenceProps = useMemo(() => {
    return {
      cardItems: WebscrapingGuides,
      onCardClick: () => {},
    };
  }, []);

  const cardDetails = useMemo(
    () => [
      {
        title: "Services",
        Component: PsImageWithTextCard,
        componentProps: serviceProps,
        componentId: "document-processing-service",
      },
      {
        title: "Quick Start Guides & Reference Library",
        Component: PsImageWithTextCard,
        componentProps: referenceProps,
        componentId: "document-processing-reference",
      },
    ],
    [referenceProps, serviceProps]
  );

  return (
    <>
      <div className="web-scraping-home-page ">
        <div className="jumbotron">
          <div className="jumbotron-content">
            <h1>Automated Web Scraping</h1>
            <h3>
              Unlock the power of web data with our state-of-the-art scraping
              solution
            </h3>
            <p>
              Our Web Scraping Module is designed to effortlessly extract,
              process, and analyze data from any website. Whether you're
              gathering market intelligence, monitoring competitors, or building
              comprehensive datasets, our tool provides the speed, accuracy, and
              scalability you need.
            </p>
          </div>
        </div>
      </div>
      <ContentExpandCollapse cardDetails={cardDetails} />
    </>
  );
};

export default WebScrapingHomepage;
