import { useState } from "react";
import { Button, Popup } from "devextreme-react";
import SelectBox from "devextreme-react/select-box";
import { FormTextbox } from "../../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { simpleProductLabel } from "../../../../../../config/constants";
import { TagBox, Validator } from "devextreme-react";
import { RequiredRule } from "devextreme-react/form";

export const Modal = (props: any) => {
  const {
    showModal,
    onModalToggle,
    formData,
    nodeId: originalNodeId,
    steps
  } = props;

  const {
    newWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData
  } = formData;

  const [selectedAction, setSelectedAction] = useState<string | null>(null);
  const functionsData = selectedAction ? functionFilterData?.[selectedAction] || [] : [];
  const nodeId = originalNodeId - 1;

  const handleSave = () => {
    onModalToggle();
  };

  return (
    <Popup
      visible={showModal}
      onHiding={onModalToggle}
      dragEnabled={false}
      width={1200}
      height={600}
      title={`${steps} Details`}
    >
      <div className="grid-container-estimator">
        <div className="dx-field">
          <div className="dx-field-label">Name</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Name"
              isEditing={false}
              value={newWorkflowData[nodeId]?.name}
              onValueChange={updateWorkflowField(nodeId, "name")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Description</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Description..."
              value={newWorkflowData[nodeId]?.description}
              isEditing={false}
              onValueChange={updateWorkflowField(nodeId, "description")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Type</div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={["folder", "file", "data", "endpoint"]}
              searchEnabled={true}
              value={newWorkflowData[nodeId]?.inputType}
              placeholder="Select Input File Type..."
              onValueChange={updateWorkflowField(nodeId, "inputType")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Location</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Input File Location"
              isEditing={false}
              value={newWorkflowData[nodeId]?.inputLocation}
              onValueChange={updateWorkflowField(nodeId, "inputLocation")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Name</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Input File Name"
              isEditing={false}
              value={newWorkflowData[nodeId]?.inputFilename}
              onValueChange={updateWorkflowField(nodeId, "inputFilename")}
            />
          </div>
        </div >
        <div className="dx-field">
          <div className="dx-field-label">Action</div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={actionData}
              searchEnabled={true}
              inputAttr={simpleProductLabel}
              placeholder="Select an Action..."
              value={newWorkflowData[nodeId]?.action}
              onValueChange={(e) => {
                setSelectedAction(e);
                updateWorkflowField(nodeId, "action")(e);
              }}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Functions</div>
          <div className="dx-field-value">
            <TagBox
              dataSource={functionsData}
              searchEnabled={true}
              inputAttr={simpleProductLabel}
              value={newWorkflowData[nodeId]?.functions}
              placeholder="Select Functions..."
              showSelectionControls={true}
              onValueChange={updateWorkflowField(nodeId, "functions")}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </TagBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Type</div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={typeData}
              searchEnabled={true}
              inputAttr={simpleProductLabel}
              placeholder="Select a Type..."
              value={newWorkflowData[nodeId]?.type}
              onValueChange={updateWorkflowField(nodeId, "type")}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Impact</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Impact"
              isEditing={false}
              value={newWorkflowData[nodeId]?.impact}
              onValueChange={updateWorkflowField(nodeId, "impact")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Type</div>
          <div className="dx-field-value">
            <SelectBox
              dataSource={["folder", "file", "data", "endpoint"]}
              searchEnabled={true}
              value={newWorkflowData[nodeId]?.outputType}
              placeholder="Select Output File Type..."
              onValueChange={updateWorkflowField(nodeId, "outputType")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Location</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Output File Location"
              isEditing={false}
              value={newWorkflowData[nodeId]?.outputLocation}
              onValueChange={updateWorkflowField(nodeId, "outputLocation")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Name</div>
          <div className="dx-field-value">
            <FormTextbox
              placeholder="Output File Name"
              isEditing={false}
              value={newWorkflowData[nodeId]?.outputFilename}
              onValueChange={updateWorkflowField(nodeId, "outputFilename")}
            />
          </div>
        </div>
        <div className="popup-footer" style={{ textAlign: 'right', padding: '10px' }}>
          <Button
            text="Save"
            type="success"
            onClick={handleSave}
          />
        </div>
      </div>
    </Popup>
  );
};
