import { useCallback, useMemo, useState } from "react";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { Item } from "devextreme-react/toolbar";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import WorkflowGrid from "./helper-component/grid-component/workflowGrid";
import { useWorkflow } from "../../../hooks/task/useWorkflow";
import { WorkflowForm } from "./helper-component/add-workflow/workflowForm";
import '../../../styles/task/workflow.scss';
import { useFetchWorkflowApi } from "../../../hooks/task/useWorkflow";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { DropDownButton } from "devextreme-react";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { customDateRange, dropDownOptions } from "../../../config/constants";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { replaceQueryUrl } from "../../../utlis/helper";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

export const WorkFlow = () => {
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const { state: { popupVisible }, onAddWorkflowOpen } = useWorkflow();
  const {
    state: { client, clientId, projectId, projectName },
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef
  } = useAppHook();
  const [selectedDays, setSelectedDays] = useState({});
  const { data: DateRange } = useFetchDateRange();
  const { data, isLoading, refetch, isFetching } = useFetchWorkflowApi(selectedDays, clientId, projectId);

  const closeShowModal = () => {
    onAddWorkflowOpen(false);
  };

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
        replaceQueryUrl("period", `startTime=${startTime}&endTime=${endTime}`);
      } else {
        setSelectedDays({ timePeriod });
        replaceQueryUrl("period", timePeriod);
      }
      const eventName = `date_range : ${timePeriod}_${"Projects"}`;
      GTAButtonEventHandler(eventName);
    },
    []
  );

  const DataGridProps = useMemo(() => {
    return {
      data,
      isLoading,
      gridRef
    };
  }, [data, isLoading, gridRef]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData}
        width={"auto"}
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DropDownButton
        stylingMode="text"
        text={projectName ? projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
      <PSIconText
        src={NewIcon}
        text="Add Workflow"
        alt="Add Workflow"
        onClick={() => onAddWorkflowOpen(true)}
      />
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: WorkflowGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",

    headerText: "Workflow",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    isLoading: isFetching,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      {popupVisible && (
        <WorkflowForm
          onOpen={popupVisible}
          onClose={closeShowModal}
        />
      )}
    </div>
  );
};
