import {
  fetchCatalogApi,
  fetchReportInfoApi,
  fetchActiveDocumentApi,
  fetchDocumentSummaryApi,
  fetchDataProcessDropDownApi,
  fetchHierarchyInfoApi,
  fetchMapFilesApi,
  updateBulkFinalizeApi
} from "../../service/datastudio/api-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { formatDate } from "devextreme/localization";
import { DataProcessingState } from "../../pages/datastudio/dataAnalyze/types";
import { useCallback, useReducer } from "react";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { fileUploadProjectApi } from "../../service/client/api-service";

export const initialState: any = {
  clientId: "",
  projectId: "",
  telemetryData: {},
  metricsData: {},
  pdfFinalData: {},
  mapFileData: {},
  viewType: "gridView",
  show: false,
  refreshKey: 0,
  finalizePopupVisible: false,
  selectedDocumentIds: [],
  openPopup: false,
  titleData: "",
  showTelemetryModal: false,
  selectedRowKeys: [],
  gridTitleStatus: "Pending",
  selectedClientId: "",
  openHirarchyPopup: false,
  openLineage: false,
  selectedData: {},
};

export const reducer = (state: DataProcessingState, action: any) => {
  switch (action?.type) {
    case "CLIENTID":
      return {
        ...state,
        clientId: action.payload, // used
      };
    case "PROJECTID":
      return {
        ...state,
        projectId: action.payload, // used
      };
    case "SETTELEMETRYDATA":
      return {
        ...state,
        telemetryData: action.payload,
      };
    case "SETMETRICSDATA":
      return {
        ...state,
        metricsData: action.payload,
      };
    case "SETPDFDATA":
      return {
        ...state,
        pdfFinalData: action.payload,
      };
    case "SETMAPFILEDATA":
      return {
        ...state,
        mapFileData: action.payload,
      };
    case "SETVIEWTYPE":
      return {
        ...state,
        viewType: action.payload,
      };
    case "SETPOPUPADDFILESVISIBLE":
      return {
        ...state,
        show: action.payload,
      };
    case "REFRESH_KEY":
      return {
        ...state,
        refreshKey: action.payload,
      };
    case "FINALIZE_POPUP_VISIBLE":
      return {
        ...state,
        finalizePopupVisible: action.payload,
      };
    case "SELECTED_DOCUMENT_IDS":
      return {
        ...state,
        selectedDocumentIds: action.payload,
      };
    case "OPEN_POPUP":
      return {
        ...state,
        openPopup: action.payload,
      };
    case "TITLE_DATA":
      return {
        ...state,
        titleData: action.payload,
      };
    case "SHOW_TELEMETRY_MODAL":
      return {
        ...state,
        showTelemetryModal: action.payload,
      };
    case "SELECTED_ROW_KEYS":
      return {
        ...state,
        selectedRowKeys: action.payload,
      };
    case "GRID_TITLE_STATUS":
      return {
        ...state,
        gridTitleStatus: action.payload,
      };
    case "SELECTED_CLIENT_ID":
      return {
        ...state,
        selectedClientId: action.payload,
      };
    case "OPEN_HIRARCHY_POPUP":
      return {
        ...state,
        openHirarchyPopup: action.payload,
      };
    case "OPEN_LINEAGE":
      return {
        ...state,
        openLineage: action.payload,
      };
    case "SELECTED_DATA":
      return {
        ...state,
        selectedData: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useDocumentCatalog = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data: fetchDropDown }: { data: any; isLoading: boolean } = useFetchDataProcessDropDownDataApi(state.clientId);
  const {
    mutate: DocumentCatalogBulkFinalizeMutate,
    isLoading: isFinalizeLoading,
    isSuccess: isFinalizeSuccess
  } = useDocumentCatalogBulkFinalizeApi();

  const getClientId = (e: any) => {
    const { value } = e || {};
    const { clientId } = value || {};
    dispatch({ type: "CLIENTID", payload: clientId });
  }; // used

  const getProjectId = (e: any) => {
    const { value } = e || {};
    const { projectId } = value || {};
    dispatch({ type: "PROJECTID", payload: projectId });
  }; // used

  const setTelemetryData = useCallback((value: any) => {
    dispatch({ type: "SETTELEMETRYDATA", payload: value });
  }, []);

  const setMetricsData = useCallback((value: any) => {
    dispatch({ type: "SETMETRICSDATA", payload: value });
  }, []);

  const setPdfFinalData = useCallback((value: any) => {
    dispatch({ type: "SETPDFDATA", payload: value });
  }, []);

  const setMapFileData = useCallback((value: any) => {
    dispatch({ type: "SETMAPFILEDATA", payload: value });
  }, []);

  const setViewType = useCallback((value: any) => {
    dispatch({ type: "SETVIEWTYPE", payload: value });
  }, []);

  const setPopupAddFilesVisible = useCallback((value: any) => {
    dispatch({ type: "SETPOPUPADDFILESVISIBLE", payload: value });
  }, []);

  const setRefreshKey = useCallback((refreshKey: any) => {
    dispatch({ type: "REFRESH_KEY", payload: refreshKey + 1 });
  }, []);

  const setFinalizePopupVisible = useCallback((value: any) => {
    dispatch({ type: "FINALIZE_POPUP_VISIBLE", payload: value });
  }, []);

  const setSelectedDocumentIds = useCallback((value: any) => {
    dispatch({ type: "SELECTED_DOCUMENT_IDS", payload: value });
  }, []);

  const setOpenPopup = useCallback((value: any) => {
    dispatch({ type: "OPEN_POPUP", payload: value });
  }, []);

  const setTitleData = useCallback((value: any) => {
    dispatch({ type: "TITLE_DATA", payload: value });
  }, []);

  const setShowTelemetryModal = useCallback((value: any) => {
    dispatch({ type: "SHOW_TELEMETRY_MODAL", payload: value });
  }, []);

  const setSelectedRowKeys = useCallback((value: any) => {
    dispatch({ type: "SELECTED_ROW_KEYS", payload: value });
  }, []);

  const setGridTitleStatus = useCallback((value: any) => {
    dispatch({ type: "GRID_TITLE_STATUS", payload: value });
  }, []);

  const setSelectedClientId = useCallback((value: any) => {
    dispatch({ type: "SELECTED_CLIENT_ID", payload: value });
  }, []);

  const setOpenHirarchyPopup = useCallback((value: any) => {
    dispatch({ type: "OPEN_HIRARCHY_POPUP", payload: value });
  }, []);

  const setOpenLineage = useCallback((value: any) => {
    dispatch({ type: "OPEN_LINEAGE", payload: value });
  }, []);

  const setSelectedData = useCallback((value: any) => {
    dispatch({ type: "SELECTED_DATA", payload: value });
  }, []);

  return {
    state,
    fetchDropDown,
    getClientId,
    getProjectId,
    setTelemetryData,
    setMetricsData,
    setPdfFinalData,
    setMapFileData,
    setViewType,
    setPopupAddFilesVisible,
    setRefreshKey,
    setFinalizePopupVisible,
    setSelectedDocumentIds,
    setOpenPopup,
    setTitleData,
    setShowTelemetryModal,
    setSelectedRowKeys,
    setGridTitleStatus,
    setSelectedClientId,
    setOpenHirarchyPopup,
    setOpenLineage,
    setSelectedData,
    DocumentCatalogBulkFinalizeMutate,
    isFinalizeLoading,
    isFinalizeSuccess
  };
};

export const useFetchDataProcessDropDownDataApi = (id: string) => {
  return useQuery(
    ["fetch-dataprocess-drop-down", id],
    () => fetchDataProcessDropDownApi(id),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

const fetchDocumentCatalog = async (queryParams: any) => {
  const response: any = await fetchCatalogApi(queryParams);
  if (response) {
    return response;
  }
}; // used

export const useCatalogInfo = (
  clientId: any,
  projectId: any,
  selectedDays: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: boolean
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex;
  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    Count,
    sortOrder,
    sortColumn,
    Page: page,
  };
  return useQuery(
    ["fetch-document-catalogss", queryParams],
    () => fetchDocumentCatalog(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select: (data: any) => {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

export const useCatalogBoardInfo = (
  clientId: any,
  projectId: any,
  selectedDays: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: boolean,
  status: any
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length - 1] + 1;
  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    Count,
    sortOrder,
    sortColumn,
    status,
    Page: page,
  };
  return useQuery(
    ["fetch-document-catalogsboard", queryParams],
    () => fetchDocumentCatalog(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select: (data: any) => {
        const updatedData = {
          list: data?.data || [],
          totalcount: data?.pagination?.totalRecords || 0,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

const isDateFormatted = (date: string) => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  return regex.test(date);
};

const fetchDocumentReports = async (data: any) => {
  const {
    timePeriod,
    Count,
    startTime,
    endTime,
    clientId,
    ProjectId,
    status,
    taskId,
    processType,
  } = data || {};

  const queryParams = {
    timePeriod,
    Count,
    startTime: isDateFormatted(startTime)
      ? startTime
      : formatDate(startTime, "yyyy-MM-dd"),
    endTime: isDateFormatted(endTime)
      ? endTime
      : formatDate(endTime, "yyyy-MM-dd"),
    clientId,
    ProjectId,
    status,
    taskId,
    processType,
  };

  const response: any = await fetchReportInfoApi(queryParams);
  if (response) {
    return response;
  }
};

export const useReportsInfo = (data: any) => {
  return useQuery(
    ["fetch-document-catalogs", data],
    () => fetchDocumentReports(data),
    {
      refetchOnWindowFocus: false,
      enabled: !!data,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchFileUploadProject = (onClose: any) => {
  const fnQueryClient = useQueryClient();
  return useMutation(fileUploadProjectApi, {
    onSuccess: (response: any) => {
      onClose();
      fnQueryClient.invalidateQueries("fetch-fileupload-project");
      fnQueryClient.invalidateQueries("fetch-document-catalogss");
      showNotification({
        message: response.response
          ? response.response
          : "File uploaded successfully",
        type: response.status ? response.status : "success",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

const fetchDocumentSummary = async (data: any) => {
  const { startTime, endTime, timePeriod } = data || {};
  const queryParams = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
    timePeriod,
  };
  const response: any = await fetchDocumentSummaryApi(queryParams);
  if (response) {
    return response;
  }
}; // used

export const useFetchDocumentSummary = (data: any) => {
  return useQuery(
    ["fetch-document-summary", data],
    () => fetchDocumentSummary(data),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

const fetchActiveDocument = async (data: any) => {
  const { startTime, endTime, timePeriod, clientId, projectId } = data || {};
  const queryParams = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
    timePeriod,
    clientId,
    projectId
  };
  const response: any = await fetchActiveDocumentApi(queryParams);
  if (response) {
    return response;
  }
}; // used

export const useFetchActiveDocument = (data: any) => {
  return useQuery(
    ["fetch-active-document", data],
    () => fetchActiveDocument(data),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

export const useFetchHierarchyInfo = (
  status: string,
  processType: string,
  dataCatalogId: any,
  filename: string
) => {
  return useQuery(
    ["fetch-hierarchy-info", status, processType, dataCatalogId, filename],
    () => fetchHierarchyInfoApi({ status, processType, dataCatalogId, filename }),
    {
      enabled: !!processType && !!status && !!dataCatalogId,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchMapFiles = (details: any) => {
  const ext = details?.ext;
  const params = {
    document_id: details?.document_id,
    map: details?.map,
  };
  return useQuery(["fetch-map-file", params], () => fetchMapFilesApi(params), {
    refetchOnWindowFocus: false,
    enabled: !!params?.document_id && !!params?.map,
    select: (data: any) => {
      const type = ext;
      if (type === "json" || type === "map") {
        const res = JSON?.stringify(data, null, 2);
        return res;
      }
      return data || "";
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDocumentCatalogBulkFinalizeApi = () => {
  return useMutation(updateBulkFinalizeApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.message,
      });
    },
    onError: (error: Error) => {
      showNotification({
        message: "Finalizing Failed",
        type: "error",
      });
    },
  });
};