import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { FormTextbox } from "../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../utlis/media-query";
import { DataGrid, SelectBox, TextArea } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import { useCreateWorkItem } from "../../../../hooks/workbench/useWorkbench";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import { useParams, useNavigate } from "react-router-dom";
import {
  newAddItemDataEntryFormData,
  newAddItemFormData,
  WorkBookItemData,
  WorkBookItemDataEntry,
  newAddItemDataAnalyseFormData,
  WorkBookItemDataAnalyse,
} from "../../types";
import { TemlatesDataEntry } from "../../../../fixture/WorkbooksItems";
import { useApp } from "../../../../contexts/app";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
import { RightSidePannel } from "../../../../components/ui-components/right-side-panel/right-side-panel";
import { Validator, RequiredRule } from "devextreme-react/validator";

const AddWorkBookItems = (props: any) => {
  const { workBookItemsType, changePanelOpened, isWorkBookAddFormShow } = props;
  const { user } = useApp();
  let { workbookName, workbookId } = useParams();
  const navigate = useNavigate();
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [projectFiles, setProjectFiles] = useState<any[]>([]);
  const [transformColumnData, setTransformColumnData] = useState<any>([]);
  const [columdata, setColumnData] = useState<any>([]);

  const [newWorkBookItemData, setWorkBookItemData] = useState<WorkBookItemData>(
    {
      ...newAddItemFormData,
    }
  );
  const [newWorkBookItemDataEntry, setWorkBookItemDataEntry] =
    useState<WorkBookItemDataEntry>({
      ...newAddItemDataEntryFormData,
    });
  const [newWorkBookItemDataAnalyse, setWorkBookItemDataAnalyse] =
    useState<WorkBookItemDataAnalyse>({
      ...newAddItemDataAnalyseFormData,
    });

  const updateFieldDataEntry = (field: string) => (value: any) => {
    setWorkBookItemDataEntry({ ...newWorkBookItemDataEntry, [field]: value });
  };
  const updateFieldDataAnalyse = (field: string) => (value: any) => {
    setWorkBookItemDataAnalyse({
      ...newWorkBookItemDataAnalyse,
      [field]: value,
    });
  };
  const updateField = (field: string) => (value: any) => {
    setWorkBookItemData({ ...newWorkBookItemData, [field]: value });
  };

  const { mutate, isSuccess, isLoading } = useCreateWorkItem();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `Workbook Item Added Successfully`,
      });
      navigate(`/workbooks/${workbookName}/${workbookId}`);
    }
  }, [isSuccess, navigate, workbookId, workbookName]);

  const handleTemplateSelection = (e: any) => {
    try {
      const { value } = e;
      if (value?.templatename === "Create New Template") {
        setColumnData([]);
      } else {
        setColumnData(value?.value);
        updateFieldDataEntry("content")(value?.value);
      }
    } catch (err) {
      console.error("Error in handleTemplateSelection:", err);
    }
  };

  const handleFormSubmitData = useCallback(() => {
    try {
      const uploadPromises = projectFiles.map((file: any) => {
        return new Promise<void>((resolve) => {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 10;
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: Math.min(progress, 100),
            }));
            if (progress >= 100) {
              clearInterval(interval);
              resolve();
            }
          }, 500);
        });
      });
      mutate({
        ...newWorkBookItemData,
        createdBy: user?.userId,
        workbookId: workbookId,
        workbookName: workbookName,
        activityType: workBookItemsType,
        createdName: user?.name,
      });
    } catch (err) {
      console.error("Error in handleFormSubmitData:", err);
    }
  }, [
    mutate,
    newWorkBookItemData,
    projectFiles,
    user,
    workBookItemsType,
    workbookId,
    workbookName,
  ]);

  const handleFilesUpload = (e: any) => {
    try {
      const files = e.value;
      const filePromises = files.map((file: any) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () =>
            resolve({ name: file?.name, content: reader.result });
          reader.onerror = (error) => reject(error);
        });
      });
      Promise.all(filePromises).then((encodedFiles) => {
        setProjectFiles(encodedFiles);
        setWorkBookItemData({
          ...newWorkBookItemData,
          content: encodedFiles[0]?.content,
          activityName: encodedFiles[0]?.name,
        });
        setWorkBookItemDataAnalyse({
          ...newWorkBookItemDataAnalyse,
          content: encodedFiles[0]?.content,
          activityName: encodedFiles[0]?.name,
        });
        const initialFileProgress: { [key: string]: number } = {};
        encodedFiles.forEach((file) => {
          initialFileProgress[file.name] = 0;
        });
        setFileProgress(initialFileProgress);
      });
    } catch (err) {
      console.error("Error in handleFilesUpload:", err);
    }
  };

  const getProgressBar = (value: number) => <ProgressBar value={value} />;

  const handleFormSubmitDataEntry = useCallback(() => {
    try {
      mutate({
        ...newWorkBookItemDataEntry,
        createdBy: user?.userId,
        workbookId: workbookId,
        workbookName: workbookName,
        activityType: workBookItemsType,
        createdName: user?.name,
      });
    } catch (err) {
      console.error("Error in handleFormSubmitDataEntry:", err);
    }
  }, [
    mutate,
    newWorkBookItemDataEntry,
    user,
    workBookItemsType,
    workbookId,
    workbookName,
  ]);

  useEffect(() => {
    if (columdata && columdata.length > 0) {
      const transformedData = Object.entries(columdata[0]).map(
        ([key, value]) => {
          return {
            key: key,
            value: value,
          };
        }
      );
      setTransformColumnData(transformedData);
    }
  }, [columdata]);

  const handleSubmitDataAnalyse = useCallback(() => {
    try {
      projectFiles.map((file: any) => {
        return new Promise<void>((resolve) => {
          let progress = 0;
          const interval = setInterval(() => {
            progress += 10;
            setFileProgress((prevProgress) => ({
              ...prevProgress,
              [file.name]: Math.min(progress, 100),
            }));
            if (progress >= 100) {
              clearInterval(interval);
              resolve();
            }
          }, 500);
        });
      });
      mutate({
        ...newWorkBookItemDataAnalyse,
        createdBy: user?.userId,
        workbookId: workbookId,
        workbookName: workbookName,
        activityType: workBookItemsType,
        createdName: user?.name,
      });
    } catch (err) {
      console.error("Error in handleSubmitDataAnalyse:", err);
    }
  }, [
    mutate,
    newWorkBookItemDataAnalyse,
    projectFiles,
    user,
    workBookItemsType,
    workbookId,
    workbookName,
  ]);

  const handleFormAddItemSubmit = useCallback(() => {
    try {
      switch (workBookItemsType) {
        case "Add Files":
          handleFormSubmitData();
          break;
        case "Data Analysis":
          handleSubmitDataAnalyse();
          break;
        case "Manual Data Entry":
          handleFormSubmitDataEntry();
          break;
        default:
          console.error("Unknown workbook item type");
      }
    } catch (err) {
      console.error("Error in handleFormAddItemSubmit:", err);
    }
  }, [
    workBookItemsType,
    handleFormSubmitData,
    handleSubmitDataAnalyse,
    handleFormSubmitDataEntry,
  ]);

  const renderFormFields = () => {
    switch (workBookItemsType) {
      case "Add Files":
        return (
          <>
            <Form
              className="plain-styled-form"
              screenByWidth={getSizeQualifier}
            >
              <GroupItem>
                <ColCountByScreen xs={1} />
                <FormItem>
                  <div className="file-uploader-container">
                    <FileUploader
                      key={projectFiles?.length}
                      multiple={true}
                      accept={".pdf" || ".json"}
                      uploadMode="useForm"
                      onValueChanged={handleFilesUpload}
                    />
                    <span className="note">{'Allowed file extensions: '}
                      <span>.json, .pdf</span>
                    </span>
                    {Object?.keys(fileProgress)?.map((fileName) => (
                      <div key={fileName} className="progressDiv">
                        {fileName} {getProgressBar(fileProgress[fileName])}
                      </div>
                    ))}
                  </div>
                </FormItem>
                <FormItem>
                  <FormTextbox
                    placeholder="File Name..."
                    value={newWorkBookItemData.activityName}
                    isEditing={true}
                    onValueChange={updateField("activityName")}
                  />
                </FormItem>
                <FormItem>
                  <TextArea
                    placeholder="File Description..."
                    value={newWorkBookItemData.activityDescription}
                    onValueChanged={(e) =>
                      updateField("activityDescription")(e.value)
                    }
                    height={100}
                  >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </TextArea>
                </FormItem>
              </GroupItem>
            </Form>
          </>
        );
      case "Data Analysis":
        return (
          <>
            <Form
              className="plain-styled-form"
              screenByWidth={getSizeQualifier}
            >
              <GroupItem>
                <ColCountByScreen xs={1} />
                <FormItem>
                  <FileUploader
                    key={projectFiles?.length}
                    multiple={true}
                    accept=".pdf,.json,.csv,.xlsx,.xml,.txt"
                    uploadMode="useForm"
                    onValueChanged={handleFilesUpload}
                  />
                  <span className="note">{'Allowed file extensions: '}
                    <span>.json, .csv, .xlsx, .xls, .xml, .txt</span>
                  </span>
                  {Object.keys(fileProgress).map((fileName) => (
                    <div key={fileName} className="progressDiv">
                      {fileName} {getProgressBar(fileProgress[fileName])}
                    </div>
                  ))}
                </FormItem>
                <FormItem>
                  <FormTextbox
                    placeholder="File Name"
                    value={newWorkBookItemDataAnalyse.activityName}
                    isEditing={true}
                    onValueChange={updateFieldDataAnalyse("activityName")}
                  />
                </FormItem>
                <FormItem>
                  <TextArea
                    placeholder="File Description..."
                    value={newWorkBookItemDataAnalyse.activityDescription}
                    onValueChanged={(e) =>
                      updateFieldDataAnalyse("activityDescription")(e.value)
                    }
                    height={100}
                  >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </TextArea>
                </FormItem>
              </GroupItem>
            </Form>
          </>
        );
      case "Manual Data Entry":
        return (
          <>
            <Form
              className="plain-styled-form"
              screenByWidth={getSizeQualifier}
            >
              <GroupItem>
                <ColCountByScreen xs={1} />
                <FormItem>
                  <FormTextbox
                    placeholder="File Name..."
                    value={newWorkBookItemDataEntry.activityName}
                    isEditing={false}
                    onValueChange={updateFieldDataEntry("activityName")}
                  />
                </FormItem>
                <FormItem>
                  <TextArea
                    placeholder="File Description..."
                    value={newWorkBookItemDataEntry.activityDescription}
                    onValueChanged={(e) =>
                      updateFieldDataEntry("activityDescription")(e.value)
                    }
                    height={100}
                  >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </TextArea>
                </FormItem>
                <FormItem>
                  <SelectBox
                    dataSource={TemlatesDataEntry}
                    placeholder="Select Template..."
                    displayExpr="templatename"
                    onValueChanged={handleTemplateSelection}
                  >
                    <Validator>
                      <RequiredRule />
                    </Validator>
                  </SelectBox>
                </FormItem>
                <FormItem>
                  {transformColumnData && (
                    <DataGrid
                      dataSource={transformColumnData}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      showBorders={true}
                      rowAlternationEnabled={true}
                    />
                  )}
                </FormItem>
              </GroupItem>
            </Form>
          </>
        );
      default:
        return (
          <>
            <div>Currenlty Under Construction</div>
          </>
        );
    }
  };

  return (
    <div>
      <RightSidePannel
        isOpened={isWorkBookAddFormShow}
        changePanelOpened={changePanelOpened}
        title={`${workBookItemsType}`}
        onSave={handleFormAddItemSubmit}
        isLoading={isLoading}
      >
        {renderFormFields()}
      </RightSidePannel>
    </div>
  );
};

export default AddWorkBookItems;
