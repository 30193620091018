import React from 'react';
import PieChart, {
  Legend,
  Series,
  Label,
  Connector,
} from 'devextreme-react/pie-chart';


function customizeLabel(point: { argumentText: string; valueText: string; }) {
  return `${point.argumentText}: ${point.valueText}%`;
}

function FinopsDonutChart({dataSource}:any) {
  const totalCostData  = dataSource?.totalCost;
  const finalData = totalCostData?.filter((item: { name: string; }) => item?.name !== "Total");
  const clientName = dataSource?.clientName;

  const title = clientName ? `Total Cost For ${clientName}` : 'Total Cost For All Clients';
  
  return (
    <PieChart
      id="pie"
      type="doughnut"
      // title={totalCostData&&title}
      palette="Soft Pastel"
      dataSource={finalData}
    >
      <Series argumentField="name" valueField="percentage">
        <Label
          visible={true}
          format="fixedPoint"
          customizeText={customizeLabel}
        >
          <Connector visible={true} width={1} />
        </Label>
      </Series>
      <Legend horizontalAlignment="center" verticalAlignment="bottom" />
    </PieChart>
  );
}

export default FinopsDonutChart;
