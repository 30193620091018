import { TreeView } from "devextreme-react";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import {
  useClientProfileData,
  useFetchSelectedClientFileData,
} from "../../../../hooks/client/useClientProfile";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import "../../../../styles/client/dataTab.scss";
import {
  transClientStorageData,
  useFetchClientDataList,
} from "../../../../hooks/client/useClientProfile";
import { useMemo } from "react";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { GridComponent } from "../../../core-component/dataLakeGrid/GridComponent";
import { clientLocation } from "../../../../config/client/commonConfig";

export const ClientProfileDataTab = (props: any) => {
  const { selectedDays, customerId, clientContactData, projectInfo } = props;
  const clientId = useHelperHooks().getQueryParams("clientId") ?? "";
  const client = clientContactData?.length > 0 ? clientContactData[0] : {};
  const clientName = client?.name;
  const projectData = projectInfo?.data;

  const { data: clientData, isFetching } = useFetchClientDataList(clientId);

  const finalData = useMemo(() => {
    return clientData ? transClientStorageData(clientData, clientName) : [];
  }, [clientData, clientName]);

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewScrapingButtonClick,
  } = useClientProfileData();
  const { data: SelectedClientFileData, isLoading } =
    useFetchSelectedClientFileData(selectedFileData);

  const renderItem = (item: any) => {
    const shortenedId = item.id.slice(0, 3);
    const selectedId = item?.projectId;
    const projectFilter = projectData?.find((project: any) => project?.projectId === selectedId);
    const projectShortName = projectFilter?.projectShortName;
    const gridProps = {
      id: { projectId: item?.projectId },
      location: clientLocation[shortenedId],
      projectShortName: projectShortName,
      onViewScrapingButtonClick,
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? <GridComponent {...gridProps} /> : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
      {isFetching ? (
        <PsSkeleton height={30} count={6} />
      ) : (
        <TreeView
          id="simple-treeview"
          items={finalData}
          itemRender={renderItem}
        />
      )}
      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewScrapingButtonClick}
        data={
          selectedFileData?.fileExtension === "pdf"
            ? SelectedClientFileData?.data
            : JSON.stringify(SelectedClientFileData?.data)
        }
        title={title}
        isLoading={isLoading}
        extensionType={selectedFileData?.fileExtension}
      />
    </>
  );
};

export default ClientProfileDataTab;
