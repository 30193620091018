import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { useFetchProjectTask } from "../../../../hooks/client/useProjectProfile";
import { useNavigate } from "react-router";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellLastExecutionTime,
  editCellStatusRender,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import TableSkeleton from "../../../ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";

const TasksGrid = (props: any) => {
  const { renderTaskStart } = props;
  const navigate = useNavigate();

  const projectId = useHelperHooks().getQueryParams("projectId");
  const { data, isLoading, isFetching } = useFetchProjectTask(
    projectId as string
  );

  const handleClientLinkClick = (event: any, clientId: string) => {
    event.preventDefault();
    navigate(`/client-profile?clientId=${clientId}`);
  };

  const handleTaskLinkClick = (event: any, taskId: string) => {
    event.preventDefault();
    navigate(`/client-task?taskId=${taskId}`);
  };

  return (
    <>
    {!isFetching ? (
      <DataGrid
        className="grid project-profile"
        noDataText={"No Task  to display at this time"}
        focusedRowEnabled={false}
        height="450px"
        dataSource={data}
        allowColumnReordering
        showBorders
        allowColumnResizing={true}
      >
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <Column
          dataField="clientName"
          caption="Client"
          cellRender={(data) => {
            const clientName = data?.data?.clientName;
            const clientId = data?.data?.clientId;
            return (
              <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
                {clientName}
              </a>
            );
          }}
        />
        <Column
          dataField="taskName"
          caption="Task Name"
          cellRender={(data) => {
            const taskDescription = data?.data?.taskName;
            const taskId = data?.data?.taskId;
            return (
              <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                {taskDescription}
              </a>
            );
          }}
        />
        <Column dataField="workflowName" caption="Workflow Name" />
        <Column dataField="projectType" caption="Activity Type" />
        <Column dataField="taskScheduling" caption="Frequency" />
        <Column
          dataField="status"
          caption="Status"
          cellRender={ContactStatus}
          editCellRender={editCellStatusRender}
        />
        <Column dataField="maxContainerGroup" caption="Max Container Group" alignment="left" />
        <Column dataField="containersPerGroup" caption="Containers Per Group" alignment="left" />
        <Column dataField="containerSize" caption="Container Size" />
        <Column
          dataField="latest_execution"
          caption="Last Execution"
          cellRender={cellLastExecutionTime}
        />
        <Column caption="" type="buttons" width={160} cellRender={renderTaskStart}>
        </Column>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
    ) : (
      <TableSkeleton />
    )}
    </>
  );
};

export default TasksGrid;
