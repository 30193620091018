import PsSkeleton from "../../../../../components/ui-components/skeleton/PsSkeleton";
import { useFetchDocumentSummary } from "../../../../../hooks/datastudio/useDocumentCatalog";
import "../../../../../styles/datastudio/ManagementView.scss";

const ComputeUitilization = (props: any) => {
  const { selectedDays, client } = props;
  const { data: progress, isLoading } = useFetchDocumentSummary(selectedDays) as any;
  const clientFiltered = progress?.filter((item: any) =>
    client === 'All Clients' || item?.clientName === client
  );
  const progressStages = clientFiltered;

  if(isLoading) {
    return (
      <PsSkeleton height={250} width={"90%"} />
    );
  }
  return (
    <div style={{ marginLeft: "-20px", marginBottom: "20px" }}>
      <div className="documentUtilizationHeading">
        Progress Stages 
      </div>
      <div className="document-details documentSummary header">
        <div className="column">
          <span className="label" style={{ width: "200px" }}>
            Client
          </span>
        </div>
        <div className="column" style={{ marginLeft: "0px", minWidth: "100px" }}>
          <span className="label">In Progress</span>
        </div>
        <div className="column" style={{ marginLeft: "0px", minWidth: "100px" }}>
          <span className="label">Completed</span>
        </div>
        <div className="column" style={{ marginLeft: "0px", minWidth: "120px" }}>
          <span className="label">Ready for Review</span>
        </div>
      </div>
      <div className="scrollable-content">
        {progressStages?.map((item: any, index: number) => {
          const { clientName, documentProcess } = item;

          const InProgress = documentProcess?.find((cost: any) => cost?.name === "In Progress")?.value || 0;
          const Completed = documentProcess?.find((cost: any) => cost?.name === "Completed")?.value || 0;
          const ReadyForReview = documentProcess?.find((cost: any) => cost?.name === "Ready for Review")?.value || 0;

          return (
            <div className="document-details documentSummary" key={index} style={{ marginTop: "-10px" }}>
              <div className="column">
                <span className="value" style={{ width: "200px" }}>
                  {clientName}
                </span>
              </div>
              <div className="column" style={{ marginLeft: "0px", justifyContent: "flex-start", minWidth: "100px" }}>
                <span className="value">{InProgress}</span>
              </div>
              <div className="column" style={{ marginLeft: "0px", justifyContent: "flex-start", minWidth: "100px" }}>
                <span className="value">{Completed}</span>
              </div>
              <div className="column" style={{ marginLeft: "0px", justifyContent: "flex-start", minWidth: "100px" }}>
                <span className="value">{ReadyForReview}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ComputeUitilization;
