import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchWorkSpaceApi,
  createWorkSpaceApi,
  updateWorkSpaceApi,
  createWorkBookApi,
  updateWorkBookApi,
  fetchWorkBookApi,
  updateWorkItemApi,
  createWorkItemApi,
  fetchWorkBookItemApi,
  fetchActivityItemsPdf,
  fetchProjectInfoApi,
  fetchRecentActivityInfoApi,
  updateWorkbenchShare,
  createWorkbenchShare,
  fetchSharedUserData,
  fetchSharedWorkBenchData,
} from "../../service/admin/api-service";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomStore from "devextreme/data/custom_store";
import TreeView from "devextreme-react/tree-view";
import { useApp } from "../../contexts/app";
import { formatDateAndSplit } from "../../utlis/helper";
import pdfFiles from "../../assets/media/pdf-1.png";
import dataAnalyse from "../../assets/media/data-1.png";
import manualEntry from "../../assets/media/typing-1.png";
import { useFetchClient, useFetchSpecificProject, useFetchUserInformation } from "../app/useApps";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";

interface OriginalFormat {
  subscriberId: string;
  subscriberName: string;
  role: string[];
  assetShared : boolean;
}

interface TransformedFormat {
  ID: string | number;
  name: string;
  userId?: string;
  selected : boolean;
  categoryId?: number;
}

export interface WorkBenchState {
  isWorkSpaceShow: boolean;
  isWorkBookShow: boolean;
  isWorkBookAddFormShow: boolean;
  workBookItemsType: string;
  editPopupVisible: boolean;
  selectedManualEntryData?: any;
  isWorkSpaceAddItemShow?: boolean;
  selectedClientData?: any;
  isShareWorkbenchShow?: boolean;
  isWorkbookItemShow?: string;
  pdfFilePath?: string;
  workBookAssetType?: string;
}

const initialState: WorkBenchState = {
  isWorkSpaceShow: false, // used 
  isWorkBookShow: false, // used 
  isWorkBookAddFormShow: false, // used 
  workBookItemsType: "", // used 
  editPopupVisible: false, // used 
  selectedManualEntryData: null, // used 
  isWorkSpaceAddItemShow: false, // used 
  selectedClientData: null,
  isShareWorkbenchShow: false, // used 
  isWorkbookItemShow: "gridView", // used 
  pdfFilePath: "", // used 
  workBookAssetType: "", // used 
};

export const getTransformedWorkbooks = (data: any[], navigate: any) => {
  let transformedWorkbooks: any[] = [];
  data?.forEach((workspace) => {
    workspace?.assets?.forEach((asset: { workbooks: any }) => {
      asset.workbooks.forEach((workbook: any) => {
        transformedWorkbooks.push({
          id: workbook?.workbookId,
          title: workbook?.workbookName,
          creater: workbook?.createdBy,
          assetType: workbook?.assetType,
          workbookFile: workbook?.workbookFile,
          description: workbook?.description || workbook?.workbookDescription,
          date: formatDateAndSplit(workbook?.date),
          onClick: () =>
            navigate(
              `/workbooks/${workbook?.workbookName}/${workbook?.workbookId}`
            ),
        });
      });
    });
  });
  return transformedWorkbooks;
}; // used 

export const projectIdsString = (workspacesData: any) => {
  const ids = new Set();
  workspacesData?.data?.forEach((workspace: { assets: any[] }) => {
    workspace?.assets?.forEach((asset) => {
      asset?.projects?.forEach((project: { projectId: unknown }) => {
        ids.add(project?.projectId);
      });
    });
  });
  return `(${Array.from(ids)
    .map((id) => `"${id}"`)
    .join(", ")})`;
}; // used 

const assetImageMapping: { [key: string]: string } = {
  "Manual Data Entry": manualEntry,
  "Add Files": pdfFiles,
  "Data Analysis": dataAnalyse,
};

export const transformData = (data: any[]) => {
  let transformedAssets: any[] = [];

  data?.forEach((workbook) => {
    const { createdName, assets } = workbook;

    assets?.forEach((asset: { title: any; assetData: any[] }) => {
      const assetType = asset?.title;

      asset?.assetData.forEach((file) => {
        transformedAssets?.push({
          ...file,
          createdName: createdName,
          assetType: assetType,
          uploadedOn: formatDateAndSplit(file.Date),
          imageUrl: assetImageMapping[assetType],
        });
      });
    });
  });

  return transformedAssets;
};

const reducer = (state: WorkBenchState, action: any) => {
  switch (action.type) {
    case "IS_WORKSPACE_SHOW":
      return {
        ...state,
        isWorkSpaceShow: action.payload, // used
      };
    case "IS_WORKBOOK_SHOW":
      return {
        ...state,
        isWorkBookShow: action.payload, // used 
      };
    case "Is_WORKBOOK_ADD_FORM_SHOW":
      return {
        ...state,
        isWorkBookAddFormShow: action.payload,
      };
    case "SELECTED_WORKBOOK_ITEM_TYPE":
      return {
        ...state,
        workBookItemsType: action.payload,
      };
    case "SELECTED_MANUAL_ENTRY_DATA":
      return {
        ...state,
        selectedManualEntryData: action.payload, // used 
      };
    case "EDITPOPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload, // used 
      };
    case "IS_WORKSPACE_ADD_ITEM_SHOW":
      return {
        ...state,
        isWorkSpaceAddItemShow: action.payload, // used
      };
    case "SELECTED_CLIENT_DATA":
      return {
        ...state,
        selectedClientData: action.payload, // 
      };
    case "IS_SHARE_ITEMS_SHOW":
      return {
        ...state,
        isShareWorkbenchShow: action.payload,
      };
    case "IS_WORKBOOK_ITEM_SHOW":
      return {
        ...state,
        isWorkbookItemShow: action.payload,
      };
    case "PDF_FILE_PATH_VIEWER":
      return {
        ...state,
        pdfFilePath: action.payload, // used 
      };
    case "WORKBOOK_ASSET_TYPE":
      return {
        ...state,
        workBookAssetType: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

const toTransformedFormat = (data: OriginalFormat[]): TransformedFormat[] => {
  const transformed: TransformedFormat[] = [];

  data?.forEach((user, index) => {
    const userId = index + 1;
    transformed.push({
      ID: userId,
      name: user?.subscriberName,
      userId: user?.subscriberId,
      selected : user?.assetShared,
    });

    user?.role?.forEach((role, roleIndex) => {
      transformed.push({
        ID: `${userId}_${roleIndex + 1}`,
        categoryId: userId,
        name: role,
        selected : false,
      });
    });
  });

  return transformed;
};

export const useWorkBench = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [userTreeDataSource, setUserTreeDataSource] = useState<any>(null); // used 
  const [shareWorkBenchData, setShareWorkBenchData] = useState<any[]>([]); // used 
  const [reformedData, setReformedData] = useState<any[]>([]);
  const treeViewRef = useRef<TreeView>(null);
  const { user } = useApp();
  const navigate = useNavigate();
  const { workbookId, workspaceId } = useParams();

  const { data: clientData } = useFetchClient(); // used 
  const { data: projectInfo } = useFetchSpecificProject(
    state.selectedClientData?.clientId
  ); // used 

  const assetId = workspaceId ?? workbookId;
  const { data : workSharedDetail} = useFetchSharedWorkBenchDetails(user?.userId, assetId);

  const handleAddWorkSpace = useCallback(() => {
    dispatch({ type: "IS_WORKSPACE_SHOW", payload: !state.isWorkSpaceShow });
    console.log("acive_task:workspaces");
    const eventName = `Create Workspace`;
    GTAButtonEventHandler(eventName)
  }, [state.isWorkSpaceShow]); // used

  const handleAddWorkBook = useCallback(() => {
    dispatch({ type: "IS_WORKBOOK_SHOW", payload: !state.isWorkBookShow });
  }, [state.isWorkBookShow]); // used 

  const onHandleAddWorkBookfORM = useCallback(() => {
    dispatch({
      type: "Is_WORKBOOK_ADD_FORM_SHOW",
      payload: !state.isWorkBookAddFormShow,
    });
  }, [state.isWorkBookAddFormShow]);

  const onHandleChangeViewCard = useCallback(() => {
    const workBookItemView =
      state.isWorkbookItemShow === "boardView" ? "gridView" : "boardView";
    dispatch({
      type: "IS_WORKBOOK_ITEM_SHOW",
      payload: workBookItemView,
    });
  }, [state.isWorkbookItemShow]);

  const onEditmanualDataEntry = useCallback(
    (e: any) => {
      const { data } = e || {};
      dispatch({ type: "EDITPOPUP_VISIBLE", payload: !state.editPopupVisible });
      dispatch({ type: "SELECTED_MANUAL_ENTRY_DATA", payload: data });
    },
    [state.editPopupVisible]
  ); // used 

  const OnHandleWorkbookCardItemClick = useCallback(
    (e?: any) => {
      const { assetType, createdBy, fileName, fileType } = e || {};
      const retreiveFile = {
        userId: createdBy,
        module: assetType,
        fileName: fileName,
        fileType: fileType
      }
      dispatch({ type: "SELECTED_MANUAL_ENTRY_DATA", payload: e });
      dispatch({ type: "WORKBOOK_ASSET_TYPE", payload: assetType });
      dispatch({ type: "EDITPOPUP_VISIBLE", payload: !state.editPopupVisible });
      dispatch({ type: "PDF_FILE_PATH_VIEWER", payload: retreiveFile });
      if (assetType === "Data Analysis") {
        const { fileName } = e || {};
        navigate(
          `/data-analyzer?fileName=${fileName}&userId=${createdBy}&processType=${assetType}&action=${true}`
        );
      }
    },
    [navigate, state.editPopupVisible]
  ); // used 

  const onRowWorkBookItemClick = useCallback(
    (e?: any) => {
      const { data } = e || {};
      const { assetType, fileName, createdBy, fileType } = data || {};
      const retreiveFile = {
        userId: createdBy,
        module: assetType,
        fileName: fileName,
        fileType: fileType
      }
      dispatch({ type: "SELECTED_MANUAL_ENTRY_DATA", payload: data });
      dispatch({ type: "WORKBOOK_ASSET_TYPE", payload: assetType });
      dispatch({ type: "EDITPOPUP_VISIBLE", payload: !state.editPopupVisible });
      dispatch({ type: "PDF_FILE_PATH_VIEWER", payload: retreiveFile });
      if (assetType === "Data Analysis") {
        const { fileName } = data || {};
        navigate(
          `/data-analyzer?fileName=${fileName}&userId=${createdBy}&processType=${assetType}&action=${true}`
        );
      }
    },
    [navigate, state.editPopupVisible]
  ); // used 

  const onHandleWorkBookItemsClick = useCallback(
    (type?: any) => {
      const { name } = type || {};
      dispatch({
        type: "SELECTED_WORKBOOK_ITEM_TYPE",
        payload: name,
      });
      onHandleAddWorkBookfORM();
    },
    [onHandleAddWorkBookfORM]
  ); // used

  const onHandleWorkSpaceItems = useCallback(() => {
    dispatch({
      type: "IS_WORKSPACE_ADD_ITEM_SHOW",
      payload: !state.isWorkSpaceAddItemShow,
    });
  }, [state.isWorkSpaceAddItemShow]); // used 

  const onHandleClientSelection = useCallback((e: any) => {
    const { value } = e || {};
    const { name, clientId } = value || {};
    dispatch({
      type: "SELECTED_CLIENT_DATA",
      payload: { clientName: name, clientId: clientId },
    });
  }, []); // used 

  const onHandleShareWorkBench = useCallback(() => {
    dispatch({
      type: "IS_SHARE_ITEMS_SHOW",
      payload: !state.isShareWorkbenchShow,
    });
    setShareWorkBenchData([]);
  }, [state.isShareWorkbenchShow]); // used 

  const onHandleRowClick = useCallback(
    (e: any) => {
      const { data } = e || {};
      const { filePath, fileName } = data || {};
      const lastSlashIndex = filePath.lastIndexOf("/");
      const directoryPath = filePath.substring(0, lastSlashIndex);
      navigate(`/data-analyzer?fileName=${fileName}&filePath=${directoryPath}`);
    },
    [navigate]
  );

  const makeAsyncDataSource = (localData: any[]) => {
    if (
      !Array?.isArray(localData) ||
      localData?.length === 0 ||
      !localData[0]?.hasOwnProperty("ID")
    ) {
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "ID",
      load() {
        return localData;
      },
    });
  };

  const toOriginalFormat = (data: TransformedFormat[]): OriginalFormat[] => {
    const original: OriginalFormat[] = [];

    const userMap: { [key: number]: OriginalFormat } = {};

    data?.forEach((item) => {
      if (typeof item?.ID === "number") {
        userMap[item?.ID] = {
          subscriberId: item?.userId!,
          subscriberName: item?.name,
          role: [],
           assetShared : user?.assetShared,
        };
      } else {
        const [userIdStr, roleIdStr] = item?.ID?.split("_");
        const userId = parseInt(userIdStr, 10);
        if (userMap[userId]) {
          userMap[userId]?.role?.push(item?.name);
        }
      }
    });

    for (const key in userMap) {
      original?.push(userMap[key]);
    }

    return original;
  }; // used 

  useEffect(() => {
    const transformedData = toTransformedFormat(workSharedDetail?.data);
    const checkedItems = transformedData
    .filter((item) => item.selected) 
    .map((item) => item.ID); 
    setUserTreeDataSource(makeAsyncDataSource(transformedData));
    if (treeViewRef.current) {
      treeViewRef.current.instance.selectItem(checkedItems);
    }
  }, [workSharedDetail, onHandleShareWorkBench]);

  useEffect(() => {
    const revertedData = toOriginalFormat(reformedData);
    setShareWorkBenchData(revertedData);
  }, [reformedData, onHandleShareWorkBench]);

  const getSelectedNodeData = (keys: (string | number)[]) => {
    if (!treeViewRef?.current) return [];
    const allNodes = treeViewRef?.current?.instance?.getNodes();
    const selectedNodeData: any[] = [];

    const findSelectedNodes = (nodes: any[]) => {
      nodes?.forEach((node) => {
        if (keys?.includes(node?.key)) {
          selectedNodeData?.push(node?.itemData);
        }
        if (node?.children?.length > 0) {
          findSelectedNodes(node?.children);
        }
      });
    };

    findSelectedNodes(allNodes);
    return selectedNodeData;
  }; // used 

  const syncTreeViewSelection = useCallback((e: any) => {
    if (treeViewRef?.current) {   
      const selectedKeys =
        treeViewRef?.current?.instance?.getSelectedNodeKeys();
      setReformedData(getSelectedNodeData(selectedKeys));
    }
  }, []); // used 

  const treeViewItemSelectionChanged = useCallback((e: any) => {
    if (treeViewRef?.current) {
      const selectedKeys =
        treeViewRef?.current?.instance?.getSelectedNodeKeys();
      setReformedData(getSelectedNodeData(selectedKeys));
    }
  }, []); // used
 
  const treeViewRender = useCallback(
    () => (
      <TreeView
        dataSource={userTreeDataSource}
        ref={treeViewRef}
        dataStructure="plain"
        keyExpr="ID"
        parentIdExpr="categoryId"
        selectionMode="multiple"
        showCheckBoxesMode="normal"
        selectNodesRecursive={false}
        displayExpr="name"
        selectByClick={true}
        onContentReady={syncTreeViewSelection}
        onItemSelectionChanged={treeViewItemSelectionChanged}
      />
    ),
    [syncTreeViewSelection, treeViewItemSelectionChanged, userTreeDataSource]
  ); // used 

  return {
    state,
    handleAddWorkSpace,
    onHandleAddWorkBookfORM,
    handleAddWorkBook,
    onHandleWorkBookItemsClick,
    onEditmanualDataEntry,
    onHandleWorkSpaceItems,
    projectInfo,
    clientData,
    onHandleClientSelection,
    onHandleShareWorkBench,
    userTreeDataSource,
    syncTreeViewSelection,
    treeViewRender,
    shareWorkBenchData,
    onHandleRowClick,
    onRowWorkBookItemClick,
    onHandleChangeViewCard, // used 
    OnHandleWorkbookCardItemClick,
  };
};

export const useFetchWorkSpace = (params: any) => {
  const navigate = useNavigate();
  return useQuery(
    ["fetch-workspace", params],
    () => fetchWorkSpaceApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.user,
      select: (data: any) => {
        return data?.data?.map((item: any) => {
          const {
            workspaceName,
            workspaceId,
            createdName,
            workspaceDescription,
            createdDate,
          } = item || {};
          return {
            title: workspaceName,
            creater: createdName,
            id: workspaceId,
            description: workspaceDescription,
            date: formatDateAndSplit(createdDate),
            onClick: () =>
              navigate(`/workspaces/${workspaceName}/${workspaceId}`),
          };
        });
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

export const useFetchWorkSpaceDetails = (params: any) => {
  return useQuery(
    ["fetch-workspace", params],
    () => fetchWorkSpaceApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.workspaceId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 


export const useFetchProjectInfo = (params: any) => {
  return useQuery(
    ["fetch-project-info", params],
    () => fetchProjectInfoApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 
export const useFetchRecentActivityInfo = (params: any) => {
  return useQuery(
    ["fetch-recentActivty-info", params],
    () => fetchRecentActivityInfoApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used

export const useFetchWorkBooks = (params: any) => {
  const navigate = useNavigate();
  return useQuery(["fetch-workbook", params], () => fetchWorkBookApi(params), {
    refetchOnWindowFocus: false,
    enabled: !!params?.user,
    select: (data: any) => {
      return data?.data?.map((item: any) => {
        const {
          workbookName,
          workbookId,
          createdName,
          workbookDescription,
          createdDate,
        } = item || {};
        return {
          title: workbookName,
          creater: createdName,
          id: workbookId,
          description: workbookDescription,
          date: formatDateAndSplit(createdDate),
          onClick: () => navigate(`/workbooks/${workbookName}/${workbookId}`),
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};// used 
export const useFetchWorkBookItemData = (params: any) => {
  return useQuery(
    ["fetch-workbook", params],
    () => fetchWorkBookItemApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.user,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

export const useCreateWorkSpace = (
  toggleWorkSpaceModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryWorkSpace = useQueryClient();
  const workSpacfun = isEdit ? updateWorkSpaceApi : createWorkSpaceApi;
  const message = isEdit ? "Workspace updated successfully" : "Workspace created successfully";
  return useMutation(workSpacfun, {
    onSuccess: () => {
      toggleWorkSpaceModal();
      fnQueryWorkSpace.invalidateQueries("fetch-workspace");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useCreateWorkItem = (isEdit?: boolean) => {
  const workBookItemfun = isEdit ? updateWorkItemApi : createWorkItemApi;
  const message = isEdit ? "Workbook item updated successfully" : "Workbook item added successfully";
  return useMutation(workBookItemfun, {
    onSuccess: () => {
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useCreateWorkBook = (
  changePanelOpened: () => void,
  isEdit?: boolean
) => {
  const fnQueryWorkSpace = useQueryClient();
  const workBookfun = isEdit ? updateWorkBookApi : createWorkBookApi;
  const message = isEdit ? "Workbook updated successfully" : "Workbook created successfully";
  return useMutation(workBookfun, {
    onSuccess: () => {
      changePanelOpened();
      fnQueryWorkSpace.invalidateQueries("fetch-workbook");
      fnQueryWorkSpace.invalidateQueries("fetch-workspace");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used 

export const useFetchActivityItemsPdf = (params: any) => {
  return useQuery(
    ["fetch-activity-items-pdf", params],
    () => fetchActivityItemsPdf(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 


export const useCreateShareWorkbenchUser = (
  changePanelOpened: () => void,
  isEdit?: boolean
) => {
  const fnQueryWorkbenchShare = useQueryClient();
  const workbenchShare = isEdit ? updateWorkbenchShare : createWorkbenchShare;
  const message = isEdit ? "Invite updates successfully" : "Invite sent successfully"; 
  return useMutation(workbenchShare, {
    onSuccess: () => {
      changePanelOpened();
      fnQueryWorkbenchShare.invalidateQueries("fetch-workbook");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used 

export const useFetchSharedData = (subscriberId: string, assetType: string) => {
  const navigate = useNavigate();
  return useQuery(
    ["fetch-shared-data", subscriberId, assetType],
    () => fetchSharedUserData(subscriberId, assetType),
    {
      refetchOnWindowFocus: false,
      enabled: !!subscriberId,
      select: (data: any) => {
        return data?.data?.map((item: any) => {
          const { assetName, ownerName, assetId, description, createdDate } =
            item || {};
          const redirectUrl =
            assetType === "workbook"
              ? `/workbooks/${assetName}/${assetId}`
              : `/workspaces/${assetName}/${assetId}`;
          return {
            title: assetName,
            creater: ownerName,
            id: assetId,
            description: description,
            date: formatDateAndSplit(createdDate),
            onClick: () => navigate(redirectUrl),
          };
        });
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 

export const useFetchSharedWorkBenchDetails = (useId : string, assetId : any) => {
  return useQuery(
    ["fetch-shared-workbench", useId, assetId],
    () => fetchSharedWorkBenchData(useId, assetId),
    {
      refetchOnWindowFocus: false,
      enabled: !!useId || !!assetId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; // used 