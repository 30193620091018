import ClientDetailsCard from "./infoTab/clientInfo";
import YTDMTDCostCard from "./YtdMdtData/ytdmdt";
import { ExpensesChartCard } from "./chartData/chartData";

const CilentDetails = (props: any) => {
  const { selectedDays } = props;
  return (
    <div className="details-container">
      <ClientDetailsCard />
      <YTDMTDCostCard />
      <ExpensesChartCard selectedDays={selectedDays} />
    </div>
  );
};

export default CilentDetails;
