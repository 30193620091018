import { Popup, TextArea } from "devextreme-react";

export const Modal = (props: any) => {
  const {
    showModal,
    onModalToggle,
    formData,
    nodeId: originalNodeId,
    steps
  } = props;

  const { data } = formData;

  const workflowData = data?.workflow;

  const nodeId = originalNodeId - 1;

  return (
    <Popup
      visible={showModal}
      onHiding={onModalToggle}
      dragEnabled={false}
      width={1200}
      height={600}
      title={`${steps} Details`}
    >
      <div className="grid-container-estimator">
        <div className="dx-field">
          <div className="dx-field-label">Name</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.name}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Description</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.description}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Type</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.inputType}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Location</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.inputLocation}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Input File Name</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.inputFilename}
              readOnly={true}
            />
          </div>
        </div >
        <div className="dx-field">
          <div className="dx-field-label">Action</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.action}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Functions</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.functions}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Type</div>
          <div className="dx-field-value">
          <TextArea
              value={workflowData?.[nodeId]?.type}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Impact</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.impact}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Type</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.outputType}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Location</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.outputLocation}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Output File Name</div>
          <div className="dx-field-value">
            <TextArea
              value={workflowData?.[nodeId]?.outputFilename}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};
