import { useCallback, useRef, useState } from "react";
import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import EstimatorProjectList from "./EstimatorProjectList";
import { ProjectData } from "./types";
import { useUpdateProject } from "../../../../hooks/finops/useEstimator";

const EstimatorMapToProject = (props: any) => {
  const { popupVisible, changePopupVisibility, selectedEstimatorData } = props;
  const [fomrData, setData] = useState<ProjectData>();
  const formDataRef = useRef<ProjectData>();

  const { row } = selectedEstimatorData || {};
  const { data: selectedEstimator } = row || {};

  const closePanel = useCallback(() => {
    changePopupVisibility();
  }, [changePopupVisibility]);

  const { mutate, isLoading } = useUpdateProject(closePanel);

  const handleFormSubmit = useCallback(() => {
    const dataToSubmit = Array.isArray(formDataRef.current)
      ? formDataRef.current[0]
      : formDataRef.current;
    if (dataToSubmit) {
      mutate(dataToSubmit);
    }
  }, [mutate]);

  const onDataChanged = useCallback(
    (data: any) => {
      setData(data);
      formDataRef.current = data;
    },
    [selectedEstimator]
  );

  return (
    <>
      <FormPopup
        title="Estimator Map To Project"
        visible={popupVisible}
        setVisible={changePopupVisibility}
        onSave={handleFormSubmit}
        height={300}
        isLoading={isLoading}
      >
        <EstimatorProjectList
          onDataChanged={onDataChanged}
          selectedEstimator={selectedEstimator}
        />
      </FormPopup>
    </>
  );
};
export default EstimatorMapToProject;
