import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../../styles/core-components/alertException.scss";
import { dropDownOptions } from "../../../config/constants";
import { DropDownButton } from "devextreme-react";
import DateDropDown from "../../ui-components/datebox/DateBox";
import RecentAlertExceptionGridView from "./helper-component/GridView";
import DataGridMultiViewLayout from "../../ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { Item } from "devextreme-react/toolbar";
import {
  useFetchAlerException,
  useFetchSpecificProjectDetails,
} from "../../../hooks/activity-monitor/useAlertExceptions";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";

const AlertExceptionPage = (props: any) => {
  const { showToolbar, filteredClientId, height, selectedDays, onCurrentValueChange } = props;
  const { data: DateRange } = useFetchDateRange();
  const projectId = useHelperHooks().getQueryParams("projectId");
  const queryClientId = useHelperHooks().getQueryParams("clientId");
  const [openPopup, setOpenPopup] = useState(false);
  const [fileViewerData, setFileViewerData] = useState(null);
  const fileViewer = JSON?.stringify(fileViewerData, null, 2);
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const navigate = useNavigate();

  const closePopupException = useCallback(() => {
    setOpenPopup(false);
    setFileViewerData(null);
  }, [setOpenPopup, setFileViewerData]);

  const projectdetail = useFetchSpecificProjectDetails(projectId as string);

  const projectClientName = projectdetail?.data?.[0]?.clientName;
  const {
    state: {client,clientId},
    filterByClient,
    clientDropDownData,
    gridRef,
  } = useAppHook();

  const {
    data,
    isLoading: isExceptionsLoading,
    refetch,
    isFetching,
  } = useFetchAlerException(
    filteredClientId ? filteredClientId : clientId,
    selectedDays
  );
  
  const FinalData =
    projectId === null
      ? data
      : data?.filter((item: any) => item?.clientName === projectClientName);

  

  const handleExceptionClick = useCallback(
    (e: any, exceptionData: any) => {
      e.preventDefault();
      setFileViewerData(exceptionData);
      setOpenPopup(true);
    },
    [setFileViewerData, setOpenPopup]
  );

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const DataGridProps = useMemo(() => {
    return {
      FinalData,
      PagerVisible: true,
      fileViewer,
      openPopup,
      isExceptionsLoading,
      handleClientLinkClick,
      closePopupException,
      handleTaskLinkClick,
      handleExceptionClick,
      gridRef,
      height: height ? height : "height-large",
    };
  }, [
    FinalData,
    fileViewer,
    openPopup,
    isExceptionsLoading,
    closePopupException,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleExceptionClick,
    gridRef,
    height,
  ]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: RecentAlertExceptionGridView,
      componentProps: DataGridProps,
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
    </Item>
  );

  const layoutProps = {
    headerText: "Alerts & Exceptions",
    refetch,
    additionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: isFetching,
    showToolbar,
    showBackButton: showBackButton,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default AlertExceptionPage;
