import "../../../styles/finops/client-info.scss";
import InvoiceInformations from "./invoiceData";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { useMemo } from "react";
import { useFetchInvoiceInfo } from "../../../hooks/finops/useFinops";

export const InvoiceDetails = (props: any) => {
  const { showToolbar,height } = props;
  const invData = useFetchInvoiceInfo();
  const { refetch } = invData;

  const DataGridProps = useMemo(() => {
    return {
      gridDataSource: invData.data,
      isLoading: invData.isLoading,
      isRefetching: invData.isFetching,
      height:height ? height : "height-large",
    };
  }, [height, invData.data, invData.isFetching, invData.isLoading]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: InvoiceInformations,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "Invoice",
    refetch,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    isLoading: invData.isFetching,
    showToolbar,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default InvoiceDetails;
