import React from "react";
import "../../../styles/ui-components/Tag.scss";

interface TagProps {
  text: string;
  className: string;
}

const PsTag: React.FC<TagProps> = ({ text, className }) => {
  return <span className={`ps-tag ps-tag-${className}`}>{text}</span>;
};

export default PsTag;
