// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resourceTab .dx-gridbase-container {
  margin-left: 5px;
  margin-right: 5px;
}
.resourceTab .dx-gridbase-container > .dx-bordered-bottom-view {
  border-radius: 0 0 8px 8px;
  margin-bottom: 10px;
}

.resource-add {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/resourcesTab.scss"],"names":[],"mappings":"AACE;EACE,gBAAA;EACA,iBAAA;AAAJ;AAEI;EACE,0BAAA;EACA,mBAAA;AAAN;;AAMA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AAHF","sourcesContent":[".resourceTab {\n  .dx-gridbase-container {\n    margin-left: 5px;\n    margin-right: 5px;\n\n    >.dx-bordered-bottom-view {\n      border-radius: 0 0 8px 8px;\n      margin-bottom: 10px;\n    }\n  }\n}\n\n\n.resource-add {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 10px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
