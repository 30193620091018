import { Item } from "devextreme-react/toolbar";
import "../../../../../styles/finops/digital-services.scss";
import DigitalServicesGridPage from "./helper-component/digitalServicesDataGrid";
import { useMemo } from "react";
import { DigitalServicesFormPopup } from "./helper-component/digitalServicesFormPopup";
import DigitalServicesEditPopup from "./helper-component/digitalServicesEditPopup";
import { DigitalServicesDeletePopup } from "./helper-component/digitalServicesDeletePopup";
import DataGridMultiViewLayout from "../../../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import {
  useFetchDigitalServices,
  useRateCard,
} from "../../../../../hooks/finops/useRatecard";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import NewIcon from "../../../../../assets/images/icons/icon-add-item-1.png";
import { usePermission } from "../../../../../contexts/permissions";

export const DigitalServicesPage = () => {
  const {
    state: {
      popupVisible,
      editPopupVisible,
      selectedRow,
      deletePopupVisible,
    },
    onAddRowClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onEditClick,
    onEditRowClick,
    changeEditPopupVisibility,
  } = useRateCard();

  const digiData = useFetchDigitalServices();
  const { refetch, isFetching } = digiData;

  const {currentPermission} = usePermission();

  const DataGridProps = useMemo(() => {
    return {
      onAddRowClick,
      onDeletePopupOpen,
      onStartPopupOpen,
      onEditClick,
      onEditRowClick,
      digiData,
    };
  }, [
    onEditClick,
    onEditRowClick,
    onDeletePopupOpen,
    onStartPopupOpen,
    onAddRowClick,
    digiData,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const PanelProps = useMemo(() => {
    return {
      selectedRow,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedRow, editPopupVisible]);

  const deleteProps = useMemo(() => {
    return {
      selectedRow,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedRow, deletePopupVisible, onDeletePopupClose]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      { currentPermission?.canCreate &&
      <PSIconText
        text="Add Role"
        alt="Add Role"
        src={NewIcon}
        onClick={onAddRowClick}
      />}
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: DigitalServicesGridPage,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    isLoading: isFetching,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <DigitalServicesFormPopup {...FormProps} />
      <DigitalServicesEditPopup {...PanelProps} />
      <DigitalServicesDeletePopup {...deleteProps} />
    </>
  );
};
