import { useDataAnalyzer } from "../../../hooks/datastudio/useDataAnalyzer";
import { Item } from "devextreme-react/toolbar";
import { DropDownButton } from "devextreme-react";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { exportItems } from "../../../config/datastudio/pageConfig";
import { dropDownOptions } from "../../../config/datastudio/commonConfig";
import "../../../styles/datastudio/DataAnalyze.scss";
import { useHelperHooks } from "../../../hooks/helper/utils";
import uploadIcon from "../../../assets/icons/icon-upload-1.png";
import MyFilesIcon from "../../../assets/icons/icon-file-list-2.png";
import FunctionsIcon from "../../../assets/icons/icon-functions-1.png";
import ValidateIcon from "../../../assets/icons/icon-validate-4.png";
import MergeIcon from "../../../assets/icons/icon-file-merge-1.png";
import SaveIcon from "../../../assets/icons/icon-save-1.png";
import Addfiles from "./helper-component/AddFiles";
import MyFiles from "./helper-component/MyFiles";
import Functions from "./helper-component/Functions";
import { useCallback, useMemo, useState} from "react";
import Validation from "./helper-component/Validation";
import ResultGrid from "./helper-component/ResultGrid";
import ConfirmModal from "../../../components/data-studio/ConfirmModal";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import ReplaceNullConfirmModal from "../../../components/data-studio/ReplaceNullConfirmModal";
import KeywordSearch from "../../../components/data-studio/KeywordSearch";
import MergeColumnConfirmModal from "../../../components/data-studio/MergeColumnConfirmModel";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import SaveConfirmModal from "../../../components/data-studio/SaveConfirmModal";
import PsExport from "../../../components/core-component/exportDatalake/PsExport";
import { usePage } from "../../../contexts/pageContext";
import { fetchDataProcessingMyFilesApi } from "../../../service/datastudio/api-service";
import { usePermission } from "../../../contexts/permissions";

export const DataAnalyzer = () => {

  const {
    handleDropDownPageIndex,
    handleDateRangeChange,
    handleColumnIndexChange
  } = useAppHook();


  const {
    state: {
      gridDataSource,
      tabView,
      isRowUpdated,
      basicFunctionType,
      showConfirmModal,
      selectedTemplate,
      totalrowsprocessed,
      totalrowsvalidated,
      totalrowswitherrors,
      isVisibile,
      replaceNullConfirmModal,
      columnData,    
      keywordSearchConfirmModal,
      mergecolumnconfirmModal,
      keywordSearchStats,
      isVisibileKeywordSearch,
      isSavingpopup,
      paramsDetails: {  filename: currentFilename, userId, processType },
    },
    isFileUploading,
    dataProcessing,
    handleDeleteRow,
    onHanldeCheckbox,
    onTabChange,
    handleDuplicates,
    handleFilesUpload,
    handleRowPrepared,
    rowUpdated,
    getGridData,
    getColumnNames,
    hanldeOnclickApply,
    handleApplyClick,
    handleMergeColumnClick,
    onClose,
    handleListClick,
    handleOnKeywordClick,
    handleKeywordSearch,
    handleSelectionChanged,
    handleResetButton,
    handleSaveDocument,
    handleItemClick,
    onFunctionReset,
    handleSavePopup,
    onActionHandler,
    rowinsert,
    fetchFunctionDropDownFeatures,
    userRecentData,
    isFilesLoading,
    isFilesFetching,
    isDataLoading,
    refetchUserRecentData,
    pageIndex,
    pageSize    
  } = useDataAnalyzer();


  const activeTab: any = Object.keys(tabView)?.[0];
  const { data: DateRange } = useFetchDateRange();
  const [showPsExport, setShowPsExport] = useState(false);


  const {currentPermission} = usePermission();

  const { isLoading: isGridDataLoading, refetch: dataRefetch, isFetching: isDatafetching } = dataProcessing;
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const workbookFileName = useHelperHooks().getQueryParams("fileName");

  const refetch = useCallback(() => {
    dataRefetch();
    refetchUserRecentData();
  }, [dataRefetch, refetchUserRecentData]);

  
  const AdditionalToolbarContent = (
    <Item
      location="after"
      locateInMenu="auto"
      widget="dxButton"
      cssClass="toolbar-item-flex"
    >
       <DropDownButton
        icon="export"
        text="Export"
        width="auto"
        stylingMode="text"
        items={exportItems}
        useSelectMode={true}
        displayExpr="text"
        dropDownOptions={dropDownOptions}
        onItemClick={(e) => {
          handleItemClick(e);
          if (e.itemData.text === "Export to DataLake") {
            setShowPsExport(true); 
          }
        }}
        disabled={
          activeTab !== "resultGrid" &&
          activeTab !== "functions" &&
          activeTab !== "validation"
        }
        className="dropdown-export"
      />
      { currentPermission?.canCreate &&
      <PSIconText
        alt="addFiles"
        text="Upload Files"
        src={uploadIcon}
        disabled={!!workbookFileName}
        onClick={() => onTabChange("addFiles")}
      />}
      <PSIconText
        text="Files"
        alt="MyFiles"
        src={MyFilesIcon}
        disabled={!!workbookFileName}
        onClick={() => onTabChange("myFiles")}
      />
      <PSIconText
        text="Functions"
        alt="functions"
        src={FunctionsIcon}
        disabled={gridDataSource?.length === 0}
        onClick={() => onTabChange("functions")}
      />
      <PSIconText
        text="Validation"
        alt="validation files"
        src={ValidateIcon}
        disabled={gridDataSource?.length === 0}
        onClick={() => onTabChange("validation")}
      />
      <PSIconText
        text="Merge"
        alt="merge"
        src={MergeIcon}
        disabled={activeTab !== "myFiles" && activeTab !== "merge"}
        onClick={() => onTabChange("merge")}
      />
      <PSIconText
        text="Save"
        alt="Save"
        src={SaveIcon}
        disabled={!isRowUpdated}
        onClick={handleSavePopup}
      />
     {(activeTab === "myFiles" || activeTab === "merge") && (
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} handleDropDownPageIndex={handleDropDownPageIndex}/>
    )}

    </Item>
  );

  const title = "Data Analyzer";
  const rowTitle: any = (
    <>
      <span>{title}</span>
    </>
  );

  const ResultGridProps = useMemo(() => {
    return {
      gridDataSource,
      isGridDataLoading,
      isDataLoading,
      isDatafetching,
      handleRowPrepared,
      rowUpdated,
      rowinsert,
      getColumnNames,
      handleDeleteRow,
      onHanldeCheckbox,
    };
  }, [
    gridDataSource,
    isGridDataLoading,
    isDataLoading,
    isDatafetching,
    handleRowPrepared,
    rowUpdated,
    getColumnNames,
    rowinsert,
    handleDeleteRow,
    onHanldeCheckbox,
  ]);

  const AddFileProps = useMemo(() => {
    return {
      handleFilesUpload,
      isFileUploading,
    };
  }, [handleFilesUpload, isFileUploading]);

  const FunctionsProps = useMemo(() => {
    return {
      fetchFunctionDropDownFeatures,
      basicFunctionType,
      tabView,
      handleDuplicates,
      hanldeOnclickApply,
      handleResetButton,
      resultGridProps: ResultGridProps,
      keywordSearchStats,
      isDataLoading,
      isVisibileKeywordSearch,
      handleOnKeywordClick,
    };
  }, [
    fetchFunctionDropDownFeatures,
    basicFunctionType,
    tabView,
    handleDuplicates,
    hanldeOnclickApply,
    handleResetButton,
    ResultGridProps,
    keywordSearchStats,
    isDataLoading,
    isVisibileKeywordSearch,
    handleOnKeywordClick,
  ]);

  const ValidationProps = useMemo(() => {
    return {
      tabView,
      isDataLoading,
      resultGridProps: ResultGridProps,
      selectedTemplate,
      totalrowsprocessed,
      totalrowsvalidated,
      totalrowswitherrors,
      isVisibile,
      handleResetButton,
      handleListClick,
      hanldeOnclickApply,
      handleApplyClick,
      handleSelectionChanged,
    };
  }, [tabView, isDataLoading, ResultGridProps, selectedTemplate, totalrowsprocessed, totalrowsvalidated, totalrowswitherrors, isVisibile, handleResetButton, handleListClick, hanldeOnclickApply, handleSelectionChanged, handleApplyClick]);

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      processType: "Data Analyzer",
      process: "latest",
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [filterValue]);
  
  const apiProps = useMemo(
    () => ({
      apiFunction: fetchDataProcessingMyFilesApi,
      additionalParams: additionalParams,
    }),
    [additionalParams]
  );

  const MyFileProps = useMemo(() => {
    return {
      tabView,
      onRowClick: getGridData,
      onActionHandler,
      userRecentData,
      isFilesLoading,
      isFilesFetching,
      pageIndex,
      pageSize,
      handleDropDownPageIndex,

      handleColumnIndexChange,
      apiProps
    };
  }, [tabView, getGridData, onActionHandler, userRecentData, isFilesLoading, isFilesFetching,  pageIndex,
    pageSize,
    handleDropDownPageIndex,
    handleColumnIndexChange,
    apiProps]);

  const renderBody = useCallback(() => {
    const view: any = {
      addFiles: <Addfiles {...AddFileProps} />,
      myFiles: <MyFiles {...MyFileProps} />,
      functions: <Functions {...FunctionsProps} />,
      validation: <Validation {...ValidationProps} />,
      merge: <MyFiles {...MyFileProps} />,
      resultGrid: <ResultGrid {...ResultGridProps} />,
    };
    return view[activeTab];
  }, [
    AddFileProps,
    MyFileProps,
    FunctionsProps,
    ValidationProps,
    ResultGridProps,
    activeTab,
  ]);

  const ConfirmModalProps = useMemo(() => {
    return {
      show: showConfirmModal,
      onClose: handleResetButton,
      onConfirm: onFunctionReset,
    };
  }, [showConfirmModal, handleResetButton, onFunctionReset]);

  const ReplaceNullConfirmModalProps = useMemo(() => {
    return {
      show: replaceNullConfirmModal,
      onConfirm: hanldeOnclickApply,
      columnData: columnData,
      onClose: onClose
    };
  }, [replaceNullConfirmModal, hanldeOnclickApply, columnData, onClose]);

  const MergeColumnConfirmModalProps = useMemo(() => {
    return {
      show: mergecolumnconfirmModal,
      onConfirm: handleMergeColumnClick,
      columnData: columnData,
      onClose: onClose
    };
  }, [mergecolumnconfirmModal, handleMergeColumnClick, columnData, onClose]);

  const keywordSearchConfirmModalProps = useMemo(() => {
    return {
      show: keywordSearchConfirmModal,
      onClose: onClose,
      onConfirm: handleKeywordSearch,
    };
  }, [keywordSearchConfirmModal, onClose, handleKeywordSearch]);

  const SaveConfirmModalProps = useMemo(() => {
    return {
      show: isSavingpopup,
      filename: currentFilename,
      onConfirm: handleSaveDocument,
      onClose: handleSavePopup,
    };
  }, [isSavingpopup, currentFilename, handleSaveDocument, handleSavePopup]);

  return (
    <div id="data-analyzer" className="flex-colunm-layout">
      <ToolbarAnalytics
        title={rowTitle === null ? title : rowTitle}
        refresh={refetch}
        disabledRefresh={activeTab === "addFiles"}
        additionalToolbarContent={AdditionalToolbarContent}
        showBackButton={showBackButton}
      />
       {showPsExport && (
        <PsExport
          isVisible={showPsExport}
          onClose={() => setShowPsExport(false)}
          onSave={() => {setShowPsExport(false)}}
          userId={userId} 
          fileName={currentFilename}
          processType={processType}
          isLoading={false}
        />
      )}
      {renderBody()}
      <ConfirmModal {...ConfirmModalProps} />
      <ReplaceNullConfirmModal {...ReplaceNullConfirmModalProps} />
      <KeywordSearch {...keywordSearchConfirmModalProps} />
      <MergeColumnConfirmModal {...MergeColumnConfirmModalProps} />
      <SaveConfirmModal {...SaveConfirmModalProps} />
    </div>
  );
};

export default DataAnalyzer;
