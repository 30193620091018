import React, { useCallback, useMemo, useState, useEffect } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Button as ActionsButton,
  Editing,
  Scrolling,
  Pager,
  SearchPanel,
  LoadPanel,
} from "devextreme-react/data-grid";
import { HeaderFilter } from "devextreme-react/cjs/gantt";
import DataSource from "devextreme/data/data_source";
import { LoadOptions } from "devextreme/data";
import { PsDataGridProps } from "./helper-component/types";
import TableSkeleton from "./helper-component/TableSkeleton";
import { usePage } from "../../../contexts/pageContext";

const PsDataGrid = React.memo((props: PsDataGridProps) => {
  const { pageProps, gridProps, apiProps } = props;
  const { nodataText, heightClass,id } = pageProps;

  const {
    dataSource,
    columns,
    keyExpr,
    allowedPageSizes,
    searchPanelVisible,
    headerFilterVisible,
    pagingEnabled,
    pagerEnabled,
    detailComponent,
    onRowEdit,
    onRowDelete,
    onRowClone,
    onCellPrepared,
    handleColumnIndexChange,
  } = gridProps;


  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const { pageSize, pageIndex } = filterValue?.[id] || {};
  const { apiFunction, additionalParams, refreshKey } = apiProps || {};

  const [isDataFetched, setIsDataFetched] = useState(false);

  useEffect(() => {
    if (apiFunction === undefined) {
      setIsDataFetched(true);
    } else {
      setIsDataFetched(false);
    }
  }, [apiFunction, refreshKey]);

  const loadData = useCallback(
    async (loadOptions: LoadOptions) => {
      const { skip = 0, take = 25, sort = [], filter = [] } = loadOptions;

      let currentSkip = 0;

      if (Array.isArray(sort) && sort.length > 0) {
        currentSkip = 0;
      }

      if (filter.length === 0 && skip === 0 && currentSkip !== 0) {
        loadOptions.skip = currentSkip;
      } else {
        currentSkip = skip;
      }

      if (skip !== currentSkip) {
        currentSkip = skip;
      }

      const page = Math.floor((skip ?? 0) / take) + 1;
      const sortArray = Array.isArray(sort)
        ? (sort as Array<{ selector: string; desc: boolean }>)
        : [];
      const sortColumn = sortArray[0]?.selector || null;
      const sortOrder =
        sortArray[0]?.desc === true
          ? "DESC"
          : sortArray[0]?.desc === false
          ? "ASC"
          : null;

      const queryParams = {
        Page: page,
        Count: take,
        sortColumn,
        sortOrder,
        ...additionalParams,
      };

      try {
        const response = await apiFunction(queryParams);
        setIsDataFetched(true);
        const data = response?.data || [];
        const totalCount = response?.pagination?.totalRecords || data.length;

        return {
          data,
          totalCount,
        };
      } catch (error) {
        console.error("Error loading data:", error);
        setIsDataFetched(false);
        throw error;
      }
    },
    [apiFunction, additionalParams]
  );

  const createDataSource = useCallback(() => {
    if (!apiFunction) {
      return new DataSource({
        store: {
          type: "array",
          data: dataSource || [],
          key: keyExpr,
        },
      });
    }

    return new DataSource({
      key: keyExpr,
      load: loadData,
    });
  }, [apiFunction, dataSource, keyExpr, loadData]);

  const finalDataSource = useMemo(() => {
    if (apiFunction === undefined) {
      setIsDataFetched(true);
      return dataSource;
    } else {
      return createDataSource();
    }
  }, [apiFunction, dataSource, createDataSource]);

  return (
    <>
      <div>{isDataFetched ? "" : <TableSkeleton />}</div>
      <div hidden={!isDataFetched}>
        <DataGrid
          id={id}
          className={`grid theme-dependent ${heightClass}`}
          dataSource={finalDataSource}
          keyExpr={keyExpr}
          showBorders={true}
          allowColumnResizing={true}
          allowColumnReordering={true}
          repaintChangesOnly={true}
          noDataText={nodataText}
          remoteOperations={{ filtering: false, paging: true, sorting: true }}
          onCellPrepared={onCellPrepared}
          onOptionChanged={handleColumnIndexChange}
        >
          {/* {pagingEnabled && <Paging defaultPageSize={25}  pageSize={pageSize} pageIndex={pageIndex} />} */}
          {pagingEnabled && <Paging pageSize={pageSize ? pageSize : 25} pageIndex={pageIndex} />}
          {pagerEnabled && (
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          )}
          {searchPanelVisible && <SearchPanel visible width={200} />}
          {headerFilterVisible && <HeaderFilter visible />}
          <Scrolling rowRenderingMode="virtual" />
          {columns?.map((col, index) => (
            <Column key={index} {...col} />
          ))}
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating={!!onRowEdit}
            allowDeleting={!!onRowDelete}
          />
          <LoadPanel showIndicator={true} showPane={true} text="" />
          {onRowEdit && (
            <Column caption="" type="buttons" width={120}>
              <ActionsButton
                name="edit"
                onClick={onRowEdit}
                hint="Edit"
                cssClass="action-image"
              />
              {onRowClone && (
                <ActionsButton
                  text="Clone"
                  hint="Clone"
                  icon="copy"
                  cssClass="action-image"
                  onClick={onRowClone}
                />
              )}
              <ActionsButton
                name="delete"
                onClick={onRowDelete}
                hint="Delete"
                cssClass="action-image"
              />
            </Column>
          )}
          {detailComponent && (
            <MasterDetail enabled={true} component={detailComponent} />
          )}
        </DataGrid>
      </div>
    </>
  );
});

export default PsDataGrid;
