import notify from "devextreme/ui/notify";

type NotificationProps = {
  message: string;
  position?: { at: string; my: string; offset?: string };
  type?: string;
};

const showNotification = ({
  message,
  position = { at: "top center", my: "top center", offset: "0 20" },
  type = "success",
}: NotificationProps) => {
  notify({ message, position }, type);
};

export default showNotification;
