
import Popup from "devextreme-react/popup";
import { useDeleteUrlClient } from "../../../../hooks/client/useClientUrl";
import PsButton from "../../../../components/ui-components/button/Button";


const DeleteUrl = (props: any) => {
  const { deletePopupVisible, onDeletePopupClose,selectedClientData } = props;
  const { row} = selectedClientData || {};
  const { data} = row || {};
  const { url_id,url} = data || {};
  const { isLoading, refetch,isRefetching } = useDeleteUrlClient(onDeletePopupClose, url_id);

  const loaderProps = {
    show: isLoading || isRefetching,
    loadertext: "Please Wait...",
  };  
  
  return (
    <Popup
      title="Delete Client"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height='auto'
    >
        <div>
          <p>Are you sure you want to delete the <b>{url}</b> client ?</p>
          
        </div>
        <div>
          <PsButton onClick={() => refetch()} text="Confirm" type="normal" loaderProps={loaderProps}/>
          <div style={{float:'right'}}>
          <PsButton onClick={onDeletePopupClose} text="Close" type="normal" />
        </div>
      </div>
    </Popup>
  );
};

export default DeleteUrl;
