import { Popup } from "devextreme-react";

export const Modal = (props: any) => {
  const { showModal, onModalToggle } = props;
  return (
    <Popup
      visible={showModal}
      onHiding={onModalToggle}
      dragEnabled={false}
      width={1200}
      height={600}
      title="Add Resources"
    >
      <div className="grid-container-estimator"></div>
    </Popup>
  );
};
