import { LoadPanel } from "devextreme-react";
import DataGrid, {
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  HeaderFilter,
  Pager,
} from "devextreme-react/data-grid";
import { CellDollar } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/finops/commonConfig";

const InfrastructureDataGridPage = (props: any) => {
  const { infraData, onDeletePopupOpen, onEditRowClick } = props;

  const { data, isLoading, isFetching } = infraData || {};

  const infraList = data?.infra_new || {};

  return (
    <>
      <DataGrid
        className="grid theme-dependent-infrastructure height-large"
        noDataText={"No Infrastructure to display at this time"}
        focusedRowEnabled
        keyExpr="id"
        dataSource={infraList}
        allowColumnReordering
        showBorders
        allowColumnResizing
      >
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={25} />
        <Column dataField="resource" caption="Resource" />
        <Column
          dataField="cost_per_day"
          caption="Rate"
          cellRender={CellDollar}
        />
        <Column   dataField="type"
          caption="Type" />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"id"}
        />
        <Column caption="" type="buttons" width={180}>
          <ActionsButton
            name="edit"
            onClick={onEditRowClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton name="delete" cssClass="action-image" onClick={onDeletePopupOpen} />
        </Column>
        <Paging defaultPageSize={25} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}/>
      </DataGrid>
      <LoadPanel container=".content" visible={isFetching} />
    </>
  );
};

export default InfrastructureDataGridPage;
