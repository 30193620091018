import DataGrid, {
  Column,
  Editing,
  Paging,
  Button as ActionsButton,
  HeaderFilter,
  Pager,
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import DataSource from "devextreme/data/data_source";
import { LoadPanel } from "devextreme-react";
import {CellDollar} from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/finops/commonConfig";

const OperationsDataGridPage = (props: any) => {
    const {opData,onDeletePopupOpen, onEditRowClick } = props; 
    const { data, isLoading,isFetching } = opData || {};   
    const opsTeamCost = data?.operations;
  
    const [gridDataSource, setGridDataSource] = useState<any>(null);
    
      useEffect(() => {
        setGridDataSource(
          new DataSource({
            key: "designation",
            load: () => opsTeamCost,
          })
        );
      }, [opsTeamCost]);
  
    return (
      <>
      <DataGrid
        className="grid theme-dependent-operations height-large"
        noDataText={"No Operations to display at this time"}
        focusedRowEnabled
        dataSource={gridDataSource}
        allowColumnReordering
        showBorders
        allowColumnResizing
      >
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={25} />
        <Column dataField="designation" caption="Role" />
        <Column dataField="description" caption="Role Description" />
        <Column dataField="cost_per_day" caption="Rate" cellRender={CellDollar} />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"id"}
        />
        <Column caption="" type="buttons" width={180}>
          <ActionsButton
            name="edit"
            onClick={onEditRowClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton name="delete"  cssClass="action-image" onClick={onDeletePopupOpen} />
        </Column>
        <Paging defaultPageSize={25} />
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}/>
      </DataGrid>
      <LoadPanel container=".content" visible={isFetching} />
    </>
  );
};

export default OperationsDataGridPage;
