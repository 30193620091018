import { useMemo } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Button as ActionsButton,
  Editing,
  Scrolling,
  Pager,
  SearchPanel,
} from "devextreme-react/data-grid";
import "../../../../../styles/client/ClientInformation.scss";
import EditProject from "../editProject";
import { useNavigate } from "react-router-dom";
import { HeaderFilter } from "devextreme-react/cjs/gantt";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellTimeZone,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../config/client/commonConfig";
import { useProjects } from "../../../../../hooks/client/useProject";
import MasterDetailView from "./helper-component/ProjectInfo";
import { GTAButtonEventHandler } from "../../../../../utlis/GAT-event-handler";
import DataSource from "devextreme/data/data_source";

const ClientInfo = ({
  gridData,
  gridRef,
  height,
  handlePageIndexChange,
  fetchNextPage,
  hasNextPage,
  pageSize,
  pageIndex,
}: any) => {
  const navigate = useNavigate();

  const handleProjectLinkClick = (
    event: any,
    projectId: string,
    projectName: any
  ) => {
    navigate(`/project-profile?projectId=${projectId}`);
    event.preventDefault();
    const eventName = `project_profile: ${projectName}_${projectId}`;
    GTAButtonEventHandler(eventName);
  };

  const handleClientLinkClick = (
    event: any,
    clientId: string,
    clientName: any
  ) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
    const eventName = `client_profile: ${clientName}_${clientId}`;
    GTAButtonEventHandler(eventName);
  };

  const {
    state: { editPopupVisible, selectedProject },
    onEditProjectClick,
    changeEditPopupVisibility,
  } = useProjects();

  const PanelProps = useMemo(() => {
    return {
      selectedProject,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedProject, editPopupVisible]);

  const gridDataV2 = new DataSource({
    key: "projectId",
    load: (loadOptions: any) => {
      return new Promise((resolve, reject) => {
        try {
          const data = gridData?.list || [];
          const totalCount = gridData?.totalcount || data.length; // Default to data.length if totalCount is not available
          // Calculate the start and end index for the current page
          const pageIndex = loadOptions.skip / loadOptions.take; // skip is the starting index, take is page size
          const pageSize = loadOptions.take;
          const paginatedData = data.slice(
            pageIndex * pageSize,
            (pageIndex + 1) * pageSize
          );

          resolve({
            data: paginatedData,
            totalCount: totalCount,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  });

  const handleNextPage = (e: any) => {
    handlePageIndexChange && handlePageIndexChange(e);
    if (e.fullName === "paging.pageIndex" && hasNextPage) {
      fetchNextPage();
    }
  };

  const renderHeaderFilter = (options: any, filterName: string) => {
    return (options.dataSource = gridData?.list
      ? gridData.list.map((item: any) => ({
          text: item[filterName],
          value: item[filterName],
        }))
      : []);
  };
  return (
    <>
      <div className="recent-activity-grid">
        <DataGrid
          className={`grid theme-dependent ${height}`}
          dataSource={gridDataV2}
          showBorders={true}
          id="gridContainer"
          allowColumnResizing={true}
          allowColumnReordering={true}
          repaintChangesOnly={true}
          noDataText={"No projects to display at this time"}
          ref={gridRef}
          remoteOperations={true}
          keyExpr={"projectId"}
          onOptionChanged={handleNextPage}
        >
          <Paging
            defaultPageIndex={0}
            pageIndex={pageIndex}
            pageSize={pageSize}
            defaultPageSize={25}
          />
          <SearchPanel visible={true} width={200} />
          <HeaderFilter visible={true} />
          <Column
            dataField="projectName"
            caption="Project Name"
            width={"30%"}
            allowHeaderFiltering={true}
            headerFilter={{
              allowSearch: true,
              dataSource: (options) => {
                renderHeaderFilter(options, "projectName");
              },
            }}
            allowSorting={true}
            cellRender={(data) => {
              const projectName = data?.data?.projectName;
              const projectId = data?.data?.projectId;
              return (
                <a
                  href="*"
                  onClick={(e) =>
                    handleProjectLinkClick(e, projectId, projectName)
                  }
                >
                  {projectName}
                </a>
              );
            }}
          />
          <Column
            dataField="clientName"
            caption="Client Name"
            width={"20%"}
            allowSorting={true}
            allowHeaderFiltering={true}
            headerFilter={{
              allowSearch: true,
              dataSource: (options) => {
                renderHeaderFilter(options, "clientName");
              },
            }}
            cellRender={(data) => {
              const clientName = data?.data?.clientName;
              const clientId = data?.data?.clientId;
              return (
                <a
                  href="*"
                  onClick={(e) =>
                    handleClientLinkClick(e, clientId, clientName)
                  }
                >
                  {clientName}
                </a>
              );
            }}
          />
          <Column
            dataField="projectDescription"
            dataType="string"
            allowSorting={true}
            allowFiltering={false}
            caption="Project Description"
            width={"30%"}
          />
          <Column
            dataField="projectType"
            allowSorting={true}
            dataType="string"
            caption="Activity Type"
            width={"20%"}
            allowExporting={true}
            headerFilter={{
              allowSearch: true,
              dataSource: (options) => {
                renderHeaderFilter(options, "projectType");
              },
            }}
          />
          <Column
            dataField="status"
            dataType="string"
            cellRender={ContactStatus}
            width={"10%"}
          />
          <Column
            dataField="startDate"
            dataType="string"
            cellRender={cellTimeZone}
            width={"20%"}
          />
          <Column
            dataField="endDate"
            dataType="string"
            cellRender={cellEndTime}
            width={"10%"}
          />
          <Editing
            mode="row"
            useIcons={true}
            allowUpdating
            allowDeleting={true}
            key={"id"}
          />
          <Column caption="" type="buttons" width={120}>
            <ActionsButton
              name="edit"
              onClick={onEditProjectClick}
              hint="Edit"
              cssClass="action-image"
            />
            <ActionsButton
              name="delete"
              disabled={true}
              hint="delete"
              cssClass="action-image"
            />
          </Column>
          <MasterDetail enabled={true} component={MasterDetailView} />
          <Scrolling rowRenderingMode="virtual"></Scrolling>
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}
          />
        </DataGrid>
        <EditProject {...PanelProps} />
      </div>
    </>
  );
};

export default ClientInfo;
