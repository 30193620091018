import { useCallback, useState } from "react";
import { Client } from "../types";
import ClientEditForm from "./client-form/clienteditform";
import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import { useCreateClient } from "../../../../hooks/client/useClientCatlog";
import { useNavigate, useLocation } from "react-router-dom";

const EditClient = (props: any) => {
  const { EditpopupVisible, changeEditPopupVisibility, selectedClient } = props;
  const [formData, setData] = useState<Client>();

  const { row } = selectedClient || {};
  const { data: selectedClientData } = row || {};
  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility();
  }, [changeEditPopupVisibility]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refreshpage = () => navigate(pathname);
  const { mutate, isLoading } = useCreateClient(() => {
    closePanel();
    refreshpage();
  }, isEdit);

  const handleFormSubmit = useCallback(() => {
    if (formData){
      mutate(formData);
    }
  }, [mutate, formData]);

  const UpdateClientdFiled = useCallback(
    (data: React.SetStateAction<Client | undefined>) => {
      setData(data);
    },
    []
  );

  return (
    <FormPopup
      title="Edit Client"
      visible={EditpopupVisible}
      setVisible={changeEditPopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <ClientEditForm
        newContactData={selectedClientData}
        updateField={UpdateClientdFiled}
      />
    </FormPopup>
  );
};
export default EditClient;
