import { RequiredRule } from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import { TextArea, Validator } from "devextreme-react";
import Card from "../../../../../../components/ui-components/card/card";
import { useFetchClient } from "../../../../../../hooks/app/useApps";
import { useFetchProject } from "../../../../../../hooks/task/useTask";
import { useEffect } from "react";

const HeaderTab = (props: any) => {

  const { editHeaderData, updateHeaderField, selectedValues, setSelectedValues } = props;

  const { data: clientData } = useFetchClient();
  const { data: projectData } = useFetchProject(selectedValues?.clientId) as any;

  const ClientNameValues = clientData?.map((item: any) => item?.name);
  const ProjectDataValues = projectData?.map((item: { projectName: any }) => item.projectName);

  useEffect(() => {
    setSelectedValues(editHeaderData);
  }, [editHeaderData, setSelectedValues]);

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <div className="dx-field">
          <div className="dx-field-label">Workflow Name</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Workflow Name..."
              height={35}
              value={editHeaderData?.workflowName}
              onValueChange={updateHeaderField("workflowName")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Description</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Description..."
              height={35}
              value={editHeaderData?.description}
              onValueChange={updateHeaderField("description")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Version</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Version..."
              height={35}
              value={editHeaderData?.version}
              onValueChange={updateHeaderField("version")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Activity</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Activity..."
              height={35}
              value={editHeaderData?.activity}
              onValueChange={updateHeaderField("activity")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Type</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Type..."
              height={35}
              value={editHeaderData?.type}
              onValueChange={updateHeaderField("type")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Application Name</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Application Name..."
              height={35}
              value={editHeaderData?.applicationName}
              onValueChange={updateHeaderField("applicationName")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Client Name</div>
          <div className="dx-field-value">
            <SelectBox
              value={editHeaderData?.clientName}
              items={ClientNameValues}
              readOnly={true}
              stylingMode="filled"
              onValueChange={updateHeaderField("clientName")}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </div>
        </div >
        <div className="dx-field">
          <div className="dx-field-label">Project Name</div>
          <div className="dx-field-value">
            <SelectBox
              value={editHeaderData?.projectName}
              items={ProjectDataValues}
              stylingMode="filled"
              onValueChange={updateHeaderField("projectName")}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Service Name</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Service Name..."
              height={35}
              value={editHeaderData?.serviceName}
              onValueChange={updateHeaderField("serviceName")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Module Id</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Module Id..."
              height={35}
              value={editHeaderData?.moduleId}
              onValueChange={updateHeaderField("moduleId")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Source</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Source..."
              height={35}
              value={editHeaderData?.source}
              onValueChange={updateHeaderField("source")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Target</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Target..."
              height={35}
              value={editHeaderData?.target}
              onValueChange={updateHeaderField("target")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Priority</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Priority..."
              height={35}
              value={editHeaderData?.priority}
              onValueChange={updateHeaderField("priority")}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Severity</div>
          <div className="dx-field-value">
            <TextArea
              placeholder="Severity..."
              height={35}
              value={editHeaderData?.severity}
              onValueChange={updateHeaderField("severity")}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default HeaderTab;