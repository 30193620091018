import { useCallback } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Position,
  NodeTypes,
} from "@xyflow/react";

import "./style.css";
import CustomNode from "./CustomNode";

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

const initialNodes: Node[] = [
  {
    id: "A",
    type: "input",
    position: { x: 0, y: 150 },
    data: { label: "Name:Mr.John, API Name: Documet Analyzer" },
    ...nodeDefaults,
  },
  {
    id: "B",
    position: { x: 250, y: 150 },
    data: { label: "File.json,API Name: Documet Analyzer" },
    ...nodeDefaults,
  },
  {
    id: "C",
    position: { x: 450, y: 150 },
    data: { label: "Storage Conatiner: Document Storage,API Name: Documet Analyzer" },
    ...nodeDefaults,
  },
  {
    id: "D",
    position: { x: 650, y: 150 },
    data: { label: "Action: Analysis Documet trigger," },
    ...nodeDefaults,
  },
  {
    id: "E",
    position: { x: 650, y: 150 },
    data: {
      label: "Telemetry Titeline: Total Completed Process :  10, Error: 5,API Name: Documet Analyzer Telemetry by id",
    },
    ...nodeDefaults,
  },
  {
    id: "F",
    position: { x: 850, y: 150 },
    data: {
      label: "OutPut: Total Record : 1000, Totalpage: 50,API Name: Documet Analyzer by id",
    },
    ...nodeDefaults,
  },
];

const initialEdges: Edge[] = [
  {
    id: "A-B",
    source: "A",
    target: "B",
  },
  {
    id: "B-C",
    source: "B",
    target: "C",
  },
  {
    id: "C-D",
    source: "C",
    target: "D",
  },
  {
    id: "D-E",
    source: "D",
    target: "E",
  },
  {
    id: "E-F",
    source: "E",
    target: "F",
  },
];

const LinecageText = () => {
  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const nodeTypes: NodeTypes = {
    custom: CustomNode,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default LinecageText;
