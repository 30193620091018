import { useQuery } from "react-query";
import { fetchClientAlertExceptionApi, fetchSpecificProjectEditDetailsApi } from "../../service/activity-monitor/api-service";
import { formatDate } from "devextreme/localization";

export const useFetchSpecificProjectDetails = (projectId: string) => {
  return useQuery(
    ["fetch-project-byId", projectId],
    () => fetchSpecificProjectEditDetailsApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};




export const useFetchAlerException = (clientId: any, selectedDays: any) => {
  const startTime = selectedDays?.startTime ? formatDate(selectedDays.startTime, "yyyy-MM-dd") : null;
  const endTime = selectedDays?.endTime ? formatDate(selectedDays.endTime, "yyyy-MM-dd") : null;
  const timePeriod = selectedDays?.timePeriod;

  const queryParams = {
    clientId,
    startTime,
    endTime,
    timePeriod,
  };

  return useQuery(
    ["fetch-alert-Exception", clientId, selectedDays],
    async () => {
      const response: any = await fetchClientAlertExceptionApi(queryParams);
      if (response) {
        return response;
      }
    },
    {
      //enabled: !!clientId  && !!selectedDays,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        const updatedData = data?.data?.map((item: any) => {
          return {
            ...item,
            name: item?.clientName,
          };
        });
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

