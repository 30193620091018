import { Handle } from "@xyflow/react";
import { Modal } from "./FlowPopup";
import { useState } from "react";
import { Popover } from "devextreme-react";
import { generateRandomId } from "../../../../utlis/helper";

const TextUpdaterNode = (props: any) => {
  const { data, sourcePosition, targetPosition } = props || {}
  const { step, label, description, additionalInfo, createdBy, createdAt, popupData, params, popupType, height, width } = data;
  const [showModal, setShowModal] = useState(false);
  const randomId = generateRandomId();
  const onModalToggle = () => {
    setShowModal(!showModal);
  };

  const nodeStyle = {
    height: height || (description || createdBy ? "200px" : "80px"),
    width: width || "200px",
  };

  const renderPopupData = () => {
    if (!popupData || Object?.keys(popupData)?.length === 0) {
      return <p>No additional information available</p>;
    }
    return (
      <div>
        {Object?.entries(popupData)?.map(([key, value]:any) => (
          <div key={key} className="popup-data-item">
            <strong>{key}:</strong>{" "}
            {typeof value === "object" && value !== null ? (
              <pre>{JSON?.stringify(value, null, 2)}</pre> 
            ) : (
              value || "N/A" 
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <div onClick={onModalToggle}>
        {targetPosition && <Handle type="target" position={targetPosition} />}
        <div
          className={`${description || createdBy ? "large-node" : "small-node"} NodeWrapper`}
          style={nodeStyle}
        >
          <div className="header_info">
            {step && (
              <center>
                <span className="badge-blue">{step}</span>
              </center>
            )}
            <span className="label">{label}</span>
            <span>{description}</span>
            <span>{additionalInfo}</span>
          </div>
          {createdBy && (
            <div className="header_info">
              <hr />
              <span>Created By: {createdBy}</span>
              <span>Created At: {createdAt}</span>
            </div>
          )}
          <div>
            <span className="reactflow-info" id={randomId} />
            <Popover
              target={`#${randomId}`}
              showEvent="mouseenter"
              hideEvent="mouseleave"
              position="right"
              width={'auto'}
            >
              {renderPopupData()}
            </Popover>
          </div>
        </div>
        {sourcePosition && <Handle type="source" position={sourcePosition} id="a" />}
      </div>
      <Modal
        showModal={showModal}
        onModalToggle={onModalToggle}
        popupData={popupData}
        popupType={popupType}
        params={params}
      />
    </>
  );
};

export default TextUpdaterNode;
