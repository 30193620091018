import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { useManageInfo } from "../../../../../../hooks/client/useProject";
import PsAccordion from "../../../../../../components/ui-components/accordion/accordion";
import { CustomItem, CustomTitle } from "./customManager";

const ManagementDetailView = (
  props: DataGridTypes.MasterDetailTemplateData
) => {
  const projectId = props.data?.data?.projectId;

  const params = {
    viewType: "managementview",
    projectId,
  };

  const { data: detailData, isLoading } = useManageInfo(
    params.projectId,
    params.viewType
  );

  const detailDataView = detailData?.resources?.[0]?.departments || [];

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <div className="externalPsAccordion">
      <PsAccordion
        dataSource={detailDataView}
        Component={(e: any) => CustomItem(e)}
        CustomTitle={CustomTitle}
      />
    </div>
  );
};

export default ManagementDetailView;
