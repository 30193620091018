import { useMemo } from "react";
import ClientGrid from "./helper-component/clientgrid";
import NewClient from "./helper-component/newclient";
import EditClient from "./helper-component/editClient";
import DeleteClient from "./helper-component/deleteclient";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { SearchPanel } from "devextreme-react/data-grid";
import { filterStatusList } from "../../../config/client/commonConfig";
import { dropDownOptions } from "../../../config/constants";
import { useClientCatlog } from "../../../hooks/client/useClientCatlog";
import { useAppHook } from "../../../hooks/app/useApps";
import { useFetchClientCatalog } from "../../../hooks/client/useClientCatlog";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";

export const ClientCatalog = () => {
  const {
    state: {
      popupVisible,
      selectedClient,
      deletePopupVisible,
      EditpopupVisible,
    },
    onRowClick,
    onEditClick,
    onAddContactClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    changeEditPopupVisibility,
  } = useClientCatlog();

  const {
    state: { client, status, clientId },
    filterByClient,
    filterByStatus,
    clientDropDownData,
    gridRef,
  } = useAppHook();

  const { data, refetch, isFetching } = useFetchClientCatalog(clientId);

  const DataGridProps = useMemo(() => {
    return {
      data,
      onRowClick,
      onEditClick,
      onAddContactClick,
      onDeletePopupOpen,
      gridRef,
    };
  }, [
    data,
    gridRef,
    onAddContactClick,
    onDeletePopupOpen,
    onEditClick,
    onRowClick,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const editProps = useMemo(() => {
    return {
      selectedClient,
      EditpopupVisible,
      changeEditPopupVisibility,
    };
  }, [selectedClient, EditpopupVisible, changeEditPopupVisibility]);

  const deleteProps = useMemo(() => {
    return {
      selectedClient,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedClient, deletePopupVisible, onDeletePopupClose]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={filterStatusList}
        stylingMode="text"
        text={status}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
      />
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <PSIconText
        src={NewIcon}
        text="Add Client"
        alt="Add Client"
        onClick={onAddContactClick}
      />
      <SearchPanel placeholder="Client Search" />
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: ClientGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "Client Catalog",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    isLoading: isFetching,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewClient {...FormProps} />
      <EditClient {...editProps} />
      <DeleteClient {...deleteProps} />
    </div>
  );
};
