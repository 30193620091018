import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Popup } from "devextreme-react";
import ToolbarAnalytics from "../toolbar-analytics/ToolbarAnalytics";
import "../../../styles/ui-components/LineageView.scss";
import { Item } from "devextreme-react/cjs/toolbar";
import PsButton from "../button/Button";
import {PsPopUpProps} from "./Types";

const PsPopUp: React.FC<PsPopUpProps> = ({
  visible,
  onHiding,
  fullScreen = true,
  title,
  isLoading = false,
  component,
}) => {
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setIndex(Date.now());
  }, []);

  useEffect(() => {
    if (visible) {
      const popupContent = document?.querySelector(
        `.ps-popup-${index} .dx-popup-content`
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        `.ps-popup-${index} .dx-popup-content-scrollable`
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [visible, index]);

    const onClose = useCallback(() => {
      onHiding();
    }, [onHiding]);

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PsButton
          icon="close"
          mode="text"
          type="normal"
          width={"auto"}
          onClick={onClose}
          eventName="File Viewer close button"
        />
      </Item>
    );
  }, [onClose]);

  const renderComponent = useCallback(() => {
    if (component) {
      return <div className="file-container">{component}</div>;
    }
    return <div className="loader-container">No component found.</div>;
  }, [component]);

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      fullScreen={fullScreen}
      width="auto"
      height={1000}
      showTitle={false}
      showCloseButton={false}
      className={`ps-popup-${index}`}
    >
      <div className="view-wrapper">
        <ToolbarAnalytics 
        title={title} 
        showToolbar={true} 
        additionalToolbarContent={AdditionalToolbarContent}
        />
        <div className="popup-content-container">
          {isLoading ? (
            <div className="loader-container">Loading...</div>
          ) : (
            renderComponent()
          )}
        </div>
      </div>
    </Popup>
  );
};

export default PsPopUp;