import DocumentCatalog from "../../assets/media/background-digital-24.jpg";
import DocumentAnalyzer from "../../assets/media/background-digital-23.jpg";
import ModelLibrary from "../../assets/media/background-digital-30.jpg";
import TasksAutomation from "../../assets/media/background-digital-17.jpg";
import AlertsExceptions from "../../assets/media/background-digital-6.jpg";
import DataLake from "../../assets/media/background-digital-5.jpg";
import Tutorials from "../../assets/media/background-digital-4.jpg";
import Reference from "../../assets/media/background-digital-22.jpg";

export const DocumentProcessServiceMap = [
  {
    id: 1,
    image: DocumentCatalog,
    name: "Document Catalog",
    description: [
      "Manage all client document processing workflows from a single interface. Customized views provide a single point of development, QA and data distribution to client endpoints.",
    ],
    navigation:'/document-catalog?action=true'
  },
  {
    id: 2,
    name: "Document Analyzer",
    image: DocumentAnalyzer,
    description: [
      "Ideal for interactive document analysis and data extraction. Provides a visual interface for exploring and analyzing document data.",
    ],
    navigation:'/document-analyzer?action=true'
  },
  {
    id: 3,
    name: "Model Library",
    image: ModelLibrary,
    description: [
      "Repository of vaious custome models developed specifically for client use cases. Provides a starting point for new projects and tasks.",
    ],
  },
  {
    id: 4,
    name: "Tasks & Automation",
    image: TasksAutomation,
    description: [
      "Command center for managing all document processing tasks and managing client workflows. Provides real-time monitoring and alerts.",
    ],
      navigation:'/task-automation?action=true'
  },
  {
    id: 5,
    name: "Alerts & Exceptions",
    image: AlertsExceptions,
    description: [
      "Get realtime updates on document processing tasks, exceptions, and alerts. Manage exceptions and errors from a single interface. Provides access to full telemetry and error logs.",
    ],
      navigation:'/alert-exceptions?action=true'
  },
  {
    id: 6,
    name: "Client Data Lake",
    image: DataLake,
    description: [
      "Access to all client data within the Polaris Data Lake. Provides data storage, retrieval, and analysis capabilities.",
    ],
    navigation:'/data-lake-management?action=true'
  },
];

export const DocumentProcessingGuides = [
  {
    id: 1,
    image: Tutorials,
    name: "Tutorials",
    description: [
      "Tutorials and guides for new users to get started with web scraping. Provides step-by-step instructions for setting up and running scraping tasks.",
    ],
    url: "",
  },
  {
    id: 2,
    name: "Reference documents",
    image: Reference,
    description: [
      "Reference documents for advanced users. Provides detailed information on web scraping best practices, tools, and techniques.",
    ],
    url: "https://www.google.com",
  }
];
