import { TickerCard } from './library/TickerCard';
import { TickerProps } from './type';
import { convertToUSD } from '../../../utlis/helper';

export const MetricsTicker = ({ secondaryValue,primaryValue,icon,title,onClick}: TickerProps) =>
  <TickerCard
    title={title}
    icon={icon}
    primaryValue={primaryValue}
    secondaryValue={secondaryValue}
    formatValue={convertToUSD}
    percentage={20.3}
    onClick={onClick}  
    />;
