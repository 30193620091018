// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveFile {
  margin-left: 10px;
  background-color: rgb(15, 108, 189);
  border: none;
  border-radius: 4px;
}

.treeviewButton {
  margin-left: 10px;
  border: none;
  border-radius: 4px;
}

.popup-pdf-container {
  width: auto;
  height: auto;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-pdf-container .PdfViewer__container {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/task/clientTaskTab.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mCAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,iBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,2BAAA;EACA,8BAAA;AACJ","sourcesContent":[".saveFile {\n  margin-left: 10px;\n  background-color: rgb(15, 108, 189);\n  border: none;\n  border-radius: 4px;\n}\n\n.treeviewButton {\n  margin-left: 10px;\n  border: none;\n  border-radius: 4px;\n}\n\n.popup-pdf-container {\n  width: auto;\n  height: auto;\n  overflow-y: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .PdfViewer__container {\n    margin-top: 30px !important;\n    margin-bottom: 30px !important;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
