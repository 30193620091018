import React from "react";
import TaskInfo from "./infoTab/taskinfo";
import CostChart from "./chartData/chartData";

const TaskDetails = ({ selectedDays }: any) => {
  return (
    <div className="details-container">
      <TaskInfo />
      <CostChart selectedDays={selectedDays} />
    </div>
  );
};

export default TaskDetails;
