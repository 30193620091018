import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  createUrlApi,
  fetchAllUrlsApi,
  updateUrlApi,
  deleteUrlClientById,
  fetchTemplateApi,
  fetchUrlSummaryApi,
  bulkURLsUploadApi
} from "../../service/client/api-service";
import { useCallback, useReducer } from "react";
import { UrlStateProps } from "../../pages/client/url-catalog/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const initialState: UrlStateProps = {
  popupVisible: false,
  selectedClientData: null,
  EditpopupVisible: false,
  deletePopupVisible: false,
  showpopup: false,
  viewType: "gridView",
  refreshKey: 0,
  openLineage: false,
  selectedData: null,
  gridTitleStatus: "",
};

const reducer = (state: UrlStateProps, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, // used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        EditpopupVisible: action.payload, // used
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used
      };
    case "SELECTED_CLIENT_DATA":
      return {
        ...state,
        selectedClientData: action.payload, // used
      };
    case "SHOW_POPUP":
      return {
        ...state,
        showpopup: action.payload,
      };
    case "VIEW_TYPE":
      return {
        ...state,
        viewType: action.payload,
      };
    case "REFRESH_KEY":
      return {
        ...state,
        refreshKey: action.payload,
      };
    case "OPEN_LINEAGE":
      return {
        ...state,
        openLineage: action.payload,
      };
    case "SELECTED_DATA":
      return {
        ...state,
        selectedData: action.payload,
      };
    case "GRID_TITLE_STATUS":
      return {
        ...state,
        gridTitleStatus: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useUrl = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddContactClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []);

  const onEditUrlClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectClient = (data: any) => {
    dispatch({ type: "SELECTED_CLIENT_DATA", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectClient(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []);

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []);

  const setPopupVisible = useCallback((value: boolean) => {
    dispatch({ type: "SHOW_POPUP", payload: value });
  }, []);

  const setViewType = useCallback((value: string) => {
    dispatch({ type: "VIEW_TYPE", payload: value });
  }, []);

  const setRefreshKey = useCallback((refreshKey: any) => {
    dispatch({ type: "REFRESH_KEY", payload: refreshKey + 1 });
  }, []);

  const setOpenLineage = useCallback((value: boolean) => {
    dispatch({ type: "OPEN_LINEAGE", payload: value });
  }, []);

  const setSelectedData = useCallback((data: any) => {
    dispatch({ type: "SELECTED_DATA", payload: data });
  }, []);

  const setGridTitleStatus = useCallback((status: string) => {
    dispatch({ type: "GRID_TITLE_STATUS", payload: status });
  }, []);

  return {
    state,
    onAddContactClick,
    changePopupVisibility,
    handleSelectClient,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditUrlClick,
    changeEditPopupVisibility,
    setPopupVisible,
    setViewType,
    setRefreshKey,
    setOpenLineage,
    setSelectedData,
    setGridTitleStatus
  };
};


export const useFetchurl = (
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: any,
  clientId: any
) => {
  const page = pageIndex[pageIndex.length - 1] + 1;

  const queryParams = {
    Count,
    sortColumn,
    sortOrder,
    Page: page,
    clientId
  };
  return useQuery(
    ["fetch-urls", queryParams],
    () => fetchAllUrlsApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      keepPreviousData: true,
      select: (data: any) => {
        const updatedData = data?.data?.map((item: any) => {
          return {
            ...item,
            name: item?.customer_name,
          };
        }) || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return { list: updatedData, totalcount };
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};



export const useCreateUrl = (
  toggleClientModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const taskfunc = isEdit ? updateUrlApi : createUrlApi;
  const message = isEdit ? "Client url updated successfully" : "New client url added";
  return useMutation(taskfunc, {
    onSuccess: () => {
      toggleClientModal();
      fnQueryClient.invalidateQueries("fetch-urls");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteUrlClient = (
  onDeleteHandler: () => void,
  url_id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(["deleteClient", url_id], () => deleteUrlClientById(url_id), {
    enabled: !!url_id && false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      onDeleteHandler();
      queryClient.invalidateQueries("fetch-urls");
      showNotification({
        message: "Client url deleted successfully",
      });
    },
  });
};

export const useFetchTemplate = () => {
  return useQuery("fetch-template", fetchTemplateApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.map((item: any) => {
        return {
          ...item,
        };
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchUrlSummary = () => {
  return useQuery(
    ["fetch-url-summary", ],
    () => fetchUrlSummaryApi(),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}; 

export const useBulkUpload = (onClose:any) => {
  const fnQueryClient = useQueryClient();
  return useMutation(bulkURLsUploadApi, {
    onSuccess: () => {
      onClose();
      fnQueryClient.invalidateQueries("fetch-bulkURLsupload-project");
      showNotification({
        message: "URLs uploaded successfully",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used


export const useBoardViewGrid = (
  clientId: any,
  status: any,
  Count: any,
  sortColumn: any,
  sortOrder: any,
  pageIndex: any,
  isNextPage: any,
  
  
) => {
  const page = pageIndex[pageIndex.length - 1] + 1; // Current page based on index

  const queryParams = {
    clientId,
    status,
    Count,
    sortColumn,
    sortOrder,
    Page: page,
  };

  return useQuery(
    ["fetch-urls", queryParams],
    () => fetchAllUrlsApi(queryParams), 
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      keepPreviousData: true,
      select: (data: any) => {
        const updatedData =
          data?.data?.map((item: any) => ({
            ...item,
            name: item?.customer_name, 
          })) || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return { list: updatedData, totalcount };
      },
      onError: (error: Error) => {
        console.error("Error fetching URLs:", error);
      },
    }
  );
};
