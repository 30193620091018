// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.start-icon-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 10%;
  font-size: 13px !important;
  background-color: rgb(15, 108, 189);
  color: white !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.dx-row-focused .start-icon-image {
  background-color: rgb(15, 108, 189) !important;
}

.common-toolbar-header {
  font-size: 22px;
  font-weight: 400;
  color: black !important;
}

.dx-datagrid-headers.dx-datagrid-nowrap.dx-bordered-top-view {
  border-radius: 8px 8px 0 0;
}

.theme-dependent .dx-gridbase-container > .dx-bordered-bottom-view {
  border-radius: 0 0 8px 8px;
}

.task-start {
  margin-bottom: 50px;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/styles/task/TasksAutomation.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,0BAAA;EACA,mCAAA;EACA,uBAAA;EACA,8BAAA;EACA,6BAAA;AACF;;AAGE;EACE,8CAAA;AAAJ;;AAIE;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;AADJ;;AAIE;EACE,0BAAA;AADJ;;AAMM;EACE,0BAAA;AAHR;;AAQE;EACE,mBAAA;EACA,eAAA;AALJ","sourcesContent":[".start-icon-image{\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  border-radius: 10%;\n  font-size: 13px!important;\n  background-color: rgb(15, 108, 189);\n  color: white!important;\n  padding-right: 10px!important;\n  padding-left: 10px!important;\n}\n\n.dx-row-focused{\n  .start-icon-image{\n    background-color: rgb(15, 108, 189)!important;\n  }\n}\n\n  .common-toolbar-header{\n    font-size: 22px;\n    font-weight: 400;\n    color: black!important;\n  }\n  \n  .dx-datagrid-headers.dx-datagrid-nowrap.dx-bordered-top-view {\n    border-radius: 8px 8px 0 0;\n  }\n  \n  .theme-dependent {\n    .dx-gridbase-container {\n      > .dx-bordered-bottom-view {\n        border-radius: 0 0 8px 8px;\n      }\n    }\n  }\n   \n  .task-start{\n    margin-bottom: 50px;\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
