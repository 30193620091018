import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import LineageViewer from "../../../../components/ui-components/lineageView/LineageView";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { useMemo, useState } from "react";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import ProjectCatlogLineage from "../../../../lineageTracing/project";
import { cellStartTime } from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import DataSource from "devextreme/data/data_source";
import { usePage } from "../../../../contexts/pageContext";
import { customDateRange } from "../../../../config/constants";
import { fetchClientData } from "../../../../service/app/api-service";

export const ClientGridComponent = (props: any) => {
  const { id, location, projectShortName, handleColumnIndexChange } = props;
  const [selectedData, setSelectedData] = useState<any>();
  const [openLineage, setOpenLineage] = useState(false);

  const {projectId}= id || {};
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    filename : filenameColumn,
    createdDate : createdDateColumn,
    fileSize : fileSizeColumn,
  } = filterValue || {};

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      projectId : projectId,
      location, 
      projectShortName,
      timePeriod,
      ...(timePeriod === customDateRange && { startTime, endTime}),
    };
  }, [projectId, filterValue]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchClientData,
      additionalParams: additionalParams,
    }),
    [additionalParams]
  );

  const onHanldingLineage = () => {
    setOpenLineage(!openLineage);
  };

  const onHandleLineageData = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const val = {
      ...props,
      ...data,
    };
    setSelectedData(val);
    onHanldingLineage();
  };

  const renderLineageIcon = (cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  };

  const columns = [
    {
      caption:"File Name",
      dataField:"filename", 
      dataType:"string",
      filterValues:filenameColumn,
    },
    {
      caption:"Uploaded on",
      dataField:"createdDate",
      dataType:"string",
      cellRender:cellStartTime,
      filterValues:createdDateColumn,
    },
    {
       caption:"File Size",
       dataField:"fileSize", 
       dataType:"string",
       filterValues:fileSizeColumn,
    },
    {
      caption:"",
      type:"buttons",
      cellRender:renderLineageIcon,
      width:70
    }
  ]

  const pageProps = useMemo(
    () => ({
    nodataText: "No Results to display at this time",
    heightClass: "height-small",
    id:"linaegeDatagrid",
  }), []);

  const gridProps =useMemo( 
    () => ({
    dataSource: DataSource,
    columns,
    allowedPageSizes,
    searchPanelVisible: false,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    handleColumnIndexChange,
  }), [columns, handleColumnIndexChange ]);

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: (
      <ProjectCatlogLineage
        data={selectedData}
        assetType={selectedData?.assetType}
      />
    ),
  };

  return (
    <>
      <PsDataGrid
       pageProps={pageProps}
       gridProps={gridProps}
       apiProps={apiProps}
     />
      <LineageViewer {...lineageDataProps} />
    </>
  );
};
