// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.t-gap {
  margin-top: 50px;
}

.t-gap h6 {
  padding: 20px 20px 0 20px;
  font-size: 18px !important;
  cursor: pointer;
}

.titleWrapperBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.titleWrapperBar div {
  margin-right: 5px;
}

.titleWrapperBar-nospace {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.titleWrapperBar-nospace div {
  margin-right: 5px;
}

.underline-link {
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px !important;
}

.expand-collapse-tilecard-layout {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
}

.expand-collapse-tilecard-layout img {
  width: 35px;
  height: 35px;
}

.content-panel.collapsed {
  display: none;
}

.recent-activity-grid.collapsed .content-panel {
  display: none;
}

.flex-colunm-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.individual-grid {
  border: 1px solid #ddd;
  padding: 15px;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
}

.individual-grid.collapsed .content-panel {
  display: none;
}

.expand-collapse-tilecard-layout {
  background: none;
  border: none;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/DataGridTitleLayout.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;;AAEA;EACE,yBAAA;EACA,0BAAA;EACA,eAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,mBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AACF;AAAE;EACE,iBAAA;AAEJ;;AAEA;EACE,0BAAA;EACA,eAAA;EACA,0BAAA;AACF;;AACA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;EACA,UAAA;EACA,iBAAA;AAEF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AACA;EACE,aAAA;AAEF;;AAAA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;AAGF;;AAAA;EACE,sBAAA;EACA,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAGF;;AAAA;EACE,aAAA;AAGF;;AAAA;EACE,gBAAA;EACA,YAAA;EACA,eAAA;AAGF","sourcesContent":[".t-gap {\n  margin-top: 50px;\n}\n\n.t-gap h6 {\n  padding: 20px 20px 0 20px;\n  font-size: 18px !important;\n  cursor: pointer;\n}\n\n.titleWrapperBar{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  margin-bottom: 15px;\n  div{\n    margin-right: 5px;\n  }\n}\n\n.titleWrapperBar-nospace{\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  div{\n    margin-right: 5px;\n  }\n}\n\n.underline-link{\n  text-decoration: underline;\n  cursor: pointer;\n  font-size: 18px !important;\n}\n.expand-collapse-tilecard-layout {\n  background: none;\n  border: none;\n  cursor: pointer;\n  padding: 0;\n  margin-left: 10px; \n}\n\n.expand-collapse-tilecard-layout img {\n  width: 35px; \n  height: 35px; \n}\n\n.content-panel.collapsed {\n  display: none;\n}\n\n.recent-activity-grid.collapsed .content-panel {\n  display: none;\n}\n.flex-colunm-layout {\n  display: flex;\n  flex-direction: column;\n  gap: 20px; \n}\n\n.individual-grid {\n  border: 1px solid #ddd; \n  padding: 15px;\n  background: #fff;\n  border-radius: 10px;\n  margin-bottom: 30px;\n}\n\n.individual-grid.collapsed .content-panel {\n  display: none;\n}\n\n.expand-collapse-tilecard-layout {\n  background: none;\n  border: none;\n  cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
