import Popup from "devextreme-react/popup";
import PsButton from "../../../../../../components/ui-components/button/Button";
import { useDeleteDigitalServices } from "../../../../../../hooks/finops/useRatecard";

export const DigitalServicesDeletePopup = (props: any) => {
  const { deletePopupVisible, onDeletePopupClose, selectedRow } =
    props;
  const { row } = selectedRow || {};
  const { data } = row || {};
  const { id, designation } = data || {};
  const { isLoading, refetch, isRefetching } = useDeleteDigitalServices(
    onDeletePopupClose,
    id
  );

  const loaderProps = {
    show: isLoading || isRefetching,
    loadertext: "Please Wait...",
  };

  if (typeof window === "undefined") return null;
  return (
    <Popup
      title="Delete Digital Services Role"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height="auto"
    >
        <div>
          <p>
            Are you sure you want to delete the <b>{designation}</b> ?
          </p>
          <p>
            <b>
              If you delete this Digital Services Role, you will not be able to
              recover it.
            </b>
          </p>
        </div>
        <div>
          <PsButton
            onClick={() => refetch()}
            text="Confirm"
            type="normal"
            loaderProps={loaderProps}
          />

          <div style={{ float: "right" }}>
            <PsButton onClick={onDeletePopupClose} text="Close" type="normal" />
          </div>
        </div>
    </Popup>
  );
};
