import { useCallback, useEffect, useMemo, useState } from "react";
import {
  HeaderData,
  newHeaderTab,
  MetaData,
  newMetaTab,
  WorkflowData,
  DataItems,
  newDataTab
} from "../../types";
import WorkFlowEditPanel from './workflowEditPanel'
import { Popup } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { ToolbarAnalytics } from "../../../../../components/ui-components/toolbar-analytics";
import { useFetchWorkflowFunctionDropdown, useFetchManifestData, useEditWorkflow } from "../../../../../hooks/task/useWorkflow";
import PsButton from "../../../../../components/ui-components/button/Button";
import { EditConfirmation } from "./EditConfirmation";
import { useApp } from "../../../../../contexts/app";
import { useNavigate, useLocation } from "react-router-dom";

export const WorkflowEditForm = (props: any) => {

  const {
    onEditOpen,
    onEditClose,
    onEditWorkflowOpen,
    showCancelPopup,
    setShowCancelPopup,
    selectedValues,
    setSelectedValues,
    selectedWorkflowData
  } = props;
  const { user: { userId, name } } = useApp();
  const { data: manifestData } = useFetchManifestData(selectedWorkflowData?.manifestId);
  const { data: workflowFunctionDropdown } = useFetchWorkflowFunctionDropdown();
  const actionData = workflowFunctionDropdown?.actions;
  const typeData = workflowFunctionDropdown?.type;
  const functionFilterData = workflowFunctionDropdown?.functions;
  const fileTypeData = workflowFunctionDropdown?.fileType;
  const transmitTypeData = workflowFunctionDropdown?.transmitType;

  const closePanel = useCallback(() => {
    onEditWorkflowOpen(false);
  }, [onEditWorkflowOpen]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refreshpage = () => navigate(pathname);
  const { mutate, isSuccess } = useEditWorkflow(() => {
    closePanel();
    refreshpage();
  });

  const [editHeaderProps, setEditHeaderProps] = useState<HeaderData>({
    ...newHeaderTab,
  });

  const [editMetaProps, setEditMetaProps] = useState<MetaData>({
    ...newMetaTab,
  });

  const [editWorkflowProps, setEditWorkflowProps] = useState<WorkflowData[]>([]);

  const [editDataProps, setEditDataProps] = useState<DataItems>({
    ...newDataTab,
  });

  useEffect(() => {
    if (manifestData) {
      setEditHeaderProps(manifestData?.header);
      setEditMetaProps(manifestData?.meta);
      setEditWorkflowProps(manifestData?.workflow);
      setEditDataProps(manifestData?.data);
    }
  }, [manifestData]);

  const updateHeaderField = (field: string) => (value: any) => {
    setEditHeaderProps((prevHeader: any) => ({
      ...prevHeader,
      [field]: value,
    }));
  };

  const updateMetaField = (field: string) => (value: any) => {
    setEditMetaProps((prevMeta: any) => {
      const [mainField, subField] = field?.split('.');
      if (subField) {
        return {
          ...prevMeta,
          [mainField]: {
            ...prevMeta[mainField],
            [subField]: value,
          },
        };
      }
      return {
        ...prevMeta,
        [field]: value,
      };
    });
  };

  const updateWorkflowField = (nodeId: number, field: string) => (value: any) => {
    setEditWorkflowProps((prevWorkflow: any) => {
      const newWorkflow = [...prevWorkflow];
      newWorkflow[nodeId] = {
        ...newWorkflow[nodeId],
        [field]: value,
      };
      return newWorkflow;
    });
  };

  const updateDataField = (field: string) => (value: any) => {
    setEditDataProps((prevData: any) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleFormSubmit = useCallback(() => {
    const queryParams = {
      manifestId: selectedWorkflowData?.manifestId,
      filename: selectedWorkflowData?.workflowName,
    };
    const newWorkflowData = {
      header: { ...editHeaderProps, ...{ "updatedBy": userId, "updatedUser": name } },
      meta: editMetaProps,
      workflow: editWorkflowProps,
      data: editDataProps
    }
    mutate({ queryParams, payload: newWorkflowData });
  }, [mutate, editHeaderProps, editMetaProps, editWorkflowProps, editDataProps, selectedWorkflowData, userId, name]);

  const handleCancelClick = () => {
    setShowCancelPopup(true);
  };

  const handleCancelConfirm = () => {
    setShowCancelPopup(false);
    onEditClose();
  };

  const handleCancelReject = () => {
    setShowCancelPopup(false);
  };

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PsButton
          icon="close"
          mode="text"
          type="normal"
          width={"auto"}
          onClick={handleCancelClick}
          eventName="File Viewer close buttonp"
        />
      </Item>
    );
  }, []);

  useEffect(() => {
    if (onEditOpen) {
      const popupContent = document?.querySelector(
        ".workflow-popup .dx-popup-content"
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        ".workflow-popup .dx-popup-content-scrollable"
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [onEditOpen]);

  return (
    <Popup
      visible={onEditOpen}
      onHiding={onEditClose}
      fullScreen={true}
      width={"100%"}
      height={1000}
      showTitle={false}
      showCloseButton={false}
      className="workflow-popup"
    >
      <div className="view-wrapper">
        <ToolbarAnalytics
          title={"Edit Workflow"}
          showToolbar={true}
          additionalToolbarContent={AdditionalToolbarContent}
        />
        <div>
          <WorkFlowEditPanel
            editHeaderData={editHeaderProps}
            editMetaData={editMetaProps}
            editWorkflowData={editWorkflowProps}
            editDataItems={editDataProps}
            updateHeaderField={updateHeaderField}
            updateMetaField={updateMetaField}
            updateWorkflowField={updateWorkflowField}
            updateDataField={updateDataField}
            handleFormSubmit={handleFormSubmit}
            onEditClose={onEditClose}
            actionData={actionData}
            functionFilterData={functionFilterData}
            fileTypeData={fileTypeData}
            transmitTypeData={transmitTypeData}
            showCancelPopup={showCancelPopup}
            setShowCancelPopup={setShowCancelPopup}
            typeData={typeData}
            isSuccess={isSuccess}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
          />
        </div>
      </div>
      <EditConfirmation
        visible={showCancelPopup}
        onConfirm={handleCancelConfirm}
        onCancel={handleCancelReject}
      />
    </Popup>
  );
};

export default WorkflowEditForm;
