import DataGrid, {
  Column,
  Editing,
  Button as ActionsButton,
  HeaderFilter,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { useEffect, useState } from "react";
import DataSource from "devextreme/data/data_source";
import { LoadPanel } from "devextreme-react";
import {CellDollar} from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/finops/commonConfig";

const DigitalServicesGridPage = (props: any) => {
  const { digiData, onDeletePopupOpen, onEditRowClick } = props;
  const { data, isLoading, isFetching } = digiData;

  const digiServices = data?.digitalServices;

  const [gridDataSource, setGridDataSource] = useState<any>(null);

  useEffect(() => {
    setGridDataSource(
      new DataSource({
        key: "designation",
        load: () => digiServices,
      })
    );
  }, [digiServices]);

  return (
    <>
      <DataGrid
        className="grid theme-dependent-digital height-large"
        noDataText={"No Digital Services to display at this time"}
        focusedRowEnabled
        dataSource={gridDataSource}
        allowColumnReordering
        showBorders
        allowColumnResizing
      >
        <HeaderFilter visible={true} />
        <Paging defaultPageSize={25} />
        <Column dataField="designation" caption="Role" />
        <Column dataField="description" caption="Role Description" />
        <Column dataField="cost_per_day" caption="Rate" cellRender={CellDollar} />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"id"}
        />
        <Column caption="" type="buttons" width={180}>
          <ActionsButton
            name="edit"
            onClick={onEditRowClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton name="delete" onClick={onDeletePopupOpen} />
        </Column>
          <Pager
            visible={true}
            allowedPageSizes={allowedPageSizes}
            displayMode={"full"}
            showPageSizeSelector={true}
            showInfo={true}
            showNavigationButtons={true}/>
      </DataGrid>
      <LoadPanel container=".content" visible={isFetching} />
    </>
  );
};

export default DigitalServicesGridPage;
