import { CSSProperties } from "react";
import { TextArea } from "devextreme-react/text-area";
import { ValueChangedEvent } from "devextreme/ui/text_area";

type TextAreaProps = {
  label?: string;
  name?: string;
  style?: CSSProperties;
  height?: number;
  maxLength?: number;
  value?: string;
  notesLabel?: {
    "aria-label": string;
  };
  autoResizeEnabled?: boolean;
  stylingMode?: string;
  disabled?: boolean;
  width?: string;
  onValueChanged?: (e: ValueChangedEvent) => void;
};

export const PsTextArea = ({
  label,
  style,
  height,
  width,
  maxLength,
  value,
  notesLabel,
  autoResizeEnabled,
  disabled,
  onValueChanged,
  ...rest
}: React.PropsWithChildren<TextAreaProps>) => {
  return (
    <>
      <TextArea
        label={label}
        height={height || 70}
        width={width || "100%"}
        maxLength={maxLength}
        value={value}
        inputAttr={notesLabel || {}}
        autoResizeEnabled={autoResizeEnabled}
        disabled={disabled}
      />
    </>
  );
};
