import { FormTextbox } from "../../../../../../components/ui-components/form/form-textbox/FormTextbox";

const MetaTab = (props: any) => {

  const { newMetaData, updateMetaField } = props;

  return (
    <>
      <div className="dx-field">
        <div className="dx-field-label">Tags</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Tags..."
            value={newMetaData?.tags}
            isEditing={false}
            onValueChange={updateMetaField("tags")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Description</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Description..."
            value={newMetaData?.description}
            isEditing={false}
            onValueChange={updateMetaField("description")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Content Type</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Content Type..."
            value={newMetaData?.headers?.contentType}
            isEditing={false}
            onValueChange={updateMetaField("headers.contentType")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Content Length</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Content Length..."
            value={newMetaData?.headers?.contentLength}
            isEditing={false}
            onValueChange={updateMetaField("headers.contentLength")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Encoding</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Encoding..."
            value={newMetaData?.headers?.encoding}
            isEditing={false}
            onValueChange={updateMetaField("headers.encoding")}
          />
        </div>
      </div >
      <div className="dx-field">
        <div className="dx-field-label">Catalog</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Catalog..."
            value={newMetaData?.catalog}
            isEditing={false}
            onValueChange={updateMetaField("catalog")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Index</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Index..."
            value={newMetaData?.index}
            isEditing={false}
            onValueChange={updateMetaField("index")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Environment</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Environment..."
            value={newMetaData?.environment}
            isEditing={false}
            onValueChange={updateMetaField("environment")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Retry Count</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Retry Count..."
            value={newMetaData?.retryCount}
            isEditing={false}
            onValueChange={updateMetaField("retryCount")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Duration Ms</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Duration Ms..."
            value={newMetaData?.durationMs}
            isEditing={false}
            onValueChange={updateMetaField("durationMs")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Geo Location</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Geo Location..."
            value={newMetaData?.geoLocation}
            isEditing={false}
            onValueChange={updateMetaField("geoLocation")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">OS Version</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="OS Version..."
            value={newMetaData?.additionalInfo?.osVersion}
            isEditing={false}
            onValueChange={updateMetaField("additionalInfo.osVersion")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Python Version</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Python Version..."
            value={newMetaData?.additionalInfo?.pythonVersion}
            isEditing={false}
            onValueChange={updateMetaField("additionalInfo.pythonVersion")}
          />
        </div>
      </div>
      <div className="dx-field">
        <div className="dx-field-label">Docker Info</div>
        <div className="dx-field-value">
          <FormTextbox
            placeholder="Docker Info..."
            value={newMetaData?.additionalInfo?.dockerInfo}
            isEditing={false}
            onValueChange={updateMetaField("additionalInfo.dockerInfo")}
          />
        </div>
      </div>
    </>
  );
}

export default MetaTab;