export const legendItems = [
  { color: "#f7f49e", text: "Duplicate" },
  { color: "#acdfff", text: "Null Rows" },
];

export const exportItems = [
  {
    text: "Download",
  },
  {
    text: "Export to DataLake",
  },
];

export const validStatuses = ["Pending", "In Progress", "Failed"];

export const fileExtensions = [".pdf"];

export const getDocumentProcessingData = (fileType: any, data: any, state: any) => {
  if (state === fileType?.[0]) {
    return data?.document_data;
  } else if (state === fileType?.[1]) {
    return data?.extracted_data;
  } else if (state === fileType?.[2]) {
    return data?.export_data;
  } else {
    return null;
  }
};

export const getNameByValue = (fileType: any, value: any) => {
  const item = fileType?.find((item: any) => item?.value === value);
  return item ? item?.name : "Select File";
};