import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import { DigitalServicesPage } from "./helper-component/digitalServices/DigitalServices";
import InfrastructurePage from "./helper-component/infrastructure/Infrastructuredashboard";
import OperationsPage from "./helper-component/operations/Operation";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import "../../../styles/finops/servicepage.scss";

export const RateCardPage = () => {
  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics title={"Rate Card"} />
      <div className="dx-card details-card right">
        <TabPanel
          focusStateEnabled={false}
          deferRendering={false}
          width={"100%"}
          animationEnabled={true}
          swipeEnabled={true}
        >
          <TabPanelItem title="Digital Services">
            <div className="tab-panel-content">
              <DigitalServicesPage />
            </div>
          </TabPanelItem>
          <TabPanelItem title="Infrastructure">
            <div className="tab-panel-content">
              <InfrastructurePage />
            </div>
          </TabPanelItem>
          <TabPanelItem title="Operations">
            <div className="tab-panel-content">
              <OperationsPage />
            </div>
          </TabPanelItem>
        </TabPanel>
      </div>
    </div>
  );
};

