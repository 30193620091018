import Popup from "devextreme-react/popup";
import PsButton from "../ui-components/button/Button";
import { SaveConfirmModalProps } from "./type";

const SaveConfirmModal = (props: SaveConfirmModalProps) => {
  const { show, filename, onClose, onConfirm } = props;
  const loaderProps = {
    show: false,
    loadertext: "Please Wait...",
  };

  return (
    <Popup
      title="Save Changes?"
      visible={show}
      onHiding={onClose}
      width="30%"
      height="auto"
    >
      <div>
        <p style={{ marginBottom: "20px" }}>
          <b>Do you want to save the changes to "{filename}"?</b>
        </p>
      </div>
      <div style={{ float: "left", marginTop: "10px" }}>
        <PsButton
          onClick={onConfirm}
          text="Confirm"
          type="default"
          loaderProps={loaderProps}
          mode="contained"
          eventName="Data Analyzer save the changes to current file confirm button"
        />
      </div>
      <div style={{ float: "right", marginTop: "10px" }}>
        <PsButton
          onClick={onClose}
          text="Close"
          type="normal"
          mode="contained"
          eventName="Data Analyzer save the changes to current file close button"
        />
      </div>
    </Popup>
  );
};

export default SaveConfirmModal;
