import Form, {
    Item as FormItem,
    GroupItem,
    ColCountByScreen,
  } from "devextreme-react/form";
import { FormTextbox } from '../../../ui-components/form/form-textbox/FormTextbox';
import { getSizeQualifier } from "../../../../utlis/media-query";
import { OperationsData } from "../../../../types/operations-add";
  
export const OperationsNewForm = ({
newOperationsData,
updateField,
}: {
newOperationsData: OperationsData;
updateField: (field: string) => (value: any) => void;
}) => {

return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
    <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
            <FormTextbox
                label="Role"
                placeholder="Role..."
                value={newOperationsData.designation}
                isEditing={false}
                onValueChange={updateField("designation")}
            />
        </FormItem>
        <FormItem>
            <FormTextbox
                label="Role Description"
                placeholder="Role Description..."
                value={newOperationsData.description}
                isEditing={false}
                onValueChange={updateField("description")}
            />
        </FormItem>
        <FormItem>
            <FormTextbox
                label="Rate"
                placeholder="Rate..."
                value={newOperationsData.cost_per_day.startsWith('$') ? newOperationsData.cost_per_day : "$" + newOperationsData.cost_per_day} 
                isEditing={false}
                onValueChange={updateField("cost_per_day")}
            />
        </FormItem>
    </GroupItem>
    </Form>
);
};
