import { useCallback, useMemo, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Panel,
  ReactFlowProvider,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { Modal } from "./FlowPopup";

const initialNodess: any[] = [
  {
    id: "1",
    data: { label: "Step-1", description: "This is step 1", additionalInfo: "Additional Info" },
    sourcePosition: "bottom",
    position: { x: 100, y: 0 },
    type: "textUpdater",
  },
  {
    id: "2",
    data: { label: "Step-2", description: "This is step 2", additionalInfo: "Additional Info" },
    sourcePosition: "bottom",
    targetPosition: "top",
    position: { x: 100, y: 200 },
    type: "textUpdater",
  },
  {
    id: "3",
    data: { label: "Step-3", description: "This is step 3", additionalInfo: "Additional Info" },
    targetPosition: "top",
    position: { x: 100, y: 400 },
    type: "textUpdater",
  },
];

const initialEdgess = [
  { id: "1-2", source: "1", target: "2" },
  { id: "2-3", source: "2", target: "3" },
];

const EdgeTypesFlow = (props: any) => {
  const { newWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData } = props;
  const [nodes, , onNodesChange] = useNodesState(initialNodess);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdgess);
  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );

  const [showModal, setShowModal] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);

  const onModalToggle = useCallback((nodeId?: string) => {
    if (nodeId) {
      setCurrentNodeId(nodeId);
    }
    setShowModal(!showModal);
  }, [showModal]);

  const formProps = useMemo(() => ({
    newWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData
  }), [newWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData]);

  const nodeTypes = useMemo(() => ({
    textUpdater: (props: any) => <TextUpdaterNode {...props} onClick={() => onModalToggle(props?.id)} />
  }), [onModalToggle]);

  const onAddNode = () => {
    const newNodeId = (nodes?.length + 1)?.toString();
    const lastNode = nodes[nodes.length - 1];
    const newNodePositionY = lastNode.position.y + 200;
    const newNode: any = {
      id: newNodeId,
      data: {
        label: `Step-${newNodeId}`,
        description: `This is step ${newNodeId}`,
        additionalInfo: "Additional Info",
      },
      sourcePosition: "bottom",
      targetPosition: "top",
      position: { x: 100, y: newNodePositionY },
      type: "textUpdater",
    };

    const newEdge = {
      id: `${lastNode.id}-${newNodeId}`,
      source: lastNode.id,
      target: newNodeId,
    };
    setEdges((eds) => addEdge(newEdge, eds));
    onNodesChange([{ type: "add", item: newNode }]);
  };

  return (
    <>
      <ReactFlowProvider>
        <div style={{ width: "100%", height: "100vh" }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes}
            minZoom={0.2}
            maxZoom={2}
            fitView
          />
          <Panel>
            <button onClick={onAddNode}>Add</button>
          </Panel>
          <Modal showModal={showModal} onModalToggle={onModalToggle} formData={formProps} nodeId={currentNodeId} steps={`Step-${currentNodeId}`} />
        </div>
      </ReactFlowProvider>
    </>
  );
};

export default EdgeTypesFlow;
