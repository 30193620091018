import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
import PsButton from "../../ui-components/button/Button";
import MyFilesView from "../../../assets/icons/icon-eye.png";
import { allowedPageSizes } from "../../../config/client/commonConfig";
import { cellStartTime } from "../../ui-components/dataGridFormat/dataGridFormat";
import PsDataGrid from "../../ui-components/dataGrid/DataGrid";
import { useMemo } from "react";
import DataSource from "devextreme/data/data_source";
import { customDateRange } from "../../../config/constants";
import { fetchClientData, fetchTaskData } from "../../../service/app/api-service";
import { usePage } from "../../../contexts/pageContext";

export const GridComponent = (props: any) => {
  const { onViewScrapingButtonClick, id, location, projectShortName, height, handleColumnIndexChange } =
    props;
    
    const {projectId, taskId}= id || {};

    const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    filename : filenameColumn,
    createdDate : createdDateColumn,
    fileSize : fileSizeColumn,
  } = filterValue || {};
    
    const additionalParams = useMemo(() => {
      const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
      const startTime = formattedStartTime;
      const endTime = formattedEndTime;
      return  {
        ...(taskId
          ? { taskId, location}
          : { projectId, location, projectShortName }),
        timePeriod,
        ...(timePeriod === customDateRange && { startTime, endTime }),
      };
    }, [projectId, taskId, filterValue]);
  
    const apiProps = useMemo(
      () => ({
        apiFunction: taskId ? fetchTaskData : fetchClientData,
        additionalParams: additionalParams,
      }),
      [additionalParams]
    );

  const renderAction = (cell: any) => {
    return (
      <PsButton
        hint="MyFiles"
        icon={MyFilesView}
        mode="text"
        onClick={(e) => onViewScrapingButtonClick(e, cell)}
        eventName="Data lake my files click event"
      />
    );
  };

  const columns = [
    {
      caption:"File Name",
      dataField:"filename",
      dataType:"string",
      filterValues:filenameColumn,
    },
    {
      caption:"Uploaded on",
      dataField:"createdDate",
      dataType:"string",
      cellRender:cellStartTime,
      filterValues:createdDateColumn,
    },
    {
      caption:"File Size",
       dataField:"fileSize", 
       dataType:"string",
       filterValues:fileSizeColumn,
    },
    {
      caption:"",
      type:"buttons",
      width:180,
      cellRender:renderAction
    },
  ]

  const pageProps = useMemo(
    () => ({
    nodataText: "No Results to display at this time",
    heightClass: "height-small",
    id:"datalakegrid",
  }), []);
 
  const gridProps =useMemo( 
    () => ({
    dataSource: DataSource,
    columns,
    allowedPageSizes,
    searchPanelVisible: false,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    handleColumnIndexChange,
  }), [columns, handleColumnIndexChange ]);


  return (
    <>
       <PsDataGrid
       pageProps={pageProps}
       gridProps={gridProps}
       apiProps={apiProps}
     />
     </>
  );
};
