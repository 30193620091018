import Masonry from "masonry-layout";
import { useEffect, useRef } from "react";

const MasonryLayout = ({ id, className, itemName, children, hasCard }: any) => {
  const masonryRef = useRef(null);

  useEffect(() => {
    if (hasCard) {
      const elem = masonryRef.current;
      if (!elem) return;

      const msnry: any = new Masonry(elem, {
        itemSelector: `.${itemName}`,
        columnWidth: `.${itemName}`,
        gutter: 0,
        fitWidth: true,
      });

      const handleResize = () => {
        msnry.layout();
      };

      window.addEventListener("resize", handleResize);

      // Initial layout
      msnry.layout();

      return () => {
        window.removeEventListener("resize", handleResize);
        msnry.destroy(); // Clean up Masonry instance
      };
    }
  }, [itemName, hasCard, children]);

  return (
    <div id={id} ref={masonryRef} className={className}>
      {children}
    </div>
  );
};

export default MasonryLayout;
