import { useEffect } from 'react';
import { useDocumentProcessingStateProvider } from '../../../../../../hooks/datastudio/useDocumentProcessing';
import DataGrid, {
  Column,
  HeaderFilter,
  Editing,
  Paging
} from "devextreme-react/data-grid";
import PsSkeleton from '../../../../../../components/ui-components/skeleton/PsSkeleton';
import { getRowStyle } from '../../../../../../utlis/helper';

export const ParagraphView = (props: any) => {

  const { content, isLoading, selectedConfidence } = props;

  const {
    state,
    setDocumentProcessingStateData,
  } = useDocumentProcessingStateProvider();


  useEffect(() => {
    setDocumentProcessingStateData([content]);
  }, [content]);

  const handleEditing = (changes: any) => {
    if (!changes || changes?.length === 0) {
      return;
    }
    const updatedData = state?.documentProcessingStateData?.map((row: any) => {
      const change = changes?.find((c: any) => c?.key === row?.key);
      if (change) {
        return { ...row, ...change?.data };
      }
      return row;
    });
    setDocumentProcessingStateData(updatedData);
  };

  if (!content) {
    return <PsSkeleton count={1} height={300} width={300} />;
  }

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      const confidenceValue = e?.data["confidence"];
      if (confidenceValue !== "" && confidenceValue !== undefined && confidenceValue !== null) {
        const rowStyle = getRowStyle(confidenceValue, selectedConfidence);
        Object?.assign(e?.rowElement?.style, rowStyle);
      }
    }
  };

  const renderConfidenceCell = (cellData: any) => {
    return (
      <div style={{ fontSize: "12px", color: "gray" }}>
        <div>
          confidence: {cellData?.value}
        </div>
      </div>
    );
  };

  return (
    <DataGrid
      className="grid theme-dependent"
      dataSource={state?.documentProcessingStateData}
      onRowUpdating={(e: any) => handleEditing(e?.changes)}
      onRowPrepared={onRowPrepared}
      noDataText={
        isLoading ? "Loading..." : "No processed data to display at this time"
      }
      width={"100%"}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnResizingMode="widget"
      wordWrapEnabled={true}
      showColumnHeaders={false}
    >
      <Editing
        mode="cell"
        allowUpdating={false}
        allowDeleting={false}
        allowAdding={false}
      />
      <HeaderFilter visible={true} />
      <Paging enabled={false} />
      <Column
        dataField="column1"
      />
      <Column
        dataField="confidence"
        width={150}
        cellRender={renderConfidenceCell}
        allowEditing={false}
      />
    </DataGrid>
  );
};
