import Popup from "devextreme-react/popup";
import PsButton from "../../../../../components/ui-components/button/Button";
import { useCreateCloneTask, useFetchUrls } from "../../../../../hooks/task/useTask";
import { useCallback, useEffect } from "react";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";

const TaskClonePopup = (props: any) => {
    const { clonePopupVisible, onClonePopupClose, selectedTask } = props;
    const { row } = selectedTask || {};
    const { data } = row || {};
    const { taskId, taskName, clientId } = data || {};
    const { data: urlsData } = useFetchUrls(clientId) as any;

    const { mutate, isLoading } = useCreateCloneTask(onClonePopupClose, taskId);

    useEffect(() => {
        if (isLoading) {
            showNotification({
                message: 'Cloning Task...',
                type: "success",
            });
        }
    }, [isLoading]);

    const handleConfirmClick = useCallback(() => {
        const urlListData = urlsData?.filter((item: any) => data?.urls?.includes(item.url_id));
        const newData = {
            ...data,
            urlList: urlListData
        };
        mutate({ taskId, payload: newData });
    }, [urlsData, mutate, data, taskId]);

    const loaderProps = {
        show: isLoading,
        loadertext: "Cloning Task...",
    };

    return (
        <Popup
            title="Clone Task Confirmation"
            visible={clonePopupVisible}
            onHiding={onClonePopupClose}
            width="30%"
            height='auto'
        >
                <div>
                    <p>Are you sure you want to clone the <b>{taskName}</b> Task ?</p>
                </div>
                {urlsData?.length > 0 ? (
                    <PsButton onClick={handleConfirmClick} text="Confirm" type="normal" mode='contained' loaderProps={loaderProps} />
                ) : "Please Wait...Loading Data"}
                <div style={{ float: 'right' }}>
                    <PsButton onClick={onClonePopupClose} text="Cancel" type="normal" mode='contained' />
                </div>
        </Popup>
    );
};

export default TaskClonePopup;
