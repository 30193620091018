// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-card {
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  margin-top: 10px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.custom-card .card-header {
  padding: 10px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
}
.custom-card .card-title {
  margin: 0;
  font-size: 18px;
}
.custom-card .card-body {
  padding: 10px;
}

.project-details-card,
.ytd-mtd-costs-card,
.expenses-chart-card {
  flex: 1 1;
  margin-right: 10px;
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/card.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;EACA,gBAAA;EACA,sBAAA;EACA,uCAAA;AACJ;AACI;EACE,aAAA;EACA,yBAAA;EACA,6BAAA;AACN;AAEI;EACE,SAAA;EACA,eAAA;AAAN;AAGI;EACE,aAAA;AADN;;AAOE;;;EAGE,SAAA;EACA,kBAAA;EACA,iBAAA;AAJJ","sourcesContent":[".custom-card {\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    margin-bottom: 10px;\n    margin-top: 10px;\n    overflow: hidden;\n    background-color: #fff;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  \n    .card-header {\n      padding: 10px;\n      background-color: #f0f0f0;\n      border-bottom: 1px solid #ddd;\n    }\n  \n    .card-title {\n      margin: 0;\n      font-size: 18px;\n    }\n  \n    .card-body {\n      padding: 10px;\n    }\n  }\n  \n \n  \n  .project-details-card,\n  .ytd-mtd-costs-card,\n  .expenses-chart-card {\n    flex: 1; \n    margin-right: 10px; \n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
