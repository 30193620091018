import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  createProjectApi,
  updateProjectApi,
  fetchTechnicalResouceDetailsApi,
  fetchOperationsResouceDetailsApi,
  fetchSpecificProjectEditDetailsApi,
  fetchProjectTypeApi,
  fetchRecentProjectApi,
  fetchManagementApi,
  fetchProjectApi,
  fetchManageApi,
  fetchProjectData,
  fetchYTDMTDCostApi,
  fetchSepecificProjectApi,
  fetchopexCardDataApi,
} from "../../service/client/api-service";
import { useCallback, useEffect, useReducer, useState } from "react";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { formatDate } from "devextreme/localization";
import { ProjectState } from "../../pages/client/projects/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { useHelperHooks } from "../helper/utils";
import { replaceQueryUrl } from "../../utlis/helper";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";

const initialState: ProjectState = {
  showProjectModal: false,
  popupVisible: false,
  editPopupVisible: false,
  selectedProject: null,
  selectedProjectType: "",
  projetView: "gridView",
  pageCount: 1,
  pageSize: 25,
  pageIndex: 0,
  sortOrder: "ASC",
  sortColumn: "",
};

const reducer = (state: ProjectState, action: any) => {
  switch (action.type) {
    case "SHOW_PROJECT_MODAL":
      return {
        ...state,
        showProjectModal: action.payload,
      };
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload,
      };
    case "SELECTED_PROJECT":
      return {
        ...state,
        selectedProject: action.payload,
      };
    case "SELECTED_PROJECT_TYPE":
      return {
        ...state,
        selectedProjectType: action.payload,
      };
    case "PROJETVIEW":
      return {
        ...state,
        projetView: action.payload,
      };
    case "PAGE_COUNT":
      return {
        ...state,
        pageCount: action.payload,
      };
    case "PAGE_INDEX":
      return {
        ...state,
        pageIndex: action.payload,
      };
    case "PAGE_SIZE":
      return {
        ...state,
        pageSize: action.payload,
      };
    case "SORTING_ORDER":
      return {
        ...state,
        sortOrder: action.payload,
      };
      case "SORTING_COLUMN":
      return {
        ...state,
        sortColumn: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useProjects = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const currentView = useHelperHooks().getQueryParams("view");
  useEffect(() => {
    if (currentView) {
      dispatch({ type: "PROJETVIEW", payload: currentView });
    }
  }, [currentView]);

  const onToggleProjectClick = useCallback(
    (e?: any) => {
      const { name } = e || {};
      dispatch({ type: "SELECTED_PROJECT_TYPE", payload: name });
      dispatch({
        type: "SHOW_PROJECT_MODAL",
        payload: !state.showProjectModal,
      });
    },
    [state.showProjectModal]
  );

  const handlePageIndexChange = useCallback(
    (e: { fullName: string; value: number; component: any }) => {
      if (e.fullName === "paging.pageIndex") {
        dispatch({
          type: "PAGE_INDEX",
          payload: e.value,
        });
      } else if (e.fullName === "paging.pageSize") {
        const pageSize = e.value;
        dispatch({
          type: "PAGE_SIZE",
          payload: pageSize,
        });
      } else if (
        e.fullName.startsWith("columns[") &&
        e.fullName.endsWith("].sortOrder")
      ) {
        const matchResult = e.fullName.match(/\d+/);
        const columnIndex = matchResult ? parseInt(matchResult[0], 10) : 0;
        const sortColumn = e.component.columnOption(columnIndex).dataField;
        const sortOrder = state.sortOrder === "ASC" ? "DESC" : "ASC";
        dispatch({
          type: "PAGE_INDEX",
          payload: e.value,
        });
        dispatch({
          type: "SORTING_ORDER",
          payload: sortOrder,
        });
        dispatch({
          type: "SORTING_COLUMN",
          payload: sortColumn,
        });
      }
    },
    [state.sortOrder, state.pageIndex]
  );

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  );

  const handleSelectProject = (data: any) => {
    dispatch({ type: "SELECTED_PROJECT", payload: data });
  };

  const onEditProjectClick = useCallback((data: any) => {
    handleSelectProject(data);
    dispatch({ type: "SELECTED_PROJECT", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []);

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "SELECTED_PROJECT", payload: "" });
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: false });
    },
    []
  );

  const filterByProjectView = useCallback(
    (e: DropDownButtonTypes.SelectionChangedEvent) => {
      const { item: view }: { item: any } = e;
      dispatch({ type: "PROJETVIEW", payload: view.value });
      replaceQueryUrl("view", view.value);
      const eventName = view.name === "gridView" ? "Grid View" : "project_view";
      GTAButtonEventHandler(eventName, {
        loaction: window.location.pathname,
        value: view.name,
      });
    },
    []
  );

  return {
    state,
    onToggleProjectClick,
    changePopupVisibility,
    onEditProjectClick,
    changeEditPopupVisibility,
    handlePageIndexChange,
    filterByProjectView,
  };
};

export const useFetchTechnicalResouce = () => {
  return useQuery("fetch-tech-resource", fetchTechnicalResouceDetailsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchOperationsResouce = () => {
  return useQuery(
    "fetch-operations-resource",
    fetchOperationsResouceDetailsApi,
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchRecentProjects = () => {
  const recent = "True";
  const count = 5;

  return useQuery(
    ["fetch-recentproject"],
    () => fetchRecentProjectApi(recent, count),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useMangaProject = () => {
  const viewType = "managementview";

  return useQuery(["fetch-manageproject"], () => fetchManagementApi(viewType), {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateProject = (
  toggleProjectModal?: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const [responseData, setResponseData] = useState<any>(null);

  const projectfunc = isEdit ? updateProjectApi : createProjectApi;

  const mutation = useMutation(projectfunc, {
    onSuccess: (data) => {
      toggleProjectModal && toggleProjectModal();
      fnQueryClient.invalidateQueries("fetch-project");
      fnQueryClient.invalidateQueries("fetch-clients-name");
      fnQueryClient.invalidateQueries("fetch-clients");
      setResponseData(data);
      const message = isEdit
        ? "Project Updated Successfully"
        : "Project Added Succefully";
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
  return {
    ...mutation,
    responseData,
  };
};

export const useFetchProjectClientInfo = (
  clientId: any,
  projectId: any,
  selectedDays: any,
  Page: any,
  Count: any,
  sortColumn: any,
  sortOrder: any
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;

  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    Count,
    Page,
    sortColumn,
    sortOrder,
  };

  return useQuery(
    [
      "fetch-project-clientInfo",
      clientId,
      projectId,
      selectedDays,
      Count,
      Page,
      sortColumn,
      sortOrder,
    ],
    () => fetchProjectApi(queryParams),
    {
      keepPreviousData: true,
      select: (data) => {
        return data;
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
};

export const fetchUserMenus = async ({ pageParam = 0 }, queryParams: any) => {
  const params = {
    ...queryParams,
    Page: pageParam + 1,
  };
  console.log(pageParam, "pageparam");
  const updatedData: any = await fetchProjectApi(params);
  return updatedData;
};

export function useFetchAllProject(
  clientId: any,
  projectId: any,
  selectedDays: any,
  Count: any,
  sortOrder: any,
  sortColumn : any,
) {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const queryParams = {
    clientId,
    projectId,
    startTime,
    endTime,
    timePeriod,
    Count,
    sortOrder,
    sortColumn
  };
  return useInfiniteQuery(
    ["fetch-project-records", queryParams],
    ({ pageParam = 0 }) => fetchUserMenus({ pageParam }, queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!timePeriod,
      getNextPageParam: (_, pages) => {
        const { totalPages } = pages?.[pages?.length - 1]?.pagination || {};
        if (pages?.length < totalPages) {
          return pages?.length;
        }
        return null;
      },
      select(data: any) {
        const list = data?.pages || [];
        const mergedList = list.reduce(
          (result: string | any[], current: { data: string }) =>
            result.concat(current.data),
          []
        );
        const updatedData: string | any = {
          list: mergedList,
          totalcount: list?.[0]?.pagination?.totalRecords,
        };
        return updatedData;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchSpecificProjectDetails = (projectId: string) => {
  return useQuery(
    ["fetch-project-byId", projectId],
    () => fetchSpecificProjectEditDetailsApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchProjectType = (func_type: any) => {
  return useQuery(
    ["fetch-project-type", func_type],
    () => fetchProjectTypeApi(func_type),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useManageInfo = (projectId: any, viewtype: any) => {
  return useQuery(
    ["fetch-manageInfo", projectId, viewtype],
    () => fetchManageApi(projectId, viewtype),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchClientDocument(
  activitytype: string,
  process: string,
  file: string
) {
  const queryParams = {
    activitytype: activitytype,
    process: process,
    filename: file,
  };
  return useQuery(
    ["fetch-client-document", queryParams],
    () => fetchProjectData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!activitytype && !!process && !!file,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchYTDMTDCost(projectId: string) {
  const frequency = "YTD,MTD";
  const queryParams = {
    projectId: projectId,
    frequency: frequency,
  };
  return useQuery(
    ["fetch-ytdmtd-cost", queryParams],
    () => fetchYTDMTDCostApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchProjectInfo = (params: any, activityType: string) => {
  const { projectId, projectName, clientName } = params;
  return useQuery(
    ["fetch-projectInfo", params, activityType],
    () =>
      fetchSepecificProjectApi(
        projectId,
        activityType,
        projectName,
        clientName
      ),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!activityType && !!projectName && !!clientName,
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchopexCard = (clientId: any) => {
  return useQuery(
    ["fetch-opex-card", clientId],
    () => fetchopexCardDataApi(clientId),
    {
      refetchOnWindowFocus: false,

      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
