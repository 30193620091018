import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import MyFilesView from "../../../../../assets/icons/icon-eye.png";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import { useDocumentCatalog, useFetchHierarchyInfo } from "../../../../../hooks/datastudio/useDocumentCatalog";
import { useCallback, useMemo } from "react";
import { useFetchSingleFiles } from "../../../../../hooks/digital-service/useFileManager";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";
import DocumentCatlogLineage from "../../../../../lineageTracing/document-catlog";
import LineageViewer from "../../../../../components/ui-components/lineageView/LineageView";
import WorkflowIcon from "../../../../../assets/images/icons/icon-processing-2.png";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import DataSource from "devextreme/data/data_source";
import { fetchHierarchyInfoApi } from "../../../../../service/datastudio/api-service";
import React from "react";
import { allowedPageSizes } from "../../../../../config/datastudio/commonConfig";

const MasterDetailView = React.memo((props: DataGridTypes.MasterDetailTemplateData) => {
  const status = props.data?.data?.executionStatus;
  const dataCatalogId = props.data?.data?.DocumentId;
  const filename = props.data?.data?.fileName;
  const processType = "Document Catalog";

  const {
    state: {
      openHirarchyPopup,
      openLineage,
      selectedData,
    },
    setOpenHirarchyPopup,
    setOpenLineage,
    setSelectedData,
  } = useDocumentCatalog();

  const { data: hierarchyData, isLoading } =
    useFetchHierarchyInfo(status, processType, dataCatalogId, filename);
  const detaildataView = hierarchyData;

  const closePopupException = () => {
    setOpenHirarchyPopup(false);
  };

  const onHanldingLineage = useCallback(() => {
    setOpenLineage(!openLineage);
  }, [openLineage, setOpenLineage]);

  const { data: fileViewer, isLoading: fileViewerLoading } =
    useFetchSingleFiles(selectedData);

  const handleViewFile = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      name: data?.fileName,
      fileExtension: data?.assetType,
      projectId: data?.projectId,
      type: data?.type,
      userId: data?.userId,
      ...data,
    };
    setSelectedData(params);
    setOpenHirarchyPopup(true);
  }, [setOpenHirarchyPopup, setSelectedData]);

  const onHandleLineageData = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  }, [onHanldingLineage, setSelectedData]);

  const title = { file: selectedData?.name };

  const renderViewFile = useCallback((cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        width={28}
        hint="View File"
        height={28}
        onClick={(e: any) => handleViewFile(e, cell)}
      />
    );
  }, [handleViewFile]);

  const renderLineageIcon = useCallback((cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  }, [onHandleLineageData]);


  const columns = useMemo(
    () => [
      {
        caption: "File Name", dataField: "fileName", dataType: "string"
      },
      {
        caption: "File Size", dataField: "fileSize", dataType: "string"
      },
      {
        caption: "File Type", dataField: "fileType", dataType: "string"
      },
      {
        caption: "Created Date",
        dataField: "createdDate",
        dataType: "datetime",
        cellRender: cellStartTime,
      },
      {
        caption: "Modified Date",
        dataField: "lastUpdated",
        dataType: "datetime",
        cellRender: cellUpdatedTime,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderViewFile,
      },
      {
        caption: "",
        type: "buttons",
        cellRender: renderLineageIcon,
      },
    ],
    [renderViewFile, renderLineageIcon]
  );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "dataCatalogId",
      allowedPageSizes,
      searchPanelVisible: false,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
    }),
    [columns]
  );

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchHierarchyInfoApi,
      additionalParams: {
        status: status,
        processType: processType,
        dataCatalogId: dataCatalogId,
      },
    }),
    [dataCatalogId, status]
  );

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    isLineageLoading: isLoading,
    component: (
      <DocumentCatlogLineage
        data={detaildataView}
        assetType={selectedData?.assetType}
      />
    ),
  };

  const pageProps = {
    nodataText: "No projects to display at this time",
    heightClass: "height-small",
    id: `documenthierachygrid-${dataCatalogId}`,
  };

  return (
    <>
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <FileViewer
        visible={openHirarchyPopup}
        onHiding={closePopupException}
        data={fileViewer}
        title={title}
        isLoading={fileViewerLoading}
        extensionType={selectedData?.fileExtension}
        params={selectedData}
        showExportToDataLake={true}
      />
      <LineageViewer {...lineageDataProps} />
    </>
  );
});

export default MasterDetailView;
