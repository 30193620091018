import { useCallback, useState } from "react";
import Pagination from "../../../../../../../components/ui-components/pagination/Pagination";
import { ParagraphView } from "./helper-component/paragraphView";
import { TableView } from "./helper-component/tableView";
import PsSkeleton from "../../../../../../../components/ui-components/skeleton/PsSkeleton";

export const DocumentDataViewer = (props: any) => {
  const {
    pageData,
    selectedConfidence,
    telemetryData,
    onPageChange,
    rowUpdated,
    jumpToPage,
    isFetching
  } = props;

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
    jumpToPage(page - 1);
  };

  const renderView = useCallback(() => {
    if (isFetching) {
      return <PsSkeleton count={1} height={"75vh"} width={"100vh"} />;
    }
    if (pageData?.[`page-${currentPage}`]?.length === 0) {
      return <div className="no-data">No Data</div>;
    }
    return pageData?.[`page-${currentPage}`]?.map((data: any) => {
      const { type, content } = data || {};
      if (type === "paragraph") {
        return (
          <ParagraphView
            dataSource={content}
            selectedConfidence={selectedConfidence}
            rowUpdated={rowUpdated}
          />
        );
      } else if (type === "table") {
        return (
          <TableView
            dataSource={content}
            selectedConfidence={selectedConfidence}
            rowUpdated={rowUpdated}
          />
        );
      }
      else 
        return null;
    });
  }, [pageData, currentPage, selectedConfidence, rowUpdated, isFetching]);

  return (
    <div>
      <div className="data-view">{renderView()}</div>
      <div>
        <Pagination
          totalPage={pageData?.totalpage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          TelemetryDescription={telemetryData}
          TelemetryHidden={false}
        />
      </div>
    </div>
  );
};
