import { useQuery } from "react-query";
import { fetchClientAlertExceptionApi, fetchSpecificProjectEditDetailsApi,fetcthSpecificExceptionApi} from "../../service/activity-monitor/api-service";
import { formatDate } from "devextreme/localization";

export const useFetchSpecificProjectDetails = (projectId: string) => {
  return useQuery(
    ["fetch-project-byId", projectId],
    () => fetchSpecificProjectEditDetailsApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};




export const useFetchAlerException = (clientId: any, selectedDays: any,
  Count: any,
  sortOrder: any,
  sortColumn: any,
  pageIndex: any,
  isNextPage: any,) => {
  const startTime = selectedDays?.startTime ? formatDate(selectedDays.startTime, "yyyy-MM-dd") : null;
  const endTime = selectedDays?.endTime ? formatDate(selectedDays.endTime, "yyyy-MM-dd") : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length - 1] + 1;


  const queryParams = {
    clientId,
    startTime,
    endTime,
    timePeriod,
    Count,
    sortOrder,
    sortColumn,
    Page: page,
  };

  return useQuery(
    ["fetch-alert-Exception", queryParams],
    async () => {
      const response: any = await fetchClientAlertExceptionApi(queryParams);
      if (response) {
        return response;
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const list = data?.data || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return { list, totalcount };
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};


export const useFetchSpecificException = (executionId: any) => {
  return useQuery(
    ["fetch-project-byId", executionId],
    () => fetcthSpecificExceptionApi(executionId),
    {
      refetchOnWindowFocus: false,
      enabled: !!executionId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );


}

