import { useCallback, useEffect, useState } from "react";
import ClientNewForm from "./client-form/clientnewform";
import { Client } from "../types";
import { newContact } from "../../../../config/constants";
import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import { useCreateClient } from "../../../../hooks/client/useClientCatlog";

const NewClient = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;

  const [newContactData, setNewContactData] = useState<Client>({
    ...newContact,
  });

  const updateField = (field: string) => (value: any) => {
    if (field === "state") {
      setNewContactData({
        ...newContactData,
        ...{ [field]: { stateShort: value.toString() } },
      });
    } else {
      setNewContactData({ ...newContactData, ...{ [field]: value } });
    }
  };

  const { mutate, isLoading } = useCreateClient(
    changePopupVisibility
  );

  useEffect(() => {
    if (!popupVisible) {
      setNewContactData({
        ...newContact,
      });
    }
  }, [popupVisible]);

  const handleFormSubmit = useCallback(() => {
    mutate(newContactData);
  }, [mutate, newContactData]);

  return (
    <FormPopup
      title="New Client"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <ClientNewForm
        newContactData={newContactData}
        updateField={updateField}
      />
    </FormPopup>
  );
};

export default NewClient;
