import { useQuery } from 'react-query';
import { fetchDataCatalogGridApi }  from '../../service/datastudio/api-service';


export const useFetchDataCatalogGridApi = ( selectedDays: any) => {
  const queryParams = {...selectedDays};

  return useQuery(
    ["fetch-datacatalog-data", selectedDays],
    () => fetchDataCatalogGridApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!selectedDays,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.error(error);
      }
    }
  );
};
