import React, { useState } from "react";
import Accordion from "devextreme-react/accordion";
import { AccordionProps } from "./types";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const PsAccordion = (props: AccordionProps) => {
  const {
    dataSource,
    Component,
    CustomTitle,
    eventName = "accordion click",
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const handleItemTitleClick = (e: any) => {
    const itemIndex = e.component.option("selectedIndex");
    setSelectedIndex(itemIndex === e.itemIndex ? -1 : e.itemIndex);
    GTAButtonEventHandler(eventName);
  };

  return (
    <div>
      <Accordion
        dataSource={dataSource}
        itemTitleRender={CustomTitle}
        itemRender={Component}
        collapsible={true}
        multiple={false}
        id="accordion-container"
        deferRendering={true}
        selectedIndex={selectedIndex}
        onItemTitleClick={handleItemTitleClick}
      />
    </div>
  );
};

export default PsAccordion;
