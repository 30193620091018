  import Form, {
    Item as FormItem,
    GroupItem,
    ColCountByScreen,
  } from "devextreme-react/form";
  import {SelectBox } from "devextreme-react";
  import { useEffect, useState } from "react";
  import { getSizeQualifier } from "../../../../utlis/media-query";
  import { useFetchFileUploadProject } from "../../../../hooks/datastudio/useDocumentCatalog";
  import { Validator, RequiredRule } from "devextreme-react/validator";
  import { useApp } from "../../../../contexts/app";
  import FileUploaderComponent from "../../../../components/ui-components/fileUploader/fileUpload";
  import { FileData } from "../../../../components/ui-components/fileUploader/type";
  import { useQueryClient } from "react-query";

  const AddFiles = (props: any) => {
    const {
      show,
      onClose,
      client_id,
      project_id,
      fetchDropDown,
      getClientId,
      getProjectId,
      clientData,
    } = props;

    const { user } = useApp();
    const userName = user?.name || "";
    const userId = user?.userId || "";
    const queryClient = useQueryClient();

    const [projectData, setProjectData] = useState<any[]>([]);
    const { mutate, isLoading } = useFetchFileUploadProject(onClose);

    useEffect(() => {
      setProjectData(fetchDropDown?.projects[client_id] || []);
    }, [fetchDropDown, client_id]);

    const handleFileUpload = (files: FileData[]) => {
      files.forEach((file) => {
        const payload = {
          projectId: project_id,
          projectFiles: [file],
          uploadedBy: userName,
          userId: userId,
        };
        mutate(payload);
        queryClient.invalidateQueries("fetch-fileupload-project");
      });
    };

    const formFields = (
      <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
        <GroupItem>
          <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
          <FormItem>
            <SelectBox
              items={clientData || []}
              placeholder="Select a client"
              displayExpr={"name"}
              onValueChanged={getClientId}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </FormItem>
          <FormItem>
            <SelectBox
              items={projectData || []}
              placeholder="Select a project"
              displayExpr={"projectName"}
              onValueChanged={getProjectId}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </FormItem>
        </GroupItem>
      </Form>
    );

    return (
      <>
        <FileUploaderComponent
          allowedExtensions={[".json", ".pdf", ".csv", ".xml", ".txt"]}
          onUpload={handleFileUpload}
          isLoading={isLoading}
          resetUploaderKey={0}
          popupAddFilesVisible={show}
          handleFormPopupClose={onClose}
          formFields={formFields}
        />
      </>
    );
  };

  export default AddFiles;
