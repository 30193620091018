import { useApp } from "../../../../../contexts/app";
import Popup from "devextreme-react/popup";
import { useDeleteWorkflow } from "../../../../../hooks/task/useWorkflow";
import PsButton from "../../../../../components/ui-components/button/Button";


const DeleteWorkflow = (props : any) => {
   const {
    onDeletePopupClose,
    selectedWorkflowData,
    deletePopupVisible} = props;

    const {row} = selectedWorkflowData || {};
    const {data} = row || {};
    const {workflowName, manifestId} = data || {};
    const {user} = useApp();
    const userId = user?.id

     const { isLoading, refetch } = useDeleteWorkflow(onDeletePopupClose, manifestId, workflowName, userId);

     const loaderProps = {
      show: isLoading,
      loadertext: "Please Wait...",
     };

  return (
    <Popup
      title="Delete Workflow"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height='auto'
    >
        <div>
          <p>Are you sure you want to delete the <b>{workflowName}</b> Workflow ?</p>
          <p style={{margin: '10px 0'}}>If you delete your Details, you will not be able to recover it.</p>
        </div>
        <div style={{ float: 'left' }}>
        <PsButton onClick={() => refetch()} text="confirm" type="normal" mode='contained' loaderProps={loaderProps} />
        </div>
        <div style={{ float: 'right' }}>
          <PsButton onClick={onDeletePopupClose} text="Close" type="normal" mode='contained' />
        </div>
    </Popup>
  )
}

export default DeleteWorkflow;