// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.treeviewButton {
  margin-left: 10px;
  border: none;
  border-radius: 4px;
}

.popup-pdf-container {
  width: auto;
  height: auto;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup-pdf-container .PdfViewer__container {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}

.projectprofile-data-header {
  font-size: 22px !important;
  font-weight: 400 !important;
  color: black;
}

.progressDiv {
  margin-left: 12px;
  margin-top: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.dataTab {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/dataTab.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,YAAA;EACA,kBAAA;AACF;;AAEA;EACE,WAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACE,2BAAA;EACA,8BAAA;AACJ;;AAIA;EACE,0BAAA;EACA,2BAAA;EACA,YAAA;AADF;;AAIA;EACE,iBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AADF;;AAIA;EACE,aAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;AADF","sourcesContent":[".treeviewButton {\n  margin-left: 10px;\n  border: none;\n  border-radius: 4px;\n}\n\n.popup-pdf-container {\n  width: auto;\n  height: auto;\n  overflow-y: auto;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .PdfViewer__container {\n    margin-top: 30px !important;\n    margin-bottom: 30px !important;\n  }\n\n}\n\n.projectprofile-data-header {\n  font-size: 22px !important;\n  font-weight: 400 !important;\n  color: black;\n}\n\n.progressDiv {\n  margin-left: 12px;\n  margin-top: 20px;\n  align-items: center;\n  margin-bottom: 20px;\n}\n\n.dataTab {\n  display: flex;\n  justify-content: flex-end;\n  margin-top: 10px;\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
