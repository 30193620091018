import { formatDate } from "devextreme/localization";
import { get, post, update } from "../../service/api/api";
import { makeQueryParam, validateEndPoint } from "../api/helper/helper";
import { customDateRange } from "../../config/constants";

export const CompleteSave = async (payload: any) => {
  const filename = payload.filename
  const filepath = payload.filepath
  const process = payload.process
  const response = await get(`data_processing/v1/document_process?filename=${filename}&filepath=${filepath}&process=${process}`);
  return response;
}

export const CompleteButton = async (payload: any) => {
  const filename = payload.filename
  const filepath = payload.filepath
  const process = payload.process
  const activityType = payload.activityType
  const clientId = payload.clientId
  const response = await get(`data_processing/v1/dataprocessing_retrival?filename=${filename}&filepath=${filepath}&process=${process}&activityType=${activityType}&clientId=${clientId}`);
  return response;
}

export const fetchRetrivalDataApi = async (showingDuplicates: any) => {
  const response = await get(`data_processing/v1/Datastudio?process=${showingDuplicates}`);
  return response;
};

export const fetchFirstDataApi = async (numberFirstValue: any) => {
  const records = `first ${numberFirstValue} rows`
  const response = await get(`data_processing/v1/Datastudio?records=${records}`);
  return response;
};

export const fetchLastDataApi = async (numberLastValue: any) => {
  const records = `last ${numberLastValue} rows`
  const response = await get(`data_processing/v1/Datastudio?records=${records}`);
  return response;
};

export const fetchPdfApi = async () => {
  const response = await get("data_processing/v1/pdfextraction");
  return response;
}

export const fetchClientsApi = async () => {
  const response = await get("client/v1/client");
  return response;
};
export const fetchClientsInfoDataApi = async () => {
  const response = await get("core/v1/clientinfo/all");
  return response;
};

export const fetchFunctionDropDownDataApi = async (process: any) => {
  const response = await get(`core/v1/featuredropdown/featureInfo?process=${process}`);
  return response;
};

export const fetchCatalogApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentCatalog/catalogInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchReportInfoApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams); 
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentCatalog/catalogInfo${query}`);
  const response = await get(validUrl);
  return response;
};



export const fetchTaskDescriptionApi = async () => {
  const response = await get("task/v1/task");
  return response;
};

export const fetchPdfAnalyzeDataApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`data_processing/v1/document_process${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchFilterDataApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`data_processing/v1/Datastudio${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDocumentSummaryApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentCatalog/summaryInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchActiveDocumentApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentCatalog/activeInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDataProcessDropDownApi = async (id: string) => {
  const response = await get(`core/v1/dropdown/client?clientId=${id}`);
  return response;
};

export const fetchDataProcessingApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`data_processing/v1/dataprocessing_retrival${query}`);
  const response = await get(validUrl);
  return response;
};

export const createDocumentProcessingApi = async (payload: any) => {
  const response = await post("data_processing/v1/docTableEdit", payload);
  return response;
};

export const editDataProcessingApi = async (payload: any) => {
  const response = await post("dataprocessing-service/v1/dataAnalyzerActions/actions", payload);
  return response;
}

export const createDocumentAnalyze = async (payload: any) => {
  const response = await post("documentprocessing/v1/DocumentAnalyzer/analyzecreate", payload);
  return response;
}

export const PdfEditDocumentAnalyze = async (payload: any) => {
  const response = await post("documentprocessing/v1/DocumentAnalyzer/analyzeeditorcreate", payload);
  return response;
}

export const fetchAnalyzeDocumentApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentAnalyzer/startanalyzer${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDocumentAnalyzerPDFApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentAnalyzer/pdfinfo${query}`);
  const response = await get(validUrl);
  return response;
}

export const fetchDocumentAnalyzerProcessApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentAnalyzer/analyzerinfo${query}`);
  const response = await get(validUrl);
  return response;
}

export const fetchDocumentAnalyzeTelemetry = async (dataCatalogId: any) => {
  const response = await get(`logs/v1/telemetry/documentanalyzerInfo?analyzeId=${dataCatalogId}`);
  return response;
}

export const fetchRecenAnalyseDataApi = async (queryParams: any) => {
  const {startTime, endTime, timePeriod, processType} = queryParams
  if (timePeriod === customDateRange && (!startTime || !endTime)) { return }
  const queryParam = {
          processType,
          startTime : formatDate(startTime, "yyyy-MM-dd"),
          endTime : formatDate(endTime, "yyyy-MM-dd"),
          timePeriod
       }
  const query = makeQueryParam(queryParam);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentAnalyzer/latestinfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const updateDocumentAnalyzerApi = async ({ queryParams, payload }: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = `documentprocessing/v1/DocumentAnalyzer/update${query}`;
  const response = await update(validUrl, payload);
  return response;
};

export const getDataProcessingDataApi = async (filename: string, userId: any, processType: string) => {
  const response = await get(`dataprocessing-service/v1/dataAnalyzer/user/uploadedFileData?filename=${filename}&userId=${userId}&processType=${processType}`);
  return response;
};

export const fetchDataProcessAddFilesApi = async (payload: any) => {
  const response = await post("dataprocessing-service/v1/dataAnalyzer/user/uploadFile", payload);
  return response;
}

export const fetchDataProcessingMyFilesApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`dataprocessing-service/v1/dataAnalyzer/user/myFiles${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDataAnalyzerMyFilesInfoApi = async (dataCatalogId: any) => {
  const response = await get(`dataprocessing-service/v1/dataAnalyzer/user/editedFiles?dataCatalogId=${dataCatalogId}`);
  return response;
};

export const fetchDataProcessingActions = async (filename: string, filepath: string, process: string) => {
  const validUrl = validateEndPoint(`dataprocessingFeatures/v1/dataprocessing_features?filename=${filename}&filepath=${filepath}&process=${process}`);
  const response = await get(validUrl);
  return response;
};


export const fetchDataCatalogGridApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`dataprocessing-service/v1/data-catalog/info/data-catalog/all${query}`);
  const response = await get(validUrl);
  return response;
};


export const fetchDocumentProcessApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentProcessing/processinfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDocumentProcessSaveApi = async ({ queryParams, payload }: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentProcessing/processupdate${query}`);
  const response = await update(validUrl, payload);
  return response;
};

export const fetchDocumentProcessFinalizeApi = async ({ queryParams, payload }: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentProcessing/processupdate${query}`);
  const response = await update(validUrl, payload);
  return response;
};

export const fetchDocumentProcessRejectApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`documentprocessing/v1/DocumentProcessing/processupdate${query}`);
  const response = await update(validUrl, {});
  return response;
};

export const fetchSpecificProjectApi = async (id: string) => {
  const response = await get(`projects/v1/projects?clientId=${id}`);
  return response;
};


export const fileUploadProjectApi = async (payload: any) => {
  const response = await post("client/v1/upload", payload);
  return response;
};  // used

export const fetchDocumentInfoApi = async (dataCatalogId: string, docStage: string) => {
  const response = await get(`documentprocessing/v1/DocumentAnalyzer/editedInfo?dataCatalogId=${dataCatalogId}&docStage=${docStage}`);
  return response;
};

export const fetchDocumentMetricsInfoApi = async (dataCatalogId: string) => {
  const response = await get(`logs/v1/logmetrics/executionInfo?dataCatalogId=${dataCatalogId}`);
  return response;
};

export const fetchtemplatedropdown = async () => {
  const response = await get("core/v1/templatedropdown/info");
  return response;
};

export const fetchHierarchyInfoApi = async (status: string, processType: string, dataCatalogId: string) => {
  const response = await get(`documentprocessing/v1/DocumentDetails/documentInfo?status=${status}&processType=${processType}&dataCatalogId=${dataCatalogId}`);
  return response;
};

export const fetchMapFilesApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`datalake/v1/file-manager/retrieve/viewfile${query}`);
  const response = await get(validUrl);
  return response;
};