// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activitycard-wrapper {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px 24px;
}
.activitycard-wrapper h6 {
  font-size: 16px !important;
}
.activitycard-wrapper .content-list {
  display: flex;
  align-items: center;
  padding: 10px 15px 0 15px;
  gap: 10px;
}
.activitycard-wrapper .content-list img {
  width: 10%;
  object-fit: contain;
}
.activitycard-wrapper .content-list .link_btn {
  font-size: 14px !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/ActivityTilecard.scss"],"names":[],"mappings":"AAAA;EACE,yCAAA;EACA,eAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;AACF;AAAE;EACE,0BAAA;AAEJ;AAAE;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;AAEJ;AADI;EACE,UAAA;EACA,mBAAA;AAGN;AADI;EACE,0BAAA;AAGN","sourcesContent":[".activitycard-wrapper {\n  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);\n  cursor: pointer;\n  background-color: #fff;\n  border-radius: 10px;\n  padding: 10px 24px;\n  h6 {\n    font-size: 16px !important;\n  }\n  .content-list {\n    display: flex;\n    align-items: center;\n    padding: 10px 15px 0 15px;\n    gap: 10px;\n    img {\n      width: 10%;\n      object-fit: contain;\n    }\n    .link_btn {\n      font-size: 14px !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
