import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchOperationsApi,
  createOperationsApi,
  updateOperationsApi,
  deleteOperationsById,
  fetchInfrastructureApi,
  updateInfrastructureApi,
  createInfrastructureApi,
  deleteInfrastructureById,
  deleteDigitalServicesById,
  updateDigitalServicesApi,
  createDigitalServicesApi,
  fetchDigitalServicesApi,
} from "../../service/finops/api-service";
import { useCallback, useReducer } from "react";
import { OperationsState } from "../../pages/finops/rateCard/helper-component/operations/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const initialState: OperationsState = {
  popupVisible: false,
  selectedRow: null,
  deletePopupVisible: false,
  startPopupVisible: false,
  editPopupVisible: false,
};

const reducer = (state: OperationsState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload, // used
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload, //used
      };
    case "DELETE_POPUP_VISIBLE":
      return {
        ...state,
        deletePopupVisible: action.payload, // used
      };
    case "SELECTED_ROW":
      return {
        ...state,
        selectedRow: action.payload, // used
      };
    case "START_POPUP_VISIBLE":
      return {
        ...state,
        startPopupVisible: action.payload, //used
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useRateCard = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddRowClick = useCallback(() => {
    dispatch({ type: "POPUP_VISIBLE", payload: true });
  }, []); // used

  const changePopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "POPUP_VISIBLE", payload: isVisble });
    },
    []
  ); // used

  const handleSelectRow = (data: any) => {
    dispatch({ type: "SELECTED_ROW", payload: data });
  };

  const onDeletePopupOpen = useCallback((data: any) => {
    handleSelectRow(data);
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: true });
  }, []); // used

  const onStartPopupOpen = useCallback((data: any) => {
    handleSelectRow(data);
    dispatch({ type: "START_POPUP_VISIBLE", payload: true });
  }, []); // used

  const onDeletePopupClose = useCallback(() => {
    dispatch({ type: "DELETE_POPUP_VISIBLE", payload: false });
  }, []); //used

  const onStartPopupClose = useCallback(() => {
    dispatch({ type: "START_POPUP_VISIBLE", payload: false });
  }, []);

  const onEditRowClick = useCallback((data: any) => {
    handleSelectRow(data);
    dispatch({ type: "SELECTED_ROW", payload: data });
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: true });
  }, []); // used

  const changeEditPopupVisibility = useCallback(
    (isVisble: boolean | ((prevState: boolean) => boolean)) => {
      dispatch({ type: "SELECTED_ROW", payload: "" });
      dispatch({ type: "EDIT_POPUP_VISIBLE", payload: false });
    },
    []
  ); // used

  const onEditClick = useCallback((data: any) => {
    dispatch({ type: "SELECTED_ROW", payload: data });
  }, []); // used

  return {
    state,
    onAddRowClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onStartPopupClose,
    onEditClick,
    onEditRowClick,
    changeEditPopupVisibility,
  };
};

export const useFetchOperations = () => {
  return useQuery("fetch-operations", fetchOperationsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useCreateOperations = (
  toggleOperationsModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const operationsfunc = isEdit ? updateOperationsApi : createOperationsApi;
  const message = isEdit
    ? "Operations updated successfully"
    : "Operations created successfully";
  return useMutation(operationsfunc, {
    onSuccess: () => {
      toggleOperationsModal();
      fnQueryClient.invalidateQueries("fetch-operations");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useDeleteOperations = (
  onDeleteHandler: () => void,
  id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(["deleteOperations", id], () => deleteOperationsById(id), {
    enabled: !!id && false,
    refetchOnWindowFocus: false,
    onSuccess: () => {
      onDeleteHandler();
      queryClient.invalidateQueries("fetch-operations");
      showNotification({
        message: "Operations deleted successfully",
      });
    },
  });
}; // used

export const useFetchInfrastructure = () => {
  return useQuery("fetch-infrastructure", fetchInfrastructureApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useCreateInfrastructure = (
  toggleInfrastructureModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const infrastructurefunc = isEdit
    ? updateInfrastructureApi
    : createInfrastructureApi;
  const message = isEdit
    ? "Digital Services updated successfully"
    : "Digital Services created successfully";
  return useMutation(infrastructurefunc, {
    onSuccess: () => {
      toggleInfrastructureModal();
      fnQueryClient.invalidateQueries("fetch-infrastructure");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteInfrastructure = (
  onDeleteHandler: () => void,
  id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteInfrastructure", id],
    () => deleteInfrastructureById(id),
    {
      enabled: !!id && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-infrastructure");
        showNotification({
          message: "Digital Services deleted successfully",
        });
      },
    }
  );
};

export const useFetchDigitalServices = () => {
  return useQuery("fetch-digital-services", fetchDigitalServicesApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateDigitalServices = (
  toggleDigitalServicesModal: () => void,
  isEdit?: boolean
) => {
  const fnQueryClient = useQueryClient();
  const digitalServicesfunc = isEdit
    ? updateDigitalServicesApi
    : createDigitalServicesApi;
  const message = isEdit
    ? "Digital Services updated successfully"
    : "Digital Services created successfully";
  return useMutation(digitalServicesfunc, {
    onSuccess: () => {
      toggleDigitalServicesModal();
      fnQueryClient.invalidateQueries("fetch-digital-services");
      showNotification({
        message: message,
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useDeleteDigitalServices = (
  onDeleteHandler: () => void,
  id: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteDigitalServices", id],
    () => deleteDigitalServicesById(id),
    {
      enabled: !!id && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-digital-services");
        showNotification({
          message: "Digital Services deleted successfully",
        });
      },
    }
  );
};
