import React, { useCallback } from "react";
import { CheckBox } from "devextreme-react";
import { CheckBoxTypes } from "devextreme-react/cjs/check-box";
import { PsCheckBoxProps } from "./types";

const PsCheckBox = ({ id, text, value, onChange }: PsCheckBoxProps) => {
  const onValueChanged = useCallback(
    (args: CheckBoxTypes.ValueChangedEvent) => {
      onChange && onChange(args);
    },
    [onChange]
  );
  return <CheckBox id={id} text={text}  onValueChanged={onValueChanged} />;
};

export default PsCheckBox;
