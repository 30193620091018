import { DataGridTypes } from "devextreme-react/data-grid";
import SelectBox from "devextreme-react/select-box";
import { STATUS_LIST } from "../../../config/constants";
import TextBox from "devextreme-react/text-box";
import { ContactStatus } from "../contact-status/ContactStatus";

export const cellNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className="name-template">
    <div>{cell.data.clientName || cell.data.name }</div>
    <div className="position">{cell.data.position}</div>
  </div>
);

export const cellClientNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className="name-template">
    <div>{cell.data.clientName}</div>
    <div className="position">{cell.data.position}</div>
  </div>
);

export const cellNameCombine = (cell: DataGridTypes.ColumnCellTemplateData) => {
  const { firstName, lastName } = cell.data; 
  const fullName = `${firstName} ${lastName}`;

  return (
    <div className="name-template">
      <div>{fullName}</div>
      <div className="position">{cell.data.position}</div>
    </div>
  );
};


export const cellUrlNameRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className="name-template">
    <div>{cell.data.className}</div>
    <div className="position">{cell.data.position}</div>
  </div>
);


export const editCellStatusRender = () => (
  <SelectBox
    className="cell-info"
    dataSource={STATUS_LIST}
    itemRender={ContactStatus}
    fieldRender={fieldRender}
  />
);

export const cellPhoneRender = (cell: DataGridTypes.ColumnCellTemplateData) =>
  String(cell.data.phone).replace(/(\d{3})(\d{3})(\d{4})/, "+1($1)$2-$3");

export const fieldRender = (text: string) => (
  <>
    <ContactStatus text={text} />
    <TextBox readOnly />
  </>
);

export const leadingZero = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const formatDate = (dt: any) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${leadingZero(month)}/${leadingZero(day)}/${year}:  ${leadingZero(hours)}:${leadingZero(minutes)}`;
};

export const formatTime = (dt: any) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();
  return `${leadingZero(month)}/${leadingZero(day)}/${year}`;
};



export const convertToUSD = (value: any) => {
  if (value == null) {
    return '$0.00';
  }
  const stringValue = String(value);
  const numericValue = parseFloat(stringValue.replace('$', ''));
  if (isNaN(numericValue)) {
    throw new Error('Invalid numeric value');
  }
  const formattedValue = numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formattedValue}`;
};


export const cellStartTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.starttime === "" || cell.data.timestamp === "" || cell.data.executionTime === ""||cell.data.startDate === "" ||cell.data.created_at === ""|| cell.data.createdDate === "" || cell.data.startTime === "") {
    return '---';
  }
  return formatDate(cell.data.starttime || cell.data.timestamp || cell.data.executionTime|| cell.data.startDate || cell.data.created_at || cell.data.createdDate || cell.data.startTime);
};

export const cellTimeZone = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.startDate === "" || cell.data.startDate === "") {
    return '---';
  }
  return formatTime(cell.data.startDate || cell.data.startDate);
}


export const CellDollar = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.cost_per_day === "" || cell.data.value === "" || cell.data.totalcost === "" || cell.data.total_monthly_cost === "" || cell.data.per_month_cost === "") {
    return '---';
  }
  return convertToUSD(cell.data.cost_per_day || cell.data.value || cell.data.totalcost || cell.data.total_monthly_cost || cell.data.per_month_cost);
}

export const cellEndTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.endTime === "" || cell.data.endDate === "") {
    return '---';
  }
  return formatDate(cell.data.endTime || cell.data.endDate);
};

export const cellUpdatedTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data?.updated_at === "" || cell.data?.last_updated === "" || cell.data?.updatedDate === "" || cell.data?.lastUpdated === "") {
    return '---';
  }
  return formatDate(cell.data?.updated_at || cell.data?.last_updated || cell.data?.updatedDate || cell.data?.lastUpdated);
};

export const cellLastExecutionTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.latest_execution === "-") {
    return '---';
  }
  return formatDate(cell.data.latest_execution);
};

export const cellTimeStamp = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.timeStamp === "" || cell.data.date === ""|| cell.data.executionStartTime === "") {
    return '---';
  }
  return formatDate(cell.data.timeStamp || cell.data.date|| cell.data.executionStartTime);
};

export const cellComplexityRender = (cell: DataGridTypes.ColumnCellTemplateData) => (
  <div className="name-template">
    <div>{cell.data?.complexity || 'Low'}</div>
    <div className="position">{cell.data.position}</div>
  </div>
);

export const cellCreatedDate = (cell: DataGridTypes.ColumnCellTemplateData) => {
  if(cell.data.createdDate === "") {
    return '---';
  }
  return formatDate(cell.data.createdDate);
};