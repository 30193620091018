import { FormPopup } from "../../../../components/ui-components/form/form-popup/FormPopup";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { ProgressBar, SelectBox } from "devextreme-react";
import FileUploader from "devextreme-react/file-uploader";
import { useEffect, useState } from "react";
import { getSizeQualifier } from "../../../../utlis/media-query";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
import { uploadAccept } from "../../../../config/datastudio/commonConfig";
import { useFetchFileUploadProject } from "../../../../hooks/datastudio/useDocumentCatalog";
import { Validator, RequiredRule, } from 'devextreme-react/validator';
import { useApp } from "../../../../contexts/app";
import { formatFileSize } from "../../../../utlis/helper";

const AddFiles = (props: any) => {
  const {
    show,
    onClose,
    client_id,
    project_id,
    fetchDropDown,
    getClientId,
    getProjectId,
    clientData,
  } = props;

  const { user } = useApp();
  const userName = user?.name || "";

  const [projectFiles, setProjectFiles] = useState<any[]>([]);
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number }>(
    {}
  );
  const [resetUploaderKey, setResetUploaderKey] = useState(0);
  const [projectData, setProjectData] = useState<any[]>([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { mutate, isLoading } = useFetchFileUploadProject(onClose);

  useEffect(() => {
    setProjectData(fetchDropDown?.projects[client_id] || []);
  }, [fetchDropDown, client_id]);

  const handleFilesUpload = (e: any) => {
    const files = e.value;
    const filePromises = files.map((file: any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        const fileSize = formatFileSize(file?.size);
        reader.readAsDataURL(file);
        reader.onload = () =>
          resolve({ name: file.name, content: reader.result, size: fileSize });
        reader.onerror = (error) => reject(error);
      });
    });
    Promise.all(filePromises).then((encodedFiles) => {
      setProjectFiles(encodedFiles);
      const initialFileProgress: { [key: string]: number } = {};
      encodedFiles.forEach((file) => {
        initialFileProgress[file.name] = 0;
      });
      setFileProgress(initialFileProgress);
    });
  };

  const handleSave = () => {
    const payload = {
      projectId: project_id,
      projectFiles: projectFiles,
      uploadedBy: userName,
    };

    if (!projectFiles.length) {
      showNotification({
        message: "Please select files to upload",
      });
      return;
    }
    
    setIsButtonLoading(true);
    const uploadPromises = projectFiles.map((file: any) => {
      return new Promise<void>((resolve) => {
        let progress = 0;
        const interval = setInterval(() => {
          progress += 10;
          setFileProgress((prevProgress) => ({
            ...prevProgress,
            [file.name]: Math.min(progress, 100),
          }));
          if (progress >= 100) {
            clearInterval(interval);
            resolve();
          }
        }, 500);
      });
    });

    Promise.all(uploadPromises).then(() => {
      mutate(payload, {
        onSuccess: () => {
          resetUploader();
          showNotification({
            message: "Files added Successfully",
            type: "success",
          });
        },
        onError: () => {
          setIsButtonLoading(false);
          showNotification({ message: "Error uploading files", type: "error" });
        },
      });
    });
  };

  const resetUploader = () => {
    setProjectFiles([]);
    setFileProgress({});
    setResetUploaderKey((prevKey) => prevKey + 1);
    setIsButtonLoading(false);
  };

  const getProgressBar = (value: number) => <ProgressBar value={value} />;

  return (
    <FormPopup
      title="Add Files"
      visible={show}
      setVisible={onClose}
      onSave={handleSave}
      isLoading={isLoading || isButtonLoading}
    >
      <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
        <GroupItem>
          <ColCountByScreen xs={1} sm={1} md={2} lg={2} />
          <FormItem>
            <SelectBox
              items={clientData || []}
              placeholder="Select a client"
              displayExpr={"name"}
              onValueChanged={getClientId}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </FormItem>
          <FormItem>
            <SelectBox
              items={projectData || []}
              placeholder="Select a project"
              displayExpr={"projectName"}
              onValueChanged={getProjectId}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </SelectBox>
          </FormItem>
        </GroupItem>
        <GroupItem>
          <FormItem>
            <div className="file-uploader-container">
              <FileUploader
                key={resetUploaderKey}
                multiple={true}
                accept={uploadAccept}
                uploadMode="useForm"
                onValueChanged={handleFilesUpload}
              />
              <span className="note">
               {"Supported file formats: "}
               <span>.csv, .pdf, .json, .xml, .txt</span>
            </span>
              {Object?.keys(fileProgress)?.map((fileName) => (
                <div key={fileName} className="progressDiv">
                  {fileName} {getProgressBar(fileProgress[fileName])}
                </div>
              ))}
            </div>
          </FormItem>
        </GroupItem>
      </Form>
    </FormPopup>
  );
};

export default AddFiles;
