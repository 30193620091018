import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import DataGrid, { Column, HeaderFilter } from 'devextreme-react/data-grid';
import FileViewer from '../../../../../../components/ui-components/fileViewer/FileViewer';
import { useState } from 'react';
import PSIconText from '../../../../../../components/ui-components/icon-with-text/IconText';
import MyFilesView from "../../../../../../assets/icons/icon-eye.png";
import { useFetchDocumentInfo } from "../../../../../../hooks/datastudio/useDocumentAnalyze";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { useFetchSingleFiles } from "../../../../../../hooks/digital-service/useFileManager";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const dataCatalogId = props.data?.data?.dataCatalogId;
  const referencedataCatalogId = props.data?.data?.referencedataCatalogId;
  const userId = props.data?.data?.userId;
  const docStage = props.data?.data?.docStage;
  const processType = props.data?.data?.processType;
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const id = docStage === "Edited" ? referencedataCatalogId : dataCatalogId;

  const {
    data: detailData,
    isLoading,
  }: { data: any; isLoading: any } = useFetchDocumentInfo(id, docStage);

  const {
    data: fileViewerData,
    isLoading: fileViewerLoading,
  } = useFetchSingleFiles(selectedData);

  const detaildataView = detailData || [];

  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleViewFile = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = { 
      name: data?.fileName,
      fileExtension: data?.assetType,
      userId: userId,
      type: processType,
    };
    setSelectedData(params);
    setOpenPopup(true);
  };

  const title = {file: selectedData?.name}

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        src={MyFilesView}
        alt="View File"
        hint="View File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewFile(e, cell)}
      />
    );
  };

  if (isLoading) {
    return <PsSkeleton height={30} count={4} />;
  }

  return (
    <>
      <DataGrid
        dataSource={detaildataView}
        allowColumnReordering={true}
        noDataText={isLoading ? "Loading..." : "No Recent Details to display at this time"}
        showBorders={true}
        width="100%"
        allowColumnResizing={true}
      >
        <HeaderFilter visible />
        <Column caption="File Name" dataField="fileName" dataType="string" />
        <Column caption="File Size" dataField="fileSize" dataType="string" />
        <Column caption="File Type" dataField="fileType" dataType="string" />
        <Column caption="Created By" dataField="createdBy" dataType="string" />
        <Column
          caption="Created Date"
          dataField="createdDate"
          dataType="datetime"
          cellRender={cellStartTime}
        />
        <Column
          caption="Modified Date"
          dataField="lastUpdated"
          dataType="datetime"
          cellRender={cellUpdatedTime}
        />
        <Column caption="" type="buttons" cellRender={renderViewFile} />
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={fileViewerLoading}
        extensionType={selectedData?.fileExtension}
        params={selectedData}
        showExportToDataLake={true}
      />
    </>
  );
};

export default MasterDetailView;
