import { useCallback, useEffect, useMemo, useState } from "react";
import NewWorkflow from './workflowNew'
import {
  HeaderData,
  newHeaderTab,
  MetaData,
  newMetaTab,
  WorkflowData,
  DataItems,
  newDataTab
} from "../../types";
import { Popup } from "devextreme-react";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";
import GridSource from "../../../../../assets/icons/icon-grid-source-view.png";
import { Item } from "devextreme-react/toolbar";
import { ToolbarAnalytics } from "../../../../../components/ui-components/toolbar-analytics";
import { useFetchProject } from "../../../../../hooks/task/useTask";
import { useFetchClient } from "../../../../../hooks/app/useApps";
import { useApp } from "../../../../../contexts/app";
import { useFetchWorkflowFunctionDropdown, useCreateWorkflow } from "../../../../../hooks/task/useWorkflow";
import PsButton from "../../../../../components/ui-components/button/Button";
import { ConfirmationPopup } from "./ConfirmationPopup";
import { useNavigate, useLocation } from "react-router-dom";

export const WorkflowForm = (props: any) => {
  const { onOpen, onClose, onAddWorkflowOpen, showCancelPopup, setShowCancelPopup, selectedValues, setSelectedValues } = props;
  const { user: { userId, name } } = useApp();
  const { data: clientData } = useFetchClient();
  const { data: projectData } = useFetchProject(selectedValues) as any;
  const { data: workflowFunctionDropdown } = useFetchWorkflowFunctionDropdown();
  const actionData = workflowFunctionDropdown?.actions;
  const typeData = workflowFunctionDropdown?.type;
  const functionFilterData = workflowFunctionDropdown?.functions;
  const fileTypeData = workflowFunctionDropdown?.fileType;
  const transmitTypeData = workflowFunctionDropdown?.transmitType;

  const closePanel = useCallback(() => {
    onAddWorkflowOpen(false);
  }, [onAddWorkflowOpen]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refreshpage = () => navigate(pathname);
  const { mutate, isSuccess } = useCreateWorkflow(() => {
      closePanel();
      refreshpage();
    });
  
  const [newHeaderProps, setNewHeaderProps] = useState<HeaderData>({
    ...newHeaderTab,
  });

  const [newMetaProps, setNewMetaProps] = useState<MetaData>({
    ...newMetaTab,
  });

  const [newWorkflowProps, setNewWorkflowProps] = useState<WorkflowData[]>([]);

  const [newDataProps, setNewDataProps] = useState<DataItems>({
    ...newDataTab,
  });

  const updateHeaderField = (field: string) => (value: any) => {
    setNewHeaderProps((prevHeader) => ({
      ...prevHeader,
      [field]: value,
    }));
  }

  const updateMetaField = (field: string) => (value: any) => {
    setNewMetaProps((prevMeta: any) => {
      const [mainField, subField] = field?.split('.');
      if (subField) {
        return {
          ...prevMeta,
          [mainField]: {
            ...prevMeta[mainField],
            [subField]: value,
          },
        };
      }
      return {
        ...prevMeta,
        [field]: value,
      };
    });
  };

  const updateWorkflowField = (nodeId: number, field: string) => (value: any) => {
    setNewWorkflowProps((prevWorkflow) => {
      const newWorkflow = [...prevWorkflow];
      newWorkflow[nodeId] = {
        ...newWorkflow[nodeId],
        [field]: value,
      };
      return newWorkflow;
    });
  }

  const updateDataField = (field: string) => (value: any) => {
    setNewDataProps((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  }

  const onClientOptionChanged = (e: any) => {
    if (e && e?.clientId) {
      setNewHeaderProps({ ...newHeaderProps, ...{ "clientId": e?.clientId, "clientName": e.name, "createdBy": userId, "createdUser": name } });
      setSelectedValues(e.clientId);
    }
  };

  const onProjectOptionChanged = (e: { projectName: string, projectId: string, projectType: string }) => {
    if (e && newHeaderProps?.clientId) {
      setNewHeaderProps({ ...newHeaderProps, ...{ "projectName": e?.projectName, "projectId": e?.projectId } });
    }
  };

  const handleFormSubmit = useCallback(() => {
    const newWorkflowData = {
      header: newHeaderProps,
      meta: newMetaProps,
      workflow: newWorkflowProps,
      data: newDataProps
    }
    mutate(newWorkflowData);
  }, [mutate, newHeaderProps, newMetaProps, newWorkflowProps, newDataProps]);

  const handleCancelClick = () => {
    setShowCancelPopup(true);
  };

  const handleCancelConfirm = () => {
    setShowCancelPopup(false);
    onClose();
  };

  const handleCancelReject = () => {
    setShowCancelPopup(false);
  };

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PSIconText
          src={GridSource}
          alt="Grid/Source View"
          text={"Grid/Source View"}
          disabled={true}
        />
        <PsButton
          icon="close"
          mode="text"
          type="normal"
          width={"auto"}
          onClick={handleCancelClick}
          eventName="File Viewer close buttonp"
        />
      </Item>
    );
  }, []);

  useEffect(() => {
    if (onOpen) {
      const popupContent = document?.querySelector(
        ".workflow-popup .dx-popup-content"
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        ".workflow-popup .dx-popup-content-scrollable"
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [onOpen]);

  return (
    <Popup
      visible={onOpen}
      onHiding={onClose}
      fullScreen={true}
      width={"100%"}
      height={1000}
      showTitle={false}
      showCloseButton={false}
      className="workflow-popup"
    >
      <div className="view-wrapper">
        <ToolbarAnalytics
          title={"Add Workflow"}
          showToolbar={true}
          additionalToolbarContent={AdditionalToolbarContent}
        />
        <div>
          <NewWorkflow
            newHeaderData={newHeaderProps}
            newMetaData={newMetaProps}
            newWorkflowData={newWorkflowProps}
            newDataItems={newDataProps}
            updateHeaderField={updateHeaderField}
            updateMetaField={updateMetaField}
            updateWorkflowField={updateWorkflowField}
            updateDataField={updateDataField}
            handleFormSubmit={handleFormSubmit}
            onClose={onClose}
            onOpen={onOpen}
            clientData={clientData}
            projectData={projectData}
            onClientOptionChanged={onClientOptionChanged}
            onProjectOptionChanged={onProjectOptionChanged}
            actionData={actionData}
            functionFilterData={functionFilterData}
            fileTypeData={fileTypeData}
            transmitTypeData={transmitTypeData}
            showCancelPopup={showCancelPopup}
            setShowCancelPopup={setShowCancelPopup}
            typeData={typeData}
            isSuccess={isSuccess}
          />
        </div>
      </div>
      <ConfirmationPopup
        visible={showCancelPopup}
        onConfirm={handleCancelConfirm}
        onCancel={handleCancelReject}
      />
    </Popup>
  );
};

export default WorkflowForm;
