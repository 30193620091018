import { DropDownButton } from "devextreme-react";
import { FormTextbox } from "../../../../components/ui-components/form/form-textbox/FormTextbox";
import MergeIcon from "../../../../assets/icons/icon-apply-2.png";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";

export const LoactionItems = [
  {
    text: "Download",
  },
  {
    text: "Document",
  },
];

export const dropDownOptions = { width: "auto" };

const MergeFunction = () => {
  const onValueChange = (data: any) => {
    console.log(data);
  };

  const handleItemClick = (e: any) => {
    console.log(e);
  };

  return (
    <>
      <div className="merge-action-wrapper">
        <div className="file-input">
          FileName:{" "}
          <FormTextbox
            value={""}
            isEditing={false}
            onValueChange={onValueChange}
          />
        </div>
        <div>
          <DropDownButton
            icon="export"
            text="Location"
            width="auto"
            stylingMode="text"
            items={LoactionItems}
            useSelectMode={true}
            displayExpr="text"
            dropDownOptions={dropDownOptions}
            onItemClick={handleItemClick}
            disabled={false}
            className="dropdown-export"
          />
        </div>
        <div>
          <PSIconText
            text="Merge"
            alt="merge"
            src={MergeIcon}
            onClick={() => { }}
          />
        </div>
      </div>
    </>
  );
};

export default MergeFunction;
