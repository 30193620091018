import React, { useMemo } from "react";
import { PskanbanBoard } from "../../../../components/core-component/kanban";
import { MetricsTicker } from "../../../../components/ui-components/ticker-card/TickerCardNew";
import { keyToImageMap } from "../../../../utlis/image";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import {
  cellComplexityRender,
  editCellStatusRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../contexts/pageContext";
import { fetchAllUrlsApi } from "../../../../service/client/api-service";

export const taskStatuses = ["Pending", "Development", "QA", "Production"];

const UrlKanbanPage = React.memo((props: any) => {
  const {
    summaryData,
    client,
    handleDropDownPageIndex,
    handleStatusChange,
    status,
    clientId,
    handleClientLinkClick,
    additionalParams,
    handleColumnIndexChange,
    gridTitleStatus,
    setGridTitleStatus
  } = props;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName: clientColumn,
    url: urlColumn,
    status: statusColumn,
    group: groupColumn,
    complexity: complexityColumn,
    note: noteColumn,
    url_template_path: urlTemplatePathColumn
  } = filterValue || {};

  const transformMetricsData = (data: any[], client: string) => {
    if (!Array?.isArray(data)) return [];
    const metricsData: { [key: string]: any } = {};

    const cardFiltered = data?.filter(
      (item: any) => client === "All Clients" || item?.clientName === client
    );

    cardFiltered?.forEach((client) => {
      client?.urlcatalog?.forEach((catalog: { name: string; value: number }) => {
        const title = catalog?.name;
        if (!metricsData[title]) {
          metricsData[title] = { title, data: [] };
        }
        metricsData[title]?.data?.push({
          name: client?.clientName,
          value: catalog?.value,
          clientId: client?.clientId,
        });
      });
    });

    return Object?.values(metricsData);
  };

  const processKanbanData = (data: any[]) => {
    if (!Array?.isArray(data)) return [];
    const kanbanData: any[] = [];
    data?.forEach((client) => {
      client?.details?.forEach(
        (detail: { status: string; urlInfo: any[]; priority: string }) => {
          detail?.urlInfo?.forEach((url) => {
            kanbanData?.push({
              clientName: url?.clientName,
              clientId: url?.clientId,
              status: detail?.status,
              urlCount: url?.urlCount,
              priority: detail?.priority,
            });
          });
        }
      );
    });
    return kanbanData;
  };

  const metricsData = transformMetricsData(summaryData?.data || [], client);
  const kanbanData = processKanbanData(summaryData?.data || []);

  const filteredKanbanData =
    client === "All Clients"
      ? kanbanData
      : kanbanData?.filter((item) => item?.clientName === client);

  const handleTileCardClick = (status: string) => {
    setGridTitleStatus(status);
    handleStatusChange(status, clientId);
    handleDropDownPageIndex([0]);
  };

  const handleCardClick = (data: any) => {
    const { status, clientId } = data[0];
    handleStatusChange(status, clientId);
    setGridTitleStatus(status);
    handleDropDownPageIndex([0]);
  };

  const columns = useMemo(() => [
    {
      dataField: "clientName",
      caption: "Client",
      cellRender: (data: any) => {
        const clientName = data?.data?.clientName;
        const clientId = data?.data?.clientId;
        return (
          <a
            href="null"
            onClick={(e) => handleClientLinkClick(e, clientId)}
          >
            {clientName}
          </a>
        );
      },
      width: "15%",
      filterValues: clientColumn
    },
    {
      dataField: "url",
      caption: "URL",
      cellRender: (data: any) => (
        <div
          title={data?.value}
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {data?.value ? data?.value?.substring(0, 50) : ""}
        </div>
      ),
      width: "30%",
      filterValues: urlColumn
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ContactStatus,
      editCellRender: editCellStatusRender,
      width: "10%",
      filterValues: statusColumn
    },
    {
      dataField: "group",
      caption: "Group Name",
      width: "10%",
      filterValues: groupColumn
    },
    {
      dataField: "complexity",
      caption: "Complexity",
      cellRender: cellComplexityRender,
      width: "10%",
      filterValues: complexityColumn
    },
    {
      dataField: "note",
      caption: "Notes",
      width: "10%",
      filterValues: noteColumn
    },
    {
      dataField: "url_template_path",
      caption: "Template",
      width: "30%",
      filterValues: urlTemplatePathColumn
    }
  ], [clientColumn, complexityColumn, groupColumn, handleClientLinkClick, noteColumn, statusColumn, urlColumn, urlTemplatePathColumn]);

  const pageProps = useMemo(() => ({
    nodataText: "No URLs to display at this time",
    heightClass: "height-small",
    id: "urlgrid",
  }), []);

  const apiProps = useMemo(() => ({
    apiFunction: (queryParams: any) =>
      fetchAllUrlsApi({
        ...queryParams,
        ...additionalParams,
        status,
        clientId,
      }),
  }), [additionalParams, status, clientId]);

  const gridProps = useMemo(() => ({
    columns,
    keyExpr: "id",
    allowedPageSizes,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    searchPanelVisible: false,
    handleColumnIndexChange
  }), [columns, handleColumnIndexChange]);

  return (
    <>
      <div className="doc-row-card">
        {metricsData?.map((metric) => (
          <MetricsTicker
            key={metric?.title}
            title={metric?.title}
            secondaryValue={metric?.data}
            icon={keyToImageMap[metric?.title]}
            onClick={() => handleTileCardClick(metric?.title)}
          />
        ))}
      </div>
      <PskanbanBoard
        status={taskStatuses}
        data={filteredKanbanData}
        onCardClick={handleCardClick}
      />
      <div className="t-gap recent-activity-grid">
        <h6>{gridTitleStatus}</h6>
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      </div>
    </>
  );
});

export default UrlKanbanPage;
