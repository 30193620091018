import { Popup } from "devextreme-react";
import { useFetchWorkflowStepDataList } from "../../../../hooks/app/useApps";
import { transformExecutionStorageData } from "../types";
import { TreeView } from "devextreme-react";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Scrolling,
} from "devextreme-react/data-grid";
import { cellStartTime, formatDate } from "../../dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../types";
import { useFetchSelectedExecutionFileData } from "../../../../hooks/app/useApps";
import PsButton from "../../button/Button";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import { useEffect, useState } from "react";
import FileViewer from "../FileViewer";

export const Modal = (props: any) => {
  const { showModal, onModalToggle, popupData, popupType, params } = props;
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const [selectedParams, setSelectedParams] = useState<any>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const { data: datalist, isLoading: isDataListLoading } = useFetchWorkflowStepDataList(selectedParams);
  const finalData = datalist ? transformExecutionStorageData(datalist) : [];
  const { data: fileData, isLoading: isFileLoading } = useFetchSelectedExecutionFileData(selectedFileData);

  const onHiding = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (showModal && popupType?.startsWith("step-")) {
      const param = {
        executionName: params?.executionName,
        stepId: popupType,
        manifestId: params?.manifestId,
        folder: 'Input',
      };
      setSelectedParams(param);
    }
    else if (showModal && popupType?.startsWith("output-")) {
      const type = popupType?.replace(/^output/, 'step');
      const param = {
        executionName: params?.executionName,
        stepId: type,
        manifestId: params?.manifestId,
        folder: 'Output',
      };
      setSelectedParams(param);
    }
  }, [showModal, popupType, params, popupData]);

  const taskRender = (popupData: any) => {
    if (!popupData) return <div>Loading...</div>;
    return (
      <div>
        <div>
          <span><b>Task: </b></span>
          <span>{popupData?.taskName}</span>
        </div>
        <div>
          <span><b>Client: </b></span>
          <span>{popupData?.clientName}</span>
        </div>
        <div>
          <span><b>Project: </b></span>
          <span>{popupData?.projectName}</span>
        </div>
        <div>
          <span><b>Project Type: </b></span>
          <span>{popupData?.projectType}</span>
        </div>
        <div>
          <span><b>Max Container Group: </b></span>
          <span>{popupData?.maxContainerGroup}</span>
        </div>
        <div>
          <span><b>Container Per Group: </b></span>
          <span>{popupData?.containersPerGroup}</span>
        </div>
        <div>
          <span><b>Container Size: </b></span>
          <span>{popupData?.containerSize}</span>
        </div>
      </div>
    );
  };

  const actionRender = (popupData: any) => {
    if (!popupData) return <div>Loading...</div>;
    return (
      <div>
        <div>
          <span><b>Name: </b></span>
          <span>{popupData?.name}</span>
        </div>
        <div>
          <span><b>Description: </b></span>
          <span>{popupData?.description}</span>
        </div>
        <div>
          <span><b>Functions: </b></span>
          <span>{popupData?.functions}</span>
        </div>
        <div>
          <span><b>Status: </b></span>
          <span>{popupData?.status}</span>
        </div>
        <div>
          <span><b>Start Time: </b></span>
          <span>{formatDate(popupData?.startTime)}</span>
        </div>
        <div>
          <span><b>End Time: </b></span>
          <span>{formatDate(popupData?.endTime)}</span>
        </div>
      </div>
    );
  };

  const onViewScrapingButtonClick = (e: any, cell: any) => {
    const { data } = cell;
    setSelectedFileData(data);
    setPopupVisible(!popupVisible);
  };

  const renderAction = (cell: any) => {
    return (
      <PsButton
        hint="MyFiles"
        icon={MyFilesView}
        mode="text"
        onClick={(e) => onViewScrapingButtonClick(e, cell)}
        eventName='workflow files click event'
      />
    );
  };

  const renderItem = (item: any) => {
    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? (
          <DataGrid
            dataSource={item?.data}
            rowAlternationEnabled={false}
            showBorders={true}
            noDataText={isFileLoading ? "Loading..." : "No files to display at this time"}
            allowColumnResizing={true}
          >
            <Column caption="File Name" dataField="filename" dataType="string" />
            <Column caption="Uploaded on" dataField="createdDate" dataType="string" cellRender={cellStartTime} />
            <Column caption="File Size" dataField="fileSize" dataType="string" />
            <Column caption="" type="buttons" width={180} cellRender={renderAction} />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Paging defaultPageSize={5} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        ) : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  const treeView = () => {
    if (isDataListLoading) return <div>Loading...</div>;
    if (!finalData || finalData.length === 0) return <div>No data to display at this time.</div>;
    return (
      <div>
        <TreeView
          id="simple-treeview"
          items={finalData}
          itemRender={renderItem}
        />
        <FileViewer
          visible={popupVisible}
          onHiding={onHiding}
          data={
            selectedFileData?.fileExtension === "pdf"
              ? fileData?.data
              : JSON?.stringify(fileData?.data)
          }
          title={title}
          isLoading={isFileLoading}
          extensionType={selectedFileData?.fileExtension}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!showModal) return <div>Loading...</div>;
    if (popupType === "task") return taskRender(popupData);
    if (popupType?.startsWith("step-") || popupType?.startsWith("output-")) return treeView();
    if (popupType?.startsWith("action-")) return actionRender(popupData);

    return <div>No data to display at this time.</div>;
  };

  if (popupType === "function" || popupType === "status") {
    return null;
  }

  return (
    <Popup
      visible={showModal}
      onHiding={onModalToggle}
      dragEnabled={false}
      width={1200}
      height={600}
      title={`${popupType?.charAt(0)?.toUpperCase() + popupType?.slice(1)} Details`}
    >
      <div className="grid-container-estimator">
        {renderContent()}
      </div>
    </Popup>
  );
};
