import { useEffect, useReducer, useState } from "react";
import { useQuery } from "react-query";
import { fetchFilesApi, fetchSingleFilesApi } from "../../service/digital-service/api-service";

const initialState: any = {
  selectedFile: '',
  selectedDirectory: {},
};

type FileItem = {
  filename: string;
  fileExtension: string;
  projectId: string;
  userId: string;
  size: string;
  createdDate: string;
  type: string;
};

type InputItem = {
  [key: string]: Array<{ [key: string]: any } | FileItem>;
};

type OutputItem = {
  name: string;
  isDirectory: boolean;
  items: Array<OutputItem | { name: string; fileExtension: string, projectId: string, userId: string, size: any, createdDate: string, type: string }>;
};

const reducer = (state: any, action: any) => {
  switch (action?.type) {
    case "SELECTED_FILE":
      return {
        ...state,
        selectedFile: action?.payload,
      };
    case "SELECTED_DIRECTORY":
      return {
        ...state,
        selectedDirectory: action?.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

const transformData = (input: InputItem): OutputItem[] => {
  const transformObject = (obj: InputItem, key: string): OutputItem => {
    const items: any = obj[key];

    return {
      name: key,
      isDirectory: true,
      items: Array?.isArray(items)
        ? items?.flatMap((item: any): any => {
          if ("filename" in item) {
            return [
              {
                name: (item as FileItem)?.filename,
                fileExtension: (item as FileItem)?.fileExtension,
                projectId: (item as FileItem)?.projectId,
                userId: (item as FileItem)?.userId,
                size: (item as any)?.fileSize,
                createdDate: (item as FileItem)?.createdDate,
                type: (item as FileItem)?.type,
              },
            ];
          } else if (typeof item === "object" && item !== null) {
            return Object?.keys(item)?.map((subKey) =>
              transformObject(item, subKey)
            );
          }
          return [];
        })
        : typeof items === "object" && items !== null
          ? Object?.keys(items)?.map((subKey) => transformObject(items, subKey))
          : [],
    };
  };
  return Object?.keys(input)?.map((key) => transformObject(input, key));
};

const mergeData = (oldData: OutputItem[], newData: OutputItem[]): OutputItem[] => {
  const mergeNodes = (oldNode: OutputItem, newNode: OutputItem): OutputItem => {
    const mergedItems = [...oldNode?.items];
    newNode?.items?.forEach((newItem) => {
      const existingItemIndex = mergedItems?.findIndex(
        (oldItem) => "name" in oldItem && "name" in newItem && oldItem?.name === newItem?.name
      );
      if (existingItemIndex !== -1) {
        if ("isDirectory" in mergedItems[existingItemIndex]) {
          mergedItems[existingItemIndex] = mergeNodes(
            mergedItems[existingItemIndex] as OutputItem,
            newItem as OutputItem
          );
        }
      } else {
        mergedItems?.push(newItem);
      }
    });
    return { ...oldNode, items: mergedItems };
  };

  const mergedData = [...oldData];

  newData?.forEach((newNode) => {
    const existingNodeIndex = mergedData?.findIndex((oldNode) => oldNode?.name === newNode?.name);
    if (existingNodeIndex !== -1) {
      mergedData[existingNodeIndex] = mergeNodes(mergedData[existingNodeIndex], newNode);
    } else {
      mergedData?.push(newNode);
    }
  });

  return mergedData;
};

export const useFileManager = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const data = useFetchFiles(state?.selectedDirectory);
  const SingleFileData = useFetchSingleFiles(state?.selectedFile);

  const handleSelectedFile = (file: any) => {
    dispatch({ type: "CLEAR" });
    dispatch({ type: "SELECTED_FILE", payload: file });
  };

  const setSelectedDirectory = (directory: any) => {
    dispatch({ type: "SELECTED_DIRECTORY", payload: directory });
  };

  return {
    state,
    fileResources: data,
    SingleFileData,
    handleSelectedFile,
    setSelectedDirectory
  };
};

export const useFetchFiles = (params: any) => {
  const [mergedData, setMergedData] = useState<OutputItem[]>([]);
  const { data, isLoading, isError, isFetching } = useQuery(
    ["fetch-data-lakes", params],
    () => fetchFilesApi(params),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      staleTime: Infinity,
      select: (data: any) => {
        const res: any = data?.data?.[0] || {};
        return transformData(res);
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
  useEffect(() => {
    if (data) {
      setMergedData((prevData) => mergeData(prevData, data));
    }
  }, [data]);
  return { mergedData, isLoading, isError, isFetching };
};

export const useFetchSingleFiles = (details: any) => {
  const ext = details?.fileExtension;
  const params = {
    filename: details?.name,
    fileExtension: details?.fileExtension,
    userId: details?.userId,
    projectId: details?.projectId,
    type: details?.type,
    createdDate: details?.createdDate,
  };
  return useQuery(
    ["fetch-single-data-lakes", params],
    () => fetchSingleFilesApi(params),
    {
      refetchOnWindowFocus: false,
      enabled: !!params?.filename,
      select: (data: any) => {
        const type = ext
        if (type === "json" || type === "csv" || type === "data" || type === "xlsx" || type === "xls" || type === "txt" || type === "map" || type === "xml" || type === "di" || type === "processed" || type === "extracted" || type === "rejected" || type === "finalized" || type === "extracted_v2" || type === "export" || type === "export_gold" || type === "final" || type === "accounts" || type === "transactions" || type === "positions") {
          const res = JSON?.stringify(data?.data, null, 2);
          return res;
        }
        return data?.data || '';
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
