import Popup from "devextreme-react/popup";
import PsButton from "../../../../../../components/ui-components/button/Button";

const ConfirmModal = (props: any) => {
  const { onHandleConfirm, onHandleModal, popupVisible } = props;
  return (
    <Popup
      title="Terminating Container"
      visible={popupVisible}
      onHiding={onHandleModal}
      width="30%"
      height="auto"
    >
        <div>
          <p>Are you sure you want to terminate this Container Instance?</p>
          <p>
            If you terminate your Container Instance, you will not be able to
            recover it.
          </p>
        </div>
        <PsButton
          onClick={onHandleConfirm}
          text="Confirm"
          type="normal"
          mode="contained"
        />
        <div style={{ float: "right" }}>
          <PsButton
            onClick={onHandleModal}
            text="Cancel"
            type="normal"
            mode="contained"
          />
        </div>
    </Popup>
  );
};

export default ConfirmModal;
