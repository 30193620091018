// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infra-title-header {
  font-size: 15px;
  font-weight: 700;
  color: black !important;
  margin-bottom: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/task/infra-data.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;AAAJ","sourcesContent":["\n.infra-title-header{\n    font-size: 15px;\n    font-weight: 700;\n    color: black!important;\n    margin-bottom: 10px;\n    margin-top: 10px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
