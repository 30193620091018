import React from "react";
import { Chart, Series, Tooltip, Legend } from "devextreme-react/chart";
import { convertToUSD } from "../../../../utlis/helper";

const customizeTooltip = (arg: any) => {
  const designation = arg?.argument;
  const oranges = convertToUSD(arg?.value);
  let tooltipText = `${designation}: ${oranges}`;

  return {
    text: tooltipText,
  };
};

export const CostAnalysis = ({ datasource }: { datasource: any }) => {
  const chartData = datasource?.map((client: any) => ({
    designation: client?.designation,
    value: parseFloat(client?.value.replace("$", "")) || 0,
  }));

  return (
    <div style={{ padding: "20px" }}>
      <Chart id="chart" dataSource={chartData}>
        <Series
          valueField="value"
          argumentField="designation"
          name="Designation"
          type="bar"
          color="#74a0e3"
        />
        <Tooltip
          enabled={true}
          shared={true}
          customizeTooltip={customizeTooltip}
        />
        <Legend
          visible={true}
          verticalAlignment="bottom"
          horizontalAlignment="center"
        />
      </Chart>
    </div>
  );
};
