import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Form, {
  SimpleItem,
  GroupItem,
  ColCountByScreen,
  RangeRule,
} from "devextreme-react/form";
import SelectBox from "devextreme-react/select-box";
import {
  CONTAINER_SCHEDULE,
  CONTAINER_VALUE,
  CONTAINER_SIZE,
  TASK_STATUS,
} from "../../../../../config/constants";
import { TaskData } from "../../types";
import {
  useFetchProject,
  useFetchWorkflowTemplateDropdown,
} from "../../../../../hooks/task/useTask";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { DateBox } from "devextreme-react";
import { useFetchClient } from "../../../../../hooks/app/useApps";

const clearLabel = { "aria-label": "Clear" };

const TaskUrlPanelDetails = ({
  editing,
  data,
  subjectField,
  onDataChanged,
}: {
  editing: boolean;
  data: TaskData;
  subjectField: boolean;
  onDataChanged: (data: any) => void;
}) => {
  const [formData, setFormData] = useState<TaskData>({ ...data });
  const [clientData, setClientData] = useState<any[]>([]);
  const [selectedValues, setSelectedValues] = React.useState<any>(null);
  const [selectedTime, setSelectedTime] = React.useState<any>(null);
  const [selectedDay, setSelectedDay] = React.useState<any>(null);
  const [selectedFrequency, setSelectedFrequency] = React.useState<any>(null);
  const [selectDaily, setSelectDaily] = useState(true);
  const [selectWeekly, setSelectWeekly] = useState(true);
  const [selectMonthly, setSelectMonthly] = useState(true);

  const fetchClientData = useFetchClient();
  const { data: fetchProject } = useFetchProject(selectedValues?.clientId);
  const { data: workflowTemplateDropdown } = useFetchWorkflowTemplateDropdown(selectedValues?.projectId);

  useEffect(() => {
    if (data?.scheduler?.frequency === "Daily") {
      setSelectDaily(false);
      setSelectWeekly(true);
      setSelectMonthly(true);
    } else if (data?.scheduler?.frequency === "Weekly") {
      setSelectWeekly(false);
      setSelectDaily(false);
      setSelectMonthly(true);
    } else if (data?.scheduler?.frequency === "Monthly") {
      setSelectMonthly(false);
      setSelectDaily(true);
      setSelectWeekly(true);
    } else {
      setSelectMonthly(true);
      setSelectDaily(true);
      setSelectWeekly(true);
    }
  }, [formData?.scheduler?.frequency]);

  useEffect(() => {
    setFormData({ ...data });
    setClientData(fetchClientData?.data || []);
    setSelectedFrequency(data?.scheduler?.frequency);
    setSelectedDays(getDayName(data?.scheduler?.day));
  }, [data, fetchClientData?.data]);

  const ClientNameValues = clientData?.map((item) => item.name);

  const ProjectDataValues = fetchProject?.map(
    (item: { projectName: any }) => item.projectName
  );

  const WorkflowValues = workflowTemplateDropdown?.map(
    (item: { workflowName: any }) => item?.workflowName
  );

  const [timeValue, setTimeValue] = useState<string | undefined>();
  const [selectedDays, setSelectedDays] = useState<any | undefined>();
  const updateField = (field: string) => (value: any) => {
    const newData = { ...formData, [field]: value };
    onDataChanged(newData);
    setFormData(newData);
  };

  useEffect(() => {
    setSelectedValues(data);
  }, [data]);

  const onOptionDailyTaskScedule = (e: any) => {
    const selectedDateObj = new Date(e);
    const time = selectedDateObj.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
    setSelectedTime(time);
    const newData = {
      ...formData,
      ["scheduler"]: {
        start_time: time,
        frequency: selectedFrequency,
        day: [selectedDay],
      },
    };
    onDataChanged(newData);
  };

  const onOptionMonthlySchedule = (e: any) => {
    const selectedDateObj = new Date(e);
    const time = selectedDateObj.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
    const month = selectedDateObj.getMonth() + 1;
    const day = selectedDateObj.getDate();
    const newData = {
      ...formData,
      ["scheduler"]: {
        start_time: time,
        frequency: selectedFrequency,
        day: [day],
        months: [month],
      },
    };
    onDataChanged(newData);
  };

  function getDayNumber(dayName: string) {
    switch (dayName) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 7;
      default:
        return null;
    }
  }

  function getDayName(dayNumber: any) {
    const day = Array.isArray(dayNumber) ? dayNumber[0] : dayNumber;
    switch (day) {
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      case 7:
        return "Sunday";
      default:
        return null;
    }
  }

  const onOptionWeeklyTaskScedule = (e: any) => {
    const dayNumber = getDayNumber(e);
    setSelectedDay(dayNumber);
    const newData = {
      ...formData,
      ["scheduler"]: {
        start_time: formData?.scheduler?.start_time,
        frequency: formData?.scheduler?.frequency,
        day: [dayNumber],
      },
    };
    onDataChanged(newData);
    setSelectedDays(getDayName(dayNumber));
  };

  useEffect(() => {
    if (formData.taskScheduling === "Daily") {
      setSelectedFrequency(formData.taskScheduling);
      const newData = {
        ...formData,
        ["scheduler"]: {
          start_time: formData?.scheduler?.start_time,
          frequency: formData.taskScheduling,
        },
      };
      onDataChanged(newData);
      setSelectDaily(false);
      setSelectWeekly(true);
      setSelectMonthly(true);
    } else if (formData.taskScheduling === "Weekly") {
      setSelectedFrequency(formData.taskScheduling);
      const newData = {
        ...formData,
        ["scheduler"]: {
          start_time: formData?.scheduler?.start_time,
          frequency: formData.taskScheduling,
          day: [selectedDay],
        },
      };
      onDataChanged(newData);
      setSelectWeekly(false);
      setSelectDaily(false);
      setSelectMonthly(true);
    } else if (formData.taskScheduling === "Monthly") {
      const newData = {
        ...formData,
        ["scheduler"]: {
          start_time: formData?.scheduler?.start_time,
          frequency: formData.taskScheduling,
          day: [formData.scheduler?.day],
        },
        month: [formData?.scheduler?.month],
      };
      onDataChanged(newData);
      setSelectedFrequency(formData.taskScheduling);
      setSelectMonthly(false);
      setSelectDaily(true);
      setSelectWeekly(true);
    }
  }, [formData.taskScheduling]);

  if (typeof window === "undefined") return null;

  return (
    <Form
      className={classNames({
        "plain-styled-form task-form-details": true,
        "view-mode": !editing,
      })}
      screenByWidth={getSizeQualifier}
    >
      <GroupItem itemType="group">
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />

        <SimpleItem>
          <SelectBox
            label="ClientName"
            value={formData.clientName}
            items={ClientNameValues}
            readOnly={true}
            stylingMode="filled"
            onValueChange={updateField("clientName")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="ProjectName"
            value={formData.projectName}
            items={ProjectDataValues}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("projectName")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="WorkflowName"
            value={formData?.workflowName}
            items={WorkflowValues}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("workflowName")}
          />
        </SimpleItem>
        <SimpleItem cssClass="accent">
          <FormTextbox
            label="Task Name"
            value={formData.taskName}
            isEditing={!editing}
            onValueChange={updateField("taskName")}
          />
        </SimpleItem>
        <SimpleItem cssClass="accent">
          <FormTextbox
            label="Task Description"
            value={formData.taskDescription}
            isEditing={!editing}
            onValueChange={updateField("taskDescription")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Status"
            value={formData.status}
            items={TASK_STATUS}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("status")}
          />
        </SimpleItem>
        <SimpleItem>
          <FormTextbox
            label = "Max Container Group"
            placeholder="Max Container Group..."
            value={formData.maxContainerGroup}
            isEditing={false}
            onValueChange={updateField("maxContainerGroup")}
          >
            <RangeRule
              min={1}
              max={50}
            />
          </FormTextbox>
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Containers Per Group"
            value={formData.containersPerGroup}
            items={CONTAINER_VALUE}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("containersPerGroup")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Container Size"
            value={formData.containerSize}
            items={CONTAINER_SIZE}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("containerSize")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Task Shedule"
            value={formData.taskScheduling}
            items={CONTAINER_SCHEDULE}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("taskScheduling")}
          />
        </SimpleItem>
        <SimpleItem>
          <DateBox
            // defaultValue={new Date()}
            type="time"
            inputAttr={clearLabel}
            showClearButton={true}
            disabled={selectDaily}
            onValueChange={(event: any) => onOptionDailyTaskScedule(event)}
            placeholder="Select Time..."
          >
          </DateBox>
        </SimpleItem>
        <SimpleItem>
          <DateBox
            placeholder="Select Date & Time..."
            disabled={selectMonthly}
            type="datetime"
            inputAttr={{ "aria-label": "Date Time" }}
            displayFormat="yyyy-MM-dd HH:mm"
            onValueChange={(e: any) => onOptionMonthlySchedule(e)}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            dataSource={[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ]}
            searchEnabled={true}
            inputAttr={{ "aria-label": "Days" }}
            placeholder="Select Day..."
            disabled={selectWeekly}
            value={selectedDays}
            onValueChange={(event: any) => onOptionWeeklyTaskScedule(event)}
          />
        </SimpleItem>
      </GroupItem>
    </Form>
  );
};

export default TaskUrlPanelDetails;
