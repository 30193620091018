// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.portfolioDashboard {
  display: flex;
  gap: 20px;
  background-color: white;
  padding: 15px;
  border-radius: 5px;
}
.portfolioDashboard .portfolio-leftPane {
  width: 75%;
}
.portfolioDashboard .portfolio-rightPane {
  width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/portfolio.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,uBAAA;EACA,aAAA;EACA,kBAAA;AADF;AAGE;EACE,UAAA;AADJ;AAIE;EACE,UAAA;AAFJ","sourcesContent":["// portfolioDashboard.module.scss\n\n.portfolioDashboard {\n  display: flex;\n  gap: 20px;\n  background-color: white;\n  padding: 15px;\n  border-radius: 5px;\n\n  .portfolio-leftPane {\n    width: 75%;\n  }\n\n  .portfolio-rightPane {\n    width: 25%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
