// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parent-container {
  overflow-x: hidden;
  width: 100%;
}

.file-name-display {
  text-align: center;
  margin-bottom: 10px;
  font-size: 1.2rem;
}

.ps-export-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 120px);
  padding: 20px;
  padding-left: 130px;
  margin-top: 50px;
}
.ps-export-container .dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 900px;
  margin-right: 50px;
  gap: 30px;
}
.ps-export-container .dropdown-container .dx-dropdownbutton {
  font-size: 1.8em;
  padding: 16px 20px;
  width: 100%;
  min-width: 250px;
}
.ps-export-container .dx-radiogroup {
  font-size: 1.8em;
  padding: 16px 20px;
  align-self: center;
  min-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/styles/core-components/export.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;AACF;;AAGA;EACE,kBAAA;EACA,mBAAA;EAEA,iBAAA;AADF;;AAIA;EACE,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,2BAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,gBAAA;AADF;AAIE;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;AAFJ;AAKI;EACE,gBAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;AAHN;AASA;EACI,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;AAPJ","sourcesContent":[".parent-container {  \n  overflow-x: hidden; \n  width: 100%;\n}\n\n    \n.file-name-display {\n  text-align: center;\n  margin-bottom: 10px; \n  // font-weight: bold; \n  font-size: 1.2rem;\n}\n\n.ps-export-container {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: flex-start;\n  width: calc(100% - 120px);\n  padding: 20px;\n  padding-left: 130px; \n  margin-top: 50px; \n\n\n  .dropdown-container {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    width: 900px;\n    margin-right: 50px;\n    gap:30px;\n\n\n    .dx-dropdownbutton {\n      font-size: 1.8em;\n      padding: 16px 20px; \n      width: 100%;\n      min-width: 250px; \n    }\n  }\n\n  \n\n.dx-radiogroup {\n    font-size: 1.8em; \n    padding: 16px 20px; \n    align-self: center;\n    min-width: 250px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
