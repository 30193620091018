
import React, { useCallback, useMemo } from "react";
import EditProject from "../editProject";
import { useNavigate } from "react-router-dom";
import { GTAButtonEventHandler } from "../../../../../utlis/GAT-event-handler";
import { allowedPageSizes } from "../../../../../config/client/commonConfig";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";
import DataSource from "devextreme/data/data_source";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellTimeZone,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { useProjects } from "../../../../../hooks/client/useProject";
import MasterDetailView from "./helper-component/ProjectInfo";
import ProjectDeletePopup from "../deleteProject";
import { usePage } from "../../../../../contexts/pageContext";


const ClientInfo =React.memo(({height,apiProps,handleColumnIndexChange }: any) => {
  const navigate = useNavigate();
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};


  const {
    clientName: clientColunm,
    projectName: projectColumn,
    projectDescription:projectDiscriptionColumn,
    status: statusColumn,
    projectType:projectTypeColumn,
    endTime: timeEnded,
    timestamp: timeStarted,

  } = filterValue || {};


  const {
    state: { editPopupVisible, selectedProject, deletePopupVisible },
    onEditProjectClick,
    changeEditPopupVisibility,
    onDeletePopupClose,
    onDeletePopupOpen
  } = useProjects();

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string, projectName: any) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
      const eventName = `project_profile: ${projectName}_${projectId}`;
      GTAButtonEventHandler(eventName);
    },
    [navigate]
  );

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string, clientName: any) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
      const eventName = `client_profile: ${clientName}_${clientId}`;
      GTAButtonEventHandler(eventName);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      {
        dataField: "clientName",
        caption: "Client",
        filterValues: clientColunm,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleClientLinkClick(e, data?.data?.clientId, data?.data?.clientName)}
          >
            {data?.data?.clientName}
          </a>
        ),
      },
      {
        dataField: "projectName",
        caption: "Project Name",
        filterValues:projectColumn,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId, data?.data?.projectName)}
          >
            {data?.data?.projectName}
          </a>
        ),
      },
      {
        dataField: "projectDescription",
        caption: "Project Description",
        filterValues:projectDiscriptionColumn,
      },
      { dataField: "projectType", caption: "Activity Type", filterValues: projectTypeColumn },
      { dataField: "status", caption: "Status",filterValues:statusColumn ,cellRender: ContactStatus },
      {
        dataField: "timestamp",
        caption: "Date Started",
        filterValues:timeStarted,
        cellRender: cellTimeZone,
      },
      {
        dataField: "endTime",
        caption: "Date Completed",
        filterValues:timeEnded,
        cellRender: cellEndTime,
      },
    ],
    [handleClientLinkClick, handleProjectLinkClick, clientColunm, projectColumn, projectDiscriptionColumn, projectTypeColumn, statusColumn, timeEnded, timeStarted]
    
  ); 
  
  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "projectId",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      detailComponent: MasterDetailView,
      onRowEdit: onEditProjectClick,
      onRowDelete: onDeletePopupOpen,
      handleColumnIndexChange,
    }),
    [columns, onEditProjectClick, onDeletePopupOpen, handleColumnIndexChange]);
  

  const pageProps = {
    nodataText: "No projects to display at this time",
    heightClass: height || "height-large",
    id: "projectgrid",
  };


  const deleteProps = useMemo(() => {
    return {
      selectedProject,
      deletePopupVisible,
      onDeletePopupClose,
    }
  }, [
    selectedProject,
    deletePopupVisible,
    onDeletePopupClose,
  ]) 

  const PanelProps = useMemo(() => {
    return {
      selectedProject,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedProject, editPopupVisible]);

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
        <EditProject {...PanelProps} />
        <ProjectDeletePopup {...deleteProps}/>
      </div>
    </>
  );
});

export default ClientInfo;
