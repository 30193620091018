import { TextArea } from "devextreme-react";
import Card from "../../../../../../components/ui-components/card/card";

const DataTab = (props: any) => {

  const { editDataItems, updateDataField } = props;

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <div className="dx-field">
          <div className="dx-field-label">Enter the Data</div>
          <div className="dx-field-value">
            <TextArea
              value={editDataItems?.items}
              onValueChange={updateDataField("items")}
              height={500}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DataTab;