import "../../../../../styles/client/management.scss";
import ManagementPage from "./helper-component/managementgrid";
import ManageRowCard from "./helper-component/tileview";
import SideTileView from "./helper-component/side-tile";

const ManagementDashboard = ({ selectedDays,apiProps,handleColumnIndexChange}: any) => {
  return (
    <div className="managementDashboard">
      <div className="management-leftPane">
        <ManageRowCard selectedDays={selectedDays} />
        <ManagementPage apiProps={apiProps} handleColumnIndexChange={handleColumnIndexChange}/>
      </div>
      <div className="management-rightPane">
        <SideTileView selectedDays={selectedDays} />
      </div>
    </div>
  );
};

export default ManagementDashboard;