import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Form, {
  SimpleItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { Template } from "../../types";
import SelectBox from "devextreme-react/select-box";
import { useFetchSpecificTemplate } from "../../../../../../hooks/digital-service/useTemplate";
import { FormTextbox } from "../../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../../utlis/media-query";
import { STATUS_LIST } from "../../../../../../config/constants";
import PsButton from "../../../../../../components/ui-components/button/Button";
import FileUploader from 'devextreme-react/file-uploader';
import { TextArea } from "devextreme-react/text-area";

export const simpleProductLabel = { "aria-label": "Simple Product" };

const TemplateEditForm = ({
  editing,
  data,
  subjectField,
  onDataChanged,
  getTemplateTypeList
}: {
  data: Template;
  editing: boolean;
  subjectField: boolean;
  onDataChanged: (data: any) => void;
  getTemplateTypeList: any;
}) => {
  const [formData, setFormData] = useState<Template>({ ...data });
  const [templateBlobData, setTemplateBlobData] = useState<any>({});
  const [isEditingJSON, setIsEditingJSON] = useState<boolean>(false);
  const [textareaContent, setTextareaContent] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const template_id = formData.template_id;
  const specificTemplate = useFetchSpecificTemplate(template_id as string);

  const templateblobSpecific = specificTemplate?.data?.blobfile_info;

  useEffect(() => {
    setFormData({ ...data });
    if (templateblobSpecific) {
      setTemplateBlobData((prevData: any) => ({
        ...prevData,
        file: templateblobSpecific,
      }));
    }
  }, [templateblobSpecific, data]);

  useEffect(() => {
    if (templateBlobData.file) {
      setTextareaContent(JSON.stringify(templateBlobData.file, null, 2));
    }
  }, [templateBlobData]);

  const updateField = (field: string) => (value: any) => {
    let newData: any = { ...formData };
    if (field === "file") {
      const updatedFile = new File(
        [JSON.stringify(value)],
        `${formData.template_name}.json`,
        { type: "application/json" }
      );
      newData = { ...formData, [field]: updatedFile };
    } else {
      newData = { ...formData, [field]: value };
    }
    onDataChanged(newData);
    setFormData(newData);
  };

  const handleSave = () => {
    try {
      const parsedJSON = JSON.parse(textareaContent);
      updateField("file")(parsedJSON);
      setIsEditingJSON(false);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Invalid JSON format");
    }
  };

  const handleFileUpload = (e: any) => {
    const file = e.value[0];
    const reader = new FileReader();
    reader.onload = (event) => {
      try {
        const parsedJSON = JSON.parse(event.target?.result as string);
        updateField('file')(parsedJSON);
      } catch (error) {
        console.error('Invalid JSON file');
      }
    };
    reader.readAsText(file);
  };

  return (
    <Form
      className={classNames({
        "plain-styled-form task-form-details": true,
        "view-mode": !editing,
      })}
      screenByWidth={getSizeQualifier}
    >
      <GroupItem itemType="group">
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <SimpleItem cssClass="accent">
          <FormTextbox
            label="Template Name"
            value={formData.template_name}
            isEditing={!editing}
            onValueChange={updateField("template_name")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Template Type"
            value={formData.template_function}
            items={getTemplateTypeList}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("template_function")}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label="Status"
            value={formData.status}
            items={STATUS_LIST}
            readOnly={!editing}
            stylingMode="filled"
            onValueChange={updateField("status")}
          />
        </SimpleItem>
        <SimpleItem cssClass="accent">
          <FormTextbox
            label="Note"
            value={formData.notes}
            isEditing={!editing}
            onValueChange={updateField("notes")}
          />
        </SimpleItem>
        <SimpleItem cssClass="accent">
          {isEditingJSON ? (
            <TextArea
              value={textareaContent}
              onValueChanged={(e) => setTextareaContent(e.value)}
              height="55vh" 
              width="200%"
              spellcheck={false}
              showClearButton={false}
              stylingMode="outlined"
            />
          ) : (
            <FileUploader
              selectButtonText="New Template file"
              accept=".json"
              uploadMode="useForm"
              onValueChanged={handleFileUpload}
            />
          )}
          <PsButton
            text={isEditingJSON ? 'Cancel' : 'Edit Template'}
            onClick={() => setIsEditingJSON(!isEditingJSON)}
            type='normal'
          />
          {isEditingJSON && (
            <React.Fragment>
              <PsButton
                text='Save'
                type='normal'
                onClick={handleSave}
              />
              {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
            </React.Fragment>
          )}
        </SimpleItem>
      </GroupItem>
    </Form>
  );
};

export default TemplateEditForm;
