import { useCallback, useMemo, useState } from "react";
import {
  ReactFlow,
  addEdge,
  Connection,
  Edge,
  useNodesState,
  useEdgesState,
  Panel,
  OnConnect,
  MiniMap,
  Background,
  Controls,
  MarkerType,
} from "@xyflow/react";
import TextUpdaterNode from "./CustomNode";
import { Modal } from "./FlowPopup";

const initialNodess: any[] = [
  {
    id: "1",
    data: { step: "Step-1", label: `Name: Step-1`, description: "This is step 1", additionalInfo: "Additional Info" },
    sourcePosition: "bottom",
    position: { x: 100, y: 0 },
    type: "textUpdater",
  },
  {
    id: "2",
    data: { step: "Step-2", label: `Name: Step-2`, description: "This is step 2", additionalInfo: "Additional Info" },
    sourcePosition: "bottom",
    targetPosition: "top",
    position: { x: 100, y: 250 },
    type: "textUpdater",
  },
  {
    id: "3",
    data: { step: "Step-3", label: `Name: Step-3`, description: "This is step 3", additionalInfo: "Additional Info" },
    targetPosition: "top",
    position: { x: 100, y: 500 },
    type: "textUpdater",
  },
];

const initialEdgess: Edge[] = [
  { id: "1-2", source: "1", target: "2", markerEnd: { type: MarkerType?.ArrowClosed } },
  { id: "2-3", source: "2", target: "3", markerEnd: { type: MarkerType?.ArrowClosed } },
];

const EdgeTypesFlow = (props: any) => {
  const { newWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodess);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdgess);
  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const [showModal, setShowModal] = useState(false);
  const [currentNodeId, setCurrentNodeId] = useState<string | null>(null);

  const onModalToggle = useCallback((nodeId?: string) => {
    if (nodeId) {
      setCurrentNodeId(nodeId);
    }
    setShowModal(!showModal);
  }, [showModal]);

  const formProps = useMemo(() => ({
    newWorkflowData,
    updateWorkflowField,
    actionData,
    functionFilterData,
    typeData,
    fileTypeData
  }), [newWorkflowData, updateWorkflowField, actionData, functionFilterData, typeData, fileTypeData]);

  const updateNodeData = (nodeId: string, key: string, value: any) => {
    setNodes((nds) =>
      nds?.map((node) =>
        node?.id === nodeId
          ? { ...node, data: { ...node?.data, [key]: value } }
          : node
      )
    );
  };

  const nodeTypes = useMemo(() => ({
    textUpdater: (props: any) => <TextUpdaterNode {...props} onClick={() => onModalToggle(props?.id)} />
  }), [onModalToggle]);

  const onAddNode = () => {
    const newNodeId = (nodes?.length + 1)?.toString();
    const lastNode = nodes[nodes?.length - 1];
    const newNodePositionY = lastNode?.position?.y + 250;
    const newNode: any = {
      id: newNodeId,
      data: {
        step: `Step-${newNodeId}`,
        label: `Name: Step-${newNodeId}`,
        description: `This is step ${newNodeId}`,
        additionalInfo: "Additional Info",
      },
      sourcePosition: "bottom",
      targetPosition: "top",
      position: { x: 100, y: newNodePositionY },
      type: "textUpdater",
    };

    const newEdge = {
      id: `${lastNode.id}-${newNodeId}`,
      source: lastNode.id,
      target: newNodeId,
      markerEnd: { type: MarkerType?.ArrowClosed }
    };
    setEdges((eds: any) => addEdge(newEdge, eds));
    setNodes((nds) => [...nds, newNode]);
  };

  return (
    <>
      <div className="add-workflow-react">
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          nodeTypes={nodeTypes}
          fitView
        >
          <MiniMap />
          <Background />
          <Controls />
          <Panel>
            <button onClick={onAddNode}>Add</button>
          </Panel>
        </ReactFlow>
        <Modal showModal={showModal} onModalToggle={onModalToggle} formData={formProps} nodeId={currentNodeId} steps={`Step-${currentNodeId}`} updateNodeData={updateNodeData}
        />
      </div>
    </>
  );
};

export default EdgeTypesFlow;
