// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card.grid .card-contents {
  border-top: 1px solid var(--border-color);
  padding: 0 0 var(--content-padding);
}`, "",{"version":3,"sources":["webpack://./src/styles/finops/RevenueAnalysisCard.scss"],"names":[],"mappings":"AAEA;EACE,yCAAA;EACA,mCAAA;AADF","sourcesContent":["// @use '../../../variables.scss' as *;\n\n.card.grid .card-contents {\n  border-top: 1px solid var(--border-color);\n  padding: 0 0 var(--content-padding);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
