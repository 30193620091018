import React, { useEffect, useRef } from "react";

import TextBox, { Button as TextBoxButton } from "devextreme-react/text-box";
import Validator, { RequiredRule } from "devextreme-react/validator";
import "../../../../styles/ui-components/FormTextbox.scss";

type TextboxProps = {
  value: string;
  label?: string;
  isEditing?: boolean;
  onValueChange: (data: any) => void;
  mask?: string;
  icon?: string;
  placeholder?: string;
};

export const FormTextbox = ({
  value,
  label,
  placeholder,
  isEditing,
  onValueChange,
  mask = "",
  icon,
  children,
}: React.PropsWithChildren<TextboxProps>) => {
  const validatorRef = useRef<any>(null);
  const textBoxRef = useRef<any>(null);
  useEffect(() => {
    if (validatorRef.current) {
      validatorRef.current.instance.reset(); // Reset validation
    }
    if (textBoxRef.current) {
      textBoxRef.current.instance.reset(); // Reset TextBox value
    }
  }, []);

  return (
    <TextBox
      key="text-box"
      ref={textBoxRef}
      label={label}
      value={value}
      mask={mask}
      placeholder={placeholder}
      readOnly={isEditing}
      elementAttr={{ class: "form-editor" }}
      inputAttr={{ class: "form-editor-input" }}
      stylingMode="filled"
      onValueChange={onValueChange}
    >
      {icon && (
        <TextBoxButton
          name="icon"
          location="before"
          options={{
            icon: icon,
            stylingMode: "text",
            elementAttr: { class: "form-editor-icon" },
          }}
        />
      )}
      <Validator ref={validatorRef}>
        <RequiredRule />
        {children}
      </Validator>
    </TextBox>
  );
};
