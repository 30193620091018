import { Item } from "devextreme-react/toolbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import { customDateRange, dropDownOptions } from "../../../config/constants";
import "../../../styles/client/project-form.scss";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { useNavigate } from "react-router-dom";
import PortfolioDashboard from "./helper-component/portfolio-dashboard/portfolio";
import {
  useFetchopexCard,
  useFetchAllProject,
  useProjects,
} from "../../../hooks/client/useProject";
import ClientInfo from "./helper-component/clientInformation/clientInfoV2";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import ShareUsers from "../../../assets/images/icons/icon-add-users-1.png";
import collabration from "../../../assets/images/icons/icon.collabration.png";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import OpexDashboard from "./helper-component/opex-dashboard/opexDashboard";
import ManagementDashboard from "./helper-component/management-dashboard/management";
import { useAppHook } from "../../../hooks/app/useApps";
import { projectViewDropdown } from "../../../config/client/commonConfig";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { replaceQueryUrl } from "../../../utlis/helper";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

export const Projects = (props: any) => {
  const { showToolbar, gridData, showExtrenalDataSource, height, filteredClientId, selectedDays: parentSelectedDays } = props;
  const [selectedDays, setSelectedDays] = useState({});
  const { data: DateRange } = useFetchDateRange();
  const Period = useHelperHooks().getQueryParams("Period");

  const mergedSelectedDays = parentSelectedDays || selectedDays;

  const {
    state: {
      projetView,
      pageCount,
      pageSize,
      pageIndex,
      sortOrder,
      sortColumn,
    },
    filterByProjectView,
    handlePageIndexChange,
  } = useProjects();
  const {
    state: utilsState,
    state: { projectName, projectId, clientId},
    clientDropDownData,
    projectDropDownData,
    filterByClient,
    filterByProject,
    gridRef,
  } = useAppHook();

 const clientfiltered = filteredClientId ? filteredClientId : clientId;

  const { client, opexParams } = utilsState;
  const {
    data: projectRecords,
    isFetching, 
    refetch,
    hasNextPage,
    fetchNextPage,
  } = useFetchAllProject(
    clientfiltered,
    projectId,
    mergedSelectedDays,
    pageSize,
    sortOrder,
    sortColumn,
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (Period) {
      if (Period.includes("startTime")) {
        const [startTime, endTime] = Period.split("&");
        setSelectedDays({ startTime, endTime, timePeriod: customDateRange });
      } else {
        setSelectedDays({ timePeriod: Period });
      }
    }
  }, [Period]);

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
        replaceQueryUrl("period", `startTime=${startTime}&endTime=${endTime}`);
      } else {
        setSelectedDays({ timePeriod });
        replaceQueryUrl("period", timePeriod);
      }
      const eventName = `date_range : ${timePeriod}_${"Projects"}`;
      GTAButtonEventHandler(eventName);
    },
    []
  );

  const {
    data: opexCardData,
    isSuccess,
    isLoading: isOpexLoading,
  } = useFetchopexCard(opexParams);

  const currentView = projectViewDropdown.find(
    (item) => item.value === projetView
  )?.name;

  const AdditionalToolbarContent = (
    <Item
      location="after"
      locateInMenu="auto"
      cssClass="toolbar-item-flex toolbar-item-label-buton"
    >
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={client}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
      />
      <DropDownButton
        stylingMode="text"
        text={projectName ? projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={dropDownOptions}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
      />
      <DropDownButton
        items={projectViewDropdown}
        stylingMode="text"
        displayExpr={"name"}
        text={projetView ? currentView : "Select View"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByProjectView}
      />
      <DateDropDown onDateChange={onCurrentValueChange} data={DateRange} />
      <PSIconText
        text="New Project"
        src={NewIcon}
        alt="New Project"
        onClick={() => navigate("/add-projects")}
        eventName="New Project Clicked"
      />
      <PSIconText
        text="Share"
        alt="Project-Share item with users "
        src={ShareUsers}
        onClick={() => onclick}
        eventName="Project-Share item with users"
      />
      <PSIconText
        alt="Collaboration"
        text="Collaboration"
        src={collabration}
        onClick={() => onclick}
        eventName="Project-Collaboration"
      />
    </Item>
  );

  const DataGridProps = useMemo(() => {
    return {
      gridData: showExtrenalDataSource ? gridData : projectRecords,
      selectedClient: client,
      showToolbar: false,
      selectedDays,
      gridRef,
      height: height ? height : "height-large",
      searchvisible: true,
      handlePageIndexChange,
      fetchNextPage,
      hasNextPage,
      pageSize,
      pageCount,
      pageIndex,
    };
  }, [
    showExtrenalDataSource,
    gridData,
    projectRecords,
    client,
    selectedDays,
    gridRef,
    height,
    handlePageIndexChange,
    fetchNextPage,
    hasNextPage,
    pageSize,
    pageCount,
    pageIndex,
  ]);
  const PageBodyComponent = useMemo(
    () => [
      {
        mode: "grid",
        Component: ClientInfo,
        componentProps: DataGridProps,
      },
      {
        mode: "opex",
        Component: OpexDashboard,
        componentProps: {
          opexCardData: opexCardData,
          isOpexLoading,
          isSuccess,
          client,
        },
      },
      {
        mode: "management",
        Component: ManagementDashboard,
        componentProps: DataGridProps,
      },
      {
        mode: "portfolio",
        Component: PortfolioDashboard,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps, opexCardData, isOpexLoading, isSuccess, client]
  );

  const layoutProps = {
    headerText: "Projects",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent,
    layoutView: projetView,
    isLoading: isFetching,
    showToolbar,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
};
