// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.analytics-ticker {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 100px;
  text-align: center;
  padding: 10px; /* Add padding if needed */
}
.analytics-ticker .Heading {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: max-content;
  margin-bottom: 8px;
}
.analytics-ticker .Overall {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: var(--base-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/TickerCard.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,aAAA,EAAA,0BAAA;AAAF;AAEE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ;AAGE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,6BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,0BAAA;AADJ","sourcesContent":["\n.analytics-ticker {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  width: 120px; \n  height: 100px; \n  text-align: center;\n  padding: 10px; /* Add padding if needed */\n\n  .Heading {\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 20px;\n    width: max-content;\n    margin-bottom: 8px;\n  }\n\n  .Overall {\n    font-size: 20px;\n    font-weight: 600;\n    line-height: 24px;\n    color: var(--base-text-color);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-decoration: underline;\n  }\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
