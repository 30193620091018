import { Popup } from "devextreme-react";
import { useFetchWorkflowStepDataList } from "../../../../../hooks/app/useApps";
import { transformExecutionStorageData } from "./types";
import { TreeView } from "devextreme-react";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Scrolling,
} from "devextreme-react/data-grid";
import { cellStartTime, formatDate } from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../config/constants";
import { useFetchSelectedExecutionFileData } from "../../../../../hooks/app/useApps";
import PsButton from "../../../../ui-components/button/Button";
import MyFilesView from "../../../../../assets/icons/icon-eye.png";
import { useEffect, useState } from "react";
import FileViewer from "../../../../ui-components/fileViewer/FileViewer";
import TelemetryPopup from "../telemetry/Telemetry";
import { useFetchMetricsDataApi } from "../../../../../hooks/activity-monitor/useLogsMetrics";

export const Modal = (props: any) => {
  const { showModal, onModalToggle, popupData, popupType, params, stepId } = props;
  const telemetryProps = {
    executionId: params?.executionId,
    manifestId: params?.manifestId,
    taskName: params?.taskName,
    executionName: params?.executionName,
    clientShortName: params?.clientShortName,
    projectShortName: params?.projectShortName,
    projectId: params?.projectId
  };
  const metricsProps = {
    executionName: params?.executionName,
    clientShortName: params?.clientShortName,
    projectShortName: params?.projectShortName,
    projectId: params?.projectId
  };
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const [selectedParams, setSelectedParams] = useState<any>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [showTelemetryModal, setShowTelemetryModal] = useState(false);
  const { data: datalist, isLoading: isDataListLoading } = useFetchWorkflowStepDataList(selectedParams);
  const finalData = datalist ? transformExecutionStorageData(datalist) : [];
  const { data: fileData, isLoading: isFileLoading } = useFetchSelectedExecutionFileData(selectedFileData);
  const { data: metricsGridData, isLoading: isMetricsLoading } =useFetchMetricsDataApi(metricsProps);

  const onHiding = () => {
    setPopupVisible(false);
  };

  useEffect(() => {
    if (popupType?.startsWith("telemetry-")) {
      setShowTelemetryModal(true);
    }
    if (popupType?.startsWith("metrics-")) {
      setPopupVisible(true);
    }
    if (showModal && popupType?.startsWith("step-")) {
      const param = {
        executionName: params?.executionName,
        stepId: popupType,
        manifestId: params?.manifestId,
        folder: 'Input',
      };
      setSelectedParams(param);
    }
    else if (showModal && popupType?.startsWith("data-")) {
      const type = popupType?.replace(/^data/, 'step');
      const param = {
        executionName: params?.executionName,
        stepId: type,
        manifestId: params?.manifestId,
        folder: 'Input',
      };
      setSelectedParams(param);
    }
    else if (showModal && popupType?.startsWith("output-")) {
      const type = popupType?.replace(/^output/, 'step');
      const param = {
        executionName: params?.executionName,
        stepId: type,
        manifestId: params?.manifestId,
        folder: 'Output',
      };
      setSelectedParams(param);
    }
  }, [showModal, popupType, params, popupData]);

  const taskRender = (popupData: any) => {
    if (!popupData) return <div>Loading...</div>;
    return (
      <table className="popup-table-info">
        <tbody>
          <tr>
            <td className="popup-table-heading">Task</td>
            <td className="popup-table-value">{popupData?.taskName}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Client</td>
            <td className="popup-table-value">{popupData?.clientName}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Project</td>
            <td className="popup-table-value">{popupData?.projectName}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Project Type</td>
            <td className="popup-table-value">{popupData?.projectType}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Max Container Group</td>
            <td className="popup-table-value">{popupData?.maxContainerGroup}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Container Per Group</td>
            <td className="popup-table-value">{popupData?.containersPerGroup}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Container Size</td>
            <td className="popup-table-value">{popupData?.containerSize}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const actionRender = (popupData: any) => {
    if (!popupData) return <div>Loading...</div>;
    return (
      <table className="popup-table-info">
        <tbody>
          <tr>
            <td className="popup-table-heading">Name</td>
            <td className="popup-table-value">{popupData?.name}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Description</td>
            <td className="popup-table-value">{popupData?.description}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Functions</td>
            <td className="popup-table-value">{popupData?.functions}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Status</td>
            <td className="popup-table-value">{popupData?.status}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">Start Time</td>
            <td className="popup-table-value">{formatDate(popupData?.startTime)}</td>
          </tr>
          <tr>
            <td className="popup-table-heading">End Time</td>
            <td className="popup-table-value">{formatDate(popupData?.endTime)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const onViewScrapingButtonClick = (e: any, cell: any) => {
    const { data } = cell;
    setSelectedFileData(data);
    setPopupVisible(!popupVisible);
  };

  const renderAction = (cell: any) => {
    return (
      <PsButton
        hint="MyFiles"
        icon={MyFilesView}
        mode="text"
        onClick={(e) => onViewScrapingButtonClick(e, cell)}
        eventName='workflow files click event'
      />
    );
  };

  const renderItem = (item: any) => {
    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? (
          <DataGrid
            dataSource={item?.data}
            rowAlternationEnabled={false}
            showBorders={true}
            noDataText={isFileLoading ? "Loading..." : "No files to display at this time"}
            allowColumnResizing={true}
          >
            <Column caption="File Name" dataField="filename" dataType="string" />
            <Column caption="Uploaded on" dataField="createdDate" dataType="string" cellRender={cellStartTime} />
            <Column caption="File Size" dataField="fileSize" dataType="string" />
            <Column caption="" type="buttons" width={180} cellRender={renderAction} />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Paging defaultPageSize={5} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        ) : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  const treeView = () => {
    if (isDataListLoading) return <div>Loading...</div>;
    if (!finalData || finalData.length === 0) return <div>No data to display at this time.</div>;
    return (
      <div>
        <TreeView
          id="simple-treeview"
          items={finalData}
          itemRender={renderItem}
        />
        <FileViewer
          visible={popupVisible}
          onHiding={onHiding}
          data={
            selectedFileData?.fileExtension === "pdf"
              ? fileData?.data
              : JSON?.stringify(fileData?.data)
          }
          title={title}
          isLoading={isFileLoading}
          extensionType={selectedFileData?.fileExtension}
        />
      </div>
    );
  };

  const telemetryView = () => {
    return (
      <div>
        <TelemetryPopup
          selectedExecution={telemetryProps}
          showTelemetryModal={showTelemetryModal}
          onRowClick={() => setShowTelemetryModal(false)}
          workflowStepId={stepId}
        />
      </div>
    );
  };

  const metricsView = () => {
    return (
      <div>
        <FileViewer
          visible={popupVisible}
          onHiding={onHiding}
          data={JSON?.stringify(metricsGridData?.data)}
          title={''}
          isLoading={isMetricsLoading}
          extensionType={'json'}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!showModal) return <div>Loading...</div>;
    if (popupType === "task") return taskRender(popupData);
    if (popupType?.startsWith("step-") || popupType?.startsWith("data-") || popupType?.startsWith("output-")) return treeView();
    if (popupType?.startsWith("action-")) return actionRender(popupData);

    return <div>No data to display at this time.</div>;
  };

  const titles =
    popupType?.startsWith("step-")
      ? `${popupType?.charAt(0)?.toUpperCase() + popupType?.slice(1)} Input Details`
      : popupType?.startsWith("output-")
        ? `${popupType?.replace(/^output/, "Step")} Output Details`
        : popupType?.startsWith("data-")
          ? `${popupType?.replace(/^data/, "Step")} Input Details`
          : `${popupType?.charAt(0)?.toUpperCase() + popupType?.slice(1)} Details`;

  if (popupType === "function" || popupType === "status") {
    return null;
  }

  if (popupType?.startsWith("telemetry-")) {
    if (!showModal) return null;
    return telemetryView();
  }

  if (popupType?.startsWith("metrics-")) {
    if (!showModal) return null;
    return metricsView();
  }

  return (
    <Popup
      visible={showModal}
      onHiding={onModalToggle}
      dragEnabled={false}
      width={1200}
      height={600}
      title={titles}
    >
      <div className="grid-container-estimator">
        {renderContent()}
      </div>
    </Popup>
  );
};
