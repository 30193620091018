import { useCallback, useReducer } from "react";
import { createWorkflowApi, fetchWorkflowApi, fetchManifestDataApi, fetchWorkflowFunctionDropdown } from "../../service/task/api-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { WorkflowState } from "../../pages/task/workflow/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { formatDate } from "devextreme/localization";

const initialState: WorkflowState = {
  popupVisible: false,
};

const reducer = (state: WorkflowState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useWorkflow = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const onAddWorkflowOpen = useCallback((data: any) => {
    dispatch({ type: "POPUP_VISIBLE", payload: data });
  }, []);

  return {
    state,
    onAddWorkflowOpen
  };
};

export const useFetchWorkflowFunctionDropdown = () => {
  return useQuery("fetch-workflow-function-dropdown", fetchWorkflowFunctionDropdown, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data?.data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useCreateWorkflow = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(createWorkflowApi, {
    onSuccess: () => {
      showNotification({
        message: "Workflow created successfully",
        type: "success",
      });
      fnQueryClient.invalidateQueries("fetch-workflow");
      fnQueryClient.invalidateQueries("fetch-manifest-data");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchWorkflowApi = (selectedDays: any, clientId: any, projectId: any) => {
  const startTime = selectedDays?.startTime ? formatDate(selectedDays?.startTime, "yyyy-MM-dd") : null;
  const endTime = selectedDays?.endTime ? formatDate(selectedDays?.endTime, "yyyy-MM-dd") : null;
  const timePeriod = selectedDays?.timePeriod;
  const queryParams = {
    startTime,
    endTime,
    timePeriod,
    clientId,
    projectId,
  };

  return useQuery(
    ["fetch-workflow", clientId, projectId, startTime, endTime, timePeriod],
    () => fetchWorkflowApi(queryParams),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    });
};

export const useFetchManifestData = (manifestId: string) => {
  return useQuery(
    ["fetch-manifest-data", manifestId],
    () => fetchManifestDataApi(manifestId),
    {
      enabled: !!manifestId,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};