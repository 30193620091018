import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Form, { SimpleItem, GroupItem, ColCountByScreen } from 'devextreme-react/form';
import { FormTextbox } from '../../../ui-components/form/form-textbox/FormTextbox';
import { getSizeQualifier } from "../../../../utlis/media-query";
import { InfrastructureData } from '../../../../types/infrastructure-add';
import { SelectBox } from 'devextreme-react';
import { resourceTypeOptions,typeOptions} from '../../../../config/constants';

export const InfrastructureEditDetails = ({ 
    editing, 
    data, 
    onDataChanged 
}: {
  editing: boolean, 
  data: InfrastructureData, 
  onDataChanged: (data: any) => void
}) => {
  const [formData, setFormData] = useState<InfrastructureData>({
    ...data,
    resource_name: data?.resource ? data?.resource.split(' ').slice(0, -1).join(' ') : '',
    resource_type: data?.resource ? data?.resource.split(' ').slice(-1).join('') : ''
  });

  useEffect(() => {
    setFormData({
      ...data,
      resource_name: data?.resource ? data?.resource.split(' ').slice(0, -1).join(' ') : '',
      resource_type: data?.resource ? data?.resource.split(' ').slice(-1).join('') : ''
    });
  }, [data]);

  const updateField = (field: string) => (value: any) => {
    const newData = { ...formData, [field]: value };
    if (field === 'resource_name' || field === 'resource_type') {
      newData.resource = `${newData?.resource_name}-${newData?.resource_type}`;
    }
    onDataChanged(newData);
    setFormData(newData);
  };

  
  return (
    <Form
      className={classNames({ 'plain-styled-form task-form-details': true, 'view-mode': !editing })}
      screenByWidth={getSizeQualifier}
    >
      <GroupItem itemType='group'>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <SimpleItem>
          <FormTextbox
            label='Resource Name'
            value={formData?.resource_name}
            isEditing={!editing}
            onValueChange={updateField('resource_name')}
          />
        </SimpleItem>
      

        <SimpleItem>
          <SelectBox
            label='Resource Type'
            value={formData?.type}
            items={typeOptions}
            readOnly={!editing}
            stylingMode='filled'
            onValueChange={updateField('type')}
          />
        </SimpleItem>
        <SimpleItem>
          <SelectBox
            label='Resource Size'
            value={formData?.resource_type}
            items={resourceTypeOptions}
            readOnly={!editing}
            stylingMode='filled'
            onValueChange={updateField('resource_type')}
          />
        </SimpleItem>
        <SimpleItem cssClass='accent'>
          <FormTextbox
            label='Rate'
            value={formData?.cost_per_day}
            isEditing={!editing}
            onValueChange={updateField('cost_per_day')}
          />
        </SimpleItem>
      </GroupItem>
    </Form>
  );
};
