import React, { useEffect, useState } from 'react';
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from 'devextreme-react/form';
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { ClientUrl } from '../../types';
import { SelectBox } from "devextreme-react";
import { useAppHook } from '../../../../../hooks/app/useApps';

export const simpleProductLabel = { "aria-label": "Simple Product" };

const UrlEditForm = ({
  newContactData,
  updateField
}: {
  newContactData: ClientUrl;
  updateField: (data: any) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedClient, setEditedClient] = useState(newContactData);
  const {clientData} = useAppHook();

  const clientNameOptions = clientData?.map((item: any) => item?.name);

  const options = ["LOW", "MEDIUM", "HIGH"];

  const statusOptions = ["Pending", "Development", "QA", "Production", "Disabled"];

  const OnChangeUpdateField = (field: string) => (value: any) => {
    const updatedClient = { ...editedClient, [field]: value };
    setEditedClient(updatedClient);
    updateField(updatedClient);
  };

  useEffect(() => {
    setEditedClient(newContactData);
  }, [newContactData]);

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <SelectBox
            label='Select Client'
            value={editedClient?.clientName}
            items={clientNameOptions}
            stylingMode='filled'
            onValueChange={OnChangeUpdateField('clientName')}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="Client Url :"
            value={editedClient?.url}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("url")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="Note :"
            value={editedClient?.note}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("note")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="URL Template Path :"
            value={editedClient?.url_template_path}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("url_template_path")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="URL Keyword :"
            value={editedClient?.url_keyword}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("url_keyword")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="URL Name :"
            value={editedClient?.url_file_name}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("url_file_name")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <FormTextbox
            label="Driver Wait Time :"
            value={editedClient?.driver_wait_time}
            isEditing={isEditing}
            onValueChange={OnChangeUpdateField("driver_wait_time")}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <SelectBox
            label='Complexity :'
            value={editedClient?.complexity}
            items={options}
            stylingMode='filled'
            onValueChange={OnChangeUpdateField('complexity')}
          />
        </FormItem>
        <FormItem cssClass="accent">
          <SelectBox
            label='Status :'
            value={editedClient?.status}
            items={statusOptions}
            stylingMode='filled'
            onValueChange={OnChangeUpdateField('status')}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default UrlEditForm;