import { allowedPageSizes } from "../../../../config/task/commonConfig";
import ProcessDetails from "./process-Details/ProcessDetails";
import { cellTimeStamp } from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import React, { useMemo } from "react";
import { usePage } from "../../../../contexts/pageContext";

export const InfraStructureDataGrid = React.memo((props: any) => {
  const {
    apiProps,
    handleClientLinkClick,
    handleTaskLinkClick,
    handleProjectLinkClick,
    handleColumnIndexChange,
  } = props;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName: clientColunm,
    projectName: projectColumn,
    taskName: taskNameColumn,
    executionStartTime: executionStartTimeColumn,
  } = filterValue || {};

  const columns = useMemo(
    () => [
      {
        dataField: "clientName",
        caption: "Client",
        filterValues: clientColunm,
        cellRender: (data: any) => {
          const clientName = data?.data?.clientName;
          const clientId = data?.data?.clientId;
          return (
            <a href="null" onClick={(e) => handleClientLinkClick(e, clientId)}>
              {clientName}
            </a>
          );
        },
      },
      {
        dataField: "projectName",
        caption: "Project Name",
        filterValues: projectColumn,
        cellRender: (data: any) => {
          const projectName = data?.data?.projectName;
          const projectId = data?.data?.projectId;
          return (
            <a href="null" onClick={(e) => handleProjectLinkClick(e, projectId)}>
              {projectName}
            </a>
          );
        },
      },
      {
        dataField: "taskName",
        caption: "Task Name",
        filterValues: taskNameColumn,
        cellRender: (data: any) => {
          const TaskName = data?.data?.taskName;
          const taskId = data?.data?.taskId;
          return (
            <a href="null" onClick={(e) => handleTaskLinkClick(e, taskId)}>
              {TaskName}
            </a>
          );
        },
      },
      {
        dataField: "executionStartTime",
        caption: "Executed Time",
        filterValues: executionStartTimeColumn,
        cellRender: cellTimeStamp,
      },
    ],
    [clientColunm, executionStartTimeColumn, handleClientLinkClick, handleProjectLinkClick, handleTaskLinkClick, projectColumn, taskNameColumn]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No Infrastructure to display at this time",
      heightClass: "height-large",
      id:"infraStructureDataGrid",
    }),
    []
  );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "infraId",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      detailComponent: ProcessDetails,
      handleColumnIndexChange
    }),
    [columns,handleColumnIndexChange]
  );

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
    </div>
  );
});
