import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { FormTextbox } from "../../../ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../utlis/media-query";
import { DigitalServicesData } from "../../../../types/digital-services-add";

export const DigitalServicesNewForm = ({
  newDigitalServicesData,
  updateField,
}: {
  newDigitalServicesData: DigitalServicesData;
  updateField: (field: string) => (value: any) => void;
}) => {
  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label="Role"
            placeholder="Role..."
            value={newDigitalServicesData.designation}
            isEditing={false}
            onValueChange={updateField("designation")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Role Description"
            placeholder="Role Description..."
            value={newDigitalServicesData.description}
            isEditing={false}
            onValueChange={updateField("description")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Rate"
            placeholder="Rate..."
            value={
              newDigitalServicesData.cost_per_day.startsWith("$")
                ? newDigitalServicesData.cost_per_day
                : "$" + newDigitalServicesData.cost_per_day
            }
            isEditing={false}
            onValueChange={updateField("cost_per_day")}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};
