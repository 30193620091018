import { TreeView } from "devextreme-react/tree-view";
import { transformProjectStorageData } from "../../../../hooks/client/useProjectProfile";
import { useMemo } from "react";
import { location } from "../../../../config/client/commonConfig";
import { ClientGridComponent } from "./ClientDataGrid";

export function CustomTitle(data: any) {
  return (
    <>
      <div className="header">{data?.title}</div>
    </>
  );
}

export function MaterGridComponent(eventData: any, data: any) {
  const { projectId, projectName } = data;
  const renderItem = (item: any) => {
    const gridProps = {
      ...data,
      ...eventData,
      ...item,
      id: { projectId: projectId },
      location: location[item?.id],
    };
    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? <ClientGridComponent {...gridProps} /> : null}
      </div>
    );
  };

  const transformData = useMemo(() => {
    if (eventData?.clientData?.length > 0) {
      return transformProjectStorageData(
        eventData?.clientData?.[0],
        projectName
      );
    }
    return [];
  }, [eventData, projectName]);

  return (
    <div>
      {eventData?.clientData && (
        <>
          <TreeView
            id="simple-treeview"
            items={transformData}
            itemRender={renderItem}
          />
        </>
      )}
    </div>
  );
}
