import React, { useRef, useCallback } from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import { Template } from "devextreme-react/core/template";
import { UserMenuSection } from "./helper-component/UserMenuSection";
import List from "devextreme-react/list";
import "../../../styles/ui-components/UserPanel.scss";
import { UserPanelProps } from "./types";
import { useApp } from "../../../contexts/app";

export const UserPanel = ({ menuMode }: UserPanelProps) => {
  const listRef = useRef<List>(null);
  const { user } = useApp();
  const dropDownButtonAttributes = {
    class: "user-button",
  };

  const buttonDropDownOptions = {
    width: "auto",
  };

  const dropDownButtonContentReady = useCallback(
    ({ component }:any) => {
      component.registerKeyHandler("downArrow", () => {
        listRef.current?.instance.focus();
      });
    },
    [listRef]
  );

  return (
    <div className="user-panel">
      {menuMode === "context" && (
        <DropDownButton
          stylingMode="text"
          icon={'user'}
          text={user?.name}
          showArrowIcon={false}
          elementAttr={dropDownButtonAttributes}
          dropDownOptions={buttonDropDownOptions}
          dropDownContentTemplate="dropDownTemplate"
          onContentReady={dropDownButtonContentReady}
        >
          <Template name="dropDownTemplate">
            <UserMenuSection listRef={listRef} />
          </Template>
        </DropDownButton>
      )}
      {menuMode === "list" && <UserMenuSection showAvatar />}
    </div>
  );
};
