import "../../../../../styles/client/opexDashboard.scss";
import OpexPivotGrid from "./helper-component/pivotChartGrid";
import OpexRowCard from "./helper-component/opexRowCard";
import { keyMappings, replaceMappings } from "../../../../../config/client/commonConfig";
import { keyToImageMap } from "../../../../../utlis/image";

const OpexDashboard = ({ opexCardData, isOpexLoading, isSuccess, client }: any) => {
  const { data = {} } = opexCardData || {};




  const mapDataToRowCard = (dataArray: any[]) => {
    const order = ["Documents Processed", "Data Processed", "Failed", "Rejected"];
  
    const otherItems = dataArray.filter((item: any) => !order.includes(item?.name));
    const orderedItems = order.map((name) => dataArray.find((item: any) => item?.name === name)).filter(Boolean);
  
    const processedData = [...otherItems, ...orderedItems];
  
    return processedData.map((item: any) => {
      const newName = keyMappings[item?.name] || item?.name;
  
      const secondaryValue = Object.entries(item)
        .filter(([key]) => key !== 'name')
        .map(([key, value]: [string, any]) => {
          let formattedKey = keyMappings[key] || key
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());
  
          if (key === "MOM") value = `(${value})`;
  
          formattedKey = Object.keys(replaceMappings).reduce((acc, replaceKey) => {
            const regex = new RegExp(replaceKey, "i");
            return acc.toLowerCase().includes(replaceKey) ? acc.replace(regex, replaceMappings[replaceKey]) : acc;
          }, formattedKey);
  
          return { name: formattedKey, value };
        });
  
      return {
        name: newName,
        image: keyToImageMap[item?.name],
        primaryValue: item?.count || item?.primaryValue,
        secondaryValue,
      };
    });
  };
  
  

  const rowCardData = [
    ...mapDataToRowCard(data?.webScraping || []),
    ...mapDataToRowCard(data?.document || [])
  ];

  const { success, failed, urls, property_data,rejected,pending,completed,readyForReview,inProgress} = data?.details || {};
  const projectType = data?.projectType ||{};
  const clientId = data?.clientId || "";

  return (
    <div className="opex-dashboard-layout">
      <OpexRowCard opexCardData={rowCardData} isOpexLoading={isOpexLoading} />
         {client === "All Clients" ?  (
        <div className="message-container">Select a client to get started</div>
      ) : (
        <OpexPivotGrid
          successData={success}
          failedData={failed}
          urlsData={urls}
          propertyData={property_data}
          rejectedData={rejected}
          pendingData={pending}
          completedData={completed}
          reviewData={readyForReview}
          inProgressData={inProgress}
          projectType={projectType}
          clientId={clientId}
        />
      )}
    </div>
  );
};

export default OpexDashboard;

