import { Handle, Position } from "@xyflow/react";

const TextUpdaterNode = ({ data }: any) => {
  const { label, title, additionalInfo, onClick } = data;

  return (
    <>
      <div onClick={onClick}>
        <Handle type="target" position={Position?.Left} />
        <div className="NodeWrapper">
          <div className="header_info">
            {label}
          </div>
          <div>
          <span>{title}</span>
          </div>
          <div>
            <span>{additionalInfo}</span>
          </div>
        </div>
        <Handle type="source" position={Position?.Right} />
      </div>
    </>
  );
};

export default TextUpdaterNode;