import EdgeTypesFlow from "./react-flow/data-flow";

const WorkflowSteps = (props: any) => {

  const { data } = props;

  return (
    <>
      <EdgeTypesFlow
        data={data}
      />
    </>
  );
};

export default WorkflowSteps;

