import {
  editCellStatusRender,
  cellNameCombine,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { onExporting } from "../../../../config/constants";
import { useNavigate } from "react-router-dom";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../contexts/pageContext";
import PsTag from "../../../../components/ui-components/tag/Tag";

import React, { useMemo } from "react";

const ClientGrid = React.memo((props: any) => {
  const {
    onRowClick,
    onEditClick,
    data,
    onDeletePopupOpen,
    handlePageIndexChange,
    apiProps,
    handleColumnIndexChange,
    updatePageProperties,
  } = props;
  const navigate = useNavigate();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    name : clientColumn,
    primary_contact : primaryContactColumn,
    primary_email : primaryEmailColumn,
    note: noteColumn,
    status : statusColumn,
    projectCount: projectCountColumn,
    taskCount : taskCountColumn
  } = filterValue || {};

  const handleClientLinkClick = (event: any, clientId: string) => {
    navigate(`/client-profile?clientId=${clientId}`);
    event.preventDefault();
  };

  const handleProjectCountLink = (event: any, clientName : string, clientId : string) => {
     navigate(`/projects?client=${clientName}&id=${clientId}`);
    updatePageProperties("projects", { clientId, t_clientName : clientName, t_projectName: "",projectId: "" });
   event.preventDefault();
}

const handleTaskCountLink = (event : any, clientName : any, clientId : string) => {
  navigate(`/task-automation?client=${clientName}&id=${clientId}`)
  updatePageProperties("task-automation", { clientId, t_clientName : clientName, t_projectName: "",projectId: "" });
  event.preventDefault();
}

const pageProps = useMemo( 
  () => ({
  nodataText: "No clients to display at this time",
  heightClass: "height-large",
  id: "clientgrid",
}), []);

  const columns = useMemo(
    () => [
      {
        dataField: "name",
        caption: "Client",
        filterValues: clientColumn,
        cellRender: (data: any) => {
          const clientName = data?.data?.name;
          const clientId = data?.data?.clientId;
          const roleName = data?.data?.roleName;

          return (
            <>
              <a
                href="null"
                onClick={(e) => handleClientLinkClick(e, clientId)}
              >
                {clientName}
              </a>
              {roleName && (
                <PsTag
                  text={
                    roleName.split(" ").pop()
                      ? roleName.split(" ").pop()
                      : roleName
                  }
                  className="primary"
                />
              )}
            </>
          );
        },
      },
      {
        dataField: "primary_contact",
        caption: "Primary Contact",
        cellRender: cellNameCombine,
        minWidth: 100,
        filterValues: primaryContactColumn,
      },
      {
        dataField: "primary_email",
        caption: "Primary Email",
        minWidth: 100,
        filterValues: primaryEmailColumn,
      },
      {
        dataField: "note",
        caption: "Notes",
        filterValues: noteColumn,
      },
      {
        dataField: "status",
        caption: "Status",
        cellRender: ContactStatus,
        editCellRender: editCellStatusRender,
        width: 100,
        filterValues: statusColumn,
      },
      {
        dataField: "projectCount",
        caption: "Projects Count",
        alignment: "center",
        width: 150,
        filterValues: projectCountColumn,
        cellRender: (data: any) => {
          const projectCount = data?.data?.projectCount;
          const clientname = data?.data?.name;
          const clientId = data?.data?.clientId;
          return projectCount > 0 ? (
            <a
              href="*"
              onClick={(e) => {
                handleProjectCountLink(e, clientname, clientId);
              }}
            >
              {projectCount}
            </a>
          ) : (
            <span>-</span>
          );
        },
      },
      {
        dataField: "taskCount",
        caption: "Tasks Count",
        alignment: "center",
        width: 150,
        filterValues: taskCountColumn,
        cellRender: (data: any) => {
          const taskCount = data?.data?.taskCount;
          const clientName = data?.data?.name;
          const clientId = data?.data?.clientId;
          return taskCount > 0 ? (
            <a
              href="*"
              onClick={(e) => {
                handleTaskCountLink(e, clientName, clientId);
              }}
            >
              {taskCount}
            </a>
          ) : (
            <span>-</span>
          );
        },
      },
    ],
    [
      clientColumn,
      primaryContactColumn,
      primaryEmailColumn,
      noteColumn,
      statusColumn,
      projectCountColumn,
      taskCountColumn,
      handleClientLinkClick,
      handleProjectCountLink,
      handleTaskCountLink,
    ]
  );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "clientId",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      onRowEdit: onEditClick,
      onRowDelete: onDeletePopupOpen,
      handleColumnIndexChange,
    }),
    [columns, handleColumnIndexChange]
  );

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
      </div>
    </>
  );
});

export default ClientGrid;
