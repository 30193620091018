import { Handle, Position } from "@xyflow/react";
import "./style.css";
import { Modal } from "./FlowPopup";
import { useState } from "react";

const TextUpdaterNode = ({ data }: any) => {
  const { label, description, additionalInfo } = data;
  const [showModal, setShowModal] = useState(false);

  const onModalToggle = () => {
    setShowModal(!showModal);
  };

  return (
    <div onClick={onModalToggle}>
      <Handle type="target" position={Position.Left} />
      <div className="NodeWrapper">
        <div className="header_info">
          <span>{label}</span>
          <span>Description:{description}</span>
        </div>
        <hr />
        <div>
          <span>{additionalInfo}</span>
        </div>
      </div>
      <Handle type="source" position={Position.Right} id="a" />
      {/* <Handle
        type="source"
        position={Position.Bottom}
        id="b"
        style={handleStyle}
      /> */}
      <Modal showModal={showModal} onModalToggle={onModalToggle}  />
    </div>
  );
};

export default TextUpdaterNode;
