import { useCallback, useState } from "react";
import TemplateEditForm from "./template-form/TemplateEditForm";
import { Template } from "../types";
import { useCreateTemplate } from "../../../../../hooks/digital-service/useTemplate";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";

const EditTemplate = (props: any) => {

  const {
    editpopupVisible,
    changeEditPopupVisibility,
    selectedTemplateData,
    getTemplateTypeList
  } = props;
  const [formData, setData] = useState<Template>();
  const { row } = selectedTemplateData || {};
  const { data } = row || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility()
  }, [changeEditPopupVisibility]);

  const { mutate, isLoading } = useCreateTemplate(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    if (formData) {
      const formDataUpdated = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        formDataUpdated.append(key, value);
      });
      mutate(formDataUpdated);
    }
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<Template | undefined>) => {
      setData(data);
    }, []
  );

  return (
    <FormPopup
      title="Edit Template"
      visible={editpopupVisible}
      setVisible={changeEditPopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
      fullScreen={true}
    >
      <TemplateEditForm
        subjectField={!!data}
        data={data}
        editing={true}
        onDataChanged={onDataChanged}
        getTemplateTypeList={getTemplateTypeList}
      />
    </FormPopup>
  )
}
export default EditTemplate;