import { useMemo, useState } from "react";
import ClientGrid from "./helper-component/clientgrid";
import NewClient from "./helper-component/newclient";
import EditClient from "./helper-component/editClient";
import DeleteClient from "./helper-component/deleteclient";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/toolbar";
import { SearchPanel } from "devextreme-react/data-grid";
import { filterStatusList } from "../../../config/client/commonConfig";
import { dropDownOptions } from "../../../config/constants";
import { useClientCatlog } from "../../../hooks/client/useClientCatlog";
import { useAppHook } from "../../../hooks/app/useApps";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import { fetchClientCatalogApi } from "../../../service/client/api-service";
import { usePage } from "../../../contexts/pageContext";
import { usePermission } from "../../../contexts/permissions";

export const ClientCatalog = () => {
  const {
    state: {
      popupVisible,
      selectedClient,
      deletePopupVisible,
      EditpopupVisible,
      pageCount,
      pageIndex,
      pageSize,
    },
    onRowClick,
    onEditClick,
    onAddContactClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    changeEditPopupVisibility,
    handleDropDownPageIndex,
    handlePageIndexChange,
  } = useClientCatlog();

  const [refreshKey, setRefreshKey] = useState(0);
  const { getPageProperties, updatePageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    status: p_status,
  } = filterValue || {};

  const {
    state: { statusName },
    filterByClient,
    filterByStatus,
    clientDropDownData,
    gridRef,
    handleColumnIndexChange,
  } = useAppHook();

  const { currentPermission } = usePermission();

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const additionalParams = useMemo(() => {
    const status = statusName;
    return {
      clientId: p_clientId,
      status,
    };
  }, [p_clientId, statusName]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchClientCatalogApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const DataGridProps = useMemo(() => {
    return {
      onRowClick,
      onEditClick,
      onAddContactClick,
      onDeletePopupOpen,
      gridRef,
      pageCount,
      pageIndex,
      pageSize,
      handlePageIndexChange,
      apiProps,
      handleColumnIndexChange,
      updatePageProperties,
    };
  }, [
    gridRef,
    onAddContactClick,
    onDeletePopupOpen,
    onEditClick,
    onRowClick,
    pageCount,
    pageIndex,
    pageSize,
    handlePageIndexChange,
    apiProps,
    handleColumnIndexChange,
    updatePageProperties,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const editProps = useMemo(() => {
    return {
      selectedClient,
      EditpopupVisible,
      changeEditPopupVisibility,
    };
  }, [selectedClient, EditpopupVisible, changeEditPopupVisibility]);

  const deleteProps = useMemo(() => {
    return {
      selectedClient,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedClient, deletePopupVisible, onDeletePopupClose]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={filterStatusList}
        stylingMode="text"
        text={p_status ? p_status : "All Items"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      {currentPermission?.canCreate && (
        <PSIconText
          src={NewIcon}
          text="Add Client"
          alt="Add Client"
          onClick={onAddContactClick}
        />
      )}
      <SearchPanel placeholder="Client Search" />
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: ClientGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "Client Catalog",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewClient {...FormProps} />
      <EditClient {...editProps} />
      <DeleteClient {...deleteProps} />
    </div>
  );
};
