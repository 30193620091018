// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-icon {
  height: 100% !important;
}
.top-icon .dx-button-content {
  display: grid;
  justify-items: center;
  gap: 3px;
  color: black;
}

.dx-button {
  height: 100% !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/button.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;AAAJ;AACI;EACI,aAAA;EACA,qBAAA;EACA,QAAA;EACA,YAAA;AACR;;AAEA;EACI,uBAAA;AACJ","sourcesContent":["\n.top-icon{\n    height: 100% !important;\n    .dx-button-content{\n        display: grid;\n        justify-items: center;\n        gap: 3px;\n        color: black;\n    }\n}\n.dx-button{\n    height: 100% !important;\n}\n// .dx-state-hover{\n//     background-color: unset !important;\n// }\n// .dx-state-active{\n//     background-color: unset !important;\n// }\n// .dx-state-focused{\n//     background-color: unset !important;\n// }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
