// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pivotGrid-chart {
  margin-top: 20px;
}

.pivot-grid-cell {
  font-weight: bold;
  text-align: center !important;
  color: #0f6cbd !important;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/PivotGridWithChart.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAKA;EACI,iBAAA;EACA,6BAAA;EACA,yBAAA;EACA,eAAA;AAFJ","sourcesContent":["#pivotGrid-chart{\n    margin-top: 20px;\n\n}\n\n\n\n.pivot-grid-cell {\n    font-weight: bold;\n    text-align: center !important;\n    color: #0f6cbd !important; \n    cursor: pointer;\n  }  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
