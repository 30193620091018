import WebScrapingImage from '../assets/images/icons/icon-html-2.png';
import URLProcessedImage from '../assets/images/icons/icon-url-1.png';
import ExecutionSummaryImage from '../assets/images/icons/icon-processing-data-1.png';
import DataProcessed from '../assets/images/icons/icon-page-layout-1.png';
import DocumentProcessingImage from '../assets/images/icons/icon-processing-2.png';
import DocumentProcessedImage from '../assets/images/icons/icon-processing-1.png';
import Pending from '../assets/images/icons/icon-pending.png.png';
import Development from '../assets/images/icons/icon-development.png.png';
import Production from '../assets/images/icons/icon-production.png.png';
import QA from '../assets/images/icons/icon-qa.png.png';
import SuccessMetrics from '../assets/images/icons/icon-sucesstask.png.png';
import failedmetrics from '../assets/images/icons/icon-failed.png.png';
import activity from '../assets/images/icons/icon-activity-1.png';
import reject from '../assets/images/icons/reject.png';



export const keyToImageMap: { [key: string]: string } = {
    'Web Scrapping Projects': WebScrapingImage,
    'URLs Processed': URLProcessedImage,
    'Execution Summary': ExecutionSummaryImage,
    'Process Analytics': DataProcessed,
    'Document Processing Projects': DocumentProcessingImage,
    'Documents Processed': DocumentProcessedImage,
    'Pending': Pending,
    'Development':Development,
    'Production':Production,
    "QA":QA,
    "Successful Execution":SuccessMetrics,
    "Failed Execution":failedmetrics,
    "Total URL's Processed":URLProcessedImage,
    "Data Processed":DataProcessed,
    "Failed":failedmetrics,
    "Completed":SuccessMetrics,
    "Ready for Review":activity,
    "In Progress":DocumentProcessingImage,
    "Rejected":reject,
  };
