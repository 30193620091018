import React, { createContext, useContext, useState } from "react";

type Permission = {
  canView: boolean;
  canCreate: boolean;
  canUpdate: boolean;
  canDelete: boolean;
};

type PermissionContextType = {
  currentPermission: Permission | undefined;
  setCurrentPermission: (permissions: Permission | undefined) => void;
};

const PermissionContext = createContext<PermissionContextType | undefined>(
  undefined
);

export const PermissionProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentPermission, setCurrentPermission] = useState<
    Permission | undefined
  >(undefined);

  return (
    <PermissionContext.Provider
      value={{ currentPermission, setCurrentPermission }}
    >
      {children}
    </PermissionContext.Provider>
  );
};

export const usePermission = (): PermissionContextType => {
  const context = useContext(PermissionContext);
  if (!context) {
    throw new Error("usePermission must be used within a PermissionProvider");
  }
  return context;
};
