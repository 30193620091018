const SystemInfoTab = () => {

  return (
    <>
      <div className="widget-container">
        <div className='systemInfoPage'>
          <p>System Info Tab is currently under construction. Check back later!</p>
        </div>
      </div>
    </>
  );
}

export default SystemInfoTab;