import React, { useEffect, useState } from "react";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { EmailRule } from "devextreme-react/validator";
import { Client } from "../../types";
import { TextArea } from "devextreme-react";
import { generateShortName } from "../../../../../utlis/helper";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";

interface ClientNewFormProps {
  newContactData: Client;
  updateField: (field: string) => (value: any) => void;
}

const ClientNewForm: React.FC<ClientNewFormProps> = ({
  newContactData,
  updateField,
}) => {
  const [isShortNameManuallyEdited, setIsShortNameManuallyEdited] = useState(false);

  useEffect(() => {
    if (newContactData.name && !isShortNameManuallyEdited) {
      const shortName = generateShortName(newContactData.name);
      updateField("clientShortName")(shortName); 
    }
  }, [newContactData.name, isShortNameManuallyEdited, updateField]);

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <FormTextbox
            label="Organization Name"
            value={newContactData.name}
            isEditing={false}
            onValueChange={(value) => {
              updateField("name")(value);
              setIsShortNameManuallyEdited(false); 
            }}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Short Name"
            value={newContactData.clientShortName}
            isEditing={false}
            onValueChange={(value) => {
              updateField("clientShortName")(value);
              setIsShortNameManuallyEdited(true); 
            }}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Address 1"
            value={newContactData.address1}
            isEditing={false}
            onValueChange={updateField("address1")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Address 2"
            value={newContactData.address2}
            isEditing={false}
            onValueChange={updateField("address2")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="City"
            value={newContactData.city}
            isEditing={false}
            onValueChange={updateField("city")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="State"
            value={newContactData.state.stateShort}
            isEditing={false}
            onValueChange={updateField("state")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            value={newContactData.email}
            onValueChange={updateField("email")}
            isEditing={false}
            label="Email"
            icon="email"
          >
            <EmailRule />
          </FormTextbox>
        </FormItem>
        <FormItem>
          <FormTextbox
            label="First Name"
            value={newContactData.firstName}
            isEditing={false}
            onValueChange={updateField("firstName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Last Name"
            value={newContactData.lastName}
            isEditing={false}
            onValueChange={updateField("lastName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            value={newContactData.phone}
            isEditing={false}
            onValueChange={updateField("phone")}
            icon="tel"
            label="Phone"
            mask="+1(000)000-0000"
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Primary Email"
            value={newContactData.primary_email}
            isEditing={false}
            onValueChange={updateField("primary_email")}
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Notes"
            value={newContactData.note}
            onValueChange={updateField("note")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Special Instructions"
            value={newContactData.specialInstructions}
            onValueChange={updateField("specialInstructions")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
      </GroupItem>
      <GroupItem cssClass="contact-fields-group">
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <TextArea
            label="Important Links and URLs"
            value={newContactData.important_links_and_urls}
            onValueChange={updateField("important_links_and_urls")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="Technology Integration requirements"
            value={newContactData.technology_integration_requirements}
            onValueChange={updateField("technology_integration_requirements")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="KPIs"
            value={newContactData.kpis}
            onValueChange={updateField("kpis")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
        <FormItem>
          <TextArea
            label="KeyMetrics and Reporting Frequency"
            value={newContactData.keyMetrics_and_reporting_frequency}
            onValueChange={updateField("keyMetrics_and_reporting_frequency")}
            height={"100px"}
            width={"100%"}
            stylingMode="filled"
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default ClientNewForm;
