import "../../styles/core-components/staticinfo.scss";
import logos from "../../assets/images/shore-logo-1.png";

const StaticInfo = () => {
  return (
    <div id="static-info">
      <div className="logo-wrapper">
        <img
          src={logos}
          alt="Logo"
          className="polarisLogo"
          style={{ width: "45px", height: "45px" }}
        />
        <span>Polaris</span>
      </div>
      <h1>Registration Pending Review</h1>
      <div>
        <ul>
          <li>
            Thank you for registering with us! We're currently reviewing your
            information to ensure everything is in order.
          </li>
          <li>
            Your account is almost ready to go! You'll receive an email
            notification once your registration has been approved and your
            account is activated.
          </li>
          <li>
            If you have any questions in the meantime, feel free to reach out to
            our support team.
          </li>
          <li>Thank you for your patience and welcome aboard!</li>
        </ul>
      </div>
    </div>
  );
};

export default StaticInfo;
