import URLCatalog from "../../assets/media/background-banner-1.jpg";
import TemplateLibrary from "../../assets/media/background-digital-12.jpg";
import TasksAutomation from "../../assets/media/background-digital-17.jpg";
import AlertsExceptions from "../../assets/media/background-digital-6.jpg";
import DataLake from "../../assets/media/background-digital-5.jpg";
import Tutorials from "../../assets/media/background-digital-4.jpg";
import Reference from "../../assets/media/background-digital-22.jpg";

export const WebscrapingServiceMap = [
  {
    id: 1,
    name: "URL Catalog",
    image: URLCatalog,
    description: [
      "Manage all client URL development and deployment from a single interface. Provides quick view of the developemnt workflow in a Kanban view.",
    ],
    navigation:'/url-catalog?action=true'
  },
  {
    id: 2,
    name: "Template Library",
    image: TemplateLibrary,
    description: [
      "Repository of vaious Web Scraping templates for common use cases. Provides a starting point for new projects and tasks.",
    ],
    navigation:'/template-library?action=true'
  },
  {
    id: 3,
    name: "Tasks & Automation",
    image: TasksAutomation,
    description: [
      "Command center for managing all web scraping tasks and automating data extraction. Provides real-time monitoring and alerts.",
    ],
    navigation:'/task-automation?action=true'
  },
  {
    id: 4,
    name: "Alerts & Exceptions",
    image: AlertsExceptions,
    description: [
      "Get realtime updates on scraping tasks, exceptions, and alerts. Manage exceptions and errors from a single interface. Provides access to full telemetry and error logs.",
    ],
     navigation:'/alert-exceptions?action=true'
  },
  {
    id: 5,
    name: "Client Data Lake",
    image: DataLake,
    description: [
      "Access to all client data within the Polaris Data Lake. Provides data storage, retrieval, and analysis capabilities.",
    ],
     navigation:'/data-lake-management?action=true'
  },
];

export const WebscrapingGuides = [
  {
    id: 1,
    image: Tutorials,
    name: "Tutorials",
    description: [
      "Tutorials and guides for new users to get started with web scraping. Provides step-by-step instructions for setting up and running scraping tasks.",
    ],
    url: "",
  },
  {
    id: 2,
    name: "Reference documents",
    image: Reference,
    description: [
      "Reference documents for advanced users. Provides detailed information on web scraping best practices, tools, and techniques.",
    ],
    url: "https://www.google.com",
  },
];
