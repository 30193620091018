import React from "react";
import { useNavigate } from "react-router-dom";
import { useCallback, useMemo, useState } from "react";
import PsDataGrid from "../../../../../../components/ui-components/dataGrid/DataGrid";
import ManagementDetailView from "./managemnetComponent";
import { ContactStatus } from "../../../../../../components/ui-components/contact-status/ContactStatus";
import { cellStartTime } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/client/commonConfig";
import { usePage } from "../../../../../../contexts/pageContext";

const ManagementPage =React.memo(({ apiProps, handleColumnIndexChange }: any) => {
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const navigate = useNavigate();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};

  const {
    clientName: clientColunm,
    projectName: projectColumn,
    projectDescription: projectDiscriptionColumn,
    status: statusColumn,
    projectType: projectTypeColumn,
    timestamp: timeStarted,
  } = filterValue || {};

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string, clientName: any) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleRowExpanding = useCallback(
    (e: any) => {
      const projectId = e.key;
      if (projectId !== expandedRowId) {
        setExpandedRowId(projectId);
      }
    },
    [expandedRowId]
  );

  const columns = useMemo(
    () => [
      {
        dataField: "clientName",
        caption: "Client",
        filterValues: clientColunm,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) =>
              handleClientLinkClick(
                e,
                data?.data?.clientId,
                data?.data?.clientName
              )
            }
          >
            {data?.data?.clientName}
          </a>
        ),
      },
      {
        dataField: "projectName",
        caption: "Project Name",
        filterValues: projectColumn,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId)}
          >
            {data?.data?.projectName}
          </a>
        ),
      },
      { dataField: "projectDescription", caption: "Project Description", filterValues: projectDiscriptionColumn },
      { dataField: "projectType", caption: "Project Type",filterValues: projectTypeColumn },
      { dataField: "status", caption: "Status", cellRender: ContactStatus,filterValues: statusColumn },
      {
        dataField: "startDate",
        caption: "Start Date",
        cellRender: cellStartTime,
        filterValues: timeStarted,
      },
    ],
    [handleClientLinkClick, handleProjectLinkClick, clientColunm, projectColumn, projectDiscriptionColumn, projectTypeColumn, statusColumn, timeStarted]
  );

  const gridProps = useMemo(
    () => ({
      keyExpr: "projectId",
      columns,
      pagingEnabled: true,
      pagerEnabled: true,
      allowedPageSizes: allowedPageSizes,
      scrolling: { mode: "standard" },
      searchPanelVisible: true,
      headerFilterVisible: true,
      detailComponent: ManagementDetailView,
      onRowExpanding: handleRowExpanding,
      handleColumnIndexChange,
    }),
    [columns, handleRowExpanding, handleColumnIndexChange]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No Projects to display at this time",
      heightClass: "height-large",
      id: "managementgrid",
    }),
    []
  );

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
    </div>
  );
});

export default ManagementPage;
