import { useCallback, useEffect, useState } from "react";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { InfrastructureData } from "../../../../../../types/infrastructure-add";
import { newInfrastructureFormData } from "../../../../../../config/constants";
import React from "react";
import { InfrastructureNewForm } from "../../../../../../components/finops/library/infrastructure-form/InfrastructureNewForm";
import { useCreateInfrastructure } from "../../../../../../hooks/finops/useRatecard";

const InfrastructureFormPopup = (props: any) => {
  const { popupVisible, changePopupVisibility } = props;

  const [newInfrastructureData, setNewInfrastructureData] =
    useState<InfrastructureData>({
      ...newInfrastructureFormData,
    });

  const updateField = (field: string) => (value: any) => {
    setNewInfrastructureData({
      ...newInfrastructureData,
      ...{ [field]: value },
    });
  };

  useEffect(() => {
    if (!popupVisible) {
      setNewInfrastructureData({ ...newInfrastructureFormData });
    }
  }, [popupVisible]);
  
  const { mutate, isLoading } = useCreateInfrastructure(
    changePopupVisibility
  );

  const transformedData = React.useMemo(() => {
    return {
      id: newInfrastructureData.id,
      resource: `${newInfrastructureData.resource_name} - ${newInfrastructureData.resource_type}`,
      cost_per_day: newInfrastructureData.cost_per_day,
      type: newInfrastructureData.type,
      description: "",
    };
  }, [newInfrastructureData]);

  const handleFormSubmit = useCallback(() => {
    mutate(transformedData);
  }, [mutate, transformedData]);

  const onResourceTypeChanged = (e: string) => {
    setNewInfrastructureData({
      ...newInfrastructureData,
      ...{ resource_type: e },
    });
  };

  const onTypeChanged = (e: string) => {
    setNewInfrastructureData({
      ...newInfrastructureData,
      ...{type: e}
    });
  };

  if (typeof window === "undefined") return null;
  return (
    <FormPopup
      title="Add New Infrastructure Resource"
      visible={popupVisible}
      setVisible={changePopupVisibility}
      onSave={handleFormSubmit}
      isLoading={isLoading}
    >
      <InfrastructureNewForm
        newInfrastructureData={newInfrastructureData}
        onResourceTypeChanged={onResourceTypeChanged}
        onTypeChanged={onTypeChanged}
        updateField={updateField}
      />
    </FormPopup>
  );
};

export default InfrastructureFormPopup;
