import { FileUploader } from "devextreme-react";
import { fileExtensions } from "../../../../config/datastudio/commonConfig";
import { AddFilesProps } from "../types";
import { useCallback } from "react";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";

const Addfiles = (props: AddFilesProps) => {
  const { handleFilesUpload, isFileUploading } = props;

  const renderUploadFile = useCallback(() => {
    return (
      <>
        <div className="container-1">
          <h6>Unlock the power of your data with Data Analyzer. </h6>
          <article>
            Simply upload your CSV or JSON files, and our intuitive tool will
            help you explore, understand, and transform your information with
            ease.
            <ul>
              <li>
                Effortless Data Transformation: Clean, filter, sort, aggregate,
                and visualize your data without writing a single line of code.
              </li>
              <li>
                Customizable Rules & Functions: Apply your own business logic
                and leverage advanced data science functions to gain deeper
                insights.
              </li>
              <li>
                Automated Data Reconciliation: Identify and resolve
                discrepancies in your data automatically, saving you time and
                ensuring accuracy.
              </li>
            </ul>
          </article>
        </div>
        <div className="container-2">
          {!isFileUploading ? (
            <FileUploader
              uploadMode="useForm"
              onValueChanged={handleFilesUpload}
              allowedFileExtensions={fileExtensions}
              allowCanceling={true}
            />
          ) : (
            <PsSkeleton />
          )}
          <span className="note">
            {"Allowed file extensions: "}
            <span>.csv, .json, .xlsx, .xls, .xml, .txt</span>
          </span>
        </div>
      </>
    );
  }, [handleFilesUpload, isFileUploading]);

  return (
    <div className="add-file-wrapper height-large">{renderUploadFile()}</div>
  );
};

export default Addfiles;
