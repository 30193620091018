import Skeleton from "react-loading-skeleton";

const PsSkeleton = ({
  count,
  height,
  width,
  key
}: {
  count?: number;
  height?: any;
  width?: any;
  key?: any;
}) => {
  return (
    <div>
      <Skeleton count={count} height={height} width={width} key={key} />
    </div>
  );
};

export default PsSkeleton;
