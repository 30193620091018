import Popup from "devextreme-react/popup";
import TreeView from "devextreme-react/tree-view";
import "../../../../../styles/client/telemetry.scss";
import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import { LoadPanel } from "devextreme-react";
import RenderTreeViewItem from "./helper-component/TreeViewItems";
import { useFetchTaskExecutionDetailsById } from "../../../../../hooks/app/useApps";
import { Item } from "devextreme-react/toolbar";
import PsButton from "../../../../ui-components/button/Button";
import { ToolbarAnalytics } from "../../../../ui-components/toolbar-analytics";
import { useQueryClient } from "react-query";

const TelemetryPopup = (props: any) => {
  const { selectedExecution, showTelemetryModal, onRowClick, workflowStepId } = props;

  const treeViewRef = useRef<TreeView>(null);
  const fnQueryClient = useQueryClient();
  const [index, setIndex] = useState<number>(0);

  useEffect(() => {
    setIndex(Date.now());
  }, []);

  const { data, isLoading } =
    useFetchTaskExecutionDetailsById(selectedExecution, workflowStepId);

  const { source = [], finalItemsId = [] } = data || {};

  const focusNode = useCallback(() => {
    const nodeId =
      finalItemsId?.length > 0 ? finalItemsId[finalItemsId?.length - 1] : null;
    const treeView = treeViewRef.current?.instance;
    if (treeView && nodeId) {
      const nodeElement = treeView
        .element()
        .querySelector(`[data-item-id="${nodeId}"]`);
      nodeElement?.scrollIntoView({
        block: "end",
      });
    }
  }, [finalItemsId]);

  useEffect(() => {
    if (showTelemetryModal && finalItemsId?.length > 0) {
      setTimeout(focusNode, 100);
    }
  }, [showTelemetryModal, finalItemsId, focusNode]);

  useEffect(() => {
    if (showTelemetryModal) {
      const popupContent = document?.querySelector(
        `.file-viewer-popup-${index} .dx-popup-content`
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        `.file-viewer-popup-${index} .dx-popup-content-scrollable`
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [showTelemetryModal, index]);

  const renderTreeViewItem = (item: any) => {
    return (
      <RenderTreeViewItem {...item} selectedExecution={selectedExecution} />
    );
  };

  const onExpandAllNodes = () => {
    treeViewRef.current?.instance?.expandAll();
  };
  const onCollapseAllNodes = () => {
    treeViewRef.current?.instance?.collapseAll();
  };

  const renderTreeView = () => {
    if (isLoading) {
      return <LoadPanel visible={true} />;
    }
    return (
      <TreeView
        id="treeview"
        ref={treeViewRef}
        keyExpr="id"
        items={source}
        itemRender={renderTreeViewItem}
      />
    );
  };

  const refresh = useCallback(() => {
    fnQueryClient.invalidateQueries("fetch-task-execution-ById");
    fnQueryClient.invalidateQueries("fetch-exception-data");
    fnQueryClient.invalidateQueries("fetch-execution-data-list");
  }, [fnQueryClient]);

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PsButton
          className="telementryButton"
          icon="refresh"
          type="normal"
          mode="contained"
          width={"auto"}
          onClick={refresh}
          eventName="TelementryPopupButton refresh event"
        />
        <PsButton
          className="telementryButton"
          icon="chevrondown"
          type="normal"
          mode="contained"
          width={"auto"}
          onClick={onExpandAllNodes}
          eventName="TelementryPopupButton expand"
        />
        <PsButton
          className="telementryButton"
          icon="chevronup"
          type="normal"
          mode="contained"
          width={"auto"}
          onClick={onCollapseAllNodes}
          eventName="TelementryPopupButton collapse"
        />
        <PsButton
          className="telementryPopupButton"
          icon="close"
          type="normal"
          mode="contained"
          width={"auto"}
          onClick={onRowClick}
          eventName="TelementryPopupButton close"
        />
      </Item>
    );
  }, [onRowClick, refresh]);

  return (
    <div>
      <Popup
        visible={showTelemetryModal}
        onHiding={onRowClick}
        fullScreen={true}
        width={1000}
        height={"auto"}
        showCloseButton={false}
        showTitle={false}
        className={`file-viewer-popup-${index}`}
      >
        <div className="view-wrapper">
          <ToolbarAnalytics
            title={`Telemetry View | ${selectedExecution?.taskName}`}
            showToolbar={true}
            additionalToolbarContent={AdditionalToolbarContent}
          />
          <div className="telemetry-popup">{renderTreeView()}</div>
        </div>
      </Popup>
    </div>
  );
};

export default TelemetryPopup;
