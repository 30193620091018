import { IconProps } from "./types";
import "../../../styles/ui-components/IconText.scss";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const PSIconText = (props: IconProps) => {
  const {
    src,
    alt,
    className = "IconText",
    width = 35,
    height = 35,
    onClick,
    text,
    disabled = false,
    id,
    hint,
    eventName = "icon Clicked",
  } = props;

  const onButtonClick = (e: any) => {
    if (onClick) {
      onClick(e);
      GTAButtonEventHandler(eventName);
    }
  };
  return (
    <div className={`IconText-container ${disabled ? "disabled" : ""}`}>
      <img
        src={src}
        alt={alt}
        className={`${className} ${disabled ? "disabled" : ""}`}
        width={width}
        height={height}
        onClick={disabled ? undefined :  onButtonClick}
        id={id}
        title={hint}
      />
      <span className="text-size">{text}</span>
    </div>
  );
};

export default PSIconText;
