import { useCallback, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Position,
  NodeTypes,
} from "@xyflow/react";
import CustomNode from "./CustomNode";
import { DayDateFormat } from "../../utlis/helper";

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

const nodeProperties = [
  {
    label: "Profile",
    dataField: "clientShortName",
  },
  {
    label: "PDF",
    dataField: "pdf",
  },
  {
    label: "Di",
    dataField: "di",
  },
  {
    label: "Data",
    dataField: "data",
  },
  {
    label: "Process",
    dataField: "processed",
  },
  {
    label: "Extract",
    dataField: "extracted",
  },
];

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const DocumentCatlogLineage = (props: any) => {
  const { data, assetType } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const transformedObject = useCallback(() => {
    return (
      Array.isArray(data) &&
      data?.reduce((acc: any, item: any) => {
        acc.clientShortName = item.clientShortName;
        acc.projectShortName = item.projectShortName;
        acc.taskShortName = item.taskShortName;
        acc.projectId = item.projectId;
        acc.createdBy = item.createdBy;
        acc.createdDate = item.createdDate;
        acc[item.assetType] = {
          assetType: item.assetType,
          fileType: item.fileType,
          type: item.type,
          fileName: item.fileName,
          fileSize: item.fileSize,
        };
        return acc;
      }, {})
    );
  }, [data]);

  const filterObjectByAssetType = useCallback(
    (obj: any) => {
      const result: any = {};
      let shouldKeep = true;

      for (const key in obj) {
        if (shouldKeep) {
          result[key] = obj[key];
        }
        if (key === assetType) {
          shouldKeep = false;
        }
      }

      return result;
    },
    [assetType]
  );

  const renderTitleNode = (item: any) => {
    return (
      <ul style={{ listStyle: "none" }}>
        <li>Client: {item?.clientShortName}</li>
        <li>Project: {item?.projectShortName}</li>
        <li>Task: {item.taskShortName}</li>
      </ul>
    );
  };

  useEffect(() => {
    if (data) {
      const values = transformedObject();
      const nodevalues = filterObjectByAssetType(values);
      const newNodes: any = nodeProperties
        .map((property, index) => {
          const { label, dataField } = property;
          if (!nodevalues[dataField]) return null;
          const title =
            label === "Profile" ? renderTitleNode(nodevalues) : null;
          return {
            id: `node-${dataField}-${index}`,
            type: "custom",
            position: { x: index * 300, y: 150 },
            data: {
              label: nodevalues[dataField]?.fileType || label,
              title:
                title ||
                nodevalues[dataField]?.fileName ||
                nodevalues[dataField],
              additionalInfo: (
                <ul style={{ listStyle: "none" }}>
                  <li>Created By: {nodevalues["createdBy"] || "N/A"}</li>
                  <li>
                    Updated Date:{" "}
                    {DayDateFormat(nodevalues["createdDate"]) || "N/A"}
                  </li>
                </ul>
              ),
            },
            ...nodeDefaults,
          };
        })
        .filter(Boolean);

      const newEdges = [];
      for (let i = 0; i < newNodes.length - 1; i++) {
        newEdges.push({
          id: `${newNodes[i].id}-${newNodes[i + 1].id}`,
          source: newNodes[i].id,
          target: newNodes[i + 1].id,
        });
      }

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [data, filterObjectByAssetType, setEdges, setNodes, transformedObject]);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const nodeTypes: NodeTypes = {
    custom: CustomNode,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default DocumentCatlogLineage;
