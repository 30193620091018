import { useMemo } from "react";
import NewTemplate from "./helper-component/newTemplate";
import DeleteTemplate from "./helper-component/deleteTemplate";
import TemplateGrid from "./helper-component/templateGrid";
import EditTemplate from "./helper-component/editTemplate";
import TemplateClone from "./helper-component/template-clone/templateClone";
import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/cjs/data-grid";
import { dropDownOptions } from "../../../../config/constants";
import { filterStatusList } from "../../../../config/client/commonConfig";
import DataGridMultiViewLayout from "../../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import {
  useFetchSpecificTemplate,
  useFetchTemplateFunctionDropdown,
  useTemplate,
} from "../../../../hooks/digital-service/useTemplate";
import { useAppHook } from "../../../../hooks/app/useApps";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import NewIcon from "../../../../assets/images/icons/icon-add-item-1.png";
import { usePage } from "../../../../contexts/pageContext";
import { fetchTemplateApi } from "../../../../service/digital-service/api-service";
import { useLocation } from "react-router-dom";

export const TemplateLibrary = () => {
  const {
    state: {
      popupVisible,
      selectedTemplateData,
      editpopupVisible,
      deletePopupVisible,
      clonePopupVisible,
      selectedTemplateId,
      selectedTemplateShow,
      refreshKey,
      selectedData,
      openLineage,
    },
    onAddTemplateClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditTemplateClick,
    onClonePopupOpen,
    onClonePopupClose,
    changeEditPopupVisibility,
    handleRowClick,
    onHandleTemplatePopupClose,
    setRefreshKey,
    setOpenLineage,
    setSelectedData
  } = useTemplate();

  const {
    filterByStatus,
    filterByFunction,
    getFunctionTypeList,
    gridRef,
  } = useAppHook();

  const location = useLocation();
  const currentPermission = useMemo(() => location?.state?.currentPermission || {}, [location?.state]);

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    status: p_status,
    functions: p_functions
  } = filterValue || {};

  const handleRefetch = () => {
    setRefreshKey((prevKey: any) => prevKey + 1);
  };
 
  const { data: dataTemplateJson, isLoading: isTemplateLoading } = useFetchSpecificTemplate(selectedTemplateId?.template_id);
  const { data: templateFunction } = useFetchTemplateFunctionDropdown("function");
  const getTemplateTypeList = templateFunction?.[0]
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const additionalParams = useMemo(() => {
    return {
      status: p_status === "All Items" ? "" : p_status,
      templateFunction: p_functions === "All Functions" ? "" : p_functions,
    };
  }, [p_status, p_functions]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchTemplateApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const DataGridProps = useMemo(() => {
    return {
      onEditTemplateClick,
      onAddTemplateClick,
      onDeletePopupOpen,
      onClonePopupOpen,
      onClonePopupClose,
      handleRowClick,
      gridRef,
      dataTemplateJson,
      showPopup: selectedTemplateShow,
      onHandleTemplatePopupClose,
      apiProps,
      isTemplateLoading,
      selectedData,
      openLineage,
      setOpenLineage,
      setSelectedData,
      selectedTemplateId
    };
  }, [
    onEditTemplateClick,
    onAddTemplateClick,
    onDeletePopupOpen,
    onClonePopupOpen,
    onClonePopupClose,
    handleRowClick,
    gridRef,
    dataTemplateJson,
    selectedTemplateShow,
    onHandleTemplatePopupClose,
    apiProps,
    isTemplateLoading,
    selectedData,
    openLineage,
    setOpenLineage,
    setSelectedData,
    selectedTemplateId
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
      getTemplateTypeList
    };
  }, [changePopupVisibility, popupVisible, getTemplateTypeList]);

  const TemplateEditProps = useMemo(() => {
    return {
      selectedTemplateData,
      editpopupVisible,
      changeEditPopupVisibility,
      getTemplateTypeList
    };
  }, [selectedTemplateData, editpopupVisible, changeEditPopupVisibility, getTemplateTypeList]);

  const TemplateDeleteProps = useMemo(() => {
    return {
      selectedTemplateData,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [deletePopupVisible, onDeletePopupClose, selectedTemplateData]);

  const TemplateCloneProps = useMemo(() => {
    return {
      selectedTemplateData,
      clonePopupVisible,
      onClonePopupClose,
    };
  }, [selectedTemplateData, onClonePopupClose, clonePopupVisible]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass='toolbar-item-flex'>
      <DropDownButton
        items={filterStatusList || ["Loading..."]}
        stylingMode="text"
        text={p_status ? p_status : "All Items"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
      />
      <DropDownButton
        items={getFunctionTypeList || ["Loading..."]}
        stylingMode="text"
        text={p_functions ? p_functions : "All Functions"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByFunction}
      />
      { currentPermission?.canCreate &&
      <PSIconText
        text="Add Template"
        alt="Add Template"
        src={NewIcon}
        onClick={onAddTemplateClick}
      />}
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: TemplateGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "Template Library",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewTemplate {...FormProps} />
      <EditTemplate {...TemplateEditProps} />
      <DeleteTemplate {...TemplateDeleteProps} />
      <TemplateClone {...TemplateCloneProps} />
    </div>
  );
};
