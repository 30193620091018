import React, { useEffect, useMemo, useRef } from "react";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Legend,
  Size,
  Tooltip,
} from "devextreme-react/chart";
import PivotGrid, { FieldChooser } from "devextreme-react/pivot-grid";
import PivotGridRef from "devextreme-react/pivot-grid";
import ChartRef from "devextreme-react/pivot-grid";
import { PsPviotGridProps } from "./types";
import "../../../styles/ui-components/PivotGridWithChart.scss";

const PivotWithChart = (props: PsPviotGridProps) => {
  const { id, dataSource: data, customizeTooltip, legendAlignment,onCellClick } = props;
  const { verticalAlignment = "top", horizontalAlignment = "center" } =
    legendAlignment || {};

  const dataSource = useMemo(
    () =>
      new PivotGridDataSource({
        ...data,
        fields: data.fields.map((field) => ({
          ...field,
          expanded: true, 
        })),
      }),
    [data]
  );

  const chartRef = useRef<ChartRef | any>(null);
  const pivotGridRef = useRef<PivotGridRef | any>(null);

  useEffect(() => {
    if (pivotGridRef.current && chartRef.current) {
      const pivotGridInstance = pivotGridRef.current.instance;
      if (pivotGridInstance) {
        pivotGridInstance.bindChart(chartRef.current.instance, {
          dataFieldsDisplayMode: "splitPanes",
          alternateDataFields: false,
        });
      }
    }
  }, [dataSource]);

  return (
    <React.Fragment>
      <Chart className="chart-container" ref={chartRef}>
        <Size height={400} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        <CommonSeriesSettings type="bar" />
        <AdaptiveLayout width={400} />
      </Chart>
    
    
      <PivotGrid
        id={id + " pivotGrid-chart"}
        dataSource={dataSource}
        allowSortingBySummary={true}
        allowFiltering={true}
        showBorders={true}
        showColumnTotals={true}
        showColumnGrandTotals={true}
        showRowTotals={true}
        showRowGrandTotals={true}
        ref={pivotGridRef}
        onCellClick={onCellClick}
        onCellPrepared={(e: any) => {
          if (e?.area === "data") {
            e.cellElement?.classList.add("pivot-grid-cell");
          }
        }}
      >
        <FieldChooser enabled={true} height={400} />
      </PivotGrid>
    </React.Fragment>
  );
};

export default PivotWithChart;

