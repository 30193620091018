import Card from "../../../ui-components/card/card"; 
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
  ValueAxis,
  Legend,
  Tooltip,
} from "devextreme-react/chart";
import { useFetchProjectProfileGraph } from "../../../../hooks/client/useProjectProfile";
import { convertToUSD } from "../../../../utlis/helper";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useHelperHooks } from "../../../../hooks/helper/utils";

export const ExpensesChartCard = (props: any) => {
  const { selectedDays } = props;
  const projectId = useHelperHooks().getQueryParams("projectId");

  const { data: totalCost, isFetching } = useFetchProjectProfileGraph(projectId as string, selectedDays);

  const totalCostData = [totalCost];
  const chartData = totalCostData?.map((client: any) => ({
    projectName: client?.projectName,
    digitalServices:
      parseInt(
        client?.totalCost
          ?.find((item: any) => item?.name === "Digital Services")
          ?.value.replace("$", "")
      ) || 0,
    operations:
      parseInt(
        client?.totalCost
          ?.find((item: any) => item?.name === "Operations")
          ?.value.replace("$", "")
      ) || 0,
    infrastructure:
      parseInt(
        client?.totalCost
          ?.find((item: any) => item?.name === "Infrastructure")
          ?.value.replace("$", "")
      ) || 0,
  }));

  return (
    <Card className="card_wrapper">
      {isFetching ? (
        <PsSkeleton height={300} />
      ) : (
        <div className="chart-project-column">
        <Chart id="expensesChart" dataSource={chartData}>
          <CommonSeriesSettings
            argumentField="projectName"
            type="bar"
            ignoreEmptyPoints={true}
          />
          <Series valueField="digitalServices" name="Digital Services" />
          <Series valueField="operations" name="Operations" />
          <Series valueField="infrastructure" name="Infrastructure" />
          <ArgumentAxis
            title="Resources"
            label={{
              font: { size: 5 },
            }}
          />
          <ValueAxis
            title="Cost"
            label={{
              font: { size: 5 },
            }}
          />
          <Tooltip
            enabled={true}
            customizeTooltip={(info: any) => {
              return {
                text: `${info.argumentText}\n${info.seriesName}:  ${convertToUSD(
                  info.valueText
                )}`,
              };
            }}
          />
          <Legend verticalAlignment="top" horizontalAlignment="center" />
        </Chart>
        </div>
      )}
    </Card>
  );
};

export default ExpensesChartCard;
