// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recent-activity-grid {
  background: white;
  border-radius: 4px;
}
.recent-activity-grid .dx-datagrid {
  padding: 10px;
}
.recent-activity-grid .alert-exception-header {
  font-size: 15px;
  font-weight: 800;
  color: black !important;
  text-decoration: none;
}
.recent-activity-grid .grid-container {
  padding: 5px;
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/core-components/alertException.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;AACF;AAAE;EACE,aAAA;AAEJ;AAAA;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;EACA,qBAAA;AAEF;AACA;EACE,YAAA;EACA,uBAAA;AACF","sourcesContent":[".recent-activity-grid {\n  background: white;\n  border-radius: 4px;\n  .dx-datagrid{\n    padding: 10px;\n  }\n.alert-exception-header{\n  font-size: 15px;\n  font-weight: 800;\n  color: black!important;\n  text-decoration: none;\n}\n\n.grid-container {\n  padding: 5px;\n  background-color: white;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
