import DataGrid, { Scrolling, Paging, Pager } from "devextreme-react/data-grid";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { onExporting } from "../../../../config/constants";
import AddManualDataEntryGrid from "../details/workbookItemGrids/ManualEntryGrid/ManualEntryGrid";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";

const WorkbookAddedItemsGrid = (props: any) => {
  const { data, onRowClick, gridRef, manualEntryProps } = props;
  const {
    editPopupVisible,
    workBookAssetType,
    dataGridPdfFiles,
    isPdfLoading,
    changeEditPopupVisibility,
  } = manualEntryProps;

  const columns = [
    "assetType",
    "fileName",
    "filePath",
    "fileType",
    "fileDescription",
    "uploadedOn",
  ];
  return (
    <>
      <DataGrid
        className="grid theme-dependent height-large"
        noDataText={"Click add items to create your workbook items"}
        dataSource={data}
        onExporting={onExporting}
        allowColumnReordering
        showBorders
        onRowClick={onRowClick}
        ref={gridRef}
        columns={columns}
      >
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      {workBookAssetType === "Manual Data Entry" ? (
        <AddManualDataEntryGrid {...manualEntryProps} />
      ) : null}
      {workBookAssetType === "Add Files" ? (
        <FileViewer
          visible={editPopupVisible}
          onHiding={changeEditPopupVisibility}
          data={dataGridPdfFiles}
          title={"PDF Viewer"}
          isLoading={isPdfLoading}
          extensionType={"pdf"}
        />
      ) : null}
    </>
  );
};

export default WorkbookAddedItemsGrid;
