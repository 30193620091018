import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ArgumentAxis,
  ValueAxis,
  Tooltip,
} from "devextreme-react/chart";
import { useFetchTotalCost } from "../../../hooks/app/useApps";
import { convertToUSD } from "../../../utlis/helper";
import "../../../styles/client/client-dashboard.scss";
import ChartBarSkeleton from "../../ui-components/skeleton/ChartBarSkeleton";

const SummaryChart = (props: any) => {
  const { selectedDays } = props;
  const { data: totalCost, isFetching } = useFetchTotalCost(selectedDays);
  const totalCostData = totalCost?.client_info;

  // Map and compute the total for each client
  const chartData = totalCostData?.map((client: any) => {
    const digitalServices =
      parseFloat(
        client?.totalCost
          ?.find((item: any) => item?.name === "Digital Services")
          ?.value.replace("$", "")
      ) || 0;
    const operations =
      parseFloat(
        client?.totalCost
          ?.find((item: any) => item?.name === "Operations")
          ?.value.replace("$", "")
      ) || 0;
    const infrastructure =
      parseFloat(
        client?.totalCost
          ?.find((item: any) => item?.name === "Infrastructure")
          ?.value.replace("$", "")
      ) || 0;

    // Calculate total
    const total = digitalServices + operations + infrastructure;

    return {
      clientName: client?.clientName,
      digitalServices,
      operations,
      infrastructure,
      total, 
    };
  });

  const sortedChartData = chartData?.sort((a:any, b:any) => b.total - a.total);

  if (isFetching) return <ChartBarSkeleton />;

  return (
    <Chart id="chart" palette="Ocean" dataSource={sortedChartData}>
      <CommonSeriesSettings
        argumentField="clientName"
        type="bar"
        ignoreEmptyPoints={true}
      />
      <Series valueField="digitalServices" name="Digital Services" />
      <Series valueField="operations" name="Operations" />
      <Series valueField="infrastructure" name="Infrastructure" />
      <ArgumentAxis title="Client" />
      <ValueAxis title="Cost" />
      <Tooltip
        enabled={true}
        customizeTooltip={(info: any) => {
          return {
            text: `${info.argumentText}\n${info.seriesName}: ${convertToUSD(
              info.valueText
            )}`,
          };
        }}
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
    </Chart>
  );
};

export default SummaryChart;
