import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { ClientUrl } from "../../types";
import { SelectBox } from "devextreme-react";
import { options } from "../../types";
import { useFetchTemplate } from "../../../../../hooks/client/useClientUrl";
import { useFetchClient } from "../../../../../hooks/app/useApps";
export const minimumSearchLengthLabel = {
  "aria-label": "Minumum Search length",
};
export const searchTimeoutLabel = { "aria-label": "Search Timeout" };
export const searchExpressionLabel = { "aria-label": "Search Expression" };
export const searchModeLabel = { "aria-label": "Search Mode" };
export const productLabel = { "aria-label": "Product" };
export const simpleProductLabel = { "aria-label": "Simple Product" };

const UrlNewForm = ({
  newContactData,
  updateField,
  onOptionChanged,
  onComplexityChanged,
  onStatusChanged,
  onTemplatePathChanged,
}: {
  newContactData: ClientUrl;
  updateField: (field: string) => (value: any) => void;
  onOptionChanged: (e: string) => void;
  onComplexityChanged: (e: string) => void;
  onStatusChanged: (e: string) => void;
  onTemplatePathChanged: (e: string) => void;
}) => {
  const { data } = useFetchClient();
  const { data: templateLibrary } = useFetchTemplate();

  const templatePathOptions = templateLibrary?.map(
    (item: any) => item.template_path
  );

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <SelectBox
            label="Select Client"
            dataSource={data}
            displayExpr="name"
            valueExpr="clientId" 
            value={newContactData.clientId} 
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            onValueChange={(event: any) => onOptionChanged(event)}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="URL"
            value={newContactData.url}
            isEditing={false}
            onValueChange={updateField("url")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Notes"
            value={newContactData.note}
            isEditing={false}
            onValueChange={updateField("note")}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            label="URL Template Path"
            dataSource={templatePathOptions}
            displayExpr={(item) => item}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            valueExpr={(item) => item}
            value={newContactData.url_template_path} 
            placeholder="Select a Template Path..."
            onValueChange={(event: any) => onTemplatePathChanged(event)}
          ></SelectBox>
        </FormItem>
        <FormItem>
          <FormTextbox
            label="URL Keyword"
            value={newContactData.url_keyword}
            isEditing={false}
            onValueChange={updateField("url_keyword")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="URL Name"
            value={newContactData.url_file_name}
            isEditing={false}
            onValueChange={updateField("url_file_name")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            label="Driver Wait Time"
            value={newContactData.driver_wait_time}
            isEditing={false}
            onValueChange={updateField("driver_wait_time")}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            label="complexity"
            dataSource={options}
            displayExpr={(item) => item}
            searchEnabled={true}
            valueExpr={(item) => item}
            value={newContactData.complexity} 
            inputAttr={simpleProductLabel}
            onValueChange={(event: any) => onComplexityChanged(event)}
          ></SelectBox>
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default UrlNewForm;
