import { useFetchTaskInfo } from "../../../../hooks/app/useApps";
import InvoiceInformations from "../../../core-component/invoiceComponent/invoiceInformation";

const FinopsTab = (props: any) => {
  const { taskData } = props;
  const clientId = taskData?.[0]?.clientId;
  const projectId = taskData?.[0]?.projectId;
  const { data: invoiceData, isLoading } = useFetchTaskInfo(clientId,projectId);
  return <InvoiceInformations clientInfo={invoiceData} isLoading={isLoading} />;
};

export default FinopsTab;
