import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  DataGridTypes,
  Pager,
} from "devextreme-react/data-grid";
import PsAccordion from "../../ui-components/accordion/accordion";
import { CustomItem, CustomTitle } from "./invoiceComponent";
import ScrollView from "devextreme-react/scroll-view";
import Popup from "devextreme-react/popup";
import { CellDollar, cellStartTime } from "../../ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../ui-components/contact-status/ContactStatus";
import "../../../styles/core-components/invoiceInformation.scss";
import { Item } from "devextreme-react/toolbar";
import PsButton from "../../ui-components/button/Button";
import { ToolbarAnalytics } from "../../ui-components/toolbar-analytics";
import { allowedPageSizes } from "../../../config/constants";

const InvoiceInformations = (props: any) => {
  const { clientInfo, isLoading } = props;
  const clientInfoData = clientInfo?.[0]?.billDate;
  const [popupVisible, setPopupVisible] = useState(false);
  interface InvoiceData {
    invoiceId: string;
  }
  
  const [selectedRowData, setSelectedRowData] = useState<InvoiceData | null>(null);

  const handleRowClick = (e: any) => {
    setSelectedRowData(e.data);
    setPopupVisible(true);
  };

  const closePopup = useCallback(() => {
    setPopupVisible(false);
    setSelectedRowData(null);
  }, []);

  const detailRenderTask = (detail: DataGridTypes.MasterDetailTemplateData) => {
    return (
      <div>
        <PsAccordion
          dataSource={detail.data?.invoice}
          Component={CustomItem}
          CustomTitle={CustomTitle}
        />
      </div>
    );
  };

  const clientRender = (data: any) => {
    const clientData = data?.data?.projects || [];
    return (
      <div>
        <DataGrid
          dataSource={clientData}
          repaintChangesOnly={true}
          columnAutoWidth={true}
          showBorders={true}
          allowColumnReordering={true}
        >
          <Paging defaultPageSize={25} />
          <Column dataField="projectName" dataType="string" />
          <Column dataField="description" dataType="string" />
          <Column dataField="status" dataType="string" cellRender={ContactStatus} />
          <Column dataField="startDate" dataType="string" cellRender={cellStartTime} />
          <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
        </DataGrid>
      </div>
    );
  };

  useEffect(() => {
    if (popupVisible) {
      const popupContent = document?.querySelector(
        `.file-viewer-popup .dx-popup-content`
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        `.file-viewer-popup .dx-popup-content-scrollable`
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [popupVisible]);

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PsButton
          className="telementryPopupButton"
          icon="close"
          type="normal"
          mode="contained"
          width={"auto"}
          onClick={closePopup}
          eventName="TelementryPopupButton close"
        />
      </Item>
    );
  }, [closePopup]);

  return (
    <ScrollView>
      <div>
        <DataGrid
          className="grid theme-dependent"
          dataSource={clientInfoData}
          noDataText={"No Invoice detail to display at this time"}
          repaintChangesOnly={true}
          allowColumnReordering={true}
          columnAutoWidth={true}
          showBorders={true}
          onRowClick={handleRowClick}
        >
          <Paging defaultPageSize={25} />
          <Column dataField="invoiceId" caption="Invoice Id" dataType="string" />
          <Column dataField="bill_date" caption="Billing Period" dataType="string" />
          <Column dataField="date" caption="Invoice Date" dataType="string" />
          <Column dataField="totalcost" caption="Total Cost" dataType="string" cellRender={CellDollar} />
        </DataGrid>
      </div>
      <Popup
        visible={popupVisible}
        onHiding={closePopup}
        fullScreen={true}
        width={1000}
        height={"auto"}
        showCloseButton={false}
        showTitle={false}
        className={`file-viewer-popup`}
      >
        <div className="view-wrapper">
          <ToolbarAnalytics
            title={`Invoice Details | ${selectedRowData?.invoiceId}`}
            showToolbar={true}
            additionalToolbarContent={AdditionalToolbarContent}
          />
          <div className="telemetry-popup">
            {selectedRowData ? (
              <div>
                {clientRender({ data: selectedRowData })}
              </div>
            ) : (
              <div>No Data Available</div>
            )}
          </div>
        </div>
      </Popup>
    </ScrollView>
  );
};

export default InvoiceInformations;
