import React, { useState, useMemo, useEffect } from "react";
import { DropDownButton, Popup } from "devextreme-react";
import {
  isArrayOfObjects,
  hasNestedStructure,
  FileViwerPopupProps,
  formatXML,
  ExportController,
  FormatTitle,
} from "./types";
import GridView from "./GridView";
import ToolbarAnalytics from "../toolbar-analytics/ToolbarAnalytics";
import { Item } from "devextreme-react/toolbar";
import PsButton from "../button/Button";
import GridSource from "../../../assets/icons/icon-grid-source-view.png";
import PSIconText from "../icon-with-text/IconText";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import XMLViewer from "react-xml-viewer";
import DocumentGridView from "../../../pages/datastudio/documentCatalog/helper-component/DocumentGridView";
import "../../../styles/ui-components/FileViewer.scss";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";
import { useDocumentCatalog } from "../../../hooks/datastudio/useDocumentCatalog";
import Linecage from "./react-flow/linecage";
import { exportItems } from "../../../config/datastudio/pageConfig";
import { dropDownOptions } from "../../../config/constants";
import PsExport from "../../core-component/exportDatalake/PsExport";

export const FileViewer: React.FC<FileViwerPopupProps> = ({
  visible,
  onHiding,
  data,
  title,
  isLoading,
  extensionType,
  type,
  params,
  showExportToDataLake,
  Component,
  compoentProps
}) => {
  const [isGridView, setIsGridView] = useState(true);
  const [showPsExport, setShowPsExport] = useState(false);
  const [index, setIndex] = useState<number>(0);
  const isPdf = ["pdf"]?.includes(extensionType);
  const isData = [
    "json",
    "csv",
    "data",
    "xls",
    "xlsx",
    "txt",
    "map",
    "di",
    "process",
    "extract"
  ]?.includes(extensionType);
  const isXml = ["xml"]?.includes(extensionType);
  const gridData = data && isData ? JSON?.parse(data) : null;
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode?.Hand,
      },
    },
  });

  const canShowGridButton =
    isData &&
    gridData &&
    isArrayOfObjects(gridData) &&
    !hasNestedStructure(gridData);

  const fileTitle: any = useMemo(() => FormatTitle(title), [title]);

  const {
    state: { telemetryData, metricsData, pdfFinalData },
    setTelemetryData,
    setMetricsData,
    setPdfFinalData,
  } = useDocumentCatalog();

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <DropDownButton
          icon="export"
          text="Export"
          width="auto"
          stylingMode="text"
          items={
            showExportToDataLake
              ? exportItems
              : exportItems.filter((item) => item.text !== "Export to DataLake")
          }
          useSelectMode={true}
          displayExpr="text"
          dropDownOptions={dropDownOptions}
          onItemClick={(e) => {
            if (e.itemData.text === "Download") {
              ExportController(
                isGridView,
                isData,
                gridData,
                extensionType,
                data,
                isXml,
                isPdf,
                title
              );
            }
            if (
              e.itemData.text === "Export to DataLake" &&
              showExportToDataLake
            ) {
              setShowPsExport(true);
            }
          }}
          className="dropdown-export"
        />
        <PSIconText
          src={GridSource}
          alt="Grid/Source View"
          onClick={() => setIsGridView(!isGridView)}
          text={"Grid/Source View"}
          disabled={!canShowGridButton}
        />
        <PsButton
          icon="close"
          mode="text"
          type="normal"
          width={"auto"}
          onClick={onHiding}
          eventName="File Viewer close buttonp"
        />
      </Item>
    );
  }, [
    canShowGridButton,
    isGridView,
    onHiding,
    isData,
    gridData,
    extensionType,
    data,
    isXml,
    isPdf,
    title,
    showExportToDataLake,
  ]);

  useEffect(() => {
    setIndex(Date.now());
  }, []);

  useEffect(() => {
    if (visible) {
      const popupContent = document?.querySelector(
        `.file-viewer-popup-${index} .dx-popup-content`
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        `.file-viewer-popup-${index} .dx-popup-content-scrollable`
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [visible, index]);

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      fullScreen={true}
      width={"auto"}
      height={1000}
      showTitle={false}
      showCloseButton={false}
      className={`file-viewer-popup-${index}`}
    >
      <div className="view-wrapper">
        <ToolbarAnalytics
          title={fileTitle}
          showToolbar={true}
          additionalToolbarContent={AdditionalToolbarContent}
        />
        <div className="popup-content-container">
          {isLoading ? (
            <div className="file-container">Loading...</div>
          ) : type === "document processing" ? (
            <div className="grid-container">
              <DocumentGridView
                catalogInfo={gridData}
                disableLinks={true}
                telemetryData={telemetryData}
                metricsData={metricsData}
                pdfFinalData={pdfFinalData}
                setTelemetryData={setTelemetryData}
                setMetricsData={setMetricsData}
                setPdfFinalData={setPdfFinalData}
              />
            </div>
          ) : type === "workflow" ? (
            <div className="grid-container">
              <Linecage data={gridData} params={params} />
            </div>
          ) : type === "custom-component" ? (
            <div className="grid-container">
              <Component data={compoentProps} params={params} />
            </div>
          ) : isPdf && data ? (
            <div className="file-container">
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                  fileUrl={data}
                  plugins={[defaultLayoutPluginInstance]}
                  defaultScale={SpecialZoomLevel?.PageFit}
                />
              </Worker>
            </div>
          ) : isData && gridData ? (
            isArrayOfObjects(gridData) && !hasNestedStructure(gridData) ? (
              isGridView ? (
                <div className="grid-container">
                  <GridView gridData={gridData} title={title} />
                </div>
              ) : (
                <div className="file-container">
                  <pre
                    style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                  >
                    {JSON?.stringify(gridData, null, 2)}
                  </pre>
                </div>
              )
            ) : (
              <div className="file-container">
                <pre
                  style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
                >
                  {JSON?.stringify(gridData, null, 2)}
                </pre>
              </div>
            )
          ) : isXml && data ? (
            <div className="file-container">
              <XMLViewer xml={formatXML(data)} />
            </div>
          ) : (
            <div className="file-container">
              No file data to display at this time.
            </div>
          )}
        </div>
      </div>
      {showPsExport && (
        <PsExport
          isVisible={showPsExport}
          onClose={() => setShowPsExport(false)}
          onSave={() => {
            setShowPsExport(false);
          }}
          userId={params?.userId}
          fileName={params?.name}
          processType={params?.type}
          isLoading={false}
        />
      )}
    </Popup>
  );
};

export default FileViewer;
