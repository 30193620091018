import DataGrid, {
  Column,
  Paging,
  Scrolling,
  Pager,
} from "devextreme-react/data-grid";
import PsButton from "../../ui-components/button/Button";
import MyFilesView from "../../../assets/icons/icon-eye.png";
import { allowedPageSizes } from "../../../config/client/commonConfig";
import { useFetchSelectedProjectClientDataList } from "../../../hooks/app/useApps";
import { cellStartTime } from "../../ui-components/dataGridFormat/dataGridFormat";

export const GridComponent = (props: any) => {
  const { onViewScrapingButtonClick, id, location, projectShortName } = props;

  const { data: selectedList, isLoading: isListLoading } =
    useFetchSelectedProjectClientDataList(id, location, projectShortName);
      const renderAction = (cell: any) => {
    return (
      <PsButton
        hint="MyFiles"
        icon={MyFilesView}
        mode="text"
        onClick={(e) => onViewScrapingButtonClick(e, cell)}
        eventName="Data lake my files click event"
      />
    );
  };

  return (
    <DataGrid
      className="grid theme-dependent fileviewer-grid"
      dataSource={selectedList}
      rowAlternationEnabled={false}
      noDataText={
        isListLoading ? "Loading..." : "No Results to display at this time"
      }
      showBorders={true}
      allowColumnResizing={true}
    >
      <Scrolling mode="virtual" preloadEnabled={true} />
      <Paging defaultPageSize={25}/>
      <Column caption="File Name" dataField="filename" dataType="string" />
      <Column
        caption="Uploaded on"
        dataField="createdDate"
        dataType="string"
        cellRender={cellStartTime}
      />
      <Column caption="File Size" dataField="fileSize" dataType="string" />
      <Column caption="" type="buttons" width={180} cellRender={renderAction} />
      <Scrolling rowRenderingMode="virtual"></Scrolling>
      <Pager
        visible={true}
        allowedPageSizes={allowedPageSizes}
        displayMode={"full"}
        showPageSizeSelector={true}
        showInfo={true}
        showNavigationButtons={true}
      />
    </DataGrid>
  );
};
