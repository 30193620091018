import { generateRandomId } from "../../../utlis/helper";
import { Popover } from "devextreme-react";
import '../../../styles/ui-components/Telemetry-Ipop.scss';
import { formatDate } from "../../../utlis/helper";

export const TelemetryIPop = (props: any) => {

  const { TelemetryDescription, TelemetryHidden } = props;
  const randomId = generateRandomId();

  return (
    <div hidden={TelemetryHidden} >
      <span className="telemetry-info-icon" id={randomId} />
      <Popover
        target={`#${randomId}`}
        showEvent="mouseenter"
        hideEvent="mouseleave"
        position="right"
        width={'auto'}
      >
        <div>
          <ul>
            <h4>Telemetry</h4>
            {TelemetryDescription?.events?.map((event: any, index: number) => (
              <li className="tele-info" key={index}>
                {event?.eventData} {formatDate(event?.timeStamp)}
              </li>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};