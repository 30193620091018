// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resouceTable {
  width: 100%;
}

.resoucetableHead {
  text-align: left;
}

.actionButtonAdd {
  display: flex;
  align-items: center;
}

.resourceCount {
  width: 40px;
  display: flex;
  justify-content: center;
}

.resourceButton {
  border-radius: 30px !important;
}
.resourceButton .dx-icon {
  font-size: x-small !important;
  color: black !important;
}

.radioButtonField {
  margin: 0 !important;
}

.infraResource {
  margin: 0 !important;
}

.infraRemoveIcon {
  height: 20px !important;
}
.infraRemoveIcon .dx-icon {
  font-size: 16px !important;
  color: black !important;
}

.operationalRemoveIcon {
  height: 20px !important;
  box-shadow: none !important;
}
.operationalRemoveIcon .dx-icon {
  font-size: 16px !important;
  color: black !important;
}

.technicalRemoveIcon {
  height: 20px !important;
  box-shadow: none !important;
}
.technicalRemoveIcon .dx-icon {
  font-size: 16px !important;
  color: black !important;
}`, "",{"version":3,"sources":["webpack://./src/styles/client/project-form.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;AADJ;;AAIA;EACI,gBAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;AADJ;;AAIA;EACI,WAAA;EACA,aAAA;EACA,uBAAA;AADJ;;AAIA;EACI,8BAAA;AADJ;AAEI;EACI,6BAAA;EACA,uBAAA;AAAR;;AAIA;EACI,oBAAA;AADJ;;AAGA;EACI,oBAAA;AAAJ;;AAGA;EACI,uBAAA;AAAJ;AACI;EACI,0BAAA;EACA,uBAAA;AACR;;AAGA;EACI,uBAAA;EACA,2BAAA;AAAJ;AACI;EACI,0BAAA;EACA,uBAAA;AACR;;AAEA;EACI,uBAAA;EACA,2BAAA;AACJ;AAAI;EACI,0BAAA;EACA,uBAAA;AAER","sourcesContent":["\n\n.resouceTable{\n    width: 100%;\n}\n\n.resoucetableHead{\n    text-align: left;\n}\n\n.actionButtonAdd{\n    display: flex;\n    align-items: center;\n}\n\n.resourceCount{\n    width:40px;\n    display:flex;\n    justify-content:center;\n}\n\n.resourceButton{\n    border-radius: 30px!important;    \n    .dx-icon{\n        font-size: x-small!important;\n        color: black!important;\n    }\n}\n\n.radioButtonField{\n    margin: 0!important;\n}\n.infraResource{\n    margin: 0!important;\n}\n\n.infraRemoveIcon{\n    height: 20px!important;\n    .dx-icon{\n        font-size: 16px!important;\n        color: black!important; \n    }\n}\n\n.operationalRemoveIcon{\n    height: 20px!important;\n    box-shadow: none!important;\n    .dx-icon{\n        font-size: 16px!important;\n        color: black!important; \n    }\n}\n.technicalRemoveIcon{\n    height: 20px!important;\n    box-shadow: none!important;\n    .dx-icon{\n        font-size: 16px!important;\n        color: black!important; \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
