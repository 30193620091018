import React from "react";
import ScrollView from "devextreme-react/scroll-view";
import Sortable from "devextreme-react/sortable";
import { kanbanDataProps, kanbanProps } from "./types";
import "../../../styles/core-components//Kanban.scss";

const normalizeStatus = (status: string) => status?.toUpperCase();

function getLists(statusArray: string[], listArray: kanbanDataProps[]) {
  const listMap: { [status: string]: kanbanDataProps[] } = {};
  listArray?.forEach((list) => {
    const status = normalizeStatus(list?.status);
    if (!listMap[status]) {
      listMap[status] = [];
    }
    listMap[status]?.push(list);
  });
  const lists = statusArray?.map(
    (status) => listMap[normalizeStatus(status)] || []
  );
  return lists;
}

const Card: React.FC<{
  list: kanbanDataProps;
  onClick: (data: any) => void;
}> = ({ list, onClick }) => (
  <div
    className="card dx-card dx-theme-text-color dx-theme-background-color"
    onClick={() => onClick([list])}
  >
    <div className={`card-priority priority-${list?.priority}`}></div>
    <div className="card-subject">
      <b>Client: </b>
      {list?.clientName}
    </div>
    {list?.urlCount !== undefined && (
      <div className="card-subject">
        <b>Url Count: </b>
        {list?.urlCount}
      </div>
    )}
    {list?.taskCounts && (
      <div className="card-subject">
        <b>Tasks:</b>
        <ul>
          {list?.taskCounts?.map((task, index) => (
            <li className="list-gap" key={index}>
              {Object?.keys(task)[0]}: {task[Object?.keys(task)[0]]}
            </li>
          ))}
        </ul>
      </div>
    )}
    {list?.taskNames && (
      <div className="card-subject">
        <b>Tasks:</b>
        <ul>
          {list?.taskNames.map((task, index) => {
            if (typeof task !== "string") {
              return null;
            }
            return (
              <li className="list-gap" key={index}>
                {task}
              </li>
            );
          })}
        </ul>
      </div>
    )}
  </div>
);

const List = ({ title, index, list, onCardClick }: any) => (
  <div className="list">
    <div className="list-title dx-theme-text-color">{title}</div>
    <ScrollView
      className="scrollable-list"
      direction="vertical"
      showScrollbar="always"
    >
      <Sortable className="sortable-cards" group="cardsGroup" data={index}>
        {list?.map((item: any) => (
          <Card key={item?.clientID} list={item} onClick={onCardClick}></Card>
        ))}
      </Sortable>
    </ScrollView>
  </div>
);

const KanbanBoard = (props: kanbanProps) => {
  const { status, data, onCardClick } = props;

  const normalizedStatus = status?.map(normalizeStatus);

  const lists = getLists(normalizedStatus, data);

  return (
    <div id="kanban">
      {lists?.map((list, listIndex: string | number) => {
        const statusvalue = normalizedStatus[listIndex as any];
        return (
          <List
            key={statusvalue}
            title={status[listIndex as any]}
            index={listIndex}
            list={list}
            onCardClick={onCardClick}
          />
        );
      })}
    </div>
  );
};

export default KanbanBoard;
