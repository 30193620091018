import Popup from "devextreme-react/popup";
import PsButton from "../../../../components/ui-components/button/Button";
import { useDeleteClient } from "../../../../hooks/client/useClientCatlog";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
import { useEffect } from "react";
import { useApp } from "../../../../contexts/app";

const DeleteClient = (props: any) => {
  const { deletePopupVisible, onDeletePopupClose, selectedClient } = props;
  const { row } = selectedClient || {};
  const { data } = row || {};
  const { clientId, name } = data || {};
  const { user } = useApp();
  const userId = user?.id
  const { isLoading, refetch, isRefetching, isSuccess } = useDeleteClient(
    onDeletePopupClose,
    clientId,
    userId,
  );

  const loaderProps = {
    show: isLoading || isRefetching,
    loadertext: "Please Wait...",
  };

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `"${name}" deleted successfully`,
        type: "success",
      });
    }
  }, [isSuccess, name]);

  return (
    <Popup
      title="Delete Client"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height="auto"
    >
        <div>
          <p>
            Are you sure you want to delete the <b>{name}</b> client ?
          </p>
          <p style={{margin: '15px 0'}}>
            <b>
              If you delete your information, you will not be able to recover it.
            </b>
          </p>
        </div>
        <div style={{ float: "left" }}>
        <PsButton
          onClick={() => refetch()}
          text="Confirm"
          type="normal"
          loaderProps={loaderProps}
          mode="contained"
        />
        </div>
        <div style={{ float: "right" }}>
          <PsButton
            onClick={onDeletePopupClose}
            text="Close"
            type="normal"
            mode="contained"
          />
        </div>
    </Popup>
  );
};

export default DeleteClient;
