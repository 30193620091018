import React, { useCallback, useMemo, useState, useEffect, useRef } from "react";
import RefreshIcon from "../../../assets/icons/icon-reload-1.png";
import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Button as ActionsButton,
  Editing,
  Scrolling,
  Pager,
  SearchPanel,
  Item,
  StateStoring,
  Selection,
} from "devextreme-react/data-grid";
import { HeaderFilter } from "devextreme-react/cjs/gantt";
import DataSource from "devextreme/data/data_source";
import { LoadOptions } from "devextreme/data";
import { PsDataGridProps } from "./helper-component/types";
import TableSkeleton from "./helper-component/TableSkeleton";
import { Toolbar } from "devextreme-react";
import PSIconText from "../icon-with-text/IconText";
import { useLocation, useNavigate } from "react-router-dom";
import Pluse from "../../../assets/media/plus.png";
import Minus from "../../../assets/media/minus.png";
import { useApp } from "../../../contexts/app";
import { dataQualityIndicator, EXECUTION_STATUS } from "../../../config/constants";

const PsDataGrid = React.memo((props: PsDataGridProps) => {
  const { pageProps, gridProps, apiProps } = props;
  const { nodataText, heightClass, id } = pageProps;

  const {
    dataSource,
    columns,
    keyExpr,
    allowedPageSizes,
    searchPanelVisible,
    headerFilterVisible,
    pagingEnabled,
    pagerEnabled,
    detailComponent,
    onRowEdit,
    onRowDelete,
    onRowClone,
    onCellPrepared,
    handleRefetch,
    title,
    pageRoute,
    loaderEnabled,
    selection,
    onSelectionChanged,
    enableWrapText = false,
    ref
  } = gridProps;

  const { apiFunction, additionalParams, refreshKey } = apiProps || {};

  const [isDataFetched, setIsDataFetched] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [blockApiCall, setBlockApiCall] = useState(false);
  const [pageSize, setPageSize] = useState(25);
  const [pageIndex, setPageIndex] = useState(0);

  const location = useLocation();
  const { pathname } = location;
  const pathValue = pathname.split('/')[1];
  const path = pathValue ? pathValue : "home"

  const navigate = useNavigate()

  const { user } = useApp();
  const userAdmin = user?.userId;

  const loaderDivRef = useRef<HTMLDivElement>(null);
  const gridDivRef = useRef<HTMLDivElement>(null);

  const prevData = useRef({ data: [], totalCount: 0 });

  useEffect(() => {
    if (apiFunction === undefined) {
      // setIsDataFetched(true);
    } else {
      // setIsDataFetched(false);
    }
    setIsDataFetched(true)
    handleShowLoader();
  }, [apiFunction, refreshKey]);


  useEffect(() => {
    if (!isCollapsed) {
      setBlockApiCall(false);
    }
  }, [refreshKey, pageSize, pageIndex]);

  const loadData = useCallback(
    async (loadOptions: LoadOptions) => {
      if (blockApiCall) {
        return prevData.current;
      }
      const { skip = 0, take = pageSize ? pageSize : 25, sort = [], filter = [] } = loadOptions;

      let currentSkip = 0;

      if (Array.isArray(sort) && sort.length > 0) {
        currentSkip = 0;
      }

      if (filter.length === 0 && skip === 0 && currentSkip !== 0) {
        loadOptions.skip = currentSkip;
      } else {
        currentSkip = skip;
      }

      if (skip !== currentSkip) {
        currentSkip = skip;
      }

      const page = Math.floor((skip ?? 0) / take) + 1;
      const sortArray = Array.isArray(sort)
        ? (sort as Array<{ selector: string; desc: boolean }>)
        : [];
      const sortColumn = sortArray[0]?.selector || null;
      const sortOrder =
        sortArray[0]?.desc === true
          ? "DESC"
          : sortArray[0]?.desc === false
            ? "ASC"
            : null;

      const queryParams = {
        Page: page,
        Count: take,
        sortColumn,
        sortOrder,
        ...additionalParams,
      };

      try {
        const response = await apiFunction(queryParams);
        const newData = {
          data: response?.data || [],
          totalCount: response?.pagination?.totalRecords || 0,
        };
        prevData.current = newData;
        return newData;
      } catch (error) {
        console.error("Error loading data:", error);
        throw error;
      }
    },
    [additionalParams, apiFunction, pageSize, blockApiCall]
  );

  const createDataSource = useCallback(() => {
    if (!apiFunction) {
      return new DataSource({
        store: {
          type: "array",
          data: dataSource || [],
          key: keyExpr,
        },
      });
    }

    return new DataSource({
      key: keyExpr,
      load: loadData,
    });
  }, [apiFunction, dataSource, keyExpr, loadData]);

  const finalDataSource = useMemo(() => {
    if (apiFunction === undefined) {
      return dataSource;
    } else {
      return createDataSource();
    }
  }, [apiFunction, dataSource, createDataSource]);

  const handleOptionChanged = (e: any) => {
    setIsDataFetched(true)
    if (e.fullName === "paging.pageSize") {
      setPageSize(e.value);
      if (pageIndex === 0) {
        const gridProperties = localStorage.getItem(`GRID-${path}-${id}`)
        const gridPropertiesParsed = gridProperties ? JSON.parse(gridProperties) : null;
        if (gridPropertiesParsed?.pageIndex > 0) {
          setPageIndex(gridPropertiesParsed.pageIndex)
        }

      } else {
        setPageIndex(0)
      }
    } else if (e.fullName === "paging.pageIndex") {
      setPageIndex(e.value);
    }
  };

  const handleContentReady = (dataSource: any) => {
    if (loaderDivRef.current) {
      loaderDivRef.current.style.display = "none";
    }
    if (gridDivRef.current) {
      gridDivRef.current.style.display = "block";
    }
  };

  const handleShowLoader = () => {
    if (loaderDivRef.current) {
      loaderDivRef.current.style.display = "block";
    }
    if (gridDivRef.current) {
      gridDivRef.current.style.display = "none";
    }
  }

  return (
    <>
      {title && pageRoute &&
        <Toolbar>
          <Item
            location="before"
            text={title}
            render={() => (
              <h6 className="underline-link"
                onClick={() => navigate(pageRoute)}
              >
                {title}
              </h6>
            )}
          />
          {!isCollapsed &&
            <Item location="after" widget="dxButton">
              <PSIconText
                src={RefreshIcon}
                alt="refresh"
                onClick={() => {
                  setBlockApiCall(false);
                  handleRefetch && handleRefetch(refreshKey)
                }}
              />
            </Item>
          }

          <Item location="after" widget="dxButton">
            <PSIconText
              src={isCollapsed ? Pluse : Minus}
              alt="expand/collapse"
              onClick={() => {
                setBlockApiCall(true);
                setIsCollapsed((prev) => !prev);
              }}
            />
          </Item>
        </Toolbar>
      }
      <div ref={loaderDivRef}>
        {loaderEnabled !== false && !isCollapsed && <TableSkeleton />}
      </div>
      <div ref={gridDivRef}>

        {!isCollapsed &&
          <DataGrid
            id={id}
            ref={ref}
            className={`grid theme-dependent ${heightClass}`}
            dataSource={finalDataSource}
            keyExpr={keyExpr}
            showBorders={true}
            allowColumnResizing={true}
            allowColumnReordering={true}
            repaintChangesOnly={true}
            noDataText={nodataText}
            remoteOperations={{ filtering: false, paging: true, sorting: true }}
            onCellPrepared={onCellPrepared}
            onOptionChanged={handleOptionChanged}
            onContentReady={() => handleContentReady(finalDataSource)}
            loadPanel={{ enabled: isDataFetched, text: "" }}
            wordWrapEnabled={enableWrapText}
            onRowPrepared={(e: any) => {
              if (e?.rowElement && e?.rowType === "data") {
                if (e?.key === userAdmin) {
                  e.rowElement.style.backgroundColor = "#d1e7fd";
                } else if (EXECUTION_STATUS?.includes(e?.data?.executionStatus)) {
                  if (e?.data?.dataQualityIndicator === dataQualityIndicator?.[2]) {
                    e.rowElement.style.backgroundColor = "#fadede";
                  } else if (e?.data?.dataQualityIndicator === dataQualityIndicator?.[1]) {
                    e.rowElement.style.backgroundColor = "#faf3de";
                  }
                }
                if (e?.data?.permissions) {
                  e.rowElement.dataset.disabled = !(e?.data?.permissions?.canUpdate || e?.data?.permissions?.canDelete) ? "true" : "false"
                } else {
                  e.rowElement.dataset.disabled = "false";
                }
                if (!EXECUTION_STATUS?.includes(e?.data?.executionStatus)) {
                  const checkboxCell = e?.rowElement?.querySelector('.dx-command-select');
                  if (checkboxCell) {
                    checkboxCell.style.pointerEvents = 'none';
                    checkboxCell.style.opacity = '0.5';
                  }
                }
              }
            }}
            onSelectionChanged={(e) => {
              const filtered = e?.selectedRowsData?.filter(
                (row: any) => EXECUTION_STATUS?.includes(row?.executionStatus)
              );
              if (filtered?.length !== e?.selectedRowsData?.length) {
                e?.component?.deselectRows(
                  e?.selectedRowsData
                    ?.filter((row: any) => !EXECUTION_STATUS?.includes(row?.executionStatus))
                    ?.map((row: any) => keyExpr ? row[keyExpr] : undefined)
                );
              }
              onSelectionChanged?.(e);
            }}
          >
            {selection && (
              <Selection
                mode={selection?.mode as "none" | "single" | "multiple"}
                showCheckBoxesMode={selection?.showCheckBoxesMode || "onClick"}
                allowSelectAll={selection?.allowSelectAll || false}
              />
            )}
            {/* {pagingEnabled && <Paging defaultPageSize={25}  pageSize={pageSize} pageIndex={pageIndex} />} */}
            <StateStoring savingTimeout={0} enabled={true} type="localStorage" storageKey={`GRID-${path}-${id}`} />
            {pagingEnabled && <Paging pageSize={pageSize ? pageSize : 25} pageIndex={pageIndex} />}
            {pagerEnabled && (
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode={"full"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
            )}
            {searchPanelVisible && <SearchPanel visible width={200} />}
            {headerFilterVisible && <HeaderFilter visible />}
            <Scrolling rowRenderingMode="standard" />
            {columns?.map((col, index) => (
              <Column key={index} {...col} />
            ))}
            <Editing
              mode="row"
              useIcons={true}
              allowUpdating={!!onRowEdit}
              allowDeleting={!!onRowDelete}
            />
            {/* <LoadPanel showIndicator={true} showPane={true} text="" /> */}
            {onRowEdit && (
              <Column caption="" type="buttons" width={120}>
                <ActionsButton
                  name="edit"
                  onClick={onRowEdit}
                  hint="Edit"
                  cssClass="action-image"
                  disabled={({ row }: any) => !(row?.data?.permissions?.canUpdate ?? true)}
                />
                {onRowClone && (
                  <ActionsButton
                    text="Clone"
                    hint="Clone"
                    icon="copy"
                    cssClass="action-image"
                    onClick={onRowClone}
                    disabled={({ row }: any) => !(row?.data?.permissions?.canUpdate ?? true)}
                  />
                )}
                <ActionsButton
                  name="delete"
                  onClick={onRowDelete}
                  hint="Delete"
                  cssClass="action-image"
                  disabled={({ row }: any) => !(row?.data?.permissions?.canDelete ?? true)}
                />
              </Column>
            )}
            {detailComponent && (
              <MasterDetail enabled={true} component={detailComponent} />
            )}
          </DataGrid>
        }
      </div>
    </>
  );
});

export default PsDataGrid;
