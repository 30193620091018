import React, { useCallback, useEffect } from "react";
import { useUpdateMapFile } from "../../../../../hooks/datastudio/useDataCatalog";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";
import { useGetDataProcessingDataApi } from "../../../../../hooks/datastudio/useDataAnalyzer";
import { JsonEditor } from 'json-edit-react'

const MapfileEdit = React.memo((props: any) => {

  const {
    selectedData,
    popupVisible,
    setPopupClose,
    updatedData,
    setUpdatedData
  } = props;

  const data = selectedData?.row?.data

  const { fileName, userId, processType, dataCatalogId } = data || {};

  const { data: FileData, isLoading: isDataLoading } = useGetDataProcessingDataApi({ filename: fileName, userId: userId, processType: processType, dataCatalogId: dataCatalogId, isFileUploaded: true });

  useEffect(() => {
    if (FileData) {
      setUpdatedData(FileData?.data);
    }
  }, [FileData, setUpdatedData]);

  const closePanel = useCallback(() => {
    setPopupClose(true);
  }, [setPopupClose]);

  const { mutate, isLoading } = useUpdateMapFile(closePanel);

  const handleFormSubmit = useCallback(() => {
    const queryParams = {
      dataCatalogId: data?.dataCatalogId,
      processType: data?.processType,
      filename: data?.fileName,
    };
    mutate({ queryParams, payload: updatedData });
  }, [data, updatedData, mutate]);

  const handleEdit = (data: any) => {
    setUpdatedData(data?.newData);
  };

  return (
    <FormPopup
      title="Edit Mapfile"
      visible={popupVisible}
      setVisible={setPopupClose}
      onSave={handleFormSubmit}
      isLoading={isLoading}
      fullScreen={true}
    >
      {isDataLoading ? (
        <div>Loading...</div>
      ) : (
        <JsonEditor
          rootName=""
          data={FileData ? FileData?.data : {}}
          maxWidth='100%'
          onEdit={handleEdit}
          restrictDrag={false}
        />
      )}
    </FormPopup>
  )
});

export default MapfileEdit;