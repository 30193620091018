import DataGrid, { Column } from "devextreme-react/data-grid";
import { useCreateUserSpecificRoles } from "../../../../../hooks/app/useApps";
import { useMemo, useState } from "react";
import PsButton from "../../../../../components/ui-components/button/Button";
import { useApp } from "../../../../../contexts/app";
import { useLocation, useNavigate } from "react-router-dom";
import PsSkeleton from "../../../../../components/ui-components/skeleton/PsSkeleton";
import { CheckBox } from "devextreme-react";

const ServicesList = ({ roles, userRole }: { roles: any; userRole: any }) => {
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const navigate = useNavigate();
  const { user } = useApp();
  const userAdmin = user?.userId;

  const selectedRoles = useMemo(() => {
    const selected: { [key: string]: boolean } = {};
    userRole?.[0]?.service_action?.forEach((action: any) => {
      selected[action.roleId] = action.isChecked || false;
    });
    return selected;
  }, [userRole]);

  const [modifiedRoles, setModifiedRoles] = useState<{ [key: string]: boolean }>({});

  const combinedData = useMemo(() => {
    if (!roles) return [];
    return roles.map((role: any) => ({
      roleId: role.id,
      roleName: role.roleName,
      isActive: modifiedRoles[role.id] ?? selectedRoles[role.id] ?? false,
    }));
  }, [roles, modifiedRoles, selectedRoles]);

  const onRoleChange = (roleId: string, isChecked: boolean) => {
    setModifiedRoles((prev) => ({
      ...prev,
      [roleId]: isChecked,
    }));
  };

  const { mutate } = useCreateUserSpecificRoles();
  const onSave = () => {
    const updatedRoles = { ...selectedRoles, ...modifiedRoles };
    const payload = {
      userId,
      servicePermissions: Object.keys(updatedRoles).filter((id) => updatedRoles[id]),
      createdBy: userAdmin,
    };
    mutate(payload);
    navigate(-1);
  };

  return (
    <>
      {roles && userRole ? (
        <DataGrid dataSource={combinedData} showBorders={true} keyExpr="roleId">
          <Column
            caption="Service Roles"
            cellRender={(cell) => (
              <CheckBox
                id={cell.data.roleId}
                text={cell.data.roleName}
                defaultValue={modifiedRoles[cell.data.roleId] ?? selectedRoles[cell.data.roleId] ?? false}
                onValueChanged={(e) => onRoleChange(cell.data.roleId, e.value)}
              />
            )}
          />
        </DataGrid>
      ) : (
        <PsSkeleton count={5} height={30} />
      )}
      <div className="accessListContainer">
        <PsButton text="Cancel" type="default" mode="outlined" onClick={() => navigate(-1)} />
        <PsButton text="Save" type="default" onClick={onSave} />
      </div>
    </>
  );
};

export default ServicesList;