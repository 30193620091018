import React from 'react';
import Chart, { ArgumentAxis, Border, CommonSeriesSettings, Format, Label, Legend, Series, Size, ValueAxis } from 'devextreme-react/chart';
import { CardAnalytics } from '../../library/finops-analytics/CardAnalytics';

export const RevenueCard = ({ datasource }: { datasource:any}) => (
  
  <CardAnalytics title='Total Cost Analysis' contentClass='sales'>
    <Chart dataSource={datasource}>
      <Series valueField='value' name="designation" />
      <CommonSeriesSettings argumentField='designation' type='splinearea'>
        <Border visible />
      </CommonSeriesSettings>
      <ArgumentAxis tickInterval={1}/>
      <ValueAxis>
        <Label>
          <Format type='currency' currency='USD' />
        </Label>
      </ValueAxis>
      <Legend visible={false} />
      <Size height={270} />
    </Chart>
  </CardAnalytics>
);
