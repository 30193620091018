import { ServiceApi } from "../../config/serviceConfig";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

const handleResponse = async (response: Response) => {
  const data = await response.json();
    if (!response.ok) {
      throw new Error(data.message || "Something went wrong");
    }
    return data;
};

const handleError = (error: Error | unknown) => {
  showNotification({
    message: error instanceof Error ? error.message : "Something went wrong",
  });
};

const customFetch = async (endpoint: string, options: RequestInit) => {
  const OcpApimSubscriptionKey =
    process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY;
  const { baseUrl } = ServiceApi;
  // assign token to headers
  const headers = new Headers();
  if (options?.body instanceof FormData) {
    headers.delete("Content-Type");
  } else {
    headers.append("Content-Type", "application/json");
  }
  headers.append("Accept", "application/json");
  headers.append("Ocp-Apim-Subscription-Key", OcpApimSubscriptionKey || "");
  const optionsModified = {
    ...options,
    headers,
  };

  try {
    const response = await fetch(`${baseUrl}/${endpoint}`, optionsModified);
    return handleResponse(response);
  } catch (error) {
    return handleError(error);
  }
};

export const get = async <T>(url: string): Promise<T> => {
  return customFetch(url, {
    method: "GET",
  });
};

export const post = async <T>(url: string, data: any): Promise<T> =>
  customFetch(url, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const del = async <T>(url: string): Promise<T> =>
  customFetch(url, {
    method: "DELETE",
  });

export const update = async <T>(url: string, data: any): Promise<T> =>
  customFetch(url, {
    method: "PUT",
    body: JSON.stringify(data),
  });

export const updateFile = async <T>(url: string, formData: any): Promise<T> =>
  customFetch(url, {
    method: "PUT",
    body: formData,
  });

export const upload = async <T>(url: string, formData: any): Promise<T> =>
  customFetch(url, {
    method: "POST",
    body: formData,
  });
