import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
  RangeRule,
} from "devextreme-react/form";
import DataGrid, {
  Selection,
  Paging,
  FilterRow,
  Scrolling,
  DataGridTypes,
  RequiredRule,
   HeaderFilter,
} from "devextreme-react/data-grid";
import React, { useCallback, useReducer,useRef,useEffect } from "react";
import { FormTextbox } from "../../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../../utlis/media-query";
import { generateShortName } from "../../../../../utlis/helper";
import {
  TaskData,
  initialSchedulerState,
  SET_SCHEDULER_TYPE,
} from "../../types";
import SelectBox from "devextreme-react/select-box";
import DropDownBox, { DropDownBoxTypes } from "devextreme-react/drop-down-box";
import {
  containerSchedule,
  containerSize,
  containerValue,
  gridColumns,
  ownerLabel,
  simpleProductLabel,
  taskStatus,
} from "../../../../../config/task/commonConfig";
import { DateBox, Validator } from "devextreme-react";
import { useFetchClient } from "../../../../../hooks/app/useApps";


const schedulerReducer = (state: any, action: any) => {
  switch (action.type) {
    case SET_SCHEDULER_TYPE:
      switch (action.payload) {
        case "Daily":
          return {
            selectWeekly: false,
            selectDaily: true,
            selectMonthly: false,
          };
        case "Weekly":
          return {
            selectWeekly: true,
            selectDaily: true,
            selectMonthly: false,
          };
        case "Monthly":
          return {
            selectMonthly: true,
            selectDaily: false,
            selectWeekly: false,
          };
        default:
          return {
            selectMonthly: false,
            selectDaily: false,
            selectWeekly: false,
          };
      }
    default:
      return state;
  }
};

const TaskNewForm = ({
  newTaskData,
  urlGridData,
  updateField,
  onOptionChanged,
  onOptionContainerSize,
  onOptionTaskScedule,
  onOptionMaxContainer,
  onOptionTaskActive,
  dataGridOnSelectionChanged,
  gridBoxValue,
  syncDataGridSelection,
  fetchProject,
  onProjectOptionChanged,
  selectedProjectType,
  isUrlLoading,
  onWorkflowOptionChanged,
  workflowTemplateDropdown
}: {
  newTaskData: TaskData;
  updateField: (field: string) => (value: any) => void;
  onOptionChanged: (e: { clientId: string; name: string , clientTag: string;clientShortName:string}) => void;
  onOptionContainerSize: (e: string) => void;
  onOptionTaskScedule: (e: any) => void;
  onOptionTaskActive: (e: string) => void;
  onOptionMaxContainer: (e: { value: string }) => void;
  dataGridOnSelectionChanged: (e: DataGridTypes.SelectionChangedEvent) => void;
  syncDataGridSelection: (e: DropDownBoxTypes.ValueChangedEvent) => void;
  gridBoxValue: any;
  urlGridData: any;
  fetchProject: any;
  onProjectOptionChanged: (e: {
    projectName: string;
    projectId: string;
    projectType: string;
    projectTag: string;
    projectShortName: string;
   
  }) => void;
  selectedProjectType: any;
  isUrlLoading: boolean;
  onWorkflowOptionChanged: (e: {
    workflowName: string;
    manifestId: string;
  }) => void;
  workflowTemplateDropdown: any;
}) => {
  const { data } = useFetchClient();
  const clearLabel = { "aria-label": "Clear" };
  const [schedulerState, schedulerDispatch] = useReducer(
    schedulerReducer,
    initialSchedulerState
  );
  const [selectedValues, setSelectedValues] = React.useState<any>(null);
  const [selectedTime, setSelectedTime] = React.useState<any>(null);
  const [selectedDay, setSelectedDay] = React.useState<any>(null);
  const isShortNameManuallyEdited = useRef(false);

  const dataGridRender = useCallback(
    () => (
      <DataGrid
        height={345}
        noDataText={isUrlLoading ? "Loading..." : "No urls to display at this time"}
        dataSource={urlGridData}
        columns={gridColumns}
        hoverStateEnabled={true}
        selectedRowKeys={gridBoxValue}
        onSelectionChanged={dataGridOnSelectionChanged}
      >
        <HeaderFilter visible />
        <Selection mode="multiple" showCheckBoxesMode="always" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <FilterRow visible={true} />
      </DataGrid>
    ),
    [urlGridData, gridBoxValue, dataGridOnSelectionChanged, isUrlLoading]
  );

  function getDayNumber(dayName: string) {
    switch (dayName) {
      case "Monday":
        return 1;
      case "Tuesday":
        return 2;
      case "Wednesday":
        return 3;
      case "Thursday":
        return 4;
      case "Friday":
        return 5;
      case "Saturday":
        return 6;
      case "Sunday":
        return 7;
      default:
        return null;
    }
  }

  const onOptionWeeklyTaskScedule = (e: any) => {
    const dayNumber = getDayNumber(e);
    setSelectedDay(dayNumber);
    onOptionTaskScedule({
      day: [dayNumber],
      frequency: selectedValues,
      start_Time: selectedTime,
    });
  };

  const onOptionMonthlySchedule = (e: any) => {
    const selectedDateObj = new Date(e);
    // const date = selectedDateObj.toLocaleDateString();
    const time = selectedDateObj.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
    const month = selectedDateObj.getMonth() + 1;
    const day = selectedDateObj.getDate();
    onOptionTaskScedule({
      start_time: time,
      day: [day],
      month: [month],
      frequency: selectedValues,
    });
  };

  useEffect(() => {
    if (!isShortNameManuallyEdited.current && newTaskData.taskName) {
      const newShortName = generateShortName(newTaskData.taskName);
      updateField("taskShortName")(newShortName);
    }
    if (!newTaskData.taskName) {
      isShortNameManuallyEdited.current = false;
      updateField("taskShortName")("");
    }
  }, [newTaskData?.taskName]);


  const handleShortNameChange = (value: string) => {
    if (value) {
      isShortNameManuallyEdited.current = true;
    }
    updateField("taskShortName")(value);
  };


  const onOptionDailyTaskScedule = (e: any) => {
    const selectedDateObj = new Date(e);
    const time = selectedDateObj.toLocaleTimeString([], {
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });
    setSelectedTime(time);

    onOptionTaskScedule({
      start_time: time,
      frequency: selectedValues,
      day: [selectedDay],
    });
  };

  const handleOptionTaskScedule = (e: string) => {
    updateField("taskScheduling")(e);
    setSelectedValues(e);
    schedulerDispatch({ type: SET_SCHEDULER_TYPE, payload: e });
  };

  if (typeof window === "undefined") return null;

  return (
    <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
      <GroupItem>
        <ColCountByScreen xs={1} sm={2} md={2} lg={2} />
        <FormItem>
          <SelectBox
            dataSource={data}
            displayExpr="name"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select a Client..."
            onValueChange={(event: any) => onOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={fetchProject}
            displayExpr="projectName"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select a Project..."
            onValueChange={(event: any) => onProjectOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={workflowTemplateDropdown}
            displayExpr="workflowName"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select a Workflow..."
            onValueChange={(event: any) => onWorkflowOptionChanged(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Task Description..."
            value={newTaskData.taskDescription}
            isEditing={false}
            onValueChange={updateField("taskDescription")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Task Name..."
            value={newTaskData.taskName}
            isEditing={false}
            onValueChange={updateField("taskName")}
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Task Short Name"
            value={newTaskData.taskShortName}
            isEditing={false}
            onValueChange={handleShortNameChange}  
          />
        </FormItem>
        <FormItem>
          <FormTextbox
            placeholder="Max Container Group (1 - 50)..."
            value={newTaskData.maxContainerGroup}
            isEditing={false}
            onValueChange={updateField("maxContainerGroup")}
          >
            <RangeRule
              min={1}
              max={50}
            />
          </FormTextbox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={containerValue}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Containers Per Group..."
            onValueChange={(event: any) => onOptionMaxContainer(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={containerSize}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Container Size..."
            onValueChange={(event: any) => onOptionContainerSize(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={taskStatus}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Status..."
            onValueChange={(event: any) => onOptionTaskActive(event)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <DropDownBox
            value={gridBoxValue}
            valueExpr="url_id"
            deferRendering={false}
            inputAttr={ownerLabel}
            displayExpr="url"
            placeholder="Select a Group/URLs"
            showClearButton={true}
            visible={selectedProjectType?.projectType === "Web Scraping"}
            dataSource={urlGridData}
            onValueChanged={syncDataGridSelection}
            contentRender={dataGridRender}
          >
            {/* <Validator>
                <RequiredRule />
              </Validator> */}
          </DropDownBox>
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={containerSchedule}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Task Schedule..."
            onValueChange={handleOptionTaskScedule}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </FormItem>
        <FormItem>
          <DateBox
            // defaultValue={dateClear}
            type="time"
            inputAttr={clearLabel}
            showClearButton={true}
            visible={schedulerState.selectDaily}
            onValueChange={(event: any) => onOptionDailyTaskScedule(event)}
            placeholder="Select Time..."
          />
        </FormItem>
        <FormItem>
          <DateBox
            // defaultValue={new Date()}
            placeholder="Select Date & Time..."
            visible={schedulerState.selectMonthly}
            type="datetime"
            inputAttr={{ "aria-label": "Date Time" }}
            displayFormat="yyyy-MM-dd HH:mm"
            onValueChange={(e: any) => onOptionMonthlySchedule(e)}
          />
        </FormItem>
        <FormItem>
          <SelectBox
            dataSource={[
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ]}
            searchEnabled={true}
            inputAttr={{ "aria-label": "Days" }}
            placeholder="Select Day..."
            visible={schedulerState.selectWeekly}
            onValueChange={(event: any) => onOptionWeeklyTaskScedule(event)}
          />
        </FormItem>
      </GroupItem>
    </Form>
  );
};

export default TaskNewForm;
