import {
  useCreateWorkBook,
  useCreateWorkSpace,
  useFetchSharedData,
  useFetchWorkBooks,
  useFetchWorkSpace,
  useWorkBench,
} from "../../hooks/workbench/useWorkbench";
import { useCallback, useEffect, useMemo, useState } from "react";
import WorkbenchLayout from "./layout/WorkbenchLayout";
import AddNewWorkSpace from "./helper-component/form/NewWorkSpace";
import AddNewWorkBook from "./helper-component/form/NewWorkBook";
import { useApp } from "../../contexts/app";
import WorkbenchCards from "./helper-component/workbenchCards/Workbenchcards";
import WorkspaceIcon from "../../assets/media/workspace-1.png";
import WorkbookIcon from "../../assets/media/workbook.png";
import SharedWorkbookIcon from "../../assets/media/workspace-shared-1.png";
import {
  newWorkSpaceFormData,
  WorkSpaceData,
  WorkBookData,
  newWorkBookFormData,
} from "./types";
import DataGridTitleCardLayout from "../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { Projects } from "../../pages/client/projects/projects";
import AlertExceptionPage from "../../components/core-component/alertExceptions/alertException";
import RecentActivityDataGrid from "../../components/core-component/recentActivity/recentActivity";
import { customDateRange } from "../../config/constants";

const WorkSpaces = () => {
  const {
    state: { isWorkSpaceShow, isWorkBookShow },
    handleAddWorkBook,
    handleAddWorkSpace,
  } = useWorkBench();

  const { user } = useApp();

  const [selectedDays, setSelectedDays] = useState<any>(null);

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
      } else {
        setSelectedDays({ timePeriod });
      }
    },
    [setSelectedDays]
  );

  const {
    data: workspacesData,
    isLoading: isWorkSpacesLoading,
    isSuccess: hasWorkSpaceLoaded,
  } = useFetchWorkSpace({
    user: user?.userId,
  });
  const {
    data: workBooksData,
    isLoading: isWorkBooksLoading,
    isSuccess: hasWorkbooksLoaded,
  } = useFetchWorkBooks({
    user: user?.userId,
  });

  const { data: sharedworkspaces } = useFetchSharedData(
    user?.userId,
    "workspace"
  );

  const { data: sharedworkBook } = useFetchSharedData(user?.userId, "workbook");

  const [newWorkSpaceData, setNewWorkSpaceData] = useState<WorkSpaceData>({
    ...newWorkSpaceFormData,
  });

  const [newWorkBookData, setNewWorkBookData] = useState<WorkBookData>({
    ...newWorkBookFormData,
  });

  const {
    mutate,
    isLoading: buttonisLoading,
    isSuccess: isSuccessWorkSpace,
  } = useCreateWorkSpace(handleAddWorkSpace);

  const {
    mutate: mutateWorkbook,
    isLoading: isWorkbookLoading,
    isSuccess: isSuccessWorkBook,
  } = useCreateWorkBook(handleAddWorkBook);

  useEffect(() => {
    setNewWorkBookData({
      ...newWorkBookFormData,
    });
  }, [isWorkBookShow]);

  useEffect(() => {
    setNewWorkSpaceData({
      ...newWorkSpaceFormData,
    });
  }, [isWorkSpaceShow]);

  const updateWorkspaceField = useCallback(
    (field: string) => (value: any) => {
      setNewWorkSpaceData({ ...newWorkSpaceData, [field]: value });
    },
    [newWorkSpaceData]
  );
  const updateWorkbookField = useCallback(
    (field: string) => (value: any) => {
      setNewWorkBookData({ ...newWorkBookData, [field]: value });
    },
    [newWorkBookData]
  );

  const handleSaveSumitWorkSpace = useCallback(() => {
    mutate({
      ...newWorkSpaceData,
      createdBy: user?.userId,
      createdName: user?.name,
    });
  }, [mutate, newWorkSpaceData, user]);

  const handleSaveSumbitWorkBook = useCallback(() => {
    mutateWorkbook({
      ...newWorkBookData,
      createdBy: user?.userId,
      createdName: user?.name,
    });
  }, [mutateWorkbook, newWorkBookData, user]);

  const combinedWorkspacesData = useMemo(() => {
    const regularWorkspaces =
      workspacesData?.map((item: any) => ({
        ...item,
        imageIcon: WorkspaceIcon,
      })) || [];

    const sharedWorkspaces =
      sharedworkspaces?.map((item: any) => ({
        ...item,
        imageIcon: SharedWorkbookIcon,
      })) || [];

    return [...regularWorkspaces, ...sharedWorkspaces];
  }, [workspacesData, sharedworkspaces]);

  const combinedWorkbooksData = useMemo(() => {
    const regularWorkbooks =
      workBooksData?.map((item: any) => ({
        ...item,
        imageIcon: WorkbookIcon,
      })) || [];

    const sharedWorkbooks =
      sharedworkBook?.map((item: any) => ({
        ...item,
        imageIcon: SharedWorkbookIcon,
      })) || [];

    return [...regularWorkbooks, ...sharedWorkbooks];
  }, [workBooksData, sharedworkBook]);

  const WorkBooksProps = useMemo(() => {
    return {
      data: combinedWorkbooksData,
      message: `Click "${"New Workbook"}" to create your first workbook.`,
      imageIcon: WorkbookIcon,
      isLoading: isWorkBooksLoading,
      sidepanelProps: {
        title: "New Workbook",
        Component: AddNewWorkBook,
        componentProps: {
          isOpened: isWorkBookShow,
          changePanelOpened: handleAddWorkBook,
          onSave: handleSaveSumbitWorkBook,
          newWorkBookData,
          updateField: updateWorkbookField,
          isSuccessWorkBook,
        },
        isLoadingButton: isWorkbookLoading,
        isWorkBookShow,
        handleAddWorkBook,
      },
    };
  }, [
    combinedWorkbooksData,
    handleAddWorkBook,
    handleSaveSumbitWorkBook,
    isSuccessWorkBook,
    isWorkBookShow,
    isWorkBooksLoading,
    isWorkbookLoading,
    newWorkBookData,
    updateWorkbookField,
  ]);

  const WorkSpaceProps = useMemo(() => {
    return {
      data: combinedWorkspacesData,
      message: `Click "${"New Workspace"}" to create your first workspace.`,
      isLoading: isWorkSpacesLoading,
      sidepanelProps: {
        title: "New Workspace",
        Component: AddNewWorkSpace,
        componentProps: {
          isOpened: isWorkSpaceShow,
          changePanelOpened: handleAddWorkSpace,
          onSave: handleSaveSumitWorkSpace,
          newWorkSpaceData,
          updateField: updateWorkspaceField,
          isSuccessWorkSpace,
        },
        isLoadingButton: buttonisLoading,
        isWorkSpaceShow,
        handleAddWorkSpace,
      },
    };
  }, [
    combinedWorkspacesData,
    isWorkSpacesLoading,
    isWorkSpaceShow,
    handleAddWorkSpace,
    handleSaveSumitWorkSpace,
    newWorkSpaceData,
    updateWorkspaceField,
    isSuccessWorkSpace,
    buttonisLoading,
  ]);

  const workbookButtonProps = useMemo(() => {
    return {
      show: true,
      onClick: handleAddWorkBook,
      text: "New Workbook",
    };
  }, [handleAddWorkBook]);

  const workspaceButtonProps = useMemo(() => {
    return {
      show: true,
      onClick: handleAddWorkSpace,
      text: "New Workspace",
      eventName: "Create Workspaces",
    };
  }, [handleAddWorkSpace]);

  const cardDetails = useMemo(
    () => [
      {
        title: "Workspaces",
        buttonProps: workspaceButtonProps,
        Component: WorkbenchCards,
        componentProps: WorkSpaceProps,
        componentId: "workspace-container",
        hasCard: hasWorkSpaceLoaded,
      },
      {
        title: "Workbooks",
        buttonProps: workbookButtonProps,
        Component: WorkbenchCards,
        componentProps: WorkBooksProps,
        componentId: "workbooks-container",
        hasCard: hasWorkbooksLoaded,
      },
    ],
    [
      workspaceButtonProps,
      WorkSpaceProps,
      hasWorkSpaceLoaded,
      workbookButtonProps,
      WorkBooksProps,
      hasWorkbooksLoaded,
    ]
  );

  const WorkbenchLayoutProps = useMemo(() => {
    return {
      headerText: "Workspaces",
      cardDetails,
      activityData: {},
      showToolbar: false,
    };
  }, [cardDetails]);

  const pageBodyComponent = () => [
    {
      title: "Project Details",
      Component: Projects,
      componentProps: { showToolbar: false, height: "height-small" },
      pageRoute: "/projects",
      componentId: "projects",
      reloadParams: "fetch-project-clientInfo",
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: {
        showToolbar: false,
        height: "height-small",
        selectedDays: selectedDays,
        onCurrentValueChange: onCurrentValueChange,
      },
      pageRoute: "/activity-dashboard",
      componentId: "activity dashboard",
      reloadParams: "fetch-recent-activity",
    },
    {
      title: "Alerts & Exceptions",
      Component: AlertExceptionPage,
      componentProps: {
        showToolbar: false,
        height: "height-small",
        selectedDays: selectedDays,
        onCurrentValueChange: onCurrentValueChange,
      },
      pageRoute: "/alert-exceptions",
      componentId: "alerts&exceptions",
      reloadParams: "fetch-alert-Exception",
    },
  ];

  const LayoutProps = {
    pageBodyComponent: pageBodyComponent(),
    showToolbar: false,
  };

  return (
    <>
      <WorkbenchLayout {...WorkbenchLayoutProps} />
      <DataGridTitleCardLayout {...LayoutProps} />
    </>
  );
};

export default WorkSpaces;
