import DataGrid, { Column, MasterDetail, Paging } from "devextreme-react/data-grid";
import {
  useCreateUserSpecificRoles,
  useFetchDropDownData,
  useFetchRolesByScope,
  useFetchSpecificProject,
} from "../../../../../hooks/app/useApps";
import {useCallback, useEffect, useMemo, useState, useRef } from "react";
import PsButton from "../../../../../components/ui-components/button/Button";
import PsSkeleton from "../../../../../components/ui-components/skeleton/PsSkeleton";
import { useLocation, useNavigate } from "react-router-dom";
import { CheckBox } from "devextreme-react";
import { useApp } from "../../../../../contexts/app";
import "../../../../../styles/user-administration/accesslist.scss";
import React from "react";

const AccessList = ({ roles, userRole }: { roles: any; userRole: any }) => {
  const clientGridRef = useRef<DataGrid>(null);
  const location = useLocation();
  const userId = location.pathname.split("/")[2];
  const { user } = useApp();
  const userAdmin = user?.userId;

  const navigate = useNavigate();
  const { data: clientInfo } = useFetchDropDownData();
  const { data: projectroleData} = useFetchRolesByScope("Project");

  const selectedData = useMemo(() => {
    const selected: { [key: string]: boolean } = {};
    userRole?.[0]?.client_action?.forEach((action: any) => {
      selected[`${action.clientId}&${action.roleId}`] = action.isChecked || false;
    });
    return selected;
  }, [userRole]);

  const selectedProjectData = useMemo(() => {
    const selected: { [key: string]: boolean } = {};
    userRole?.[0]?.project_action?.forEach((action: any) => {
      selected[`${action.projectId}/${action.roleId}`] = action.isChecked || false;
    });
    return selected;
  }, [userRole]);

  const [localSelectedData, setLocalSelectedData] = useState<{ [key: string]: boolean }>({});
  const [localProjectSelectedData, setLocalProjectSelectedData] = useState<{ [key: string]: boolean }>(selectedProjectData);
  const [pendingAutoCheck, setPendingAutoCheck] = useState<{ [clientId: string]: string[] }>({});
  const [clientProjectRolesMap, setClientProjectRolesMap] = useState<{[clientId: string]: { [projectId: string]: string[] }}>({});

  const onRoleChange = useCallback(
    (clientId: string, newRoleId: string, isChecked: boolean) => {
      const newRole = roles?.find((role: any) => role?.id === newRoleId);
      const newDefaults = newRole?.defaultProjectRoleIds || [];
      setLocalSelectedData((prev) => {
        const newState = { ...prev };
        Object.keys({ ...selectedData, ...prev })?.forEach((key) => {
          if (key?.startsWith(`${clientId}&`)) {
            newState[key] = false;
          }
        });
        if (isChecked) {
          newState[`${clientId}&${newRoleId}`] = true;
          setTimeout(() => {
            if (clientGridRef?.current) {
              clientGridRef?.current?.instance?.expandRow(clientId);
            }
          }, 0);
        }
        if (!isChecked) {
          const associatedProjects = clientProjectRolesMap[clientId];
          let projectIdsToClear: string[] = [];
          if (associatedProjects) {
            projectIdsToClear = Object.keys(associatedProjects);
          } else {
            const seen: { [projectId: string]: boolean } = {};
            Object.keys(localProjectSelectedData)?.forEach((key) => {
              const [projectId] = key?.split("/");
              if (!seen[projectId] && localProjectSelectedData[key]) {
                seen[projectId] = true;
              }
            });
            projectIdsToClear = Object.keys(seen);
          }
          setLocalProjectSelectedData((prev) => {
            const newState = { ...prev };
            projectIdsToClear?.forEach((projectId) => {
              Object.keys(newState)?.forEach((key) => {
                if (key?.startsWith(`${projectId}/`)) {
                  newState[key] = false;
                }
              });
            });
            return newState;
          }); 
          setClientProjectRolesMap((prev) => {
            const newMap = { ...prev };
            delete newMap[clientId];
            return newMap;
          });
        }
        return newState;
      });
      setPendingAutoCheck((prev) => ({
        ...prev,
        [clientId]: isChecked ? newDefaults : [],
      }));
    },
    [roles, selectedData, localProjectSelectedData, clientProjectRolesMap]
  );

const onProjectRoleChange = useCallback(
  (projectId: string, roleId: string, isChecked: boolean) => {
    setLocalProjectSelectedData((prev) => {
      const newState = { ...prev };
      Object.keys(prev)?.forEach((key) => {
        if (key.startsWith(`${projectId}/`) && key !== `${projectId}/${roleId}`) {
          newState[key] = false;
        }
      });
      newState[`${projectId}/${roleId}`] = isChecked; 
      return newState;
    });
  },
  []
);

  const combinedData = useMemo(() => {
    if (!clientInfo || !roles) return [];
    return clientInfo.map((client: any) => {
      const clientUserRoles = userRole?.[0]?.client_action?.filter(
        (action: any) => action.clientId === client.clientId
      ) || [];
      return {
        clientId: client?.clientId,
        clientName: client?.name,
        roles: roles?.map((role: any) => {
          const matchedUserRole = clientUserRoles?.find(
            (userRole: any) => userRole?.roleId === role?.id
          );
          return {
            roleId: role?.id,
            roleName: role?.roleName,
            isActive:
              localSelectedData[`${client?.clientId}&${role?.id}`] ?? 
              selectedData[`${client?.clientId}&${role?.id}`] ?? 
              matchedUserRole?.isChecked ?? false, 
          };
        }),
      };
    });
  }, [clientInfo, roles, userRole, selectedData, localSelectedData]);

  const renderRolesCheckboxes = useCallback((cell: any) => {
    const clientId = cell?.data?.clientId;
    const clientRoles = cell?.data?.roles;
    return (
      <div className="rolesCheckboxes">
        {clientRoles.map((role: any) => {
           const isChecked =
           localSelectedData[`${clientId}&${role.roleId}`] ??
           selectedData[`${clientId}&${role.roleId}`] ??
           false;
           return (
          <CheckBox
            key={`${clientId}-${role.roleId}`}
            id={`${clientId}-${role.roleId}`}
            text={role.roleName}
            defaultValue={isChecked} 
            onValueChanged={(e) => onRoleChange(clientId, role?.roleId, e.value)}
          />
        )})}
      </div>
    );
  },[localSelectedData, onRoleChange, selectedData]);

  const RenderProjectDetail = React.memo(({ clientId }: { clientId: string }) => {
    const { data: projectInfo } = useFetchSpecificProject(clientId);

    useEffect(() => {
      if (!projectInfo || !pendingAutoCheck[clientId]?.length) return;
      const updated = { ...localProjectSelectedData };
      const updatedClientMap: { [projectId: string]: string[] } = {};   
      let hasChange = false;
      projectInfo.forEach((project: any) => {
        const projectId = project?.projectId;
        Object.keys(updated)?.forEach((key) => {
          if (key.startsWith(`${projectId}/`)) {
            updated[key] = false;
          }
        });
        const defaultRoles = pendingAutoCheck[clientId] || [];
        defaultRoles?.forEach((roleId: string) => {
          const key = `${projectId}/${roleId}`;
          updated[key] = true;
          hasChange = true;
          if (!updatedClientMap[projectId]) {
            updatedClientMap[projectId] = [];
          }
          updatedClientMap[projectId].push(roleId);
        });
      });
      if (hasChange) {
        setLocalProjectSelectedData(updated);
        setClientProjectRolesMap((prev) => ({
          ...prev,
          [clientId]: updatedClientMap,
        }));
      }
      setPendingAutoCheck((prev) => {
        const newMap = { ...prev };
        delete newMap[clientId];
        return newMap;
      });
    }, [projectInfo, clientId]);

  const isClientSelected = useMemo(() => {
    const combinedSelection = { ...selectedData, ...localSelectedData };
    return Object.keys(combinedSelection)?.some(
      (key) =>
        key?.startsWith(`${clientId}&`) && combinedSelection[key] === true
    );
  }, [clientId]);

    if (!isClientSelected) {
      return <p style={{ padding: "10px", fontWeight: "bold", color: "red" }}>
        Choose a client-level role to see the associated projects</p>;
    }

    const renderProjectRolesCheckboxes = (cell: any) => {
      const projectId = cell?.data?.projectId;
      if (!projectroleData?.length) {
        <PsSkeleton count={5} height={30} />; 
      }
    
      return (
        <div className="projectRolesCheckboxes">
          {projectroleData?.map((role: any) => {
            const isChecked =
              localProjectSelectedData[`${projectId}/${role.id}`] ??
              false;
    
            return (
              <CheckBox
                key={`${projectId}/${role.id}`}
                id={`${projectId}/${role.id}`}
                text={role.roleName}
                defaultValue={isChecked}
                onValueChanged={(e) => onProjectRoleChange(projectId, role?.id, e.value)}
              />
            );
          })}
        </div>
      );
    };

    return (
      <div style={{ padding: "10px"  }}>
        {projectInfo && projectroleData ? (
          <DataGrid
            dataSource={projectInfo}
            showBorders={true}
            paging={{ pageSize: 3 }}
          >
            <Column caption="Project Name" dataField="projectName" />
            <Column caption="Roles"  cellRender={renderProjectRolesCheckboxes}/>
          </DataGrid>
        ) : (
         <PsSkeleton count={5} height={30} />
        )}
      </div>
    );
  });

  const { mutate } = useCreateUserSpecificRoles();

  const onSave = () => {
    const clientsMap: { [key: string]: { clientId: string; roles: string[] } } = {};
    const projectPermissionsMap: { [key: string]: { projectId: string; roles: string[] } } = {};

    const mergedData = { ...selectedData, ...localSelectedData };
    Object.keys(mergedData).forEach((key) => {
      const [clientId, roleId] = key?.split("&");
      if (mergedData[key]) {
        if (!clientsMap[clientId]) {
          clientsMap[clientId] = { clientId, roles: [] };
        }
        clientsMap[clientId]?.roles?.push(roleId);
      }
    });

    const mergedProjectData = { ...selectedProjectData, ...localProjectSelectedData };
    Object.keys(mergedProjectData).forEach((key) => {
      const parts = key?.split("/"); 
      if (parts?.length === 2) {
        const [ projectId, roleId] = parts; 
        if (mergedProjectData[key]) {
          if (!projectPermissionsMap[projectId]) {
            projectPermissionsMap[projectId] = { projectId, roles: [] };
          }
          projectPermissionsMap[projectId].roles.push(roleId);
        }
      }
    });

    const payload = {
      userId,
      clients: Object.values(clientsMap),
      projectPermissions: Object.values(projectPermissionsMap),
      createdBy: userAdmin,
    };
    mutate(payload);
    navigate(-1);
  };

  const renderProjectDetailMemoized = useCallback(({ data }:any) => {
    return <RenderProjectDetail clientId={data?.data?.clientId} />;
  }, [RenderProjectDetail]);

  return (
    <>
      {clientInfo ? (
        <DataGrid
          dataSource={combinedData}
          showBorders={true}
          columnAutoWidth={true}
          keyExpr="clientId"
          remoteOperations={false}
          ref={clientGridRef}
        >
          <Column caption="Client Name" dataField="clientName" />
          <Column caption="Roles" cellRender={renderRolesCheckboxes} />
          <Paging pageSize={10} />
          <MasterDetail
            enabled={true}
            component={(props) => (
              <div style={{ height: "260px" }}>
                {renderProjectDetailMemoized(props)}
              </div>
            )}
          />
        </DataGrid>
      ) : (
        <PsSkeleton count={5} height={30} />
      )}
      <div className="accessListContainer">
        <PsButton text="Cancel" onClick={() => navigate(-1)} type="default" mode="outlined" />
        <PsButton text="Save" onClick={onSave} type="default" />
      </div>
    </>
  );
};

export default AccessList;