import { useCallback, useState } from "react";
import { DigitalServicesData } from "../../../../../../types/digital-services-add";
import { DigitalServicesEditDetails } from "../../../../../../components/finops/library/digital-services-form/DigitalServicesEditForm";
import { FormPopup } from "../../../../../../components/ui-components/form/form-popup/FormPopup";
import { useCreateDigitalServices } from "../../../../../../hooks/finops/useRatecard";

const DigitalServicesEditPopup = (props: any) => {
  const { 
    editPopupVisible,
    changeEditPopupVisibility,
    selectedRow,
  } = props;

  const [formData, setData] = useState<DigitalServicesData>();
  const { row} = selectedRow || {};
  const { data } = row || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility()
  },[changeEditPopupVisibility]);

  const { mutate,isLoading } = useCreateDigitalServices(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<DigitalServicesData | undefined>) => {
      setData(data);
    }, []
  );
  if(typeof window === 'undefined' ) return null;
  return (
    <>
      <FormPopup
        title="Digital Services Role Details"
        visible={editPopupVisible}
        setVisible={changeEditPopupVisibility}
        onSave={handleFormSubmit}
        isLoading={isLoading}
      >
        <DigitalServicesEditDetails
          data={data}
          editing={true}
          onDataChanged={onDataChanged} 
        />  
      </FormPopup>
    </>
  )
}
export default DigitalServicesEditPopup;