import { useCallback, useMemo, useState } from "react";
import { Item } from "devextreme-react/toolbar";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import ProjectSummary from "./helper-component/ProjectSummary";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import { useFetchSpecificProjectProfile } from "../../../hooks/client/useProjectProfile";
import "../../../styles/client/project-profile.scss";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import CardSkeleton from "../../../components/ui-components/DataGridMultiViewLayout/helper-component/CardSkeleton";
import { useLocation } from "react-router-dom";

export const ProjectProfile = () => {
  const location = useLocation();
  const currentPermission = useMemo(() => location?.state?.currentPermission || {}, [location?.state?.currentPermission]);
  const { data: DateRange } = useFetchDateRange();
  const projectId = useHelperHooks().getQueryParams("projectId");

  const [refreshKey, setRefreshKey] = useState(0);

  const {
    data: projectData,
    refetch,
    isFetching,
  } = useFetchSpecificProjectProfile(projectId as string);

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const project = projectData?.length > 0 ? projectData[0] : {};
  const filteredProjectId = project?.projectId;
  const filteredClientId = project?.clientId;

  const {
    state: { selectedDays },
    handleDateRangeChange
  } = useAppHook();

  const DataGridProps = useMemo(() => {
    return {
      projectData,
      selectedDays,
      refetch,
      currentPermission,
      refreshKey,
      handleRefetch,
    };
  }, [projectData, refetch, selectedDays, currentPermission, refreshKey]);

  const title = projectData
    ? `Project 360 - ${project?.projectName}`
    : "Project 360";

  const AnalyticsComponent = useCallback(() => {
    return projectData ? (
      <ProjectSummary {...DataGridProps} />) :
      (< CardSkeleton />)
  }, [DataGridProps, projectData]);

  const pageBodyComponent = () => [
    {
      title: "Project Summary",
      Component: AnalyticsComponent,
      componentProps: DataGridProps,
      reloadParams: [
        "fetch-specific-project",
        "fetch-ytdmtd-cost",
        "fetch-ytdmtd-cost",
        "fetch-project-data-list",
        "fetch-project-task",
        "fetch-collaboration",
        "fetch-project-invoice-info",
      ],
      pageRoute: `/project-profile?projectId=${projectId}`,
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: {
        showToolbar: false,
        height: "height-medium",
        selectedDays: selectedDays,
        filteredProject: projectId,
        onCurrentValueChange: handleDateRangeChange,
        title: "Recent Activity",
        pageRoute: "/activity-dashboard",
      },
      pageRoute: "/activity-dashboard",
      componentId: "activitydashboard",
      reloadParams: "fetch-recent-activity",
      titleToolBar: true,
      hideHeader: true
    },
    {
      title: "Alerts & Exceptions",
      Component: AlertExceptionPage,
      componentProps: {
        showToolbar: false,
        height: "height-medium",
        selectedDays: selectedDays,
        filteredClientId: filteredClientId,
        filteredProjectId: filteredProjectId,
        onCurrentValueChange: handleDateRangeChange,
        title: "Alerts & Exceptions",
        pageRoute: "/alert-exceptions",
      },
      pageRoute: "/alert-exceptions",
      componentId: "alertsexceptions",
      reloadParams: "fetch-alert-Exception",
      titleToolBar: true,
      hideHeader: true
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} />
    </Item>
  );

  const LayoutProps = {
    headerText: title,
    additionalToolbarContent,
    isLoading: isFetching,
    showBackButton: true,
    pageBodyComponent: pageBodyComponent(),
  };

  return (
    <>
      <DataGridTitleCardLayout {...LayoutProps} />
    </>
  );
};
