import React, { useCallback, useEffect, useState } from "react";
import DropDownBox, { DropDownBoxTypes } from "devextreme-react/drop-down-box";
import DataGrid, {
  Selection,
  Paging,
  Scrolling,
  HeaderFilter,
  Pager,
  Sorting,
} from "devextreme-react/data-grid";
import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import CustomStore from "devextreme/data/custom_store";
import { getSizeQualifier } from "../../../../utlis/media-query";
import {
  useCreateWorkSpace,
  useFetchWorkBooks,
  useFetchWorkSpaceDetails,
  useWorkBench,
} from "../../../../hooks/workbench/useWorkbench";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { SelectBox } from "devextreme-react";
import { useApp } from "../../../../contexts/app";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
import { RightSidePannel } from "../../../../components/ui-components/right-side-panel/right-side-panel";

const gridColumnsProjects = ["projectName"];
const gridColumnsWorkbooks = ["workbookName"];
const ownerLabel = { "aria-label": "Owner" };

const AssignWorkSpaceForm = (props: any) => {
  const {
    workspaceId,
    changePanelOpened,
    workspaceName,
    isWorkSpaceAddItemShow,
  } = props;
  const { clientData, projectInfo, onHandleClientSelection } =
  useWorkBench();

  const { user } = useApp();
  const { data: workBookData } = useFetchWorkBooks({ user: user?.userId });
  const { data: workspacesData } = useFetchWorkSpaceDetails({
    workspaceId: workspaceId,
  });

  const { data } = workspacesData || {};

  const projectIds = data?.flatMap((workspace: { assets: any[] }) =>
    workspace?.assets?.flatMap((asset) =>
      asset.projects.map((project: { projectId: any }) => project.projectId)
    )
  );
  const workbookIds = data?.flatMap((workspace: { assets: any[] }) =>
    workspace?.assets?.flatMap((asset) =>
      asset.workbooks.map((book: { workbookId: any }) => book.workbookId)
    )
  );

  const filteredProjectData = projectInfo?.filter(
    (entry: { projectId: any }) => !projectIds?.includes(entry.projectId)
  );
  const filteredWorkbookData = workBookData?.filter(
    (entry: { id: any }) => !workbookIds?.includes(entry.id)
  );

  const [projectGridBoxValue, setProjectGridBoxValue] = useState([]);
  const [workbookBoxvalue, setWorkbookBoxvalue] = useState([]);
  const [isProjectGridBoxOpened, setIsProjectGridBoxOpened] = useState(false);
  const [isWorkbookGridBoxOpened, setIsWorkbookGridBoxOpened] = useState(false);
  const [workBookFinalData, setWorkBookFinalData] = useState([]) as any[];
  const [projectFinalData, setProjectFinalData] = useState([]) as any[];

  const projectDataGridOnSelectionChanged = useCallback(
    (e: { selectedRowKeys: any; selectedRowsData: any }) => {
      const { selectedRowKeys, selectedRowsData } = e;
      setProjectGridBoxValue(selectedRowKeys);
      setIsProjectGridBoxOpened(true);
      setProjectFinalData(selectedRowsData);
    },
    []
  );
  const workBookGridOnSelectionChanged = useCallback(
    (e: { selectedRowKeys: any; selectedRowsData: any }) => {
      const { selectedRowKeys, selectedRowsData } = e;
      setWorkbookBoxvalue(selectedRowKeys);
      setIsWorkbookGridBoxOpened(true);
      setWorkBookFinalData(selectedRowsData);
    },
    []
  );

  const makeAsyncProjectDataSource = (
    localData: any[],
    keysToRemove: any[]
  ) => {
    if (!Array.isArray(localData) || localData.length === 0) {
      return null;
    }

    const transformedData = localData.map((item) => {
      const { id, ...rest } = item;
      const transformedItem = { ID: id, ...rest };

      keysToRemove.forEach((key) => {
        delete transformedItem[key];
      });

      return transformedItem;
    });

    if (!transformedData.length || !transformedData[0].hasOwnProperty("ID")) {
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "ID",
      load: () => transformedData,
    });
  };
  const makeAsyncWorkbookDataSource = (
    localData: any[],
    keysToRemove: any[]
  ) => {
    if (!Array.isArray(localData) || localData.length === 0) {
      return null;
    }

    const transformedData = localData.map(
      ({ id, name, title, creater, ...rest }) => {
        const transformedItem = {
          workbookId: id,
          workbookName: title,
          createdBy: creater,
          ...rest,
        };

        keysToRemove.forEach((key) => {
          delete transformedItem[key];
        });

        return transformedItem;
      }
    );

    if (
      !transformedData.length ||
      !transformedData[0].hasOwnProperty("workbookId")
    ) {
      return null;
    }

    return new CustomStore({
      loadMode: "raw",
      key: "workbookId",
      load: () => transformedData,
    });
  };

  const keysToRemove = ["onClick", "description"];
  const keysToRemoveProject = [
    "status",
    "projectDescription",
    "endDate",
    "startDate",
    "clientName",
    "clientId",
  ];
  const projectGridDataSource = makeAsyncProjectDataSource(
    filteredProjectData,
    keysToRemoveProject
  );
  const workbookGridDataSource = makeAsyncWorkbookDataSource(
    filteredWorkbookData,
    keysToRemove
  );

  const gridProjectBoxDisplayExpr = (item: { projectName: any }) =>
    item && `${item.projectName}`;

  const workbookBoxDisplayExpr = (item: { workbookName: any }) =>
    item && `${item.workbookName}`;

  const projectDataGridRender = useCallback(
    (e: any) => (
      <DataGrid
        dataSource={projectGridDataSource}
        columns={gridColumnsProjects}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={projectGridBoxValue}
        onSelectionChanged={projectDataGridOnSelectionChanged}
        height="100%"
        width="100%"
      >
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <Sorting mode="multiple" />
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
        <HeaderFilter visible />
      </DataGrid>
    ),
    [
      projectGridDataSource,
      projectGridBoxValue,
      projectDataGridOnSelectionChanged,
    ]
  );
  const workbookDataGridRender = useCallback(
    (e: any) => (
      <DataGrid
        dataSource={workbookGridDataSource}
        columns={gridColumnsWorkbooks}
        hoverStateEnabled={true}
        showBorders={true}
        selectedRowKeys={workbookBoxvalue}
        onSelectionChanged={workBookGridOnSelectionChanged}
        height="100%"
        width="100%"
      >
        <Selection mode="multiple" />
        <Scrolling mode="virtual" />
        <Paging enabled={true} pageSize={10} />
        <HeaderFilter visible />
        {/* <FilterRow visible={true} /> */}
      </DataGrid>
    ),
    [workbookGridDataSource, workbookBoxvalue, workBookGridOnSelectionChanged]
  );

  const syncProjectDataGridSelection = useCallback(
    (e: DropDownBoxTypes.ValueChangedEvent) => {
      setProjectGridBoxValue(e.value);
    },
    []
  );
  const syncWorkbookDataGridSelection = useCallback(
    (e: DropDownBoxTypes.ValueChangedEvent) => {
      setWorkbookBoxvalue(e.value);
    },
    []
  );

  const onProjectGridBoxOpened = useCallback(
    (e: DropDownBoxTypes.OptionChangedEvent) => {
      if (e.name === "opened") {
        setIsProjectGridBoxOpened(e.value);
      }
    },
    []
  );
  const onWorkbookBoxOpened = useCallback(
    (e: DropDownBoxTypes.OptionChangedEvent) => {
      if (e.name === "opened") {
        setIsWorkbookGridBoxOpened(e.value);
      }
    },
    []
  );
  const isEdit = true;

  const { mutate, isLoading, isSuccess } = useCreateWorkSpace(
    changePanelOpened,
    isEdit
  );

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `Workspace Item Added Successfully`,
      });
    }
  }, [isSuccess]);

  const handleFormSubmitData = useCallback(() => {
    try {
      if (workBookFinalData?.length !== 0 || projectFinalData?.length !== 0) {
        const payload = {
          workbooks: workBookFinalData,
          projects: projectFinalData,
          workspaceId: workspaceId,
          updatedBy: user?.userId,
          workSpaceName: workspaceName,
          updatedName: user?.name,
        };
        mutate(payload);
      } else {
        showNotification({
          message: `Please select a project or workbook`,
          type: "warning",
        });
      }
    } catch {
      console.log("error");
    }
  }, [
    mutate,
    projectFinalData,
    user,
    workBookFinalData,
    workspaceId,
    workspaceName,
  ]);

  return (
    <div>
      <RightSidePannel
        isOpened={isWorkSpaceAddItemShow}
        changePanelOpened={changePanelOpened}
        title={"Add Items"}
        onSave={handleFormSubmitData}
        isLoading={isLoading}
      >
        <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
          <GroupItem>
            <ColCountByScreen xs={1} />
            <FormItem>
              <SelectBox
                dataSource={clientData}
                placeholder="Select Client..."
                displayExpr="name"
                onValueChanged={onHandleClientSelection}
              />
            </FormItem>
            <FormItem>
              <DropDownBox
                value={projectGridBoxValue}
                opened={isProjectGridBoxOpened}
                valueExpr="projectId"
                deferRendering={false}
                inputAttr={ownerLabel}
                displayExpr={gridProjectBoxDisplayExpr}
                placeholder="Add projects..."
                showClearButton={true}
                dataSource={projectGridDataSource}
                onValueChanged={syncProjectDataGridSelection}
                onOptionChanged={onProjectGridBoxOpened}
                contentRender={projectDataGridRender}
              />
            </FormItem>
            <FormItem>
              <DropDownBox
                value={workbookBoxvalue}
                opened={isWorkbookGridBoxOpened}
                valueExpr="workbookId"
                deferRendering={false}
                inputAttr={ownerLabel}
                displayExpr={workbookBoxDisplayExpr}
                placeholder="Add Workbook..."
                showClearButton={true}
                dataSource={workbookGridDataSource}
                onValueChanged={syncWorkbookDataGridSelection}
                onOptionChanged={onWorkbookBoxOpened}
                contentRender={workbookDataGridRender}
                className="dx-field-value"
              />
            </FormItem>
          </GroupItem>
        </Form>
      </RightSidePannel>
    </div>
  );
};

export default AssignWorkSpaceForm;
