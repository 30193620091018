import Card from "../../../../../../components/ui-components/card/card";
import EdgeTypesFlow from "./react-flow/data-flow";

const WorkflowSteps = (props: any) => {

  const { data } = props;

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <EdgeTypesFlow
          data={data}
        />
      </Card>
    </div>
  );
};

export default WorkflowSteps;

