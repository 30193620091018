import {
  createContext,
  ReactNode,
  useContext,
  useState,
} from "react";

interface ErrorProviderProps {
  children: ReactNode;
}

type ErrorType =
  | "componentError"
  | "APIError"
  | "networkError"
  | "unknownError";

type ErrorContextType = {
  addError: (error: Error | string, errorType: ErrorType) => void;
  errors: Array<string | Error>;
};

interface ErrorItem {
  message: string;
  type: ErrorType;
}

const ErrorContext = createContext<ErrorContextType | undefined>(undefined);

export const useErrorContext = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error("useErrorContext must be used within an ErrorProvider");
  }
  return context;
};

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
  const [errors, setErrors] = useState<Array<Error | any>>(() => {
    const storedErrors = localStorage.getItem("errors");
    return storedErrors ? JSON.parse(storedErrors) : [];
  });

  const addError = (error: Error | string, errorType: ErrorType) => {
    const errorString = error instanceof Error ? error.message : error;
    setErrors((prevErrors: any) => {
      const updatedErrors: ErrorItem[] = [
        ...prevErrors,
        {
          message: errorString,
          type: errorType,
          time: new Date().toISOString(),
        },
      ];
      localStorage.setItem("errors", JSON.stringify(updatedErrors));
      return updatedErrors;
    });
  };


  
  return (
    <ErrorContext.Provider value={{ addError, errors }}>
      {children}
    </ErrorContext.Provider>
  );
};
