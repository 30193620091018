// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow-x: auto;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  border-radius: 100px;
}

.pagination button:hover {
  background-color: #f5f5f5;
}

.pagination button.active {
  background-color: #007acc;
  color: #fff;
  border-color: #007acc;
}

.pagination button:disabled {
  background-color: #eee;
  color: #999;
  cursor: not-allowed;
}

.pagination button:first-child,
.pagination button:last-child {
  margin: 0 10px;
}

.tele-icon {
  flex: auto;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/Pagination.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,sBAAA;EACA,WAAA;EACA,eAAA;EACA,6CAAA;EACA,oBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;EACA,qBAAA;AACF;;AAEA;EACE,sBAAA;EACA,WAAA;EACA,mBAAA;AACF;;AAEA;;EAEE,cAAA;AACF;;AAEA;EACE,UAAA;AACF","sourcesContent":[".pagination {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  margin-top: 20px;\n  margin-bottom: 20px;\n  overflow-x: auto;\n}\n\n.pagination button {\n  margin: 0 5px;\n  padding: 5px 10px;\n  border: 1px solid #ddd;\n  background-color: #fff;\n  color: #333;\n  cursor: pointer;\n  transition: background-color 0.3s, color 0.3s;\n  border-radius: 100px;\n}\n\n.pagination button:hover {\n  background-color: #f5f5f5;\n}\n\n.pagination button.active {\n  background-color: #007acc;\n  color: #fff;\n  border-color: #007acc;\n}\n\n.pagination button:disabled {\n  background-color: #eee;\n  color: #999;\n  cursor: not-allowed;\n}\n\n.pagination button:first-child,\n.pagination button:last-child {\n  margin: 0 10px;\n}\n\n.tele-icon {\n  flex: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
