import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import "../../../../../../styles/client/opexStyles.scss";
import { MetricsTicker } from "../../../../../../components/ui-components/ticker-card/TickerCardNew";

const OpexRowCard = ({ opexCardData, isOpexLoading }: any) => {
  if (isOpexLoading) {
    const skeletons = Array.from({ length: 8 }, (_, index) => (
      <PsSkeleton key={index} count={1} height={150} />
    ));

    return <div className="opex-row-card">{skeletons}</div>;
  }

  return (
    <div className="opex-row-card">
      {opexCardData?.map((item: any, index: any) => (
        <MetricsTicker
          key={index}
          title={item?.name}
          icon={item?.image}
          primaryValue={item?.primaryValue}
          secondaryValue={item?.secondaryValue}
        />
      ))}
    </div>
  );
};

export default OpexRowCard;
