import { decryptText } from "./helper";

/* eslint-disable no-restricted-globals */
declare global {
  interface Window {
    gtag: any;
  }
}
export const GTAPageEventHandler = (location: any) => {
  gtag("event", "polaris_page_view", {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
  });
};

export const GTAButtonEventHandler = (labelName: string, value?: any) => {
  const specialKey = "***&&&key***";
  const userInfo = localStorage.getItem("userInfo");
  const decryptedData = decryptText(userInfo, specialKey);
  const userName = decryptedData?.shortName || "unknown";
  gtag("event", "polaris_user_activity", {
    user_id: userName,
    button_name: labelName,
    page_location: location.href,
    page_referrer: document.referrer,
    event_timestamp: new Date().toISOString(),
  });
};
