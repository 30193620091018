import { TextArea } from "devextreme-react";
import Card from "../../../../../../components/ui-components/card/card";

const HeaderTab = (props: any) => {

  const { data } = props;

  const headerData = data?.header

  return (
    <div className="workflow-tabs">
      <Card className="workflow-wrapper">
        <div className="dx-field">
          <div className="dx-field-label">Workflow Name</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.workflowName}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Description</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.description}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Version</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.version}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Activity</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.activity}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Type</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.type}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Application Name</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.applicationName}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Client Name</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.clientName}
              readOnly={true}
            />
          </div>
        </div >
        <div className="dx-field">
          <div className="dx-field-label">Project Name</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.projectName}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Service Name</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.serviceName}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Module Id</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.moduleId}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Source</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.source}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Target</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.target}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Priority</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.priority}
              readOnly={true}
            />
          </div>
        </div>
        <div className="dx-field">
          <div className="dx-field-label">Severity</div>
          <div className="dx-field-value">
            <TextArea
              value={headerData?.severity}
              readOnly={true}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default HeaderTab;