import { TreeView } from "devextreme-react";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import {
  transformProjectStorageData,
  useFetchSelectedProjectFileData,
  useFetchProjectDataList,
  useProjectProfile,
} from "../../../../hooks/client/useProjectProfile";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import ProjectDataFileUploader from "./ProjectDataFileUploader";
import { useMemo } from "react";
import uploadIcon from "../../../../assets/icons/icon-upload-1.png";
import "../../../../styles/client/dataTab.scss";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { GridComponent } from "../../../core-component/dataLakeGrid/GridComponent";
import { location } from "../../../../config/client/commonConfig";

export const ProjectDataTab = (props: any) => {
  const { selectedDays, projectData, handleColumnIndexChange } = props;
  const projectId = useHelperHooks().getQueryParams("projectId") ?? "";
  const { data: projectDataList, isFetching  } = useFetchProjectDataList(projectId);
  const project = projectData?.length > 0 ? projectData[0] : {};
  const projectName = project?.projectName;

  const finalData = useMemo(() => {
      return projectDataList ? transformProjectStorageData(projectDataList, projectName) : [];
    }, [projectDataList, projectName]);

  const {
    state: { fileViewerPopupVisible, selectedFileData, popupAddFilesVisible },
    onViewScrapingButtonClick,
    handleAddFilesClick,
  } = useProjectProfile();

  const { data: SelectedProjectFileData, isLoading } =
    useFetchSelectedProjectFileData(selectedFileData);

  const renderItem = (item: any) => {
    const gridProps = {
      id: {"projectId":projectId},
      location: location[item?.id],
      projectShortName: project?.projectShortName,
      onViewScrapingButtonClick,
      handleColumnIndexChange,
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? (
          <GridComponent {...gridProps} />
        ) : null}
      </div>
    );
  };

  const fileUploaderProps = useMemo(() => {
    return {
      popupAddFilesVisible,
      projectId,
      handleFormPopupClose: handleAddFilesClick,
    };
  }, [handleAddFilesClick, projectId, popupAddFilesVisible]);

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
      <div className="dataTab">
        <PSIconText
          alt="addFiles"
          text="Upload Files"
          src={uploadIcon}
          onClick={handleAddFilesClick}
        />
      </div>
      <ProjectDataFileUploader {...fileUploaderProps} />
      {isFetching ? (
        <PsSkeleton height={30} count={6} />
      ) : (
        <TreeView id="simple-treeview" items={finalData} itemRender={renderItem} />
      )}
      
      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewScrapingButtonClick}
        data={
          selectedFileData?.fileExtension === "pdf"
            ? SelectedProjectFileData?.data
            : JSON.stringify(SelectedProjectFileData?.data)
        }
        title={title}
        isLoading={isLoading}
        extensionType={selectedFileData?.fileExtension}
      />
    </>
  );
};

export default ProjectDataTab;
