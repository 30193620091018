import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import ProjectDetails from "../../../../components/client/project-details/ProfileTab/projectDetails";
import { TasksTab } from "../../../../components/client/project-details/TasksTab/tasksTab";
import ProjectDataTab from "../../../../components/client/project-details/DataTab/ProjectDataTab";
import ResourcesTab from "../../../../components/client/project-details/ResourcesTab/resourcesTab";
import InfrastructureTab from "../../../../components/client/project-details/InfrastructureTab/infrastructureTab";
import FinopsTab from "../../../../components/client/project-details/FinopsTab/finopsTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";

const ProjectSummary = (props: any) => {
  const { selectedDays, projectData, refetch } = props;
  const TabMap = [
    { title: "Project Profile", component: ProjectDetails, componentProps: { selectedDays } },
    { title: "Tasks", component: TasksTab },
    {
      title: "Data",
      component: ProjectDataTab,
      componentProps: { selectedDays, projectData },
    },
    {
      title: "Team",
      component: ResourcesTab,
      componentProps: { selectedDays, ResourceData: projectData, refetch },
    },
    {
      title: "Infrastructure",
      component: InfrastructureTab,
      componentProps: { selectedDays, ResourceData: projectData, refetch },
    },
    { title: "Finops", component: FinopsTab, componentProps: { projectData } },
    { title: "Collaboration", component: CollaborationChats },
  ];

  const TabPanelItems = TabMap.map((tab, index) => {
    const Component = tab.component;
    return (
      <TabPanelItem title={tab.title} key={index}>
        <div style={{ minHeight:"465px" }}>
          <Component {...tab.componentProps} />
        </div>
      </TabPanelItem>
    );
  });

  return (
    <TabPanel
      focusStateEnabled={false}
      deferRendering={false}
      animationEnabled={true}
      swipeEnabled={true}
    >
      {TabPanelItems}
    </TabPanel>
  );
};

export default ProjectSummary;
