import { Routes, Route, Navigate } from "react-router-dom";
import { appInfo } from "./app-info";
import { appRoutes } from "./app-routes";
import ScrollView from "devextreme-react/scroll-view";
import { SideNavOuterToolbar } from "./components/ui-components/layout/side-nav-outer-toolbar/side-nav-outer-toolbar";
import StaticInfo from "./pages/static-info/Staticinfo";
import { useApp } from "./contexts/app";
import StaticLoading from "./pages/static-info/StaticLoading";

export const Content = () => {
  const { status, isLoading, user } = useApp();
 const userStatus =  Array.isArray(status) ? status[0].status : status;
  if (isLoading || (!user && !userStatus)) {
    return <StaticLoading />;
  }

  if (userStatus === "Pending") {
    return <StaticInfo />;
  }

  

  return (
    <SideNavOuterToolbar title={appInfo.title}>
      <ScrollView className="view-wrapper-scroll">
        <div className="view-wrapper view-wrapper-contact-list list-page">
          <Routes>
            {appRoutes.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
      </ScrollView>
    </SideNavOuterToolbar>
  );
};
