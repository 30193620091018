import { formatDate } from "devextreme/localization";
import { customDateRange } from "../../config/constants";
import { del, get, post, update } from "../../service/api/api";
import { constructDynamicUrl, makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchRecentProjectApi = async (recent: string, count: number) => {
  const response = await get(
    `finops/v1/addresource/resourceinfo?recent=${recent}&count=${count}`
  );

  return response;
}; 

export const fetchManagementApi = async (viewType: string) => {
  const response = await get(`client/v1/project/summary/all`);

  return response;
}; 

export const fetchSpecificClientApi = async (id: string) => {
  const response = await get(
    `client/v1/client/info/client/${id}`
  );
  return response;
};

export const fetchClientCatalogApi = async (clientId: any) => {
  const reportsquery = constructDynamicUrl({clientId});
  const response = await get(`client/v1/client/info/${reportsquery}`);
  return response;
};


export const createUrlApi = async (payload: any) => {
  const response = await post("webscraping/v1/urls/create", payload);
  return response;
}; 

export const bulkURLsUploadApi = async (payload: any) => {
  const response = await post("webscraping/v1/urls/bulk_upload", payload);
  return response;
}

export const updateUrlApi = async (payload: any) => {
  const response = await update("webscraping/v1/urls/update", payload);
  return response;
}; 

export const fetchUrlsApi = async (_id: string) => {
  const response = await get(`webscraping/v1/urls/urls-info/${_id}`);
  return response;
}; 

export const deleteUrlClientById = async (url_id: string) => {
  const response = await del(`webscraping/v1/urls/delete/${url_id}`);
  return response;
}; 

export const fetchAllUrlsApi = async () => {
  const response = await get("webscraping/v1/urls/urls-info/all");
  return response;
}; 

export const createClientApi = async (payload: any) => {
  const response = await post("client/v1/client/create", payload);
  return response;
}; 

export const updateClientApi = async (payload: any) => {
  const clientId = payload?.clientId;
  const response = await update(`client/v1/client/update/${clientId}`, payload);
  return response;
}; //used

export const deleteClientById = async (clientId: string) => {
  const response = await del(`client/v1/client/delete/${clientId}`);
  return response;
};  

export const fetchActiveTaskApi = async (data: any) => {
  const { timePeriod, clientId } = data || {};
  const url = clientId ? `automation/v1/activetasks/count/${timePeriod}/${clientId}` 
                       : `automation/v1/activetasks/count/${timePeriod}`;
  const response = await get(url);
  return response;
}; 

export const fetchTaskDetailsApi = async (clientId: string | null) => {
  const response = await get(
    `task/v1/task?type=taskcount&clientId=${clientId}`
  );
  return response;
};

export const fetchTemplateApi = async () => {
  const response = await get("webscraping/v1/templates/templates-info/all");
  return response;
}; 

export const fetchResouceDetailsApi = async () => {
  const type = 'all'
  const response = await get(`finops/v1/ratecard/resource?type=${type}`);
  return response;
}; 

export const fetchYTDMTDCostApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `finops/v1/costcalculation/calculation${query}`
  );
  const response = await get(validUrl);
  return response;
}; 

export const createProjectApi = async (payload: any) => {
  const response = await post("client/v1/project/create", payload);
  return response;
}; 

export const updateProjectApi = async (payload: any) => {
  const projectId = payload?.projectId 
  const response = await update(`client/v1/project/update/${projectId}`, payload);
  return response;
}; 

export const fetchSpecificProjectEditDetailsApi = async (id: string) => {
  const response = await get(`client/v1/project/info/project/${id}`);
  return response;
}; 

export const fetchTechnicalResouceDetailsApi = async () => {
  const type = "digitalServices";
  const response = await get(`finops/v1/ratecard/resource?type=${type}`);
  return response;
}; 

export const fetchOperationsResouceDetailsApi = async () => {
  const type = "operations";
  const response = await get(`finops/v1/ratecard/resource?type=${type}`);
  return response;
}; 

export const fetchProjectApi = async (queryParams:any) => {
  if(queryParams.timePeriod === customDateRange && (!queryParams.startTime || !queryParams.endTime)) {return}
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`client/v1/project/info/all${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchopexCardDataApi = async (clientId: any) => {
  const reportsquery = constructDynamicUrl(clientId);
  const query = makeQueryParam({ daterange: "YTD" });
  const validUrl = validateEndPoint(`reporting/v1/summary/retrieve/${reportsquery}${query}`);
  const response = await get(validUrl);
  return response;
};   



export const fetchSepecificProjectApi = async (
  projectId: string,
  activityType: string,
  projectName: string,
  clientName: string
) => {
  const response = await get(
    `finops/v1/projectview/projectview?projectId=${projectId}&activity_type=${activityType}&projectname=${projectName}&clientname=${clientName}`
  );
  return response;
}; 

export const fetchManageApi = async (projectId: string, viewtype: string) => {
  const response = await get(
    `finops/v1/projectview/projectview?viewtype=${viewtype}&projectId=${projectId}`
  );
  return response;
}; 

export const fetchProjectTypeApi = async (func_type: any) => {
  const response = await get(
    `core/v1/functiondropdown/dropdownInfo/${func_type}`
  );
  return response;
}; 

export const fetchEstimatorInfo = async () => {
  const response = await get(`finops/v1/resourcecalaculator/resourceinfo`);
  return response;
}; 

export const fetchProjectProfileApi = async (projectId: string) => {
  const response = await get(`client/v1/project/info/project/${projectId}`);
  return response;
}; 

export const fetchProjectTaskApi = async (projectId: string) => {
  const response = await get(`automation/v1/automation/info/project/${projectId}`);
  return response;
}; 

export const fetchProjectDetailComponentApi = async (projectId: string) => {
  const response = await get(
    `finops/v1/projectview/projectview?projectId=${projectId}`
  );
  return response;
}; 
export const fetchProjectClientInfoApi = async (clientId: string) => {
  const response = await get(
    `common_component/v1/common_component?clientId=${clientId}`
  );
  return response;
};

export const fetchProjectInvoiceApi = async (
  clientId: string,
  projectId: string
) => {
  const response = await get(
    `finops/v1/invoice/client/project?invoice-get=True&clientId=${clientId}&projectId=${projectId}`
  );
  return response;
}; 

export const fetchProjectProfileGraphApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `finops/v1/costcalculation/calculation${query}`
  );
  const response = await get(validUrl);
  return response;
}; 

export const fetchProjectData = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `datalake/v1/file-manager/retrieve/viewfile${query}`
  );
  const response = await get(validUrl);
  return response;
}; 

export const fetchProjectDataList = async (id: any) => {
  const response = await get(`datalake/v1/file-manager/retrieve/project/${id}?structure=${true}`);
  return response;
}; 

export const fetchClientDataList = async (id: any) => {
  const response = await get(`datalake/v1/file-manager/retrieve/client/${id}`);
  return response;
}; 

 

export const fileUploadProjectApi = async (payload: any) => {
  const response = await post("client/v1/upload", payload);
  return response;
}; 

export const fetchProjectResourcesApi = async (projectId: string) => {
  const response = await get(
    `finops/v1/addresource/resourceinfo?resources=True&projectId=${projectId}`
  );
  return response;
}; 

export const fetchProjectResourcesAddApi = async (payload: any) => {
  const response = await update("finops/v1/addresource/updateresource", payload);
  return response;
}; 

export const fetchUrlSummaryApi = async () => {
  const response = await get("webscraping/v1/urls/urls-summary");
  return response;
}; 