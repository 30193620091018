import { RightSidePannel } from "../../../../components/ui-components/right-side-panel/right-side-panel";
import { useCallback, useMemo } from "react";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";
import FileViewCard from "../../../../components/ui-components/fileViewCard/FileViewcard";
import {
  useFetchActivityItemsPdf,
  useWorkBench,
} from "../../../../hooks/workbench/useWorkbench";
import AddManualDataEntryGrid from "../details/workbookItemGrids/ManualEntryGrid/ManualEntryGrid";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";

const WorkbookDetailsCards = (props: any) => {
  const { data, isLoading, message, sidepanelProps } = props;
  const { title, Component, componentProps, isLoadingButton } =
    sidepanelProps || {};
  const { isOpened, changePanelOpened, onSave } = componentProps || {};

  const {
    state: {
      workBookAssetType,
      editPopupVisible,
      selectedManualEntryData,
      pdfFilePath,
    },
    OnHandleWorkbookCardItemClick,
  } = useWorkBench();

  const manualEntryProps = useMemo(() => {
    return {
      editPopupVisible: editPopupVisible,
      workBookAssetType: workBookAssetType,
      changeEditPopupVisibility: OnHandleWorkbookCardItemClick,
      selectedManualEntryData,
    };
  }, [
    OnHandleWorkbookCardItemClick,
    editPopupVisible,
    selectedManualEntryData,
    workBookAssetType,
  ]);

  const { data: dataGridPdfFiles, isLoading: isPdfLoading } =
    useFetchActivityItemsPdf(pdfFilePath);

  const renderPerformance = useCallback(
    (item: any) => {
      const {
        fileName,
        fileId,
        fileDescription,
        createdName,
        uploadedOn,
        imageUrl,
      } = item;
      try {
        const workspaceData = {
          title: fileName,
          creater: createdName,
          description: fileDescription,
          date: uploadedOn,
          image: imageUrl,
          dataa: item,
          onClick: OnHandleWorkbookCardItemClick,
        };
        return <FileViewCard key={fileId} {...workspaceData} />;
      } catch (err) {
        console.error("Error rendering Performance:", err);
      }
    },
    [OnHandleWorkbookCardItemClick]
  );

  const renderpanel = useCallback(() => {
    if (sidepanelProps && isOpened)
      return (
        <RightSidePannel
          title={title}
          isOpened={isOpened}
          changePanelOpened={changePanelOpened}
          onSave={onSave}
          isLoading={isLoadingButton}
        >
          <Component {...componentProps} />
        </RightSidePannel>
      );
  }, [
    Component,
    changePanelOpened,
    componentProps,
    isLoadingButton,
    isOpened,
    onSave,
    sidepanelProps,
    title,
  ]);

  if (isLoading) {
    return (
      <div className="card-container">
        <PsSkeleton count={1} height={150} width={200} />
        <PsSkeleton count={1} height={150} width={200} />
        <PsSkeleton count={1} height={150} width={200} />
      </div>
    );
  }

  if (!data && !isLoading) {
    return <div className="no-data-message">{message}</div>;
  }

  return (
    <>
      <div className="workbench-new">
        <div className="content-pannel">
          <div className="card-container" id="worksspace-details-card">
            {data?.map((item: any) => renderPerformance(item))}
          </div>
        </div>
      </div>
      {renderpanel()}
      {workBookAssetType === "Manual Data Entry" ? (
        <AddManualDataEntryGrid {...manualEntryProps} />
      ) : null}
      {workBookAssetType === "Add Files" ? (
        <FileViewer
          visible={editPopupVisible}
          onHiding={OnHandleWorkbookCardItemClick}
          data={dataGridPdfFiles}
          title={"PDF Viewer"}
          isLoading={isPdfLoading}
          extensionType={"pdf"}
        />
      ) : null}
    </>
  );
};

export default WorkbookDetailsCards;
