import { useCallback, useEffect, useMemo, useState } from "react";
import PsAccordion from "../../../../ui-components/accordion/accordion";
import PsButton from "../../../../ui-components/button/Button";
import {
  useFetchProjectResourceAdd,
  useFetchResouce,
} from "../../../../../hooks/client/useProjectProfile";
import TechnicalAllocationGrid from "./helper-component/digitalGrid";
import OperationsAllocationGrid from "./helper-component/operationGrid";
import InfraAllocationGrid from "./helper-component/infrastructureGrid";
import { Item, Toolbar } from "devextreme-react/toolbar";
import "../../../../../styles/client/addResource.scss";
import { useApp } from "../../../../../contexts/app";
import { convertToUSD } from "../../../../../utlis/helper";
import { Popup } from "devextreme-react";

const sourceDatas: any = [
  {
    serviceInfo: "Infrastructure Services",
    MonthlyCost: "00.00",
    id: "1",
  },
  {
    serviceInfo: "Digital Services",
    MonthlyCost: "00.00",
    id: "2",
  },
  {
    serviceInfo: "Operation Services",
    MonthlyCost: "00.00",
    id: "3",
  },
];

export const AddResource = (props: any) => {
  const {
    changePopupVisibility,
    popupVisible,
    projectId,
    ResourceData,
  } = props;

  const [selectedDigitalResource, setselectedDigitalResource] = useState<any>(
    ResourceData?.[0]?.digitalServices || []
  );
  const [selectedOerations, setselectedOerations] = useState<any>(
    ResourceData?.[0]?.operations || []
  );
  const [selectedInfraResource, setselectedInfraResource] = useState<any>(
    ResourceData?.[0]?.infrastructure || []
  );

  const { user } = useApp();

  const { data } = useFetchResouce();

  const { digitalServices, operations, infra_new } = data || {};
  const { mutate, isSuccess } = useFetchProjectResourceAdd();

  useEffect(() => {
    if (isSuccess) {
      changePopupVisibility();
    }
  }, [changePopupVisibility, isSuccess]);

  const getTotalCost = (resource: any) => {
    const totalCost = resource?.reduce(
      (acc: number, item: { totalcost: string }) => {
        const itemTotalCost = parseFloat(item?.totalcost?.replace("$", ""));
        if (isNaN(itemTotalCost)) {
          return acc;
        }
        return acc + itemTotalCost;
      },
      0
    );
    return totalCost;
  };

  const mapResource = useCallback(
    (resourceArray: any[], resourceType: string) =>
      resourceArray?.map((item) => ({
        ...item,
        resourceId: item?.id,
        resource_type: resourceType,
        created_by: user?.name,
        updated_by: user?.name,
      })),
    [user?.name]
  );

  const handleSaveProjectEsimate = useCallback(() => {
    const estimateFinalData = {
      projectId: projectId,
      digitalServices: mapResource(selectedDigitalResource, "digitalServices"),
      operations: mapResource(selectedOerations, "operations"),
      infrastructure: mapResource(selectedInfraResource, "infrastructure"),
      created_by: user?.name,
      updated_by: user?.name,
    };

    mutate(estimateFinalData);
  }, [
    mapResource,
    mutate,
    projectId,
    selectedDigitalResource,
    selectedInfraResource,
    selectedOerations,
    user?.name,
  ]);

  const techAllocationProps = useMemo(() => {
    return {
      digitalResourceData: digitalServices,
      setselectedDigitalResource,
      selectedDigitalResource,
    };
  }, [selectedDigitalResource, setselectedDigitalResource, digitalServices]);

  const operationalAllocationProps = useMemo(() => {
    return {
      operationResourceData: operations,
      setselectedOerations,
      selectedOerations,
    };
  }, [operations, selectedOerations, setselectedOerations]);

  const infraAllocationProps = useMemo(() => {
    return {
      infraResourceData: infra_new,
      setselectedInfraResource,
      selectedInfraResource,
    };
  }, [infra_new, selectedInfraResource, setselectedInfraResource]);

  const CustomTitle = useCallback(
    (data: any) => {
      const costData: any = {
        "Digital Services": selectedDigitalResource,
        "Operation Services": selectedOerations,
        "Infrastructure Services": selectedInfraResource,
      };
      return (
        <div className="custom-title-accordion">
          <div className="header">{data?.serviceInfo}</div>
          <div className="header headerCost">
            Monthly Cost :{" "}
            {convertToUSD(getTotalCost(costData[data?.serviceInfo]))}
          </div>
        </div>
      );
    },
    [selectedDigitalResource, selectedInfraResource, selectedOerations]
  );

  const accordionBodyComponent = useMemo(() => {
    return [
      {
        dataSource: sourceDatas,
        serviceInfo: "Digital Services",
        component: () => <TechnicalAllocationGrid {...techAllocationProps} />,
      },
      {
        dataSource: sourceDatas,
        serviceInfo: "Operation Services",
        component: () => (
          <OperationsAllocationGrid {...operationalAllocationProps} />
        ),
      },
      {
        dataSource: sourceDatas,
        serviceInfo: "Infrastructure Services",
        component: () => <InfraAllocationGrid {...infraAllocationProps} />,
      },
    ];
  }, [techAllocationProps, operationalAllocationProps, infraAllocationProps]);

  const renderAccordionBody = useCallback(() => {
    return accordionBodyComponent?.map((item) => {
      const { dataSource, serviceInfo, component } = item;
      const componentData = dataSource?.find(
        (data: any) => data?.serviceInfo === serviceInfo
      );
      return (
        <PsAccordion
          dataSource={[componentData]}
          Component={component}
          CustomTitle={CustomTitle}
        />
      );
    });
  }, [CustomTitle, accordionBodyComponent]);

  const renderBottomSummary = () => {
    const allCostData = [
      ...selectedDigitalResource,
      ...selectedOerations,
      ...selectedInfraResource,
    ];
    return (
      <Toolbar>
        <Item location="after">
          <div className="grid-estimate-footer">
            Estimated Monthly Cost : {convertToUSD(getTotalCost(allCostData))}
          </div>
        </Item>
      </Toolbar>
    );
  };

  const loaderProps = {
    show: isSuccess,
    loadertext: "Saving...",
  };

  return (
    <Popup
      visible={popupVisible}
      onHiding={changePopupVisibility}
      dragEnabled={false}
      width={1200}
      height={600}
      title="Add Resources"
    >
      <div className="grid-container-estimator">
        <div className="externalPsAccordion">
          {renderAccordionBody()}
          {renderBottomSummary()}
          <div className="custom-estimator-submit">
            <PsButton
              text="Submit"
              onClick={handleSaveProjectEsimate}
              loaderProps={loaderProps}
              eventName="Save Your Resources"
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};
