import DataGridPage from "./helper-component/taskDataGrid";
import {
  useFetchTask,
  useTask,
} from "../../../hooks/task/useTask";
import { useCallback, useEffect, useMemo, useState } from "react";
import "../../../styles/task/TasksAutomation.scss";
import { TaskFormPopup } from "./helper-component/task-add/taskFormPopup";
import TaskDeletePopup from "./helper-component/task-start-delete/taskDeletePopup";
import TaskStartPopup from "./helper-component/task-start-delete/taskConfirmPopup";
import TaskEditPopup from "./helper-component/task-edit/taskEditPopup";
import TaskClonePopup from "./helper-component/task-clone/taskClonePopup";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import DropDownButton from "devextreme-react/drop-down-button";
import { Item } from "devextreme-react/toolbar";
import { filterStatusList } from "./types";
import {customDateRange, dropDownOptions} from "../../../config/constants";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import TaskIcon from "../../../assets/icons/icon-task-2.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { fetchTaskDescriptionApi } from "../../../service/task/api-service";
import { usePage } from "../../../contexts/pageContext";

export const TasksAutomationPage = () => {
  const {
    state: {
      popupVisible,
      editPopupVisible,
      selectedTask,
      deletePopupVisible,
      startPopupVisible,
      clonePopupVisible,
      pageCount,
      pageIndex,
      pageSize,
    },
    onAddTaskClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onStartPopupClose,
    onEditClick,
    onEditTaskClick,
    changeEditPopupVisibility,
    onClonePopupOpen,
    onClonePopupClose,
    handlePageIndexChange,
    handleDropDownPageIndex,
  } = useTask();

  const {
    state: { client, status, projectName,clientId,projectId, statusName, selectedDays},
    filterByClient,
    filterByStatus,
    filterByProject,
    handleDateRangeChange,
    clientDropDownData,
    projectDropDownData,
    gridRef,
    handleColumnIndexChange,
  } = useAppHook();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientId : p_clientId,
    t_clientName : p_clientName,
    projectId : p_projectId,
    t_projectName: p_projectName,
    status: p_status,
  } = filterValue || {};
  
  const [refreshKey, setRefreshKey] = useState(0);
  const Period = useHelperHooks().getQueryParams("Period");
  const { data: DateRange } = useFetchDateRange();

  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    const status = statusName
    return {
      clientId : p_clientId,
      projectId : p_projectId,
      timePeriod,
      status,
      ...(timePeriod === customDateRange && { startTime, endTime }),
    };
  }, [clientId, projectId, statusName, filterValue]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchTaskDescriptionApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const DataGridProps = useMemo(() => {
    const renderStartTask = (cell: any) => {
      return (
        <PSIconText
          alt="Start"
          hint="Start"
          src={TaskIcon}
          width={28}
          height={28}
          onClick={() => onStartPopupOpen(cell)}
        />
      );
    };
  
    return {
      onAddTaskClick,
      onDeletePopupOpen,
      onStartPopupOpen,
      onEditClick,
      onEditTaskClick,
      onClonePopupOpen,
      onClonePopupClose,
      gridRef,
      renderStartTask,
      pageCount,
      pageIndex,
      pageSize, 
      handlePageIndexChange,
      apiProps,
      handleColumnIndexChange,
    };
  }, [
    onEditClick,
    onEditTaskClick,
    onDeletePopupOpen,
    onStartPopupOpen,
    onAddTaskClick,
    onClonePopupOpen,
    onClonePopupClose,
    gridRef,
    pageCount,
    pageIndex,
    pageSize,  
    handlePageIndexChange,
    apiProps,
    handleColumnIndexChange,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const PanelProps = useMemo(() => {
    return {
      selectedTask,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedTask, editPopupVisible]);

  const deleteProps = useMemo(() => {
    return {
      selectedTask,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedTask, deletePopupVisible, onDeletePopupClose]);

  const startProps = useMemo(() => {
    return {
      selectedTask,
      startPopupVisible,
      onStartPopupClose,
    };
  }, [selectedTask, startPopupVisible, onStartPopupClose]);

  const cloneProps = useMemo(() => {
    return {
      selectedTask,
      clonePopupVisible,
      onClonePopupClose,
    };
  }, [selectedTask, onClonePopupClose, clonePopupVisible]);


  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: DataGridPage,
      componentProps: DataGridProps,
    },
  ];

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        text={p_projectName ? p_projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
        onItemClick={handleDropDownPageIndex}
      />
       <DropDownButton
        items={filterStatusList}
        stylingMode="text"
        text={p_status ? p_status : "All Items"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
        onItemClick={handleDropDownPageIndex}
      />
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} 
         handleDropDownPageIndex={handleDropDownPageIndex}/>
      <PSIconText
        text="Add Task"
        alt="Add Task"
        src={NewIcon}
        onClick={onAddTaskClick}
      />
    </Item>
  );

  const layoutProps = {
    headerText: "Tasks & Automation",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <TaskFormPopup {...FormProps} />
      <TaskEditPopup {...PanelProps} />
      <TaskDeletePopup {...deleteProps} />
      <TaskStartPopup {...startProps} />
      <TaskClonePopup {...cloneProps} />
    </div>
  );
};
