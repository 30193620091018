import { useCallback, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Position,
  NodeTypes,
} from "@xyflow/react";
import CustomNode from "./CustomNode";
import { DayDateFormat } from "../../utlis/helper";

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

const nodeProperties = [
  {
    label: "User",
    dataField: "userName",
  },
  {
    label: "Original",
    dataField: "originalFileName",
  },
  {
    label: "Process Type",
    dataField: "processType",
  },
  {
    label: "FilePath",
    dataField: "filePath",
  },
  {
    label: "File",
    dataField: "fileName",
  },
];

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const DataCatlogLineage = (props: any) => {
  const { data } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const transformedObject = useCallback(() => {
    return (
      Array.isArray(data) &&
      data?.reduce((acc: any, item: any) => {
        acc.clientShortName = item.clientShortName;
        acc.projectShortName = item.projectShortName;
        acc.taskShortName = item.taskShortName;
        acc.projectId = item.projectId;
        acc.createdBy = item.createdBy;
        acc.createdDate = item.createdDate;
        acc[item.assetType] = {
          assetType: item.assetType,
          fileType: item.fileType,
          type: item.type,
          fileName: item.fileName,
          fileSize: item.fileSize,
        };
        return acc;
      }, {})
    );
  }, [data]);

  useEffect(() => {
    if (data) {
      const newNodes: any = nodeProperties
        .map((property, index) => {
          const { label, dataField } = property;
          return {
            id: `node-${dataField}-${index}`,
            type: "custom",
            position: { x: index * 300, y: 150 },
            data: {
              label: data[dataField]?.fileType || label,
              title: data[dataField],
              status: dataField === 'fileName' ? data["status"] : null,
              additionalInfo: (
                <ul style={{ listStyle: "none" }}>
                  <li>Created By: {data["userName"] || "N/A"}</li>
                  <li>
                    Updated Date: {DayDateFormat(data["created_at"]) || "N/A"}
                  </li>
                </ul>
              ),
            },
            ...nodeDefaults,
          };
        })
        .filter(Boolean);

      const newEdges = [];
      for (let i = 0; i < newNodes.length - 1; i++) {
        newEdges.push({
          id: `${newNodes[i].id}-${newNodes[i + 1].id}`,
          source: newNodes[i].id,
          target: newNodes[i + 1].id,
        });
      }

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [data, setEdges, setNodes, transformedObject]);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const nodeTypes: NodeTypes = {
    custom: CustomNode,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default DataCatlogLineage;
