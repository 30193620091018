import { get } from "../api/api";
import {makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchMetricsDataApi = async (queryParams: any) => {
  if (!queryParams?.clientShortName || !queryParams?.projectShortName || !queryParams?.projectId || !queryParams?.executionName || !queryParams?.executionTime) {
    return { data: [] };
  }
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`logs/v1/logmetrics/executionInfo${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchDataProcessDropDownApi = async (id: string) => {
  const response = await get(`core/v1/dropdown/client?clientId=${id}`);
  return response;
}; 

export const fetchSpecificProjectEditDetailsApi = async (id: string) => {
  const response = await get(`client/v1/project/info/project/${id}`);
  return response;
};

export const fetchClientAlertExceptionApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`automation/v1/alertsException/info/all/${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetcthSpecificExceptionApi = async (executionId:any) => { 
  const validUrl = validateEndPoint(`automation/v1/alertsException/info/exception?executionId=${executionId}`);
  const response = await get(validUrl);
  return response;
}

export const fetchAuditLogApi = async(queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`admin/v1/auditInfo/retrieve/latest${query}`);
  const response = await get(validUrl);
  return response;
}

export const fetchAuditLogSpecificFileApi = async (id: any) => {
  const validUrl = validateEndPoint(`admin/v1/auditInfo/useractions?polarisAssetID=${id}`);
  const response = await get(validUrl);
  return response;
}
export const fetchAuditLogSpecificLineageNodeApi = async (id: any) => {  
  const validUrl = validateEndPoint(`admin/v1/auditInfo/lineage/retrieve?referenceid=${id}`);
  const response = await get(validUrl);
  return response;
}


