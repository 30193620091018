import { useFetchUserAdministration, useEditUserStatus } from "../../hooks/admin/useUserAdministration";
import DataGridMultiViewLayout from "../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import UserDataGrid from "../userAdministration/helper-component/userGrid";

export const UserAdministration = () => {
  const {
    data: userData,
    isLoading: isUserDataLoading,
    isFetching,
    refetch,
  } = useFetchUserAdministration();
  const { mutate: editUserStatus } = useEditUserStatus();
  const userDataGrid = userData?.data || [];

  const onRowUpdated = (e: any) => {
    const { data } = e;
    editUserStatus({ userId: data?.userId, status: data?.status });
  };

  const DataGridProps = {
    userData: userDataGrid,
    isUserDataLoading,
    refetch,
    onRowUpdated,
  };

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: UserDataGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "User Administration",
    refetch,
    additionalToolbarContent: null,
    pageBodyComponent: PageBodyComponent(),
    isLoading: isFetching,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default UserAdministration;
