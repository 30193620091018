import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { STATUS_LIST } from "../constants";

export const dropDownOptions = { width: "auto" };

export const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
  25,
  50,
  100
];

export const fileExtensions = ['.csv', '.json', '.xlsx', '.xls'];

export type ContactStatus = (typeof STATUS_LIST)[number];

export type FilterContactStatus = ContactStatus | 'All';

export const taskStatuses = [
  "Pending",
  "In Progress",
  "Ready For Review",
  "Completed",
];

export const uploadAccept = ".pdf";

