import { MetricsTicker } from "../../../../../../components/ui-components/ticker-card/TickerCardNew";
import {
  useMangaProject,
} from "../../../../../../hooks/client/useProject";
import { convertToUSD } from "../../../../../../utlis/helper";
import "../../../../../../styles/client/mangementStyles.scss";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { useFetchRecentTasks, useFetchTotalCost } from "../../../../../../hooks/app/useApps";

const ManageRowCard = (props: any) => {
  const { selectedDays } = props;
  const { data: recentActivity, isLoading: isRecentActivityLoading } =
  useFetchRecentTasks(selectedDays);
  const { data: finopsCost, isLoading: isFinopsLoading } =
    useFetchTotalCost(selectedDays);
  const { data: manageProject, isLoading: isProjectsLoading } =
    useMangaProject();
  const manageData = manageProject?.data?.projectSummary ?? {};

  const activityData =Array.isArray(recentActivity?.data) 
    ? recentActivity?.data?.slice(0, 4)?.map((activity: any) => ({
      name: activity?.taskName,
      value: activity?.status,
    }))
    : [];

  const finopsData = finopsCost
    ? finopsCost?.totalCost
      ?.filter((finops: any) => finops?.name !== "Total")
      ?.map((finops: any) => ({
        name: finops?.name,
        value: convertToUSD(finops?.value),
      }))
    : [];

  const totalFinopsValue = finopsCost
    ? finopsCost?.totalCost?.find((finops: any) => finops?.name === "Total")
      ?.value
    : "0";

  const primaryValue = manageData?.Totalprojects;
  const secondaryData = Object?.entries(manageData)
    ?.filter(([key]) => key !== "Totalprojects")
    ?.map(([key, value]) => ({
      name: key,
      value: String(value),
    }));

  if (isProjectsLoading || isFinopsLoading || isRecentActivityLoading) {
    const skeletons = Array?.from({ length: 3 }, (_, index) => (
      <PsSkeleton key={index} count={1} height={150} />
    ));

    return <div className="portfolio-row-card">{skeletons}</div>;
  }

  return (
    <div className="management-row-card">
      <MetricsTicker
        title="Total Projects "
        icon="hierarchy"
        primaryValue={primaryValue}
        secondaryValue={secondaryData}
      />
      <MetricsTicker
        title="Financials"
        icon="money"
        primaryValue={convertToUSD(totalFinopsValue)}
        secondaryValue={finopsData}
      />
      <MetricsTicker
        title="Recent Tasks"
        icon="taskstop"
        secondaryValue={activityData}
      />
    </div>
  );
};

export default ManageRowCard;