import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { CardDetailsProps, WorkSpacelayoutProps } from "../types";
import "../../../styles/workbench/Workspaces.scss";
import { useCallback } from "react";
import Pluse from "../../../assets/media/plus.png";
import Minus from "../../../assets/media/minus.png";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import MasonryLayout from "../../../components/ui-components/MasonryLayout/MasonryLayout";
import { RightSidePannel } from "../../../components/ui-components/right-side-panel/right-side-panel";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const WorkbenchLayout = (props: WorkSpacelayoutProps) => {
  const {
    headerText,
    additionalToolbarContent,
    additionalToolbarBeforeContent,
    cardDetails,
    showToolbar,
  } = props;

  const plusImg = "/static/media/plus.0f4eeb08ebbfa93539a6.png";

  const handleButtonClick = useCallback((componentId: string) => {
    const container = document.querySelector(`#${componentId}`);
    const img: any = document.querySelector(`#img-${componentId}`);
    if (container && img) {
      const currentSrc = img.src;
      if (currentSrc?.includes(plusImg)) {
        img.src = Minus;
        container.classList.remove("collapsed");
      } else {
        img.src = Pluse;
        container.classList.add("collapsed");
      }
    }
    GTAButtonEventHandler("workbench_collapsed", componentId);
  }, []);

  const renderpanel = useCallback((sidepanelProps: any) => {
    const { title, Component, componentProps, isLoadingButton } =
      sidepanelProps || {};
    const { isOpened, changePanelOpened, onSave } = componentProps || {};

    if (sidepanelProps && isOpened)
      return (
        <RightSidePannel
          title={title}
          isOpened={isOpened}
          changePanelOpened={changePanelOpened}
          onSave={onSave}
          isLoading={isLoadingButton}
        >
          <Component {...componentProps} />
        </RightSidePannel>
      );
  }, []);

  const renderLeftSideCard = useCallback(() => {
    return cardDetails?.map((item: CardDetailsProps) => {
      const {
        title,
        Component,
        buttonProps,
        componentId,
        componentProps,
        hasCard,
      } = item;

      const IconProps = {
        text: buttonProps?.text,
        src: NewIcon,
        onClick: buttonProps?.onClick,
      };
      const itemName = componentId + "-card";
      return (
        <>
          <div className="content-pannel-header">
            <div className="content-pannel-title">{title}</div>
            {buttonProps && <PSIconText {...IconProps} />}
            <div style={{ marginBottom: "10px" }}>
              <PSIconText
                text=""
                src={Minus}
                alt="plus"
                id={`img-${componentId}`}
                onClick={() => handleButtonClick(componentId)}
              />
            </div>
          </div>
          <MasonryLayout
            className="card-container"
            id={componentId}
            itemName={itemName}
            hasCard={hasCard}
          >
            <Component {...componentProps} className={itemName} />
          </MasonryLayout>
          {renderpanel(componentProps?.sidepanelProps)}
        </>
      );
    });
  }, [cardDetails, handleButtonClick, renderpanel]);

  return (
    <>
      <ToolbarAnalytics
        title={headerText}
        showToolbar={showToolbar}
        additionalToolbarContent={additionalToolbarContent}
        additionalToolbarBeforeContent={additionalToolbarBeforeContent}
      />
      <div className="workbench-new">
        <div className="content-pannel">{renderLeftSideCard()}</div>
      </div>
    </>
  );
};

export default WorkbenchLayout;
