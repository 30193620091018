import PSIconText from "../../../../../../components/ui-components/icon-with-text/IconText";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { TelemetryAnalyzer } from "./telemetryAnalyzer";
import AnalyzeDocumentIcon from "../../../../../../assets/icons/icon-analyze-document.png";

const Process = (props: any) => {
  const { telemetryData, isLoading, handleAnalyzeDocument } = props;

  if (telemetryData) {
    return <TelemetryAnalyzer telemetryData={telemetryData} />;
  } else if (isLoading) {
    return <PsSkeleton count={1} height={800} />;
  } else {
    return (
      <div className="card-analyze-text">
        <p className="text-gap">Click the Analyze Document to process file</p>
        <PSIconText
          text="Analyze Document"
          alt="Analyze Document"
          onClick={handleAnalyzeDocument}
          src={AnalyzeDocumentIcon}
        />
      </div>
    );
  }
};

export default Process;
