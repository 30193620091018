import Popup from "devextreme-react/popup";
import PsButton from "../ui-components/button/Button";
import { ConfirmModalProps } from "./type";

const ConfirmModal = (props: ConfirmModalProps) => {
  const { show, onClose, onConfirm } = props;
  const loaderProps = {
    show: false,
    loadertext: "Please Wait...",
  };

  return (
    <Popup
      title="Revert Changes?"
      visible={show}
      onHiding={onClose}
      width="30%"
      height="auto"
    >
      <div>
        <p style={{ marginBottom: "20px" }}>
          <b>Are you sure you want to load the original file?</b>
        </p>
      </div>
      <div style={{ float: "left", marginTop: "10px" }}>
        <PsButton
          onClick={onConfirm}
          text="Confirm"
          type="normal"
          loaderProps={loaderProps}
          mode="contained"
          eventName="Data studio load the original confirm button"
        />
      </div>
      <div style={{ float: "right", marginTop: "10px" }}>
        <PsButton
          onClick={onClose}
          text="Close"
          type="normal"
          mode="contained"
          eventName="Data studio load the original close button"
        />
      </div>
    </Popup>
  );
};

export default ConfirmModal;
