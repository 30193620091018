

import {
  del,
  get,
  post,
  update,
} from "../../service/api/api";
import { makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchResouceDetailsApi = async () => {
  const type="all";
  const response = await get(`finops/v1/ratecard/resource?type=${type}`);
  return response;
}; // used 

export const fetchSpecificProjectDetailsApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`client/v1/project/info/all${query}`);
  const response = await get(validUrl);
  return response;
}; // used 


export const updateProjectApi = async (payload: any) => {
  const response = await update("client/v1/project/update", payload);
  return response;
}; // used 

export const fetchDigitalServicesApi = async () => {
  const response = await get(`finops/v1/ratecard/resource?type=digitalServices`);
  return response;
} //used 

export const createDigitalServicesApi = async (payload: any) => {
  const response = await post("finops/v1/ratecard/create?type=digitalServices", payload);
  return response;
}; //used 

export const updateDigitalServicesApi = async (payload: any) => {
  const response = await update("finops/v1/ratecard/update?type=digitalServices", payload);
  return response;
}; //used 

export const deleteDigitalServicesById = async (id: string) => {
  const response = await del(`finops/v1/ratecard/delete?type=digitalServices&id=${id}`);
  return response;
}; //used 

export const fetchOperationsApi = async () => {
  const response = await get(`finops/v1/ratecard/resource?type=operations`);
  return response;
} // used 

export const createOperationsApi = async (payload: any) => {
  const response = await post("finops/v1/ratecard/create?type=operations", payload);
  return response;
}; //used 

export const updateOperationsApi = async (payload: any) => {
  const response = await update("finops/v1/ratecard/update?type=operations", payload);
  return response;
}; // used 

export const deleteOperationsById = async (id: string) => {
  const response = await del(`finops/v1/ratecard/delete?type=operations&id=${id}`);
  return response;
}; //used

export const fetchInfrastructureApi = async () => {
  const response = await get(`finops/v1/ratecard/resource?type=infra_new`);
  return response;
} //used 

export const createInfrastructureApi = async (payload: any) => {
  const response = await post("finops/v1/ratecard/create?type=infra_new", payload);
  return response;
}; //used 

export const updateInfrastructureApi = async (payload: any) => {
  const response = await update("finops/v1/ratecard/update?type=infra_new", payload);
  return response;
}; //used 

export const deleteInfrastructureById = async (id: string) => {
  const response = await del(`finops/v1/ratecard/delete?type=infra_new&id=${id}`);
  return response;
}; //used 


export const fetchInvoiceApi = async () => {
  const response = await get(`finops/v1/invoice/all?invoice-get=True`);
  return response;
} // used 

export const fetchEstimatorInfo = async () => {
  const response = await get(`finops/v1/resourcecalaculator/resourceinfo`);
  return response;
}; // used 

export const fetchEstimatorApi = async () => {
  const response = await get("finops/v1/resourcecalaculator/resourceinfo");
  return response;
}; // used 

export const createEstimatorApi = async (payload: any) => {
  const response = await post("finops/v1/resourcecalaculator/createstimate", payload);
  return response;
}; // used 
