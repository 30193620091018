import { customDateRange } from "../../config/constants";
import { get,post} from "../api/api";
import {
  constructDynamicUrl,
  makeQueryParam,
  validateEndPoint,
} from "../api/helper/helper";
import { formatDate } from "devextreme/localization";

export const fetchExceptionApi = async (
  executionId: string
) => {
  const response = await get(
    `logs/v1/exception/exceptionInfo?executionId=${executionId}`
  );
  return response;
};

export const fetchClientsInfo = async () => {
  const response = await get("core/v1/clientinfo/all");
  return response;
};

export const fetchClientNames = async () => {
  const response = await get("core/v1/clientinfo/all");
  return response;
};

export const fetchRecentActivityAPI = async (queryParams: any) => {
  const {clientId, projectId, timePeriod, startTime, endTime} = queryParams;
  if (timePeriod === customDateRange && (!startTime || !endTime)) { return }
  const frequency = {
    startTime: formatDate(startTime, "yyyy-MM-dd"),
    endTime: formatDate(endTime, "yyyy-MM-dd"),
    timePeriod,
    clientId,
    projectId
  };
  const query = makeQueryParam(frequency);
  const validUrl = validateEndPoint(`automation/v1/recentactivity/info/all${query}`);
  const response = await get(validUrl);
  return response;
};

export const fetchTotalCostApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `finops/v1/costcalculation/calculation${query}`
  );
  const response = await get(validUrl);
  return response;
};

export const fetchClientsInfoDataApi = async () => {
  const response = await get("core/v1/clientinfo/all");
  return response;
};

export const fetchTaskExecutionDetailsByIdApi = async (
  executionName: string | null,
  clientShortName: string,
  projectShortName: string,
  projectId: string
) => {
  const response = await get(
    `logs/v1/telemetry/executionInfo?executionName=${executionName}&clientShortName=${clientShortName}&projectShortName=${projectShortName}&projectId=${projectId}`
  );
  return response;
};

export const fetchTaskOutputListByIdApi = async (filename: string) => {
  const response = await get(
    `input_output/v1/inputoutput?process=output&filename=${filename}.json`
  );
  return response;
};

export const fetchExecutionDataList = async (executionName: string, stepId: string, manifestId: string, module: string ) => {
  const response = await get(
    `datalake/v1/file-manager/retrieve/filesinfo?type=telemetry&executionName=${executionName}&stepId=${stepId}&manifestId=${manifestId}&module=${module}`
  );
  return response;
};

export const fetchExecutiontData = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `datalake/v1/file-manager/retrieve/viewfile${query}`
  );
  const response = await get(validUrl);
  return response;
};

export const fetchFinopsCostApiData = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(
    `finops/v1/costcalculation/calculation${query}`
  );
  const response = await get(validUrl);
  return response;
};


export const exportDataApi = async (payload: any) => {
  const response = await post("datalake/v1/file-manager/export/datalake", payload);
  return response;
}; 

export const fetchInvoiceApi = async (clientId: string) => {
  const response = await get(
    `finops/v1/invoice/all?invoice-get=True&clientId=${clientId}`
  );
  return response;
};

export const fetchSpecificProjectApi = async (id: string) => {
  const response = await get(`client/v1/project/info/client/${id}`);
  return response;
};

export const fetchestimateProjectApi = async (id: string) => {
  const response = await get(`client/v1/project/info/client/${id}`);
  return response;
};

export const fetchFunctionTypeApi = async (func_type: any) => {
  const response = await get(
    `core/v1/functiondropdown/dropdownInfo/${func_type}`
  );
  return response;
};

export const fetchSidebarInfoApi = async () => {
  const response = await get(`core/v1/sidebar/sidebarInfo`);
  return response;
};

export const fetchClientData = async (id: string, location: string, projectShortName: string) => {
  const templatequery = constructDynamicUrl(id);
  const response = await get(
    `datalake/v1/file-manager/retrieve/${templatequery}?location=${location}&projectShortName=${projectShortName}`
  );
  return response;
};

export const fetchAnalyticsScript = async () => {
  const response = await get(`logs/v1/Googleanalytics/info`);
  return response;
};

export const fetchtaskInvoiceApi = async (clientId: string,projectId:string) => {
  const response = await get(
    `finops/v1/invoice/all?invoice-get=True&clientId=${clientId}&projectId=${projectId}`
  );
  return response;
};

export const fetchSpecificProjectDropdownApi = async (id : string) => {
  const response = await get(
    `core/v1/projectinfo/all?clientId=${id}`
  );
  return response;
}

export const fetchWorkflowStepDataList = async (executionName: string, stepId: string, manifestId: string, folder: string ) => {
  const response = await get(
    `datalake/v1/file-manager/retrieve/filesinfo?type=workflow&executionName=${executionName}&stepId=${stepId}&manifestId=${manifestId}&folder=${folder}`
  );
  return response;
};