import "../../../../styles/workbench/Workspaces.scss";
import { Item } from "devextreme-react/toolbar";
import { useNavigate, useParams } from "react-router-dom";
import {
  transformData,
  useFetchActivityItemsPdf,
  useFetchWorkBookItemData,
  useWorkBench,
} from "../../../../hooks/workbench/useWorkbench";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useApp } from "../../../../contexts/app";
import ShareWorkbenchAccess from "../form/ShareAccess";
import PsButton from "../../../../components/ui-components/button/Button";
import WorkbookDetailsCards from "../workbenchCards/WorkbookDetailCard";
import WorkbookAddedItemsGrid from "../workbenchCards/WorkBookItemsGrid";
import DataGridMultiViewLayout from "../../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import AddItems from "../../../../assets/images/icons/icon-add-item-1.png";
import ShareUsers from "../../../../assets/images/icons/icon-add-users-1.png";
import TileGrids from "../../../../assets/images/icons/tiles.png";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import ReactDOM from "react-dom";
const WorksBookDetailsPage = () => {
  const navigate = useNavigate();
  const { user } = useApp();
  let { workbookName, workbookId } = useParams();

  const {
    state: {
      isShareWorkbenchShow,
      pdfFilePath,
      isWorkbookItemShow,
      editPopupVisible,
      selectedManualEntryData,
      workBookAssetType,
    },
    onHandleShareWorkBench,
    userTreeDataSource,
    syncTreeViewSelection,
    treeViewRender,
    shareWorkBenchData,
    onRowWorkBookItemClick,
    onHandleChangeViewCard,
  } = useWorkBench();

  const ShareAccessProps = useMemo(
    () => ({
      isShareWorkbenchShow: isShareWorkbenchShow,
      changePanelOpened: onHandleShareWorkBench,
      userTreeDataSource,
      syncTreeViewSelection,
      treeViewRender,
      shareWorkBenchData,
      assetType: "workbook",
      assetId: workbookId,
      assetName: workbookName,
    }),
    [
      isShareWorkbenchShow,
      onHandleShareWorkBench,
      userTreeDataSource,
      syncTreeViewSelection,
      treeViewRender,
      shareWorkBenchData,
      workbookId,
      workbookName,
    ]
  );

  const [workBookItemParams, setWorkBookItemParams] = useState({});
  const { data: dataGridPdfFiles, isLoading: isPdfLoading } =
    useFetchActivityItemsPdf(pdfFilePath);

  useEffect(() => {
    setWorkBookItemParams({ workbookId: workbookId, user: user?.userId });
  }, [workbookId, user]);

  const { data: workbookItemsData, isLoading } =
    useFetchWorkBookItemData(workBookItemParams);

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        cssClass="toolbar-item-flex toolbar-item-label-buton"
      >
        <PSIconText
          src={TileGrids}
          alt="Grid/Card View"
          onClick={onHandleChangeViewCard}
          text={"Grid/Card View"}
        />
        <PSIconText
          src={ShareUsers}
          alt="Share item with users"
          onClick={onHandleShareWorkBench}
          text={"Share"}
        />
        <PSIconText
          src={AddItems}
          alt="Add Items"
          onClick={() =>
            navigate(`/workbooks/${workbookName}/${workbookId}/add-items`)
          }
          text={"Add Items"}
        />
      </Item>
    );
  }, [
    navigate,
    onHandleChangeViewCard,
    onHandleShareWorkBench,
    workbookId,
    workbookName,
  ]);

  const handleBackButton = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const additionalToolbarBeforeContent = useMemo(() => {
    return (
      <Item location="before" locateInMenu="auto" cssClass="toolbar-item-flex">
        <PsButton
          icon="arrowleft"
          mode="text"
          width={"auto"}
          onClick={handleBackButton}
        />
      </Item>
    );
  }, [handleBackButton]);

  const manualEntryProps = useMemo(() => {
    return {
      editPopupVisible: editPopupVisible,
      changeEditPopupVisibility: onRowWorkBookItemClick,
      selectedManualEntryData: selectedManualEntryData,
      workBookAssetType: workBookAssetType,
      dataGridPdfFiles,
      isPdfLoading,
    };
  }, [
    dataGridPdfFiles,
    editPopupVisible,
    isPdfLoading,
    onRowWorkBookItemClick,
    selectedManualEntryData,
    workBookAssetType,
  ]);

  const workbookItemProps = useMemo(() => {
    return {
      data: transformData(workbookItemsData?.data),
      isLoading: isLoading,
      onRowClick: onRowWorkBookItemClick,
      manualEntryProps,
      imageIcon: Image,
    };
  }, [
    isLoading,
    manualEntryProps,
    onRowWorkBookItemClick,
    workbookItemsData?.data,
  ]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: WorkbookAddedItemsGrid,
      componentProps: workbookItemProps,
    },
    {
      mode: "board",
      Component: WorkbookDetailsCards,
      componentProps: workbookItemProps,
    },
  ];

  const rowTitles: any = (
    <>
      <span>Workbook</span>
      <span style={{ fontSize: "16px", marginLeft: "15px" }}>
        {workbookName}
      </span>
    </>
  );

  const layoutProps = {
    headerText: rowTitles,
    additionalToolbarContent: additionalToolbarContent,
    additionalToolbarBeforeContent: additionalToolbarBeforeContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: isWorkbookItemShow,
    isLoading: isLoading,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      {ReactDOM.createPortal(<ShareWorkbenchAccess {...ShareAccessProps} />, document?.body)}
    </>
  );
};

export default WorksBookDetailsPage;
