import {
  HeaderData,
  MetaData,
  WorkflowData,
  DataItems,
  validateHeaderTab,
  validateMetaTab,
  validateWorkflowTab
} from "../../types";
import WorkflowSteps from "./tabs/workflowTab";
import { useState } from "react";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import Button from "devextreme-react/button";
import HeaderTab from "./tabs/headerTab";
import MetaTab from "./tabs/metaTab";
import DataTab from "./tabs/dataTab";
import { ConfirmationPopup } from "./ConfirmationPopup";
import PsButton from "../../../../../components/ui-components/button/Button";

const NewWorkflow = ({
  newHeaderData,
  newMetaData,
  newWorkflowData,
  newDataItems,
  updateHeaderField,
  updateMetaField,
  updateWorkflowField,
  updateDataField,
  handleFormSubmit,
  onClose,
  onOpen,
  clientData,
  projectData,
  onClientOptionChanged,
  onProjectOptionChanged,
  actionData,
  functionFilterData,
  fileTypeData,
  showCancelPopup,
  setShowCancelPopup,
  typeData,
  isSuccess
}: {
  newHeaderData: HeaderData;
  newMetaData: MetaData;
  newWorkflowData: WorkflowData[];
  newDataItems: DataItems;
  updateHeaderField: (field: string) => (value: any) => void;
  updateMetaField: (field: string) => (value: any) => void;
  updateWorkflowField: (nodeId: number, field: string) => (value: any) => void;
  updateDataField: (field: string) => (value: any) => void;
  handleFormSubmit: () => void;
  onClose: () => void;
  onOpen: () => void;
  clientData: any;
  projectData: any;
  onClientOptionChanged: (e: any) => void;
  onProjectOptionChanged: (e: any) => void;
  actionData: any;
  functionFilterData: any;
  fileTypeData: any;
  showCancelPopup: boolean;
  setShowCancelPopup: (value: boolean) => void;
  typeData: any;
  isSuccess: boolean;
}) => {

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleNextTab = () => {
    let isValid = true;
    if (activeTab === 0) isValid = validateHeaderTab(newHeaderData);
    else if (activeTab === 1) isValid = validateMetaTab(newMetaData);
    else if (activeTab === 2) isValid = validateWorkflowTab(newWorkflowData);

    if (isValid && activeTab < 3) {
      setActiveTab(activeTab + 1);
    }
  };

  const handlePreviousTab = () => {
    if (activeTab > 0) setActiveTab(activeTab - 1);
  };

  const handleCancelClick = () => {
    setShowCancelPopup(true);
  };

  const handleCancelConfirm = () => {
    setShowCancelPopup(false);
    onClose();
  };

  const handleCancelReject = () => {
    setShowCancelPopup(false);
  };

  const loaderProps = {
    show: isSuccess,
    loadertext: "Saving...",
  };

  return (
    <div className="workflow-container">
      <TabPanel
        selectedIndex={activeTab}
        onSelectionChanged={(e: any) => {
          if (e?.component?.option("selectedIndex") !== activeTab) {
            setActiveTab(e?.component?.option("selectedIndex"));
            e.cancel = true;
          }
        }}
        className="add-tab-panel"
      >
        <TabPanelItem title="Header">
          <HeaderTab
            newHeaderData={newHeaderData}
            updateHeaderField={updateHeaderField}
            clientData={clientData}
            projectData={projectData}
            onClientOptionChanged={onClientOptionChanged}
            onProjectOptionChanged={onProjectOptionChanged}
          />
        </TabPanelItem>
        <TabPanelItem title="Meta">
          <MetaTab
            newMetaData={newMetaData}
            updateMetaField={updateMetaField}
          />
        </TabPanelItem>
        <TabPanelItem title="Workflow">
          <WorkflowSteps
            newWorkflowData={newWorkflowData}
            updateWorkflowField={updateWorkflowField}
            actionData={actionData}
            functionFilterData={functionFilterData}
            typeData={typeData}
            fileTypeData={fileTypeData}
          />
        </TabPanelItem>
        <TabPanelItem title="Data">
          <DataTab
            newDataItems={newDataItems}
            updateDataField={updateDataField}
          />
        </TabPanelItem>
      </TabPanel>
      <div className="footer-actions">
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {activeTab > 0 && (
            <Button
              text="Previous"
              onClick={handlePreviousTab}
              style={{ marginRight: '10px' }}
            />
          )}
          {activeTab < 3 && (
            <Button
              text="Next"
              onClick={handleNextTab}
              style={{ marginRight: '10px' }}
            />
          )}
          {activeTab === 3 && (
            <>
              <Button
                text="Cancel"
                onClick={handleCancelClick}
                style={{ marginRight: '10px' }}
              />
              <PsButton
                text="Save"
                type="default"
                width={'auto'}
                onClick={handleFormSubmit}
                loaderProps={loaderProps}
                eventName="Save Workflow"
              />
            </>
          )}
        </div>
      </div>
      <ConfirmationPopup
        visible={showCancelPopup}
        onConfirm={handleCancelConfirm}
        onCancel={handleCancelReject}
      />
    </div>
  );
};

export default NewWorkflow;
