import Toolbar, { Item } from "devextreme-react/toolbar";
import TextBox from "devextreme-react/text-box";
import Button from "devextreme-react/button";
import type { AppHeaderProps } from "../../../../../types";
import logos from "../../../../../assets/images/shore-logo-1.png";
import Projects from "../../../../../assets/images/icons/recentProject.png";
import WorkBooks from "../../../../../assets/images/workbooks.png";
import WorkSpaces from "../../../../../assets/images/workspaces.png";
import { Template } from "devextreme-react/core/template";
import "../../../../../styles/ui-components/AppHeader.scss";
import { useNavigate } from "react-router";
import { UserPanel } from "../../../user-panel/UserPanel";
import PSIconText from "../../../icon-with-text/IconText";

export const AppHeader = ({
  menuToggleEnabled,
  title,
  toggleMenu,
  className,
}: AppHeaderProps) => {
  const navigate = useNavigate();

  const handleLinkHomePage = (event: any) => {
    navigate("/");
    event.preventDefault();
  };
  return (
    <header className={`header-component ${className}`}>
      <Toolbar className="header-toolbar">
        <Item
          visible={menuToggleEnabled}
          location="before"
          widget="dxButton"
          cssClass="menu-button"
        >
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>
        <Item location="before" cssClass="header-logo">
          <PSIconText
            src={logos}
            alt="Logo"
            className="polarisLogo"
            height={45}
            width={45}
            onClick={(e) => handleLinkHomePage(e)}
          />
        </Item>
        <Item location="before" cssClass="menu-header-title">
          <div onClick={(e) => handleLinkHomePage(e)}>{title}</div>
        </Item>
        <Item location="before" cssClass="menu-logo">
          <div>
            <PSIconText
              src={WorkSpaces}
              alt="WorkSpaces"
              className="polarisLogo"
              width={45}
              height={45}
              onClick={() => navigate("/workspace-dashboard")}
              text={"Workspaces"}
              eventName="Header Create Workspaces Event"
            />
          </div>
          <div>
            <PSIconText
              src={WorkBooks}
              alt="WorkBooks"
              className="polarisLogo"
              width={45}
              height={45}
              onClick={() => navigate("/my-workbooks")}
              text="Workbooks"
              eventName="Header Create Workbook Event"
            />
          </div>
          <div>
            <PSIconText
              src={Projects}
              alt="DataStudio"
              className="polarisLogo"
              width={45}
              height={45}
              onClick={() => navigate("/projects")}
              text={"Projects"}
              eventName="Header Create Project Event"
            />
          </div>
        </Item>
        <Item location="after" locateInMenu="auto" cssClass="global-search-box">
          <TextBox
            placeholder="Search"
            width={180}
            mode="search"
            stylingMode="filled"
          />
        </Item>
        <Item location="after">
          <div className="messages">
            <Button icon="belloutline" stylingMode="text" />
            {/* <div className="dx-badge">4</div> */}
          </div>
        </Item>
        <Item
          location="after"
          locateInMenu="auto"
          menuItemTemplate="userPanelTemplate"
        >
          <UserPanel menuMode="context" />
        </Item>
        <Template name="userPanelTemplate">
          <UserPanel menuMode="list" />
        </Template>
      </Toolbar>
    </header>
  );
};
