// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NodeWrapper{
  background-color : white;
  border-radius: 10px;
  padding: 15px;
  height: 200px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
  span{
    font-size: 12px;
  }
}

.NodeWrapper .header_info{
  display: flex;
  flex-direction: column;
}

.multiset .react-flow__node {
  width: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ui-components/react-flow/style.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,6BAA6B;EAC7B,4CAA4C;EAC5C;IACE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".NodeWrapper{\n  background-color : white;\n  border-radius: 10px;\n  padding: 15px;\n  height: 200px;\n  width: 200px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);\n  span{\n    font-size: 12px;\n  }\n}\n\n.NodeWrapper .header_info{\n  display: flex;\n  flex-direction: column;\n}\n\n.multiset .react-flow__node {\n  width: 50px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
