import "../../../styles/ui-components/TickerCard.scss";

export const TickerCardStyle = ({
  title,
  value,
  colur,
  formatValue = (value) => `${value}`,
  onClick = () => { },
}: {
  title: string;
  icon: string;
  tone?: "warning" | "info";
  value: number;
  percentage: number;
  colur: string;
  formatValue?: (value: number) => string;
  onClick?: (e: any) => void;
}) => (
  <div className="analytics-ticker" onClick={onClick} style={{ cursor: "pointer" }}>
    <div className="middle">
      <div className="Heading">{title}</div>
      <div
        className="Overall"
        style={{
          color: colur,
        }}
      >
        {formatValue(value)}
      </div>
    </div>
  </div>
);
