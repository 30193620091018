import Form, {
  Item as FormItem,
  GroupItem,
  ColCountByScreen,
} from "devextreme-react/form";
import { FormTextbox } from "../../../../components/ui-components/form/form-textbox/FormTextbox";
import { getSizeQualifier } from "../../../../utlis/media-query";
import { TextArea } from "devextreme-react";
import { Validator, RequiredRule } from "devextreme-react/validator";

const AddNewWorkSpace = (props: any) => {
  const { updateField, newWorkSpaceData } = props;
  return (
    <div>
      <Form className="plain-styled-form" screenByWidth={getSizeQualifier}>
        <GroupItem>
          <ColCountByScreen xs={1} />
          <FormItem>
            <FormTextbox
              placeholder="Workspace Name..."
              value={newWorkSpaceData.workspaceName}
              isEditing={false}
              onValueChange={updateField("workspaceName")}
            ></FormTextbox>
          </FormItem>
          <FormItem>
            <TextArea
              placeholder="Workspace Description..."
              value={newWorkSpaceData.workspaceDescription}
              onValueChanged={(e) =>
                updateField("workspaceDescription")(e.value)
              }
              height={100}
            >
              <Validator>
                <RequiredRule />
              </Validator>
            </TextArea>
          </FormItem>
        </GroupItem>
      </Form>
    </div>
  );
};

export default AddNewWorkSpace;
