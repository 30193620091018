import React, { useState, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { PDFDocument } from 'pdf-lib';
import { formatFileSize } from '../../../../../../utlis/helper';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import '../../../../../../styles/datastudio/splitPdf.scss';
import PsButton from '../../../../../../components/ui-components/button/Button';
import { Popup } from 'devextreme-react';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs?.version}/pdf.worker.min.js`;

export const PdfEditor = (props: any) => {
  const { pdfData, fileName, dataCatalogId, userName, userId, mutate, parentId } = props;

  const [file, setFile] = useState<File | null>(null);
  const [numPages, setNumPages] = useState<number | null>(null);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [pageInput, setPageInput] = useState('');
  const [activeTab, setActiveTab] = useState('main');
  const [keyword, setKeyword] = useState('');
  const [searchResults, setSearchResults] = useState<number[]>([]);
  const [modalPage, setModalPage] = useState<number | null>(null);
  const [modalZoom, setModalZoom] = useState(1);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (pdfData) {
      const base64Data = pdfData?.split(',')?.[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters?.length);
      for (let i = 0; i < byteCharacters?.length; i++) {
        byteNumbers[i] = byteCharacters?.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      setFile(new File([blob], `${fileName}.pdf`, { type: 'application/pdf' }));
    }
  }, [pdfData, fileName]);

  const onDocumentLoadSuccess = ({ numPages }: any) => {
    setNumPages(numPages);
  };

  const updateSelectedPages = (newSelectedPages: number[]) => {
    setSelectedPages(newSelectedPages);
    setPageInput(newSelectedPages?.sort((a, b) => a - b)?.join(', '));
  };

  const togglePageSelection = (pageNumber: number) => {
    const newSelectedPages = selectedPages?.includes(pageNumber)
      ? selectedPages?.filter(page => page !== pageNumber)
      : [...selectedPages, pageNumber];
    updateSelectedPages(newSelectedPages);
  };

  const handlePageInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageInput(event?.target?.value);
  };

  const selectPages = () => {
    const pageNumbers = pageInput?.split(',')?.flatMap(range => {
      const [start, end] = range?.split('-')?.map(num => parseInt(num?.trim(), 10));
      if (isNaN(end)) return [start];
      return Array?.from({ length: end - start + 1 }, (_, i) => start + i);
    });
    const validPageNumbers = pageNumbers?.filter(num => !isNaN(num) && num > 0 && numPages !== null && num <= numPages);
    updateSelectedPages(Array?.from(new Set(validPageNumbers)));
  };

  const extractPages = async () => {
    if (!file || selectedPages?.length === 0) return;

    const fileArrayBuffer = await file?.arrayBuffer();
    const pdfDoc = await PDFDocument?.load(fileArrayBuffer);
    const newPdfDoc = await PDFDocument?.create();

    for (const pageNumber of selectedPages) {
      const [copiedPage] = await newPdfDoc?.copyPages(pdfDoc, [pageNumber - 1]);
      newPdfDoc?.addPage(copiedPage);
    }

    const pdfBytes = await newPdfDoc?.save();

    let binary = '';
    const bytes = new Uint8Array(pdfBytes);
    const len = bytes?.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String?.fromCharCode(bytes[i]);
    }
    const base64Content = btoa(binary);
    const fileSize = formatFileSize(pdfBytes?.length);
    const extension = fileName?.split('.')?.pop();

    const payload = {
      userName: userName,
      userId: userId,
      parentDocumentId: dataCatalogId,
      fileName: [
        {
          name: fileName,
          content: `data:application/pdf;base64,${base64Content}`,
          extension: extension,
          size: fileSize,
        }
      ],
      fileSize: fileSize,
      assetType: extension,
      processType: 'Document Analyzer',
      created_by: userId,
      created_at: ""
    };
    mutate(payload);
  };


  const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setKeyword(event?.target?.value);
  };

  const searchKeyword = async () => {
    if (!file || !keyword) return;

    const reader = new FileReader();
    reader.onload = async (e: any) => {
      const typedarray = new Uint8Array(e?.target?.result);
      const pdf = await pdfjs?.getDocument(typedarray)?.promise;
      const results: number[] = [];

      for (let i = 1; i <= pdf?.numPages; i++) {
        const page = await pdf?.getPage(i);
        const textContent = await page?.getTextContent();
        const text = textContent?.items?.map((item: any) => item?.str)?.join(' ');
        if (text?.toLowerCase()?.includes(keyword?.toLowerCase())) {
          results?.push(i);
        }
      }

      setSearchResults(results);
    };
    reader?.readAsArrayBuffer(file);
  };

  const openModal = (pageNumber: number) => {
    setModalPage(pageNumber);
    setModalZoom(1);
  };

  const closeModal = () => {
    setModalPage(null);
  };

  const nextPage = () => {
    if (modalPage !== null && numPages !== null && modalPage < numPages) {
      setModalPage(modalPage + 1);
    }
  };

  const prevPage = () => {
    if (modalPage !== null && modalPage > 1) {
      setModalPage(modalPage - 1);
    }
  };

  const zoomIn = () => {
    setModalZoom(prev => Math?.min(prev + 0.2, 3));
  };

  const zoomOut = () => {
    setModalZoom(prev => Math?.max(prev - 0.2, 0.5));
  };

  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const handleConfirm = () => {
    extractPages();
    handlePopupClose();
  };

  const fileModel = () => {
    const file_name = fileName?.split('.')?.[0];
    if (parentId === undefined) {
      return (
        <div className="confirmation-modal">
          <p>
            Are you sure you want to save the selected pages as <b>{file_name}_edited.pdf</b>?
          </p>
        </div>
      );
    }
    else {
      return (
        <div className="confirmation-modal">
          <p>
            Are you sure you want to overwrite the selected pages in the <b>{file_name}</b> file?
          </p>
        </div>
      );
    }
  };

  return (
    <div className="App">
      {file && (
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <div className="content-wrapper">
            <div className="main-content">
              <div className="tabs">
                <button
                  className={activeTab === 'main' ? 'active' : ''}
                  onClick={() => setActiveTab('main')}
                >
                  Main
                </button>
                <button
                  className={activeTab === 'search' ? 'active' : ''}
                  onClick={() => setActiveTab('search')}
                >
                  Keyword Search
                </button>
              </div>
              {activeTab === 'main' && (
                <div>
                  <div className="page-selection-input">
                    <input
                      type="text"
                      value={pageInput}
                      onChange={handlePageInputChange}
                      placeholder="Enter page numbers (e.g., 1, 3, 5-7)"
                    />
                    <button onClick={selectPages}>Select Pages</button>
                  </div>
                  <div className="select-page-container">
                    {Array?.from(new Array(numPages), (el, index) => (
                      <div key={`page_${index + 1}`} className="page-card">
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={selectedPages?.includes(index + 1)}
                            onChange={() => togglePageSelection(index + 1)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <div onClick={() => openModal(index + 1)}>
                          <Page
                            pageNumber={index + 1}
                            width={250}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </div>
                        <p>Page {index + 1}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeTab === 'search' && (
                <div>
                  <div className="keyword-search">
                    <input
                      type="text"
                      value={keyword}
                      onChange={handleKeywordChange}
                      placeholder="Enter keyword to search"
                    />
                    <button onClick={searchKeyword}>Search</button>
                  </div>
                  <div className="select-page-container">
                    {searchResults?.map((pageNum) => (
                      <div key={`search_page_${pageNum}`} className="page-card">
                        <label className="checkbox-container">
                          <input
                            type="checkbox"
                            checked={selectedPages?.includes(pageNum)}
                            onChange={() => togglePageSelection(pageNum)}
                          />
                          <span className="checkmark"></span>
                        </label>
                        <div onClick={() => openModal(pageNum)}>
                          <Page
                            pageNumber={pageNum}
                            width={250}
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                          />
                        </div>
                        <p>Page {pageNum}</p>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="selected-pages-panel">
              <h2 className="selected-pages-panel-h2">Selected pages for extraction</h2>
              <button onClick={showPopup} disabled={selectedPages?.length === 0}>
                Save Selected Pages
              </button>
              <div className="page-container">
                {selectedPages?.map((pageNum) => (
                  <div key={`selected_page_${pageNum}`} className="page-card">
                    <label className="checkbox-container">
                      <input
                        type="checkbox"
                        checked={true}
                        onChange={() => togglePageSelection(pageNum)}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <div onClick={() => openModal(pageNum)}>
                      <Page
                        pageNumber={pageNum}
                        width={125}
                        renderAnnotationLayer={false}
                        renderTextLayer={false}
                      />
                    </div>
                    <p>Page {pageNum}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {modalPage && (
            <div className="modal" onClick={closeModal}>
              <div className="modal-content" onClick={(e) => e?.stopPropagation()}>
                <span className="close" onClick={closeModal}>&times;</span>
                <div className="modal-controls">
                  <button onClick={prevPage} disabled={modalPage === 1}>Previous</button>
                  <button onClick={nextPage} disabled={modalPage === numPages}>Next</button>
                  <button onClick={zoomIn}>Zoom In</button>
                  <button onClick={zoomOut}>Zoom Out</button>
                </div>
                <div className="modal-page-wrapper">
                  <Page
                    pageNumber={modalPage}
                    scale={modalZoom}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                  />
                </div>
                <p>Page {modalPage} of {numPages}</p>
              </div>
            </div>
          )}
        </Document>
      )}
      <Popup
        visible={isPopupVisible}
        onHiding={handlePopupClose}
        showCloseButton={true}
        title="Confirmation"
        width="35%"
        height="220px"
      >
        {fileModel()}
        <div style={{ float: "left" }}>
          <PsButton
            onClick={() => handleConfirm()}
            text="confirm"
            type="normal"
            mode="contained"
          />
        </div>
        <div style={{ float: "right" }}>
          <PsButton
            onClick={handlePopupClose}
            text="Cancel"
            type="normal"
            mode="contained"
          />
        </div>
      </Popup>
    </div>
  );
}

