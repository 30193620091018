import Popup from "devextreme-react/popup";
import { useStartProgress } from "../../../../hooks/task/useTask";
import { UseQueryResult } from "react-query";
import { useEffect } from "react";
import PsButton from "../../../ui-components/button/Button";
import showNotification from "../../../ui-components/alertPopup/AlertPopup";
import { useApp } from "../../../../contexts/app";

const TasksStart = (props: any) => {
  const { startPopupVisible, onStartPopupClose, selectedTask } = props;
  const { row } = selectedTask || {};
  const { data } = row || {};
  const { taskId, taskName } = data || {};
  const {
    user: { userId, name },
  } = useApp();

  const { data: startData, refetch: startProgress } = useStartProgress(
    taskId,
    userId,
    name
  ) as UseQueryResult<any>;

  const handleTaskStartProcess = () => {
    startProgress();
    onStartPopupClose();
  };
  const loaderProps = {
    show: startData,
    loadertext: "Please Wait...",
  };

  useEffect(() => {
    if (startData) {
      showNotification({
        message: startData.body || startData.message,
        type: "success",
      });
    }
  }, [startData]);

  return (
    <Popup
      title="Process Start Confirmation"
      visible={startPopupVisible}
      onHiding={onStartPopupClose}
      width="30%"
      height="200px"
    >
        <div className="task-start">
          <p>
            Are you sure you want to start the <b>{taskName}</b> Task ?
          </p>
        </div>

        <div style={{ float: "left" }}>
          <PsButton
            onClick={() => handleTaskStartProcess()}
            text="confirm"
            type="normal"
            mode="contained"
            loaderProps={loaderProps}
            eventName="Task start process event"    
          />
        </div>
        <div style={{ float: "right" }}>
          <PsButton
            onClick={onStartPopupClose}
            text="Cancel"
            type="normal"
            mode="contained"
            eventName="Task start cancel event"    
          />
        </div>
    </Popup>
  );
};

export default TasksStart;
