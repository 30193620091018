import { saveAs } from "file-saver-es";
import {
  confidence90,
  confidence80,
  confidence70,
  confidence60,
  confidenceBG
} from '../config/datastudio/confidence';
import CryptoJS from "crypto-js";

export const removeUndefinedProperties = <T>(obj: Record<string, T>) => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([, value]) => value !== undefined && value !== null
    )
  );
};

export const leadingZero = (num: number) => {
  return num < 10 ? `0${num}` : `${num}`;
};

export const formatDate = (dt: any) => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${leadingZero(month)}/${leadingZero(day)}/${year}:  ${leadingZero(hours)}:${leadingZero(minutes)}`;
};


const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];


export const DayDateFormat = (dt: any): string => {
  const date = new Date(dt);
  const year = date.getFullYear();
  const month = date.getMonth() + 1; 
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const dayOfWeek = daysOfWeek[date.getDay()];
  
  return `${dayOfWeek}, ${leadingZero(month)}/${leadingZero(day)}/${year} ${leadingZero(hours)}:${leadingZero(minutes)}`;
};


export const truncateString = (str: any, maxLength: number) => {
  if (typeof str !== "string") {
    return null;
  }

  if (typeof maxLength !== "number" || maxLength < 0) {
    return null;
  }

  if (str.length <= maxLength) {
    return str;
  } else {
    return str.slice(0, maxLength) + "...";
  }
};

export const getTokenFromHash = (hash: any,name:string) => {
  const params = new URLSearchParams(hash.substring(1));
  return params.get(name);
};



export const convertToUSD = (value: any) => {
  if (value == null) {
    return '$0.00';
  }
  const stringValue = String(value);
  const numericValue = parseFloat(stringValue.replace('$', ''));
  if (isNaN(numericValue)) {
    throw new Error('Invalid numeric value');
  }
  const formattedValue = numericValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `$${formattedValue}`;
};



const colors: string[] = [
  '#063970', '#dca67c', '#CB6545', '#d5c09f', '#6ac4d6', '#f0c77d', 
  '#F1F1F1', '#004E8C', '#7B85BC', '#96A033', '#26837A', '#26837A',
];

export const getUserColor = (name: string): { color: string, initials: string } => {
  const charCodeSum = name.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const color = colors[charCodeSum % colors.length];
  const initials = name.split(' ').map((n) => n[0]).join('').toUpperCase();
  return { color, initials };
};

export const generateRandomId =() =>{
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < 5; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
  }
  return result;
}


export const exportToCSV = (data: any[], filename: string) => {
  const csvData = convertToCSV(data);
  const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
  saveAs(blob, filename);
};

export const convertToCSV = (data: any[]) => {
  if (!data || !data?.length) {
    return "";
  }

  const allKeys = new Set<string>();
  data?.forEach((row) => {
    Object?.keys(row)?.forEach((key) => {
      if (key !== 'confidence') {
        allKeys.add(key);
      }
    });
  });
  const keys = Array?.from(allKeys);
  const csvRows = data?.map((row) =>
    keys?.map((key) => `"${row[key] || ""}"`)?.join(",")
  );
  csvRows?.unshift(keys?.join(","));

  return csvRows?.join("\n");
};

export const formatDateAndSplit = (date:any) => {
  if (!date) return "";
  const formattedDate = formatDate(date);
  return formattedDate.split(":")[0];
};

export const getRowStyle = (confidenceValue: number, selectedConfidence: any) => {
  if (selectedConfidence === null) return {};
  if (selectedConfidence === confidence90 && confidenceValue < confidence90) {
    return { backgroundColor: confidenceBG };
  } else if (selectedConfidence === confidence80 && confidenceValue < confidence80) {
    return { backgroundColor: confidenceBG };
  } else if (selectedConfidence === confidence70 && confidenceValue < confidence70) {
    return { backgroundColor: confidenceBG };
  } else if (selectedConfidence === confidence60 && confidenceValue < confidence60) {
    return { backgroundColor: confidenceBG };
  }
  return {};
};

export const getPreviewRowStyle = (confidenceValue: number, selectedConfidence: any) => {
  const activeConfidence = selectedConfidence === null ? confidence90 : selectedConfidence;
  if (activeConfidence === confidence90 && confidenceValue < confidence90) {
    return { backgroundColor: confidenceBG };
  } else if (activeConfidence === confidence80 && confidenceValue < confidence80) {
    return { backgroundColor: confidenceBG };
  } else if (activeConfidence === confidence70 && confidenceValue < confidence70) {
    return { backgroundColor: confidenceBG };
  } else if (activeConfidence === confidence60 && confidenceValue < confidence60) {
    return { backgroundColor: confidenceBG };
  }
  return {};
};

  // Encrypt function
  export const encryptText = (text: any,specialKey:string) => {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      specialKey
    ).toString();
    return ciphertext;
  };

  // Decrypt function
  export const decryptText = (ciphertext: any,specialKey:string) => {
    if(ciphertext === null) return null;
    const bytes = CryptoJS.AES.decrypt(ciphertext, specialKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  export const replaceQueryUrl = (queryName: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set(queryName, value);

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, "", newUrl);
  };

  export const formatFileSize = (bytes : number) =>{
    if (bytes === 0) return "0 KB";
    const sizeKB = bytes / 1024;
    return `${Math?.round(sizeKB)} KB`;
  };

export const generateShortName = (name: string): string => {
  if (!name) return "";

  const currentYear = new Date().getFullYear().toString();
  let shortName = name.split(" ").join("").toUpperCase();

  if (shortName.length < 5) {
    shortName = shortName + currentYear;
  }

  return shortName.slice(0, 5);
};

export const convertValueToJsonFile = (value:any) => {
  const jsonValue = JSON.stringify(value, null, 2);
  return new Blob([jsonValue], { type: "application/json" });
}