import "../../styles/core-components/staticinfo.scss";
import logos from "../../assets/images/shore-logo-1.png";

const PageError = () => {
  return (
    <div id="static-info">
      <div className="logo-wrapper">
        <img
          src={logos}
          alt="Logo"
          className="polarisLogo"
          style={{ width: "45px", height: "45px" }}
        />
        <span>Polaris</span>
      </div>
      <h1>Somthing Went Wrong</h1>
    </div>
  );
};

export default PageError;
