import ManualDataEntry from "../assets/media/manualdataentry.jpeg";
import DatraVisualization from "../assets/media/datavisualiation.jpeg";
import DataScience from "../assets/media/datascience.jpeg";
import ReportsDashboard from "../assets/media/reports.jpeg";
import AddFiles from "../assets/media/addfiles.jpeg";
import DataAnalysis from "../assets/media/dataanalysis.jpeg";

export const WorkBooItemMaps = [
  {
    id: 1,
    image: ManualDataEntry,
    name: "Manual Data Entry",
    description: [
      "Effortlessly extract valuable information from your PDF documents.",
      "Transform unstructured data into organized formats for easy analysis.",
      "Identify patterns, keywords, and trends within your documents.",
    ],
  },
  {
    id: 2,
    name: "Data Analysis",
    image: DataAnalysis,
    description: [
      "Gather specific data from multiple websites with ease.",
      "Build customized datasets for research, analysis, or business intelligence.",
      "Save time by automating the process of extracting online information.",
    ],
  },
  {
    id: 6,
    name: "Add Files",
    image: AddFiles,
    description: [
      "Monitor key metrics, track performance, and identify trends.",
      "Gain a comprehensive overview of your business operations.",
      "Make informed decisions based on real-time data analysis.",
    ],
  },
  {
    id: 3,
    name: "Reports & Dashboards",
    image: ReportsDashboard,
    description: [
      "Explore your data through advanced analysis techniques.",
      "Uncover hidden patterns, correlations, and insights.Clean, transform, and prepare your data for modeling and machine learning.",
      "Make data-driven decisions that optimize your business strategies.",
    ],
  },
  {
    id: 4,
    name: "Data Science",
    image: DataScience,
    description: [
      "Design and implement efficient data pipelines for reliable processing. ",
      "Integrate data from various sources, including databases and APIs. ",
      "Automate data ingestion, transformation, and storage tasks.",
      "Ensure data quality, consistency, and accessibility.",
    ],
  },
  {
    id: 5,
    name: "Data Visualization",
    image: DatraVisualization,
    description: [
      "Enter and validate data from various sources, such as forms or spreadsheets.",
      "Ensure accuracy and completeness of client information.",
      "Reconcile discrepancies and resolve data inconsistencies.",
    ],
  }
];


export const DataEntryTemplateRb = [
  {
    "column1": "Url",
    "column2": "Floor Name",
    "column3": "Unit Number",
    "column4": "Bed Count",
    "column5": "Bath Count",
    "column6": "Availability",
  }
] 


export const DataEntryTemplateDd = [
  {
    "column1": "Name",
    "column2": "Email",
    "column3": "Phone",
    "column4": "Address",
    "column5": "City",
    "column6": "State",
  }
] 

export const DataEntryTemplateRb02 = [
  {
    "column1": "Url",
    "column2": "Bath count",
    "column3": "Bed Count",
    "column4": "Availability",
    "column5": "Rent",
    "column6": "Floor Name",
  }
] 

export const TemlatesDataEntry = [
  {
    "templatename": "Rental Beast Template",
    "value": DataEntryTemplateRb,
  },
  {
    "templatename": "Discovery Data Template",
    "value": DataEntryTemplateDd,
  },
  {
    "templatename": "Rental Beast Template 02",
    "value": DataEntryTemplateRb02,
  },
  {
    "templatename": "Create New Template",
    "value": "newTemplateEntry",
  },
]
