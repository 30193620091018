import React, { useEffect, useMemo, useRef } from "react";
import PivotGridDataSource from "devextreme/ui/pivot_grid/data_source";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver-es';
import { exportPivotGrid } from 'devextreme/excel_exporter';
import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Export,
  Legend,
  Size,
  Tooltip,
} from "devextreme-react/chart";
import PivotGrid, { FieldChooser, PivotGridTypes } from "devextreme-react/pivot-grid";
import PivotGridRef from "devextreme-react/pivot-grid";
import ChartRef from "devextreme-react/pivot-grid";
import { PsPviotGridProps } from "./types";
import "../../../styles/ui-components/PivotGridWithChart.scss";
import { customDateRange } from "../../../config/constants";

const PivotWithChart = (props: PsPviotGridProps) => {
  const { id, dataSource: data, customizeTooltip, legendAlignment,onCellClick, clientName, timePeriod, startTime, endTime, pivotChartViewStatus } = props;
  const { verticalAlignment = "top", horizontalAlignment = "center" } =
    legendAlignment || {};

  const dataSource = useMemo(
    () =>
      new PivotGridDataSource({
        ...data,
        fields: data.fields.map((field) => ({
          ...field,
          expanded: true, 
        })),
      }),
    [data]
  );

  const chartRef = useRef<ChartRef | any>(null);
  const pivotGridRef = useRef<PivotGridRef | any>(null);

  const fileName = () => {
    if (timePeriod === customDateRange) {
      return `${clientName}_${startTime}_to_${endTime}_${pivotChartViewStatus}`;
    } else if (timePeriod){
      return `${clientName}_${timePeriod}_${pivotChartViewStatus}`;
    }
    return `${clientName}_${pivotChartViewStatus}`;
  }

  const onExporting = (e: PivotGridTypes.ExportingEvent) => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Report');
  
    exportPivotGrid({
      component: e.component,
      worksheet,
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName()}.xlsx`);
      });
    });
  };

  useEffect(() => {
    if (pivotGridRef.current && chartRef.current) {
      const pivotGridInstance = pivotGridRef.current.instance;
      if (pivotGridInstance) {
        pivotGridInstance.bindChart(chartRef.current.instance, {
          dataFieldsDisplayMode: "splitPanes",
          alternateDataFields: false,
        });
      }
    }
  }, [dataSource]);

  return (
    <React.Fragment>
      <Chart className="chart-container" ref={chartRef}>
        <Size height={400} />
        <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
        <CommonSeriesSettings type="bar" />
        <AdaptiveLayout width={400} />
      </Chart>
    
    
      <PivotGrid
        id={id + " pivotGrid-chart"}
        dataSource={dataSource}
        onExporting={onExporting}
        allowSortingBySummary={true}
        allowFiltering={true}
        showBorders={true}
        showColumnTotals={true}
        showColumnGrandTotals={true}
        showRowTotals={true}
        showRowGrandTotals={true}
        ref={pivotGridRef}
        onCellClick={onCellClick}
        onCellPrepared={(e: any) => {
          if (e?.area === "data") {
            e.cellElement?.classList.add("pivot-grid-cell");
          }
        }}
      >
        <FieldChooser enabled={true} height={400} />
        <Export enabled={true} />
      </PivotGrid>
    </React.Fragment>
  );
};

export default PivotWithChart;

