import DataGrid, {
  Column,
  MasterDetail,
  Paging,
  Pager,
  DataGridTypes,
  SearchPanel,
} from "devextreme-react/data-grid";
import { CustomItem } from "./helper-component/invoiceComponent";
import { CustomTitle } from "./helper-component/invoiceComponent";
import "../../../styles/finops/client-info.scss";
import PsAccordion from "../../../components/ui-components/accordion/accordion";
import { CellDollar ,cellTimeStamp,cellStartTime} from "../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../config/finops/commonConfig";

const detailRender = (detail: DataGridTypes.MasterDetailTemplateData) => {
  const clientData = detail.data?.billDate || [];
  return (
    <div>
      <DataGrid
        dataSource={clientData}
        repaintChangesOnly={true}
        showBorders={true}
        noDataText={"No Invoice detail to display at this time"}
        allowColumnReordering={true}
      >
        
        <Column dataField="invoiceId" caption="Invoice Id" dataType="string" />
        <Column
          dataField="bill_date"
          caption="Billing Period"
          dataType="string"
        />
        <Column dataField="date" caption="Invoice Date" dataType="string" cellRender={cellTimeStamp} />

        <Column dataField="totalcost" caption="Total Cost" dataType="string" cellRender={CellDollar} />

        <MasterDetail enabled={true} render={clientRender}></MasterDetail>
      </DataGrid>
    </div>
  );
};

const clientRender = (detail: DataGridTypes.MasterDetailTemplateData) => {
  const clientData = detail.data?.projects || [];
  return (
    <div>
      <DataGrid
        dataSource={clientData}
        repaintChangesOnly={true}
        showBorders={true}
        noDataText={"No Invoice detail to display at this time"}
        allowColumnReordering={true}
      >


        <Column dataField="projectName" dataType="string" />
        <Column dataField="description" dataType="string" />
        <Column dataField="status" dataType="string" />
        <Column dataField="startDate" dataType="string"  cellRender={cellStartTime}/>
        <Column dataField="totalcost" dataType="string" cellRender={CellDollar}/>
        <MasterDetail enabled={true} render={detailRenderTask}></MasterDetail>
      </DataGrid>
    </div>
  );
};

const detailRenderTask = (detail: DataGridTypes.MasterDetailTemplateData) => {
  return (
    <PsAccordion
      dataSource={detail.data?.invoice}
      Component={CustomItem}
      CustomTitle={CustomTitle}
    />
  );
};

const InvoiceInformations = (props: any) => {
  const { gridDataSource, isLoading, isRefetching,height } = props || {};
  return (
      <>
      <div className="recent-activity-grid">
        <DataGrid
          className={`grid theme-dependent ${height}`}
          dataSource={gridDataSource}
          noDataText={"No Invoice detail to display at this time"}
          repaintChangesOnly={true}
          allowColumnReordering={true}
          showBorders={true}
        >
        <SearchPanel visible={true} width={200}/>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
          <Column dataField="clientName" dataType="string" />
          <MasterDetail enabled={true} render={detailRender}></MasterDetail>
        </DataGrid>
      </div>  
    </>
  );
};

export default InvoiceInformations;
