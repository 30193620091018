import DataGrid, { Column, HeaderFilter } from 'devextreme-react/data-grid';
import { CellDollar } from '../../../../components/ui-components/dataGridFormat/dataGridFormat';
import { convertToUSD } from '../../../../utlis/helper';
import '../../../../styles/finops/estimator.scss';

export const CustomTitle = (data: any) => {
  return <div className="custom-title-accordion">
    <div className="header">{data?.serviceInfo}</div>
    <div className="header headerCost">Total Cost {convertToUSD(data?.totalCost)}</div>
  </div>
};


export function CustomItem(data: any) {

  return (

    <div style={{marginTop:"10px"}}>
      {data.data && (
        <DataGrid
          dataSource={data.data}
          columnAutoWidth={true}
          noDataText={"No Estimates to display at this time"}
          showBorders={true}
          allowColumnReordering
          allowColumnResizing        
        >
        <HeaderFilter visible={true} />
          <Column
            dataField="designation"
            caption="Role"
            cellRender={(cellData: any) => {
              const resource = cellData?.data?.resource;
              const designation = cellData?.data?.designation;

              return (
                <div>
                  <div>{resource}</div>
                  <div>{designation}</div>
                </div>
              );
            }}
          />

          <Column dataField="description" caption="Description" />
          <Column dataField='cost_per_day' caption='Cost per Day' alignment='center' cellRender={CellDollar}/>
          <Column dataField='quantity' caption="Quantity" alignment='center' />
          <Column dataField="status" caption="Status" />
          <Column dataField="per_month_cost" caption='Cost Per Month' alignment='center' cellRender={CellDollar}/>
        </DataGrid>
      )}
    </div>
  );
}


