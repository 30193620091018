import React, { useState, useEffect, useMemo, useCallback } from "react";
import { DataGridTypes } from "devextreme-react/data-grid";
import { useStopProgress } from "../../../../../hooks/task/useInfra";
import "../../../../../styles/task/infra-data.scss";
import {
  cellEndTime,
  cellStartTime,
} from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import ConfirmModal from "./helper-component/ConfirmModal";
import showNotification from "../../../../../components/ui-components/alertPopup/AlertPopup";
import WorkflowProcessDetails from "./WorkflowDetails";
import PsDataGrid from "../../../../../components/ui-components/dataGrid/DataGrid";

const ProcessDetails = (props: DataGridTypes.MasterDetailTemplateData) => {
  const {
    execution_details,
    clientId,
    taskId,
    executionId,
    clientTag,
    customerName,
    taskName,
  } = props?.data?.data || {};

  const [popupVisible, setPopupVisible] = useState(false);
  const { mutate, isLoading, error, isSuccess } = useStopProgress();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `terminating container instance...`,
        type: "success",
      });
    } else if (error) {
      showNotification({
        message: `Error terminating container instance: ${error.message}`,
        type: "error",
      });
    }
  }, [isSuccess, isLoading, error]);

  const onHandleConfirm = useCallback(
    (e: any) => {
      const cotainerGroupData = e?.row?.data;
      const { containers } = cotainerGroupData;
      const containerName = containers[0]?.container_name;
      const queueMsg = {
        container_name: containerName,
        container_group_name: e?.row?.data?.group_name,
        module: "manual_shutdown_container",
        customer_id: clientId,
        taskId: taskId,
        executionId: executionId,
        clientTag: clientTag,
        clientName: customerName,
        taskName: taskName,
      };
      mutate(queueMsg);
    },
    [clientId, clientTag, customerName, executionId, mutate, taskId, taskName]
  );

  const onHandleModal = useCallback(() => {
    setPopupVisible(!popupVisible);
  }, [popupVisible]);

  const ConfirmModalProps = useMemo(() => {
    return {
      onHandleConfirm,
      onHandleModal,
      popupVisible,
      isLoading,
    };
  }, [onHandleConfirm, onHandleModal, popupVisible, isLoading]);

  const columns = [
    {
      dataField: "name",
      caption: "Workflow Function",
    },
    {
      dataField: "starttime",
      caption: "Start Time",
      cellRender: cellStartTime,
    },
    {
      dataField: "endTime",
      caption: "End Time",
      cellRender: cellEndTime,
    },
    {
      dataField: "status",
      caption: "Status",
    },
  ];

  const executionDetailsWithId = execution_details?.map(
    (item: any, index: any) => ({
      id: `row-${index}`,
      ...item,
    })
  );

  const handleCellPrepared = (e: any) => {
    if (e.rowType === "data" && e.columnIndex === 0 && e.rowIndex === 0) {
      e.cellElement.style.visibility = "hidden";
    }
  };

  const gridProps = {
    dataSource: executionDetailsWithId,
    columns,
    keyExpr: "id",
    detailComponent: WorkflowProcessDetails,
    onCellPrepared: handleCellPrepared,
  };
  const pageProps = {
    nodataText: "No Function Details to display at this time",
    id:"processDetailsGrid",
  };

  return (
    <React.Fragment>
      <PsDataGrid pageProps={pageProps} gridProps={gridProps} />
      <ConfirmModal {...ConfirmModalProps} />
    </React.Fragment>
  );
};

export default ProcessDetails;
