import DataGrid, { Column } from "devextreme-react/data-grid";
import PsCheckBox from "../../../../../components/ui-components/checkbox/Checkbox";

const PageLsit: any = {
  client: [
    {
      id: 1,
      name: "Client Dashborad",
      roles: {
        admin: {
          isActive: true,
          Value: "Admin",
        },
        reader: {
          isActive: true,
          Value: "Reader",
        },
        contributor: {
          isActive: true,
          Value: "Contributor",
        },
      },
    },
    { id: 2, name: "Project" },
    { id: 3, name: "Client Catlog" },
  ],
  dataStudio: [
    { id: 1, name: "Document Catlog" },
    { id: 2, name: "Document Analyzer" },
    { id: 3, name: "Data Catlog" },
    { id: 4, name: "Data Analyzer" },
  ],
  digitalService: [
    {
      id: 1,
      name: "Web Scraping",
      roles: {
        admin: {
          isActive: true,
          Value: "Admin",
        },
        reader: {
          isActive: true,
          Value: "Reader",
        },
        contributor: {
          isActive: true,
          Value: "Contributor",
        },
      },
    },
    { id: 2, name: "Document Processing" },
    { id: 3, name: "Data Engineering" },
    { id: 4, name: "Data Lake Managment" },
  ],
  activityMonitor: [
    { id: 1, name: "Aelert & Exceptions" },
    { id: 2, name: "Activity dashboard" },
    { id: 3, name: "Logs & Matrics" },
  ],
  finops: [
    { id: 1, name: "Finops Dashboard" },
    { id: 2, name: "Rate Card" },
    { id: 3, name: "Client Invoice" },
    { id: 4, name: "Resource Calculator" },
  ],
  taskAutomation: [
    { id: 1, name: "Automation dashboard" },
    { id: 2, name: "Infrastructure Servcie" },
  ],
};

const AccessList = (props: any) => {
  console.log("AccessList", props);
  const AccessKey: any = props.data?.data?.key;
  const AccessData: any = PageLsit[AccessKey];
  const onChange = () => {
    console.log("on change");
  };
  const renderCheckbox = (cell:any) => {
    const roles: any = cell.data?.roles || {};
    return Object.entries(roles).map(([key, value]: any) => {
        return (
            <PsCheckBox
            id={key}
            text={value.Value}
            value={value.isActive}
            onChange={onChange}
            />
        );
    });
  };

  return (
    <DataGrid
      dataSource={AccessData}
      remoteOperations={true}
      showBorders={true}
      id="gridContainer"
      allowColumnResizing={true}
      allowColumnReordering={true}
      repaintChangesOnly={true}
    >
      <Column caption="" dataField="name" dataType="string" />
      <Column
        caption=""
        dataField="roles"
        dataType="boolean"
        cellRender={renderCheckbox}
      />
    </DataGrid>
  );
};

export default AccessList;
