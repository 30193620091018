import { useCallback, useReducer } from "react";
import {
  createWorkflowApi,
  fetchWorkflowApi,
  fetchManifestDataApi,
  fetchWorkflowFunctionDropdown,
  editWorkflowApi,
  deleteWorkflowApi,
} from "../../service/task/api-service";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { WorkflowState } from "../../pages/task/workflow/types";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";
import { formatDate } from "devextreme/localization";

const initialState: WorkflowState = {
  popupVisible: false,
  openPopup: false,
  selectedWorkflowData: {},
  showCancelPopup: false,
  selectedValues: '',
  editPopupVisible: false,
  deletePopupVisible: false,
};

const reducer = (state: WorkflowState, action: any) => {
  switch (action.type) {
    case "POPUP_VISIBLE":
      return {
        ...state,
        popupVisible: action.payload,
      };
    case "OPEN_POPUP":
      return {
        ...state,
        openPopup: action.payload,
      };
    case "SELECTED_WORKFLOW_DATA":
      return {
        ...state,
        selectedWorkflowData: action.payload,
      };
    case "SHOW_CANCEL_POPUP":
      return {
        ...state,
        showCancelPopup: action.payload,
      };
    case "SELECTED_VALUES":
      return {
        ...state,
        selectedValues: action.payload,
      };
    case "EDIT_POPUP_VISIBLE":
      return {
        ...state,
        editPopupVisible: action.payload,
      };
      case "DELETE_POPUP_VISIBLE" : 
      return {
        ...state,
        deletePopupVisible: action.payload,
      }
    case "CLEAR":
      return initialState;
    default:
      return state;
  }
};

export const useWorkflow = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setSelectedWorkflowData = useCallback((data: any) => {
    dispatch({ type: "SELECTED_WORKFLOW_DATA", payload: data });
  }, []);

  const onAddWorkflowOpen = useCallback((data: any) => {
    dispatch({ type: "POPUP_VISIBLE", payload: data });
  }, []);

  const onEditWorkflowOpen = useCallback((data: any) => {
    const selectedData = data?.row?.data
    setSelectedWorkflowData(selectedData);
    dispatch({ type: "EDIT_POPUP_VISIBLE", payload: !!selectedData });
  }, [setSelectedWorkflowData]);

  const setOpenPopup = useCallback((data: any) => {
    dispatch({ type: "OPEN_POPUP", payload: data });
  }, []);

  const setShowCancelPopup = useCallback((data: any) => {
    dispatch({ type: "SHOW_CANCEL_POPUP", payload: data });
  }, []);

  const setSelectedValues = useCallback((data: any) => {
    dispatch({ type: "SELECTED_VALUES", payload: data });
  }, []);

  const onDeletePopupOpen = useCallback((data: any) => {
    setSelectedWorkflowData(data);
    dispatch({type: "DELETE_POPUP_VISIBLE", payload: true})
   }, []);

   const onDeletePopupClose = useCallback(() => {
    dispatch({type : "DELETE_POPUP_VISIBLE", payload : false})
   }, []);

  return {
    state,
    onAddWorkflowOpen,
    setOpenPopup,
    setSelectedWorkflowData,
    onEditWorkflowOpen,
    setShowCancelPopup,
    setSelectedValues,
    onDeletePopupOpen,
    onDeletePopupClose,
  };
};

export const useFetchWorkflowFunctionDropdown = () => {
  return useQuery(
    "fetch-workflow-function-dropdown",
    fetchWorkflowFunctionDropdown,
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useCreateWorkflow = (closePanel: () => void) => {
  const fnQueryClient = useQueryClient();
  return useMutation(createWorkflowApi, {
    onSuccess: () => {
      showNotification({
        message: "Workflow created successfully",
        type: "success",
      });
      closePanel();
      fnQueryClient.invalidateQueries("fetch-workflow");
      fnQueryClient.invalidateQueries("fetch-manifest-data");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useEditWorkflow = (closePanel: () => void) => {
  const fnQueryClient = useQueryClient();
  return useMutation(editWorkflowApi, {
    onSuccess: (data: any) => {
      showNotification({
        message: data?.response,
        type: "success",
      });
      closePanel();
      fnQueryClient.invalidateQueries("fetch-workflow");
      fnQueryClient.invalidateQueries("fetch-manifest-data");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchWorkflowApi = (
  selectedDays: any,
  clientId: any,
  projectId: any,
  Count: any,
  sortOrder: any,
  sortColumn: any,
  pageIndex: any,
  isNextPage: any
) => {
  const startTime = selectedDays?.startTime
    ? formatDate(selectedDays?.startTime, "yyyy-MM-dd")
    : null;
  const endTime = selectedDays?.endTime
    ? formatDate(selectedDays?.endTime, "yyyy-MM-dd")
    : null;
  const timePeriod = selectedDays?.timePeriod;
  const page = pageIndex[pageIndex.length - 1] + 1;

  const queryParams = {
    startTime,
    endTime,
    timePeriod,
    clientId,
    projectId,
    Count,
    sortOrder,
    sortColumn,
    Page: page,
  };

  return useQuery(
    ["fetch-workflow", queryParams],
    () => fetchWorkflowApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !isNextPage,
      select(data: any) {
        const list = data?.data || [];
        const totalcount = data?.pagination?.totalRecords || 0;
        return { list, totalcount };
      },
      onError: (error: Error) => {
        console.error(error);
      },
    }
  );
};

export const useFetchManifestData = (manifestId: string) => {
  return useQuery(
    ["fetch-manifest-data", manifestId],
    () => fetchManifestDataApi(manifestId),
    {
      enabled: !!manifestId,
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useDeleteWorkflow = (
  onDeleteHandler: () => void,
  workflowId: string,
  workFlowName : string,
  userId: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ["deleteWorkflow", workflowId, userId],
    () => deleteWorkflowApi(workflowId, userId),
    {
      enabled: !!workflowId && !!userId && false,
      refetchOnWindowFocus: false,
      onSuccess: () => {
        onDeleteHandler();
        queryClient.invalidateQueries("fetch-workflow");
        showNotification({
          message: `${workFlowName} Deleted Successfully`,
        });
      },
    }
  );
};
