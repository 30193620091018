import { Item } from "devextreme-react/toolbar";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import {useDocumentAnalyze,} from "../../../hooks/datastudio/useDocumentAnalyze";
import { DropDownButton } from "devextreme-react";
import { SelectBox } from "devextreme-react";
import {exportItems,} from "../../../config/datastudio/pageConfig";
import { useCallback, useEffect, useMemo, useState } from "react";
import { dropDownOptions } from "../../../config/datastudio/commonConfig";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "../../../styles/datastudio/DocumentAnalyze.scss";
import { useHelperHooks } from "../../../hooks/helper/utils";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import SaveIcon from "../../../assets/icons/icon-save-1.png";
import uploadIcon from "../../../assets/icons/icon-upload-1.png";
import MyFilesIcon from "../../../assets/icons/icon-file-list-2.png";
import Addfiles from "./helper-component/AddFiles";
import MyDocuments from "./helper-component/MyDocuments/MyDocuments";
import AnalyzedResult from "./helper-component/result/AnalyzedResult";
import PdfEditIcon from "../../../assets/icons/icon-pdf-edit.png";
import {PdfEditor } from "./helper-component/result/helper-component/PdfEditor";
import {FormatTitle } from "./types";
import {useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import PsExport from "../../../components/core-component/exportDatalake/PsExport";
import { usePage } from "../../../contexts/pageContext";
import { customDateRange } from "../../../config/constants";
import { fetchRecenAnalyseDataApi } from "../../../service/datastudio/api-service";

export const DocumentAnalyze = () => {

  const {
    state: { tabView, uploadData, pdfPage, rowTitle, rowGrid, selectedConfidence, isRowUpdated, confidenceValue, pdfFinalData, metricsData, telemetryDatas},
    analyzedData,
    telemetryData,
    pdfData,
    DocumentData,
    isLoading,
    isFetching,
    isPdfSaving,
    hasFileUploaded,
    confidenceData,
    documentLoading,
    onTabChange,
    handleFilesUpload,
    handleItemClick,
    handleSaveDocument,
    handleAnalyzeDocument,
    handleRowClick,
    handleApplyConfidence,
    setPdfPage,
    rowUpdated,
    refetch,
    confidenceSelectBoxRef,
    parentData,
    saveEditAnalyzePdf,
    setPdfFinalData,
    setMetricsData,
    setTelemetryData,
    onCurrentValueChange,
    pageIndex,
    pageSize,
    handleDropDownPageIndex,
    handlePageIndexChange,
  } = useDocumentAnalyze();

  const [pdfEditorData, setPdfEditorData] = useState<any>(null);
  const [showPsExport, setShowPsExport] = useState(false);
  const [exportData, setExportData] = useState({ userId: "", fileName: "", processType: "" });
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const activeTab: any = Object.keys(tabView)?.[0];
  const { data: DateRange } = useFetchDateRange();

  const rowTitles: any = FormatTitle(rowTitle);

  const refetchs = () => {
    refetch();
    confidenceSelectBoxRef?.current?.instance?.reset();
  };

  const {
    state: {selectedDays},
    handleColumnIndexChange,
    handleDateRangeChange
  } = useAppHook();
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
  } = filterValue || {};
  
  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      processType: "Document Analyzer",
      timePeriod,
      ...(timePeriod === customDateRange && { startTime, endTime }),
    };
  }, [filterValue]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchRecenAnalyseDataApi,
      additionalParams: additionalParams,
    }),
    [additionalParams]
  );

  const AdditionalToolbarContent = (
    <Item
      location="after"
      locateInMenu="auto"
      cssClass="toolbar-item-flex"
    >
      <SelectBox
        ref={confidenceSelectBoxRef}
        dataSource={confidenceData}
        defaultValue={confidenceValue}
        displayExpr="confidence"
        width={180}
        showClearButton={true}
        placeholder={"Select Confidence"}
        disabled={!analyzedData}
        onValueChange={handleApplyConfidence}
      />
       <DropDownButton
        icon="export"
        text="Export"
        width="auto"
        stylingMode="text"
        items={exportItems}
        useSelectMode={true}
        displayExpr="text"
        dropDownOptions={dropDownOptions}
        onItemClick={(e) => {
          handleItemClick(e);
          if (e.itemData.text === "Export to DataLake") {
            setShowPsExport(true); 
          }
        }}
        disabled={
          activeTab !== "resultGrid" &&
          activeTab !== "functions" &&
          activeTab !== "validation"
        }
        className="dropdown-export"
      />
      <PSIconText
        alt="addFiles"
        text="Upload Files"
        src={uploadIcon}
        onClick={() => onTabChange("addFiles")}
      />
      <PSIconText
        text="PDF Editor"
        alt="pdfeditor"
        src={PdfEditIcon}
        onClick={() => onTabChange("pdfEditor")}
        disabled={
          !pdfData?.isSuccess ||
          (rowGrid && rowGrid?.status !== 'Uploaded')
        }
      />
      <PSIconText
        text="Documents"
        alt="Documents"
        src={MyFilesIcon}
        onClick={() => onTabChange("myFiles")}
      />
      <PSIconText
        text="Save"
        alt="Save"
        src={SaveIcon}
        disabled={!isRowUpdated}
        onClick={handleSaveDocument}
      />
      {(activeTab === "myFiles" || activeTab === "merge") && (
      <DateDropDown
       onDateChange={handleDateRangeChange}
       data={DateRange}
       handleDropDownPageIndex={handleDropDownPageIndex}
      />)}
    </Item>
  );

  const AddFileProps = useMemo(() => {
    return {
      handleFilesUpload,
      isPdfSaving,
    };
  }, [handleFilesUpload, isPdfSaving]);

  useEffect(() => {
    if (pdfData?.data || uploadData?.content) {
      const catalogId = rowGrid?.parentId ? rowGrid?.parentId : rowGrid?.dataCatalogId;
      const data = {
        pdfData: pdfData?.data || uploadData?.content,
        fileName: rowGrid?.fileName || parentData?.fileName,
        dataCatalogId: catalogId || parentData?.dataCatalogId,
        userName: rowGrid?.userName || uploadData?.userName,
        userId: rowGrid?.userId || uploadData?.userId,
        processType: rowGrid?.processType,
        mutate: saveEditAnalyzePdf,
        parentId: rowGrid?.parentId
      }
      setPdfEditorData(data);
      setExportData({ userId: data.userId, fileName: data.fileName,processType:data.processType}); 
    }
  }, [uploadData, parentData, rowGrid, saveEditAnalyzePdf, pdfData?.data]);

  const PdfEditorProps = useMemo(() => {
    return pdfEditorData;
  }, [pdfEditorData]);

  const MyDocumentProps = useMemo(() => {
    return {
      DocumentData,
      onRowClick: handleRowClick,
      isFetching,
      documentLoading,
      setPdfFinalData,
      pdfFinalData,
      setMetricsData,
      metricsData,
      setTelemetryData,
      telemetryDatas,
      pageIndex,
      pageSize,
      handlePageIndexChange,
      apiProps,
      handleColumnIndexChange,
    };
  }, [DocumentData, handleRowClick, isFetching, documentLoading, setPdfFinalData, pdfFinalData, setMetricsData, metricsData, setTelemetryData, telemetryDatas, pageIndex, pageSize, handlePageIndexChange, apiProps, handleColumnIndexChange]);

  const AnalyzedresultProps = useMemo(() => {
    return {
      analyzedData,
      isLoading,
      selectedConfidence,
      telemetryData,
      setPdfPage,
      uploadData,
      pdfData,
      pdfPage,
      isPdfSaving,
      handleAnalyzeDocument,
      rowUpdated,
      hasFileUploaded,
      isFetching
    };
  }, [analyzedData, isLoading, selectedConfidence, telemetryData, setPdfPage, uploadData, pdfData, pdfPage, isPdfSaving, handleAnalyzeDocument, rowUpdated, hasFileUploaded, isFetching]);
  const renderBody = useCallback(() => {
    const view: any = {
      addFiles: <Addfiles {...AddFileProps} />,
      pdfEditor: <PdfEditor {...PdfEditorProps} />,
      myFiles: <MyDocuments {...MyDocumentProps} />,
      resultGrid: <AnalyzedResult {...AnalyzedresultProps} />,
    };
    return view[activeTab];
  }, [AddFileProps, AnalyzedresultProps, MyDocumentProps, activeTab, PdfEditorProps]);

  return (
    <div id="document-analyzer" className="flex-colunm-layout">
      <ToolbarAnalytics
        title={rowTitles}
        refresh={refetchs}
        disabledRefresh={activeTab === "addFiles"}
        additionalToolbarContent={AdditionalToolbarContent}
        showBackButton={showBackButton}
      />
       {showPsExport && (
        <PsExport
          isVisible={showPsExport}
          onClose={() => setShowPsExport(false)}
          onSave={() => {setShowPsExport(false)}}
          userId={exportData.userId} 
          fileName={exportData.fileName}
          processType={exportData.processType}
          isLoading={false}
        />
      )}
      {renderBody()}
    </div>
  );
};


