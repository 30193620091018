import { Link } from "react-router-dom";
import "../../../styles/ui-components/ActivityTilecard.scss";
import { ActivityTileCardProps } from "./type";

const ActivityTileCard = ({
  name,
  data,
  onItemClick,
}: ActivityTileCardProps) => {
  return (
    <div className="activitycard-wrapper">
      <h6>{name}</h6>
      {data.map((activity, index) => (
        <div
          key={index}
          className="content-list"
          onClick={(event) => onItemClick(event, activity?.id)}
        >
          <img src={activity.image} alt={activity.text} />
          <Link className="link_btn" to={""}>{activity.text}</Link>
        </div>
      ))}
    </div>
  );
};

export default ActivityTileCard;
