import { DataGridTypes } from "devextreme-react/cjs/data-grid";

export const minimumSearchLengthLabel = { 'aria-label': 'Minumum Search length' };
export const searchTimeoutLabel = { 'aria-label': 'Search Timeout' };
export const searchExpressionLabel = { 'aria-label': 'Search Expression' };
export const searchModeLabel = { 'aria-label': 'Search Mode' };
export const productLabel = { 'aria-label': 'Product' };
export const simpleProductLabel = { 'aria-label': 'Simple Product' };
export const containerSchedule = ["Daily", "Weekly", "Monthly","Ad-hoc"]
export const taskStatus = ["Active", "InActive"]
export const containerValue = ["1", "2", "3", "4"]
export const containerSize = ["Small", "Medium", "Large"]
export const frequencyList = ["daily", "weekly", "monthly"]
export const gridColumns = ['group','url'];
export const ownerLabel = { 'aria-label': 'Owner' };
export const adHocMessage = "It will not be scheduled using CRON it will be run directly"

export const allowedPageSizes: (DataGridTypes.PagerPageSize | number)[] = [
    25,
    50,
    100
  ];

  export const dropDownOptions = { width: "auto" };


  export const leadingZero = (num: number) => {
    return num < 10 ? `0${num}` : `${num}`;
  };
  
  export const formatDate = (dt: any) => {
    const date = new Date(dt);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; 
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${leadingZero(month)}/${leadingZero(day)}/${year}:  ${leadingZero(hours)}:${leadingZero(minutes)}`;
  };
  
  export const cellStartTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
    if(cell.data.starttime === "" || cell.data.timestamp === "") {
      return '---';
    }
    return formatDate(cell.data.starttime || cell.data.timestamp);
  };
  
  export const cellEndTime = (cell: DataGridTypes.ColumnCellTemplateData) => {
    if(cell.data.endTime === "" || cell.data.endDate === "") {
      return '---';
    }
    return formatDate(cell.data.endTime || cell.data.endDate);
  };
