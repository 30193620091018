import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import DataGrid, { Column, HeaderFilter } from 'devextreme-react/data-grid';
import {
  cellStartTime,
  cellEndTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const workflowsteps = props?.data?.data?.workflowsteps;

  return (
    <>
      <DataGrid
        dataSource={workflowsteps}
        allowColumnReordering={true}
        noDataText={"No Recent Details to display at this time"}
        showBorders={true}
        width="100%"
        allowColumnResizing={true}
      >
        <HeaderFilter visible />
        <Column caption="Step ID" dataField="stepNumber" dataType="string" />
        <Column caption="Step Name" dataField="name" dataType="string" />
        <Column caption="Description" dataField="description" dataType="string" />
        <Column caption="Status" dataField="status" dataType="string" cellRender={ContactStatus}/>
        <Column caption="Functions" dataField="functions" dataType="string" />
        <Column
          caption="Start Time"
          dataField="startTime"
          dataType="datetime"
          cellRender={cellStartTime}
        />
        <Column
          caption="End Time"
          dataField="endTime"
          dataType="datetime"
          cellRender={cellEndTime}
        />
      </DataGrid>
    </>
  );
};

export default MasterDetailView;
