import { useCallback, useState } from "react";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import { customDateRange } from "../../../config/constants";

export const AlertsAndExceptionsPage = () => {

  const [selectedDays, setSelectedDays] = useState<any>(null);

  const onCurrentValueChange = useCallback(
    (value: any) => {
      const [startTime, endTime, timePeriod ] = value?.value;
      if (timePeriod === customDateRange) {
        setSelectedDays({ startTime, endTime, timePeriod });
      }
      else {
        setSelectedDays({ timePeriod });
      }
    },
    [setSelectedDays]
  );
  
  return <AlertExceptionPage selectedDays={selectedDays} onCurrentValueChange={onCurrentValueChange} />;
};
