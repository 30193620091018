import { useMemo, useCallback } from "react";
import List, { ListTypes } from "devextreme-react/list";
import "../../../../styles/ui-components//UserMenuSection.scss";
import { useApp } from "../../../../contexts/app";
import type { UserMenuSectionProps } from "../types";

type MenuItem = {
  text: string;
  icon: string;
  onClick: () => void;
};

export const UserMenuSection = ({
  showAvatar,
  listRef,
}: UserMenuSectionProps) => {
  const { signOut } = useApp();

  const menuItems = useMemo<MenuItem[]>(
    () => [
      {
        text: "Logout",
        icon: "runner",
        onClick: signOut,
      },
    ],
    [signOut]
  );

  const listElementAttr = {
    class: "user-info-list",
  };

  const onItemClick = useCallback(
    ({ itemData }: ListTypes.ItemClickEvent<MenuItem>) => itemData?.onClick(),
    []
  );

  return (
    <List
      ref={listRef}
      elementAttr={listElementAttr}
      onItemClick={onItemClick}
      items={menuItems}
    />
  );
};
