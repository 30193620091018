import React from "react";
import { Item } from "devextreme-react/toolbar";
import { useNavigate } from "react-router";
import DropDownButton from "devextreme-react/drop-down-button";
import { dropDownOptions } from "../../../config/constants";
import "../../../styles/task/Infrastructure.scss";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { useCallback, useMemo } from "react";
import { InfraStructureDataGrid } from "./helper-component/InfraGridView";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { fetchInfraDetailsApi } from "../../../service/task/api-service";
import { usePage } from "../../../contexts/pageContext";
import { usePermission } from "../../../contexts/permissions";

export const InfraStructure = React.memo(() => {
  const { data: DateRange } = useFetchDateRange();
  const { getPageProperties } = usePage();
  
  const { filterValue } = getPageProperties() || {};

  const [refreshKey, setRefreshKey] = React.useState(0);

  const {currentPermission} = usePermission();

  const {
    state: { selectedDays },
    clientDropDownData,
    projectDropDownData,
    filterByClient,
    gridRef,
    handleDropDownPageIndex,
    handleDateRangeChange,
    handleColumnIndexChange
  } = useAppHook();

  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
  } = filterValue || {};

  const navigate = useNavigate();

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      event.preventDefault();
      navigate(`/client-profile?clientId=${clientId}`);
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      event.preventDefault();
      navigate(`/client-task?taskId=${taskId}`);
    },
    [navigate]
  );
  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      event.preventDefault();
      navigate(`/project-profile?projectId=${projectId}`, {state: {currentPermission}});
    },
    [navigate, currentPermission]
  );

  const additionalParams = useMemo(() => {
    const { startTime, endTime, timePeriod } = selectedDays || {};

    return {
      clientId: p_clientId,
      projectId:p_projectId,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [p_clientId, p_projectId, selectedDays]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchInfraDetailsApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const DataGridProps = useMemo(() => {
    return {
      handleClientLinkClick,
      handleTaskLinkClick,
      handleProjectLinkClick,
      gridRef,
      apiProps,
      handleColumnIndexChange
    };
  }, [handleClientLinkClick, handleTaskLinkClick,handleProjectLinkClick,handleColumnIndexChange, gridRef, apiProps]);

  const PageBodyComponent = useCallback(
    () => [
      {
        mode: "grid",
        Component: InfraStructureDataGrid,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps]
  );

  const AdditionalToolbarContent = useMemo(
    () => (
      <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
        <DropDownButton
          items={clientDropDownData || ["Loading..."]}
          stylingMode="text"
          text={p_clientName ? p_clientName : "All Clients"}
          dropDownOptions={dropDownOptions}
          useSelectMode
          onSelectionChanged={filterByClient}
          onItemClick={handleDropDownPageIndex}
        />
        <DateDropDown
          onDateChange={handleDateRangeChange}
          data={DateRange}
          handleDropDownPageIndex={handleDropDownPageIndex}
        />
      </Item>
    ),
    [
      DateRange,
      p_clientName,
      clientDropDownData,
      filterByClient,
      handleDateRangeChange,
      handleDropDownPageIndex,
    ]
  );

  const layoutProps = {
    headerText: "Infrastructure Services",
    refetch: () => setRefreshKey((prev) => prev + 1),
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: false,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
});