import React, { useState } from "react";
import { SelectBox, RadioGroup, Validator } from "devextreme-react";
import { FormPopup } from "../../ui-components/form/form-popup/FormPopup";
import { useFetchClient } from "../../../hooks/app/useApps";
import { ActionPopupProps } from "./type";
import { useMutation } from "react-query";
import "../../../styles/core-components/export.scss";
import {
  DATA_TIRE,
  ACTION_TYPE,
  simpleProductLabel,
} from "../../../config/constants";
import { RequiredRule } from "devextreme-react/cjs/data-grid";
import { SelectionChangedEvent } from "devextreme/ui/select_box";
import { useFetchProject } from "../../../hooks/task/useTask";
import { exportDataApi } from "../../../service/app/api-service";
import showNotification from "../../ui-components/alertPopup/AlertPopup";

const PsExport: React.FC<
  ActionPopupProps & { userId: string; fileName: string; processType: string }
> = ({
  isVisible,
  onClose,
  onSave,
  isLoading,
  userId,
  fileName,
  processType,
}) => {
  const [actionType, setActionType] = useState<string>("");
  const [selectedClient, setSelectedClient] = useState<{
    clientId: string;
    name: string;
    clientTag: string;
    clientShortName: string;
  } | null>(null);
  const [selectedProject, setSelectedProject] = useState<{
    projectName: string;
    projectId: string;
    projectType: string;
    projectTag: string;
    projectShortName: string;
  } | null>(null);
  const [selectedTier, setSelectedTier] = useState<string>("");

  const { data: clients } = useFetchClient();
  const { data: projects } = useFetchProject(
    selectedClient ? selectedClient?.clientId : ""
  ) as any;

  const { mutate } = useMutation(exportDataApi, {
    onSuccess: (data) => {
      const combinedData = {
        clientshortName: selectedClient ? selectedClient?.clientShortName : "",
        projectshortName: selectedProject
          ? selectedProject?.projectShortName
          : "",
        location: selectedTier,
        actiontype: actionType,
        userId: userId,
        filename: fileName,
        processtype: processType,
      };
      onSave(actionType, combinedData);
      showNotification({
        message: `Data for "${fileName}" has been successfully saved.`,
        type: "success",
      });
      onClose();
    },
    onError: (error) => {
      console.error("Error saving data:", error);
    },
  });

  const onClientOptionChanged = (e: SelectionChangedEvent) => {
    const selectedOption = e?.component?.option("selectedItem");
    if (selectedOption) {
      setSelectedClient(selectedOption);
      setSelectedProject(null);
    }
  };

  const onProjectOptionChanged = (e: SelectionChangedEvent) => {
    const selectedOption = e?.component?.option("selectedItem");
    if (selectedOption) {
      setSelectedProject(selectedOption);
    }
  };

  const onOptionTaskActive = (selectedTier: string) => {
    setSelectedTier(selectedTier);
  };

  const handleSave = () => {
    const combinedData = {
      clientshortName: selectedClient ? selectedClient?.clientShortName : "",
      projectshortName: selectedProject
        ? selectedProject?.projectShortName
        : "",
      location: selectedTier,
      actiontype: actionType,
      userId: userId,
      filename: fileName,
      processtype: processType,
    };

    mutate(combinedData);
  };

  return (
    <FormPopup
      title="Select Location"
      visible={isVisible}
      setVisible={onClose}
      onSave={handleSave}
      isLoading={isLoading}
    >
      <div className="file-name-display">
        <strong>File Ready for Export:</strong> {fileName}
      </div>
      <div className="ps-export-container">
        <div className="dropdown-container">
          <SelectBox
            dataSource={clients}
            displayExpr="name"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select Client..."
            onSelectionChanged={onClientOptionChanged}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>

          <SelectBox
            dataSource={projects}
            displayExpr="projectName"
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select Project..."
            onSelectionChanged={onProjectOptionChanged}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>

          <SelectBox
            dataSource={DATA_TIRE}
            searchEnabled={true}
            inputAttr={simpleProductLabel}
            placeholder="Select Tier..."
            value={selectedTier}
            onValueChanged={(e) => onOptionTaskActive(e.value)}
          >
            <Validator>
              <RequiredRule />
            </Validator>
          </SelectBox>
        </div>
        <div className="dx-radiogroup">
          <RadioGroup
            items={ACTION_TYPE}
            value={actionType}
            layout="vertical"
            onValueChanged={(e) => setActionType(e.value)}
          />
        </div>
      </div>
    </FormPopup>
  );
};

export default PsExport;
