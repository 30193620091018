import React, {
    createContext,
    useContext,
    useState,
    ReactNode,
  } from "react";
  import type { PageContextType } from "../types";
import { useLocation } from "react-router-dom";
  
  type AppProviderProps = {
    children: ReactNode;
  };
  
  const AuthContext = createContext<PageContextType>({} as PageContextType);
  
  function PagePropertiesProvider({ children }: AppProviderProps) {
    const [pageProperties, setPageProperties] = useState<any[]>([]);
    const location = useLocation();
    
    const updatePageProperties = (newPage: string, newFilterValue: object) => {
      setPageProperties((prevPages) => {
        // Check if the page already exists
        const existingPageIndex = prevPages.findIndex(
          (item) => item.page === newPage
        );
    
        if (existingPageIndex !== -1) {
          // If the page exists, update its filterValue
          const updatedPages = [...prevPages];
          updatedPages[existingPageIndex] = {
            ...updatedPages[existingPageIndex],
            filterValue: {
              ...updatedPages[existingPageIndex].filterValue,
              ...newFilterValue, // Merge new filter values
            },
          };
          return updatedPages;
        }
    
        // If the page does not exist, add a new entry
        return [
          ...prevPages,
          {
            page: newPage,
            filterValue: newFilterValue,
          },
        ];
      });
    };

   const getPageProperties = () => {
      const {pathname} = location;
      const path = pathname.split('/');
      return pageProperties.find((item) => item.page === path[1]);
    }

    const value = { pageProperties,updatePageProperties,getPageProperties };
  
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
  }
  
  const usePage = () => useContext(AuthContext);
  
  export { PagePropertiesProvider, usePage };
  