import React from "react";
import "../../../../styles/ui-components/TickerCardNew.scss";

export const TickerCard = ({
  title,
  icon,
  primaryValue,
  secondaryValue,
  tone,
  percentage,
  onClick,
}: {
  title?: string;
  primaryValue?: string;
  secondaryValue?: any[];
  icon?: string;
  tone?: "warning" | "info";
  percentage: number;
  onClick?: (e: any) => void;
  formatValue?: (value: number) => string;
}) => {
  const isImageUrl = (url: string) => {
    return /\.(jpeg|jpg|gif|png|svg)$/.test(url);
  };

  return (
    <div className="ticker" onClick={onClick}>
      <div
        className={`icon-wrapper ${
          tone || (percentage > 0 ? "positive" : "negative")
        } icon-${icon}`}
      >
        {icon && isImageUrl(icon) ? (
          <img src={icon} alt="icon" className="icon-image" />
        ) : (
          <i className={`dx-icon dx-icon-${icon}`} />
        )}
      </div>
      <div className="middle">
        <div className="totalalign">
          <div className="title">{title}</div>
          <div className="total">{primaryValue}</div>
        </div>
        <div className="secondary">
          {secondaryValue &&
            secondaryValue.map((item) => (
              <div key={item?.name} className="secondary-item">
                <span className="secondary-key">{item?.name}</span>
                <span className="secondary-value">{item?.value}</span>
              </div>
            ))}
        </div>
      </div>
      <div className={`percentage ${percentage > 0}`}>
        <div className="value"></div>
      </div>
    </div>
  );
};

