import { Item } from "devextreme-react/toolbar";
import { useCallback, useEffect, useMemo, useState } from "react";
import DropDownButton from "devextreme-react/drop-down-button";
import "../../styles/client/project-form.scss";
import { useFetchDateRange } from "../../hooks/app/useApps";
import { useHelperHooks } from "../../hooks/helper/utils";
import { useFetchAllProject, useProjects } from "../../hooks/client/useProject";
import { customDateRange, dropDownOptions } from "../../config/constants";
import { replaceQueryUrl } from "../../utlis/helper";
import { GTAButtonEventHandler } from "../../utlis/GAT-event-handler";
import DateDropDown from "../../components/ui-components/datebox/DateBox";
import DataGridMultiViewLayout from "../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { useAppHook } from "../../hooks/app/useApps";
import ProjectLineageGrid from "./clientInformation/ProjectLineageGrid";
import { usePage } from "../../contexts/pageContext";
import { fetchProjectApi } from "../../service/client/api-service";
import { usePermission } from "../../contexts/permissions";

export const ProjectsLinage = (props: any) => {
  const [selectedDays, setSelectedDays] = useState({});
  const [refreshKey, setRefreshKey] = useState(0);
  const { data: DateRange } = useFetchDateRange();
  const Period = useHelperHooks().getQueryParams("Period");

  const {
    state: { projetView },
  } = useProjects();
  const {
    state: {
      projectId,
      pageCount,
      pageSize,
      sortColumn,
      sortOrder,
      pageIndex,
      isNextPage,
    },
    clientDropDownData,
    projectDropDownData,
    filterByClient,
    filterByProject,
    gridRef,
    handleDropDownPageIndex,
    handleColumnIndexChange,
    handleDateRangeChange,
  } = useAppHook();

  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientId : p_clientId,
    t_clientName : p_clientName,
    projectId : p_projectId,
    t_projectName: p_projectName,
  } = filterValue || {};

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      clientId : p_clientId,
      projectId : p_projectId,
      timePeriod,
      ...(timePeriod === customDateRange && { startTime, endTime }),
    };
  }, [p_clientId, projectId, filterValue]);

  const apiProps = useMemo(
    () => ({
      apiFunction: fetchProjectApi,
      additionalParams: additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey]
  );

  const {currentPermission} = usePermission();

  const AdditionalToolbarContent = (
    <Item
      location="after"
      locateInMenu="auto"
      cssClass="toolbar-item-flex toolbar-item-label-buton"
    >
      <DropDownButton
        items={clientDropDownData || ["Loading..."]}
        stylingMode="text"
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        text={p_projectName ? p_projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={dropDownOptions}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
        onItemClick={handleDropDownPageIndex}
      />
      <DateDropDown
        onDateChange={handleDateRangeChange}
        data={DateRange}
        handleDropDownPageIndex={handleDropDownPageIndex}
      />
    </Item>
  );

  const DataGridProps = useMemo(() => {
    return {
      showToolbar: false,
      selectedDays,
      gridRef,
      height:"height-large",
      searchvisible: true,
      pageSize,
      pageCount,
      pageIndex,
      apiProps,
      handleColumnIndexChange,
      currentPermission,
    };
  }, [ selectedDays, gridRef, apiProps, currentPermission]);
  const PageBodyComponent = useMemo(
    () => [
      {
        mode: "grid",
        Component: ProjectLineageGrid,
        componentProps: DataGridProps,
      },
    ],
    [DataGridProps]
  );

  const layoutProps = {
    headerText: "Data Lineage",
    refetch : handleRefetch, 
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent,
    layoutView: projetView,
  };

  return <DataGridMultiViewLayout {...layoutProps} />;
};
