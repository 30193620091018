import { Handle, Position } from "@xyflow/react";
import "../../../../../../../styles/task/workflow.scss";

const TextUpdaterNode = ({ data, onClick }: any) => {
  const { step, label, description, additionalInfo } = data;
  return (
    <>
      <div onClick={onClick}>
        <Handle type="target" position={Position?.Top} />
        <div className="NodeWrapper">
          <div className="header_info">
            {step && (
              <center>
                <span className="badge-blue">{step}</span>
              </center>
            )}
            <span className="label">{label}</span>
            <span>Description: {description}</span>
          </div>
          <hr />
          <div>
            <span>{additionalInfo}</span>
          </div>
        </div>
        <Handle type="source" position={Position?.Bottom} id="a" />
      </div>
    </>
  );
};

export default TextUpdaterNode;
