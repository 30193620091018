import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer, SpecialZoomLevel } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";

const PdfView = (props: any) => {
  const { uploadData, pdfData, pdfPage, pageNavigationPluginInstance } = props;
  const { data, isLoading: isPdfLoading } = pdfData;
  const { content } = uploadData || {};
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      selectionModePlugin: {
        selectionMode: SelectionMode?.Hand,
      },
    },
  });

  if (isPdfLoading) {
    return <PsSkeleton count={1} height={930} />;
  }
  if (!content && !data) {
    return null;
  }
  return (
    <div className="height-large">
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Viewer
          fileUrl={content || data}
          plugins={[
            defaultLayoutPluginInstance,
            pageNavigationPluginInstance
          ]}
          initialPage={pdfPage - 1}
          defaultScale={SpecialZoomLevel?.PageFit}
        />
      </Worker>
    </div>
  );
};

export default PdfView;
