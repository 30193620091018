import { useCallback } from "react";
import { ToolbarAnalytics } from "../toolbar-analytics";
import { DataGridMultiViewLayoutProps } from "./types";
import TableSkeleton from "./helper-component/TableSkeleton";
import "../../../styles/ui-components/DataGridMultiViewLayout.scss";
import CardSkeleton from "./helper-component/CardSkeleton";

const ViewMode: any = {
  grid: "gridView",
  board: "boardView",
  opex: "opexView",
  management: "managmentView",
  portfolio: "portfolioView",
};

 
const DataGridMultiViewLayout = (props: DataGridMultiViewLayoutProps) => {
  const {
    headerText,
    refetch,
    additionalToolbarContent,
    additionalToolbarBeforeContent,
    pageBodyComponent,
    layoutView = "gridView",
    isLoading,
    showToolbar = true,
    showBackButton = false,
  } = props;

  const renderPageBodyComponent = useCallback(() => {
    return pageBodyComponent?.map((body: any, index: number) => {
      const { mode, Component, componentProps } = body || {};
      if (layoutView?.value === ViewMode[mode] || layoutView === ViewMode[mode])
        return <Component {...componentProps} key={mode + index} />;
    });
  }, [pageBodyComponent, layoutView]);

  const renderLoader = () => {
    if (layoutView === "gridView") {
      return <TableSkeleton />;
    }
    return <CardSkeleton />;
  };

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics
        title={headerText}
        showToolbar={showToolbar}
        additionalToolbarContent={additionalToolbarContent}
        additionalToolbarBeforeContent={additionalToolbarBeforeContent}
        refresh={refetch}
        showBackButton={showBackButton}
      />
      {isLoading ? renderLoader() : renderPageBodyComponent()}
    </div>
  );
};

export default DataGridMultiViewLayout;
