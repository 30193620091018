import { useCallback, useState } from "react";
import { FormPopup } from "../../../../../components/ui-components/form/form-popup/FormPopup";
import  TaskUrlPanelDetails  from "./taskUrlPanelDetails";
import { TaskData } from "../../types";
import { useCreateTask } from "../../../../../hooks/task/useTask";
import { useApp } from "../../../../../contexts/app";
import { useNavigate, useLocation } from "react-router-dom";

const TaskEditPopup = (props: any) => {
  const { editPopupVisible,
    changeEditPopupVisibility,
    selectedTask,
  } = props;
  
  const [formData, setData] = useState<TaskData>();
  const { row } = selectedTask || {};
  const { data } = row || {};

  const {user} = useApp();
  const {userId} = user || {};

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility()
  }
    , [changeEditPopupVisibility]);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const refreshpage = () => navigate(pathname);
  const { mutate, isLoading } = useCreateTask(() => {
      closePanel();
      refreshpage();
    }, isEdit, userId);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<TaskData | undefined>) => {
      setData(data);
    },
    []
  );
  if(typeof window === 'undefined' ) return null;
  return (
    <>
      <FormPopup
        title="Edit TaskAutomation Details"
        visible={editPopupVisible}
        setVisible={changeEditPopupVisibility}
        onSave={handleFormSubmit}
        isLoading={isLoading}
      >
        <TaskUrlPanelDetails
          subjectField={!!data}
          data={data}
          editing={true}
          onDataChanged={onDataChanged}
        />
      </FormPopup>
    </>
  )
}
export default TaskEditPopup;