import "../../../styles/core-components/under-construction.scss";

export const UnderConstruction = () => {
  return (
    <div className="contactionPage">
      <div>
        <p>This screen is currently under construction. Check back later!</p>
      </div>
    </div>
  );
};
