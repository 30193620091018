import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { useFetchProjectInfo } from "../../../../../../hooks/client/useProject";
import PsAccordion from "../../../../../../components/ui-components/accordion/accordion";
import { CustomItem, CustomTitle } from "./ProjectComponent";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const projectId = props.data?.data?.projectId;
  const projectName = props.data?.data?.projectName;
  const clientName = props.data?.data?.clientName;
  const activityType = props.data?.data?.projectType;
  const projectShortName = props.data?.data?.projectShortName;
  const params = {
    projectId,
    projectName,
    clientName,
  };
  const {
    data: detailData,
    isLoading,
  }: { data: any; isLoading: any } = useFetchProjectInfo(
    params,
    activityType
  );
  const detaildataView = detailData?.projects?.[0]?.resources || [];
  if (isLoading) {
    return <span>Loading...</span>;
  }
  return (
    <>
      <div className="externalPsAccordion">
        <PsAccordion
          dataSource={detaildataView}
          Component={(e: any) => CustomItem(e, activityType, projectName, params, projectShortName)}
          CustomTitle={CustomTitle}
        />
      </div>
    </>
  );
};

export default MasterDetailView;
