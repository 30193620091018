import HtmlEditor from "devextreme-react/html-editor";
import { getPreviewRowStyle } from "../../../../../../utlis/helper";

export const ExtractView = (props: any) => {
  const { data, selectedConfidence } = props;

  const capitalizeHeading = (text: string): string =>
    text?.replace(/-/g, " ")?.replace(/^./, (char) => char?.toUpperCase());

  const generateTable = (rows: any[]): string => {
    if (!Array?.isArray(rows) || rows?.length === 0) return "";

    const excludedFields = ["quality_score_of_row", "confidence_quality_score"];
    const headers = Object?.keys(rows?.[0] || {})?.filter(header => !excludedFields?.includes(header));

    return `
      <table style="width: 100%; border-collapse: collapse; margin-top: 30px; font-family: Arial, sans-serif;">
        <tr style="background-color: #f5f5f5;">
          ${headers?.map(header => `<th style="border: 1px solid #ddd; padding: 10px; text-align: left;">${header?.replace(/-/g, " ")}</th>`)?.join("")}
        </tr>
        ${rows?.map(row => {
      const confidenceValue = row["confidence"];
      const rowStyle = confidenceValue ? getPreviewRowStyle(confidenceValue, selectedConfidence) : {};
      const backgroundColor = confidenceValue ? rowStyle?.backgroundColor : "transparent";

      return `
          <tr style="background-color: ${backgroundColor};">
            ${headers?.map(header => `<td style="border: 1px solid #ddd; padding: 8px;">${row[header] ?? ""}</td>`)?.join("")}
          </tr>`;
    })?.join("")}
      </table>
    `;
  };

  const htmlConversion = (data: any): string => {
    if (!data || Object?.keys(data)?.length === 0) {
      return `<h5 style="font-family: Arial, sans-serif; text-align: center">No data to show</h5>`;
    }

    let htmlContent = "";

    const documentDetails = Object?.entries(data)?.filter(([_, value]) => typeof value !== "object");
    if (documentDetails?.length > 0) {
      htmlContent += `
        <table style="width: 100%; border-collapse: collapse; padding: 30px; font-family: Arial, sans-serif;">
          ${documentDetails?.map(([key, value]) => `
            <tr>
              <td style="border: 1px solid #ddd; padding: 10px;">${key?.replace(/-/g, " ")}</td>
              <td style="border: 1px solid #ddd; padding: 10px;">${value}</td>
            </tr>`)?.join("")}
        </table>
      `;
    }

    for (const key in data) {
      if (typeof data[key] === "object") {
        if (!Array?.isArray(data[key]) && Object?.keys(data[key])?.every(subKey => typeof data[key][subKey] !== "object")) {
          htmlContent += `<h6 style="font-family: Arial, sans-serif;">${capitalizeHeading(key)}</h6>`;
          htmlContent += `
            <table style="width: 100%; border-collapse: collapse; margin-top: 50px; font-family: Arial, sans-serif;">
              ${Object?.entries(data[key])?.map(([subKey, subValue]) => `
                <tr>
                  <td style="border: 1px solid #ddd; padding: 10px;">${subKey?.replace(/-/g, " ")}</td>
                  <td style="border: 1px solid #ddd; padding: 10px;">${subValue}</td>
                </tr>`)?.join("")}
            </table>
          `;
        } else if (!Array?.isArray(data[key])) {
          htmlContent += `<h5 style='margin-top: 25px; font-family: Arial, sans-serif;'>${capitalizeHeading(key)}</h5>`;
          for (const subKey in data[key]) {
            if (Array?.isArray(data[key][subKey])) {
              htmlContent += `<h6 style='margin-top: 50px; font-family: Arial, sans-serif;'>${capitalizeHeading(subKey)}</h6>`;
              htmlContent += generateTable(data[key][subKey]);
            }
          }
        }
      }
    }

    return htmlContent;
  };

  const htmlData = htmlConversion(data || {});

  return (
    <HtmlEditor
      height={"70vh"}
      width={"100%"}
      value={htmlData}
      readOnly={true}
    />
  );
};
