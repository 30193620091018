
export interface Estimator {

}

export interface EstimatorState {
  digitalServiceResource: any[],
  operationResource: any[],
  selectedEstimator: any[],
  infraResource: any[],
  initalDataEstimator: any[],
  digitalCost: number,
  operationCost: number,
  infraServiceCost: number,
  popupVisible: boolean,
  selectedEstimatorData: any,
}

export interface EstimatorProjectData {
  technicalResource: [],
  operationalResource: [],
  infraResources: [],
}

export const newEstimatorProjectData: EstimatorProjectData = {
  technicalResource: [],
  operationalResource: [],
  infraResources: [],
};

export interface ProjectData {
  projectId: string,
  clientId: string,
  projectDescription: string,
  status: string,
  projectName: string,
  projectType: string,
  clientName: string,
  startDate: string,
  endDate: string,
  technicalResource:[],
  operationalResource:[],
  infraResources:[],
  estimatorId:string,
  estimatorName:string,
}