import React, { useMemo } from "react";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { Item } from "devextreme-react/toolbar";
import NewIcon from "../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../components/ui-components/icon-with-text/IconText";
import WorkflowGrid from "./helper-component/grid-component/workflowGrid";
import { useWorkflow } from "../../../hooks/task/useWorkflow";
import { WorkflowForm } from "./helper-component/add-workflow/workflowForm";
import '../../../styles/task/workflow.scss';
import { useHelperHooks } from "../../../hooks/helper/utils";
import { DropDownButton } from "devextreme-react";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { dropDownOptions } from "../../../config/constants";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import WorkflowEditForm from "./helper-component/edit-workflow/workflowEditForm";
import DeleteWorkflow from "./helper-component/delete-workflow/workflowDeletePopup";
import { usePage } from "../../../contexts/pageContext";
import { fetchWorkflowApi } from "../../../service/task/api-service";
import { usePermission } from "../../../contexts/permissions";

export const WorkFlow = () => {
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
  } = filterValue || {};
  const [refreshKey, setRefreshKey] = React.useState(0);

  const {
    state: {
      popupVisible,
      openPopup,
      selectedWorkflowData,
      editPopupVisible,
      showCancelPopup,
      selectedValues,
      deletePopupVisible,
    },
    onAddWorkflowOpen,
    setOpenPopup,
    setSelectedWorkflowData,
    onEditWorkflowOpen,
    setShowCancelPopup,
    setSelectedValues,
    onDeletePopupClose,
    onDeletePopupOpen,
  } = useWorkflow();

  const {
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
    handleDateRangeChange,
    handleDropDownPageIndex,
    handleColumnIndexChange
  } = useAppHook();

  const { data: DateRange } = useFetchDateRange();

  const {currentPermission} = usePermission();
  
  const closeShowModal = () => {
    onAddWorkflowOpen(false);
  };

  const closeEditModal = () => {
    onEditWorkflowOpen(false);
  };

  const additionalParams = useMemo(() => {
    const { formattedStartTime, formattedEndTime, timePeriod } = filterValue || {};
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      clientId: p_clientId,
      projectId: p_projectId,
      timePeriod,
      ...(timePeriod === "CustomDateRange" && { startTime, endTime }),
    };
  }, [p_clientId, p_projectId, filterValue]);

  const apiProps = useMemo(() => ({
    apiFunction: fetchWorkflowApi,
    additionalParams: additionalParams,
    refreshKey,
  }), [additionalParams, refreshKey]);

  const DataGridProps = useMemo(() => {
    return {
      gridRef,
      height: "height-large",
      openPopup,
      setOpenPopup,
      selectedWorkflowData,
      setSelectedWorkflowData,
      onEditWorkflowOpen,
      onDeletePopupOpen,
      apiProps,
      handleColumnIndexChange,
      currentPermission,
    };
  }, [
    gridRef,
    openPopup,
    setOpenPopup,
    selectedWorkflowData,
    setSelectedWorkflowData,
    onEditWorkflowOpen,
    onDeletePopupOpen,
    apiProps,
    handleColumnIndexChange,
    currentPermission,
  ]);

  const deleteProps = useMemo(() => {
    return {
      onDeletePopupClose,
      selectedWorkflowData,
      deletePopupVisible
    }
  }, [
    onDeletePopupClose,
    selectedWorkflowData,
    deletePopupVisible
  ]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData}
        width={"auto"}
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        text={p_projectName ? p_projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
        onItemClick={handleDropDownPageIndex}
      />
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} handleDropDownPageIndex={handleDropDownPageIndex} />
      { currentPermission?.canCreate &&
      <PSIconText
        src={NewIcon}
        text="Add Workflow"
        alt="Add Workflow"
        onClick={() => onAddWorkflowOpen(true)}
      />}
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: WorkflowGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    headerText: "Workflow",
    refetch: () => setRefreshKey((prev) => prev + 1),
    additionalToolbarContent: AdditionalToolbarContent,
    isLoading: false,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      {popupVisible && (
        <WorkflowForm
          onOpen={popupVisible}
          onClose={closeShowModal}
          onAddWorkflowOpen={onAddWorkflowOpen}
          showCancelPopup={showCancelPopup}
          setShowCancelPopup={setShowCancelPopup}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
        />
      )}
      {editPopupVisible && (
        <WorkflowEditForm
          onEditOpen={editPopupVisible}
          onEditClose={closeEditModal}
          onEditWorkflowOpen={onEditWorkflowOpen}
          showCancelPopup={showCancelPopup}
          setShowCancelPopup={setShowCancelPopup}
          selectedValues={selectedValues}
          setSelectedValues={setSelectedValues}
          selectedWorkflowData={selectedWorkflowData}
        />
      )}
      <DeleteWorkflow {...deleteProps} />
    </div>
  );
};
