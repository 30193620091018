import { useEffect } from "react";
import PageError from "./pages/static-info/PageError";

function logErrorToLocalStorage(errorDetails: any) {
  localStorage.setItem("globalError", JSON.stringify(errorDetails));
}

function GlobalErrorHandler() {
  useEffect(() => {
    const handleRuntimeError = (event: any) => {
      const errorDetails = {
        type: "runtime",
        message: event.message,
        source: event.filename,
        line: event.lineno,
        column: event.colno,
        stack: event.error ? event.error.stack : null,
        time: new Date().toISOString(),
      };
      logErrorToLocalStorage(errorDetails);
      return <PageError />;
    };

    // Add error listener for unhandled promise rejections
    const handlePromiseRejection = (event: any) => {
      const errorDetails = {
        type: "promise",
        message: event.reason?.message || "Unhandled promise rejection",
        stack: event.reason?.stack || null,
        time: new Date().toISOString(),
      };
      logErrorToLocalStorage(errorDetails);
      return <PageError />;
    };

    window.addEventListener("error", handleRuntimeError);
    window.addEventListener("unhandledrejection", handlePromiseRejection);

    // Cleanup listeners on component unmount
    return () => {
      window.removeEventListener("error", handleRuntimeError);
      window.removeEventListener("unhandledrejection", handlePromiseRejection);
    };
  }, []);

  return null; // This component doesn’t render anything
}

export default GlobalErrorHandler;
