
import { useNavigate } from "react-router";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import {
  cellLastExecutionTime,
  editCellStatusRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../config/task/commonConfig";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { useMemo } from "react";
import { usePage } from "../../../../contexts/pageContext";


const TaskDataGridPage = (props: any) => {
  const {
    data,
    onDeletePopupOpen,
    onEditTaskClick,
    onClonePopupOpen,
    renderStartTask,
    handlePageIndexChange,
    handleColumnIndexChange,
    apiProps,
    currentPermission,
  } = props;

  const navigate = useNavigate();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName : clientNameColumn,
    projectName : projectNameColumn,
    taskName : taskNameColumn,
    workflowName: workFlowNameColumn,
    projectType: projectTypeColumn,
    taskScheduling:  taskSchedulingColumn,
    status : statusColumn,
    maxContainerGroup : maxContainerGroupColumn,
    containersPerGroup : containersPerGroupColumn,
    containerSize: containerSizeColumn,
    latest_execution : latestExecutionColumn,

  } = filterValue || {};

  const handleClientLinkClick = (event: any, clientId: string) => {
    event.preventDefault();
    navigate(`/client-profile?clientId=${clientId}`);
  };

  const handleProjectLinkClick = (event: any, projectId: string) => {
    navigate(`/project-profile?projectId=${projectId}`, {state: {currentPermission},});
    event.preventDefault();
  };

  const handleTaskLinkClick = (event: any, taskId: string) => {
    event.preventDefault();
    navigate(`/client-task?taskId=${taskId}`);
  };

  const columns = useMemo(
    () => [
    {
      dataField:"clientName",
      caption:"Client",
      filterValues: clientNameColumn,
      cellRender:(data : any) => {
        const clientName = data?.data?.clientName;
        const clientId = data?.data?.clientId;
        return (
          <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
            {clientName}
          </a>
        );
      }},
      {
        dataField:"projectName",
          caption:"Project Name",
          filterValues: projectNameColumn,
          cellRender:(data : any) => {
            const projectName = data?.data?.projectName;
            const projectId = data?.data?.projectId;
            return (
              <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
                {projectName}
              </a>
            );
          }},
       {
        dataField:"taskName",
        caption:"Task Name",
        filterValues: taskNameColumn,
        cellRender:(data : any) => {
            const taskDescription = data?.data?.taskName;
            const taskId = data?.data?.taskId;
            return (
              <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                {taskDescription}
              </a>
            );
          }},
          {
            dataField:"workflowName",
            caption:"Workflow Name",
            filterValues: workFlowNameColumn,
          },
          {
            dataField:"projectType" ,
            caption:"Activity Type",
            filterValues: projectTypeColumn,
          },
          {
            dataField:"taskScheduling",
            caption:"Frequency",
            filterValues: taskSchedulingColumn,
          },
          {
          dataField:"status",
          caption:"Status",
          cellRender:ContactStatus,
          editCellRender:editCellStatusRender,
          filterValues: statusColumn
          },
          {
            dataField:"maxContainerGroup",
            caption:"Max Container Group",
            alignment:"left",
            filterValues: maxContainerGroupColumn,
          },
          {
            dataField:"containersPerGroup",
            caption:"Containers Per Group",
            alignment:"left",
            filterValues: containersPerGroupColumn,
          },
          {
            dataField:"containerSize",
             caption:"Container Size",
             filterValues:containerSizeColumn,
          },
          {
            dataField:"latest_execution",
            caption:"Last Execution",
            cellRender:cellLastExecutionTime,
            filterValues: latestExecutionColumn,
          },
          {
            caption:"",
            type:"buttons",
            width:60,
            cellRender:renderStartTask
          }
      
  ], [clientNameColumn, projectNameColumn, latestExecutionColumn, containerSizeColumn, containersPerGroupColumn,
    maxContainerGroupColumn, statusColumn, taskSchedulingColumn, projectTypeColumn, workFlowNameColumn, taskNameColumn,
    renderStartTask])

  const pageProps = useMemo(
    () => ({
    nodataText: "No tasks to display at this time",
    heightClass: "height-large",
    id:"taskGrid",
  }), []);

  const gridProps =useMemo( 
    () => ({
    dataSource: DataSource,
    columns,
    keyExpr: "taskId",
    allowedPageSizes,
    searchPanelVisible: true,
    headerFilterVisible: true,
    pagingEnabled: true,
    pagerEnabled: true,
    onRowEdit: onEditTaskClick,
    onRowDelete: onDeletePopupOpen,
    onRowClone:  onClonePopupOpen,
    handleColumnIndexChange,
  }), [columns, handleColumnIndexChange ]);

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
    </div>
  );
};

export default TaskDataGridPage;
