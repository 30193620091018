import "../../styles/core-components/staticinfo.scss";
import logos from "../../assets/images/shore-logo-1.png";

const RoleInfo = () => {
    return (
      <div id="static-info">
        <div className="logo-wrapper">
          <img
            src={logos}
            alt="Logo"
            className="polarisLogo"
            style={{ width: "45px", height: "45px" }}
          />
          <span>Polaris</span>
        </div>
        <h1>Permission Required</h1>
        <ul>
        <li>
          You do not have the necessary permissions to access. Please contact the site administrator for assistance
        </li>
          </ul>
      </div>
    );
  };
  
  export default RoleInfo;
  