import {
  Chart,
  Series,
  CommonSeriesSettings,
  Legend,
  ArgumentAxis,
  ValueAxis,
  Tooltip
} from "devextreme-react/chart";
import { useFetchDocumentSummary } from "../../../../../hooks/datastudio/useDocumentCatalog";

const DocumentChart = (props: any) => {
  const { selectedDays, client } = props;
  const { data: summaryData } = useFetchDocumentSummary(selectedDays);
  const clientFiltered = summaryData?.filter((item: any) =>
    (client === 'All Clients' || item?.clientName === client)
  );
  const totalDocument = clientFiltered

  const chartData = totalDocument?.map((client: any) => ({
    clientName: client?.clientName,
    Pending: client?.documentProcess?.find((item: any) => item?.name === "Pending")?.value,
    InProgress: client?.documentProcess?.find((item: any) => item?.name === "In Progress")?.value,
    Completed: client?.documentProcess?.find((item: any) => item?.name === "Completed")?.value,
    ReadyForReview: client?.documentProcess?.find((item: any) => item?.name === "Ready for Review")?.value,
    Failed: client?.documentProcess?.find((item: any) => item?.name === "Failed")?.value
  }));

  return (
    <Chart
      id="chart"
      palette="Soft"
      title="Document Summary"
      dataSource={chartData}
    >
      <CommonSeriesSettings
        argumentField="clientName"
        type="bar"
        ignoreEmptyPoints={true}
      />
      <Series valueField="Pending" name="Pending" />
      <Series valueField="InProgress" name="In Progress" />
      <Series valueField="Completed" name="Completed" />
      <Series valueField="ReadyForReview" name="Ready for Review" />
      <Series valueField="Failed" name="Failed" />
      <ArgumentAxis title="Client" />
      {/* <ValueAxis title="Documents" /> */}
      <ValueAxis
        title="Documents"
        tickInterval={2}
        label={{ format: { type: 'fixedPoint', precision: 0 } }}
      />
      <Tooltip
        enabled={true}
        customizeTooltip={(info: any) => {
          return {
            text: `${info.argumentText}\n${info.seriesName}: ${info.valueText}`
          };
        }}
      />
      <Legend verticalAlignment="bottom" horizontalAlignment="center" />
    </Chart>
  );
};

export default DocumentChart;
