import { TreeView } from "devextreme-react";
import "../../../styles/task/clientTaskTab.scss";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { transformTaskStorageData, useFetchSelectedTaskFileData, useFetchTaskDataList, useTask } from "../../../hooks/task/useTask";
import FileViewer from "../../ui-components/fileViewer/FileViewer";
import { useMemo } from "react";
import PsSkeleton from "../../ui-components/skeleton/PsSkeleton";
import { GridComponent } from "../../core-component/dataLakeGrid/GridComponent";
import { location } from "../../../config/client/commonConfig";

export const TaskDataTab = (props: any) => {
  const { selectedDays, taskData } = props;
  const taskId = useHelperHooks().getQueryParams("taskId") ?? "";
  const { data: taskProfileData, isFetching } = useFetchTaskDataList(taskId);
  const task = taskData?.length > 0 ? taskData[0] : {};
  const taskName = task?.taskName
  const projectShortName = task?.projectShortName;

  const finalData = useMemo(() => {
    return taskProfileData ? transformTaskStorageData(taskProfileData, taskName) : [];
  }, [taskProfileData, taskName]);
  
  const {
    state: { fileViewerPopupVisible, selectedFileData},
    onViewScrapingButtonClick,
  } = useTask();

  const { data: SelectedTaskFileData, isLoading} = useFetchSelectedTaskFileData(selectedFileData);

  const renderItem = (item: any) => {
    const gridProps = { 
      id: {"taskId": taskId},
      location: location[item?.id],
      projectShortName: projectShortName,
      onViewScrapingButtonClick 
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item.data ? (
          <GridComponent {...gridProps} /> 
        ) : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
        {isFetching ? (
        <PsSkeleton height={30} count={6} />
      ) : (
        <TreeView
          id="simple-treeview"
          items={finalData}
          itemRender={renderItem}
          />
        )}
        <FileViewer
          visible={fileViewerPopupVisible}
          onHiding={onViewScrapingButtonClick}
          data={selectedFileData?.fileExtension ==='pdf' ? SelectedTaskFileData?.data : JSON.stringify(SelectedTaskFileData?.data)}
          title={title}
          isLoading={isLoading}
          extensionType={selectedFileData?.fileExtension}
          />
    </>
  );
};

export default TaskDataTab;
