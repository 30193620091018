import ManagementDetailCard from "../managementDataCard/managementDeatil";
import ExpensesChartCard from "../chartdata/chartdata";
import YtdMtdCostsCard from "../YtdMtdData/ytdmtd";

const ProjectDetails = (props:any) => {
  const { selectedDays } = props;
  return (
    <div className="details-container">
      <ManagementDetailCard />
      <YtdMtdCostsCard />
      <ExpensesChartCard selectedDays={selectedDays}/>
    </div>
  );
};

export default ProjectDetails;
