import RecentProject from "../../../../../../assets/images/icons/icon-project-1.png";
import RecentActivity from "../../../../../../assets/images/icons/icon-activity-1.png";
import { useNavigate } from "react-router-dom";
import "../../../../../../styles/client/mangementStyles.scss";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { useFetchRecentProjects } from "../../../../../../hooks/client/useProject";
import ActivityTileCard from "../../../../../../components/ui-components/activitPanelcard/ActivityTilecard";
import { useFetchRecentActivity } from "../../../../../../hooks/app/useApps";
import { GTAButtonEventHandler } from "../../../../../../utlis/GAT-event-handler";

const SideTileView = (props: any) => {
  const { selectedDays } = props;
  const { data, isLoading } = useFetchRecentProjects();
  const recentActiviy = useFetchRecentActivity(selectedDays);
  const navigate = useNavigate();

  const projectData = data
    ? data.map((project: any) => ({
        image: RecentProject,
        text: project.projectName,
        id: project?.projectId,
      }))
    : [];

  const activityData = recentActiviy.data
    ? recentActiviy?.data.slice(0, 5).map((activity: any) => ({
        image: RecentActivity,
        text: activity.taskName,
        id: activity?.taskId,
      }))
    : [];

  const handleRecentProjectLinkClick = (event: any, projectId: string) => {
    navigate(`/project-profile?projectId=${projectId}`);
    event.preventDefault();
    const eventName = `recent_projects: ${projectId}_managementview`;
    GTAButtonEventHandler(eventName);
  };

  const handleRecentActivityClick = (event: any, taskId: string) => {
    navigate(`/activity-dashboard?taskId=${taskId}`);
    event.preventDefault();
    const eventName = `recent_activity: ${taskId}_managementview`;
    GTAButtonEventHandler(eventName);
  };

  const renderCard = (
    name: string,
    data: any,
    onClick: (e: any, id: string) => void
  ) => {
    if (isLoading) {
      return <PsSkeleton count={1} height={250} />;
    }
    return <ActivityTileCard name={name} data={data} onItemClick={onClick} />;
  };

  return (
    <div className="management-column-card">
      {renderCard("Recent Projects", projectData, handleRecentProjectLinkClick)}
      {renderCard("Recent Activity", activityData, handleRecentActivityClick)}
    </div>
  );
};

export default SideTileView;
