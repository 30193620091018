import { ProjectMap } from "../../../../../fixture/project";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateProject,
  useFetchProjectType,
  useProjects,
} from "../../../../../hooks/client/useProject";
import { RightSidePannel } from "../../../../../components/ui-components/right-side-panel/right-side-panel";
import { ToolbarAnalytics } from "../../../../../components/ui-components/toolbar-analytics";
import { newProjectFormData, ProjectData } from "../../types";
import { useNavigate } from "react-router-dom";
import PsImageWithTextCard from "../../../../../components/ui-components/PsImageWithTextCard/ItemCard";
import "../../../../../styles/client/Project.scss";
import { GTAButtonEventHandler } from "../../../../../utlis/GAT-event-handler";
import ProjectNewForm from "../project-form/ProjectNewForm";

export const ProjectsList = () => {
  const {
    state: { showProjectModal },
    onToggleProjectClick,
  } = useProjects();
  const { mutate, isLoading, isSuccess, responseData } = useCreateProject();
  const navigate = useNavigate();
  const func_type = "function";
  const { data: projectTypeData } = useFetchProjectType(func_type);
  const getProjectTypeList = projectTypeData?.functionType;
  const [newProjectData, setNewProjectData] = useState<ProjectData | any>({
    ...newProjectFormData,
  });

  const responseProjectId = useMemo(() => {
    return responseData?.data?.[0]?.projectId || "";
  }, [responseData]);

  useEffect(() => {
    setNewProjectData({ ...newProjectFormData });
  }, [showProjectModal]);

  const updateField = useCallback(
    (field: string) => (value: any) => {
      setNewProjectData({ ...newProjectData, ...{ [field]: value } });
    },
    [newProjectData]
  );

  const [selectedStartDate, setSelectedStartDate] = useState<Date>(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);

  const onOptionChanged = useCallback(
    (e: {
      clientId: string;
      name: string;
      clientTag: string;
      clientShortName: string;
    }) => {
      if (e) {
        setNewProjectData({
          ...newProjectData,
          ...{
            clientId: e.clientId,
            clientName: e.name,
            clientTag: e.clientTag,
            clientShortName: e.clientShortName,
          },
        });
        const eventName = `project_option_changed: ${e.name}`;
        GTAButtonEventHandler(eventName);
      }
    },
    [newProjectData]
  );

  const onOptionTaskActive = useCallback(
    (e: string) => {
      if (e) {
        setNewProjectData({ ...newProjectData, ...{ status: e } });
        const eventName = `project_task_active: ${e}`;
        GTAButtonEventHandler(eventName);
      }
    },
    [newProjectData]
  );

  const handleStartDateChange = useCallback(
    (value: any) => {
      setSelectedStartDate(value);
      setNewProjectData({ ...newProjectData, ...{ startDate: value } });
      const eventName = `p  roject_start_date: ${value}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleEndDateChange = useCallback(
    (value: any) => {
      setSelectedEndDate(value);
      setNewProjectData({ ...newProjectData, ...{ endDate: value } });
      const eventName = `project_end_date: ${value}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleProjectTypeChange = useCallback(
    (e: any) => {
      const { projectType, projectModule } = e;
      setNewProjectData({
        ...newProjectData,
        ...{ projectType: projectType, projectModule: projectModule },
      });
      const eventName = `project_type: ${projectType}`;
      GTAButtonEventHandler(eventName);
    },
    [newProjectData]
  );

  const handleEstimatorTypeChange = useCallback(
    (value: any) => {
      setNewProjectData({
        ...newProjectData,
        ...{ estimatorName: value?.name, estimatorId: value?.estimaterId },
      });
    },
    [newProjectData]
  );

  const handleFormSubmit = useCallback(() => {
    if (isSuccess) {
      navigate(`/project-profile?projectId=${responseProjectId}`);
    } else {
      mutate(newProjectData);
    }
    const eventName = `project_form_submit: ${responseProjectId}`;
    GTAButtonEventHandler(eventName);
  }, [isSuccess, mutate, navigate, newProjectData, responseProjectId]);

  const FormProps = useMemo(() => {
    return {
      isOpened: showProjectModal,
      changePanelOpened: onToggleProjectClick,
      handleFormSubmit,
      responseData,
      isLoading,
      newProjectData,
      updateField,
      onOptionChanged,
      onOptionTaskActive,
      handleStartDateChange,
      handleEndDateChange,
      handleProjectTypeChange,
      handleEstimatorTypeChange,
      selectedStartDate,
      selectedEndDate,
      getProjectTypeList,
    };
  }, [
    showProjectModal,
    onToggleProjectClick,
    handleFormSubmit,
    responseData,
    isLoading,
    newProjectData,
    updateField,
    onOptionChanged,
    onOptionTaskActive,
    handleStartDateChange,
    handleEndDateChange,
    handleProjectTypeChange,
    handleEstimatorTypeChange,
    selectedStartDate,
    selectedEndDate,
    getProjectTypeList,
  ]);

  const ItemProps = useMemo(() => {
    return {
      cardItems: ProjectMap,
      onCardClick: onToggleProjectClick,
    };
  }, [onToggleProjectClick]);

  const renderPannel = useCallback(() => {
    if (!showProjectModal) return null;
    return (
      <RightSidePannel
        isOpened={showProjectModal}
        changePanelOpened={onToggleProjectClick}
        title={"Project"}
        onSave={handleFormSubmit}
        isLoading={isLoading}
        isSaveDisabled={isSuccess}
      >
        <ProjectNewForm {...FormProps} />
      </RightSidePannel>
    );
  }, [
    FormProps,
    handleFormSubmit,
    isLoading,
    isSuccess,
    onToggleProjectClick,
    showProjectModal,
  ]);

  return (
    <>
      <ToolbarAnalytics title={"Add Projects"} showBackButton />
      <PsImageWithTextCard {...ItemProps} />
      {renderPannel()}
    </>
  );
};
export default ProjectsList;
