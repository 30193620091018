import Card from "../../../ui-components/card/card"; // Adjust path as needed
import { useFetchSpecificProjectProfile } from "../../../../hooks/client/useProjectProfile";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import { convertToUSD, truncateString } from "../../../../utlis/helper";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";

export const ManagementDetailCard = () => {
  const projectId = useHelperHooks().getQueryParams("projectId");
  const { data: projectData, isFetching } = useFetchSpecificProjectProfile(
    projectId as string
  );
  const project = projectData?.length > 0 ? projectData[0] : {};
  const {
    projectManagerName = "N/A",
    projectManagerEmail = "N/A",
    projectManagerPhone = "N/A",
    clientName,
    projectType,
    projectDescription,
    budgetMonth,
    budgetYear,
    status,
  } = project || {};

  const renderDetailRow = (
    label: string,
    value: string | undefined,
    width: string,
    maxLength: number
  ) => (
    <div className="detail-row">
      <span className="label" style={{ width }}>
        {label}
      </span>
      <span className="value project-display-values" data-tooltip={value}>
        {truncateString(value, maxLength)}
      </span>
    </div>
  );

  return (
    <Card className="card_wrapper">
      {isFetching ? (
        <>
          <PsSkeleton height={30} count={9} />
        </>
      ) : (
        <>
          {renderDetailRow("Client Name", clientName, "150px", 20)}
          {renderDetailRow("Project Manager", projectManagerName, "150px", 50)}
          {renderDetailRow("Email", projectManagerEmail, "150px", 50)}
          {renderDetailRow("Contact", projectManagerPhone, "150px", 20)}
          {renderDetailRow("Project Type", projectType, "150px", 20)}
          {renderDetailRow(
            "Project Description",
            projectDescription,
            "150px",
            100
          )}
          <div className="detail-row">
            <span className="label" style={{ width: "150px" }}>
              {"Monthly Budget"}
            </span>
            <span className="value">{convertToUSD(budgetMonth)}</span>
          </div>
          <div className="detail-row">
            <span className="label" style={{ width: "150px" }}>
              {"Yearly Budget"}
            </span>
            <span className="value">{convertToUSD(budgetYear)}</span>
          </div>
          <div className="detail-row">
            <span className="label" style={{ width: "150px" }}>
              {"Project Status"}
            </span>
            <span className="value">{status}</span>
          </div>
        </>
      )}
    </Card>
  );
};

export default ManagementDetailCard;
