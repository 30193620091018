import DataGrid, {
  Column,
  HeaderFilter,
  Editing,
  Paging,
} from "devextreme-react/data-grid";
import PsSkeleton from "../../../../../../../../components/ui-components/skeleton/PsSkeleton";
import { getRowStyle } from "../../../../../../../../utlis/helper";

export const ParagraphView = (props: any) => {
  const {
    dataSource,
    isLoading,
    selectedConfidence,
    rowUpdated
  } = props;

  if (!dataSource) {
    return <PsSkeleton count={1} height={300} width={300} />;
  }

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      const confidenceValue = e?.data["confidence"];
      if (confidenceValue !== "" && confidenceValue !== undefined && confidenceValue !== null) {
        const rowStyle = getRowStyle(confidenceValue, selectedConfidence);
        Object?.assign(e?.rowElement?.style, rowStyle);
      }
    }
  };

  const renderConfidenceCell = (cellData: any) => {
    return (
      <div style={{ fontSize: "12px", color: "gray" }}>
        <div>
          confidence: {cellData?.value}
        </div>
      </div>
    );
  };

  return (
    <DataGrid
      className="grid theme-dependent"
      dataSource={[dataSource]}
      onRowUpdated={rowUpdated}
      onRowPrepared={onRowPrepared}
      noDataText={
        isLoading ? "Loading..." : "No analyzed PDF available to display at this time"
      }
      width={"100%"}
      showBorders={true}
      allowColumnResizing={true}
      columnAutoWidth={true}
      columnResizingMode="widget"
      wordWrapEnabled={true}
      showColumnHeaders={false}
    >
      <Editing
        mode="cell"
        allowUpdating={true}
        allowDeleting={false}
        allowAdding={false}
      />
      <HeaderFilter visible={true} />
      <Paging enabled={false} />
      <Column dataField="column1" />
      <Column
        dataField="confidence"
        width={150}
        cellRender={renderConfidenceCell}
        allowEditing={false}
      />
    </DataGrid>
  );
};
