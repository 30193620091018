import { useState, useEffect } from "react";
import { PskanbanBoard } from "../../../../components/core-component/kanban";
import { keyToImageMap } from "../../../../utlis/image";
import { taskStatuses, cardStatuses } from "../../../../config/datastudio/commonConfig";
import { useFetchDocumentSummary } from "../../../../hooks/datastudio/useDocumentCatalog";
import { MetricsTicker } from "../../../../components/ui-components/ticker-card/TickerCardNew";
import '../../../../styles/datastudio/DocumentCatalog.scss';
import DocumentGridView from "./DocumentGridView";

const DocumentBoardView = (props: any) => {
  const {
    catalogInfo,
    client,
    gridRef,
    onRowClick,
    handleClientLinkClick,
    handleProjectLinkClick,
    handleTaskLinkClick,
    selectedDays,
    telemetryData,
    setTelemetryData,
    metricsData,
    setMetricsData,
    pdfFinalData,
    setPdfFinalData
  } = props;
  const [clickedListData, setClickedListData] = useState<any[]>([]);
  const [gridTitleStatus, setGridTitleStatus] = useState("Pending");
  const { data: summaryData } = useFetchDocumentSummary(selectedDays);

  useEffect(() => {
    const defaultStatus = "Pending";
    setGridTitleStatus(defaultStatus);
    const filteredData = catalogInfo?.filter(
      (item: any) => item?.executionStatus === defaultStatus
    );
    setClickedListData(filteredData);
  }, [catalogInfo]);

  const processData = (catalogInfo: any[]) => {
    const clientStatusCount: {
      [clientName: string]: {
        [status: string]: {
          clientId: string;
          priority: number;
          taskNames: { [taskName: string]: any };
        };
      };
    } = {};


    const getPriority = (status: string) => {
      switch (status) {
        case "Pending":
          return 1;
        case "In Progress":
          return 2;
        case "Failed":
          return 3;
        case "Ready for Review":
          return 4;
        case "Completed":
          return 5;
        case "Rejected":
          return 6;
        default:
          return 0;
      }
    };

    catalogInfo?.forEach((entry) => {
      const {
        clientName,
        executionStatus: status,
        clientId,
        taskName,
      } = entry;
      if (!clientStatusCount[clientName]) {
        clientStatusCount[clientName] = {};
      }
      if (!clientStatusCount[clientName][status]) {
        clientStatusCount[clientName][status] = {
          clientId,
          priority: getPriority(status),
          taskNames: {},
        };
      }

      if (!clientStatusCount[clientName][status]?.taskNames[taskName]) {
        clientStatusCount[clientName][status].taskNames[taskName] = 0;
      }
      clientStatusCount[clientName][status].taskNames[taskName]++;
    });

    const result = [];
    for (const clientName in clientStatusCount) {
      for (const status in clientStatusCount[clientName]) {
        const { clientId, priority, taskNames } =
          clientStatusCount[clientName][status];
        const formattedTaskCounts = Object?.entries(taskNames)?.map(
          ([taskName, count]) => ({
            [`${taskName} - Files`]: count,
          })
        );
        result?.push({
          clientName,
          status,
          clientId,
          priority,
          taskNames: formattedTaskCounts,
        });
      }
    }
    return result;
  }
  const kanbanFiltered = catalogInfo?.filter((item: any) =>
    (client === 'All Clients' || item?.clientName === client)
  );
  const formattedData = processData(kanbanFiltered);

  const handleTileCardClick = (data: any) => {
    setGridTitleStatus(data);
    const filteredData = catalogInfo?.filter(
      
      (item: any) => item?.executionStatus === data 
    );
    setClickedListData(filteredData);

  };

  const handleCardClick = (data: any) => {
    const { status, clientId } = data[0];
    setGridTitleStatus(status);
    const filteredData = catalogInfo?.filter(
      (item: any) =>
        item?.executionStatus === status && item?.clientId === clientId
    );
    setClickedListData(filteredData);
  };
  


  const onRowPrepared = (e: any) => {
    if (e.rowType === "data") {
      e.rowElement.classList.add("hand-cursor");
    }
  };


  const groupAndTransformData = (data: any[]) => {
    const groupedData: { [key: string]: { title: string; data: any[]; icon: string } } = {};

    const cardFiltered = data?.filter((item: any) =>
      (client === 'All Clients' || item?.clientName === client) &&
      (gridTitleStatus === 'All Items' || item?.documentProcess.some((process: any) => process?.name === gridTitleStatus))
    );

    cardFiltered?.forEach((client: { clientName: string; documentProcess: { name: any; value: any; }[] }) => {
      client?.documentProcess?.forEach((process: { name: any; value: any; }) => {
        const title = process?.name;
        if (!groupedData[title]) {
          groupedData[title] = {
            title,
            data: [],
            icon: keyToImageMap[title]
          };
        }
        groupedData[title].data.push({
          name: client?.clientName,
          value: process?.value
        });
      });
    });

    return Object.values(groupedData);
  };

  const transformedData = groupAndTransformData(summaryData || []);

  return (
    <>
      <div className="doc-row-card">
        {cardStatuses.map((status) => {
          const metric = transformedData.find((item) => item.title === status) || {
            title: status,
            data: [],
            icon: keyToImageMap[status] || '',
          };
          return (
            <MetricsTicker
              key={metric?.title}
              title={metric?.title}
              secondaryValue={metric?.data}
              icon={metric?.icon}
              onClick={() => handleTileCardClick(metric?.title)}
            />
          );
        })}
      </div>
      <PskanbanBoard
        status={taskStatuses}
        data={formattedData}
        onCardClick={handleCardClick}
      />
      <div className="t-gap recent-activity-grid">
        <h6>{gridTitleStatus}</h6>
        <DocumentGridView
          catalogInfo={clickedListData}
          onRowClick={onRowClick}
          onRowPrepared={onRowPrepared}
          handleClientLinkClick={handleClientLinkClick}
          handleProjectLinkClick={handleProjectLinkClick}
          handleTaskLinkClick={handleTaskLinkClick}
          gridRef={gridRef}
          telemetryData={telemetryData}
          setTelemetryData={setTelemetryData}
          metricsData={metricsData}
          setMetricsData={setMetricsData}
          pdfFinalData={pdfFinalData}
          setPdfFinalData={setPdfFinalData}
        />
      </div>
    </>
  );
};

export default DocumentBoardView;
