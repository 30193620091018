import classNames from "classnames";
import { ScrollView } from "devextreme-react/scroll-view";
import Toolbar, { Item as ToolbarItem } from "devextreme-react/toolbar";
import ValidationGroup from "devextreme-react/validation-group";
import "../../../styles/ui-components//right-side-panel.scss";
import PsButton from "../button/Button";
import React, { useRef, useCallback } from "react";

export const RightSidePannel = ({
  title,
  isOpened,
  changePanelOpened,
  onSave,
  isSaveDisabled,
  isLoading,
  children,
}: {
  title: string;
  isOpened: boolean;
  changePanelOpened: () => void;
  onSave?: () => void;
  isSaveDisabled?: boolean;
  isLoading?: boolean;
  children: React.ReactNode;
}) => {
  const validationGroup = useRef<ValidationGroup>(null);

  const handleSaveClick = useCallback(() => {
    if (validationGroup.current?.instance.validate().isValid) {
      onSave && onSave();
    }
  }, [onSave]);

  const loaderProps = {
    show: isLoading ?? false,
    loadertext: "Saving...",
  };

  return (
    <div
      id="contact-panel"
      className={classNames({
        panel: true,
        open: isOpened,
        requiresNoScroll: isOpened,
      })}
    >
      <div className="data-wrapper">
        <Toolbar className="panel-toolbar">
          <ToolbarItem location="before">
            <h5 className="contact-name value">{title}</h5>
          </ToolbarItem>
          <ToolbarItem location="after">
            <PsButton icon="close" mode="text" onClick={changePanelOpened} eventName="Sidebar pannel event" />
          </ToolbarItem>
        </Toolbar>

        <ScrollView className="panel-scroll" style={{ padding: "20px" }}>
          <ValidationGroup ref={validationGroup}>{children}</ValidationGroup>
        </ScrollView>
        <div className="panel-footer">
          <PsButton text="Cancel" mode="outlined" onClick={changePanelOpened} eventName="Sidebar pannel close event"/>
          <PsButton
            text={isSaveDisabled ? "Go To Project Profile" : "Save"}
            mode="contained"
            // disabled={isSaveDisabled}
            onClick={handleSaveClick}
            loaderProps={loaderProps}
            eventName="Sidebar pannel save event"
          />
        </div>
      </div>
    </div>
  );
};
