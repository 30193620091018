import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  fetchProjectProfileApi,
  fetchProjectTaskApi,
  fetchProjectInvoiceApi,
  fetchYTDMTDCostApi,
  fetchProjectProfileGraphApi,
  fetchProjectData,
  fileUploadProjectApi,
  fetchProjectDataList,
  fetchProjectResourcesAddApi,
  fetchResouceDetailsApi,
} from "../../service/client/api-service";
import { useCallback, useReducer } from "react";
import showNotification from "../../components/ui-components/alertPopup/AlertPopup";

export interface TaskData {
  clientId: string;
}

export interface TaskState {
  selectedTask: TaskData | null;
  startPopupVisible: boolean;
  fileViewerPopupVisible: boolean;
  selectedFileData: any;
  popupAddFilesVisible: boolean;
}

const initialState: TaskState = {
  selectedTask: null,
  startPopupVisible: false,
  fileViewerPopupVisible: false,
  selectedFileData: null,
  popupAddFilesVisible: false,
};

export const transformProjectStorageData = (data: any, projectName:string) => {
    const result: { id: string; text: string; expanded: boolean; items: any[] }[] = [
        {
            id: "1",
            text: `Polaris-data-lake (${projectName})`,
            expanded: true,
            items: []
        }
    ];

    let categoryId = 1;

    Object?.keys(data)?.forEach(category => {
        const categoryData = data[category] || [];

        const categoryNode = {
            id: `1_${categoryId}`,
            text: category,
            expanded: false,
            items: [
                {
                    id: `1_${categoryId}_1`,
                    text: "",
                    expanded: false,
                    items: [],
                    data: categoryData
                }
            ]
        };

        result[0]?.items?.push(categoryNode);
        categoryId++;
    });

    return result;
};

const reducer = (state: TaskState, action: any) => {
  switch (action.type) {
    case "SELECTED_TASK":
      return {
        ...state,
        selectedTask: action.payload,
      };
    case "START_POPUP_VISIBLE":
      return {
        ...state,
        startPopupVisible: action.payload,
      };
    case "CLEAR":
      return initialState;

    case "SHOW_FILE_VIEWER_POPUP":
      return {
        ...state,
        fileViewerPopupVisible: action.payload,
      };
    case "SELECTED_FILE_DATA":
      return {
        ...state,
        selectedFileData: action.payload,
      };
    case "SET_POPUP_ADD_FILES_VISIBLE":
      return {
        ...state,
        popupAddFilesVisible: action.payload,
      };
    default:
      return state;
  }
};

export const useProjectProfile = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSelectTask = (data: any) => {
    dispatch({ type: "SELECTED_TASK", payload: data });
  };

  const onStartPopupOpen = useCallback((data: any) => {
    handleSelectTask(data);
    dispatch({ type: "START_POPUP_VISIBLE", payload: true });
  }, []);

  const onStartPopupClose = useCallback(() => {
    dispatch({ type: "START_POPUP_VISIBLE", payload: false });
  }, []);

  const onViewScrapingButtonClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );

  const handleAddFilesClick = useCallback(() => {
    dispatch({
      type: "SET_POPUP_ADD_FILES_VISIBLE",
      payload: !state.popupAddFilesVisible,
    });
  }, [state.popupAddFilesVisible]);

  return {
    state,
    onStartPopupOpen,
    onStartPopupClose,
    onViewScrapingButtonClick,
    handleAddFilesClick,
  };
};

export const useFetchProjectTask = (projectId: string) => {
  return useQuery(
    ["fetch-project-task", projectId],
    () => fetchProjectTaskApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export const useFetchResouce = () => {
  return useQuery("fetch-resource", fetchResouceDetailsApi, {
    refetchOnWindowFocus: false,
    select: (data: any) => {
      return data;
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchProjectInvoiceInfo = (
  clientId: string,
  projectId: string
) => {
  return useQuery(
    ["fetch-project-invoice-info", clientId, projectId],
    () => fetchProjectInvoiceApi(clientId, projectId),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};

export function useFetchYTDMTDCost(projectId: string) {
  const frequency = "YTD,MTD";
  const queryParams = {
    projectId: projectId,
    frequency: frequency,
  };
  return useQuery(
    ["fetch-ytdmtd-cost", queryParams],
    () => fetchYTDMTDCostApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
} // used

export function useFetchProjectProfileGraph(projectId: string, frequency: any) {
  const queryParams = {
    projectId: projectId,
    frequency: frequency?.timePeriod,
  };
  return useQuery(
    ["fetch-ytdmtd-cost", queryParams],
    () => fetchProjectProfileGraphApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchProjectDataList(projectId: string) {
  return useQuery(
    ["fetch-project-data-list", projectId],
    () => fetchProjectDataList(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export function useFetchSelectedProjectFileData(
  data:any
) {
  const {projectId,fileExtension,filename,type} = data || {};
  const queryParams = {
    projectId: projectId,
    fileExtension: fileExtension,
    filename: filename,
    type: type,
  };
  return useQuery(
    ["fetch-project-data", queryParams],
    () => fetchProjectData(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && !!fileExtension && !!filename && !!type,
      select: (data: any) => {
        return data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchFileUploadProject = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(fileUploadProjectApi, {
    onSuccess: () => {
      fnQueryClient.invalidateQueries("fetch-fileupload-project");
      showNotification({
        message: "File uploaded successfully",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
}; // used

export const useFetchProjectResourceAdd = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(fetchProjectResourcesAddApi, {
    onSuccess: () => {
      fnQueryClient.invalidateQueries("fetch-specific-project");
      showNotification({
        message: "Resource added successfully",
      });
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};

export const useFetchSpecificProjectProfile = (projectId: string) => {
  return useQuery(
    ["fetch-specific-project", projectId],
    () => fetchProjectProfileApi(projectId),
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
};
