import {useMemo, useState } from "react";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import { DropDownButton } from "devextreme-react";
import DataGridMultiViewLayout from "../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import { Item } from "devextreme-react/toolbar";
import { useFetchDateRange } from "../../../hooks/app/useApps";
import { allClients, customDateRange, dropDownOptions } from "../../../config/constants";
import "../../../styles/datastudio/DocumentCatalog.scss";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { useAppHook } from "../../../hooks/app/useApps";
import { usePage } from "../../../contexts/pageContext";
import AuditLogGrid from "./helper-component/AuditLogGrid";
import { fetchAuditLogApi } from "../../../service/activity-monitor/api-service";

export const AuditLogs = () => {
  const { getPageProperties } = usePage();
  const { data: DateRange } = useFetchDateRange();
  const { getQueryParams } = useHelperHooks();
  const showBackButton = Boolean(getQueryParams("action"));
  const [refreshKey, setRefreshKey] = useState(0);

  const { filterValue } = getPageProperties() || {};
  const {
    clientId: p_clientId,
    t_clientName: p_clientName,
    projectId: p_projectId,
    t_projectName: p_projectName,
    formattedStartTime,
    formattedEndTime,
    timePeriod
  } = filterValue || {};

  const {
    state: { pageIndex, pageSize, sortColumn, sortOrder },
    filterByClient,
    filterByProject,
    clientDropDownData,
    projectDropDownData,
    gridRef,
    handleDropDownPageIndex,
    handlePageIndexChange,
    handleStatusChange,
    handleColumnIndexChange,
    handleDateRangeChange,
  } = useAppHook();


  const handleRefetch = () => {
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const additionalParams = useMemo(() => {
    const startTime = formattedStartTime;
    const endTime = formattedEndTime;
    return {
      timePeriod,
      ...(timePeriod === customDateRange && { startTime, endTime }),
      clientId: p_clientId,
      projectId: p_projectId
    };
  }, [formattedEndTime, formattedStartTime, p_clientId, p_projectId, timePeriod]);


  const apiProps = useMemo(
    () => ({
      apiFunction: fetchAuditLogApi,
      additionalParams: additionalParams,
      refreshKey
    }),
    [additionalParams, refreshKey]
  );


  const DataGridProps = useMemo(() => {
    return {
      clientId: p_clientId,
      projectId: p_projectId,
      sortColumn,
      sortOrder,
      client: p_clientName ? p_clientName : allClients,
      gridRef,
      pageIndex,
      pageSize,
      handlePageIndexChange,
      handleDropDownPageIndex,
      handleStatusChange,
      apiProps,
      handleColumnIndexChange,
      refreshKey
    };
  }, [p_clientId, p_projectId, sortColumn, sortOrder, p_clientName, gridRef, pageIndex, pageSize, handlePageIndexChange, handleDropDownPageIndex, handleStatusChange, apiProps, handleColumnIndexChange, refreshKey]);

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: AuditLogGrid,
      componentProps: DataGridProps,
    },
  ];

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass="toolbar-item-flex">
      <DropDownButton
        stylingMode="text"
        items={clientDropDownData}
        width={"auto"}
        text={p_clientName ? p_clientName : "All Clients"}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByClient}
        onItemClick={handleDropDownPageIndex}
      />
      <DropDownButton
        stylingMode="text"
        text={p_projectName ? p_projectName : "All Projects"}
        displayExpr="projectName"
        dropDownOptions={{ width: "auto" }}         
        items={projectDropDownData}
        useSelectMode
        onSelectionChanged={filterByProject}
        onItemClick={handleDropDownPageIndex}
      />
          <DateDropDown
          onDateChange={handleDateRangeChange}
          data={DateRange}
          handleDropDownPageIndex={handleDropDownPageIndex}
        />
    </Item>
  );

  const layoutProps = {
    headerText: "Audit Log",
    refetch: handleRefetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    isLoading: false,
    showBackButton: showBackButton,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
    </>
  );
};

export default AuditLogs;
