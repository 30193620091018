import DataGrid, {
  Sorting,
  HeaderFilter,
  Scrolling,
  Column,
  Editing,
  Button as ActionsButton,
  Paging,
  Pager,
} from "devextreme-react/data-grid";
import { onExporting, allowedPageSizes } from "../../../../../config/constants";
import { cellStartTime } from "../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../../components/ui-components/contact-status/ContactStatus";
import { useFetchManifestData } from "../../../../../hooks/task/useWorkflow";
import { useCallback, useState } from "react";
import FileViewer from "../../../../../components/ui-components/fileViewer/FileViewer";
import { useNavigate } from "react-router-dom";
import TabView from "./tab-view/tabView";
import { useQueryClient } from "react-query";

const WorkflowGrid = (props: any) => {
  const {
    data,
    isLoading,
    onRowClick,
    onEditClick,
    onDeletePopupOpen,
    gridRef
  } = props;

  const [selectedWorkflowData, setSelectedWorkflowData] = useState<any>(null);
  const [openPopup, setOpenPopup] = useState(false);
  const queryClient = useQueryClient();

  const closePopupException = useCallback(() => {
    queryClient.removeQueries("fetch-manifest-data"); 
    setOpenPopup(false);
  }, [setOpenPopup, queryClient]);
  const navigate = useNavigate();

  const { data: manifestData, isLoading: isManifestLoading } = useFetchManifestData(selectedWorkflowData);
  const fileViewer = JSON?.stringify(manifestData, null, 2);

  const handleRowClick = (cell: any) => {
    const { data } = cell;
    const manifestId = data?.manifestId;
    setSelectedWorkflowData(manifestId);
    setOpenPopup(true);
  };

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.workflowName}
      </span>
    );
  };

  const handleClientLinkClick = useCallback(
    (event: any, clientId: string) => {
      navigate(`/client-profile?clientId=${clientId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleProjectLinkClick = useCallback(
    (event: any, projectId: string) => {
      navigate(`/project-profile?projectId=${projectId}`);
      event.preventDefault();
    },
    [navigate]
  );

  const handleTaskLinkClick = useCallback(
    (event: any, taskId: string) => {
      navigate(`/client-task?taskId=${taskId}`);
      event.preventDefault();
    },
    [navigate]
  );

  return (
    <>
      <DataGrid
        className="grid theme-dependent height-large"
        noDataText={isLoading ? "Loading..." : "No workflow to display at this time"}
        focusedRowEnabled
        dataSource={data}
        keyExpr={"manifestId"}
        onRowClick={onRowClick}
        onExporting={onExporting}
        allowColumnReordering
        showBorders
        ref={gridRef}
        allowColumnResizing={true}
      >
        <HeaderFilter visible />
        <Sorting mode="multiple" />
        <Scrolling mode="virtual" />
        <Column dataField="workflowName" caption="Workflow Name" cellRender={renderFileName} />
        <Column dataField="description" caption="Description" />
        <Column
          dataField="clientName"
          caption="Client"
          cellRender={(data) => {
            const clientName = data?.data?.clientName;
            const clientId = data?.data?.clientId;
            return (
              <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
                {clientName}
              </a>
            );
          }}
        />
        <Column
          dataField="projectName"
          caption="Project Name"
          cellRender={(data) => {
            const projectName = data?.data?.projectName;
            const projectId = data?.data?.projectId;
            return (
              <a href="*" onClick={(e) => handleProjectLinkClick(e, projectId)}>
                {projectName}
              </a>
            );
          }}
        />
        <Column
          dataField="taskName"
          caption="Task Name"
          cellRender={(data) => {
            const TaskName = data?.data?.taskName;
            const taskId = data?.data?.taskId;
            return (
              <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                {TaskName}
              </a>
            );
          }}
        />
        <Column dataField="status" caption="Status" cellRender={ContactStatus} />
        <Column dataField="startDate" caption="Timestamp" cellRender={cellStartTime} />
        <Editing
          mode="row"
          useIcons={true}
          allowUpdating
          allowDeleting={true}
          key={"manifestId"}
        />
        <Column caption="" type="buttons" minWidth={150}>
          <ActionsButton
            name="edit"
            onClick={onEditClick}
            hint="Edit"
            cssClass="action-image"
          />
          <ActionsButton name="delete" cssClass="action-image" onClick={onDeletePopupOpen} />
        </Column>
        <Scrolling rowRenderingMode="virtual"></Scrolling>
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true} />
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewer}
        title={''}
        isLoading={isManifestLoading}
        extensionType={"json"}
        type="custom-component"
        Component={TabView}
        compoentProps={manifestData}
      />
    </>
  );
};

export default WorkflowGrid;
