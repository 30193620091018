import { TreeView } from "devextreme-react";
import DataGrid, {
  Column,
  Paging,
  Pager,
  Scrolling,
} from "devextreme-react/data-grid";
import {
  transformExecutionStorageData,
  useExecutionReducer,
} from "./transform";
import {
  useFetchSelectedExecutionFileData,
} from "../../../../../../hooks/app/useApps";
import FileViewer from "../../../../../ui-components/fileViewer/FileViewer";
import MyFilesView from "../../../../../../assets/icons/icon-eye.png";
import PsButton from "../../../../../ui-components/button/Button";
import { cellStartTime } from "../../../../../ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../../../config/activity-monitor/commonConfig";

export const DataSection = (props: any) => {
  const { list } = props;

  const finalData = list ? transformExecutionStorageData(list) : [];

  const {
    state: { fileViewerPopupVisible, selectedFileData },
    onViewScrapingButtonClick,
  } = useExecutionReducer();

  const { data: SelectedExecutionFileData, isLoading } =
    useFetchSelectedExecutionFileData(selectedFileData);

  const renderItem = (item: any) => {
    const renderAction = (cell: any) => {
      return (
        <PsButton
          hint="MyFiles"
          icon={MyFilesView}
          mode="text"
          onClick={(e) => onViewScrapingButtonClick(e, cell)}
          eventName='Recent activity my files click event'
        />
      );
    };

    return (
      <div className="treeview-item">
        <span>{item?.text}</span>
        {item?.data ? (
          <DataGrid
            dataSource={item?.data}
            rowAlternationEnabled={false}
            showBorders={true}
            noDataText={"No Files  to display at this time"}
            allowColumnResizing={true}
          >
            <Column
              caption="File Name"
              dataField="filename"
              dataType="string"
            />
            <Column
              caption="Uploaded on"
              dataField="createdDate"
              cellRender={cellStartTime}
              dataType="string"
            />
            <Column
              caption="File Size"
              dataField="fileSize"
              dataType="string"
            />
            <Column
              caption=""
              type="buttons"
              width={180}
              cellRender={renderAction}
            />
            <Scrolling rowRenderingMode="virtual"></Scrolling>
            <Paging defaultPageSize={5} />
            <Pager
              visible={true}
              allowedPageSizes={allowedPageSizes}
              displayMode={"full"}
              showPageSizeSelector={true}
              showInfo={true}
              showNavigationButtons={true}
            />
          </DataGrid>
        ) : null}
      </div>
    );
  };

  const title = {
    file: selectedFileData?.filename,
    createdDate: selectedFileData?.createdDate,
  };

  return (
    <>
      <TreeView
        id="simple-treeview"
        items={finalData}
        itemRender={renderItem}
      />
      <FileViewer
        visible={fileViewerPopupVisible}
        onHiding={onViewScrapingButtonClick}
        data={
          selectedFileData?.fileExtension === "pdf"
            ? SelectedExecutionFileData?.data
            : JSON.stringify(SelectedExecutionFileData?.data)
        }
        title={title}
        isLoading={isLoading}
        extensionType={selectedFileData?.fileExtension}
      />
    </>
  );
};
