import { useCallback, useMemo } from "react";
import { DocumentDataViewer } from "./helper-component/documentDataViewer/DocumentDataViewer";
import PdfView from "./helper-component/PdfViwer";
import Process from "./helper-component/process";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import Split from "react-split";

const AnalyzedResult = (props: any) => {
  const {
    analyzedData,
    isLoading,
    selectedConfidence,
    telemetryData,
    setPdfPage,
    uploadData,
    pdfData,
    pdfPage,
    isPdfSaving,
    handleAnalyzeDocument,
    hasFileUploaded,
    rowUpdated,
    isFetching
  } = props;

  const pageNavigationPluginInstance = pageNavigationPlugin();
  const { jumpToPage } = pageNavigationPluginInstance;

  const PdfProps = useMemo(() => {
    return {
      uploadData,
      pdfData,
      pdfPage,
      isPdfSaving,
      pageNavigationPluginInstance
    };
  }, [uploadData, pdfData, pdfPage, isPdfSaving, pageNavigationPluginInstance]);

  const ProcessProps = useMemo(() => {
    return {
      telemetryData,
      isLoading,
      handleAnalyzeDocument,
      hasFileUploaded,
    };
  }, [telemetryData, isLoading, handleAnalyzeDocument, hasFileUploaded]);

  const renderData = useCallback(() => {
    if (!analyzedData) {
      return (
        <Process {...ProcessProps} />
      );
    }
    return (
      <DocumentDataViewer
        pageData={analyzedData}
        isLoading={isLoading}
        selectedConfidence={selectedConfidence}
        telemetryData={telemetryData}
        onPageChange={setPdfPage}
        rowUpdated={rowUpdated}
        jumpToPage={jumpToPage}
        isFetching={isFetching}
      />
    );
  }, [analyzedData, isLoading, selectedConfidence, telemetryData, setPdfPage, rowUpdated, ProcessProps, jumpToPage, isFetching]);

  return (
    <Split
      sizes={[40, 60]}
      minSize={[150, 150]}
      className="split"
    >
      <div className="pdf-wrapper">{<PdfView {...PdfProps} />}</div>
      <div className="data-wrapper-doc-analyzer height-large">
        {renderData()}
      </div>
    </Split>
  );
};

export default AnalyzedResult;
