import { Handle, Position } from "@xyflow/react";

const TextUpdaterNode = ({ data, onClick }: any) => {
  const { label, title, status, description, additionalInfo } = data;
  return (
    <>
      <div onClick={onClick}>
        <Handle type="target" position={Position?.Left} />
        <div className="NodeWrapper">
          <div className="header_info">
            <span className="badge-blue">{label}</span>
            <span>{title}</span>
            {status ? <span className="status_badge">{status}</span> : null}
            {description ? <span>Description:{description}</span> : null}
          </div>
          <hr />
          <div>
            <span>{additionalInfo}</span>
          </div>
        </div>
        <Handle type="source" position={Position?.Right} id="a" />
      </div>
    </>
  );
};

export default TextUpdaterNode;
