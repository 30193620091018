import { useCallback } from "react";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";
import WorkbenchUICards from "../../../../components/ui-components/workspaceCard/WorkbenchCards";

const WorkbenchCards = (props: any) => {
  const { data, isLoading, message, className } = props;

  const renderPerformance = useCallback(
    (item: any) => {
      const { title, creater, id, description, onClick,imageIcon, date } = item;
      try {
        const workspaceData = {
          id,
          title,
          creater,
          description,
          date,
          image: imageIcon,
          onClick: onClick,
          className,
        };
        return <WorkbenchUICards key={id} {...workspaceData} />;
      } catch (err) {
        console.error("Error rendering Performance:", err);
      }
    },
    [className]
  );

  if (isLoading) {
    return (
      <div className={`workspace-card ${className}`}>
        <PsSkeleton count={1} height={500} />
      </div>
    );
  }

  if (!data && !isLoading) {
    return <div className="no-data-message">{message}</div>;
  }

  return <>{data?.map((item: any) => renderPerformance(item))}</>;
};

export default WorkbenchCards;
