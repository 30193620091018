import React, { ReactNode } from "react";
import { useErrorContext } from "./ErrorProvider";
import PageError from "./pages/static-info/PageError";


type ErrorType =
  | "componentError"
  | "APIError"
  | "networkError"
  | "unknownError";

interface ErrorBoundaryProps {
  children: ReactNode;
  addError: (error: Error | string,errorType:ErrorType) => void;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Update state to render fallback UI
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    let componentName = "Unknown Component";
    if (errorInfo.componentStack) {
      const componentNameMatch = errorInfo.componentStack.match(/at (\w+)/);
      if (componentNameMatch) {
        componentName = componentNameMatch[1];
      }
    }
    this.props.addError(`Error in ${componentName}: ${error.message}`, "componentError");
  }
  

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <PageError />;
    }

    return this.props.children;
  }
}

const ErrorBoundaryWrapper: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { addError } = useErrorContext();
  return <ErrorBoundary addError={addError}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
