import { useCallback, useEffect } from "react";
import {
  ReactFlow,
  addEdge,
  Node,
  useNodesState,
  useEdgesState,
  OnConnect,
  Edge,
  MiniMap,
  Background,
  Controls,
  Position,
  NodeTypes,
} from "@xyflow/react";
import CustomNode from "./CustomNode";
import { DayDateFormat } from "../../utlis/helper";

const nodeDefaults = {
  sourcePosition: Position.Right,
  targetPosition: Position.Left,
};

const nodeProperties = [
  {
    label: "Template Form",
    dataField: "clientName",
  },
  {
    label: "Storage",
    dataField: "",
    defaultText: "Template Container",
  },
  {
    label: "File",
    dataField: "template_path",
  },
];

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const TemplateLineage = (props: any) => {
  const { data } = props;
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);

  const renderTitleNode = (item: any) => {
    return (
      <ul style={{ listStyle: "none" }}>
        <li>Template: {item?.template_name}</li>
        <li>Type: {item.template_function}</li>
      </ul>
    );
  };

  useEffect(() => {
    if (data) {
      const newNodes: any = nodeProperties
        .map((property, index) => {
          const { label, dataField, defaultText } = property;
          const title =
            label === "Template Form" ? renderTitleNode(data) : null;
          return {
            id: `node-${dataField}-${index}`,
            type: "custom",
            position: { x: index * 300, y: 150 },
            data: {
              label: label,
              title: title || data[dataField] || defaultText,
              status: dataField === "template_path" ? data["status"] : null,
              additionalInfo: (
                <ul style={{ listStyle: "none" }}>
                  <li>Created By: {data["created_by"] || "N/A"}</li>
                  <li>
                    Updated Date: {DayDateFormat(data["created_at"]) || "N/A"}
                  </li>
                </ul>
              ),
            },
            ...nodeDefaults,
          };
        })
        .filter(Boolean);

      const newEdges = [];
      for (let i = 0; i < newNodes.length - 1; i++) {
        newEdges.push({
          id: `${newNodes[i].id}-${newNodes[i + 1].id}`,
          source: newNodes[i].id,
          target: newNodes[i + 1].id,
        });
      }

      setNodes(newNodes);
      setEdges(newEdges);
    }
  }, [data, setEdges, setNodes]);

  const onConnect: OnConnect = useCallback(
    (params) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const nodeTypes: NodeTypes = {
    custom: CustomNode,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        nodeTypes={nodeTypes}
        fitView
      >
        <MiniMap />
        <Background />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default TemplateLineage;
