import { TextArea } from "devextreme-react";

const DataTab = (props: any) => {

  const { data } = props;

  const dataItem = data?.data

  return (
    <>
      <div className="dx-field">
        <div className="dx-field-label">Data</div>
        <div className="dx-field-value">
          <TextArea
            value={dataItem?.items}
            height={500}
            readOnly={true}
          />
        </div>
      </div>
    </>
  );
}

export default DataTab;