import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Popup } from "devextreme-react";
import { FileViwerPopupProps } from "./types";
import ToolbarAnalytics from "../toolbar-analytics/ToolbarAnalytics";
import { Item } from "devextreme-react/toolbar";
import PsButton from "../button/Button";
import "../../../styles/ui-components/LineageView.scss";

export const LineageViewer: React.FC<FileViwerPopupProps> = ({
  visible,
  onHiding,
  title,
  isLineageLoading,
  component,
}) => {
  const [index, setIndex] = useState<number>(0);

  const onClose = useCallback(() => {
    onHiding();
  }, [onHiding]);

  const AdditionalToolbarContent = useMemo(() => {
    return (
      <Item
        location="after"
        locateInMenu="auto"
        widget="dxButton"
        cssClass="toolbar-item-flex"
      >
        <PsButton
          icon="close"
          mode="text"
          type="normal"
          width={"auto"}
          onClick={onClose}
          eventName="File Viewer close buttonp"
        />
      </Item>
    );
  }, [onClose]);

  useEffect(() => {
    setIndex(Date.now());
  }, []);

  useEffect(() => {
    if (visible) {
      const popupContent = document?.querySelector(
        `.lineage-view-popup-${index} .dx-popup-content`
      );
      if (popupContent) {
        popupContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
      const scrollableContent = document?.querySelector(
        `.lineage-view-popup-${index} .dx-popup-content-scrollable`
      );
      if (scrollableContent) {
        scrollableContent?.setAttribute(
          "style",
          "background-color: #ebebeb !important;"
        );
      }
    }
  }, [visible, index]);

  const renderWorkflowView = () => {
    if (component) {
      const Component = component;
      return <div className="file-container">{Component}</div>;
    }
  };

  return (
    <Popup
      visible={visible}
      onHiding={onHiding}
      fullScreen={true}
      width={"auto"}
      height={1000}
      showTitle={false}
      showCloseButton={false}
      className={`lineage-view-popup-${index}`}
    >
      <div className="view-wrapper">
        <ToolbarAnalytics
          title={title}
          showToolbar={true}
          additionalToolbarContent={AdditionalToolbarContent}
        />
        <div className="popup-content-container">
          {isLineageLoading ? <div className="file-container">Loading...</div> : renderWorkflowView()}
        </div>
      </div>
    </Popup>
  );
};

export default LineageViewer;
