import { DropDownBox } from "devextreme-react";
import { RightSidePannel } from "../../../../components/ui-components/right-side-panel/right-side-panel";
import { useCallback, useEffect } from "react";
import { useApp } from "../../../../contexts/app";
import { useCreateShareWorkbenchUser } from "../../../../hooks/workbench/useWorkbench";
import showNotification from "../../../../components/ui-components/alertPopup/AlertPopup";
const ownerLabel = { "aria-label": "Owner" };
const ShareWorkbenchAccess = (props: any) => {
  const {
    isShareWorkbenchShow,
    changePanelOpened,
    shareBoxValue,
    userTreeDataSource,
    syncTreeViewSelection,
    treeViewRender,
    shareWorkBenchData,
    assetType,
    assetId,
    assetName,
  } = props;

  const { user } = useApp();
  const { userId, name } = user || {};

  const { mutate, isLoading, isSuccess } =
    useCreateShareWorkbenchUser(changePanelOpened);

  const handleShareSumbit = useCallback(() => {
    try {
      if (shareWorkBenchData.length > 0) {
        const payload = {
          ownerId: userId,
          ownerName: name,
          assetType: assetType,
          assetId: assetId,
          assetName: assetName,
        };
        const updatedData = shareWorkBenchData?.map((item: any) => ({
          ...item,
          ...payload,
        }));
        mutate({ data: updatedData });
      } else {
        showNotification({
          message: `Please select a user to share`,
          type: "warning",
        });
      }
    } catch (e) {
      console.log(e);
    }
  }, [assetId, assetName, assetType, mutate, name, shareWorkBenchData, userId]);

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: `Invite Sent Successfully`,
      });
    }
  }, [isSuccess]);
 
  
  return (
    <>
      <RightSidePannel
        isOpened={isShareWorkbenchShow}
        changePanelOpened={changePanelOpened}
        title={"Share/Invite Users"}
        onSave={handleShareSumbit}
        isLoading={isLoading}
      >
        <DropDownBox
          value={shareBoxValue}
          valueExpr="ID"
          inputAttr={ownerLabel}
          displayExpr="name"
          placeholder="Select users..."
          showClearButton={true}
          dataSource={userTreeDataSource}
          onValueChanged={syncTreeViewSelection}
          contentRender={treeViewRender}
        ></DropDownBox>
      </RightSidePannel>
    </>
  );
};

export default ShareWorkbenchAccess;
