import DataGrid, { Column, Paging, Pager, HeaderFilter,SearchPanel, MasterDetail } from "devextreme-react/data-grid";
import {
  useGetDataProcessingDataApi,
} from "../../../../hooks/datastudio/useDataAnalyzer";
import {
  cellStartTime,
  cellUpdatedTime,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { useState } from "react";
import {
  allowedPageSizes,
  dropDownOptions,
  GridActionItems,
} from "../../../../config/datastudio/commonConfig";
import FileViewer from "../../../../components/ui-components/fileViewer/FileViewer";
import MyFilesView from "../../../../assets/icons/icon-eye.png";
import PsCheckBox from "../../../../components/ui-components/checkbox/Checkbox";
import { DropDownButton} from "devextreme-react";
import MergeFunction from "./MergeFiles";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import TableSkeleton from "../../../../components/ui-components/DataGridMultiViewLayout/helper-component/TableSkeleton";
import MasterDetailView from "./MyFilesInfo";

const MyfilesDataGrid = ({ onRowClick, onActionHandler,tabView, userRecentData, isFilesLoading, isFilesFetching}: any) => {

  const showMerge = tabView['merge'];
  const [filesData, setFilesData] = useState<any>();
  const [openPopup, setOpenPopup] = useState(false);

  const file_name = filesData?.name;
  const user_id = filesData?.userId;
  const process_type = filesData?.type;
  const { data: getFilesData, isLoading: isDataLoading } =
    useGetDataProcessingDataApi({
      filename: file_name,
      userId: user_id,
      processType: process_type,
      isFileUploaded: true,
    });
  const fileViewerData = JSON?.stringify(getFilesData?.data, null, 2);
  const fileExtensions = file_name?.split(".").pop();

  const handleViewFiles = (e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    const params = {
      name: data?.fileName,
      userId: data?.userId,
      status: data?.status,
      type: data?.processType,
    };
    setFilesData(params);
    setOpenPopup(true);
  };


  const closePopupException = () => {
    setOpenPopup(false);
  };

  const handleRowClick = (e: any) => {
    const { data } = e;
    const params = {
      file_name: data?.fileName,
      status: data?.status,
      user_id: data?.userId,
      process_type: data?.processType,
    };
    setFilesData(params);
    onRowClick(params);
  };

  const onHanldeCheckbox = (e: any) => {
    e?.event?.stopPropagation();
    console.log(e);
  };

  const renderViewFile = (cell: any) => {
    return (
      <PSIconText
        alt="View File"
        src={MyFilesView}
        hint="View File"
        width={28}
        height={28}
        onClick={(e: any) => handleViewFiles(e, cell)}
      />
    );
  };

  const headerCellRender = () => {
    return (
      <DropDownButton
        text="Action"
        width="auto"
        stylingMode="text"
        items={GridActionItems}
        useSelectMode={true}
        displayExpr="text"
        dropDownOptions={dropDownOptions}
        onItemClick={onActionHandler}
        disabled={false}
        className="dropdown-export"
      />
    );
  };

  const renderAction = (cell: any) => {
    return (
      <PsCheckBox
        id="myfile-action"
        value={true}
        onChange={onHanldeCheckbox}
      />
    );
  };

  const title = {
    file: filesData?.name,
    status: filesData?.status,
  };

  if (isFilesLoading || isFilesFetching) {
    return <TableSkeleton />;
  }

  const renderFileName = (cell: any) => {
    return (
      <span
        className="file-name"
        style={{ cursor: "pointer", color: "#0F6CBD", textDecoration: "underline" }}
        onClick={() => handleRowClick(cell)}
      >
        {cell?.data?.fileName}
      </span>
    );
  };

  return (
    <>
    {showMerge ?<MergeFunction /> : null}
    <div className="recent-activity-grid">
      <DataGrid
        className="grid theme-dependent height-large"
        dataSource={userRecentData}
        noDataText={isFilesLoading ? "Loading..." : "Recent Updation Will Be Dispalyed Here"}
        allowColumnReordering
        showBorders
        allowColumnResizing={true}
      >
        <SearchPanel visible={true} width={200}/>
        <HeaderFilter visible={true} />
        <Column
          caption="Action"
          dataField="action"
          type="buttons"
          width={150}
          headerCellRender={headerCellRender}
          cellRender={renderAction}
        />
        <Column
          caption="System File Name"
          dataField="fileName"
          dataType="string"
          width={300}
          cellRender={renderFileName}
        />
        <Column
          caption="Original File Name"
          dataField="originalFileName"
          dataType="string"
          width={180}
        />
        <Column
          caption="Uploaded By"
          dataField="userName"
          dataType="string"
          width="auto"
        />
        <Column caption="Type" dataField="processType" dataType="string" />
        <Column
          caption="Status"
          dataField="status"
          dataType="string"
          width="auto"
          cellRender={ContactStatus}
        />
        <Column
          caption="Uploaded On"
          dataField="created_at"
          dataType="datetime"
          width="auto"
          cellRender={cellStartTime}
        />
        <Column
          caption="Last Updated"
          dataField="updated_at"
          dataType="datetime"
          width="auto"
          cellRender={cellUpdatedTime}
        />
        <Column
          caption=""
          type="buttons"
          width={180}
          cellRender={renderViewFile}
        />
        <MasterDetail enabled={true} component={MasterDetailView} />
        <Paging defaultPageSize={25} />
        <Pager
          visible={true}
          allowedPageSizes={allowedPageSizes}
          displayMode={"full"}
          showPageSizeSelector={true}
          showInfo={true}
          showNavigationButtons={true}
        />
      </DataGrid>
      <FileViewer
        visible={openPopup}
        onHiding={closePopupException}
        data={fileViewerData}
        title={title}
        isLoading={isDataLoading}
        extensionType={fileExtensions}
        params={filesData}
        showExportToDataLake={true}
      />
    </div>
    </>
  );
};

export default MyfilesDataGrid;
