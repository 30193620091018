import { useCallback } from "react";
import { Column, DataGrid, HeaderFilter } from "devextreme-react/data-grid";
import { DropDownButton } from "devextreme-react";
import { DropDownButtonTypes } from "devextreme-react/cjs/drop-down-button";
import { CellDollar } from "../../../../../../components/ui-components/dataGridFormat/dataGridFormat";
import '../../../../../../styles/finops/resourceGrid.scss';

const TechnicalAllocationGrid = (props: any) => {
  const {
    digitalResourceData,
    setselectedDigitalResource,
    selectedDigitalResource,
  } = props;

  const onItemClick = useCallback(
    (e: DropDownButtonTypes.ItemClickEvent) => {
      const selectedItem = { ...e.itemData };
      const dailyCost =
        parseFloat(selectedItem?.cost_per_day?.replace("$", "")) || 0;
      selectedItem.per_month_cost = `$${(dailyCost * 30).toFixed(2)}`;
      selectedItem.quantity = "1";
      selectedItem.totalcost = `$${(
        dailyCost *
        30 *
        selectedItem.quantity
      ).toFixed(2)}`;

      setselectedDigitalResource((prevSelected: any) =>
        prevSelected?.some((item: any) => item?.id === selectedItem.id)
          ? prevSelected
          : [...prevSelected, selectedItem]
      );
    },
    [setselectedDigitalResource]
  );

  const onRowUpdated = (e: any) => {
    const updatedData = selectedDigitalResource?.map((item: any) =>
      item.id === e.key
        ? {
            ...item,
            ...e.data,
            totalcost: `$${(
              parseFloat(item?.per_month_cost?.replace("$", "")) * e?.data?.quantity
            ).toFixed(2)}`,
          }
        : item
    );
    setselectedDigitalResource(updatedData);
  };

  const onRowRemoved = (e: any) => {
    const updatedData = selectedDigitalResource?.filter(
      (item: any) => item.id !== e?.key
    );
    setselectedDigitalResource(updatedData);
  };

  const calculateTotalCost = (data: any) => {
    const perMonthCost = parseFloat(data?.per_month_cost?.replace("$", "")) || 0;
    const totalPerMonthCost = perMonthCost * data?.quantity;
    return totalPerMonthCost;
  };

  return (
    <>
      <div className="resource-gird-calculations">
        <DropDownButton
          id="resource-template"
          useSelectMode={false}
          items={digitalResourceData}
          displayExpr="designation"
          keyExpr="id"
          text="Select Resource"
          width={300}
          onItemClick={onItemClick}
        />
        <DataGrid
          dataSource={selectedDigitalResource}
          className="resource-grid"
          keyExpr="id"
          editing={{
            mode: "row",
            allowUpdating: true,
            allowDeleting: true,
          }}
          onRowUpdated={onRowUpdated}
          onRowRemoved={onRowRemoved}
          allowColumnReordering
          allowColumnResizing
        >
        <HeaderFilter visible={true} />
          <Column
            dataField="designation"
            caption="Designation"
            allowEditing={false}
          />
          <Column
            dataField="cost_per_day"
            caption="Daily Cost"
            dataType="number"
            cellRender={CellDollar}
            allowEditing={false}
          />
          <Column
            dataField="per_month_cost"
            caption="Monthly Cost"
            dataType="number"
            allowEditing={false}
          />
          <Column dataField="start_date" caption="Start Date" dataType="date" />
          <Column dataField="end_date" caption="End Date" dataType="date" />
          <Column
            dataField="quantity"
            caption="No.of Resource"
            dataType="number"
          />
          <Column
            dataField="totalcost"
            caption="Total Cost / Month"
            calculateCellValue={calculateTotalCost}
            format={"currency"}
            allowEditing={false}
            alignment="right"
          />
        </DataGrid>
      </div>
    </>
  );
};

export default TechnicalAllocationGrid;
