import DataGrid, { Column, MasterDetail } from "devextreme-react/data-grid";
import AccessList from "./helper-component/AccessList";
import ServicesList from "./helper-component/ServicesList";
import { useFetchClientUserRoles, useFetchRolesByScope } from "../../../../hooks/app/useApps";
import AdminList from "./helper-component/AdminList";
import PsSkeleton from "../../../../components/ui-components/skeleton/PsSkeleton";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import {fullServiceList} from "../../../../config/user-adminstration/commonConfig";
import ToolbarAnalytics from "../../../../components/ui-components/toolbar-analytics/ToolbarAnalytics";

const Permission = () => {
  const [isAdminDisabled, setIsAdminDisabled] = useState<boolean>(false);
  const location = useLocation();
  const userId = location.pathname.split("/")[2];

  const { data: userRole, isLoading: isUserRoleLoading } = useFetchClientUserRoles(userId);

  const hasAdminPermissions = useMemo(() => {
    return (userRole?.[0]?.admin_action?.length || 0) > 0;
  }, [userRole]);

  useEffect(() => {
    setIsAdminDisabled(hasAdminPermissions);
  }, [hasAdminPermissions]);

  const filteredServiceList = useMemo(() => {
    if (isAdminDisabled) {
      return fullServiceList.filter((service) => service.key === "Polaris");
    }
    return fullServiceList; 
  }, [isAdminDisabled]);

  const RenderMasterDetail = ({ data }: any) => {
    const { key } = data;
    const { data: roleData, isLoading: isRoleLoading } = useFetchRolesByScope(key);
    if (isRoleLoading || isUserRoleLoading) return  <PsSkeleton count={5} height={30}/>;

    if (key === "Polaris") return <AdminList roles={roleData} setIsAdminDisabled={setIsAdminDisabled} />;
    if (key === "Client" || key === "Project") return <AccessList roles={roleData} userRole={userRole} />;
    if (key === "Service") return <ServicesList roles={roleData} userRole={userRole} />;

    return null;
  };

  return (
    <div className="recent-activity-grid">
    <ToolbarAnalytics 
        title="Access Permission" 
        showBackButton
      />
    { isUserRoleLoading ? <PsSkeleton count={8} height={40}/>
     : <DataGrid
      className="grid theme-dependent height-large"
      dataSource={filteredServiceList}
      remoteOperations={true}
      showBorders={true}
      id="gridContainer"
      allowColumnResizing={true}
      allowColumnReordering={true}
      repaintChangesOnly={true}
      noDataText="No roles to display"
    >
      <Column caption="Service" dataField="name" dataType="string"/>
      <MasterDetail enabled render={RenderMasterDetail} />
    </DataGrid> }
    </div>
  );
};


export default Permission;
