// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-datagrid-headers.dx-datagrid-nowrap.dx-bordered-top-view {
  border-radius: 8px 8px 0 0;
}

.theme-dependent .dx-gridbase-container > .dx-bordered-bottom-view {
  border-radius: 0 0 8px 8px;
}

.stop-icon-image {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  font-size: 13px !important;
  background-color: rgb(15, 108, 189);
  color: white !important;
  padding-right: 10px !important;
  padding-left: 10px !important;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
}
.stop-icon-image:hover {
  background-color: #d43f1f;
}
.stop-icon-image:focus {
  outline: none;
}
.stop-icon-image:active {
  transform: translateY(1px);
}`, "",{"version":3,"sources":["webpack://./src/styles/task/Infrastructure.scss"],"names":[],"mappings":"AAAE;EACE,0BAAA;AACJ;;AAIM;EACE,0BAAA;AADR;;AAMA;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;EACA,mCAAA;EACA,uBAAA;EACA,8BAAA;EACA,6BAAA;EACA,eAAA;EACA,sCAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;AAHF;AAKE;EACE,yBAAA;AAHJ;AAME;EACI,aAAA;AAJN;AAOE;EACI,0BAAA;AALN","sourcesContent":["  .dx-datagrid-headers.dx-datagrid-nowrap.dx-bordered-top-view {\n    border-radius: 8px 8px 0 0;\n  }\n  \n  .theme-dependent {\n    .dx-gridbase-container {\n      > .dx-bordered-bottom-view {\n        border-radius: 0 0 8px 8px;\n      }\n    }\n  }\n\n.stop-icon-image {\n  width: 20px;\n  height: 20px;\n  margin-right: 10px;\n  font-size: 13px !important;\n  background-color: rgb(15, 108, 189);\n  color: white !important;\n  padding-right: 10px !important;\n  padding-left: 10px !important;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n  border: none;\n  padding: 8px 16px; \n  border-radius: 4px; \n\n  &:hover {\n    background-color: #d43f1f; \n  }\n\n  &:focus {\n      outline: none;\n  }\n\n  &:active {\n      transform: translateY(1px); \n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
