import { useMemo } from "react";
import NewTemplate from "./helper-component/newTemplate";
import DeleteTemplate from "./helper-component/deleteTemplate";
import TemplateGrid from "./helper-component/templateGrid";
import EditTemplate from "./helper-component/editTemplate";
import TemplateClone from "./helper-component/template-clone/templateClone";
import { DropDownButton } from "devextreme-react";
import { Item } from "devextreme-react/cjs/data-grid";
import { dropDownOptions } from "../../../../config/constants";
import { filterStatusList } from "../../../../config/client/commonConfig";
import DataGridMultiViewLayout from "../../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import {
  useFetchSpecificTemplate,
  useFetchTemplate,
  useFetchTemplateFunctionDropdown,
  useTemplate,
} from "../../../../hooks/digital-service/useTemplate";
import { useAppHook } from "../../../../hooks/app/useApps";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import NewIcon from "../../../../assets/images/icons/icon-add-item-1.png";

export const TemplateLibrary = () => {
  const {
    state: {
      popupVisible,
      selectedTemplateData,
      editpopupVisible,
      deletePopupVisible,
      clonePopupVisible,
      selectedTemplateId,
      selectedTemplateShow,
    },
    onAddTemplateClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onEditTemplateClick,
    onClonePopupOpen,
    onClonePopupClose,
    changeEditPopupVisibility,
    handleRowClick,
    onHandleTemplatePopupClose,
  } = useTemplate();

  const {
    state: { status, functions },
    filterByStatus,
    filterByFunction,
    getFunctionTypeList,
    gridRef,
  } = useAppHook();

  const {
    data: templateLibrary,
    refetch,
    isFetching,
  } = useFetchTemplate();
  const { data: dataTemplateJson } =
    useFetchSpecificTemplate(selectedTemplateId);
  const { data: templateFunction } = useFetchTemplateFunctionDropdown("function");
  const getTemplateTypeList = templateFunction?.[0]
  const showBackButton = Boolean(useHelperHooks().getQueryParams("action"));

  const DataGridProps = useMemo(() => {
    return {
      onEditTemplateClick,
      onAddTemplateClick,
      onDeletePopupOpen,
      onClonePopupOpen,
      onClonePopupClose,
      handleRowClick,
      templateLibrary,
      gridRef,
      dataTemplateJson,
      showPopup: selectedTemplateShow,
      onHandleTemplatePopupClose,
    };
  }, [
    onEditTemplateClick,
    onAddTemplateClick,
    onDeletePopupOpen,
    onClonePopupOpen,
    onClonePopupClose,
    handleRowClick,
    templateLibrary,
    gridRef,
    dataTemplateJson,
    selectedTemplateShow,
    onHandleTemplatePopupClose,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
      getTemplateTypeList
    };
  }, [changePopupVisibility, popupVisible,getTemplateTypeList]);

  const TemplateEditProps = useMemo(() => {
    return {
      selectedTemplateData,
      editpopupVisible,
      changeEditPopupVisibility,
      getTemplateTypeList
    };
  }, [selectedTemplateData, editpopupVisible, changeEditPopupVisibility,getTemplateTypeList]);

  const TemplateDeleteProps = useMemo(() => {
    return {
      selectedTemplateData,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [deletePopupVisible, onDeletePopupClose, selectedTemplateData]);

  const TemplateCloneProps = useMemo(() => {
    return {
      selectedTemplateData,
      clonePopupVisible,
      onClonePopupClose,
    };
  }, [selectedTemplateData, onClonePopupClose, clonePopupVisible]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto" cssClass='toolbar-item-flex'>
      <DropDownButton
        items={filterStatusList || ["Loading..."]}
        stylingMode="text"
        text={status}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByStatus}
      />
      <DropDownButton
        items={getFunctionTypeList || ["Loading..."]}
        stylingMode="text"
        text={functions}
        dropDownOptions={dropDownOptions}
        useSelectMode
        onSelectionChanged={filterByFunction}
      />
      <PSIconText
        text="Add Template"
        alt="Add Template"
        src={NewIcon}
        onClick={onAddTemplateClick}
      />
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: TemplateGrid,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "Template Library",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    isLoading: isFetching,
    showBackButton: showBackButton,
  };

  return (
    <div>
      <DataGridMultiViewLayout {...layoutProps} />
      <NewTemplate {...FormProps} />
      <EditTemplate {...TemplateEditProps} />
      <DeleteTemplate {...TemplateDeleteProps} />
      <TemplateClone {...TemplateCloneProps} />
    </div>
  );
};
