import { TextArea } from "devextreme-react";

const DataTab = (props: any) => {

  const { newDataItems, updateDataField } = props;

  return (
    <>
      <div className="dx-field">
        <div className="dx-field-label">Enter the Data</div>
        <div className="dx-field-value">
          <TextArea
            value={newDataItems?.items}
            onValueChange={updateDataField("items")}
            height={500}
          />
        </div>
      </div>
    </>
  );
}

export default DataTab;