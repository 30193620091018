import Popup from "devextreme-react/popup";
import PsButton from "../ui-components/button/Button";
import { keywordSearchConfirmModalProps } from "./type";
import { TextBox } from "devextreme-react";
import { useState } from "react";

const KeywordSearch = (props: keywordSearchConfirmModalProps) => {
  const { show, onConfirm, onClose } = props;
  const [selectedData, setSelectedData] = useState<any>({
    keywordValue: [],
  });

  const loaderProps = {
    show: false,
    loadertext: "Please Wait...",
  };

  const setReplaceValue = (e: any) => {
    const keywordValue = e?.value;
    if (typeof keywordValue === "string") {
      const valuesArray = keywordValue
        .split(',')
        .map((val: string) => val.trim())
        .filter((val: string) => val.length > 0);
      setSelectedData((prevData: any) => ({
        ...prevData,
        keywordValue: valuesArray,
      }));
    } else {
      setSelectedData((prevData: any) => ({
        ...prevData,
        keywordValue: keywordValue || [],
      }));
    }
  }

  return (
    <Popup
      title="Keyword Search"
      visible={show}
      width="auto"
      height="auto"
      onHiding={onClose}
      onShowing={() => setSelectedData({ keywordValue: "" })}
    >
      <div style={{ display: "flex", marginTop: "10px", marginBottom: "20px" }}>
        <TextBox
          placeholder="Enter keyword(s) to search(case sensitive)"
          showClearButton={true}
          width={400}
          onValueChanged={setReplaceValue}
          value={
        Array.isArray(selectedData?.keywordValue)
          ? selectedData?.keywordValue.join(", ")
          : selectedData?.keywordValue
          }
        />
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PsButton
          text="Search"
          onClick={() => onConfirm(selectedData)}
          loaderProps={loaderProps}
          mode="contained"
          type="default"
        />
        <PsButton text="Close" onClick={onClose} type="normal" />
      </div>
    </Popup>
  );
};

export default KeywordSearch;
