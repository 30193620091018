import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import DataSource from "devextreme/data/data_source";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellEndTime,
  cellStartTime,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import FileViewer from "../../../ui-components/fileViewer/FileViewer";
import LineageViewer from "../../../ui-components/lineageView/LineageView";
import WorkflowProcess from "./react-flow/WorkflowProcess";
import PSIconText from "../../../ui-components/icon-with-text/IconText";
import MetricsIcon from "../../../../assets/icons/icon-metrics-4.png";
import TelemetryIcon from "../../../../assets/icons/icon-telemetry-5.png";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import MasterDetailView from "./hierarchyView";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import PsDataGrid from "../../../ui-components/dataGrid/DataGrid";
import { useFetchMetricsDataApi } from "../../../../hooks/activity-monitor/useLogsMetrics";
import { useFetchManifestData } from "../../../../hooks/task/useWorkflow";
import { useFetchSpecificTask } from "../../../../hooks/task/useTask";
import { fetchRecentActivityAPI } from "../../../../service/app/api-service";
import { usePage } from "../../../../contexts/pageContext";

const RecentActivityGridView = React.memo((props: any) => {
  const {
    handleProjectLinkClick,
    handleClientLinkClick,
    handleTaskLinkClick,
    gridRef,
    height,
    additionalParams,
    handleColumnIndexChange,
    refreshKey,
    workSpaceApiFunction,
    workSpaceAdditionalParams,
    workSpaceApiCall,
    title, 
    pageRoute,
    handleRefetch
  } = props;

  const [openPopup, setOpenPopup] = useState(false);
  const [filesData, setFilesData] = useState<any>("");
  const [selectedWorkflowData, setSelectedWorkflowData] = useState<any>(null);
  const [openLineage, setOpenLineage] = useState(false);
  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const queryClient = useQueryClient();

  const {
    clientName: clientColunm,
    projectName: projectColumn,
    taskName: taskColumn,
    status: statusColumn,
    executionId: executionColumn,
    projectType: projectTypeColumn,
    endTime: timeEnded,
    timestamp: timeStarted,

  } = filterValue || {};

  

  const logmetricsParams = useMemo(
    () => ({
      executionName: filesData?.executionName,
      clientShortName: filesData?.clientShortName,
      projectShortName: filesData?.projectShortName,
      projectId: filesData?.projectId,
      executionTime: filesData?.timestamp,
      pagination : false,
    }),
    [filesData]
  );
  
  const { data: metricsGridData, isLoading: isMetricsLoading } =
    useFetchMetricsDataApi(logmetricsParams);
  const metricsData = useMemo(
    () => metricsGridData?.data || [],
    [metricsGridData]
  );

  const { data: manifestData, isLoading: isManifestLoading } =
    useFetchManifestData(selectedWorkflowData?.manifestId);
  const { data: taskData } = useFetchSpecificTask(selectedWorkflowData?.taskId);

  const mergedData = useMemo(
    () => ({
      dataTask: taskData,
      dataWorkflow: manifestData,
    }),
    [taskData, manifestData]
  );

  const fileViewerData = useMemo(
    () =>
      JSON?.stringify(
        metricsData && Object?.keys(metricsData)?.length > 0
          ? metricsData
          : mergedData,
        null,
        2
      ),
    [metricsData, mergedData]
  );

  const onHanldingLineage = useCallback(() => {
    queryClient.removeQueries("fetch-manifest-data");
    setOpenLineage(!openLineage);
  }, [queryClient, openLineage]);

  const handleViewTelemetry = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      setFilesData(null);
      setSelectedWorkflowData(null);
      props.onRowClick(cell);
    },
    [props]
  );

  const renderViewTelemetryFile = useCallback(
    (cell: any) => (
      <PSIconText
        src={TelemetryIcon}
        alt="Telemetry File"
        hint="Telemetry File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewTelemetry(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    ),
    [handleViewTelemetry]
  );

  const handleViewWorkflow = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      setFilesData(null);
      setSelectedWorkflowData(cell.row.data);
      setOpenLineage(true);
    },
    []
  );

  const renderViewWorkflowFile = useCallback(
    (cell: any) => (
      <PSIconText
        src={WorkflowIcon}
        alt="Workflow File"
        hint="Workflow File"
        width={30}
        height={30}
        onClick={(e: any) => handleViewWorkflow(e, cell)}
        disabled={cell?.data?.status === "Uploaded"}
      />
    ),
    [handleViewWorkflow]
  );

  const handleViewMetrics = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    setFilesData(cell.row.data);
    setOpenPopup(true);
  }, []);

  const renderViewMetrics = useCallback(
    (cell: any) => (
      <PSIconText
        src={MetricsIcon}
        alt="Metrics"
        hint="Metrics"
        width={22}
        height={22}
        onClick={(e: any) => handleViewMetrics(e, cell)}
      />
    ),
    [handleViewMetrics]
  );

  const columns = useMemo(
    () => [
      {
        dataField: "clientName",
        caption: "Client",
        filterValues: clientColunm,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleClientLinkClick(e, data?.data?.clientId)}
          >
            {data?.data?.clientName}
          </a>
        ),
      },
      {
        dataField: "projectName",
        caption: "Project Name",
        filterValues: projectColumn,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleProjectLinkClick(e, data?.data?.projectId)}
          >
            {data?.data?.projectName}
          </a>
        ),
      },
      {
        dataField: "taskName",
        caption: "Task Name",
        filterValues: taskColumn,
        cellRender: (data: any) => (
          <a
            href="null"
            onClick={(e) => handleTaskLinkClick(e, data?.data?.taskId)}
          >
            {data?.data?.taskName}
          </a>
        ),
      },
      {
        dataField: "executionId",
        caption: "Execution ID",
        filterValues: executionColumn,
      },
      {
        dataField: "projectType",
        caption: "Activity Type",
        filterValues: projectTypeColumn,
      },
      {
        dataField: "status",
        caption: "Status",
        filterValues: statusColumn,
        cellRender: ContactStatus,
      },
      {
        dataField: "timestamp",
        caption: "Time Started",
        filterValues: timeStarted,
        cellRender: cellStartTime,
      },
      {
        dataField: "endTime",
        caption: "Time Completed",
        filterValues: timeEnded,
        cellRender: cellEndTime,
      },
      {
        caption: "",
        type: "buttons",
        width: 70,
        cellRender: renderViewTelemetryFile,
      },
      {
        caption: "",
        type: "buttons",
        width: 70,
        cellRender: renderViewWorkflowFile,
      },
      {
        caption: "",
        type: "buttons",
        width: 70,
        cellRender: renderViewMetrics,
      },
    ],
    [
      handleClientLinkClick,
      handleProjectLinkClick,
      handleTaskLinkClick,
      renderViewTelemetryFile,
      renderViewWorkflowFile,
      renderViewMetrics,
      clientColunm,
      executionColumn,
      projectColumn,
      projectTypeColumn,
      statusColumn,
      taskColumn,
      timeEnded,
      timeStarted,
    ]
  );

  const dataProps = useMemo(
    () => ({
      datas: mergedData,
      params: selectedWorkflowData,
    }),
    [mergedData, selectedWorkflowData]
  );

  const lineageDataProps = useMemo(
    () => ({
      title: `${selectedWorkflowData?.taskName} - ${selectedWorkflowData?.executionName}`,
      visible: openLineage,
      onHiding: onHanldingLineage,
      isLineageLoading: isManifestLoading,
      component: <WorkflowProcess data={dataProps} />,
    }),
    [
      dataProps,
      isManifestLoading,
      onHanldingLineage,
      openLineage,
      selectedWorkflowData,
    ]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No Recent Activity to display at this time",
      heightClass: height,
      id:"recentactivitygrid"
    }),
    [height]
  );

  const apiProps = useMemo(
    () => ({
      apiFunction: workSpaceApiCall ? workSpaceApiFunction : fetchRecentActivityAPI,
      additionalParams : workSpaceApiCall ? workSpaceAdditionalParams : additionalParams,
      refreshKey,
    }),
    [additionalParams, refreshKey, workSpaceApiFunction, workSpaceAdditionalParams, workSpaceApiCall]
  );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "executionId",
      allowedPageSizes,
      searchPanelVisible: true,
      grifRef: gridRef,
      headerFilterVisible: true,
      detailComponent: MasterDetailView,
      pagerEnabled: true,
      pagingEnabled: true,
      handleColumnIndexChange,
      title,
      pageRoute,
      handleRefetch
    }),
    [columns, gridRef, handleColumnIndexChange, title, pageRoute, handleRefetch]
  );

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <FileViewer
        key="file-viewer"
        visible={openPopup}
        onHiding={() => setOpenPopup(false)}
        data={fileViewerData}
        title={{
          file: filesData?.executionName,
          clientName: filesData?.clientName,
          projectName: filesData?.projectName,
          taskName: filesData?.taskName,
          createdDate: filesData?.timestamp,
        }}
        isLoading={isMetricsLoading || isManifestLoading}
        extensionType="json"
      />
      <LineageViewer {...lineageDataProps} />
    </div>
  );
});

export default RecentActivityGridView;
