import { useMutation, useQuery, useQueryClient } from "react-query";
import { fetchProjectCollaborationApi, sendProjectCollaborationMsgApi } from "../../../service/admin/api-service";


export function useFetchProjectCollaboration(msgId: any) {
  const queryParams = msgId;
  return useQuery(
    ["fetch-collaboration", queryParams],
    () => fetchProjectCollaborationApi(queryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!msgId,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchSendProjectCollaboration = () => {
  const fnQueryClient = useQueryClient();
  return useMutation(sendProjectCollaborationMsgApi, {
    onSuccess: () => {
      fnQueryClient.invalidateQueries("fetch-project-collaboration");
    },
    onError: (error: Error) => {
      console.log(error);
    },
  });
};
