import { del, get, post, updateFile, upload } from "../../service/api/api";
import { makeQueryParam, validateEndPoint } from "../api/helper/helper";

export const fetchFilesApi = async (queryParams: string) => {
  const query = makeQueryParam(queryParams);
  const response = await get(`datalake/v1/management/retrieve/all${query}`);
  return response;
}; //used

export const fetchSingleFilesApi = async (queryParams: any) => {
  const query = makeQueryParam(queryParams);
  const validUrl = validateEndPoint(`datalake/v1/file-manager/retrieve/viewfile${query}`);
  const response = await get(validUrl);
  return response;
}; //used 

export const fetchTemplateApi = async () => {
  const response = await get("webscraping/v1/templates/templates-info/all");
  return response;
}; //used 

export const fetchSpecificTemplateApi = async (template_id: string) => {
  const response = await get(
    `webscraping/v1/templates/templates-info/${template_id}`
  );
  return response;
}; //used 

export const fetchTemplateFunctionApi = async (func_type: any) => {
  const response = await get(
    `core/v1/functiondropdown/dropdownInfo/${func_type}`
  );
  return response;
};

export const updateTemplateApi = async (payload: any) => {
  const response = await updateFile("webscraping/v1/templates/update", payload);
  return response;
}; //used 

export const createTemplateApi = async (payload: any) => {
  const response = await upload("webscraping/v1/templates/create", payload);
  return response;
}; //used 

export const createCloneTemplateApi = async (
  type: string,
  template_id: string,
  payload: any
) => {
  const response = await post(
    `webscraping/v1/templates/create?type=${type}&template_id=${template_id}`,
    payload
  );
  return response;
}; //used 


export const deleteTemplateById = async (template_id: string) => {
  const response = await del(
    `webscraping/v1/templates/disable?template_id=${template_id}`
  );
  return response;
}; //used 
