import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateWorkSpace,
  useFetchSharedData,
  useFetchWorkSpace,
  useWorkBench,
} from "../../hooks/workbench/useWorkbench";
import WorkbenchLayout from "./layout/WorkbenchLayout";
import { useApp } from "../../contexts/app";
import AddNewWorkSpace from "./helper-component/form/NewWorkSpace";
import PSIconText from "../../components/ui-components/icon-with-text/IconText";
import { Item } from "devextreme-react/toolbar";
import WorkbenchCards from "./helper-component/workbenchCards/Workbenchcards";
import SharedWorkbookIcon from "../../assets/media/workspace-shared-1.png";
import { newWorkSpaceFormData, WorkSpaceData } from "./types";
import WorkspaceIcon from "../../assets/media/workspace-1.png";
import NewIcon from "../../assets/images/icons/icon-add-item-1.png";

const WorkSpaceDashboard = () => {
  const { user } = useApp();

  const {
    state: { isWorkSpaceShow },
    handleAddWorkSpace,
  } = useWorkBench();

  const {
    data: workspacesData,
    isLoading: isWorkspaceLoading,
    isSuccess: hasWorkSpaceLoaded,
  } = useFetchWorkSpace({
    user: user?.userId,
  });

  const {
    data: sharedworkspaces,
    isLoading: isSharedLoading,
    isSuccess: hasWorkbooksLoaded,
  } = useFetchSharedData(user?.userId, "workspace");

  const [newWorkSpaceData, setNewWorkSpaceData] = useState<WorkSpaceData>({
    ...newWorkSpaceFormData,
  });

  const {
    mutate,
    isLoading: buttonisLoading,
    isSuccess: isSuccessWorkSpace,
  } = useCreateWorkSpace(handleAddWorkSpace);

  useEffect(() => {
    setNewWorkSpaceData({
      ...newWorkSpaceFormData,
    });
  }, [isWorkSpaceShow]);

  const updateWorkspaceField = useCallback(
    (field: string) => (value: any) => {
      setNewWorkSpaceData({ ...newWorkSpaceData, [field]: value });
    },
    [newWorkSpaceData]
  );

  const handleSaveSumitWorkSpace = useCallback(() => {
    mutate({
      ...newWorkSpaceData,
      createdBy: user?.userId,
      createdName: user?.name,
    });
  }, [mutate, newWorkSpaceData, user]);

  const combinedWorkspacesData = useMemo(() => {
    const regularWorkspace =
      workspacesData?.map((item: any) => ({
        ...item,
        imageIcon: WorkspaceIcon,
      })) || [];
    return [...regularWorkspace];
  }, [workspacesData]);

  const combinedSharedWorkspacesData = useMemo(() => {
    const regularSharedWorkspace =
      sharedworkspaces?.map((item: any) => ({
        ...item,
        imageIcon: SharedWorkbookIcon,
      })) || [];
    return [...regularSharedWorkspace];
  }, [sharedworkspaces]);

  const WorkSpaceProps = useMemo(() => {
    return {
      data: combinedWorkspacesData,
      isLoading: isWorkspaceLoading,
      message: `Click "${"New Workspace"}" to create your first workspace.`,
    };
  }, [isWorkspaceLoading, combinedWorkspacesData]);

  const SharedWorkSpaceProps = useMemo(() => {
    return {
      data: combinedSharedWorkspacesData,
      isLoading: isSharedLoading,
      // message: "Please add your workspace data.",
      sidepanelProps: {
        title: "New Workspace",
        Component: AddNewWorkSpace,
        componentProps: {
          isOpened: isWorkSpaceShow,
          changePanelOpened: handleAddWorkSpace,
          onSave: handleSaveSumitWorkSpace,
          newWorkSpaceData,
          updateField: updateWorkspaceField,
          isSuccessWorkSpace,
        },
        isLoadingButton: buttonisLoading,
        isWorkSpaceShow,
        handleAddWorkSpace,
      },
    };
  }, [
    buttonisLoading,
    combinedSharedWorkspacesData,
    handleAddWorkSpace,
    handleSaveSumitWorkSpace,
    isSharedLoading,
    isSuccessWorkSpace,
    isWorkSpaceShow,
    newWorkSpaceData,
    updateWorkspaceField,
  ]);

  const cardDetails = useMemo(
    () => [
      {
        title: "My Workspace",
        Component: WorkbenchCards,
        componentProps: WorkSpaceProps,
        componentId: "workspace-card",
        hasCard: hasWorkSpaceLoaded,
      },
      {
        title: "Shared Workspaces",
        Component: WorkbenchCards,
        componentProps: SharedWorkSpaceProps,
        componentId: "shared-workspace-card",
        hasCard: hasWorkbooksLoaded,
      },
    ],
    [
      SharedWorkSpaceProps,
      WorkSpaceProps,
      hasWorkSpaceLoaded,
      hasWorkbooksLoaded,
    ]
  );

  const additionalToolbarContent = useMemo(() => {
    return (
      <Item location="after" locateInMenu="auto">
        <PSIconText
          src={NewIcon}
          alt="New Workspace"
          onClick={handleAddWorkSpace}
          text={"New Workspace"}
        />
      </Item>
    );
  }, [handleAddWorkSpace]);

  const WorkbenchLayoutProps = useMemo(() => {
    return {
      headerText: "Workspaces",
      additionalToolbarContent,
      cardDetails,
      activityData: {},
    };
  }, [cardDetails, additionalToolbarContent]);

  return <WorkbenchLayout {...WorkbenchLayoutProps} />;
};

export default WorkSpaceDashboard;
