import { formatDate } from "../../../../../../utlis/helper";

export const TelemetryAnalyzer = (props: any) => {
  const { telemetryData, message } = props;
  const hasTelemetryData = telemetryData && telemetryData?.fileName && telemetryData?.events?.length > 0;
  return (
    <>
      {hasTelemetryData ? (
        <div className="card-analyze-telemetry">
          <ul>
            {telemetryData?.events?.map((event: any, index: number) => (
              <li className="card-stable" key={index}>
                {event?.eventData} {formatDate(event?.timeStamp)}
              </li>
            ))}
          </ul>
        </div>
      ) : message === "File does not exist" ? (
        <div className="card-analyze-text">{message}</div>
      ) : (
        <div className="card-analyze-text">Loading...</div>
      )}
    </>
  );
};
