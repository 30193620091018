import { useCallback, useReducer } from "react";

export interface ExecutionData {
  clientId: string;
}

export interface ExecutionState {
  selectedExecution: ExecutionData | null;
  startPopupVisible: boolean;
  fileViewerPopupVisible: boolean;
  selectedFileData: any;
  popupAddFilesVisible: boolean;
}

const initialState: ExecutionState = {
  selectedExecution: null,
  startPopupVisible: false,
  fileViewerPopupVisible: false,
  selectedFileData: null,
  popupAddFilesVisible: false,
};

export const transformExecutionStorageData = (data: any) => {
  const categoryData = data || [];
  const result: { id: string; text: string; expanded: boolean; items: any[]; data: any }[] = [
     {
      id: `1`,
      text: "",
      expanded: false,
      items: [],
      data: categoryData
    }
  ];
return result;
};

const reducer = (state: ExecutionState, action: any) => {
  switch (action.type) {
    case "SELECTED_EXECUTION":
      return {
        ...state,
        selectedExecution: action.payload,
      };
    case "START_POPUP_VISIBLE":
      return {
        ...state,
        startPopupVisible: action.payload,
      };
    case "CLEAR":
      return initialState;

    case "SHOW_FILE_VIEWER_POPUP":
      return {
        ...state,
        fileViewerPopupVisible: action.payload,
      };
    case "SELECTED_FILE_DATA":
      return {
        ...state,
        selectedFileData: action.payload,
      };
    case "SET_POPUP_ADD_FILES_VISIBLE":
      return {
        ...state,
        popupAddFilesVisible: action.payload,
      };
    default:
      return state;
  }
};

export const useExecutionReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleSelectExecution = (data: any) => {
    dispatch({ type: "SELECTED_EXECUTION", payload: data });
  };

  const onStartPopupOpen = useCallback((data: any) => {
    handleSelectExecution(data);
    dispatch({ type: "START_POPUP_VISIBLE", payload: true });
  }, []);

  const onStartPopupClose = useCallback(() => {
    dispatch({ type: "START_POPUP_VISIBLE", payload: false });
  }, []);

  const onViewScrapingButtonClick = useCallback(
    (e?: any, cell?: any) => {
      const data = cell?.row?.data || {};
      dispatch({
        type: "SHOW_FILE_VIEWER_POPUP",
        payload: !state.fileViewerPopupVisible,
      });
      dispatch({ type: "SELECTED_FILE_DATA", payload: data });
    },
    [state.fileViewerPopupVisible]
  );

  const handleAddFilesClick = useCallback(() => {
    dispatch({
      type: "SET_POPUP_ADD_FILES_VISIBLE",
      payload: !state.popupAddFilesVisible,
    });
  }, [state.popupAddFilesVisible]);

  return {
    state,
    onStartPopupOpen,
    onStartPopupClose,
    onViewScrapingButtonClick,
    handleAddFilesClick,
  };
};