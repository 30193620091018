import { useMemo } from "react";
import PsImageWithTextCard from "../../../../components/ui-components/PsImageWithTextCard/ItemCard";
import { WorkBooItemMaps } from "../../../../fixture/WorkbooksItems";
import { useWorkBench} from "../../../../hooks/workbench/useWorkbench";
import AddWorkBookItems from "../form/WorkBookItemForm";
import { ToolbarAnalytics } from "../../../../components/ui-components/toolbar-analytics";

const WorkbookItems = () => {
  const {
    state: { workBookItemsType, isWorkBookAddFormShow },
    onHandleWorkBookItemsClick,
  } = useWorkBench();

  const ItemProps = useMemo(() => {
    return {
      cardItems: WorkBooItemMaps,
      onCardClick: onHandleWorkBookItemsClick,
    };
  }, [onHandleWorkBookItemsClick]);

  const FormProps = useMemo(() => {
    return {
      changePanelOpened: onHandleWorkBookItemsClick,
      workBookItemsType,
      isWorkBookAddFormShow,
    };
  }, [isWorkBookAddFormShow, onHandleWorkBookItemsClick, workBookItemsType]);

  return (
    <div>
      <ToolbarAnalytics title={"Workbook Items"} showBackButton={true} />
      <PsImageWithTextCard {...ItemProps} />
      <AddWorkBookItems {...FormProps} />
    </div>
  );
};

export default WorkbookItems;
