import { useNavigate } from "react-router";
import { ContactStatus } from "../../../ui-components/contact-status/ContactStatus";
import {
  cellLastExecutionTime,
  editCellStatusRender,
} from "../../../ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import { useHelperHooks } from "../../../../hooks/helper/utils";
import PsDataGrid from "../../../ui-components/dataGrid/DataGrid";
import { useMemo } from "react";
import DataSource from "devextreme/data/data_source";
import { usePage } from "../../../../contexts/pageContext";
import { fetchTaskDescriptionApi } from "../../../../service/task/api-service";

const TasksGrid = (props: any) => {
  const { renderTaskStart, handleRefetch, refreshKey } = props;
  const navigate = useNavigate();

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName : clientNameColumn,
    taskName : taskNameColumn,
    workflowName: workFlowNameColumn,
    projectType: projectTypeColumn,
    taskScheduling:  taskSchedulingColumn,
    status : statusColumn,
    maxContainerGroup : maxContainerGroupColumn,
    containersPerGroup : containersPerGroupColumn,
    containerSize: containerSizeColumn,
    latest_execution : latestExecutionColumn,

  } = filterValue || {};

  const projectId = useHelperHooks().getQueryParams("projectId");

  const handleClientLinkClick = (event: any, clientId: string) => {
    event.preventDefault();
    navigate(`/client-profile?clientId=${clientId}`);
  };

  const handleTaskLinkClick = (event: any, taskId: string) => {
    event.preventDefault();
    navigate(`/client-task?taskId=${taskId}`);
  };
  
  const additionalParams = useMemo(() => {
      return {
        projectId : projectId,
      };
    }, [projectId]);
    
    const apiProps = useMemo(
      () => ({
        apiFunction: fetchTaskDescriptionApi,
        additionalParams: additionalParams,
        refreshKey,
      }),
      [additionalParams, refreshKey]
    );

  const columns = useMemo(
    () => [
    {
      dataField:"clientName",
      caption:"Client",
      filterValues: clientNameColumn,
      cellRender:(data : any) => {
        const clientName = data?.data?.clientName;
        const clientId = data?.data?.clientId;
        return (
          <a href="*" onClick={(e) => handleClientLinkClick(e, clientId)}>
            {clientName}
          </a>
        );
      }},
       {
        dataField:"taskName",
        caption:"Task Name",
        filterValues: taskNameColumn,
        cellRender:(data : any) => {
            const taskDescription = data?.data?.taskName;
            const taskId = data?.data?.taskId;
            return (
              <a href="*" onClick={(e) => handleTaskLinkClick(e, taskId)}>
                {taskDescription}
              </a>
            );
          }},
          {
            dataField:"workflowName",
            caption:"Workflow Name",
            filterValues: workFlowNameColumn,
          },
          {
            dataField:"projectType" ,
            caption:"Activity Type",
            filterValues: projectTypeColumn,
          },
          {
            dataField:"taskScheduling",
            caption:"Frequency",
            filterValues: taskSchedulingColumn,
          },
          {
          dataField:"status",
          caption:"Status",
          cellRender:ContactStatus,
          editCellRender:editCellStatusRender,
          filterValues: statusColumn
          },
          {
            dataField:"maxContainerGroup",
            caption:"Max Container Group",
            alignment:"left",
            filterValues: maxContainerGroupColumn,
          },
          {
            dataField:"containersPerGroup",
            caption:"Containers Per Group",
            alignment:"left",
            filterValues: containersPerGroupColumn,
          },
          {
            dataField:"containerSize",
             caption:"Container Size",
             filterValues:containerSizeColumn,
          },
          {
            dataField:"latest_execution",
            caption:"Last Execution",
            cellRender:cellLastExecutionTime,
            filterValues: latestExecutionColumn,
          },
          {
            caption:"",
            type:"buttons",
            width:60,
            cellRender:renderTaskStart,
          }
      
  ], [clientNameColumn, containerSizeColumn, containersPerGroupColumn, latestExecutionColumn, maxContainerGroupColumn, projectTypeColumn, renderTaskStart, statusColumn, taskNameColumn, taskSchedulingColumn, workFlowNameColumn])

    const pageProps = useMemo(
      () => ({
      nodataText: "No tasks to display at this time",
      heightClass: "height-large",
      id:"taskGrid",
    }), []);

    const gridProps =useMemo( 
        () => ({
        dataSource: DataSource,
        columns,
        keyExpr: "taskId",
        allowedPageSizes,
        searchPanelVisible: true,
        headerFilterVisible: true,
        pagingEnabled: true,
        pagerEnabled: true,
        handleRefetch,
      }), [columns, handleRefetch]);

  return (
    <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
  );
};

export default TasksGrid;
