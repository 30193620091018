// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datebox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.custom-date-range {
  display: flex;
  align-items: center;
}
.custom-date-range .dx-texteditor-input {
  text-align: center;
  color: #333;
  font-size: 14px;
  padding: 5px;
}
.custom-date-range .dx-editor-outlined.dx-texteditor-label-outside {
  margin-bottom: -5px;
  width: 100%;
  font-size: 14px;
  line-height: 1.4285714286em;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/DateBox.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;AACJ;AACI;EACI,kBAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AACR;AAEI;EACI,mBAAA;EACA,WAAA;EACA,eAAA;EACA,2BAAA;AAAR","sourcesContent":[".datebox {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    gap: 20px;\n}\n\n.custom-date-range {\n    display: flex;\n    align-items: center;\n\n    .dx-texteditor-input {\n        text-align: center;\n        color: #333;\n        font-size: 14px;\n        padding: 5px;\n    }\n\n    .dx-editor-outlined.dx-texteditor-label-outside {\n        margin-bottom: -5px;\n        width: 100%;\n        font-size: 14px;\n        line-height: 1.4285714286em;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
