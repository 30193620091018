import { ItemCardProps, ItemCardState } from "./types";
import "../../../styles/ui-components/ItemCard.scss";

const PsImageWithTextCard = (props: ItemCardProps) => {
  const { cardItems, onCardClick } = props;
  const renderCard = () => {
    return cardItems?.map((project: ItemCardState) => {
      const { id, image, name, description } = project;
      return (
        <div
          className="card"
          key={id}
          onClick={() => onCardClick && onCardClick(project)}
        >
          <img className="card-background" src={image} alt={image} />
          <div className="card-content">
            <div className="card-title">{name}</div>
            <div className="card-description">
              {description.map((desc: string, index: number) => (
                <div key={index}>{desc}</div>
              ))}
            </div>
            <span className="info-icon"></span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="card-wrapper-box card-container" id="card-container">
      {renderCard()}
    </div>
  );
};

export default PsImageWithTextCard;
