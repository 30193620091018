import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useCreateEstimator,
  transformData,
} from "../../../hooks/finops/useEstimator";
import TextBox from "devextreme-react/text-box";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { ToolbarAnalytics } from "../../../components/ui-components/toolbar-analytics";
import { ResourceEstimator } from "./ResourceEstimator";
import { AddResource } from "./helper-component/Add-Resource/AddResource";
import showNotification from "../../../components/ui-components/alertPopup/AlertPopup";
import "../../../styles/finops/estimator.scss";

export const ResourceCalculator = () => {
  const [estimateTitle, setEstimateTitle] = useState("");
  const [estimateTitleError, setEstimateTitleError] = useState(false);

  const [selectedDigitalResource, setselectedDigitalResource] = useState<any>(
    []
  );
  const [selectedOerations, setselectedOerations] = useState<any>([]);
  const [selectedInfraResource, setselectedInfraResource] = useState<any>([]);

  const { mutate, isSuccess } = useCreateEstimator();

  useEffect(() => {
    if (isSuccess) {
      showNotification({
        message: "Estimation Added Successfully To Blob",
        type: "success",
      });
      setEstimateTitle("");
      setselectedDigitalResource([]);
      setselectedOerations([]);
      setselectedInfraResource([]);
    }
  }, [isSuccess]);

  const onhandleEstimateTitle = (e: any) => {
    setEstimateTitle(e);
  };

  const onhandleEstimateTitleChange = (e: any) => {
    if (typeof e === "string" && e.trim()) {
      setEstimateTitleError(false);
    }
  };

  const onHandleFinalSave = useCallback(
    (data: any) => {
      if (estimateTitle.length !== 0) {
        const estimatorFinalData = transformData(data, estimateTitle);
        mutate(estimatorFinalData);
      } else {
        showNotification({ message: "Estimator Name Is Required" });
      }
    },
    [estimateTitle, mutate]
  );

  const propEstimator = useMemo(() => {
    return {
      onHandleFinalSave,
      isSuccessEstimator: isSuccess,
      resourceGridProps: {
        setselectedDigitalResource,
        selectedDigitalResource,
        setselectedOerations,
        selectedOerations,
        setselectedInfraResource,
        selectedInfraResource,
      },
    };
  }, [
    isSuccess,
    onHandleFinalSave,
    selectedDigitalResource,
    selectedInfraResource,
    selectedOerations,
  ]);

  return (
    <div className="flex-colunm-layout">
      <ToolbarAnalytics title={"Resource Calculator"} />
      <div className="grid-container-estimator">
        <div className="estimate-text-editor">
          <TextBox
            placeholder="Your Estimate Name"
            value={estimateTitle}
            onValueChange={onhandleEstimateTitle}
            onChange={onhandleEstimateTitleChange}
            width="100%"
            className={estimateTitleError ? "required-field" : ""}
          >
            <Validator>
              <RequiredRule message="" />
            </Validator>
          </TextBox>
        </div>
        <AddResource {...propEstimator} />
      </div>
      <ResourceEstimator />
    </div>
  );
};
