import { Button } from "devextreme-react/button";
import { ButtonProps } from "./types";
import "../../../styles/ui-components/button.scss";
import { ClickEvent } from "devextreme/ui/button";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const PsButton = (props: ButtonProps) => {
  const {
    text,
    onClick,
    type = "default",
    width = 135,
    mode = "contained",
    disabled,
    icon,
    loaderProps,
    hint,
    className,
    iconPosition = "left",
    visible = true,
    eventName = 'button click'
  } = props;
  const { show, loadertext } = loaderProps || {};
  const positionCss =
    iconPosition === "left"
      ? "start-icon"
      : iconPosition === "right"
      ? "end-icon"
      : iconPosition === "top"
      ? "top-icon"
      : iconPosition === "bottom"
      ? "bottom-icon"
      : "";

  const onButtonClick = (e: ClickEvent) => {
    if (onClick) {
      onClick(e);
      GTAButtonEventHandler(eventName);
    }
  };

  return (
    <Button
      width={width}
      text={show ? loadertext : text}
      type={type}
      icon={icon}
      hint={hint}
      className={className + " " + positionCss}
      stylingMode={mode}
      onClick={onButtonClick}
      disabled={disabled}
      visible={visible}
    />
  );
};

export default PsButton;
