// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.document-processing-container {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  margin-top: 20px;
}
.document-processing-container .data-viewer {
  width: 100%;
}
.document-processing-container .card-analyze-text {
  background-color: white;
  width: 100%;
  padding: 50px;
  text-align: center;
}
.document-processing-container .pdf-wrapper {
  width: 35%;
  height: 80vh;
  min-height: 80vh;
  overflow: hidden;
}
.document-processing-container .data-wrapper {
  width: 63%;
  height: 80vh;
  min-height: 80vh;
  padding: 20px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.document-processing-container .data-wrapper .result-container {
  height: 70vh;
  min-height: 70vh;
  overflow-y: auto;
}

.pragraph-view {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 20px;
  background: rgb(241, 240, 240);
}

.no-data {
  text-align: center;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/datastudio/documentProcessing.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,eAAA;EACA,SAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;AAEJ;AAAE;EACE,uBAAA;EACA,WAAA;EACA,aAAA;EACA,kBAAA;AAEJ;AAAE;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,UAAA;EACA,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;AAEJ;AADI;EACE,YAAA;EACA,gBAAA;EACA,gBAAA;AAGN;;AAEA;EACE,gBAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;AACF;;AACA;EACE,kBAAA;EACA,gBAAA;AAEF","sourcesContent":[".document-processing-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 25px;\n  margin-top: 20px;\n  .data-viewer{\n    width:100%;\n  }\n  .card-analyze-text{\n    background-color: white;\n    width: 100%;\n    padding: 50px;\n    text-align: center;\n  }\n  .pdf-wrapper {\n    width: 35%;\n    height: 80vh;\n    min-height: 80vh;\n    overflow: hidden;\n  }\n  .data-wrapper {\n    width: 63%;\n    height: 80vh;\n    min-height: 80vh;\n    padding: 20px;\n    background-color: white;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    .result-container {\n      height: 70vh;\n      min-height: 70vh;\n      overflow-y: auto;\n    }\n  }\n}\n\n.pragraph-view {\n  margin-top: 10px;\n  margin-bottom: 10px;\n  padding: 20px;\n  background: rgb(241 240 240);\n}\n.no-data {\n  text-align: center;\n  margin-top: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
