import DataGrid, {
  Column,
  HeaderFilter,
  Editing,
  Paging
} from "devextreme-react/data-grid";
import { useEffect } from "react";
import PsSkeleton from "../../../../../../components/ui-components/skeleton/PsSkeleton";
import { useDocumentProcessingStateProvider } from "../../../../../../hooks/datastudio/useDocumentProcessing";
import { disableFields, restrictColumns } from "../../../../../../config/datastudio/commonConfig";
import { getRowStyle } from "../../../../../../utlis/helper";
import { confidenceBG } from "../../../../../../config/datastudio/confidence";

export const DefaultEdit = (props: any) => {
  const { content, isLoading, selectedConfidence } = props;
  const { state, setDocumentProcessingStateData } = useDocumentProcessingStateProvider();

  useEffect(() => {
    setDocumentProcessingStateData(content);
  }, [content]);

  const handleEditing = (changes: any) => {
    if (!changes?.length) return;
    const updatedData = state?.documentProcessingStateData?.map((row: any) => {
      const change = changes?.find((c: any) => c?.key === row?.key);
      return change ? { ...row, ...change?.data } : row;
    });
    setDocumentProcessingStateData(updatedData);
  };

  const onRowPrepared = (e: any) => {
    if (e?.rowType === "data") {
      const confidenceValue = e?.data["confidence"];
      const qualityIndex = e?.data["qualityIndex"];
      let rowStyle = {};
      if (qualityIndex === 0) {
        rowStyle = { backgroundColor: confidenceBG };
      }
      if (confidenceValue !== "" && confidenceValue !== undefined && confidenceValue !== null) {
        const confidenceStyle = getRowStyle(confidenceValue, selectedConfidence);
        rowStyle = { ...rowStyle, ...confidenceStyle };
      }
      Object?.assign(e?.rowElement?.style, rowStyle);
    }
  };

  if (!content) {
    return <PsSkeleton count={1} height={300} width={300} />;
  }

  const renderTable = (data: any, key: string) => {
    if (!data || typeof data !== "object") return null;
    const dataSource = Array?.isArray(data) ? data : [data];
    if (!dataSource?.length || (Array?.isArray(dataSource) && dataSource?.every(item => Object?.keys(item)?.length === 0))) {
      return null;
    }
    return (
      <div key={key} style={{ marginBottom: "20px" }}>
        <DataGrid
          className="grid theme-dependent"
          dataSource={dataSource}
          onRowUpdating={(e: any) => handleEditing(e?.changes)}
          noDataText={
            isLoading ? "Loading..." : `No ${key} data to display at this time`
          }
          width={"100%"}
          showBorders={true}
          allowColumnResizing={true}
          columnAutoWidth={false}
          columnResizingMode="widget"
          onRowPrepared={onRowPrepared}
        >
          <Editing
            mode="cell"
            allowUpdating={true}
            allowDeleting={false}
            allowAdding={false}
          />
          <Paging enabled={false} />
          <HeaderFilter visible={true} />
          {Object?.keys(dataSource[0] || {})?.map((field) => (
            <Column
              key={field}
              dataField={field}
              width={300}
              alignment="left"
              caption={field?.replace(/-/g, " ")}
              allowEditing={!disableFields?.includes(field)}
            />
          ))}
        </DataGrid>
      </div>
    );
  };

  const documentDetails = Object?.entries(content)
    ?.filter(([key, value]) => value !== null && typeof value !== "object" && !restrictColumns?.includes(key))
    ?.map(([key, value]) => ({ key, value }));

  return (
    <div>
      {documentDetails?.length > 0 && (
        <div>
          <DataGrid
            className="grid theme-dependent"
            dataSource={documentDetails}
            noDataText={isLoading ? "Loading..." : "No document details to display at this time"}
            width={"100%"}
            showBorders={true}
            allowColumnResizing={true}
            columnAutoWidth={false}
            columnResizingMode="widget"
            showColumnHeaders={true}
          >
            <Paging enabled={false} />
            <HeaderFilter visible={true} />
            <Column
              dataField="key"
              caption="Key"
              allowEditing={false}
            />
            <Column
              dataField="value"
              caption="Value"
              allowEditing={false}
            />
          </DataGrid>
        </div>
      )}
      {Object?.entries(content)?.map(([key, value]) => {
        if (typeof value === "object") {
          if (Array?.isArray(value)) {
            return renderTable(value, key);
          }
          return (
            <div key={key}>
              {value && Object?.entries(value)?.map(([nestedKey, nestedValue]) =>
                renderTable(nestedValue, nestedKey)
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};
