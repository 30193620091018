import React, { useReducer, useState } from "react";
import FileUploader from "devextreme-react/file-uploader";
import ProgressBar from "devextreme-react/progress-bar";
import { formatFileSize } from "../../../utlis/helper";
import { FormPopup } from "../../ui-components/form/form-popup/FormPopup";
import { FileData, FileUploaderComponentProps, State, Action } from "./type";
import showNotification from "../alertPopup/AlertPopup";
import "../../../styles/ui-components/fileUploader.scss";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";

const initialState: State = {
  files: [],
  fileProgress: {},
  configFiles: [],
  configFileProgress: {},
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "SET_FILES":
      return { ...state, files: action.payload };
    case "RESET_FILES":
      return { ...state, files: [] };
    case "SET_CONFIG_FILES":
      return { ...state, configFiles: action.payload };
    case "RESET_CONFIG_FILES":
      return { ...state, configFiles: [] };
    default:
      return state;
  }
};

const FileUploaderComponent: React.FC<FileUploaderComponentProps> = ({
  allowedExtensions,
  allowedConfigExtensions,
  onUpload,
  onConfigUpload,
  isLoading,
  resetUploaderKey,
  popupAddFilesVisible,
  handleFormPopupClose,
  formFields,
  configUploader = true,
  tabPanel = false
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fileProgress, setFileProgress] = useState<{ [key: string]: number | null }>({});
  const [configFileProgress, setConfigFileProgress] = useState<{ [key: string]: number | null }>({});

  const maxFileSize = 20 * (1024 * 1024);

  const handleFilesUpload = (e: { value?: File[] }) => {
    handleFileUploadLogic(e, "FILES");
  };

  const handleConfigFilesUpload = (e: { value?: File[] }) => {
    handleFileUploadLogic(e, "CONFIG_FILES");
  };

  const handleFileUploadLogic = (e: { value?: File[] }, type: "FILES" | "CONFIG_FILES") => {
    const files = e?.value;
    if (!files) return;

    const hugeFiles = files?.filter((file) => file?.size > maxFileSize);
    if (hugeFiles?.length > 0) {
      const maxFiles = hugeFiles?.map((file) => file?.name)?.join(' ,');
      showNotification({
        message: `Files OverSized *${maxFiles}*`,
        type: "warning",
      })
    };

    const validSize = files?.filter((file) => file?.size <= maxFileSize);
    const filePromises = validSize?.map((file) => {
      return new Promise<FileData>((resolve, reject) => {
        const reader = new FileReader();
        const fileSize = formatFileSize(file?.size);
        reader?.readAsDataURL(file);
        reader.onload = () => resolve({ name: file?.name, content: reader?.result, size: fileSize });
        reader.onerror = (error) => reject(error);
      });
    });

    Promise?.all(filePromises)?.then((encodedFiles) => {
      if (type === "FILES") {
        dispatch({ type: "SET_FILES", payload: encodedFiles });
        const initialFileProgress: { [key: string]: number | null } = {};
        encodedFiles?.forEach((file) => {
          initialFileProgress[file?.name] = 0;
        });
        setFileProgress(initialFileProgress);

        encodedFiles?.forEach((file) => {
          const interval = setInterval(() => {
            setFileProgress((prevProgress) => {
              const newProgress = { ...prevProgress };
              if (newProgress[file?.name] !== null && newProgress[file?.name]! < 100) {
                newProgress[file?.name] = newProgress[file?.name]! + 10;
              } else {
                clearInterval(interval);
              }
              return newProgress;
            });
          }, 500);
        });
      } else {
        dispatch({ type: "SET_CONFIG_FILES", payload: encodedFiles });
        const initialConfigFileProgress: { [key: string]: number | null } = {};
        encodedFiles?.forEach((file) => {
          initialConfigFileProgress[file?.name] = 0;
        });
        setConfigFileProgress(initialConfigFileProgress);

        encodedFiles?.forEach((file) => {
          const interval = setInterval(() => {
            setConfigFileProgress((prevProgress) => {
              const newProgress = { ...prevProgress };
              if (newProgress[file?.name] !== null && newProgress[file?.name]! < 100) {
                newProgress[file?.name] = newProgress[file?.name]! + 10;
              } else {
                clearInterval(interval);
              }
              return newProgress;
            });
          }, 500);
        });
      }
    });
  };

  const handleRemoveFile = (fileName: string) => {

    if (state?.files?.some((file) => file?.name === fileName)) {
      const updatedFiles = state?.files?.filter((file) => file?.name !== fileName);
      dispatch({ type: "SET_FILES", payload: updatedFiles });
      const updatedFileProgress = { ...fileProgress };
      delete updatedFileProgress[fileName];
      setFileProgress(updatedFileProgress);
    }

    if (state?.configFiles?.some((file) => file?.name === fileName)) {
      const updatedConfigFiles = state?.configFiles?.filter((file) => file?.name !== fileName);
      dispatch({ type: "SET_CONFIG_FILES", payload: updatedConfigFiles });
      const updatedConfigFileProgress = { ...configFileProgress };
      delete updatedConfigFileProgress[fileName];
      setConfigFileProgress(updatedConfigFileProgress);
    }
  };

  const handleSave = () => {
    if (state?.files?.length === 0 && state?.configFiles?.length === 0) {
      showNotification({
        message: `Please select files to upload`,
        type: "warning",
      });
      return;
    }
    if (state?.files?.length > 0) onUpload(state?.files);
    dispatch({ type: "RESET_FILES" });
    setFileProgress({});
    if (state?.configFiles?.length > 0 && onConfigUpload) onConfigUpload(state?.configFiles);
    dispatch({ type: "RESET_CONFIG_FILES" });
    setConfigFileProgress({});
    handleFormPopupClose();
  };

  const renderUploader = (tabPanel: boolean) => {
    if (tabPanel) {
      return (
        <TabPanel>
          <div className="file-form-container">{formFields}</div>
          <TabPanelItem title="Data Files">
            <div className="file-uploader-container">
              <FileUploader
                key={resetUploaderKey}
                multiple
                accept={allowedExtensions?.join(",")}
                uploadMode="useForm"
                onValueChanged={handleFilesUpload}
                showFileList={false}
              />
              <span className="note">
                {"Allowed file extensions: "}
                {allowedExtensions?.join(", ")}
              </span>
              {Object?.keys(fileProgress)?.map((fileName) => (
                <div key={fileName} className="progressDiv">
                  {fileName}
                  {fileProgress[fileName] !== null && (
                    <ProgressBar value={fileProgress[fileName] ?? 0} />
                  )}
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveFile(fileName)}
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </TabPanelItem>
          <TabPanelItem title="Configuration Files">
            <div hidden={configUploader}>
              <div className="file-uploader-container">
                <FileUploader
                  key={`${resetUploaderKey}-config`}
                  multiple
                  accept={allowedConfigExtensions?.join(",")}
                  uploadMode="useForm"
                  onValueChanged={handleConfigFilesUpload}
                  showFileList={false}
                />
                <span className="note">
                  {"Allowed file extensions: "}
                  {allowedConfigExtensions?.join(", ")}
                </span>
                {Object?.keys(configFileProgress)?.map((fileName) => (
                  <div key={fileName} className="progressDiv">
                    {fileName}
                    {configFileProgress[fileName] !== null && (
                      <ProgressBar value={configFileProgress[fileName] ?? 0} />
                    )}
                    <button
                      className="remove-button"
                      onClick={() => handleRemoveFile(fileName)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </TabPanelItem>
        </TabPanel>
      )
    }
    else {
      return (
        <>
          <div className="file-form-container">{formFields}</div>
          <div className="file-uploader-container">
            <FileUploader
              key={resetUploaderKey}
              multiple
              accept={allowedExtensions?.join(",")}
              uploadMode="useForm"
              onValueChanged={handleFilesUpload}
              showFileList={false}
            />
            <span className="note">
              {"Allowed file extensions: "}
              {allowedExtensions?.join(", ")}
            </span>
            {Object?.keys(fileProgress)?.map((fileName) => (
              <div key={fileName} className="progressDiv">
                {fileName}
                {fileProgress[fileName] !== null && (
                  <ProgressBar value={fileProgress[fileName] ?? 0} />
                )}
                <button
                  className="remove-button"
                  onClick={() => handleRemoveFile(fileName)}
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
        </>
      )
    }
  }

  return (
    <FormPopup
      title="Add Files"
      visible={popupAddFilesVisible}
      setVisible={handleFormPopupClose}
      onSave={handleSave}
      isLoading={isLoading}
      width={1200}
      height={600}
    >
      {renderUploader(tabPanel)}
    </FormPopup>
  );
};

export default FileUploaderComponent;
