// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/media/info-1.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.telemetry-info-icon {
  background-repeat: no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.5;
  bottom: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  display: flex;
  justify-content: flex-start;
}

.telemetry-info-icon:hover {
  opacity: 1;
}

.tele-info {
  font-size: 17px;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/Telemetry-Ipop.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,sBAAA;EACA,YAAA;EACA,eAAA;EACA,6BAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,yDAAA;EACA,aAAA;EACA,2BAAA;AACJ;;AACE;EACE,UAAA;AAEJ;;AAAE;EACE,eAAA;AAGJ","sourcesContent":[".telemetry-info-icon {\n    background-repeat: no-repeat;\n    background-size: cover;\n    border: none;\n    cursor: pointer;\n    background-color: transparent;\n    opacity: 0.5;\n    bottom: 10px;\n    right: 10px;\n    width: 25px;\n    height: 25px;\n    background-image: url(\"../../assets/media/info-1.png\");\n    display: flex;\n    justify-content: flex-start;\n  }\n  .telemetry-info-icon:hover {\n    opacity: 1;\n  }\n  .tele-info {\n    font-size: 17px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
