import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const CardSkeleton = () => {
  return (
    <SkeletonTheme baseColor="#ffffff" highlightColor="#f0f0f0">
      <p>
        <Skeleton count={1} height={`calc(100vh - 200px)`} />
      </p>
    </SkeletonTheme>
  );
};

export default CardSkeleton;
