// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ps-tag {
  display: inline-block;
  color: #000000;
  padding: 2px 10px;
  border-radius: 20px;
  font-size: 12px;
  margin-left: 8px;
}

.ps-tag-primary {
  background-color: var(--side-panel-background);
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/Tag.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,cAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AACF;;AAEA;EACE,8CAAA;AACF","sourcesContent":[".ps-tag {\n  display: inline-block;\n  color: #000000;\n  padding: 2px 10px;\n  border-radius: 20px;\n  font-size: 12px;\n  margin-left: 8px;\n}\n\n.ps-tag-primary {\n  background-color: var(--side-panel-background);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
