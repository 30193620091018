import {
  cellTimeStamp,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { allowedPageSizes } from "../../../../config/datastudio/commonConfig";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import { useCallback, useMemo, useState } from "react";
import DataSource from "devextreme/data/data_source";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import LineageViewer from "../../../../components/ui-components/lineageView/LineageView";
import AuditLogLineage from "../../../../lineageTracing/auditLog";
import { useFetchAuditLogSpecificFileApi } from "../../../../hooks/activity-monitor/useAuditLogs";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";

const AuditLogGrid = (props: any) => {
  const {
    apiProps,
    handleColumnIndexChange,
    id
  } = props;

  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedFileData, setSelectedFileData] = useState<any>(null);
  const {data : fileData, isLoading : isAuditLogFileLoading } = useFetchAuditLogSpecificFileApi(selectedFileData?.polarisAssetID);

  const handleViewLineage = useCallback(
    (e: any, cell: any) => {
      e?.stopPropagation();
      const { row } = cell;
      const { data } = row;
      setSelectedFileData(data);
      setPopupVisible(true);
    },
    []
  );

  const renderViewFile = useCallback(
    (cell: any) => {
      return (
        <PSIconText
          src={WorkflowIcon}
          alt="View File"
          hint="View Lineage"
          width={30}
          height={30}
          onClick={(e: any) => handleViewLineage(e, cell)}
        />
      );
    },
    [handleViewLineage]
  );

    const columns = useMemo(
      () => [
        { caption: "Client Name", dataField: "clientName", dataType: "string" },
        { caption: "Project Name", dataField: "projectName", dataType: "string" },
        { caption: "File Name", dataField: "assetName", dataType: "string" },
        { caption: "Recent User", dataField: "userName", dataType: "string" }, 
        { caption: "Recent Action", dataField: "userAction", dataType: "string" },
        { caption: "Recent Timestamp", dataField: "timeStamp", dataType: "datetime", cellRender: cellTimeStamp },
        { caption: "", type: "buttons", cellRender: renderViewFile, width: 50 },
      ],
      [renderViewFile]
    );

  const gridProps = useMemo(
    () => ({
      dataSource: DataSource,
      columns,
      keyExpr: "id",
      allowedPageSizes,
      searchPanelVisible: true,
      headerFilterVisible: true,
      pagingEnabled: true,
      pagerEnabled: true,
      handleColumnIndexChange
    }),
    [columns, handleColumnIndexChange]
  );

  const pageProps = useMemo(
    () => ({
      nodataText: "No Logs to display at this time",
      heightClass: "height-large",
      id: id ? id : "id",
    }),
    [id]
  );

    const lineageDataProps = {
      title: selectedFileData?.assetName,
      visible: popupVisible,
      onHiding: () => setPopupVisible(false),
      isLineageLoading: isAuditLogFileLoading,
      component: (
        <AuditLogLineage
          data={fileData}
          assetType={selectedFileData?.assetType}
        />
      ),
    };

  return (
    <>
      <div className="recent-activity-grid">
        <PsDataGrid
          pageProps={pageProps}
          gridProps={gridProps}
          apiProps={apiProps}
        />
   <LineageViewer {...lineageDataProps} />
      </div>
    </>
  );
};

export default AuditLogGrid;
