import {
  editCellStatusRender,
  cellComplexityRender,
} from "../../../../components/ui-components/dataGridFormat/dataGridFormat";
import { ContactStatus } from "../../../../components/ui-components/contact-status/ContactStatus";
import { allowedPageSizes } from "../../../../config/client/commonConfig";
import DataSource from "devextreme/data/data_source";
import React, { useCallback, useMemo } from "react";
import PSIconText from "../../../../components/ui-components/icon-with-text/IconText";
import WorkflowIcon from "../../../../assets/images/icons/icon-processing-2.png";
import UrlCatlogLineage from "../../../../lineageTracing/urlCatlog.tsx";
import LineageViewer from "../../../../components/ui-components/lineageView/LineageView";
import PsDataGrid from "../../../../components/ui-components/dataGrid/DataGrid";
import { usePage } from "../../../../contexts/pageContext";

const UrlGrid = React.memo((props: any) => {
  const {
    onEditUrlClick,
    onDeletePopupOpen,
    gridRef,
    handleClientLinkClick,
    height,
    handleColumnIndexChange,
    apiProps,
    openLineage,
    setOpenLineage,
    selectedData,
    setSelectedData,
  } = props;

  const { getPageProperties } = usePage();
  const { filterValue } = getPageProperties() || {};
  const {
    clientName: clientColumn,
    url: urlColumn,
    status: statusColumn,
    group: groupColumn,
    complexity: complexityColumn,
    note: noteColumn,
    url_template_path: urlTemplatePathColumn
  } = filterValue || {};

  const onHanldingLineage = useCallback(() => {
    setOpenLineage(!openLineage);
  }, [openLineage, setOpenLineage]);

  const onHandleLineageData = useCallback((e: any, cell: any) => {
    e?.stopPropagation();
    const { row } = cell;
    const { data } = row;
    setSelectedData(data);
    onHanldingLineage();
  }, [onHanldingLineage, setSelectedData]);

  const renderLineageIcon = useCallback((cell: any) => {
    return (
      <PSIconText
        src={WorkflowIcon}
        alt="View Lineage"
        hint="View Lineage"
        width={30}
        height={30}
        onClick={(e: any) => onHandleLineageData(e, cell)}
      />
    );
  }, [onHandleLineageData]);

  const lineageDataProps = {
    title: selectedData?.fileName,
    visible: openLineage,
    onHiding: onHanldingLineage,
    component: <UrlCatlogLineage data={selectedData} />,
  };

  const columns = useMemo(() => [
    {
      dataField: "clientName",
      caption: "Client",
      cellRender: (data: any) => {
        const clientName = data?.data?.clientName;
        const clientId = data?.data?.clientId;
        return (
          <a
            href="null"
            onClick={(e) => handleClientLinkClick(e, clientId)}
          >
            {clientName}
          </a>
        );
      },
      width: "15%",
      filterValues: clientColumn
    },
    {
      dataField: "url",
      caption: "URL",
      cellRender: (data: any) => (
        <div
          title={data?.value}
          style={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {data?.value ? data?.value?.substring(0, 50) : ""}
        </div>
      ),
      width: "30%",
      filterValues: urlColumn
    },
    {
      dataField: "status",
      caption: "Status",
      cellRender: ContactStatus,
      editCellRender: editCellStatusRender,
      width: "10%",
      filterValues: statusColumn
    },
    {
      dataField: "group",
      caption: "Group Name",
      width: "10%",
      filterValues: groupColumn
    },
    {
      dataField: "complexity",
      caption: "Complexity",
      cellRender: cellComplexityRender,
      width: "10%",
      filterValues: complexityColumn
    },
    {
      dataField: "note",
      caption: "Notes",
      width: "10%",
      filterValues: noteColumn
    },
    {
      dataField: "url_template_path",
      caption: "Template",
      width: "30%",
      filterValues: urlTemplatePathColumn
    },
    {
      caption: "",
      type: "buttons",
      width: 70,
      cellRender: renderLineageIcon,
    },
  ], [clientColumn, complexityColumn, groupColumn, handleClientLinkClick, noteColumn, renderLineageIcon, statusColumn, urlColumn, urlTemplatePathColumn]);

  const pageProps = useMemo(() => ({
    nodataText: "No URLs to display at this time",
    heightClass: height,
    id: "urlgrid",
  }), [height]);

  const gridProps = useMemo(() => ({
    dataSource: DataSource,
    columns: columns,
    keyExpr: "id",
    allowedPageSizes: allowedPageSizes,
    searchPanelVisible: true,
    grifRef: gridRef,
    headerFilterVisible: true,
    pagerEnabled: true,
    pagingEnabled: true,
    onRowEdit: onEditUrlClick,
    onRowDelete: onDeletePopupOpen,
    handleColumnIndexChange
  }), [columns, gridRef, onEditUrlClick, onDeletePopupOpen, handleColumnIndexChange]);

  return (
    <div className="recent-activity-grid">
      <PsDataGrid
        pageProps={pageProps}
        gridProps={gridProps}
        apiProps={apiProps}
      />
      <LineageViewer {...lineageDataProps} />
    </div>
  );
});

export default UrlGrid;
