import { useEffect, useState } from "react";
import { PsTextArea } from "../../../ui-components/form/form";

const Notes = (props: any) => {
  const { contactDetails } = props;
  const [tabsWidth, setTabsWidth] = useState(0);
  const [specialInstructions, setSpecialInstructions] = useState("");
  const [importantLinksAndUrls, setImportantLinksAndUrls] = useState("");
  const [
    technologyIntegrationRequirements,
    setTechnologyIntegrationRequirements,
  ] = useState("");
  const [kpis, setKpis] = useState("");
  const [keyMetricsAndReportingFrequency, setKeyMetricsAndReportingFrequency] =
    useState("");

  const textareaStyles = {
    marginTop: "10px",
    width: "100%",
    backgroundColor: "#f0f0f0",
    color: "black",
  };

  // We need to move this css to global css using the classname provided in the querySelector
  useEffect(() => {
    const labels = document.querySelectorAll(
      ".dx-editor-outlined.dx-texteditor-label-outside .dx-label span"
    );
    labels.forEach((label) => {
      if (label instanceof HTMLElement) {
        label.style.color = "black";
      }
    });
  }, [
    specialInstructions,
    importantLinksAndUrls,
    technologyIntegrationRequirements,
    kpis,
    keyMetricsAndReportingFrequency,
  ]);

  useEffect(() => {
    if (contactDetails && contactDetails.length > 0) {
      setSpecialInstructions(contactDetails[0]["specialInstructions"]);
      setImportantLinksAndUrls(contactDetails[0]["important_links_and_urls"]);
      setTechnologyIntegrationRequirements(
        contactDetails[0]["technology_integration_requirements"]
      );
      setKpis(contactDetails[0]["kpis"]);
      setKeyMetricsAndReportingFrequency(
        contactDetails[0]["keyMetrics_and_reporting_frequency"]
      );
    }
  }, [contactDetails]);

  useEffect(() => {
    setTabsWidth(150);
  }, []);

  return (
    <div className="parentcontainer" style={{ padding: "30px" }}>
      <div className="notescontainer">
        <div className="notescolumn">
          <PsTextArea
            label="Special Instruction"
            value={specialInstructions}
            style={textareaStyles}
            disabled
          />
        </div>
        <div className="notescolumn" style={{ marginLeft: "25px" }}>
          <PsTextArea
            label="Important Links and URLs"
            value={importantLinksAndUrls}
            style={textareaStyles}
            disabled
          />
        </div>
      </div>
      <div className="notescontainer">
        <div className="notescolumn">
          <PsTextArea
            label="Technology integration requirements"
            value={technologyIntegrationRequirements}
            style={textareaStyles}
            disabled
          />
        </div>
        <div className="notescolumn" style={{ marginLeft: "25px" }}>
          <PsTextArea
            label="KPIs"
            value={kpis}
            style={textareaStyles}
            disabled
          />
        </div>
      </div>
      <div className="notescontainer">
        <div className="notescolumn">
          <PsTextArea
            label="Key Metrics and reporting frequency"
            width="49%"
            value={keyMetricsAndReportingFrequency}
            style={textareaStyles}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;
