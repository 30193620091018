import PsDataGrid from "../../../../../../components/ui-components/dataGrid/DataGrid";

interface ReusableDataGridProps {
    title: string;
    gridProps: any;
    pageProps: any;
  }

const ReusableWorkflowDataGrid = ({ title, gridProps, pageProps }:ReusableDataGridProps) => (
  <>
    <div className="infra-title-header">{title}</div>
    <PsDataGrid pageProps={pageProps} gridProps={gridProps} />
  </>
);

export default ReusableWorkflowDataGrid;
