import "../../styles/core-components/staticinfo.scss";
import logos from "../../assets/images/polaris_landing.jpg";

const StaticLoading = () => {
  return (
    <div id="static-loading">
      <div className="logo-wrapper">
        <img
          src={logos}
          alt="Logo"
          className="polarisLogo"
        />
      </div>
    </div>
  );
};

export default StaticLoading;
