import React, { useEffect, useRef, useCallback, useMemo } from "react";
import TreeView from "devextreme-react/tree-view";
import { useScreenSize } from "../../../../../utlis/media-query";
import type { SideNavigationMenuProps } from "../../../../../types";
import "../../../../../styles/ui-components/SideNavigationMenu.scss";
import * as events from "devextreme/events";
import clients from "../../../../../assets/images/noun-client.png";
import activityMonitor from "../../../../../assets/images/noun-worker-4615779.png";
import finops from "../../../../../assets/images/noun-client.png";
import workbench from "../../../../../assets/images/noun-work-1612352.png";
import controlPanel from "../../../../../assets/images/noun-control-6465754.png";
import digitalService from "../../../../../assets/images/noun-digital-2995238.png";
import dataStudio from "../../../../../assets/images/noun-complex-data-6195099.png";
import administration from "../../../../../assets/images/noun-management-5325385.png";
import { useNavigation } from "../../../../../contexts/navigation";
import { useAppHook } from "../../../../../hooks/app/useApps";
import PsSkeleton from "../../../skeleton/PsSkeleton";

export type SideNavigationItem = {
  expanded: boolean;
  path: string;
  text: string;
  icon: string;
  items: Array<{
    text: string;
    path: string | undefined;
    type?: string;
  }>;
};

function getCustomIcon(icon: string): string {
  switch (icon) {
    case "clients":
      return clients;
    case "activityMonitor":
      return activityMonitor;
    case "finops":
      return finops;
    case "workbench":
      return workbench;
    case "controlPanel":
      return controlPanel;
    case "digitalService":
      return digitalService;
    case "dataStudio":
      return dataStudio;
    case "administration":
      return administration;
    default:
      return icon;
  }
}

export const SideNavigationMenu = (
  props: React.PropsWithChildren<SideNavigationMenuProps>
) => {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const { isLarge } = useScreenSize();
  const { sidebarInfo = [], isSideInfoLoading } = useAppHook();

  const normalizePath = useCallback(() => {
    // return navigation.map((item) => ({ ...item, expanded: isLarge, path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path }));
    return sidebarInfo.map((item: any) => ({
      ...item,
      expanded: isLarge,
      path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
      icon: getCustomIcon(item.icon),
    }));
  }, [isLarge, sidebarInfo]);

  const items: SideNavigationItem[] = useMemo(normalizePath, [normalizePath]);

  const { navigationData: { currentPath } = {} } = useNavigation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef: any = useRef();

  const getWrapperRef = useCallback(
    (element: Element | Element[] | any) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, "dxclick");
      }

      wrapperRef.current = element;
      events.on(element, "dxclick", (e: React.PointerEvent) => {
        openMenu(e);
      });
    },
    [openMenu]
  );

  const findItemByPath = useCallback(
    (items: any, currentPath: string | undefined): any => {
      for (const item of items) {
        if (item.path === currentPath) {
          return item;
        }
        if (item?.items) {
          const found = findItemByPath(item.items, currentPath);
          if (found) {
            return found;
          }
        }
      }
      return undefined;
    },
    []
  );

  useEffect(() => {
    const treeView: any = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    const items = treeView.option("items");
    const item = findItemByPath(items, currentPath)?.path;

    if (currentPath === item) {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    } else {
      treeView.unselectAll();
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode, findItemByPath]);

  return (
    <div
      className="dx-swatch-additional side-navigation-menu"
      ref={getWrapperRef}
    >
      {children}
      <div className="menu-container theme-dependent">
        {isSideInfoLoading ? (
          <div className="menu_skeleton">
            <PsSkeleton height={30} count={20} width={200} />
          </div>
        ) : (
          <TreeView
            ref={treeViewRef}
            items={items}
            keyExpr="path"
            selectionMode="single"
            focusStateEnabled={false}
            expandEvent="click"
            onItemClick={selectedItemChanged}
            onContentReady={onMenuReady}
            width="100%"
          />
        )}
      </div>
      {/* <AppFooter>
        Copyright © {new Date().getFullYear()} <br /> Developer Express Inc.
      </AppFooter> */}
    </div>
  );
};
