import { TickerCardStyle } from "../../../../../components/ui-components/ticker-card/TickerCard";
import { TickerCardProps } from "../../types";

const OpportunitiesTicker = ({
  title,
  value,
  colur,
  onClick,
}: TickerCardProps) => (
  <TickerCardStyle
    title={title}
    icon="datatrending"
    value={value}
    percentage={20.3}
    colur={colur}
    onClick={onClick}
  />
);
export default OpportunitiesTicker;