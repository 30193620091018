import { NumberBox } from 'devextreme-react';
import { STATUS_LIST} from '../../../config/constants';

export type ContactStatus = (typeof STATUS_LIST)[number];

export type FilterContactStatus = ContactStatus | 'All';

interface State {
    stateShort: string;
}
export interface ProjectState {
    showProjectModal: boolean,
    popupVisible: boolean,
    selectedProject:ProjectData | null,
    editPopupVisible:boolean,
    selectedProjectType: string,
    projetView: any,
    pageCount:number,
    pageSize:number,
    pageIndex:number
    sortOrder: string,
    sortColumn: string,
}
export interface ProjectData {
    projectManagerEmail: string;
    projectManagerPhone: string;
    projectManagerName: string;
    budgetMonth: string;
    budgetYear: string;
    projectId: string,
    clientId: string,
    clientTag: string,
    clientShortName: string,
    projectDescription: string,
    status: string,
    projectName: string,
    projectShortName: string,
    projectType: string,
    projectModule: string,
    clientName: string,
    startDate: Date | string,
    endDate: string,
    digitalServices:[],
    operations:[],
    infrastructure:[],
    projectFiles: File
}

export const newProjectFormData: ProjectData = {
    projectManagerEmail: '',
    projectManagerPhone: '',
    projectManagerName: '',
    budgetMonth: '',
    budgetYear: '',
    projectId: '',
    clientId: '',
    clientTag:'',
    clientShortName:'',
    projectDescription: '',
    status: 'Active',
    projectName: '',
    projectType: '',
    projectModule: '',
    projectShortName: '',
    clientName: '',
    startDate: new Date(),
    endDate: '',
    digitalServices:[],
    operations:[],
    infrastructure:[],
    projectFiles: new File([''], '')
};

export interface Client {
    status: ContactStatus,
    city: string,
    state: State ,
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    note: string,
    primary_contact:string,
    primary_email:string,
    address1:string,
    address2:string,
    important_links_and_urls:string,
    specialInstructions:string,
    technology_integration_requirements:string,
    kpis:string,
    keyMetrics_and_reporting_frequency: string;
    name:string;

}
export interface ClientState {
    popupVisible: boolean,
    clientId: number,
    selectedClient:Client | null,
    deletePopupVisible:boolean,
    EditpopupVisible:boolean
}

export const SET_PROJECT_TYPE = 'SET_PROJECT_TYPE';

export const initialState = {
  selectWebScraping: true,
  selectDocument: true,
  selectDataAnalytics: true,
};