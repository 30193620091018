import React, { useEffect, useState } from "react";
import TabPanel, { Item as TabPanelItem } from "devextreme-react/tab-panel";
import CilentDetails from "../../../../components/client/client-details/clientDetails";
import Notes from "../../../../components/client/client-details/notesTab/notes";
import ClientProfileDataTab from "../../../../components/client/client-details/clientDataTab/ClientProfileDataTab";
import FinopsTab from "../../../../components/client/client-details/FinopsTab/finopsTab";
import IntegrationTab from "../../../../components/client/client-details/integrationTab/integrationTab";
import SystemInfoTab from "../../../../components/client/client-details/systemInfoTab/systemInfoTab";
import CollaborationChats from "../../../../components/ui-components/chat/Chat";
import { useLocation } from "react-router-dom";
import { useFetchSpecificClient } from "../../../../hooks/client/useClientProfile";
import { useHelperHooks } from "../../../../hooks/helper/utils";

const ClientProfileTab = (props: any) => {
  const [customerId, setCustomerId] = useState("");
  const location = useLocation();
  const { selectedDays, projectInfo } = props;
  const clientId = useHelperHooks().getQueryParams("clientId");

  useEffect(() => {
    if (clientId) {
      setCustomerId(clientId);
      fetchClientContact(clientId);
    }
  }, [location.search]);

  const {
    isError,
    data: clientContactData,
    error,
    isFetching,
  } = useFetchSpecificClient(customerId);

  const fetchClientContact = async (clientId: any) => {
    try {
      if (isFetching) return;

      if (isError) {
        console.error("Error fetching client contact details:", error);
        return;
      }
    } catch (error: any) {
      console.error("Error fetching client contact details:", error.message);
    }
  };

  return (
    <>
      <TabPanel
        focusStateEnabled={false}
        deferRendering={false}
        width={"100%"}
        animationEnabled={true}
        swipeEnabled={true}
      >
        <TabPanelItem title="Client Profile">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <CilentDetails selectedDays={selectedDays} />
          </div>
        </TabPanelItem>
        <TabPanelItem title="Notes">
          <div style={{ minHeight: "465px" }}>
            <Notes contactDetails={clientContactData} />
          </div>
        </TabPanelItem>
        <TabPanelItem title="Client Data">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <ClientProfileDataTab
              customerId={customerId}
              selectedDays={selectedDays}
              clientContactData={clientContactData}
              projectInfo={projectInfo}
            />
          </div>
        </TabPanelItem>
        <TabPanelItem title="Invoice">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <FinopsTab />
          </div>
        </TabPanelItem>
        <TabPanelItem title="Collaboration">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <CollaborationChats />
          </div>
        </TabPanelItem>
        <TabPanelItem title="Integration">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <IntegrationTab />
          </div>
        </TabPanelItem>
        <TabPanelItem title="System Info">
          <div style={{ width: "100%", minHeight: "465px" }}>
            <SystemInfoTab />
          </div>
        </TabPanelItem>
      </TabPanel>
    </>
  );
};

export default ClientProfileTab;
