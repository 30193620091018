import { RevenueAnalysisCard } from "../../../../components/finops/utils/revenue-analysis-card/RevenueAnalysisCard";
import InfraPieChart from "../../../../components/finops/utils/finopsPieChart/infraPieChart";

export const InfraCost = ({
  sales,
  salesByState,
}: {
  sales: any;
  salesByState: any;
}) => {
  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, marginRight: "10px" }}>
        <InfraPieChart datasource={sales} />
      </div>
      <div style={{ flex: 1 }}>
        <RevenueAnalysisCard datasource={salesByState} />
      </div>
    </div>
  );
};
