import Card from "../../../ui-components/card/card";
import { convertToUSD } from "../../../../utlis/helper";
import PsSkeleton from "../../../ui-components/skeleton/PsSkeleton";
import { useFetchTaskDetails, useFetchYTDMTDCost } from "../../../../hooks/client/useClientProfile";

const YTDMTDCostCard = () => {
  const { data: ytdMtdCost, isFetching } =
    useFetchYTDMTDCost();
  const { data: taskDetails } =
    useFetchTaskDetails();

  const totalYTDCost = ytdMtdCost?.YTD?.totalCost;
  const totalMTDCost = ytdMtdCost?.MTD?.totalCost;
  const taskDataLength = taskDetails?.length || 0;

  const renderCostDetails = (costDetails: any, labelSuffix: string) => {
    return costDetails.map((detail: any, index: any) => (
      <div className="detail-row" key={index}>
        <span className="costLabel" style={{ width: "220px" }}>
          {detail.name} Charges ({labelSuffix})
        </span>
        <span className="value" style={{ textAlign: "end" }}>
          {convertToUSD(detail.value)}
        </span>
      </div>
    ));
  };

  return (
    <Card className="card_wrapper">
      <div className="ytdmtdContainer">
        <div className="ytdMtdCostContainer">
          {isFetching || isFetching ? (
            <>
              <PsSkeleton height={30} count={10} />
            </>
          ) : (
            <>
              {taskDataLength > 0 && (
                <div className="detail-row">
                  <span className="costLabel" style={{ width: "190px" }}>
                    {"Total Executions (YTD)"}
                  </span>
                  <span className="value">{taskDataLength}</span>
                </div>
              )}
              {totalYTDCost && renderCostDetails(totalYTDCost, "YTD")}
              {totalMTDCost && renderCostDetails(totalMTDCost, "MTD")}
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default YTDMTDCostCard;
