export interface TickerCardProps {
  value: number;
  title: string;
  colur: string;
  onClick: (e: any) => void;
}

// telemetry
export interface ExecutionDetails {
  eventId: string;
  eventType: string;
  timeStamp: string;
  eventData: string;
  sourceApplication: string;
  sourceEnvironment: string;
}

export interface Telemetry {
  instanceName?: string;
  taskExecutionId: string;
  executionDetails: ExecutionDetails[];
}
export interface Module {
  moduleId: number;
  moduleName: string;
  status: string;
  telemetry: Telemetry[];
}
export interface TelemetryItem {
  id: string;
  executionId: string;
  clientId: string;
  clientName: string;
  taskId: string;
  status: string;
  timestamp: string;
  endTime: string;
  module: Module[];
}



export type FileData = {
  filename: string;
  clientId: string;
  projectId: string;
  fileSize: string;
  createdDate: string;
  fileExtension: string;
  type: string;
};

export type FileItem = {
  id: string;
  text: string;
  expanded: boolean;
  items: any[];
  data: FileData[];
};

export type ProjectItem = {
  id: string;
  text: string;
  expanded: boolean;
  items: FileItem[];
};

export type CategoryItem = {
  id: string;
  text: string;
  expanded: boolean;
  items: ProjectItem[];
};

export interface ClientDataState {
  fileViewerPopupVisible: boolean;
  selectedFileData: any;
}

export const initialState: ClientDataState = {
  fileViewerPopupVisible: false,
  selectedFileData: null,
};