import { useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import { Item } from "devextreme-react/toolbar";
import DateDropDown from "../../../components/ui-components/datebox/DateBox";
import ProjectSummary from "./helper-component/ProjectSummary";
import RecentActivityDataGrid from "../../../components/core-component/recentActivity/recentActivity";
import AlertExceptionPage from "../../../components/core-component/alertExceptions/alertException";
import { useFetchSpecificProjectProfile } from "../../../hooks/client/useProjectProfile";
import "../../../styles/client/project-profile.scss";
import DataGridTitleCardLayout from "../../../components/ui-components/DataGridTitleCardLayout/DataGridTitleCardLayout";
import { useAppHook, useFetchDateRange } from "../../../hooks/app/useApps";
import { useHelperHooks } from "../../../hooks/helper/utils";
import { customDateRange } from "../../../config/constants";
import { replaceQueryUrl } from "../../../utlis/helper";

export const ProjectProfile = () => {
  const { data: DateRange } = useFetchDateRange();
  const projectId = useHelperHooks().getQueryParams("projectId");
  const Period = useHelperHooks().getQueryParams("period");
  const {
    data: projectData,
    refetch,
    isFetching,
  } = useFetchSpecificProjectProfile(projectId as string);

  const project = projectData?.length > 0 ? projectData[0] : {};
  const filteredProjectId = project?.projectId;
  const filteredClientId = project?.clientId;

  const 
  {state:{selectedDays},
  handleDateRangeChange
} = useAppHook();

  const DataGridProps = useMemo(() => {
    return {
      projectData,
      selectedDays,
      refetch,
    };
  }, [projectData, refetch, selectedDays]);

  const title = projectData
    ? `Project 360 - ${project?.projectName}`
    : "Project 360";

  const AnalyticsComponent = useCallback(() => {
    return <ProjectSummary {...DataGridProps} />;
  }, [DataGridProps]);

  const pageBodyComponent = () => [
    {
      title: "",
      Component: AnalyticsComponent,
      componentProps: DataGridProps,
      reloadParams: [
        "fetch-specific-project",
        "fetch-ytdmtd-cost",
        "fetch-ytdmtd-cost",
        "fetch-project-data-list",
        "fetch-project-task",
        "fetch-collaboration",
        "fetch-project-invoice-info",
      ],
    },
    {
      title: "Recent Activity",
      Component: RecentActivityDataGrid,
      componentProps: {
        showToolbar: false,
        height: "height-medium",
        selectedDays: selectedDays,
        filteredProject : projectId,
        onCurrentValueChange: handleDateRangeChange,
      },
      pageRoute: "/activity-dashboard",
      componentId: "activitydashboard",
      reloadParams: "fetch-recent-activity",
    },
    {
      title: "Alerts & Exceptions",
      Component: AlertExceptionPage,
      componentProps: {
        showToolbar: false,
        height: "height-medium",
        selectedDays: selectedDays,
        filteredClientId : filteredClientId, 
        filteredProjectId : filteredProjectId,
        onCurrentValueChange: handleDateRangeChange,
      },
      pageRoute: "/alert-exceptions",
      componentId: "alertsexceptions",
      reloadParams: "fetch-alert-Exception",
    },
  ];

  const additionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <DateDropDown onDateChange={handleDateRangeChange} data={DateRange} />
    </Item>
  );

  const LayoutProps = {
    headerText: title,
    additionalToolbarContent,
    isLoading: isFetching,
    showBackButton: true,
    pageBodyComponent: pageBodyComponent(),
  };

  return (
    <>
      <DataGridTitleCardLayout {...LayoutProps} />
    </>
  );
};
