import { useQuery } from "react-query";
import { fetchAuditLogSpecificFileApi, 
    fetchAuditLogSpecificLineageNodeApi }from "../../service/activity-monitor/api-service";


export const useFetchAuditLogSpecificFileApi = (id: any) => {
  return useQuery(
    ["fetch-audit-log-specific-file", id],
    () => fetchAuditLogSpecificFileApi(id),
    {
      refetchOnWindowFocus: false,
      select: (data: any) => {
        return data?.data;
      },
      onError: (error: Error) => {
        console.log(error);
      },
    }
  );
}

export const useFetchAuditLogSpecificLineageNodeApi = (id: any) =>{
   return useQuery(
    ["fetch-audit-log-specific-lineage-node", id],
    () => fetchAuditLogSpecificLineageNodeApi(id),
    {
        refetchOnWindowFocus: false,
        enabled: !!id,
        select: (data: any) => {
            return data?.data;
          },
          onError: (error: Error) => {
            console.log(error);
          },
    }
   )
}