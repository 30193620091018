import { useCallback, useMemo, useState } from "react";
import { ProjectData } from "../types";
import {
  useCreateProject,
  useFetchSpecificProjectDetails,
} from "../../../../hooks/client/useProject";
import ProjectUrlPanelDetails from "./projectUrl-panel/ProjectUrlPanelDetails";
import { RightSidePannel } from "../../../../components/ui-components/right-side-panel/right-side-panel";

const EditProject = (props: any) => {
  const { editPopupVisible, changeEditPopupVisibility, selectedProject } =
    props;

  const [formData, setData] = useState<ProjectData>();

  const projectId = selectedProject?.row?.data?.projectId;
  const projectData = useFetchSpecificProjectDetails(projectId);
  const data = projectData?.data;
  const projectEditData = data?.[0];

  const isEdit = true;

  const closePanel = useCallback(() => {
    changeEditPopupVisibility();
  }, [changeEditPopupVisibility]);

  const {
    mutate,
    isLoading,
    isSuccess,
  } = useCreateProject(closePanel, isEdit);

  const handleFormSubmit = useCallback(() => {
    mutate(formData);
  }, [mutate, formData]);

  const onDataChanged = useCallback(
    (data: React.SetStateAction<ProjectData | undefined>) => {
      setData(data);
    },
    []
  );

  const FormProps = useMemo(() => {
    return{
      data: projectEditData,
      editing: true,
      onDataChanged,
      isSuccess,
      changeEditPopupVisibility,
    }
  }, [projectEditData, onDataChanged, isSuccess, changeEditPopupVisibility]);

  return (
    <>
      <div className="project-wrapper"></div>
      {editPopupVisible && (
        <RightSidePannel
          isOpened={editPopupVisible}
          changePanelOpened={changeEditPopupVisibility}
          title={"Project"}
          onSave={handleFormSubmit}
          isLoading={isLoading}
        >
          <ProjectUrlPanelDetails {...FormProps} />
        </RightSidePannel>
      )}
    </>
  );
};

export default EditProject;
