// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-content-container {
  display: contents;
}

.file-container {
  background-color: white;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  height: 85vh;
  max-height: 85vh;
  overflow: auto;
  display: flex;
}

.fileviewer-grid {
  height: 90vh;
  max-height: 90vh;
}

.grid-container {
  margin-top: 20px;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 8px;
  gap: 40px;
  margin-left: 20px;
}

.title-text {
  font-size: 22px !important;
}

.title-details {
  text-align: right;
  flex: 1 1;
}

.title-detail {
  text-align: left;
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/styles/ui-components/FileViewer.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF;;AAEA;EACE,uBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,cAAA;EACA,SAAA;EACA,iBAAA;AACF;;AAEA;EACE,0BAAA;AACF;;AAEA;EACE,iBAAA;EACA,SAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF","sourcesContent":[".popup-content-container {\n  display: contents;\n}\n\n.file-container {\n  background-color: white;\n  width: 100%;\n  padding: 10px;\n  margin-top: 20px;\n  height: 85vh;\n  max-height: 85vh;\n  overflow: auto;\n  display: flex;\n}\n\n.fileviewer-grid {\n  height: 90vh;\n  max-height: 90vh;\n}\n\n.grid-container {\n  margin-top: 20px;\n}\n\n.title-container {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 8px;\n  gap: 40px;\n  margin-left: 20px;\n}\n\n.title-text {\n  font-size: 22px !important;\n}\n\n.title-details {\n  text-align: right;\n  flex: 1;\n}\n\n.title-detail {\n  text-align: left;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
