import  OpportunitiesTicker  from "./OpportunitiesTicker";
import { useNavigate } from 'react-router-dom';
import "../../../styles/client/client-dashboard.scss";
import PsSkeleton from "../../ui-components/skeleton/PsSkeleton";
import { useFetchActiveTask } from "../../../hooks/client/useClientDashboard";
import { GTAButtonEventHandler } from "../../../utlis/GAT-event-handler";

const ActiveTask = (props: any) => {
  const { selectedDays } = props;
  const { data: fetchedActiveTask, isFetching } = useFetchActiveTask(selectedDays);
  const navigate = useNavigate();

  const parseAndValidateInt = (value: any) => {
    const parsedValue = parseInt(value);
    return isNaN(parsedValue) ? 0 : parsedValue;
  };

  const handleClick = (route: string) => (event: any) => {
    navigate(route);
    event.preventDefault();
    const eventName = `acive_task: ${route}`;
    GTAButtonEventHandler(eventName)
  };

  if (isFetching) return <PsSkeleton count={1} height={50} />;

  return (
    <div className="cards compact">
      <OpportunitiesTicker
        title="Active Tasks"
        onClick={handleClick("/task-automation")}
        value={parseAndValidateInt(
          fetchedActiveTask?.activeTasks?.["Active Tasks"]
        )}
        colur="black"
      />
      <OpportunitiesTicker
        title="Completed"
        onClick={handleClick("/activity-dashboard")}
        value={parseAndValidateInt(
          fetchedActiveTask?.activeTasks.Completed
        )}
        colur="green"
      />
      <OpportunitiesTicker
        title="Failed"
        onClick={handleClick("/activity-dashboard")}
        value={parseAndValidateInt(
          fetchedActiveTask?.activeTasks?.Failed
        )}
        colur="red"
      />
    </div>
  );
};

export default ActiveTask;