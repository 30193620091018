import { Item } from "devextreme-react/toolbar";
import "../../../../../styles/finops/operations.scss";
import OperationsDataGridPage from "./helper-component/operationsDataGrid";
import { useMemo } from "react";
import {
  useFetchOperations,
  useRateCard,
} from "../../../../../hooks/finops/useRatecard";
import { OperationsFormPopup } from "./helper-component/operationsFormPopup";
import OperationsEditPopup from "./helper-component/operationsEditPopup";
import { OperationsDeletePopup } from "./helper-component/operationsDeletePopup";
import DataGridMultiViewLayout from "../../../../../components/ui-components/DataGridMultiViewLayout/DataGridMultiViewLayout";
import NewIcon from "../../../../../assets/images/icons/icon-add-item-1.png";
import PSIconText from "../../../../../components/ui-components/icon-with-text/IconText";

const OperationsPage = () => {
  const {
    state: {
      popupVisible,
      editPopupVisible,
      selectedRow,
      deletePopupVisible,
    },
    onAddRowClick,
    changePopupVisibility,
    onDeletePopupOpen,
    onDeletePopupClose,
    onStartPopupOpen,
    onEditClick,
    onEditRowClick,
    changeEditPopupVisibility,
  } = useRateCard();

  const opData = useFetchOperations();
  const { refetch, isFetching } = opData;

  const DataGridProps = useMemo(() => {
    return {
      opData,
      onAddRowClick,
      onDeletePopupOpen,
      onStartPopupOpen,
      onEditClick,
      onEditRowClick,
    };
  }, [
    opData,
    onEditClick,
    onEditRowClick,
    onDeletePopupOpen,
    onStartPopupOpen,
    onAddRowClick,
  ]);

  const FormProps = useMemo(() => {
    return {
      popupVisible,
      changePopupVisibility,
    };
  }, [changePopupVisibility, popupVisible]);

  const PanelProps = useMemo(() => {
    return {
      selectedRow,
      editPopupVisible,
      changeEditPopupVisibility,
    };
  }, [changeEditPopupVisibility, selectedRow, editPopupVisible]);

  const deleteProps = useMemo(() => {
    return {
      selectedRow,
      deletePopupVisible,
      onDeletePopupClose,
    };
  }, [selectedRow, deletePopupVisible, onDeletePopupClose]);

  const AdditionalToolbarContent = (
    <Item location="after" locateInMenu="auto">
      <PSIconText
        text="Add Role"
        src={NewIcon}
        alt="Add Role"
        onClick={onAddRowClick}
      />
    </Item>
  );

  const PageBodyComponent = () => [
    {
      mode: "grid",
      Component: OperationsDataGridPage,
      componentProps: DataGridProps,
    },
  ];

  const layoutProps = {
    headerText: "",
    refetch,
    additionalToolbarContent: AdditionalToolbarContent,
    pageBodyComponent: PageBodyComponent(),
    layoutView: "gridView",
    isLoading: isFetching,
  };

  return (
    <>
      <DataGridMultiViewLayout {...layoutProps} />
      <OperationsFormPopup {...FormProps} />
      <OperationsEditPopup {...PanelProps} />
      <OperationsDeletePopup {...deleteProps} />
    </>
  );
};

export default OperationsPage;
