// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.workflow-container {
  background-color: white;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  height: 85vh;
  max-height: 85vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: white;
  position: relative;
  flex-shrink: 0;
}
.footer-actions .add-step-button {
  align-self: flex-end;
}
.footer-actions .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.form-style {
  padding: 10px;
}

.tab-panel {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  height: 85vh;
  overflow: auto;
}

.NodeWrapper {
  background-color: white;
  border-radius: 10px;
  padding: 15px;
  height: 150px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.NodeWrapper span {
  font-size: 12px;
}

.NodeWrapper .header_info {
  display: flex;
  flex-direction: column;
}

.multiset .react-flow__node {
  width: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/task/workflow.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,WAAA;EACA,aAAA;EACA,gBAAA;EACA,YAAA;EACA,gBAAA;EACA,cAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,uBAAA;EACA,kBAAA;EACA,cAAA;AACF;AAAE;EACE,oBAAA;AAEJ;AAAE;EACE,aAAA;EACA,yBAAA;EACA,SAAA;AAEJ;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,aAAA;EACA,YAAA;EACA,cAAA;AACF;;AAEA;EACE,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,+CAAA;AACF;AAAE;EACE,eAAA;AAEJ;;AAEA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":[".workflow-container {\n  background-color: white;\n  width: 100%;\n  padding: 10px;\n  margin-top: 20px;\n  height: 85vh;\n  max-height: 85vh;\n  overflow: auto;\n  display: flex;\n  flex-direction: column; \n  justify-content: space-between; \n}\n\n.footer-actions {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 10px;\n  background-color: white;\n  position: relative; \n  flex-shrink: 0; \n  .add-step-button {\n    align-self: flex-end;\n  }\n  .button-group {\n    display: flex;\n    justify-content: flex-end;\n    gap: 10px;\n  }\n}\n\n.form-style {\n  padding: 10px;\n}\n \n.tab-panel {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  padding: 10px;\n  height: 85vh;\n  overflow: auto;\n}\n\n.NodeWrapper{\n  background-color : white;\n  border-radius: 10px;\n  padding: 15px;\n  height: 150px;\n  width: 200px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-around;\n  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);\n  span{\n    font-size: 12px;\n  }\n}\n\n.NodeWrapper .header_info{\n  display: flex;\n  flex-direction: column;\n}\n\n.multiset .react-flow__node {\n  width: 50px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
