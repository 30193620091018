import Popup from "devextreme-react/popup";
import PsButton from "../../../../components/ui-components/button/Button";
import { useApp } from "../../../../contexts/app";
import { useDeleteProject } from "../../../../hooks/client/useProject";

const ProjectDeletePopup = (props: any) => {
  const {deletePopupVisible, onDeletePopupClose, selectedProject} = props;
  const { row } = selectedProject || {};
  const { data } = row || {};
  const { projectId, projectName } = data || {};
  const { user } = useApp();
  const userId = user?.id
  const {isLoading, refetch} = useDeleteProject(onDeletePopupClose, projectName, projectId, userId)
  
  const loaderProps = {
    show: isLoading,
    loadertext: "Please Wait...",
  };


  return (
    <Popup
      title="Delete Project"
      visible={deletePopupVisible}
      onHiding={onDeletePopupClose}
      width="30%"
      height='auto'
    >
        <div style={{margin: '10px 0'}}>
          <p>Are you sure you want to delete the <b>{projectName}</b> Project ?</p>
          <p style={{margin: '10px 0'}}>If you delete the Info, you will not be able to recover it.</p>
        </div>
        <div style={{ float: 'left' }}>
        <PsButton onClick={() => refetch()} text="confirm" type="normal" mode='contained' loaderProps={loaderProps} />
        </div>
        <div style={{ float: 'right' }}>
          <PsButton onClick={onDeletePopupClose} text="Close" type="normal" mode='contained' />
        </div>
    </Popup>
  )
}

export default ProjectDeletePopup;