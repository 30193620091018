import { DataGridTypes } from "devextreme-react/cjs/data-grid";
import { CustomTitle, MaterGridComponent } from "./ProjectComponent";
import { useFetchProjectInfo } from "../../../../hooks/client/useProject";
import PsAccordion from "../../../../components/ui-components/accordion/accordion";

const MasterDetailView = (props: DataGridTypes.MasterDetailTemplateData) => {
  const data = props.data?.data;
  const { projectId, projectName, clientName, projectType } =
    data;

  const params = {
    projectId,
    projectName,
    clientName,
  };

  const { data: detailData, isLoading }: { data: any; isLoading: any } =
    useFetchProjectInfo(params, projectType);
  const detaildataView =
    detailData?.projects?.[0]?.resources.filter(
      (item: any) => item.clientData
    ) || [];

  if (isLoading) {
    return <span>Loading...</span>;
  }

  return (
    <>
      <div className="externalPsAccordion">
        <PsAccordion
          dataSource={detaildataView}
          Component={(e: any) => MaterGridComponent(e, data)}
          CustomTitle={CustomTitle}
        />
      </div>
    </>
  );
};

export default MasterDetailView;
